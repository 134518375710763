<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="overflow-y- overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal justify-center items-center flex"
  >
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div
      class="main-container relative my-auto px-4 h-full md:h-auto bg-white rounded-lg shadow dark:bg-gray-700 border-grey-300 border-2"
    >
      <div id="titre+retour" class="flex justify-center items-center p-5">
        <!-- <a @click="close"
                  class="cursor-pointer whitespace-no-wrap inline-flex font-bold items-center justify-center p-2 mr-2 border border-transparent text-base leading-6 rounded-md text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150">
                Retour
                </a> -->
        <h3 class="font-bold text-black mt-2" style="font-size: 25px">Répartition des travaux</h3>
        <div
          @click="close"
          class="cursor-pointer absolute right-10 text-5xl rounded-lg h-10 w-10 flex place-content-center place-items-center hover:bg-gray-100 focus:outline-none focus:shadow-outline-indigo transition ease-in-out duration-150"
        >
          &#215;
        </div>
      </div>
      <p
        v-if="typeexploitation == 'denormandie' || typeexploitation == 'pinel_ancien' || typeexploitation == 'malraux'"
        class="text-center text-xs"
      >
        Insérez dans les <span class="bg-purple-200 p-1 rounded">champs violets</span> les travaux relatifs au
        dispositif fiscal selectionné
      </p>
      <div class="p-8 grid grid-cols-3 w-full">
        <div id="titres" class="col-span-4 grid grid-cols-3 titres-col justify-items-center mb-4">
          <div class="flex"><div class="self-end pb-3">Période</div></div>
          <div class="flex self-end">
            Rénovation
            <QuestionMarkv2bottom
              class="ml-1 place-self-center"
              :text="`N’inscrivez ici que les travaux d’entretien et de rénovation du bien. Les frais liés à l'ameublement du bien (si loué meublé) seront à inscrire dans la partie 'Régime fiscal'.`"
            />
          </div>
          <div class="flex self-end">
            Construction
            <QuestionMarkv2bottom
              class="ml-1 place-self-center"
              :text="`Les frais de travaux de construction, de reconstruction et/ou d’agrandissement ne sont pas déductibles fiscalement en temps que charges. Ils seront comptabilisés dans l'amortissement comptable du bien lorsque le régime fiscal sélectionné le permettra (mettre au LMNP au réel par exemple).`"
            />
          </div>
        </div>
        <div id="Periode" class="w-full">
          <div v-for="(annee, index) in annees" id="Annee" class="h-10">
            <div class="tableau-col text-center h-full pt-1">Année {{ index + 1 }}</div>
          </div>
        </div>
        <div id="Renovation" class="w-full">
          <div v-for="(annee, index) in annees" class="euro-input h-10 mx-8">
            <input
              required
              type="number"
              v-model="travauxReno['year_' + index]"
              min="0"
              :class="isBackgrounded(index, 'denormandie')"
              class="tableau-col text-right h-8 form-input block px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
            />
          </div>
        </div>
        <div id="Cons/agran" class="w-full">
          <div v-for="(annee, index) in annees" class="euro-input h-10 mx-8">
            <input
              required
              type="number"
              v-model="travauxConstru['year_' + index]"
              min="0"
              :class="[isBackgrounded(index, 'pinel_ancien'), isBackgrounded(index, 'malraux')]"
              class="tableau-col text-right h-8 form-input block px-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
            />
          </div>
        </div>
      </div>
      <div class="px-8 grid grid-cols-3 w-full">
        <div id="titres" class="col-span-3 grid grid-cols-3 titres-col justify-items-center mb-4">
          <div class="flex"><div class="self-end font-bold">TOTAUX</div></div>
          <div class="self-end font-bold justify-self-end pr-9">
            {{
              getTotalReno().toLocaleString('fr-FR', {
                minimumFractionDigits: 0,
              })
            }}
            €
          </div>
          <div class="self-end font-bold justify-self-end pr-9">
            {{
              getTotalConstru().toLocaleString('fr-FR', {
                minimumFractionDigits: 0,
              })
            }}
            €
          </div>
        </div>
      </div>
      <div id="bouton-enregistrer" class="my-6">
        <div class="px-4 py-3 text-right flex justify-center items-center">
          <span class="inline-flex rounded-md shadow-sm">
            <a
              @click="close"
              class="cursor-pointer whitespace-no-wrap inline-flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-xl text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
            >
              Enregistrer les modifications
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionMarkv2bottom from '@/components/SVG/QuestionMarkv2bottom.vue';

export default {
  name: 'Travaux',
  data() {
    return {
      annees: [],
    };
  },
  props: {
    travauxReno: Object,
    travauxConstru: Object,
    typeexploitation: String,
    anneeTravaux: Number,
    duree: Number,
  },
  components: {
    QuestionMarkv2bottom,
  },
  mounted() {
    for (let index = 0; index < this.duree; index++) {
      this.annees.push({ id: index });
    }
  },
  methods: {
    close() {
      this.$emit('close', this.travauxReno, this.travauxConstru, this.getTotalReno(), this.getTotalConstru());
    },
    getTotalReno() {
      let total = 0;
      for (const annee in this.annees) {
        let anneeAInserer = `year_${annee}`;
        let nbReno;
        let coutReno = this.travauxReno[anneeAInserer];
        if (typeof coutReno == 'number') nbReno = coutReno;
        else if (typeof coutReno == 'string' && coutReno.length) nbReno = Number.parseInt(coutReno);
        else nbReno = 0;
        total += nbReno;
      }
      return total;
    },
    getTotalConstru() {
      let total = 0;
      for (const annee in this.annees) {
        let anneeAInserer = `year_${annee}`;
        let nbConstru;
        let coutConstru = this.travauxConstru[anneeAInserer];
        if (typeof coutConstru == 'number') nbConstru = coutConstru;
        else if (typeof coutConstru == 'string' && coutConstru.length)
          nbConstru = Number.parseInt(coutConstru);
        else nbConstru = 0;
        total += nbConstru;
      }
      return total;
    },
    //Permet de mettre en surbrillance les années de travaux affectées en fonction du type d'exploitation
    isBackgrounded(index, type) {
      return {
        'bg-purple-200': this.typeexploitation == type && this.anneeTravaux >= index + 1,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  width: 56rem;
}

.head-text {
  font-size: 15px;
  line-height: 40px;
}

.content-text {
  font-size: 16px;
  line-height: 40px;
}
.subtitle {
  font-size: 20px;
  margin-top: 60px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.titres-col {
  width: 100%;
  place-self: center;
  text-align: center;
  font-weight: 500;
}

.tableau-col {
  width: 100%;
  place-self: center;
  font-weight: 400;
}
.euro-input {
  position: relative;
}

.euro-input input {
  padding-right: 18px;
  text-align: end;
}

.euro-input:before {
  position: absolute;
  top: 7px;
  right: 8px;
  font-size: 0.75rem;
  content: '€';
}
</style>
