<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="content-text overflow-y-auto overflow-x-hidden fixed z-50 mx-30 md:inset-8 h-full"
  >
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div
      class="relative px-15 py-5 md:h-auto bg-white shadow dark:bg-gray-700 leading-9 border border-gray-300 rounded-xl max-w-7xl mx-auto"
    >
    <PButton icon="pi pi-times" class="p-button-rounded p-button-danger absolute top-5 right-5" style="position: absolute;" @click="close" />
      <div class="relative">
        <div class="text-left pb-0 pt-4">
          <h3 class="px-5 font-bold text-black" style="font-size: 30px">
            Bonjour
            <!-- {{ prenom }} -->
            !
          </h3>
        </div>

        <div class="pz-6 pt-0 overflow-y-auto overflow-x-auto">
          <div class="px-5 text-sm">
            <div class="head-text text-left pt-4">
              <p>Bienvenue sur la solution Aestima.</p>
            </div>
            <div class="py-8">
              <p>
                Voici un bref résumé de ce que vous pourrez réaliser avec Aestima.
                <a
                  href="https://www.youtube.com/playlist?list=PLtzzHvUMaMTK6jpPhVdKTvlm2bHflKfBa"
                  target="_blank"
                  to="/acces-simulateur"
                  class="text-lightblueaestima-100 font-bold underline"
                  >Nos tutoriels vidéos</a
                >
                sont également un bon moyen de prendre en main la solution Aestima. N’hésitez pas à aller les
                consulter.
              </p>
            </div>
            <div class="flex flex-col gap-y-12">
              <div class="my-2">
                1. Pour commencer à utiliser l’outil, vous devez tout d’abord
                <strong>créer votre premier client</strong>. Pour cela, cliquez sur “Créer un nouvel
                investisseur” en haut à droite de la page d’accueil.
                <img class="mt-4" src="../../assets/tuto/capture1.png" alt="" />
              </div>
              <div>
                2. Un fois le client créé, vous pourrez
                <strong>réaliser des simulations financières</strong>. Sachez que certains éléments du profil
                de votre client, comme sa fiscalité, impacteront directement les simulations que vous
                réaliserez pour lui.
                <img class="mt-4" src="../../assets/tuto/capture2.png" alt="" />
              </div>
              <div>
                3. <strong>Vous arriverez ensuite sur la partie résultats</strong>. Consultez les performances
                financières du bien étudié ainsi que son marché locatif local. Cliquez ensuite sur “Export du
                rapport financier” pour personnaliser et exporter votre synthèse financière en PDF.
                <img class="mt-4 mb-8" src="../../assets/tuto/capture3.png" alt="" />
              </div>
              <div>
                4. <strong>Vos simulations restent enregistrées</strong>. Vous pouvez les consulter à tout
                moment (<svg
                  class="inline"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g fill="#14A0EB">
                      <path
                        d="M86,28.66667c-57.33333,0 -78.83333,57.33333 -78.83333,57.33333c0,0 21.5,57.33333 78.83333,57.33333c57.33333,0 78.83333,-57.33333 78.83333,-57.33333c0,0 -21.5,-57.33333 -78.83333,-57.33333zM86,50.16667c19.78717,0 35.83333,16.04617 35.83333,35.83333c0,19.78717 -16.04617,35.83333 -35.83333,35.83333c-19.78717,0 -35.83333,-16.04617 -35.83333,-35.83333c0,-19.78717 16.04617,-35.83333 35.83333,-35.83333zM86,64.5c-11.87412,0 -21.5,9.62588 -21.5,21.5c0,11.87412 9.62588,21.5 21.5,21.5c11.87412,0 21.5,-9.62588 21.5,-21.5c0,-11.87412 -9.62588,-21.5 -21.5,-21.5z"
                      ></path>
                    </g>
                  </g></svg
                >) depuis l’onglet “Mes biens locatifs”, les modifier (<svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20"
                  height="20"
                  viewBox="0 0 172 172"
                  class="inline"
                  style="fill: #000000"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g fill="#31c48d">
                      <path
                        d="M138.52092,66.42067l8.74333,-8.74333c9.08017,-9.08017 9.08017,-23.85783 0,-32.94158c-4.39675,-4.39317 -10.24117,-6.80833 -16.47258,-6.80833c-6.23142,0 -12.07942,2.41875 -16.47258,6.81192l-8.73975,8.73975zM97.97908,41.07933l-64.74725,64.74725c-1.37958,1.37958 -2.4295,3.08167 -3.03867,4.92708l-12.00417,36.26692c-0.64142,1.92783 -0.13617,4.05275 1.30075,5.48967c1.02842,1.02483 2.39725,1.57308 3.80192,1.57308c0.56617,0 1.13592,-0.08958 1.69133,-0.27233l36.25617,-12.00775c1.85258,-0.60917 3.55825,-1.65908 4.93783,-3.04225l64.74367,-64.74367z"
                      ></path>
                    </g>
                  </g></svg
                >), les dupliquer (<svg
                  class="inline"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="22"
                  height="22"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g fill="#14a0eb">
                      <path
                        d="M50.16667,14.33333c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h93.16667v93.16667c0,3.956 3.21067,7.16667 7.16667,7.16667c3.956,0 7.16667,-3.21067 7.16667,-7.16667v-93.16667c0,-7.91917 -6.41417,-14.33333 -14.33333,-14.33333zM28.66667,43c-7.91917,0 -14.33333,6.41417 -14.33333,14.33333v86c0,7.91917 6.41417,14.33333 14.33333,14.33333h86c7.91917,0 14.33333,-6.41417 14.33333,-14.33333v-86c0,-7.91917 -6.41417,-14.33333 -14.33333,-14.33333zM71.66667,71.66667c3.956,0 7.16667,3.21067 7.16667,7.16667v14.33333h14.33333c3.956,0 7.16667,3.21067 7.16667,7.16667c0,3.956 -3.21067,7.16667 -7.16667,7.16667h-14.33333v14.33333c0,3.956 -3.21067,7.16667 -7.16667,7.16667c-3.956,0 -7.16667,-3.21067 -7.16667,-7.16667v-14.33333h-14.33333c-3.956,0 -7.16667,-3.21067 -7.16667,-7.16667c0,-3.956 3.21067,-7.16667 7.16667,-7.16667h14.33333v-14.33333c0,-3.956 3.21067,-7.16667 7.16667,-7.16667z"
                      ></path>
                    </g>
                  </g></svg
                >), les comparer entre elles pour sélectionner la plus adaptée aux objectifs de votre client.
                <div class="">
                  <div class="text-center mt-16 sm:px-6">
                    <span class="rounded-md shadow-sm hover:cursor-pointer">
                      <a
                        @click="close"
                        class="cursor-pointer whitespace-no-wrap px-15 py-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
                      >
                        J’ai compris, c’est parti ! 💪🏽
                      </a>
                    </span>
                  </div>
                  <div class="text-xs text-center my-4">
                    Ce tutoriel reste accessible à tout moment depuis vos paramètres.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PremiereUtil',
  props: {},
  methods: {
    close() {
      this.$emit('close');
    },
  },
  data() {
    return {
      prenom: 'toi',
    };
  },
};
</script>

<style lang="scss" scoped>
.head-text {
  font-size: 15px;
  line-height: 40px;
}
.content-text {
  font-size: 20px;
  line-height: 40px;
}
.subtitle {
  font-size: 20px;
  margin-top: 60px;
}
</style>
