<template>
  <svg data-v-79ad0d38="" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="46" height="46" viewBox="0 0 172 172" style="fill: rgb(0, 0, 0);"><g data-v-79ad0d38="" fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="inherit" font-size="none" text-anchor="inherit" style="mix-blend-mode: normal;"><path data-v-79ad0d38="" d="M0,172v-172h172v172z" fill="none"></path><g data-v-79ad0d38="" fill="#e74c3c"><path data-v-79ad0d38="" d="M52.03,42.2475l-9.7825,9.7825l34.185,33.97l-34.185,33.97l9.7825,9.7825l34.185,-33.97l34.185,33.97l9.675,-9.7825l-34.0775,-33.97l34.0775,-33.97l-9.675,-9.7825l-34.185,33.97z"></path></g></g></svg>
</template>

<script>
export default {
  name: "No"
}
</script>

<style scoped>

</style>