<template>
	<form
		id="simulator"
		enctype="multipart/form-data"
		class="pt-8 text-left bg w-full"
		@submit.prevent="timeline"
		@keypress.enter="checkForTextarea($event)"
	>
		<div class="grid grid-cols-12">
			<Sidebar />
			<!-- Tooltip valeurs par defaut -->
			<!-- <div id="toptip" style="padding-bottom: 0rem" class="pb-0 card">
        <div class="flex flex-row align-middle card-header">
          <img src="../assets/icons/faq11.png" class="w-20 h-20" alt="Information">
          <div class="my-auto ml-5">
            <p>
              Même si vous n’avez pas encore repéré de bien locatif, vous pouvez quand
              même faire une simulation fictive pour étudier en amont les répercutions
              financières.
            </p>
            <p>Pour cela, appuyez vous sur les données automatiques.</p>
            <div class="mt-6 col-span-3">
              <a
                class="inline-flex items-center justify-center px-4 py-2 text-xs font-medium text-center text-gray-700 bg-white border border-gray-700 cursor-pointer leading-6 rounded-md hover:bg-gray-300 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
                @click="showPremiereUtilModal"
              >Valeur automatique par défaut</a>
              <PremiereUtil
                v-show="premiereUtilModal"
                @close="closePremiereUtilModal"
              />
            </div>
          </div>
        </div>
      </div> -->

			<div id="descriptif" class="card flex flex-col gap-5 w-full">
				<div class="flex flex-row align-middle card-header">
					<img
						src="../assets/icons/faq12.png"
						class="h-28"
						alt="description du bien"
					/>
					<div
						ref="adress"
						class="flex flex-col my-auto ml-5 text-xl"
					>
						<p>Comment se définit le projet d’investissement ?</p>
						<p class="font-bold">Descriptif du projet</p>
					</div>
				</div>
				<div class="card-body">
					<div class="flex flex-col mx-auto max-w-5xl">
						<div
							class="justify-between col-start-2 col-span-10 grid grid-cols-3 gap-x-6"
						>
							<!-- NOM SIMULATION -->
							<div class="focus flex flex-col justify-between">
								<label for="name" class="texte-label"
									>Nom de la simulation*</label
								>
								<input
									id="name"
									v-model="name"
									required
									type="text"
									class="block w-full px-3 py-2 mt-1 border form-input"
								/>
							</div>
							<!-- FIN NOM SIMULATION -->
							<!-- EMPLACEMENT -->
							<div class="focus flex flex-col justify-between">
								<label class="texte-label"
									>Emplacement du bien (N° + Rue + CP +
									Ville)</label
								>
								<AutoComplete
									v-if="
										projectSelected === null ||
										modifAdress === true
									"
									v-model="locApi"
									class="text-xs"
									:api="APICall"
									:is-async="true"
									@selected="setLocalisation"
								/>
								<div v-else class="mt-3 text-xs font-medium">
									{{ loc.carac.label }}
								</div>
								<button
									v-if="
										projectSelected !== null &&
										modifAdress === false
									"
									class="px-2 py-1 mt-3 text-xs font-medium text-white bg-lightblueaestima-100 rounded-md bouton"
									@click="modifAdress = true"
								>
									Modifier l'adresse
								</button>
							</div>
							<!-- FIN EMPLACEMENT -->
							<!-- TYPE -->
							<div class="focus flex flex-col justify-between">
								<label for="exploitation" class="texte-label"
									>Type d'exploitation choisi*</label
								>
								<select
									id="exploitation"
									v-model="typeexploitation"
									:required="!projectSelected"
									:disabled="projectSelected"
									class="w-full mt-1 text-gray-500 bg-transparent border-transparent form-select sm:text-xs sm:leading-5"
									:class="{ 'bg-gray-200': projectSelected }"
									name="exploitation"
									@change="checkExploitationEvent"
								>
									<!-- Willian avait mis checkExploitationEvent en v-model, j'ai remis typeexploitation car les v-if 30 lignes plus bas etaient broken, mais j'imagine qu'il faudrait regarder sa fonction (Guillaume) -->
									<option value="classique_ancien" selected>
										Exploitation classique dans l’ancien
									</option>
									<option value="classique_neuf">
										Exploitation classique dans le neuf
									</option>
									<option
										:disabled="!!user.sub_level === 0"
										value="pinel_neuf"
									>
										PINEL dans le neuf
									</option>
									<option
										:disabled="!!user.sub_level === 0"
										value="denormandie"
									>
										DENORMANDIE dans l’ancien
									</option>
									<option
										:disabled="!!user.sub_level === 0"
										value="pinel_ancien"
									>
										PINEL dans l’ancien à réhabiliter
									</option>
									<option
										:disabled="!!user.sub_level === 0"
										value="malraux"
									>
										MALRAUX dans l'ancien
									</option>
								</select>

								<p
									v-show="error.pinel"
									class="mt-2 text-xs text-red"
								>
									L’adresse que vous avez saisi se situe dans
									une ville non éligible au dispositif que
									vous avez sélectionné
								</p>
							</div>
							<!-- FIN TYPE -->
						</div>
					</div>
				</div>
				<div
					v-if="
						typeexploitation === 'pinel_ancien' ||
						typeexploitation === 'pinel_neuf'
					"
					class="flex flex-col mx-auto max-w-5xl"
				>
					<div
						class="relative flex flex-row text-sm place-conten-start col-start-2 col-span-10"
					>
						<input
							id="pinelplus"
							v-model="pinelplus"
							type="checkbox"
							class="mt-1 mr-2 border form-checkbox"
						/>
						Cochez la case ci-contre si le projet est éligible aux
						conditions du "Pinel +" pour maintenir les taux de
						réduction d'impôt de 2022
						<QuestionMarkv2
							class="flex ml-2"
							:text="`${infobulles.pinelplus}`"
						/>
					</div>
				</div>
				<div id="tips" class="flex flex-col mx-auto max-w-5xl">
					<div class="text-xs col-span-10 col-start-2">
						<div
							v-if="
								typeexploitation === 'pinel_ancien' ||
								typeexploitation === 'pinel_neuf' ||
								typeexploitation === 'denormandie' ||
								typeexploitation === 'malraux'
							"
							class="relative px-6 py-4 border-2 rounded-lg tips col-span-6 sm:col-span-4"
						>
							<img
								class="absolute z-0 w-auto max-h-10 ampoule-picto"
								src="../assets/picto/light.png"
								alt=""
							/>

							<p v-if="typeexploitation === 'pinel_ancien'">
								Ce dispositif fiscal permet de bénéficier d’une
								réduction d’impôt en investissant dans un bien
								inhabitable à réhabiliter ou dans un local à
								transformer en habitation. Comme dans le neuf,
								il s’applique dans la limite de 300 000€ ou
								5500€ du m2. Attention, depuis le 1er janvier
								2021, ce logement doit être situé dans un
								immeuble collectif, et non individuel ou
								pavillonaire. Avant travaux, un expert devra
								constater que le logement est bel et bien
								inhabitable. Il repassera à la fin des travaux
								pour s’assurer que l’investisseur l’ait rendu
								habitable avec des travaux respectant certaines
								normes de performances énergétiques.
							</p>

							<p v-if="typeexploitation === 'pinel_neuf'">
								Ce dispositif fiscal permet de bénéficier d’une
								réduction d’impôt en investissant dans
								l’immobilier neuf. Il s’applique dans la limite
								de 300 000€ ou 5500€ du m2. La valeur au delà ne
								sera pas prise en compte pour la réduction
								d’impôts. Pas d’inquiétude, votre simulateur
								Aestima en tiendra compte dans ses calculs.
							</p>

							<div v-if="typeexploitation === 'denormandie'">
								<p>
									Ce dispositif fiscal permet de bénéficier
									d’une réduction d’impôt en investissant dans
									l’immobilier ancien à condition de réaliser
									des travaux à hauteur de 25% du prix de
									revient de l’opération. Ils devront
									comprendre une des trois améliorations
									suivantes:
								</p>
								<ul class="list-disc">
									<li class="mb-3">
										Améliorer la performance énergétique du
										logement de 20 % au moins (30 % au moins
										pour les logements individuels)
									</li>
									<li class="mb-3">
										Changement de chaudière, isolation
										combles, isolation murs, changement
										production d'eau chaude, isolation
										fenêtres.
									</li>
									<li>
										Créer de la surface habitable nouvelle
										(par exemple, balcon, terrasse ou
										garage).
									</li>
								</ul>
							</div>

							<div v-if="typeexploitation === 'malraux'">
								Ce dispositif fiscal permet de bénéficier d’une
								réduction d’impôt en investissant dans
								l’immobilier ancien. Le calcul de la réduction
								se base sur le montant total des travaux (et non
								le coût de revient du projet comme en
								Denormandie ancien par exemple). Il s’applique
								cependant dans la limite de 400 000€ sur 4 ans.
								Si le montant des travaux dépasse cette valeur,
								il ne sera pas pris en compte pour la réduction
								d’impôts. Pas d’inquiétude, votre simulateur
								Aestima en tiendra compte dans ses calculs.
								<b
									>Cette réduction n’est pas soumise au
									plafonnement des niches fiscales.</b
								>
							</div>
						</div>
					</div>
				</div>
				<div class="flex flex-col mx-auto max-w-5xl w-full">
					<div
						class="justify-between col-start-2 col-span-10 grid grid-cols-3 gap-x-6"
					>
						<!-- CATEGORIE -->
						<div class="focus col-span-1">
							<label for="categorie" class="texte-label"
								>Catégorie de bien*</label
							>
							<select
								id="categorie"
								v-model="categcalc"
								required
								class="w-full h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
								name="categorie"
							>
								<option value="appartement" selected>
									Appartement
								</option>
								<option
									v-if="typeexploitation !== 'pinel_neuf'"
									value="maison_individuelle"
								>
									Maison individuelle
								</option>
								<option
									v-if="typeexploitation !== 'pinel_neuf'"
									value="immeuble_residentiel"
								>
									Immeuble résidentiel
								</option>
								<option
									v-if="typeexploitation !== 'pinel_neuf'"
									value="immeuble_de_rapport"
								>
									Immeuble de rapport
								</option>
							</select>
						</div>
						<!-- CATEGORIE TYPE -->

						<!-- NOMBRE DE LOTS -->
						<div
							v-if="
								categcalc === 'immeuble_residentiel' ||
								categcalc === 'immeuble_de_rapport'
							"
							style="max-width: 180px"
							class="col-span-1"
						>
							<label for="nombredelots" class="texte-label"
								>Nombre de lots</label
							>
							<input
								id="nombredelots"
								v-model="immeuble.nombre_lot"
								type="number"
								min="0"
								max="20"
								class="block w-full px-3 py-2 mt-1 border form-input"
								@change="onChangeNombreLots"
							/>
						</div>
						<!-- FIN NOMBRE DE LOTS -->
						<!-- TYPE -->
						<div
							v-if="
								categcalc !== 'immeuble_residentiel' &&
								categcalc !== 'immeuble_de_rapport'
							"
							class="col-span-1"
						>
							<label for="typeBien" class="texte-label"
								>Type de bien</label
							>
							<select
								id="typeBien"
								v-model="typeBien"
								required
								class="w-full h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
								name="typeBien"
							>
								<option disabled value="">
									Choisissez un type d'appartement
								</option>
								<option value="CH">Chambre</option>
								<option value="ST">Studio</option>
								<option value="CO">Colocation</option>
								<option value="T1">T1</option>
								<option value="T2">T2</option>
								<option value="T3">T3</option>
								<option value="T4">T4</option>
								<option value="T5">T5</option>
							</select>
						</div>
						<!-- FIN TYPE -->
						<!-- PHOTO -->
						<div
							v-if="
								![
									'immeuble_de_rapport',
									'immeuble_residentiel',
								].includes(categcalc)
							"
							class="col-span-1 flex justify-start gap-2"
						>
							<label for="photo" class="h-full texte-label">
								Photo principale du bien
								<div
									class="block w-full h-10 pt-2 text-base border cursor-pointer form-input"
									:class="
										!!(
											file.imgPrincipale ||
											imgs.principale
										)
											? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110'
											: 'bg-white'
									"
								>
									{{
										!!(
											file.imgPrincipale ||
											imgs.principale
										)
											? "Photo ajoutée"
											: "Parcourir"
									}}
								</div>
							</label>
							<img
								v-if="!!(file.imgPrincipale || imgs.principale)"
								class="place-self-center w-fit fill-red-500 text-red-500 cursor-pointer mt-4"
								src="../assets/icons/CiCloseCircle.svg"
								@click="deleteImg('principale')"
							/>
							<input
								id="photo"
								name="principale"
								accept="image/jpg, image/jpeg, image/png"
								type="file"
								class="block w-full px-3 py-2 mt-1 border form-input"
								@change="onSelect($event, 'principale')"
							/>
						</div>
						<!-- FIN PHOTO -->
					</div>
				</div>
				<!-- LOTS IMMEUBLE -->
				<div
					v-if="
						categcalc === 'immeuble_residentiel' ||
						categcalc === 'immeuble_de_rapport'
					"
					class="my-4 grid grid-cols-12"
				>
					<div
						class="justify-between col-start-2 col-span-10 grid grid-cols-3 gap-x-6 gap-y-3 font-medium"
					>
						<div
							v-for="lot in immeuble.lots"
							:key="lot.number"
							class="flex flex-col border p-2 rounded-md gap-2 text-xs"
							:class="lot.occupied ? 'bg' : ''"
						>
							<div class="text-center font-bold">
								lot n°{{ lot.number }}
							</div>
							<div class="flex items-center gap-2 relative">
								<div>Surface</div>
								<input
									:id="`lot${lot.number}surface`"
									v-model="lot.surface"
									type="number"
									min="0"
									class="block w-full px-3 py-2 mt-1 border form-input"
									@change="onChangeSurfaceLots"
								/>
								<div class="absolute right-4 top-3 text-2xs">
									m2
								</div>
							</div>
							<select
								:id="`lot${lot.number}typeBien`"
								v-model="lot.typeBien"
								required
								class="w-full h-10 py-0 form-select form-input"
								name="typeBien"
							>
								<option disabled value="x">
									Choisissez un type d'appartement
								</option>
								<option value="CH">Chambre</option>
								<option value="ST">Studio</option>
								<option value="CO">Colocation</option>
								<option value="T1">T1</option>
								<option value="T2">T2</option>
								<option value="T3">T3</option>
								<option value="T4">T4</option>
								<option value="T5">T5</option>
								<option value="LC">Local commercial</option>
								<option value="CV">Cave</option>
								<option value="PK">Parking</option>
							</select>
							<div class="flex items-center gap-2">
								<div>occupé</div>
								<input
									:id="`lot${lot.number}occupied`"
									v-model="lot.occupied"
									type="checkbox"
									class="px-3"
								/>
							</div>
						</div>
					</div>
				</div>
				<!-- FIN LOTS IMMEUBLE -->
				<div class="flex flex-col mx-auto max-w-5xl w-full">
					<div
						class="justify-between col-start-2 col-span-10 grid grid-cols-3 gap-x-6"
					>
						<!-- SURFACE -->
						<div class="flex flex-col justify-between">
							<label for="surface" class="texte-label"
								>Surface habitable<span
									v-if="
										categcalc === 'immeuble_residentiel' ||
										categcalc === 'immeuble_de_rapport'
									"
								>
									totale</span
								>*</label
							>
							<div class="m2-input">
								<input
									id="surface"
									v-model="surface"
									:disabled="
										categcalc === 'immeuble_residentiel' ||
										categcalc === 'immeuble_de_rapport'
									"
									:class="
										categcalc === 'immeuble_residentiel' ||
										categcalc === 'immeuble_de_rapport'
											? 'bg-gray-200'
											: ''
									"
									required
									type="number"
									min="1"
									class="block w-full px-3 py-2 mt-1 border form-input"
								/>
							</div>
						</div>
						<!-- FIN SURFACE -->
						<!-- SURFACES ANNEXES -->
						<div
							v-if="
								typeexploitation === 'pinel_neuf' ||
								typeexploitation === 'pinel_ancien' ||
								typeexploitation === 'denormandie'
							"
							class="col-span-1"
						>
							<div class="flex">
								<p class="mb-1 text-xs font-medium">
									Surfaces annexes*
								</p>
								<QuestionMarkv2
									class="ml-2"
									:text="`${infobulles.surfacesAnnexes}`"
								/>
							</div>
							<a
								class="inline-flex items-center justify-center w-full px-4 py-1 mt-1 text-xs font-medium text-center h-9 bouton"
								@click="showSurfaceModal"
								>Accéder au calcul par type de surface</a
							>
							<Surface
								v-show="surfaceModal"
								:surface="getSurface()"
								@close="closeSurfaceModal"
							/>
						</div>
						<!-- SURFACES fiscale -->
						<div
							v-if="
								typeexploitation === 'pinel_neuf' ||
								typeexploitation === 'pinel_ancien' ||
								typeexploitation === 'denormandie'
							"
						>
							<div
								class="flex items-center self-center justify-center mt-6 pt-auto tips h-9"
							>
								<p class="my-2 text-xs font-medium py-auto">
									Surface fiscale totale
									<b>{{ surfaceFiscale() }} m2</b>
								</p>
								<QuestionMarkv2
									class="float-right ml-2"
									:text="`${infobulles.surfaceFiscale}`"
								/>
							</div>
						</div>
						<!-- FIN SURFACES fiscale -->
						<!-- FIN SURFACES ANNEXES -->
						<!-- ZONAGE MALRAUX -->
						<div
							v-if="typeexploitation === 'malraux'"
							class="flex flex-col justify-between"
						>
							<p class="text-xs font-medium">
								Zonage réglementaire du projet
							</p>
							<select
								id="zonage"
								v-model="zonageMalraux"
								required
								class="h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
								name="zonage"
							>
								<option value="PSMV_QAD">
									Zone SPR avec PSMV et QAD
								</option>
								<option value="PVAP_AVAP/ZPPAUP">
									Zone SPR avec PVAP ou AVAP/ZPPAUP
								</option>
							</select>
						</div>
					</div>
				</div>
				<div
					v-if="typeexploitation === 'malraux'"
					class="relative tips px-5 py-2 text-justify mx-auto max-w-5xl w-full"
				>
					<img
						class="absolute z-0 w-auto max-h-10 ampoule-picto"
						src="../assets/picto/light.png"
						alt=""
					/>
					<p class="text-xs">
						Avec le zonage sélectionné, la réduction d’impôt, basée
						sur le montant total des travaux de restauration, sera
						de
						<b>{{ zonageMalraux === "PSMV_QAD" ? "22" : "30" }}% </b
						>.
					</p>
				</div>
				<!-- FIN ZONAGE MALRAUX -->
				<div class="flex flex-col mx-auto max-w-5xl w-full">
					<div
						class="justify-between col-start-2 col-span-10 grid grid-cols-3 gap-x-6"
					>
						<!-- DOCUMENT ENGAGEMENT -->
						<!-- PAS CONNECTE -->
						<!-- <div class="col-span-1">
              <label for="docEngagement" class="h-full mb-1 texte-label">
                Document d'engagement
                <div class="block w-full h-10 mt-1 border cursor-pointer form-input h-100" />
              </label>
              <input id="docEngagement" ref="file" accept="image/jpg, image/jpeg, image/png" type="file" class="block w-full px-3 py-2 mt-1 border form-input" @change="onSelect">
            </div> -->
						<!-- FIN DOCUMENT ENGAGEMENT -->
						<!-- DUREE ENGAGEMENT -->
						<div
							v-if="
								typeexploitation === 'pinel_neuf' ||
								typeexploitation === 'pinel_ancien' ||
								typeexploitation === 'denormandie'
							"
							class="col-span-1"
						>
							<div class="flex pb-1">
								<label for="typeBien" class="texte-label"
									>Durée d’engagement du dispositif</label
								>
								<QuestionMarkv2
									class="ml-2"
									:text="`${infobulles.dureeEngagement}`"
								/>
							</div>
							<select
								id="engagement"
								v-model.number="engagement"
								required
								class="w-full h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
								name="engagement"
								@change="onChangeEngagement($event)"
							>
								<option disabled value="" />
								<option :value="6">6 ans</option>
								<option :value="9">9 ans</option>
								<option :value="12">12 ans</option>
							</select>
						</div>
						<!-- FIN DUREE ENGAGEMENT -->
						<!-- ZONE -->
						<div
							v-if="
								(typeexploitation === 'pinel_neuf' ||
									typeexploitation === 'pinel_ancien' ||
									typeexploitation === 'denormandie') &&
								zonage
							"
							class="relative px-3 py-4 border-2 rounded-lg tips col-span-1"
						>
							<img
								class="absolute z-0 w-auto max-h-10 ampoule-picto"
								src="../assets/picto/light.png"
								alt=""
							/>
							<div class="flex flex-row justify-between px-2">
								<p
									style="font-size: 12px"
									class="ml-1 text-left col-span-1"
								>
									Le bien se situe en zone
								</p>
								<p class="ml-2 col-span-1">
									<b>{{ zonage }}</b>
								</p>
							</div>
							<!-- FIN ZONE -->
						</div>
					</div>
				</div>
			</div>

			<div id="descriptifDetaille" class="card-no-bot">
				<div
					class="relative flex flex-row justify-between px-4 py-3 bg-white border border-black rounded-lg cursor-pointer"
					@click="isToggle.details = !isToggle.details"
				>
					<h2 class="text-lg font-bold">
						Description détaillée du bien et de son environnement
					</h2>
					<p class="font-semibold text-lightblueaestima-500">
						Cliquer pour afficher
					</p>
					<div>
						<span
							v-if="!isToggle.details"
							class="absolute cursor-pointer right-4"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								x="0px"
								y="0px"
								width="34"
								height="34"
								viewBox="0 0 172 172"
								style="fill: #000000"
							>
								<g
									fill="none"
									fill-rule="nonzero"
									stroke="none"
									stroke-width="1"
									stroke-linecap="butt"
									stroke-linejoin="miter"
									stroke-miterlimit="10"
									stroke-dasharray=""
									stroke-dashoffset="0"
									font-family="none"
									font-weight="inherit"
									font-size="none"
									text-anchor="inherit"
									style="mix-blend-mode: normal"
								>
									<path
										d="M0,172v-172h172v172z"
										fill="none"
									/>
									<g id="Layer_1" fill="#444b54">
										<path
											d="M123.49063,63.02188l-37.49063,37.49063l-37.49062,-37.49062c-1.6125,-1.6125 -4.16562,-1.6125 -5.64375,0c-1.6125,1.6125 -1.6125,4.16562 0,5.64375l40.3125,40.3125c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82187,-1.20938l40.3125,-40.3125c1.6125,-1.6125 1.6125,-4.16563 0,-5.64375c-1.6125,-1.6125 -4.03125,-1.6125 -5.64375,0z"
										/>
									</g>
								</g>
							</svg>
						</span>
						<span v-else class="absolute cursor-pointer right-4">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								x="0px"
								y="0px"
								width="34"
								height="34"
								viewBox="0 0 172 172"
								style="fill: #000000"
							>
								<g
									fill="none"
									fill-rule="nonzero"
									stroke="none"
									stroke-width="1"
									stroke-linecap="butt"
									stroke-linejoin="miter"
									stroke-miterlimit="10"
									stroke-dasharray=""
									stroke-dashoffset="0"
									font-family="none"
									font-weight="inherit"
									font-size="none"
									text-anchor="inherit"
									style="mix-blend-mode: normal"
								>
									<path
										d="M0,172v-172h172v172z"
										fill="none"
									/>
									<g id="Layer_1" fill="#444b54">
										<path
											d="M42.86563,108.97813c1.6125,1.6125 4.16562,1.6125 5.64375,0l37.49063,-37.49062l37.49063,37.49062c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82188,-1.20938c1.6125,-1.6125 1.6125,-4.16562 0,-5.64375l-40.3125,-40.3125c-1.6125,-1.6125 -4.16563,-1.6125 -5.64375,0l-40.3125,40.3125c-1.6125,1.6125 -1.6125,4.03125 0,5.64375z"
										/>
									</g>
								</g>
							</svg>
						</span>
					</div>
				</div>
			</div>

			<div v-show="isToggle.details" class="card-no-top">
				<div class="flex flex-row align-middle card-header">
					<img
						src="../assets/icons/faq13.png"
						class="h-28"
						alt="inscrivez plus de détails"
					/>
					<div class="flex flex-col my-auto ml-5">
						<p class="text-xl">
							Quelles sont les caractéristiques physiques du bien
							?
						</p>
						<p class="text-xl font-bold">
							Descriptif complémentaire sur le bien
						</p>
					</div>
				</div>
				<div class="mt-10 card-body">
					<div class="grid grid-cols-12">
						<p
							style="font-size: 20px"
							class="font-bold col-start-2"
						>
							L'interlocuteur
						</p>
					</div>
					<div class="mt-4 grid grid-cols-12">
						<!-- QUI EST L'INTERLOCUTEUR -->
						<div class="col-span-4 col-start-2">
							<label
								for="interlocuteurwhy"
								class="pb-1 texte-label"
								>Qui est l’interlocuteur pour ce bien</label
							>
							<select
								id="interlocuteurwhy"
								v-model="interlocuteur.why"
								class="w-full h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
								name="interlocuteurwhy"
							>
								<option disabled value="" />
								<option value="proprietaire">
									Propriétaire direct
								</option>
								<option value="agence">
									Agence immobilière
								</option>
								<option value="apporteur">
									Apporteur d'affaires
								</option>
								<option value="autres">autres</option>
							</select>
						</div>
						<!-- FIN QUI EST L'INTERLOCUTEUR -->
						<!-- NOM DE L'INTERLOCUTEUR -->
						<div class="col-span-4 col-start-8">
							<label for="interlocuteurname" class="texte-label"
								>Nom de l’interlocuteur</label
							>
							<input
								id="interlocuteurname"
								v-model="interlocuteur.name"
								type="text"
								class="block w-full px-3 py-2 mt-1 border form-input"
							/>
						</div>
						<!-- FIN NOM DE L'INTERLOCUTEUR -->
					</div>
					<div class="mt-4 grid grid-cols-12">
						<!-- EMAIL DE L'INTERLOCUTEUR -->
						<div class="col-span-4 col-start-2">
							<label for="interlocuteuremail" class="texte-label"
								>E-mail</label
							>
							<input
								id="interlocuteuremail"
								v-model="interlocuteur.email"
								type="email"
								class="block w-full px-3 py-2 mt-1 border form-input"
							/>
						</div>
						<!-- FIN EMAIL DE L'INTERLOCUTEUR -->
						<!-- TELEPHONE DE L'INTERLOCUTEUR -->
						<div class="col-span-4 col-start-8">
							<label for="interlocuteurphone" class="texte-label"
								>Téléphone</label
							>
							<input
								id="interlocuteurphone"
								v-model="interlocuteur.phone"
								type="tel"
								class="block w-full px-3 py-2 mt-1 border form-input"
							/>
						</div>
						<!-- FIN TELEPHONE DE L'INTERLOCUTEUR -->
					</div>
					<div v-if="categ !== 'maison_individuelle'">
						<div class="mt-5 grid grid-cols-12">
							<p
								style="font-size: 20px"
								class="font-bold col-start-2 col-span-5"
							>
								Détails sur l'immeuble
							</p>
						</div>
						<div class="mt-5 grid grid-cols-12">
							<div
								class="flex flex-row justify-between col-start-2 col-span-10"
							>
								<!-- TYPE IMMEUBLE -->
								<div style="width: 500px">
									<label
										for="typeimmeuble"
										class="pb-1 texte-label"
										>Type de l'immeuble</label
									>
									<select
										id="typeimmeuble"
										v-model="immeuble.type"
										class="w-full h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
										name="typeimmeuble"
									>
										<option disabled value="" />
										<option value="recent">Récent</option>
										<option value="ancien">Ancien</option>
										<option value="haussmannien">
											Style Haussmannien
										</option>
										<option value="art_deco">
											Style Art déco
										</option>
										<option value="empire">
											Style Empire
										</option>
										<option value="autres">Autres</option>
									</select>
								</div>
								<!-- FIN TYPE IMMEUBLE -->
								<!-- NOMBRE D'ETAGES -->
								<div style="max-width: 180px" class="mx-3">
									<label
										for="nombredetage"
										class="texte-label"
										>Nombre d'étages</label
									>
									<input
										id="nombredetage"
										v-model="immeuble.nombre_etage"
										type="number"
										min="0"
										class="block w-full px-3 py-2 mt-1 border appearance-none form-input"
									/>
								</div>
								<!-- FIN NOMBRE D'ETAGES -->
								<!-- ETAGE DU BIEN -->
								<div
									v-if="categ === 'appartement'"
									style="max-width: 180px"
									class="mx-3"
								>
									<label for="etagedubien" class="texte-label"
										>Etage du bien</label
									>
									<input
										id="etagedubien"
										v-model="immeuble.etage_bien"
										type="number"
										class="block w-full px-3 py-2 mt-1 border form-input"
									/>
								</div>
								<!-- FIN ETAGE DU BIEN -->
								<!-- TYPE CHAUFFAGE -->
								<div class="mx-3">
									<label
										for="typechauffage"
										class="texte-label"
										>Type de chauffage</label
									>
									<input
										id="typechauffage"
										v-model="immeuble.type_chauffage"
										type="text"
										class="block w-full px-3 py-2 mt-1 border form-input"
									/>
								</div>
								<!-- FIN ETAGE DU BIEN -->
							</div>
						</div>
						<div class="mt-5 grid grid-cols-12">
							<div
								class="flex flex-row justify-between col-start-2 col-span-10"
							>
								<!-- GARDIEN CHECKBOX -->
								<div class="flex flex-row">
									<label
										for="gardien"
										class="mb-6 mr-3 text-center whitespace-no-wrap texte-label"
										>Gardien</label
									>
									<input
										id="gardien"
										v-model="immeuble.option"
										value="Gardien"
										type="checkbox"
										class="w-4 h-4 border-gray-300 rounded focus:ring-lightblueaestima-100 text-lightblueaestima-100"
									/>
								</div>
								<!-- FIN GARDIEN CHECKBOX -->
								<!-- ASCENSEUR CHECKBOX -->
								<div class="flex flex-row">
									<label
										for="ascenseur"
										class="mb-6 mr-3 text-center whitespace-no-wrap texte-label"
										>Ascenseur</label
									>
									<input
										id="ascenseur"
										v-model="immeuble.option"
										value="Ascenseur"
										type="checkbox"
										class="w-4 h-4 border-gray-300 rounded focus:ring-lightblueaestima-100 text-lightblueaestima-100"
									/>
								</div>
								<!-- FIN ASCENSEUR CHECKBOX -->
								<!-- LOCAL VELO CHECKBOX -->
								<div class="flex flex-row">
									<label
										for="local_velos"
										class="mb-6 mr-3 text-center whitespace-no-wrap texte-label"
										>Local vélos</label
									>
									<input
										id="local_velos"
										v-model="immeuble.option"
										value="Local à velos"
										type="checkbox"
										class="w-4 h-4 border-gray-300 rounded focus:ring-lightblueaestima-100 text-lightblueaestima-100"
									/>
								</div>
								<!-- FIN LOCAL VELO CHECKBOX -->
								<!-- LOCAL POUBELLE CHECKBOX -->
								<div class="flex flex-row">
									<label
										for="local_poubelle"
										class="mb-6 mr-3 text-center whitespace-no-wrap texte-label"
										>Local poubelle</label
									>
									<input
										id="local_poubelle"
										v-model="immeuble.option"
										value="Local poubelle"
										type="checkbox"
										class="w-4 h-4 border-gray-300 rounded focus:ring-lightblueaestima-100 text-lightblueaestima-100"
									/>
								</div>
								<!-- FIN LOCAL POUBELLE CHECKBOX -->
								<!-- FIBRE CHECKBOX -->
								<div class="flex flex-row">
									<label
										for="fibre"
										class="mb-6 mr-3 text-center whitespace-no-wrap texte-label"
										>Fibre</label
									>
									<input
										id="fibre"
										v-model="immeuble.option"
										value="Fibre"
										type="checkbox"
										class="w-4 h-4 border-gray-300 rounded focus:ring-lightblueaestima-100 text-lightblueaestima-100"
									/>
								</div>
								<!-- FIN FIBRE CHECKBOX -->
								<!-- ACCES HANDICAPES CHECKBOX -->
								<div class="flex flex-row">
									<label
										for="acces_handicapes"
										class="mb-6 mr-3 text-center whitespace-no-wrap texte-label"
										>Accès handicapés</label
									>
									<input
										id="acces_handicapes"
										v-model="immeuble.option"
										value="Acces handicapés"
										type="checkbox"
										class="w-4 h-4 border-gray-300 rounded focus:ring-lightblueaestima-100 text-lightblueaestima-100"
									/>
								</div>
								<!-- FIN ACCES HANDICAPES CHECKBOX -->
								<!-- DIGICODE CHECKBOX -->
								<div class="flex flex-row">
									<label
										for="digicode"
										class="mb-6 mr-3 text-center whitespace-no-wrap texte-label"
										>Digicode</label
									>
									<input
										id="digicode"
										v-model="immeuble.option"
										value="Digicode"
										type="checkbox"
										class="w-4 h-4 border-gray-300 rounded focus:ring-lightblueaestima-100 text-lightblueaestima-100"
									/>
								</div>
								<!-- FIN DIGICODE CHECKBOX -->
							</div>
						</div>
						<div class="mt-3 grid grid-cols-12">
							<div class="col-start-2 col-span-10">
								<div
									id="sous1"
									class="flex flex-row justify-between mb-4 gap-6"
								>
									<!-- ETAT FACADE -->
									<div
										class="flex flex-row items-center w-1/2"
									>
										<label
											for="etat_facade"
											class="w-1/2 text-xs font-medium"
											>Type et état de la façade</label
										>
										<input
											id="etat_facade"
											v-model="immeuble.etat_facade"
											type="text"
											class="w-1/2 text-xs font-medium border form-input"
										/>
									</div>
									<!-- FIN ETAT FACADE -->
									<!-- INSTALLATION ELECTRIQUE -->
									<div
										class="flex flex-row items-center w-1/2"
									>
										<label
											for="installation_electrique"
											class="w-1/2 text-xs font-medium"
											>Installation électrique</label
										>
										<input
											id="installation_electrique"
											v-model="
												immeuble.installation_electrique
											"
											type="text"
											class="w-1/2 text-xs font-medium border form-input"
										/>
									</div>
									<!-- FIN INSTALLATION ELECTRIQUE -->
								</div>
								<div
									id="sous2"
									class="flex flex-row justify-between mb-4 gap-6"
								>
									<!-- ETAT TOITURE -->
									<div
										class="flex flex-row items-center w-1/2"
									>
										<label
											for="etat_toiture"
											class="w-1/2 text-xs font-medium"
											>Type et état de la toiture</label
										>
										<input
											id="etat_toiture"
											v-model="immeuble.etat_toiture"
											type="text"
											class="w-1/2 text-xs font-medium border form-input"
										/>
									</div>
									<!-- FIN ETAT TOITURE -->
									<!-- INSTALLATION PLOMBERIE -->
									<div
										class="flex flex-row items-center w-1/2"
									>
										<label
											for="installation_plomberie"
											class="w-1/2 text-xs font-medium"
											>Installation plomberie</label
										>
										<input
											id="installation_plomberie"
											v-model="
												immeuble.installation_plomberie
											"
											type="text"
											class="w-1/2 text-xs font-medium border form-input"
										/>
									</div>
									<!-- FIN INSTALLATION PLOMBERIE -->
								</div>
							</div>
						</div>
						<div class="mt-1 grid grid-cols-12">
							<div class="col-start-2 col-span-10">
								<div
									class="flex flex-row justify-between mb-4 gap-6"
								>
									<!-- PHOTO 1 -->
									<div class="flex items-center w-1/2">
										<div class="w-1/2 text-xs font-medium">
											Photo de l’immeuble n°1
										</div>
										<label
											for="photo1"
											class="w-1/2 cursor-pointer form-input"
											:class="
												file.imgPhoto1 || imgs.photo1
													? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110'
													: 'bg-white'
											"
										>
											{{
												file.imgPhoto1 || imgs.photo1
													? "Photo ajoutée"
													: "Parcourir"
											}}
											<input
												id="photo1"
												ref="file"
												name="photo1"
												accept="image/jpg, image/jpeg, image/png"
												type="file"
												class="cursor-pointer"
												@change="
													onSelect($event, 'photo1')
												"
											/>
										</label>
										<img
											v-if="file.imgPhoto1 || imgs.photo1"
											class="place-self-center w-fit fill-red-500 text-red-500 cursor-pointer mt-4"
											src="../assets/icons/CiCloseCircle.svg"
											@click="deleteImg('photo1')"
										/>
									</div>
									<!-- FIN PHOTO 1 -->
									<!-- PHOTO 2 -->
									<div class="flex items-center w-1/2">
										<div class="w-1/2 text-xs font-medium">
											Photo de l’immeuble n°2
										</div>
										<label
											for="photo2"
											class="w-1/2 cursor-pointer form-input"
											:class="
												file.imgPhoto2 || imgs.photo2
													? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110'
													: 'bg-white'
											"
										>
											{{
												file.imgPhoto2 || imgs.photo2
													? "Photo ajoutée"
													: "Parcourir"
											}}
											<input
												id="photo2"
												ref="file"
												name="photo2"
												accept="image/jpg, image/jpeg, image/png"
												type="file"
												:class="
													file.imgPhoto2 ||
													imgs.photo2
														? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110'
														: 'bg-white'
												"
												class="cursor-pointer"
												@change="
													onSelect($event, 'photo2')
												"
											/>
										</label>
										<img
											v-if="file.imgPhoto2 || imgs.photo2"
											class="place-self-center w-fit fill-red-500 text-red-500 cursor-pointer mt-4"
											src="../assets/icons/CiCloseCircle.svg"
											@click="deleteImg('photo2')"
										/>
									</div>
									<!-- FIN PHOTO 2 -->
								</div>
							</div>
						</div>
					</div>
					<div id="Autres-details" class="mt-5 grid grid-cols-12">
						<p
							style="font-size: 20px"
							class="mb-6 font-bold col-start-2 col-span-10"
						>
							Autres détails sur le bien
						</p>
						<div
							class="flex flex-col col-start-2 col-span-10 gap-2"
						>
							<div id="occuDPE" class="flex mb-4 gap-12">
								<!-- Occupation -->
								<div
									v-if="
										typeexploitation !== 'pinel_neuf' &&
										typeexploitation !== 'pinel_ancien' &&
										typeexploitation !== 'denormandie' &&
										typeexploitation !== 'malraux'
									"
									class="flex flex-row w-1/2 gap-2"
								>
									<label
										for="typeBien"
										class="w-1/2 my-auto overflow-x-visible texte-label"
										>Etat d’occupation</label
									>
									<select
										id="occupation"
										v-model="occupation"
										:disabled="
											typeexploitation === 'pinel_neuf' ||
											typeexploitation ===
												'pinel_ancien' ||
											typeexploitation === 'malraux' ||
											typeexploitation === 'denormandie'
										"
										class="w-1/2 h-10 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
										name="occupation"
									>
										<option disabled value="" />
										<option value="libre">Libre</option>
										<option value="occupe">Occupé</option>
									</select>
								</div>
								<!-- FIN Occupation -->
								<!-- DPE -->
								<div
									v-if="
										typeexploitation !== 'pinel_neuf' &&
										typeexploitation !== 'pinel_ancien' &&
										typeexploitation !== 'denormandie' &&
										typeexploitation !== 'malraux'
									"
									class="flex flex-row w-1/2 gap-4"
								>
									<label
										for="dpe"
										class="w-5/6 my-auto texte-label"
										>Diagnostic de performance énergétique
										(DPE) actuel</label
									>
									<input
										id="dpe"
										v-model="dpe"
										:disabled="
											typeexploitation === 'pinel_neuf' ||
											typeexploitation ===
												'pinel_ancien' ||
											typeexploitation === 'malraux' ||
											typeexploitation === 'denormandie'
										"
										type="text"
										class="w-12 px-3 py-2 border form-input"
									/>
								</div>
								<!-- FIN DPE -->
							</div>
							<div
								id="eauMur"
								class="flex flex-row col-start-2 col-span-10 gap-12"
							>
								<!-- EAU -->
								<div class="flex w-1/2 gap-4">
									<label
										for="compteur_eau"
										class="w-1/2 texte-label place-self-center"
										>N° compteur eau</label
									>
									<input
										id="compteur_eau"
										v-model="immeuble.compteur_eau"
										type="text"
										class="w-1/2 px-3 py-2 border form-input"
									/>
								</div>
								<!-- FIN EAU -->
								<!-- REVETEMENT MUR -->
								<div class="flex w-1/2 gap-4">
									<label
										for="revetement_mur"
										class="w-1/2 texte-label place-self-center"
										>Type et état du revêtement mur</label
									>
									<input
										id="revetement_mur"
										v-model="immeuble.revetement_mur"
										type="text"
										class="w-1/2 px-3 py-2 border form-input"
									/>
								</div>
								<!-- FIN REVETEMENT MUR -->
							</div>
							<div
								id="elecSol"
								class="flex col-start-2 col-span-10 gap-12"
							>
								<!-- ELECTRICITE -->
								<div class="flex w-1/2 gap-4">
									<label
										for="compteur_elect"
										class="w-1/2 texte-label place-self-center"
										>N° compteur électricité</label
									>
									<input
										id="compteur_elect"
										v-model="immeuble.compteur_elect"
										type="text"
										class="w-1/2 px-3 py-2 border form-input"
									/>
								</div>
								<!-- FIN ELECTRICITE -->
								<!-- REVETEMENT SOL -->
								<div class="flex w-1/2 gap-4">
									<label
										for="revetement_sol"
										class="w-1/2 texte-label place-self-center"
										>Type et état du revêtement sol</label
									>
									<input
										id="revetement_sol"
										v-model="immeuble.revetement_sol"
										type="text"
										class="w-1/2 px-3 py-2 border form-input"
									/>
								</div>
								<!-- FIN REVETEMENT SOL -->
							</div>
							<div
								id="gazMenuis"
								class="flex col-start-2 col-span-10 gap-12"
							>
								<!-- GAZ -->
								<div class="flex w-1/2 gap-4">
									<label
										for="compteur_gaz"
										class="w-1/2 texte-label place-self-center"
										>N° compteur gaz</label
									>
									<input
										id="compteur_gaz"
										v-model="immeuble.compteur_gaz"
										type="text"
										class="w-1/2 px-3 py-2 border form-input"
									/>
								</div>
								<!-- FIN GAZ -->
								<!-- MENUISERIES -->
								<div class="flex w-1/2 gap-4">
									<label
										for="menuiseries"
										class="w-1/2 texte-label place-self-center"
										>Type et état des menuiseries</label
									>
									<input
										id="menuiseries"
										v-model="immeuble.menuiseries"
										type="text"
										class="w-1/2 px-3 py-2 border form-input"
									/>
								</div>
								<!-- FIN MENUISERIES -->
							</div>
							<div
								class="flex justify-between mt-8 col-start-2 col-span-10 gap-6"
							>
								<!-- INFORMATIONS COMPLEMENTAIRES -->
								<div class="flex flex-col w-1/3 mr-3">
									<label
										for="informations_complementaires"
										class="my-auto mr-2 texte-label"
										>Informations complémentaires</label
									>
									<textarea
										id="informations_complementaires"
										v-model="
											immeuble.informations_complementaires
										"
										rows="6"
										class="block w-full px-3 py-2 mt-4 border form-input"
									/>
								</div>
								<!-- FIN INFORMATIONS COMPLEMENTAIRES -->
								<!-- TRANSPORT -->
								<div class="flex flex-col w-1/3 mr-3">
									<label
										for="transport"
										class="my-auto mr-2 texte-label"
										>Transports</label
									>
									<textarea
										id="transport"
										v-model="immeuble.transport"
										rows="6"
										placeholder="A proximité de la gare. Tramway à quelques pas. Ligne de Bus à 3 minutes à pieds."
										class="block w-full px-3 py-2 mt-4 border form-input"
									/>
								</div>
								<!-- FIN TRANSPORT -->
								<!-- COMMERCES -->
								<div class="flex flex-col w-1/3">
									<label
										for="commerces"
										class="my-auto mr-2 texte-label"
										>Commerces et vie de quartier</label
									>
									<textarea
										id="commerces"
										v-model="immeuble.commerces"
										rows="6"
										placeholder="Quartier bord de mer, très vivant, dynamique avec une majorité d'étudiants et de jeunes actifs."
										class="block w-full px-3 py-2 mt-4 border form-input"
									/>
								</div>
								<!-- FIN COMMERCES -->
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- ACQUISITION CARD -->
			<div id="acquisition" class="card flex flex-col gap-5">
				<div class="flex flex-row align-middle card-header">
					<img
						src="../assets/icons/faq14.png"
						class="h-32"
						alt="l’acquisition du bien"
					/>
					<div class="flex flex-col my-auto ml-5">
						<p ref="prixAchat" class="text-xl">
							Quand et combien le bien sera acheté ?
						</p>
						<p class="text-xl font-bold">Acquisition du bien</p>
					</div>
				</div>
				<div class="card-body">
					<div class="flex flex-col mx-auto w-full max-w-xl">
						<!-- PRIX D'ACHAT MOYEN -->
						<div
							v-if="
								typeexploitation !== 'pinel_neuf' &&
								typeexploitation !== 'classique_neuf' &&
								averagePrice.basAppartement &&
								surface &&
								categcalc !== 'maison_individuelle'
							"
							class="col-start-3 col-span-9"
						>
							<div
								class="relative px-6 py-4 mb-8 border-2 rounded-lg"
							>
								<img
									class="absolute z-0 w-auto max-h-10 ampoule-picto"
									src="../assets/icons/inspector.png"
									alt=""
								/>
								<div class="flex flex-row justify-between">
									<div class="flex flex-col w-4/5">
										<QuestionMarkv2
											class="float-right mr-2"
											:text="`${infobulles.prixAchatMoyenPourXm2}`"
										/>
										<p class="pt-4 font-bold text-md">
											Prix d’achat moyen à
											{{
												loc.carac !== undefined
													? loc.carac.city
													: ""
											}}
										</p>
										<p class="mt-4 text-sm">
											pour
											{{
												categcalc ===
													"immeuble_residentiel" ||
												categcalc ===
													"immeuble_de_rapport"
													? 40
													: surface
											}}
											m2
										</p>
									</div>
									<div class="flex flex-col">
										<img
											style="max-height: 50px"
											src="../assets/progressbar.png"
											alt=""
										/>
										<p class="mt-4 text-right">
											de
											<span class="font-bold"
												>{{
													(
														averagePriceComputed.bas *
														(categcalc ===
															"immeuble_residentiel" ||
														categcalc ===
															"immeuble_de_rapport"
															? 40
															: surface)
													).toLocaleString("fr-FR", {
														minimumFractionDigits: 0,
													})
												}}
												€</span
											>
											à
											<span class="font-bold"
												>{{
													(
														averagePriceComputed.haut *
														(categcalc ===
															"immeuble_residentiel" ||
														categcalc ===
															"immeuble_de_rapport"
															? 40
															: surface)
													).toLocaleString("fr-FR", {
														minimumFractionDigits: 0,
													})
												}}
												€</span
											>
										</p>
									</div>
								</div>
								<div
									class="flex flex-row justify-between mt-1 text-sm italic"
								>
									<p>soit un prix au m2</p>
									<p>
										de
										{{
											averagePriceComputed.bas.toLocaleString(
												"fr-FR",
												{
													minimumFractionDigits: 0,
												}
											)
										}}
										€ à
										{{
											averagePriceComputed.haut.toLocaleString(
												"fr-FR",
												{
													minimumFractionDigits: 0,
												}
											)
										}}
										€
									</p>
								</div>
							</div>
						</div>
					</div>
					<!-- FIN PRIX D'ACHAT MOYEN -->
				</div>
				<div class="flex flex-col mx-auto w-full max-w-xl">
					<!-- PRIX D'ACHAT -->
					<div class="col-start-4 col-span-4">
						<label
							for="prix_dachat"
							class="my-auto mr-2 texte-label"
							>Prix d’achat du bien *</label
						>
						<div class="euro-input">
							<input
								id="prix_dachat"
								v-model="buypricetotcalc"
								required
								type="number"
								step="1"
								min="0"
								class="block w-full px-3 py-2 mt-1 border form-input"
							/>
						</div>
					</div>
					<!-- FIN PRIX D'ACHAT -->
					<!-- TVA -->
					<div
						v-show="
							typeexploitation === 'pinel_neuf' ||
							typeexploitation === 'classique_neuf'
						"
						class="mt-4 col-start-4 col-span-4"
					>
						<label for="tva" class="my-auto mr-2 texte-label"
							>TVA payée à l’achat neuf</label
						>
						<div v-if="tva.toggle" class="flex flex-row">
							<div class="mt-2">
								<span
									class="h-full px-6 mr-4 font-bold cursor-pointer bg-3D-btn"
									@click="tva.toggle = false"
									>€</span
								>
							</div>
							<input
								v-model="tva.val"
								name="tvaInput"
								type="number"
								step="1"
								min="0"
								class="block w-full px-3 py-2 mt-1 border form-input"
								@change="
									tva.val || (tva.val = 0);
									onChangeTvaVal();
								"
							/>
						</div>
						<div v-if="!tva.toggle" class="flex flex-row">
							<div class="mt-2">
								<span
									class="h-full px-6 mr-4 font-bold cursor-pointer bg-3D-btn"
									@click="tva.toggle = true"
									>%</span
								>
							</div>
							<input
								v-model="tva.per"
								type="number"
								step="1"
								min="0"
								class="block w-full px-3 py-2 mt-1 border form-input"
								@change="onChangeTvaPer"
							/>
						</div>
					</div>
					<!-- FIN TVA -->
				</div>
				<div class="flex flex-col mx-auto w-full max-w-xl">
					<!-- FRAIS NOTAIRE -->

					<div class="mt-4 col-start-4 col-span-4">
						<div class="flex flex-row">
							<label
								for="frais_notaire"
								class="my-auto mr-2 texte-label"
								>Frais de notaires *</label
							>
							<QuestionMarkv2
								:text="`${infobulles.fraisNotaires}`"
							/>
							<QuestionMarkv2
								:text="fraisNotaireComputedInfos"
								:calculatrice="true"
							/>
						</div>
						<div class="flex flex-row">
							<div class="mt-2">
								<span
									v-if="fnotaire.fnotaireEuro"
									class="h-full px-6 mr-4 font-bold cursor-pointer bg-3D-btn"
									@click="
										onChangeSigleAchatBienBtn(
											'fnotaire',
											false
										)
									"
									>€</span
								>
								<span
									v-else-if="!fnotaire.fnotaireEuro"
									class="h-full px-6 mr-4 font-bold cursor-pointer bg-3D-btn"
									@click="
										onChangeSigleAchatBienBtn(
											'fnotaire',
											true
										)
									"
									>%</span
								>
							</div>
							<input
								id="frais_notaire"
								v-model="onChangeSigleFnotaireComputed"
								type="number"
								step="1"
								min="0"
								class="block w-full px-3 py-2 mt-1 border form-input"
							/>
						</div>
					</div>
					<!-- FIN FRAIS NOTAIRE -->
					<!-- HONORAIRE -->
					<div class="mt-4 col-start-4 col-span-4">
						<div class="flex flex-row">
							<label
								for="honoraire"
								class="my-auto mr-2 texte-label"
								>Honoraires du conseiller</label
							>
							<QuestionMarkv2
								:text="`${infobulles.honoraireConseiller}`"
							/>
						</div>
						<div class="flex flex-row">
							<div class="mt-2">
								<span
									v-if="fagence.fagenceEuro"
									class="h-full px-6 mt-2 mr-4 font-bold cursor-pointer bg-3D-btn"
									@click="
										onChangeSigleAchatBienBtn(
											'fagence',
											false
										)
									"
									>€</span
								>
								<span
									v-else-if="!fagence.fagenceEuro"
									class="h-full px-6 mt-2 mr-4 font-bold cursor-pointer bg-3D-btn"
									@click="
										onChangeSigleAchatBienBtn(
											'fagence',
											true
										)
									"
									>%</span
								>
							</div>
							<input
								id="honoraire"
								v-model="onChangeSigleFagenceComputed"
								name="honoraireInput"
								type="number"
								step="1"
								min="0"
								class="block w-full px-3 py-2 mt-1 border form-input"
							/>
						</div>
					</div>
					<!-- FIN HONORAIRE -->
				</div>

				<!-- COUT D'ACQUISITION -->
				<div
					class="flex flex-col w-full max-w-xl px-5 py-2 relative border-2 rounded-lg tips place-self-center"
				>
					<img
						class="absolute z-0 w-auto max-h-10 ampoule-picto"
						src="../assets/picto/light.png"
						alt=""
					/>
					<div class="flex flex-row justify-between">
						<p>Coût d’acquisition total du bien</p>
						<p class="font-bold text-right">
							{{
								coutacqui.toLocaleString("fr-FR", {
									minimumFractionDigits: 0,
								})
							}}
							€
						</p>
					</div>
				</div>
				<!-- FIN COUT D'ACQUISITION -->
				<!-- DATE D'ACQUISITION -->
				<div class="flex flex-col mx-auto max-w-xl">
					<div
						v-if="
							typeexploitation === 'pinel_neuf' ||
							typeexploitation === 'classique_neuf'
						"
						class="col-start-4 col-span-4"
					>
						<label
							for="datesignature"
							class="my-auto mr-2 texte-label"
							>Date de signature du contrat de réservation</label
						>

						<input
							v-model="datesignature"
							required
							type="month"
							max="2050-12"
							class="block w-full px-3 py-2 mt-1 border form-input"
						/>
					</div>
				</div>

				<!-- FIN DATE D'ACQUISITION -->
				<!-- DATE D'ACQUISITION -->
				<div class="flex flex-col mx-auto w-full max-w-xl">
					<div class="col-start-4 col-span-4">
						<label
							v-if="
								typeexploitation !== 'pinel_neuf' &&
								typeexploitation !== 'classique_neuf'
							"
							for="dateacqui"
							class="my-auto mr-2 texte-label"
							>Date d’acquisition du bien</label
						>

						<label
							v-else
							ref="signature"
							for="dateacqui"
							class="my-auto mr-2 texte-label"
							>Date de signature de l'acte authentique chez le
							notaire</label
						>
						<input
							id="dateacqui"
							v-model="dateacqui"
							required
							type="month"
							max="2050-12"
							class="block w-full px-3 py-2 mt-1 border form-input"
							@change="onChangeDateAcqui"
						/>
					</div>
				</div>

				<!-- FIN DATE D'ACQUISITION -->
				<div
					v-if="
						typeexploitation === 'classique_neuf' ||
						typeexploitation === 'pinel_neuf' ||
						typeexploitation === 'denormandie' ||
						typeexploitation === 'pinel_ancien' ||
						typeexploitation === 'malraux'
					"
					class="flex flex-col mx-auto w-full max-w-xl"
				>
					<div class="col-start-4 col-span-4">
						<QuestionMarkv2
							class="float-right ml-2"
							:text="`${infobulles.dateAchevementTravauxMiseLocation}`"
						/>
						<label
							for="achevementtravaux"
							class="my-auto texte-label"
							>Date d’achèvement des travaux et mise en
							location</label
						>
						<input
							id="achevementtravauxInput"
							v-model="achevementtravaux"
							required
							type="month"
							:min="dateacqui"
							:max="datePlus36"
							class="block w-full px-3 py-2 mt-1 border form-input"
						/>
					</div>
					<!-- REPARTITION DU PAIEMENT -->
					<div
						v-if="
							typeexploitation === 'classique_neuf' ||
							typeexploitation === 'pinel_neuf'
						"
						class="col-span-4 col-start-8"
					>
						<div class="flex flex-row justify-between">
							<a
								ref="adf"
								class="cursor-pointer inline-flex items-center justify-center px-4 py-1 mt-4 mb-2 ml-8 text-xs font-medium text-center leading-5 bouton"
								@click="showAppelDeFondModal"
								>Accéder à la répartition ventilée des appels de
								fonds du constructeur</a
							>
							<AppelDeFond
								v-show="appelDeFondModal"
								:tva="Number(tva.val)"
								:new-project="!projectSelected"
								:price="buyprice"
								:appel-de-fond="appelDeFond"
								:achevementtravaux="achevementtravaux"
								:dateacqui="dateacqui"
								@close="closeAppelDeFondModal"
							/>
						</div>
					</div>
					<!-- FIN REPARTITION DU PAIEMENT -->
				</div>
				<!-- FIN DATE ACHEVEMENT DES TRAVAUX -->
			</div>

			<div id="travaux" class="card flex flex-col gap-5">
				<div class="flex flex-row align-middle card-header">
					<div class="card-body" />
					<img
						src="../assets/icons/scenario.png"
						class="h-28"
						alt="Estimation des travaux"
					/>
					<div class="flex flex-col my-auto ml-5">
						<p class="text-xl">
							Combien de temps le bien sera détenu ?
						</p>
						<p class="text-xl font-bold">Scénario de revente</p>
					</div>
				</div>
				<div class="flex flex-col gap-4 mt-10 mx-auto w-full max-w-xl">
					<!-- DUREE DE DETENTION -->
					<div class="flex flex-col">
						<label for="dureedetention" class="texte-label"
							>Durée de détention du bien (en années)</label
						>
						<input
							id="dureedetention"
							v-model="dureedetent"
							type="range"
							:min="dureeDetentionMin"
							max="30"
							step="1"
							class="slider w-full"
						/>
					</div>
					<div>{{ dureedetention.val }} ans</div>
					<!-- FIN DUREE DE DETENTION -->
					<div
						v-if="typeexploitation === 'malraux'"
						class="relative text-xs tips w-full text-justify px-6 py-2 mt-3"
					>
						<img
							class="absolute z-0 w-auto max-h-10 ampoule-picto"
							src="../assets/picto/light.png"
							alt=""
						/>
						<p>
							La durée de détention mimimale est bloquée à 9 ans
							pour respecter l’obligation de location de 9 ans
							pour l’obtention de la réduction d’impôts du
							dispositif MALRAUX.
						</p>
					</div>
					<!-- DATE DE REVENTE -->
					<div class="flex flex-row flex-start">
						<label
							for="daterevente"
							class="inline-block my-auto text-sm font-medium text-gray-700 whitespace-nowrap"
							>Date de revente</label
						>
						<input
							id="daterevente"
							v-model="datereventecal"
							type="month"
							step="any"
							class="block px-3 py-2 font-medium sm:text-sm focus:border-none focus:outline-none transition duration-150 ease-in-out sm:leading-5"
							readonly
						/>
					</div>
					<!-- FIN DATE DE REVENTE -->
					<!-- ESTIMATION DE REVALORISATION -->
					<div>
						<div class="flex flex-row">
							<label
								for="estimationrevalcal"
								class="mt-3 texte-label"
							>
								Estimation de revalorisation annuelle du marché
								immobilier
							</label>
							<QuestionMarkv2
								class="mt-2 ml-1"
								:text="`${infobulles.estimationRevente}`"
							/>
						</div>
					</div>
					<div class="percent-input w-56">
						<input
							id="estimationrevalcal"
							v-model="estimationrevalcal"
							type="number"
							step="0.01"
							class="block px-3 py-2 border form-input"
						/>
					</div>
					<!-- FIN ESTIMATION DE REVALORISATION -->
				</div>

				<div class="flex flex-col mx-auto w-full max-w-xl">
					<!-- REVALORISATION ANNUELLE -->
					<div v-if="evolution.unAn" class="col-start-4 col-span-7">
						<div class="relative px-4 py-4 border-2 rounded-lg">
							<img
								class="absolute z-0 w-auto max-h-10 ampoule-picto"
								src="../assets/icons/inspector.png"
								alt=""
							/>

							<div
								class="flex flex-row justify-end mt-2 text-sm gap-2"
							>
								<div class="flex flex-row">
									<p class="my-auto mr-1">
										Evolution des prix immobiliers
										{{ `à ${loc.carac.city}` }} sur 12 mois
									</p>
									<QuestionMarkv2
										class="float-right ml-1"
										:text="`${infobulles.revalorisationAnnuelleMoyenne}`"
									/>
								</div>
								<img
									v-if="evolution.unAn < 0"
									src="../assets/icons/regress.png"
									class="my-auto"
									style="height: 25px"
									alt=""
								/>
								<img
									v-else
									src="../assets/icons/progress.png"
									class="my-auto"
									style="height: 25px"
									alt=""
								/>
								<div class="flex flex-row">
									<p
										class="my-auto font-bold"
										:class="{
											'text-red-600': evolution.unAn < 0,
											'text-green-600':
												evolution.unAn >= 0,
										}"
									>
										{{
											evolution.unAn >= 0
												? `+${evolution.unAn}`
												: evolution.unAn
										}}
										%
									</p>
								</div>
							</div>
							<div
								class="flex flex-row justify-end mt-2 text-sm gap-2"
							>
								<div class="flex flex-row">
									<p class="my-auto mr-1">
										Evolution des prix immobiliers
										{{ `à ${loc.carac.city}` }} sur 5 ans
									</p>
								</div>
								<img
									v-if="evolution.cinqAns < 0"
									src="../assets/icons/conseil.png"
									class="my-auto"
									style="height: 25px"
								/>
								<img
									v-else
									src="../assets/icons/progress.png"
									class="my-auto"
									style="height: 25px"
								/>
								<div class="flex flex-row">
									<p
										class="my-auto font-bold"
										:class="{
											'text-red-600':
												evolution.cinqAns < 0,
											'text-green-600':
												evolution.cinqAns >= 0,
										}"
									>
										{{
											evolution.cinqAns >= 0
												? `+${evolution.cinqAns}`
												: evolution.cinqAns
										}}
										%
									</p>
								</div>
							</div>
						</div>
					</div>
					<!-- FIN REVALORISATION ANNUELLE -->
					<!-- ESTIMATION REVENTE -->
					<div
						class="relative text-right border-2 rounded-lg tips px-5 py-2"
					>
						<img
							class="absolute z-0 w-auto max-h-10 ampoule-picto"
							src="../assets/picto/light.png"
							alt=""
						/>
						<div class="font-bold grid grid-cols-6">
							<p class="col-span-4">
								Estimation du prix de revente net
							</p>
							<p class="my-auto col-start-6">
								{{ estimationprixreventecal }} €
							</p>
						</div>
						<div class="italic grid grid-cols-6">
							<p class="col-span-4">Soit une plus-value de</p>
							<p class="my-auto col-start-6">
								{{ plusvaluecal }} €
							</p>
						</div>
						<div
							v-if="augmvaleurcal"
							class="italic grid grid-cols-6"
						>
							<p class="col-span-4">
								Soit une augmentation de sa valeur de
							</p>
							<p class="my-auto col-start-6">
								{{ augmvaleurcal }} %
							</p>
						</div>
					</div>
				</div>

				<!-- FIN ESTIMATION REVENTE -->
			</div>

			<div id="travaux2" class="card">
				<div class="flex flex-row align-middle card-header">
					<img
						src="../assets/icons/faq16.png"
						class="h-32"
						alt="Estimation des travaux"
					/>
					<div class="flex flex-col my-auto ml-5">
						<p class="text-xl">Des travaux sont-ils à prévoir ?</p>
						<p class="text-xl font-bold">
							Estimation et répartition des travaux
						</p>
					</div>
				</div>
				<div class="card-body">
					<div class="grid grid-cols-12">
						<!-- REPARTITION BOUTON -->
						<div class="col-span-7 col-start-5">
							<a
								class="inline-flex items-center justify-center px-4 py-2 text-xs font-medium text-center cursor-pointer leading-6 bouton"
								@click="showTravauxModal"
								>Accéder au tableau de répartition des
								travaux</a
							>
							<Travaux
								v-show="travauxModal"
								:key="dureedetent"
								:travaux-reno="travauxReno"
								:travaux-constru="travauxConstru"
								:typeexploitation="typeexploitation"
								:annee-travaux="getAnneeTravaux"
								:duree="dureedetent"
								@close="closeTravauxModal"
							/>
						</div>
						<!-- FIN REPARTITION BOUTON -->

						<div
							class="my-auto mt-8 mr-2 col-start-3 col-span-8 texte-label"
						>
							<div class="flex flex-row">
								Montant des travaux d’entretien et de rénovation
								à réaliser
								<QuestionMarkv2
									class="ml-2"
									:text="`${infobulles.travauxRenovationEntretien}`"
								/>
							</div>
						</div>
						<p
							class="my-auto mt-8 mr-2 font-bold col-start-11 col-span-1 texte-label"
						>
							{{
								trvame.toLocaleString("fr-FR", {
									minimumFractionDigits: 0,
								})
							}}
							€
						</p>
						<div
							class="my-auto mt-4 mr-2 col-start-3 col-span-8 texte-label"
						>
							<div class="flex flex-row">
								Montant des travaux de construction ou
								d’agrandissement à réaliser
								<QuestionMarkv2
									class="ml-2"
									:text="`${infobulles.travauxConstruction}`"
								/>
							</div>
						</div>
						<p
							class="my-auto mt-4 mr-2 font-bold col-start-11 col-span-1 texte-label"
						>
							{{
								trconstruction.toLocaleString("fr-FR", {
									minimumFractionDigits: 0,
								})
							}}
							€
						</p>

						<!-- MONTANT TOTAL DES TRAVAUX -->
						<div
							class="relative w-3/4 px-24 py-4 mx-auto my-10 text-sm border-2 rounded-lg tips col-span-12"
						>
							<img
								class="absolute z-0 w-auto max-h-10 ampoule-picto"
								src="../assets/picto/light.png"
								alt=""
							/>
							<div
								id="coutRevient"
								ref="coutRevient"
								class="flex flex-row justify-between mb-1"
							>
								<p>Montant total des travaux</p>
								<div class="flex gap-4">
									<p class="font-bold">
										{{ getTravauxTotaux() }} €
									</p>
									<p>soit</p>
									<p class="font-bold">
										{{ getTravauxM2() }} € / m2
									</p>
								</div>
							</div>
							<div
								v-if="
									[
										'pinel_ancien',
										'denormandie',
										'malraux',
									].includes(typeexploitation)
								"
								class="flex flex-row justify-between"
							>
								<p>
									Coût de revient total du projet

									<QuestionMarkv2
										class="float-right ml-2"
										:text="`${infobulles.coutRevientTotal}`"
									/>
								</p>
								<p class="font-bold">
									{{
										getCoutRevientTotal().toLocaleString(
											"FR-fr"
										)
									}}
									€
								</p>
							</div>
							<div
								v-if="
									['denormandie'].includes(typeexploitation)
								"
								class="flex flex-row justify-between mt-1"
							>
								<p>
									Pourcentage des travaux par rapport au coût
									de revient
								</p>
								<p class="font-bold">{{ prctCoutRevient }}%</p>
							</div>
						</div>
						<!-- FIN MONTANT TOTAL DES TRAVAUX -->
					</div>
				</div>
			</div>

			<div id="revenus" class="card">
				<div
					ref="loyermensuel"
					class="flex flex-row align-middle card-header"
				>
					<img
						src="../assets/icons/faq15.png"
						class="h-32"
						alt="Revenus du bien"
					/>
					<div class="flex flex-col my-auto ml-5">
						<p class="text-xl">
							Quels sont les revenus potentiels du bien ?
						</p>
						<p class="text-xl font-bold">
							Revenus du bien et variations
						</p>
					</div>
				</div>
				<div class="mt-10 card-body">
					<div class="grid grid-cols-12">
						<!-- LOYER MENSUEL -->
						<div
							v-if="
								!(
									categcalc === 'immeuble_residentiel' ||
									categcalc === 'immeuble_de_rapport'
								)
							"
							class="flex flex-row justify-start mb-6 gap-6 col-span-8 col-start-3"
						>
							<div class="w-2/5">
								<div class="flex flex-row">
									<label
										for="dureedetention"
										class="mr-2 texte-label"
										>Loyer mensuel praticable</label
									>
									<QuestionMarkv2
										v-if="
											typeexploitation === 'pinel_neuf' ||
											typeexploitation ===
												'pinel_ancien' ||
											typeexploitation ===
												'denormandie' ||
											typeexploitation === 'malraux'
										"
										class="float-left"
										:text="`${infobulles.loyerMensuelPraticable}`"
									/>
								</div>
								<p
									class="text-xs italic font-medium text-gray-700"
								>
									(valeur hors charges)*
								</p>
							</div>
							<div class="flex w-2/5">
								<div class="flex w-2/5 mb-2 euro-input">
									<input
										id="loyermensuel"
										v-model.number="loyermensuel"
										required
										type="number"
										step="1"
										max="50000"
										min="0"
										class="w-full px-3 py-2 border form-input place-items-start place-content-start place-self-start"
										@change="onChangeLoyerMoyen"
									/>
								</div>
							</div>
						</div>
						<!-- FIN LOYER MENSUEL -->

						<!-- LOTS IMMEUBLE -->
						<div
							v-if="
								categcalc === 'immeuble_residentiel' ||
								categcalc === 'immeuble_de_rapport'
							"
							class="col-start-3 col-span-8 flex flex-wrap gap-3 justify-center my-7"
						>
							<div
								v-for="lot in immeuble.lots"
								:key="lot.number"
								class="flex flex-col texte-label border p-2 rounded-md w-32"
								:class="lot.occupied ? 'bg' : ''"
							>
								<div class="font-bold">
									lot n°{{ lot.number }}
								</div>
								<div class="flex items-center gap-2">
									<div>Surface {{ lot.surface }}m2</div>
								</div>
								<div
									class="flex items-center gap-2"
									:class="lot.occupied ? 'font-bold' : ''"
								>
									<div>
										{{ lot.occupied ? "Occupé" : "Vacant" }}
									</div>
								</div>

								<div class="flex items-center gap-2 relative">
									<div>Loyer</div>
									<input
										:id="`lot${lot.number}loyer`"
										v-model="lot.loyer"
										type="number"
										min="0"
										class="block w-full px-3 py-2 mt-1 border form-input"
										@change="onChangeLoyerLot"
									/>
									<div class="absolute right-4 top-3">€</div>
								</div>
							</div>
						</div>
						<!-- FIN LOTS IMMEUBLE -->

						<!-- LOYER MOYEN -->
						<div
							v-if="
								typeexploitation !== 'pinel_neuf' &&
								typeexploitation !== 'pinel_ancien' &&
								typeexploitation !== 'malraux' &&
								typeexploitation !== 'denormandie' &&
								projectSelected === null
							"
							class="text-sm col-start-3 col-span-8"
						>
							<div
								class="relative px-6 py-4 my-10 border-2 rounded-lg"
							>
								<img
									class="absolute z-0 w-auto max-h-10 ampoule-picto"
									src="../assets/icons/inspector.png"
									alt=""
								/>
								<div class="flex flex-row justify-between">
									<div class="flex flex-col">
										<p class="font-bold">
											Loyer moyen pour {{ surface }}m2
										</p>
										<p v-if="loc.carac">
											à
											{{ loc.carac.city }}
										</p>
									</div>
									<div class="flex flex-col">
										<img
											style="max-height: 50px"
											src="../assets/progressbar.png"
											alt=""
										/>
									</div>
								</div>
								<div class="flex flex-row justify-between mt-5">
									<p>En location nue</p>
									<p>
										de
										<span class="font-bold">{{
											loyerMoyen.max === 0
												? "Inconnu"
												: surface < 50
												? `${Math.round(
														loyerMoyen.max * 0.95
												  )} €`
												: `${Math.round(
														loyerMoyen.max * 0.7
												  )} €`
										}}</span>
										à
										<span class="font-bold">{{
											loyerMoyen.max === 0
												? "Inconnu"
												: surface < 50
												? `${Math.round(
														loyerMoyen.max * 1.05
												  )} €`
												: `${Math.round(
														loyerMoyen.max * 0.8
												  )} €`
										}}</span>
									</p>
								</div>
								<div class="flex flex-row justify-between mt-2">
									<p>En location meublée</p>
									<p>
										de
										<span class="font-bold">{{
											loyerMoyen.max === 0
												? "Inconnu"
												: surface < 50
												? `${Math.round(
														loyerMoyen.max * 1.15
												  )} €`
												: `${Math.round(
														loyerMoyen.max * 0.8
												  )} €`
										}}</span>
										à
										<span class="font-bold">{{
											loyerMoyen.max === 0
												? "Inconnu"
												: surface < 50
												? `${Math.round(
														loyerMoyen.max * 1.2
												  )} €`
												: `${Math.round(
														loyerMoyen.max * 0.85
												  )} €`
										}}</span>
									</p>
								</div>
							</div>
						</div>
						<!-- FIN LOYER MOYEN -->
						<!-- TIPS RENDEMENT -->
						<div
							class="relative w-full px-8 py-4 mx-auto text-right border-2 rounded-lg tips col-start-3 col-span-8"
						>
							<img
								class="absolute z-0 w-auto max-h-10 ampoule-picto"
								src="../assets/picto/light.png"
								alt=""
							/>
							<div
								v-if="
									typeexploitation === 'pinel_neuf' ||
									typeexploitation === 'pinel_ancien' ||
									typeexploitation === 'malraux' ||
									typeexploitation === 'denormandie'
								"
								class="flex flex-row justify-between mb-5"
							>
								<div
									class="flex flex-row justify-between w-full"
									v-if="typeexploitation !== 'malraux'"
								>
									<div class="text-left">
										Loyer maximum praticable avec votre
										dispositif pour
										{{
											Number.parseFloat(surface) +
											Number.parseFloat(surfaceannexe)
										}}
										m²
										<p v-if="loc">à {{ loc.carac.city }}</p>
									</div>
									<div class="flex flex-row">
										<QuestionMarkv2
											v-if="
												typeexploitation ===
													'pinel_neuf' ||
												typeexploitation ===
													'pinel_ancien' ||
												typeexploitation ===
													'denormandie'
											"
											class="float-left"
											:text="`${infobulles.loyersMaximumPraticablePINELouDENORMANDIE}`"
										/>
										<QuestionMarkv2
											v-else
											class="float-left"
											:text="`${infobulles.loyerMaximumPraticable}`"
										/>
										<p class="w-20 my-auto font-bold">
											{{ loyerMaximumPraticableComputed }}
											€
										</p>
									</div>
								</div>
							</div>
							<div class="flex flex-row justify-between">
								<p class="font-bold">
									Revenus locatifs annuels
								</p>
								<p class="my-auto font-bold">
									{{
										revenulocatif.toLocaleString("fr-FR", {
											minimumFractionDigits: 0,
										})
									}}
									€
								</p>
							</div>
							<div
								class="flex flex-row justify-between w-full mt-5"
							>
								<p class="col-span-2">
									Rendement brut de cet investissement
								</p>
								<div class="flex flex-row">
									<QuestionMarkv2
										:text="`${infobulles.rendementBrut}`"
									/>
									<p class="w-20 my-auto font-bold">
										{{ rendementbrutcalc }} %
									</p>
								</div>
							</div>
						</div>
					</div>
					<!-- FIN TIPS RENDEMENT -->
					<div class="mt-5 grid grid-cols-12">
						<!-- DATE ACHEVEMENT DES TRAVAUX -->

						<!-- PERSONNALISATION -->
						<div class="mt-10 col-span-10 col-start-2">
							<div
								class="relative flex flex-row justify-between px-4 py-2 bg-white border border-black rounded-lg cursor-pointer"
								@click="
									isToggle.detailsRevenusLocatif =
										!isToggle.detailsRevenusLocatif
								"
							>
								<h2 class="text-normal">
									Personnaliser le détail et variations des
									revenus locatifs
								</h2>
								<div>
									<span
										v-if="!isToggle.detailsRevenusLocatif"
										class="absolute cursor-pointer right-4"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											width="34"
											height="34"
											viewBox="0 0 172 172"
											style="fill: #000000"
										>
											<g
												fill="none"
												fill-rule="nonzero"
												stroke="none"
												stroke-width="1"
												stroke-linecap="butt"
												stroke-linejoin="miter"
												stroke-miterlimit="10"
												stroke-dasharray=""
												stroke-dashoffset="0"
												font-family="none"
												font-weight="inherit"
												font-size="none"
												text-anchor="inherit"
												style="mix-blend-mode: normal"
											>
												<path
													d="M0,172v-172h172v172z"
													fill="none"
												/>
												<g id="Layer_1" fill="#444b54">
													<path
														d="M123.49063,63.02188l-37.49063,37.49063l-37.49062,-37.49062c-1.6125,-1.6125 -4.16562,-1.6125 -5.64375,0c-1.6125,1.6125 -1.6125,4.16562 0,5.64375l40.3125,40.3125c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82187,-1.20938l40.3125,-40.3125c1.6125,-1.6125 1.6125,-4.16563 0,-5.64375c-1.6125,-1.6125 -4.03125,-1.6125 -5.64375,0z"
													/>
												</g>
											</g>
										</svg>
									</span>
									<span
										v-else
										class="absolute cursor-pointer right-4"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											width="34"
											height="34"
											viewBox="0 0 172 172"
											style="fill: #000000"
										>
											<g
												fill="none"
												fill-rule="nonzero"
												stroke="none"
												stroke-width="1"
												stroke-linecap="butt"
												stroke-linejoin="miter"
												stroke-miterlimit="10"
												stroke-dasharray=""
												stroke-dashoffset="0"
												font-family="none"
												font-weight="inherit"
												font-size="none"
												text-anchor="inherit"
												style="mix-blend-mode: normal"
											>
												<path
													d="M0,172v-172h172v172z"
													fill="none"
												/>
												<g id="Layer_1" fill="#444b54">
													<path
														d="M42.86563,108.97813c1.6125,1.6125 4.16562,1.6125 5.64375,0l37.49063,-37.49062l37.49063,37.49062c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82188,-1.20938c1.6125,-1.6125 1.6125,-4.16562 0,-5.64375l-40.3125,-40.3125c-1.6125,-1.6125 -4.16563,-1.6125 -5.64375,0l-40.3125,40.3125c-1.6125,1.6125 -1.6125,4.03125 0,5.64375z"
													/>
												</g>
											</g>
										</svg>
									</span>
								</div>
							</div>
						</div>
						<!-- FIN PERSONNALISATION -->
						<div
							v-if="isToggle.detailsRevenusLocatif"
							class="mt-10 col-span-10 col-start-2"
						>
							<div
								v-if="typeexploitation === 'classique_ancien'"
								class="mt-5 col-span-10 col-start-2"
							>
								<label
									for="differeDeMiseEnLocation"
									class="mb-2 texte-label"
								>
									<QuestionMarkv2
										class="float-left mr-2"
										:text="`${infobulles.differeMiseLocationPremiereAnnee}`"
									/>
									Différé de mise en location la 1ère année
									(en nombre de mois)
								</label>
								<div
									class="flex flex-row items-center whitespace-no-wrap"
								>
									<input
										id="differeDeMiseEnLocation"
										v-model="differeDeMiseEnLocation"
										type="range"
										min="0"
										max="12"
										step="1"
										class="slider"
									/>
									<div class="ml-4">
										{{ differeDeMiseEnLocation }}
									</div>
								</div>
							</div>
							<div class="mt-5 col-span-10 col-start-2">
								<QuestionMarkv2
									class="float-left mr-2"
									:text="`${infobulles.IRL}`"
								/>
								<label
									for="trevalorisation"
									class="mb-2 texte-label"
									>Taux revalorisation (IRL) annuel</label
								>
								<div
									class="flex flex-row items-center whitespace-no-wrap"
								>
									<input
										id="differeDeMiseEnLocation"
										v-model="trevalorisation.per"
										type="range"
										min="-1"
										max="7"
										step="0.20"
										class="slider"
									/>
									<div class="ml-4">
										{{ trevalorisation.per }} %
									</div>
								</div>
							</div>

							<div class="mt-5 col-span-10 col-start-2">
								<QuestionMarkv2
									class="float-left mr-2"
									:text="`${infobulles.vacanceLocative}`"
								/>
								<label
									for="rvacanceloc"
									class="mb-2 texte-label"
									>Risque de vacance locative en nombres de
									jours de loyers (par an)*</label
								>
								<div
									class="flex flex-row items-center whitespace-no-wrap"
								>
									<input
										id="rvacanceloc"
										v-model="rvacancelocComputed"
										type="range"
										min="0"
										max="60"
										step="1"
										class="slider"
									/>
									<div class="ml-4">
										{{ rvacancelocComputed }}
									</div>
								</div>
							</div>
							<div class="mt-5 col-span-10 col-start-2">
								<QuestionMarkv2
									class="float-left mr-2"
									:text="`${infobulles.tauxActualisation}`"
								/>
								<label
									for="tauxActualisationRetenu"
									class="mb-2 texte-label"
									>Taux d’actualisation retenu</label
								>
								<div
									class="flex flex-row items-center whitespace-no-wrap"
								>
									<input
										id="tauxActualisationRetenu"
										v-model="tactualisation.per"
										type="range"
										min="-2"
										max="20"
										step="0.2"
										class="slider"
									/>
									<div class="ml-4">
										{{ tactualisation.per }} %
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="chargesLocatives" class="card">
				<div class="flex flex-row align-middle card-header">
					<img
						src="../assets/icons/faq17.png"
						class="h-28"
						alt="Revenus du bien"
					/>
					<div class="flex flex-col my-auto ml-5">
						<p class="text-xl">
							Quelles sont les charges relatives à la gestion du
							bien ?
						</p>
						<p class="text-xl font-bold">
							Charges locatives annuelles
						</p>
					</div>
				</div>
				<div class="mt-10 card-body">
					<div class="grid grid-cols-12">
						<!-- ESTIMATION DES CHARGES LOCATIVES -->
						<div
							class="relative w-full px-8 py-4 mx-auto text-right border-2 rounded-lg tips col-start-3 col-span-8"
						>
							<img
								class="absolute z-0 w-auto max-h-10 ampoule-picto"
								src="../assets/picto/light.png"
								alt=""
							/>
							<div class="flex flex-row justify-around">
								<p>
									Estimation des charges locatives annuelles
									du bien
								</p>
								<p class="font-bold">
									{{
										tOPEX.toLocaleString("fr-FR", {
											minimumFractionDigits: 0,
										})
									}}
									€
								</p>
							</div>
						</div>
						<!-- FIN ESTIMATION DES CHARGES LOCATIVES -->
						<!-- TAUX DE REVALORISATION -->
						<div class="mt-4 col-span-8 col-start-3">
							<label
								for="trevalorisationOPEX.val"
								class="mb-2 texte-label"
								>Taux de revalorisation annuel des charges
								locatives</label
							>
							<div
								class="flex flex-row items-center whitespace-no-wrap"
							>
								<input
									id="trevalorisationOPEX.val"
									v-model="trevalorisationOPEX.val"
									type="range"
									min="0"
									max="4"
									step="0.25"
									class="mt-2 slider"
								/>
								<div class="ml-4">
									{{ trevalorisationOPEX.val }} %
								</div>
							</div>
						</div>
						<!-- FIN TAUX DE REVALORISATION -->
						<!-- PERSONNALISATION -->
						<div class="mx-20 mt-10 col-span-10 col-start-2">
							<div
								class="relative flex flex-row justify-between px-4 py-2 bg-white border border-black rounded-lg cursor-pointer"
								@click="
									isToggle.detailsChargesLocatives =
										!isToggle.detailsChargesLocatives
								"
							>
								<h2 class="text-xs">
									Personnaliser le détail des charges
									locatives
								</h2>
								<div>
									<span
										v-if="!isToggle.detailsChargesLocatives"
										class="absolute right-4 cursor-pointer bottom-0.5"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											width="34"
											height="34"
											viewBox="0 0 172 172"
											style="fill: #000000"
										>
											<g
												fill="none"
												fill-rule="nonzero"
												stroke="none"
												stroke-width="1"
												stroke-linecap="butt"
												stroke-linejoin="miter"
												stroke-miterlimit="10"
												stroke-dasharray=""
												stroke-dashoffset="0"
												font-family="none"
												font-weight="inherit"
												font-size="none"
												text-anchor="inherit"
												style="mix-blend-mode: normal"
											>
												<path
													d="M0,172v-172h172v172z"
													fill="none"
												/>
												<g id="Layer_1" fill="#444b54">
													<path
														d="M123.49063,63.02188l-37.49063,37.49063l-37.49062,-37.49062c-1.6125,-1.6125 -4.16562,-1.6125 -5.64375,0c-1.6125,1.6125 -1.6125,4.16562 0,5.64375l40.3125,40.3125c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82187,-1.20938l40.3125,-40.3125c1.6125,-1.6125 1.6125,-4.16563 0,-5.64375c-1.6125,-1.6125 -4.03125,-1.6125 -5.64375,0z"
													/>
												</g>
											</g>
										</svg>
									</span>
									<span
										v-else
										class="absolute right-4 bottom-0.5 cursor-pointer"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											width="34"
											height="34"
											viewBox="0 0 172 172"
											style="fill: #000000"
										>
											<g
												fill="none"
												fill-rule="nonzero"
												stroke="none"
												stroke-width="1"
												stroke-linecap="butt"
												stroke-linejoin="miter"
												stroke-miterlimit="10"
												stroke-dasharray=""
												stroke-dashoffset="0"
												font-family="none"
												font-weight="inherit"
												font-size="none"
												text-anchor="inherit"
												style="mix-blend-mode: normal"
											>
												<path
													d="M0,172v-172h172v172z"
													fill="none"
												/>
												<g id="Layer_1" fill="#444b54">
													<path
														d="M42.86563,108.97813c1.6125,1.6125 4.16562,1.6125 5.64375,0l37.49063,-37.49062l37.49063,37.49062c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82188,-1.20938c1.6125,-1.6125 1.6125,-4.16562 0,-5.64375l-40.3125,-40.3125c-1.6125,-1.6125 -4.16563,-1.6125 -5.64375,0l-40.3125,40.3125c-1.6125,1.6125 -1.6125,4.03125 0,5.64375z"
													/>
												</g>
											</g>
										</svg>
									</span>
								</div>
							</div>
						</div>
						<!-- FIN PERSONNALISATION -->
						<div
							v-show="isToggle.detailsChargesLocatives"
							class="mx-24 mt-10 col-span-10 col-start-2"
						>
							<div class="grid grid-cols-12">
								<!-- TAXE FONCIERE -->
								<div class="col-span-3 col-start-2">
									<div class="flex">
										<label
											for="fonciere"
											class="my-auto mr-2 texte-label"
											>Taxe foncière*</label
										>
										<QuestionMarkv2
											class="ml-2"
											:text="`${infobulles.taxeFonciere}`"
										/>
									</div>
									<div class="flex flex-row">
										<div class="mt-4">
											<span
												v-if="tfonciere.tfonciereEuro"
												class="h-full px-6 mt-4 mr-4 font-bold cursor-pointer bg-3D-btn"
												@click="
													onChangeSigleLocatifBien(
														'tfonciere',
														false
													)
												"
												>€</span
											>
											<span
												v-else-if="
													!tfonciere.tfonciereEuro
												"
												class="h-full px-6 mr-4 font-bold cursor-pointer bg-3D-btn"
												@click="
													onChangeSigleLocatifBien(
														'tfonciere',
														true
													)
												"
												>%</span
											>
										</div>
										<input
											id="fonciere"
											v-model="
												onChangeSigleTfonciereComputed
											"
											type="number"
											step="any"
											max="100000"
											class="block px-3 py-2 mt-3 border form-input"
										/>
									</div>
								</div>
								<div
									v-if="
										typeexploitation === 'pinel_neuf' ||
										typeexploitation === 'classique_neuf'
									"
									class="flex flex-row items-center mt-5 text-xs col-span-5 col-start-7"
								>
									<input
										v-model="exonerationTaxeFonciere"
										type="checkbox"
										class="mx-4 my-auto checked:bg-blue-500"
									/>
									<p>
										Pour appliquer l’exonération de la taxe
										foncière pendant 2 ans permise dans le
										neuf, cochez ci-contre.
									</p>
									<QuestionMarkv2
										class="float-right mr-2"
										:text="`${infobulles.exonerationTaxeFonciere}`"
									/>
								</div>
								<!-- FIN TAXE FONCIERE -->
								<!-- CHARGES DE COPROPRIETE -->
								<div class="mt-8 col-span-8 col-start-2">
									<div class="flex">
										<label
											for="chargecoproInput"
											class="my-auto mr-2 texte-label"
											>Charges de copropriété</label
										>
										<QuestionMarkv2
											class="ml-2"
											:text="`${infobulles.chargesDeCopropriete}`"
										/>
									</div>
									<div class="flex flex-row">
										<div class="mt-4">
											<span
												v-if="
													chargecopro.chargecoproEuro
												"
												class="h-full px-6 mt-4 mr-4 font-bold cursor-pointer bg-3D-btn"
												@click="
													onChangeSigleLocatifBien(
														'chargecopro',
														false
													)
												"
												>€</span
											>
											<span
												v-else-if="
													!chargecopro.chargecoproEuro
												"
												class="h-full px-6 mt-4 mr-4 font-bold cursor-pointer bg-3D-btn"
												@click="
													onChangeSigleLocatifBien(
														'chargecopro',
														true
													)
												"
												>%</span
											>
										</div>
										<input
											id="chargecoproInput"
											v-model="
												onChangeSigleChargecoproComputed
											"
											type="number"
											step="any"
											class="block px-3 py-2 mt-3 border form-input"
										/>
									</div>
								</div>
								<!-- FIN CHARGES DE COPROPRIETE -->
								<!-- ASSURANCE PROPRIETAIRE NON OCCUPANT -->
								<div class="mt-8 col-span-8 col-start-2">
									<div class="flex">
										<label
											for="apno"
											class="my-auto mr-2 texte-label"
											>Assurance Propriétaire Non Occupant
											(PNO)</label
										>
										<QuestionMarkv2
											class="ml-2"
											:text="`${infobulles.assuranceProprietaireNonOccupant}`"
										/>
									</div>
									<div class="flex flex-row">
										<div class="mt-4">
											<span
												v-if="apno.apnoEuro"
												class="h-full px-6 mt-4 mr-4 font-bold cursor-pointer bg-3D-btn"
												@click="
													onChangeSigleLocatifBien(
														'apno',
														false
													)
												"
												>€</span
											>
											<span
												v-else-if="!apno.apnoEuro"
												class="h-full px-6 mt-4 mr-4 font-bold cursor-pointer bg-3D-btn"
												@click="
													onChangeSigleLocatifBien(
														'apno',
														true
													)
												"
												>%</span
											>
										</div>
										<input
											id="apno"
											v-model="onChangeSigleApnoComputed"
											type="number"
											step="any"
											class="block px-3 py-2 mt-3 border form-input"
										/>
									</div>
								</div>
								<!-- FIN ASSURANCE PROPRIETAIRE NON OCCUPANT -->
								<!-- CHARGES DE GESTION LOCATIVE -->
								<div class="mt-8 col-span-8 col-start-2">
									<div class="flex">
										<label
											for="hgestionloc"
											class="my-auto mr-2 texte-label"
											>Charges de gestion locative</label
										>
										<QuestionMarkv2
											class="ml-2"
											:text="`${infobulles.chargesDeGestionLocative}`"
										/>
									</div>
									<div class="flex flex-row">
										<div class="mt-4">
											<span
												v-if="
													hgestionloc.hgestionlocEuro
												"
												class="h-full px-6 mt-4 mr-4 font-bold cursor-pointer bg-3D-btn"
												@click="
													onChangeSigleLocatifBien(
														'hgestionloc',
														false
													)
												"
												>€</span
											>
											<span
												v-else-if="
													!hgestionloc.hgestionlocEuro
												"
												class="h-full px-6 mt-4 mr-4 font-bold cursor-pointer bg-3D-btn"
												@click="
													onChangeSigleLocatifBien(
														'hgestionloc',
														true
													)
												"
												>%</span
											>
										</div>
										<input
											id="hgestionloc"
											v-model="
												onChangeSigleHgestionlocComputed
											"
											type="number"
											step="any"
											class="block px-3 py-2 mt-3 border form-input"
										/>
									</div>
								</div>
								<!-- FIN CHARGES DE GESTION LOCATIVE -->
								<!-- GARANTIE LOYER IMPAYES -->
								<div class="mt-8 col-span-8 col-start-2">
									<div class="flex">
										<label
											for="gli"
											class="my-auto mr-2 texte-label"
											>Garantie loyers impayés
											(GLI)</label
										>
										<QuestionMarkv2
											class="ml-2"
											:text="`${infobulles.garantieLoyerImpayes}`"
										/>
									</div>
									<div class="flex flex-row">
										<div class="mt-4">
											<span
												v-if="gli.gliEuro"
												class="h-full px-6 mt-4 mr-4 font-bold cursor-pointer bg-3D-btn"
												@click="
													onChangeSigleLocatifBien(
														'gli',
														false
													)
												"
												>€</span
											>
											<span
												v-else-if="!gli.gliEuro"
												class="h-full px-6 mt-4 mr-4 font-bold cursor-pointer bg-3D-btn"
												@click="
													onChangeSigleLocatifBien(
														'gli',
														true
													)
												"
												>%</span
											>
										</div>
										<input
											id="gli"
											v-model="onChangeGLIComputed"
											type="number"
											step="any"
											max="10000"
											class="block px-3 py-2 mt-3 border form-input"
										/>
									</div>
								</div>
								<!-- FIN GARANTIE LOYER IMPAYES -->
								<!-- PROVISION PETITS TRAVAUX -->
								<div class="mt-8 col-span-8 col-start-2">
									<div class="flex">
										<label
											for="provision"
											class="my-auto mr-2 texte-label"
											>Provisions petits travaux et autres
											charges</label
										>
										<QuestionMarkv2
											class="ml-2"
											:text="`${infobulles.provisionPetitTravauxEtAutresCharges}`"
										/>
									</div>
									<div class="flex flex-row">
										<div
											class="h-full px-6 mt-4 mr-4 bg-3D-btn"
										>
											<span
												v-if="provision.provisionEuro"
												class="font-bold cursor-pointer"
												@click="
													onChangeSigleLocatifBien(
														'provision',
														false
													)
												"
												>€</span
											>
											<span
												v-else-if="
													!provision.provisionEuro
												"
												class="font-bold cursor-pointer"
												@click="
													onChangeSigleLocatifBien(
														'provision',
														true
													)
												"
												>%</span
											>
										</div>
										<input
											id="provision"
											v-model="
												onChangeSigleProvisionComputed
											"
											type="number"
											step="any"
											max="10000"
											class="block px-3 py-2 mt-3 border form-input"
										/>
									</div>
								</div>
								<!-- FIN PROVISION PETITS TRAVAUX -->
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="financement" class="card">
				<div class="flex flex-row align-middle card-header">
					<img
						src="../assets/icons/faq18.png"
						class="h-32"
						alt="Financement de l'investissement"
					/>
					<div class="flex flex-col my-auto ml-5">
						<p class="text-xl">Comment le projet sera financé ?</p>
						<p class="text-xl font-bold">Financement du projet</p>
					</div>
				</div>
				<div class="mt-10 card-body">
					<div class="grid grid-cols-12">
						<!-- MODE DE FINANCEMENT -->
						<div class="my-auto col-span-8 col-start-3">
							<label
								for="modefinancement"
								class="block text-xs font-bold text-gray-700 leading-5"
								>Mode de financement de ce projet</label
							>
						</div>
						<select
							id="modefinancement"
							v-model="modefinancement"
							required
							class="w-full h-10 py-0 pl-2 mt-2 text-gray-500 bg-transparent border-transparent col-span-5 col-start-3 form-select sm:text-xs sm:leading-5"
							name="modefinancement"
							@change="onChangeModeFinancement"
						>
							<option value="composant">
								Financé par emprunt bancaire (méthode par
								composant)
							</option>
							<option value="apport" selected>
								Financé par emprunt bancaire (méthode par
								apport)
							</option>
							<option value="fond_propre">
								Financé à 100% avec fonds propres
							</option>
						</select>
						<!-- FIN MODE DE FINANCEMENT -->
						<div
							v-if="modefinancement === 'fond_propre'"
							class="mt-5 col-span-12"
						>
							<div
								class="relative w-3/4 px-6 py-4 mx-auto my-10 border-2 rounded-lg tips"
							>
								<img
									class="absolute z-0 w-auto max-h-10 ampoule-picto"
									src="../assets/picto/light.png"
									alt=""
								/>
								<div class="flex flex-row justify-between">
									<p>Apport total à fournir à l'achat</p>
									<p
										v-show="
											typeexploitation ===
												'pinel_ancien' ||
											typeexploitation ===
												'denormandie' ||
											typeexploitation ===
												'classique_ancien' ||
											typeexploitation === 'malraux'
										"
										class="font-bold"
									>
										{{
											Math.floor(
												Number(buypricetotcalc) +
													Number(fnotaire.val) +
													Number(fagence.val) +
													Number(travauxTotaux) +
													Number(fbancaire.val) +
													Number(fgarantie.val)
											).toLocaleString("fr-FR", {
												minimumFractionDigits: 0,
											})
										}}
										€
									</p>
									<p
										v-show="
											typeexploitation === 'pinel_neuf' ||
											typeexploitation ===
												'classique_neuf'
										"
										class="font-bold"
									>
										{{
											Math.floor(
												Number(buypricetotcalc) +
													Number(fnotaire.val) +
													Number(fagence.val) +
													Number(travauxTotaux) +
													Number(fbancaire.val) +
													Number(fgarantie.val) +
													Number(ftvatot)
											).toLocaleString("fr-FR", {
												minimumFractionDigits: 0,
											})
										}}
										€
									</p>
								</div>
							</div>
						</div>
						<div v-else class="mt-5 text-xs col-start-3 col-span-8">
							<!-- TABLEAU EMPRUNT -->
							<div
								v-if="modefinancement === 'composant'"
								class="mt-8 col-span-12 col-start-1"
							>
								<!-- COMPOSITION DE L'EMPRUNT BANCAIRE -->
								<p
									class="mt-5 font-bold col-start-3 col-span-4"
								>
									Composition de l’emprunt bancaire
								</p>
								<p class="mt-3 mb-8 col-start-3 col-span-10">
									Définissez, catégorie par catégorie, comment
									sera composé l’emprunt bancaire de votre
									client. Son apport personnel sera
									directement calculé en fonction de ce total
									pour couvrir le reste des frais.
								</p>
								<!-- FIN COMPOSITION DE L'EMPRUNT BANCAIRE -->
								<div class="grid grid-cols-12">
									<!-- HEAD -->
									<div
										class="text-white col-start-4 col-span-12"
									>
										<div
											style="
												background-color: rgba(
													12,
													30,
													59,
													0.66
												);
											"
											class="flex flex-row justify-between py-3 rounded-lg px-15"
										>
											<p class="font-bold">
												Frais totaux
											</p>
											<p class="font-bold">
												Financés par la banque?
											</p>
											<p class="font-bold">
												À hauteur de
											</p>
										</div>
									</div>
									<!-- FIN HEAD -->
									<!-- BODY -->
									<!-- PRIX D'ACHAT DU BIEN -->
									<div class="mt-5 col-span-3 col-start-1">
										<p class="text-right">
											Prix d’achat du bien*
										</p>
									</div>
									<div class="mt-5 col-span-2 col-start-4">
										<p class="text-center">
											{{
												buypricetotcalc.toLocaleString(
													"fr-FR",
													{
														minimumFractionDigits: 0,
													}
												)
											}}
											€
										</p>
									</div>
									<div
										class="flex flex-row justify-between mt-5 col-span-2 col-start-7"
									>
										<div>
											<label for="yes">Oui</label>
											<input
												v-model="empruntOption.bien"
												type="radio"
												class="ml-3 text-lg drop-shadow"
												name="radioprixdachat"
												value="yes"
												checked
											/>
										</div>
										<div>
											<label for="no">Non</label>
											<input
												v-model="empruntOption.bien"
												type="radio"
												class="ml-3 drop-shadow-sm"
												name="radioprixdachat"
												value="no"
											/>
										</div>
									</div>
									<div class="col-span-3 col-start-10">
										<div class="euro-input">
											<input
												v-model="buypricetot"
												type="number"
												:max="buypricetotcalc"
												min="0"
												step="1"
												:disabled="
													empruntOption.bien === 'no'
												"
												:class="{
													'bg-gray-400':
														empruntOption.bien ===
														'no',
												}"
												class="block w-2/3 px-3 py-2 mx-auto mt-3 border form-input"
											/>
										</div>
									</div>
									<!-- FIN PRIX D'ACHAT DU BIEN -->
									<!-- FRAIS DE NOTAIRES -->
									<div class="mt-5 col-span-3 col-start-1">
										<p class="text-right">
											Frais de notaires
										</p>
									</div>
									<div class="mt-5 col-span-2 col-start-4">
										<p class="text-center">
											{{
												fnotaire.val.toLocaleString(
													"fr-FR",
													{
														minimumFractionDigits: 0,
													}
												)
											}}
											€
										</p>
									</div>
									<div
										class="flex flex-row justify-between mt-5 col-span-2 col-start-7"
									>
										<div>
											<label for="yes">Oui</label>
											<input
												v-model="empruntOption.notaire"
												type="radio"
												class="ml-3 text-lg drop-shadow"
												name="radiofraisnotaire"
												value="yes"
												checked
											/>
										</div>
										<div>
											<label for="no">Non</label>
											<input
												v-model="empruntOption.notaire"
												type="radio"
												class="ml-3 drop-shadow-sm"
												name="radiofraisnotaire"
												value="no"
											/>
										</div>
									</div>
									<div class="col-span-3 col-start-10">
										<div class="euro-input">
											<input
												v-model="fnotairetot"
												type="number"
												:max="fnotaire.val"
												min="0"
												step="1"
												:disabled="
													empruntOption.notaire ===
													'no'
												"
												:class="{
													'bg-gray-400':
														empruntOption.notaire ===
														'no',
												}"
												class="block w-2/3 px-3 py-2 mx-auto mt-3 border form-input"
											/>
										</div>
									</div>
									<!-- FIN FRAIS DE NOTAIRES -->
									<!-- MONTANT DE LA TVA -->
									<div
										v-if="
											typeexploitation === 'pinel_neuf' ||
											typeexploitation ===
												'classique_neuf'
										"
										class="mt-5 col-span-3 col-start-1"
									>
										<p class="text-right">
											Montant de la TVA sur l’achat
										</p>
									</div>
									<div
										v-if="
											typeexploitation === 'pinel_neuf' ||
											typeexploitation ===
												'classique_neuf'
										"
										class="mt-5 col-span-2 col-start-4"
									>
										<p class="text-center">
											{{
												tva.val.toLocaleString(
													"fr-FR",
													{
														minimumFractionDigits: 0,
													}
												)
											}}
											€
										</p>
									</div>
									<div
										v-if="
											typeexploitation === 'pinel_neuf' ||
											typeexploitation ===
												'classique_neuf'
										"
										class="flex flex-row justify-between mt-5 col-span-2 col-start-7"
									>
										<div>
											<label for="yes">Oui</label>
											<input
												v-model="empruntOption.tva"
												type="radio"
												class="ml-3 text-lg drop-shadow"
												name="radiotva"
												value="yes"
												checked
											/>
										</div>
										<div>
											<label for="no">Non</label>
											<input
												v-model="empruntOption.tva"
												type="radio"
												class="ml-3 drop-shadow-sm"
												name="radiotva"
												value="no"
											/>
										</div>
									</div>
									<div
										v-if="
											typeexploitation === 'pinel_neuf' ||
											typeexploitation ===
												'classique_neuf'
										"
										class="col-span-3 col-start-10"
									>
										<div class="euro-input">
											<input
												v-model="ftvatot"
												type="number"
												step="1"
												:max="tva.val"
												:disabled="
													empruntOption.tva === 'no'
												"
												:class="{
													'bg-gray-400':
														empruntOption.tva ===
														'no',
												}"
												min="0"
												class="block w-2/3 px-3 py-2 mx-auto mt-3 border form-input"
											/>
										</div>
									</div>
									<!-- FIN MONTANT DE LA TVA -->
									<!-- HONORAIRE AGENCE / CHASSE -->
									<div class="mt-5 col-span-3 col-start-1">
										<p class="text-right">
											Honoraire du conseiller
										</p>
									</div>
									<div class="mt-5 col-span-2 col-start-4">
										<p class="text-center">
											{{
												fagence.val.toLocaleString(
													"fr-FR",
													{
														minimumFractionDigits: 0,
													}
												)
											}}
											€
										</p>
									</div>
									<div
										class="flex flex-row justify-between mt-5 col-span-2 col-start-7"
									>
										<div>
											<label for="yes">Oui</label>
											<input
												v-model="empruntOption.agence"
												type="radio"
												class="ml-3 text-lg drop-shadow"
												name="radiohonoraire"
												value="yes"
												checked
											/>
										</div>
										<div>
											<label for="no">Non</label>
											<input
												v-model="empruntOption.agence"
												type="radio"
												class="ml-3 drop-shadow-sm"
												name="radiohonoraire"
												value="no"
											/>
										</div>
									</div>
									<div class="col-span-3 col-start-10">
										<div class="euro-input">
											<input
												v-model="fagencetot"
												type="number"
												step="1"
												:max="fagence.val"
												:disabled="
													empruntOption.agence ===
													'no'
												"
												:class="{
													'bg-gray-400':
														empruntOption.agence ===
														'no',
												}"
												min="0"
												class="block w-2/3 px-3 py-2 mx-auto mt-3 border form-input"
											/>
										</div>
									</div>
									<!-- FIN HONORAIRE AGENCE / CHASSE -->
									<!-- TRAVAUX AMEUBLE -->
									<div class="mt-5 col-span-3 col-start-1">
										<p class="text-right">
											Travaux et ameublement*
										</p>
									</div>
									<div class="mt-5 col-span-2 col-start-4">
										<p class="text-center">
											{{
												travauxTotaux.toLocaleString(
													"fr-FR",
													{
														minimumFractionDigits: 0,
													}
												)
											}}
											€
										</p>
									</div>
									<div
										class="flex flex-row justify-between mt-5 col-span-2 col-start-7"
									>
										<div>
											<label for="yes">Oui</label>
											<input
												v-model="empruntOption.travaux"
												type="radio"
												class="ml-3 text-lg drop-shadow"
												name="radiotravaux"
												value="yes"
												checked
											/>
										</div>
										<div>
											<label for="no">Non</label>
											<input
												v-model="empruntOption.travaux"
												type="radio"
												class="ml-3 drop-shadow-sm"
												name="radiotravaux"
												value="no"
											/>
										</div>
									</div>
									<div class="col-span-3 col-start-10">
										<div class="euro-input">
											<input
												v-model="trvametot"
												type="number"
												step="1"
												:disabled="
													empruntOption.travaux ===
													'no'
												"
												:class="{
													'bg-gray-400':
														empruntOption.travaux ===
														'no',
												}"
												:max="travauxTotaux"
												min="0"
												class="block w-2/3 px-3 py-2 mx-auto mt-3 border form-input"
											/>
										</div>
									</div>
									<!-- FIN TRAVAUX AMEUBLE -->
									<!-- FRAIS BANCAIRE -->
									<div class="mt-5 col-span-3 col-start-1">
										<p class="text-right">
											Frais bancaires (dossier et
											garantie)
										</p>
									</div>
									<div class="mt-5 col-span-2 col-start-4">
										<p class="text-center">
											{{
												(
													Number(fbancaire.val) +
													Number(fgarantie.val)
												).toLocaleString("fr-FR", {
													minimumFractionDigits: 0,
												})
											}}
											€
										</p>
									</div>
									<div
										class="flex flex-row justify-between mt-5 col-span-2 col-start-7"
									>
										<div>
											<label for="yes">Oui</label>
											<input
												v-model="empruntOption.banque"
												type="radio"
												class="ml-3 text-lg drop-shadow"
												name="radiofraisbancaire"
												value="yes"
												checked
											/>
										</div>
										<div>
											<label for="no">Non</label>
											<input
												v-model="empruntOption.banque"
												type="radio"
												class="ml-3 drop-shadow-sm"
												name="radiofraisbancaire"
												value="no"
											/>
										</div>
									</div>
									<div class="col-span-3 col-start-10">
										<div class="euro-input">
											<input
												v-model="fbancairetot"
												type="number"
												step="1"
												:disabled="
													empruntOption.banque ===
													'no'
												"
												:max="
													Number(fbancaire.val) +
													Number(fgarantie.val)
												"
												min="0"
												:class="{
													'bg-gray-400':
														empruntOption.banque ===
														'no',
												}"
												class="block w-2/3 px-3 py-2 mx-auto mt-3 border form-input"
											/>
										</div>
									</div>
									<!-- FIN FRAIS BANCAIRE -->
									<div
										class="mt-10 text-white col-start-4 col-span-12"
									>
										<div
											style="
												background-color: rgba(
													12,
													30,
													59,
													0.66
												);
											"
											class="flex flex-col justify-between py-3 rounded-lg px-15"
										>
											<div class="grid grid-cols-12">
												<p
													class="text-right col-span-6 col-start-2"
												>
													Montant total prêté par la
													banque
												</p>
												<p
													class="font-bold text-right col-span-4 col-start-10"
												>
													{{
														montantEmprunteComputed.toLocaleString(
															"fr-FR",
															{
																minimumFractionDigits: 0,
															}
														)
													}}
													€
												</p>
											</div>
											<div class="mt-4 grid grid-cols-12">
												<p
													class="text-right col-span-6 col-start-2"
												>
													Apport personnel
												</p>
												<p
													class="font-bold text-right col-span-4 col-start-10"
												>
													{{
														apportPersoCompoComputed.toLocaleString(
															"fr-FR",
															{
																minimumFractionDigits: 0,
															}
														)
													}}
													€
												</p>
											</div>
											<div class="mt-4 grid grid-cols-12">
												<p
													class="text-right col-span-6 col-start-2"
												>
													Pourcentage d’apport
												</p>
												<p
													class="font-bold text-right col-span-4 col-start-10"
												>
													{{
														fraisTotaux === 0
															? 0
															: (
																	(apportPersoCompoComputed /
																		fraisTotaux) *
																	100
															  ).toLocaleString(
																	"fr-FR",
																	{
																		maximumFractionDigits: 1,
																	}
															  )
													}}
													%
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- FIN TABLEAU EMPRUNT -->

							<div
								v-if="modefinancement === 'apport'"
								class="flex flex-col mt-5"
							>
								<div class="flex flex-row">
									<label
										for="apportClient"
										class="mt-3 text-xs text-gray-700"
										>Montant total de l'apport du
										client</label
									>
									<div class="euro-input">
										<input
											id="apportClient"
											v-model="apportPersoApport"
											type="number"
											step="1"
											min="0"
											:max="fraisTotaux"
											class="block w-24 px-3 py-2 ml-10 border form-input"
										/>
									</div>
								</div>

								<div
									class="relative px-6 py-4 mt-10 mb-5 border-2 rounded-lg col-start-3 col-span-8 tips"
								>
									<img
										class="absolute z-0 w-auto max-h-10 ampoule-picto"
										src="../assets/picto/light.png"
										alt=""
									/>
									<div class="grid grids-col-12">
										<p class="col-start-1 col-span-2 pl-15">
											Pourcentage d’apport
										</p>
										<p
											class="font-bold text-right col-start-10 pr-15"
										>
											{{
												fraisTotaux === 0
													? 0
													: (
															(apportPersoApport /
																fraisTotaux) *
															100
													  ).toLocaleString(
															"fr-FR",
															{
																maximumFractionDigits: 1,
															}
													  )
											}}
											%
										</p>
									</div>
									<div class="mt-2 grid grids-col-12">
										<p class="col-start-1 col-span-2 pl-15">
											Montant total prêté par la banque
										</p>
										<p
											class="font-bold text-right col-start-10 pr-15"
										>
											{{
												(
													fraisTotaux -
													apportPersoApport
												).toLocaleString("fr-FR", {
													minimumFractionDigits: 0,
												})
											}}
											€
										</p>
									</div>
								</div>
							</div>

							<!-- DUREE DE L'EMPRUNT -->
							<div class="col-start-3 col-span-8">
								<div
									class="flex flex-row items-center justify-between my-8"
								>
									<div class="">
										<label
											for="dureeempr"
											class="text-xs text-gray-700"
											>Durée de l'emprunt (en
											années)</label
										>
									</div>
									<div
										class="items-center w-1/2 whitespace-no-wrap"
									>
										<input
											id="dureeempr"
											v-model="dureeempr.val"
											type="range"
											min="5"
											max="30"
											step="1"
											class="slider"
										/>
									</div>
									<div class="">
										{{ dureeempr.val }} années
									</div>
								</div>
							</div>

							<!-- FIN DUREE DE L'EMPRUNT -->
							<div class="col-start-3 col-span-8">
								<div
									class="flex items-center justify-between gap-4"
								>
									<label
										for="tinteretemprunt"
										class="my-auto text-xs text-gray-700"
										>Taux d’intérêts d’emprunt hors
										assurances*</label
									>
									<div class="percent-input">
										<input
											id="tinteretempr"
											v-model="tinteretemprunt"
											type="number"
											step="0.01"
											max="10"
											min="0"
											class="w-24 py-2 my-auto form-input"
										/>
									</div>
									<a
										class="items-center justify-center px-4 py-2 text-xs font-medium text-center cursor-pointer leading-6 bouton"
										@click="showAmortissementModal"
										>Accéder au tableau d'amortissement
										d'emprunt</a
									>
									<Amortissement
										v-show="amortissementModal"
										:amort-data="amortData"
										@close="closeAmortissementModal"
									/>
								</div>
							</div>

							<!-- ANNUITE CONSTANTE D'EMPRUNT -->
							<div
								class="relative px-12 py-4 my-10 border-2 rounded-lg col-start-3 col-span-8 tips"
							>
								<img
									class="absolute z-0 w-auto max-h-10 ampoule-picto"
									src="../assets/picto/light.png"
									alt=""
								/>
								<div class="flex flex-row justify-between">
									<p>
										Annuité constante d’emprunt

										<QuestionMarkv2
											class="float-right ml-2"
											:text="`${infobulles.annuiteConstanteEmprunt}`"
										/>
									</p>
									<p class="font-bold">
										{{
											Math.floor(
												annuite.val * 12 +
													tassuranceempr.val *
														montantemprunte.val
											).toLocaleString("fr-FR", {
												minimumFractionDigits: 0,
											})
										}}
										€
									</p>
								</div>
								<div
									v-if="
										modefinancement === 'composant' ||
										modefinancement === 'apport'
									"
									class="flex flex-row justify-between mt-3"
								>
									<p>Equivalence en termes de mensualités</p>
									<p class="font-bold">
										{{
											Math.floor(
												annuite.val +
													(tassuranceempr.val *
														montantemprunte.val) /
														12
											).toLocaleString("fr-FR", {
												minimumFractionDigits: 0,
											})
										}}
										€
									</p>
								</div>
							</div>
							<!-- FIN ANNUITE CONSTANTE D'EMPRUNT -->
							<!-- TAUX D'INTERET -->
							<div class="col-start-3 col-span-8">
								<div
									class="relative px-6 py-4 my-8 border-2 rounded-lg"
								>
									<img
										class="absolute z-0 w-auto max-h-10 ampoule-picto"
										src="../assets/icons/inspector.png"
										alt=""
									/>
									<div class="flex flex-row justify-between">
										<p>
											Taux d’intérêt considéré comme bon
											sur {{ dureeempr.val }} ans
										</p>
										<p class="font-bold">
											{{ tinteretbonComputed }} %
										</p>
									</div>
								</div>
								<div
									v-if="dureeempr.val > dureedetention.val"
									class="flex flex-col gap-3"
								>
									<div
										class="p-4 text-xs tips text-justify relative"
									>
										<img
											class="absolute z-0 w-auto max-h-10 ampoule-picto"
											src="../assets/picto/light.png"
											alt=""
										/>
										<b> Attention !</b> La durée de
										détention inscrite dans la fiche
										investisseur est inferieure à la durée
										de l'emprunt. Votre client s'expose à
										des frais de remboursement anticipé
										d'emprunt (IRA).
									</div>
									<h3 class="font-bold text-base">
										Montant des IRA
									</h3>
									<div class="text-xs text-justify">
										Remplissez ce champs uniquement si vous
										connaissez le montant des IRA ou
										inscrivez la valeur "0" s'il n'y a aucun
										frais. Sinon, nous les calculerons
										automatiquement pour vous en nous basant
										sur la méthode de 6 mois d'interêts du
										capital restant dû.
									</div>
									<div
										class="flex euro-input w-56 place-items-center"
									>
										<input
											v-model="ira.val"
											type="number"
											step="1"
											min="0"
											class="px-3 py-2 border form-input"
										/>
									</div>
								</div>
							</div>
							<!-- FIN TAUX D'INTERET -->

							<!-- PERSONNALISATION -->
							<div class="mt-10 col-span-8 col-start-3">
								<div
									class="relative flex flex-row justify-between px-4 py-2 bg-white border border-black rounded-lg cursor-pointer"
									@click="
										isToggle.detailsEmprunt =
											!isToggle.detailsEmprunt
									"
								>
									<h2 class="text-normal">
										Personnaliser le détail des frais et
										conditions d’emprunt
									</h2>
									<div>
										<span
											v-if="!isToggle.detailsEmprunt"
											class="absolute cursor-pointer right-4"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												x="0px"
												y="0px"
												width="34"
												height="34"
												viewBox="0 0 172 172"
												style="fill: #000000"
											>
												<g
													fill="none"
													fill-rule="nonzero"
													stroke="none"
													stroke-width="1"
													stroke-linecap="butt"
													stroke-linejoin="miter"
													stroke-miterlimit="10"
													stroke-dasharray=""
													stroke-dashoffset="0"
													font-family="none"
													font-weight="inherit"
													font-size="none"
													text-anchor="inherit"
													style="
														mix-blend-mode: normal;
													"
												>
													<path
														d="M0,172v-172h172v172z"
														fill="none"
													/>
													<g
														id="Layer_1"
														fill="#444b54"
													>
														<path
															d="M123.49063,63.02188l-37.49063,37.49063l-37.49062,-37.49062c-1.6125,-1.6125 -4.16562,-1.6125 -5.64375,0c-1.6125,1.6125 -1.6125,4.16562 0,5.64375l40.3125,40.3125c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82187,-1.20938l40.3125,-40.3125c1.6125,-1.6125 1.6125,-4.16563 0,-5.64375c-1.6125,-1.6125 -4.03125,-1.6125 -5.64375,0z"
														/>
													</g>
												</g>
											</svg>
										</span>
										<span
											v-else
											class="absolute cursor-pointer right-4"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												x="0px"
												y="0px"
												width="34"
												height="34"
												viewBox="0 0 172 172"
												style="fill: #000000"
											>
												<g
													fill="none"
													fill-rule="nonzero"
													stroke="none"
													stroke-width="1"
													stroke-linecap="butt"
													stroke-linejoin="miter"
													stroke-miterlimit="10"
													stroke-dasharray=""
													stroke-dashoffset="0"
													font-family="none"
													font-weight="inherit"
													font-size="none"
													text-anchor="inherit"
													style="
														mix-blend-mode: normal;
													"
												>
													<path
														d="M0,172v-172h172v172z"
														fill="none"
													/>
													<g
														id="Layer_1"
														fill="#444b54"
													>
														<path
															d="M42.86563,108.97813c1.6125,1.6125 4.16562,1.6125 5.64375,0l37.49063,-37.49062l37.49063,37.49062c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82188,-1.20938c1.6125,-1.6125 1.6125,-4.16562 0,-5.64375l-40.3125,-40.3125c-1.6125,-1.6125 -4.16563,-1.6125 -5.64375,0l-40.3125,40.3125c-1.6125,1.6125 -1.6125,4.03125 0,5.64375z"
														/>
													</g>
												</g>
											</svg>
										</span>
									</div>
								</div>
							</div>
						</div>
						<!-- FIN PERSONNALISATION -->
						<div
							v-show="isToggle.detailsEmprunt"
							v-if="
								modefinancement === 'composant' ||
								modefinancement === 'apport'
							"
							class="mt-10 col-span-10 col-start-2"
						>
							<div class="flex items-center justify-around gap-6">
								<div>
									<!-- FRAIS BANCAIRE -->
									<div class="mt-5">
										<label
											for="fbancaire"
											class="my-auto texte-label"
											>Frais bancaires d’ouverture de
											dossier*</label
										>
										<div class="euro-input">
											<input
												id="fbancaire"
												v-model="
													onChangeSigleFbancaireComputed
												"
												type="number"
												step="any"
												class="block px-3 py-2 mt-3 border form-input"
											/>
										</div>
									</div>
									<!-- FIN FRAIS BANCAIRE -->
									<!-- FRAIS GARANTIE -->
									<div class="mt-5">
										<label
											for="fgarantie"
											class="my-auto texte-label"
											>Frais de garantie bancaire</label
										>
										<div class="euro-input">
											<input
												id="fgarantie"
												v-model="fgarantie.val"
												type="number"
												step="any"
												class="block px-3 py-2 mt-3 border form-input"
											/>
										</div>
									</div>
									<!-- FIN FRAIS GARANTIE -->
								</div>
								<div>
									<!-- TAUX D'ASSURANCE -->
									<div class="mt-5">
										<label
											for="tassuranceempr"
											class="my-auto texte-label"
											>Taux d’assurance
											emprunteur(s)*</label
										>
										<div class="w-11/12 pr-3">
											<div class="percent-input">
												<input
													id="tassuranceempr"
													v-model="tassuranceemprunt"
													required
													type="number"
													step="any"
													class="block px-3 py-2 mt-3 border form-input"
												/>
											</div>
										</div>
									</div>
									<!-- FIN TAUX D'ASSURANCE -->
									<!-- DIFFERE DE REMBOURSEMENT -->
									<div
										class="flex flex-col mt-5 col-start-1 col-span-2"
									>
										<label
											for="remboursementemprunt"
											class="my-auto mr-2 texte-label"
											>Différé de remboursement d’emprunt

											<QuestionMarkv2
												class="float-right ml-2"
												:text="`${infobulles.typeDiffereEmprunt}`"
											/>
										</label>
										<select
											id="remboursementemprunt"
											v-model="remboursementemprunt"
											class="h-10 py-0 pl-2 mt-3 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
											name="remboursementemprunt"
										>
											<option
												value="pas_differe"
												:disabled="
													[
														'pinel_neuf',
														'neuf_classique',
													].includes(typeexploitation)
												"
											>
												Pas de différé
											</option>
											<option value="partiel">
												Partiel
											</option>
											<option value="total">Total</option>
										</select>
									</div>
								</div>
								<!-- FIN DIFFERE DE REMBOURSEMENT -->
							</div>
							<!-- DUREE DU DIFFERE -->
							<div
								v-if="remboursementemprunt !== 'pas_differe'"
								class="flex flex-row items-center justify-between mt-10 mb-4 px-28"
							>
								<div>
									<label
										for="dureediffere"
										class="block my-auto text-xs text-center text-gray-700"
										>Durée du différé (en mois)</label
									>
								</div>
								<div
									class="items-center w-1/2 whitespace-no-wrap"
								>
									<input
										id="dureediffere"
										v-model="dureediffere"
										type="range"
										step="1"
										:min="
											appelDeFond.livraisonRemiseClefMois
										"
										max="36"
										class="slider"
									/>
								</div>
								<div class="text-xs">
									{{ dureediffere }} mois
								</div>
							</div>
							<!-- FIN DUREE DU DIFFERE -->
						</div>
					</div>
				</div>
			</div>
			<!-- FIN FINANCEMENT INVESTISSEMENT CARD -->

			<div id="regimeFiscal" class="card">
				<div class="flex flex-row align-middle card-header">
					<img
						src="../assets/icons/faq19.png"
						class="h-32"
						alt="Régime fiscal et charges afférentes"
					/>
					<div class="flex flex-col my-auto ml-5">
						<p class="text-xl">
							Quelle sera la fiscalité du projet ?
						</p>
						<p class="text-xl font-bold">
							Régime fiscal et charges afférentes
						</p>
					</div>
				</div>
				<div class="mt-10 card-body">
					<div class="flex flex-col mx-44 gap-2">
						<!-- CHOIX REGIME FISCAL -->
						<div>
							<QuestionMarkv2
								v-show="
									formeJuridiqueSelected(['sasis', 'sasuis'])
								"
								class="float-left mr-2"
								:text="`${infobulles.regimeFiscalSASouSASU}`"
							/>
							<label
								for="formejuridique"
								class="my-auto mr-2 texte-label"
								>Choix du régime fiscal de
								l’investissement</label
							>
						</div>
						<div class="">
							<select
								id="formejuridique"
								class="h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
								@change="setFormeJurSelected($event)"
							>
								<option
									v-for="(fj, index) in formejuridique"
									:key="fj.text"
									:disabled="
										(user.sub_level === 0 &&
											[
												'sasis',
												'sasuis',
												'sciis',
												'sciirr',
												'sciirmf',
											].includes(fj.code)) ||
										((typeexploitation === 'pinel_neuf' ||
											typeexploitation ===
												'pinel_ancien' ||
											typeexploitation === 'malraux' ||
											typeexploitation ===
												'denormandie') &&
											![
												'lnmf',
												'lnr',
												'sciirr',
												'sciirmf',
											].includes(fj.code))
									"
									:value="index"
									:selected="fj.selected === true"
								>
									{{ fj.text }}
								</option>
							</select>
						</div>
						<div
							v-if="
								typeexploitation === 'pinel_ancien' ||
								typeexploitation === 'pinel_neuf' ||
								typeexploitation === 'denormandie'
							"
							class="flex flex-col justify-between gap-5 bg p-4 rounded-lg border border-gray-300"
							:class="{
								'text-gray-500 bg-gray-200':
									user.sub_level !== 2,
							}"
						>
							<div
								v-if="user.sub_level !== 2"
								class="flex text-xs font-bold flex-no-wrap gap-2 text-lightblueaestima-500"
							>
								Faites évoluer votre plan vers PROFESSIONNEL
								pour utiliser cette fonctionnalité
							</div>
							<div class="flex flex-no-wrap gap-5">
								<input
									v-model="changementDeFiscalite"
									class="font-bold texte-label"
									:disabled="user.sub_level !== 2"
									type="checkbox"
								/>
								<div
									class="flex text-xs font-bold flex-no-wrap gap-2"
								>
									Cochez la case ci-contre si vous souhaitez
									changer de régime à l’issue de la période
									d’engagement d’un dispositif fiscal et opter
									pour le statut LMNP.
								</div>
							</div>
							<div
								v-if="changementDeFiscalite"
								class="flex flex-col gap-3 text-xs"
							>
								<div class="flex w-full">
									<div class="flex flex-col w-1/2 gap-3">
										<!-- nouveaux frais ameublement -->
										<div class="flex flex-col gap-1">
											<div class="flex">
												<label
													for="fameublement"
													class="my-auto mr-2 font-semibold"
													>Frais d'ameublement</label
												>
												<QuestionMarkv2
													class="ml-2"
													:text="`${infobulles.fraisAmeublement}`"
												/>
											</div>
											<div class="flex relative">
												<input
													id="fameublement"
													v-model="fameublement"
													type="number"
													class="h-10 w-32 pl-2 text-gray-500 bg-white border-transparent form-input"
													name="fameublement"
												/>
												<span
													class="absolute left-28 top-2 text-sm"
													>€</span
												>
											</div>
										</div>

										<!-- nouveau loyer -->
										<div
											class="flex flex-col gap-1 relative"
										>
											<label
												for="nouveauLoyerMensuel"
												class="my-auto mr-2 font-semibold"
												>Veuillez entrer le nouveau
												loyer mensuel</label
											>
											<div class="flex relative">
												<input
													id="nouveauLoyerMensuel"
													v-model="
														nouveauLoyerMensuel
													"
													class="h-10 w-32 text-gray-500 bg-white border-transparent form-input"
													type="number"
													name="nouveauLoyerMensuel"
													@change="
														onChangeNouveauLoyerMensuel
													"
												/>
												<div
													class="absolute left-28 top-2 text-sm"
												>
													€
												</div>
											</div>
										</div>

										<!-- nouveau revenus locatifs annuels -->
										<div
											class="relative border-2 rounded-lg tips p-3 mr-5 mt-5"
										>
											<img
												class="absolute z-0 w-auto max-h-10 ampoule-picto"
												src="../assets/picto/light.png"
												alt=""
											/>
											<div
												class="flex flex-row justify-around"
											>
												<div>
													Nouveaux revenus locastifs
													annuels
												</div>
												<div
													class="flex w-1/4 font-bold text-right"
												>
													{{
														(
															nouveauLoyerMensuel *
															12
														).toLocaleString(
															"fr-FR",
															{
																minimumFractionDigits: 0,
															}
														)
													}}
													€
												</div>
											</div>
										</div>
									</div>
									<div class="flex flex-col w-1/2 gap-3">
										<!-- nouveaux frais comptable -->
										<div class="flex flex-col gap-1">
											<label
												for="fcomptable"
												class="font-semibold"
												>Honoraires comptables + CGA si
												présente</label
											>
											<div class="flex relative">
												<input
													id="fcomptable"
													v-model="fcomptable.val"
													type="number"
													class="h-10 w-32 pl-2 text-gray-500 bg-white border-transparent form-input"
													step="any"
												/>
												<div
													class="absolute left-28 top-2 text-sm"
												>
													€
												</div>
											</div>
										</div>
										<!-- nouveaux frais cotisation -->
										<div class="flex flex-col gap-1">
											<label
												for="fcotisation"
												class="font-semibold"
												>Cotisation foncière des
												entreprises (CFE) et autres
												taxes</label
											>
											<div class="flex relative">
												<input
													id="fcotisation"
													v-model="fcotisation.val"
													type="number"
													step="any"
													class="h-10 w-32 pl-2 text-gray-500 bg-white border-transparent form-input"
												/>
												<div
													class="absolute left-28 top-2 text-sm"
												>
													€
												</div>
											</div>
										</div>

										<!-- estimation des frais -->
										<div
											class="relative border-2 rounded-lg tips p-3 mr-5 mt-5 text-xs"
										>
											<img
												class="absolute z-0 w-auto max-h-10 ampoule-picto"
												src="../assets/picto/light.png"
												alt=""
											/>
											<div
												class="flex flex-row justify-around place-items-center"
											>
												<div>
													Estimation des frais
													relatifs au régime fiscal
													sélectionné :
												</div>
												<div
													class="flex w-1/4 font-bold text-right"
												>
													{{
														fraisRelatifsRegimeFiscal
													}}
													€
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- modal amortissement -->
								<div
									class="mt-5 col-start-3 col-span-4 place-self-center"
								>
									<a
										class="inline-flex items-center justify-center px-4 py-2 text-xs font-medium text-center cursor-pointer leading-6 bouton"
										@click="showAmortissementComposantModal"
										>Accéder au tableau d’amortissement par
										composant</a
									>
									<AmortissementParComposant
										v-show="amortissementComposantModal"
										:amortissements="
											amortissementsparcomposant
										"
										@close="
											closeAmortissementComposantModal
										"
									/>
								</div>
							</div>
						</div>
						<div
							v-if="formeJuridiqueSelected(['lnr', 'sciirr'])"
							class="flex flex-row justify-between gap-3"
						>
							<input
								v-model="doubleDFTravauxEnergie"
								class="font-bold texte-label"
								type="checkbox"
							/>
							<div>
								<div class="flex text-xs font-bold">
									Cochez la case ci-contre si les travaux à
									réaliser rentrent dans le cadre du
									doublement du déficit foncier.

									<QuestionMarkv2
										class="ml-2 font-light"
										:text="`${infobulles.travauxDPE}`"
									/>
								</div>
							</div>
						</div>
						<!-- FIN CHOIX REGIME FISCAL -->
						<!-- FRAIS D'AMEUBLEMENT -->
						<div
							v-if="
								formeJuridiqueSelected([
									'sasuis',
									'sciis',
									'lmnpbic',
									'lmnpr',
								])
							"
							class="flex flex-row justify-between"
						>
							<div class="flex flex-row">
								<div class="flex flex-col">
									<div class="flex flex-row">
										<label
											for="fameublement"
											class="my-auto mr-2 texte-label"
											>Frais d'ameublement</label
										>
										<QuestionMarkv2
											class="ml-2"
											:text="`${infobulles.fraisAmeublement}`"
										/>
									</div>
									<div class="flex flex-row">
										<input
											id="fameublement"
											v-model="fameublement"
											type="number"
											class="h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-input pr-7 sm:text-xs sm:leading-5"
											name="fameublement"
										/>
										<div class="flex h-auto mt-2 ml-1">
											€
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- FIN FRAIS D'AMEUBLEMENT -->
						<!-- ASSOCIE -->
						<div
							v-show="
								formeJuridiqueSelected([
									'sciis',
									'sciirmf',
									'sciirr',
									'sasis',
								])
							"
							class="relative w-3/4 px-6 py-4 mx-auto my-10 border-2 rounded-lg tips col-start-1 col-span-12"
						>
							<img
								class="absolute z-0 w-auto max-h-10 ampoule-picto"
								src="../assets/picto/light.png"
								alt=""
							/>
							<div class="mt-4 mb-12 text-base font-semibold">
								Répartition des associés de la société
							</div>
							<div
								class="font-semibold text-white grid grid-cols-9 gap-2"
							>
								<div class="col-span-1" />
								<div class="pl-8 col-span-2">Nom</div>
								<div class="text-center col-span-2">TMI</div>
								<div class="-ml-2 col-span-4">
									% de détention
								</div>
							</div>
							<div
								v-for="(input, index) in type_fisc"
								:key="`type_fisc-${index}`"
								class="my-4 text-sm grid grid-cols-9 gap-2 place-items-center"
							>
								<h3
									class="self-center italic text-black col-span-1"
								>
									Associé {{ index + 1 }} :
								</h3>
								<div class="col-span-2">
									<input
										v-if="index === 0"
										id="inputcheckbox"
										:value="investSelected.name + ' ' + investSelected.firstname"
										type="text"
										class="w-full pl-2 text-sm italic text-black bg-transparent border-transparent form-select pr-7 leading-5"
										readonly
									/>
									<select
										v-else
										id="inputAssoType"
										v-model="input.idInvest"
										class="w-full pl-2 text-sm italic text-gray-500 bg-transparent border-transparent form-select pr-7 leading-5"
										name="inputAssoType"
										@change="
											typeFiscInvestTMI(index, $event)
										"
									>
										<option disabled value="">
											Choisissez un associé
										</option>
										<option
											v-for="val in listInvest"
											v-show="!val.archive.isArchived"
											:key="val._id"
											:value="val._id"
										>
											{{ val.name }} {{ val.firstname }}
										</option>
									</select>
								</div>

								<div
									class="font-semibold text-center col-span-2 align-center place-self-center"
								>
									{{ input.tmi }} %
								</div>
								<div
									class="flex justify-start w-full col-span-4 gap-2"
								>
									<div
										class="flex w-1/3 h-full -ml-2 text-center place-self-center percent-input"
									>
										<input
											id="inputcheckbox"
											v-model.number="input.capital"
											type="number"
											class="w-full px-3 py-2 border form-input place-self-center"
										/>
									</div>

									<div
										v-show="
											index === type_fisc.length - 1 &&
											type_fisc.length <= 3
										"
										class="flex flex-row items-center w-1/3 px-2 py-1 text-xs rounded-lg cursor-pointer place-self-center bg-lightblueaestima-100"
										@click="addFieldFisc(input, type_fisc)"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											width="10"
											height="10"
											viewBox="0 0 172 172"
											style="fill: #000000"
										>
											<g
												fill="none"
												fill-rule="nonzero"
												stroke="none"
												stroke-width="1"
												stroke-linecap="butt"
												stroke-linejoin="miter"
												stroke-miterlimit="10"
												stroke-dasharray=""
												stroke-dashoffset="0"
												font-family="none"
												font-weight="none"
												font-size="none"
												text-anchor="none"
												style="mix-blend-mode: normal"
											>
												<path
													d="M0,172v-172h172v172z"
													fill="none"
												/>
												<g fill="#ffffff">
													<path
														d="M79.38462,19.84615c-3.97956,0 -6.61538,2.63582 -6.61538,6.61538v46.30769h-46.30769c-3.97956,0 -6.61538,2.63582 -6.61538,6.61538v13.23077c0,3.97957 2.63582,6.61538 6.61538,6.61538h46.30769v46.30769c0,3.97957 2.63582,6.61538 6.61538,6.61538h13.23077c3.97957,0 6.61538,-2.63581 6.61538,-6.61538v-46.30769h46.30769c3.97957,0 6.61538,-2.63581 6.61538,-6.61538v-13.23077c0,-3.97956 -2.63581,-6.61538 -6.61538,-6.61538h-46.30769v-46.30769c0,-3.97956 -2.63581,-6.61538 -6.61538,-6.61538z"
													/>
												</g>
											</g>
										</svg>
										<p
											class="ml-2 font-semibold text-white"
										>
											Rajouter un associé
										</p>
									</div>

									<div
										v-if="index > 1"
										v-show="type_fisc.length > 1"
										class="flex flex-row items-center w-1/3 h-8 px-2 py-1 text-xs bg-red-600 rounded-lg cursor-pointer place-self-center"
										@click="
											removeFieldFisc(index, type_fisc)
										"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											width="10"
											height="10"
											viewBox="0 0 172 172"
											style="fill: #000000"
										>
											<g
												fill="none"
												fill-rule="nonzero"
												stroke="none"
												stroke-width="1"
												stroke-linecap="butt"
												stroke-linejoin="miter"
												stroke-miterlimit="10"
												stroke-dasharray=""
												stroke-dashoffset="0"
												font-family="none"
												font-weight="inherit"
												font-size="none"
												text-anchor="inherit"
												style="mix-blend-mode: normal"
											>
												<path
													d="M0,172v-172h172v172z"
													fill="none"
												/>
												<g fill="#ffffff">
													<path
														d="M143.78125,129.93029l-13.8768,13.85096c-2.53246,2.55829 -6.66707,2.55829 -9.22536,0l-34.67909,-34.65324l-34.65324,34.65324c-2.55829,2.55829 -6.71875,2.55829 -9.25121,0l-13.8768,-13.85096c-2.55829,-2.55829 -2.55829,-6.69291 0,-9.2512l34.65324,-34.67909l-34.65324,-34.65324c-2.53245,-2.58413 -2.53245,-6.74459 0,-9.25121l13.8768,-13.8768c2.53246,-2.55829 6.69291,-2.55829 9.25121,0l34.65324,34.67909l34.67909,-34.67909c2.55829,-2.55829 6.71875,-2.55829 9.22536,0l13.8768,13.85096c2.55829,2.55829 2.55829,6.71875 0.02584,9.27704l-34.67908,34.65324l34.65324,34.67909c2.55829,2.55829 2.55829,6.6929 0,9.2512z"
													/>
												</g>
											</g>
										</svg>
										<p
											class="ml-2 font-semibold text-white"
										>
											Supprimer
										</p>
									</div>
								</div>
							</div>
						</div>
						<!-- FIN ASSOCIE -->
						<!-- ESTIMATION DES FRAIS RELATIFS -->
						<div
							v-if="
								!formeJuridiqueSelected([
									'lnmf',
									'lnr',
									'lmnpbic',
									'sciirmf',
									'sciirr',
								])
							"
							class="relative w-3/4 px-6 py-4 mx-auto my-10 border-2 rounded-lg tips col-start-1 col-span-12"
						>
							<img
								class="absolute z-0 w-auto max-h-10 ampoule-picto"
								src="../assets/picto/light.png"
								alt=""
							/>
							<div class="flex flex-row justify-between">
								<p>
									Estimation des frais relatifs au régime
									fiscal sélectionné
								</p>
								<p class="font-bold">
									{{ fraisRelatifsRegimeFiscal }} €
								</p>
							</div>
						</div>
						<!-- FIN ESTIMATION DES FRAIS RELATIFS -->
						<!-- PERSONNALISATION -->
						<div
							v-if="!formeJuridiqueSelected(['lnmf', 'lnr'])"
							class="mt-10 col-span-9 col-start-2"
						>
							<div
								class="relative flex flex-row justify-between px-4 py-2 bg-white border border-black rounded-lg cursor-pointer"
								@click="
									isToggle.detailsFiscal =
										!isToggle.detailsFiscal
								"
							>
								<h2 class="text-normal">
									Personnaliser les autres frais liés à ce
									régime fiscal
								</h2>
								<div>
									<span
										v-if="!isToggle.detailsFiscal"
										class="absolute cursor-pointer right-4"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											width="34"
											height="34"
											viewBox="0 0 172 172"
											style="fill: #000000"
										>
											<g
												fill="none"
												fill-rule="nonzero"
												stroke="none"
												stroke-width="1"
												stroke-linecap="butt"
												stroke-linejoin="miter"
												stroke-miterlimit="10"
												stroke-dasharray=""
												stroke-dashoffset="0"
												font-family="none"
												font-weight="inherit"
												font-size="none"
												text-anchor="inherit"
												style="mix-blend-mode: normal"
											>
												<path
													d="M0,172v-172h172v172z"
													fill="none"
												/>
												<g id="Layer_1" fill="#444b54">
													<path
														d="M123.49063,63.02188l-37.49063,37.49063l-37.49062,-37.49062c-1.6125,-1.6125 -4.16562,-1.6125 -5.64375,0c-1.6125,1.6125 -1.6125,4.16562 0,5.64375l40.3125,40.3125c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82187,-1.20938l40.3125,-40.3125c1.6125,-1.6125 1.6125,-4.16563 0,-5.64375c-1.6125,-1.6125 -4.03125,-1.6125 -5.64375,0z"
													/>
												</g>
											</g>
										</svg>
									</span>
									<span
										v-else
										class="absolute cursor-pointer right-4"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											x="0px"
											y="0px"
											width="34"
											height="34"
											viewBox="0 0 172 172"
											style="fill: #000000"
										>
											<g
												fill="none"
												fill-rule="nonzero"
												stroke="none"
												stroke-width="1"
												stroke-linecap="butt"
												stroke-linejoin="miter"
												stroke-miterlimit="10"
												stroke-dasharray=""
												stroke-dashoffset="0"
												font-family="none"
												font-weight="inherit"
												font-size="none"
												text-anchor="inherit"
												style="mix-blend-mode: normal"
											>
												<path
													d="M0,172v-172h172v172z"
													fill="none"
												/>
												<g id="Layer_1" fill="#444b54">
													<path
														d="M42.86563,108.97813c1.6125,1.6125 4.16562,1.6125 5.64375,0l37.49063,-37.49062l37.49063,37.49062c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82188,-1.20938c1.6125,-1.6125 1.6125,-4.16562 0,-5.64375l-40.3125,-40.3125c-1.6125,-1.6125 -4.16563,-1.6125 -5.64375,0l-40.3125,40.3125c-1.6125,1.6125 -1.6125,4.03125 0,5.64375z"
													/>
												</g>
											</g>
										</svg>
									</span>
								</div>
							</div>
						</div>
						<!-- FIN PERSONNALISATION -->
					</div>
					<!-- PERSONNALISATION FORM -->
					<div
						v-show="isToggle.detailsFiscal"
						class="grid grid-cols-12"
					>
						<!-- HONORAIRE COMPTABLES -->
						<div
							v-show="
								formeJuridiqueSelectedContain([
									'lmnpr',
									'lmp',
									'sciis',
									'sas',
									'sasu',
								])
							"
							class="flex flex-col mt-5 col-start-3 col-span-3"
						>
							<label
								for="fcomptable"
								class="my-auto mr-2 texte-label"
								>Honoraires comptables + CGA si présente
								<div class="euro-input">
									<input
										id="fcomptable"
										v-model="fcomptable.val"
										type="number"
										step="any"
										class="block w-full px-3 py-2 mt-3 border form-input"
									/>
								</div>
							</label>
						</div>
						<!-- FIN HONORAIRE COMPTABLES -->
						<!-- CFE -->
						<div
							v-show="
								formeJuridiqueSelectedContain([
									'lmnpr',
									'lmp',
									'sciis',
									'sas',
									'sasu',
									'lmnpbic',
								])
							"
							class="mt-5 col-start-3 col-span-3"
						>
							<label
								for="fcotisation"
								class="my-auto mr-2 texte-label"
								>Cotisation foncière des entreprises (CFE) et
								autres taxes
								<div class="euro-input">
									<div
										v-show="
											formeJuridiqueSelectedContain([
												'lmnpr',
												'lmp',
												'sciis',
												'sas',
												'sasu',
												'lmnpbic',
											])
										"
										class="col-start-2 col-span-3"
									>
										<input
											id="fcotisation"
											v-model="fcotisation.val"
											type="number"
											step="any"
											class="block w-full px-3 py-2 mt-3 border form-input"
										/>
									</div>
								</div>
							</label>
						</div>
						<!-- FIN CFE -->
						<!-- CRL -->
						<!-- <div v-show="formeJuridiqueSelectedContain(['sciis', 'sas', 'sasu'])" class="mt-5 col-start-2 col-span-6">
              <div class="flex flex-row">
                <label for="fcrl" class="my-auto mr-2 texte-label">Contribution sur les revenus locatifs (CRL)</label>
                <QuestionMarkv2 class="" :text="`${infobulles.nouveauChampsCRL}`" />
              </div>
            </div>
            <div v-show="formeJuridiqueSelectedContain(['sciis', 'sas', 'sasu'])" class="col-start-2 col-span-3">
              <div class="flex flex-row">
                <div class="mt-2 place-self-center">
                  <span v-if="fcrl.fcrlEuro" class="h-full px-6 mr-4 font-bold cursor-pointer bg-3D-btn" @click="onChangeSigleAchatBienBtn('fcrl', false)">€</span>
                  <span v-else-if="!fcrl.fcrlEuro" class="h-full px-6 mr-4 font-bold cursor-pointer bg-3D-btn" @click="onChangeSigleAchatBienBtn('fcrl', true)">%</span>
                </div>
                <input id="fcrl" v-model="onChangeSigleFcrlComputed" type="number" step="any" class="block w-full px-3 py-2 mt-3 border form-input">
              </div>
            </div> -->
						<!-- <input type="number" step="1" min="0" v-model="onChangeSigleFnotaireComputed" id="frais_notaire" class="block w-full px-3 py-2 mt-1 border form-input" /> -->
						<!-- FIN CRL -->
						<!-- FRAIS DE CONSTITUTION -->
						<div
							v-show="
								formeJuridiqueSelectedContain([
									'sciirmf',
									'sciirr',
									'sciis',
									'sas',
									'sasu',
								])
							"
							class="mt-5 col-start-3 col-span-3"
						>
							<label
								for="fsociete"
								class="my-auto mr-2 texte-label"
								>Frais de constitution de la société
								<div class="euro-input">
									<div
										v-show="
											formeJuridiqueSelectedContain([
												'sciirmf',
												'sciirr',
												'sciis',
												'sas',
												'sasu',
											])
										"
										class="col-start-2 col-span-3"
									>
										<input
											id="fsociete"
											v-model="fsociete"
											type="number"
											step="any"
											class="block w-full px-3 py-2 mt-3 border form-input"
										/>
									</div>
								</div>
							</label>
						</div>
						<!-- FIN FRAIS DE CONSTITUTION -->
						<!-- FRAIS DE CONSTITUTION -->
						<div
							v-show="formeJuridiqueSelectedContain(['lmp'])"
							class="mt-5 col-start-3 col-span-6"
						>
							<label for="fssi" class="my-auto mr-2 texte-label"
								>Cotisations sociales SSI pour le régime
								LMP</label
							>
						</div>
						<div
							v-show="formeJuridiqueSelectedContain(['lmp'])"
							class="col-start-3 col-span-3"
						>
							<input
								id="fssi"
								v-model="fssi"
								type="number"
								step="any"
								class="block w-full px-3 py-2 mt-3 border form-input"
							/>
						</div>
						<!-- FIN FRAIS DE CONSTITUTION -->
						<!-- VERSEMENT DES DIVIDENDES -->
						<div
							v-show="
								formeJuridiqueSelected([
									'sciis',
									'sasis',
									'sasuis',
								])
							"
							class="flex flex-row justify-between mt-5 col-start-3 col-span-3"
						>
							<label
								for="dividende"
								class="my-auto mr-2 texte-label"
								>Versement des dividendes</label
							>
							<input
								id="dividende"
								v-model="dividendeSCI"
								type="checkbox"
								class="w-4 h-4 my-auto border-gray-300 rounded focus:ring-lightblueaestima-100 text-lightblueaestima-100"
							/>
						</div>
						<!-- FIN VERSEMENT DES DIVIDENDES -->
						<!-- IMPOSITION DES DIVIDENDES -->
						<div
							v-show="
								formeJuridiqueSelected([
									'sciis',
									'sasis',
									'sasuis',
								]) && dividendeSCI
							"
							class="flex flex-row justify-between mt-5 col-start-6 col-span-5"
						>
							<label
								for="modeimposition"
								class="my-auto mr-2 texte-label"
								>Mode d'imposition des dividendes</label
							>
							<select
								id="modeimposition"
								v-model="modeimposition"
								required
								class="w-2/3 h-10 py-0 pl-2 my-auto mt-3 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
								name="modeimposition"
							>
								<option value="flax_tax">Flat tax</option>
								<!-- <option value="ir">Barème IR RCM</option> -->
							</select>
						</div>
						<!-- FIN IMPOSITION DES DIVIDENDES -->
						<!-- AMORTISSEMENT -->
						<div
							v-if="
								!formeJuridiqueSelected([
									'lnmf',
									'lnr',
									'lmnpbic',
									'sciirmf',
									'sciirr',
								])
							"
							class="mt-5 col-start-3 col-span-4"
						>
							<a
								class="inline-flex items-center justify-center px-4 py-2 text-xs font-medium text-center cursor-pointer leading-6 bouton"
								@click="showAmortissementComposantModal"
								>Accéder au tableau d’amortissement par
								composant</a
							>
							<AmortissementParComposant
								v-show="amortissementComposantModal"
								:amortissements="amortissementsparcomposant"
								@close="closeAmortissementComposantModal"
							/>
						</div>
						<!-- FIN AMORTISSEMENT -->
					</div>
				</div>
				<!-- FIN PERSONNALISATION FORM -->
			</div>
		</div>

		<div class="my-6">
			<div
				class="flex items-center justify-center px-4 py-3 text-right sm:px-6"
			>
				<span class="inline-flex rounded-md shadow-sm">
					<button
						v-if="projectSelected === null"
						type="submit"
						class="inline-flex items-center justify-center py-4 mb-16 text-sm font-bold whitespace-no-wrap px-15 leading-6 bouton"
					>
						Enregistrer et créer la simulation
					</button>

					<button
						v-else
						:disabled="modalPDFLoader"
						type="submit"
						:class="{
							'bg-lightblueaestima-100': modalPDFLoader,
							'bg-grey-400': modalPDFLoader,
						}"
						class="inline-flex items-center justify-center py-4 mb-16 text-sm font-bold whitespace-no-wrap px-15 leading-6 bouton"
					>
						Enregistrer la modification
					</button>
				</span>
			</div>
		</div>
	</form>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
import QuestionMarkv2 from "@/components/SVG/QuestionMarkv2.vue";
import AutoComplete from "@/components/API/AutoComplete";
import Surface from "@/components/popup/Surface";
import AppelDeFond from "@/components/popup/AppelDeFond";
import AmortissementParComposant from "@/components/popup/AmortissementParComposant";
import Travaux from "@/components/popup/Travaux";
import Amortissement from "@/components/popup/Amortissement";
import Sidebar from "@/components/Sidebar";
import JSONPrix from "@/assets/cityData/price.json";
import JSONEvolution from "@/assets/cityData/priceEvolution.json";
import ProjectTools from "@/tools/Project";

export default {
	name: "Simulator",
	components: {
		Travaux,
		Amortissement,
		AppelDeFond,
		QuestionMarkv2,
		AutoComplete,
		Surface,
		Sidebar,
		AmortissementParComposant,
	},
	data() {
		return {
			changementDeFiscalite: false,
			nouveauLoyerMensuel: 0,
			nouveauHgestionloc: 0,
			nouveauGLI: 0,
			nouveauPNO: 0,
			modifAdress: false,
			doubleDFTravauxEnergie: false,
			pinelplus: false,
			dureeDetentionMin: "4",
			data_frais_notaire: null,
			amortData: [],
			isloading_modal: false,
			searchLoc: "",
			evolution: {
				unAn: null,
				cinqAns: null,
			},
			averagePrice: {
				basAppartement: null,
				hautAppartement: null,
				hautMaison: null,
				basMaison: null,
			},
			file: {
				imgPrincipale: null,
				imgPhoto1: null,
				imgPhoto2: null,
			},
			imgs: {
				principale: null,
				photo1: null,
				photo2: null,
			},
			message: "",
			isSCI: false,
			isNue: true,
			isfileError: false,
			fileMessage: "",
			auto: false,
			error: {
				pinel: false,
			},
			warning: {
				isFN: false,
				isFA: false,
				isTVL: false,
				isTRA: false,
				isHGL: false,
				isPNO: false,
				message: "La valeur saisie est anormalement élevée !",
			},
			isSecondForm: false,
			name: "",
			// dateacqui: '2021-6',
			trvametot: 0,
			trvame: 0,
			trconstruction: 0,
			poidstrv: 0,
			datetrv: "",
			// DEBUT SIMULATION DATAS
			// selected: {
			categ: "appartement",
			type: "T2",
			typeexploitation: "classique_ancien",
			loc: {
				carac: {
					city: "",
				},
			},
			loyermoy: [],
			typeloc: "",
			surface: 40,
			surfaceannexe: 0,
			datesignature: "",
			dateacqui: `${new Date().getFullYear()}-${
				new Date().getMonth() + 1
			}`,
			occupation: "",
			dpe: "",
			engagement: "",
			zonage: "",
			zonageMalraux: "PSMV_QAD",
			interlocuteur: {
				why: "",
				name: "",
				email: "",
				phone: "",
			},
			immeuble: {
				type: "",
				nombre_lot: 0,
				nombre_etage: 0,
				etage_bien: 0,
				type_chauffage: 0,
				option: [],
				etat_facade: "",
				etat_toiture: "",
				installation_electrique: "",
				installation_plomberie: "",
				transport: "",
				commerces: "",
				lots: [],
			},
			// Valeur par défaut
			amortissementsparcomposant: {
				terrain: {
					pourcentage: 20,
					duree: 0,
				},
				gros_oeuvre: {
					pourcentage: 40,
					duree: 50,
				},
				facade_etancheite: {
					pourcentage: 10,
					duree: 20,
				},
				installation_generales_techniques: {
					pourcentage: 15,
					duree: 15,
				},
				agencement: {
					pourcentage: 15,
					duree: 15,
				},
				ameublement: {
					duree: 7,
					valAmo: 0,
				},
			},
			appelDeFond: {
				reservation: 5,
				reservationMois: 1,
				achevementFondation: 30,
				achevementFondationMois: 0,
				miseEauAir: 35,
				miseEauAirMois: 0,
				achevementTravaux: 25,
				achevementTravauxMois: 0,
				livraisonRemiseClef: 5,
				livraisonRemiseClefMois: 0,
			},
			cumulADF: [],
			travauxReno: {
				year_0: 0,
				year_1: 0,
				year_2: 0,
				year_3: 0,
				year_4: 0,
				year_5: 0,
				year_6: 0,
				year_7: 0,
				year_8: 0,
				year_9: 0,
				year_10: 0,
				year_11: 0,
				year_12: 0,
				year_13: 0,
				year_14: 0,
				year_15: 0,
				year_16: 0,
				year_17: 0,
				year_18: 0,
				year_19: 0,
				year_20: 0,
				year_21: 0,
				year_22: 0,
				year_23: 0,
				year_24: 0,
				year_25: 0,
				year_26: 0,
				year_27: 0,
				year_28: 0,
				year_29: 0,
			},
			travauxConstru: {
				year_0: 0,
				year_1: 0,
				year_2: 0,
				year_3: 0,
				year_4: 0,
				year_5: 0,
				year_6: 0,
				year_7: 0,
				year_8: 0,
				year_9: 0,
				year_10: 0,
				year_11: 0,
				year_12: 0,
				year_13: 0,
				year_14: 0,
				year_15: 0,
				year_16: 0,
				year_17: 0,
				year_18: 0,
				year_19: 0,
				year_20: 0,
				year_21: 0,
				year_22: 0,
				year_23: 0,
				year_24: 0,
				year_25: 0,
				year_26: 0,
				year_27: 0,
				year_28: 0,
				year_29: 0,
			},
			exonerationTaxeFonciere: false,
			surfaceModal: false,
			appelDeFondModal: false,
			amortissementComposantModal: false,
			travauxModal: false,
			amortissementModal: false,
			premiereUtilModal: false,
			isToggle: {
				details: false,
				detailsRevenusLocatif: false,
				detailsChargesLocatives: false,
				detailsEmprunt: false,
				detailsFiscal: false,
			},
			typeloc_surface: [
				{
					id: 1,
					type: "",
					surface: 0,
					isTaken: false,
					loyer: 0,
				},
			],
			loyerMaximumPraticable: 0,
			type_fisc: [
				{
					// id: 1,
					idInvest: "",
					nameInvest: "",
					capital: 50,
					apport: 0,
					tmi: 30,
				},
			],
			modefinancement: "apport",
			modeimposition: "flax_tax",
			changeAt: 0,
			newformejuridique: "",
			changementFormeJuridique: 0,
			dividendeSCI: false,
			fameublement: 0,
			fameublementDeduit: 0,
			fameublementDeduction: 0,
			fsociete: 400,
			fssi: 0,
			more_info: "",
			// => buyprice
			buypricetot: 0,
			isbuyprice: false,
			buyprice: 0,
			// <=
			fnotairetot: 0,
			fnotaire: {
				val: 0,
				per: 0,
				fnotaireEuro: true,
				isAuto: false,
				valAuto: 0,
				fnotairePercentage: {
					val: 0.075,
					per: 7.5,
				},
			},
			fagencetot: 0,
			fagence: {
				val: 0,
				per: 0,
				fagenceEuro: true,
				isAuto: false,
				valAuto: 0,
				fagencePercentage: {
					val: 0.07,
					per: 7,
				},
			},
			coutacquisition: 0,
			loyermensuel: 0,
			revenulocatif: 0,
			rendementbrut: 0,
			// TO-DO calcul
			differeDeMiseEnLocation: 0,
			trevalorisation: {
				val: 0,
				per: 1,
			},
			rvacanceloc: {
				val: 0,
				//   per: 0,
				//   isAuto: false,
				//   valAuto: 0,
				//   rvacancelocPercentage: {
				//     val: 0.02,
				//     per: 2,
				//   },
			},
			tauxActualisationRetenu: 0,
			tfonciere: {
				val: 0,
				per: 0,
				tfonciereEuro: true,
				isAuto: false,
				valAuto: 0,
				tfoncierePercentage: {
					val: 0.085,
					per: 8.5,
				},
			},
			hgestionloc: {
				val: 0,
				per: 0,
				hgestionlocEuro: true,
				isAuto: false,
				valAuto: 0,
				hgestionlocPercentage: {
					val: 0.09,
					per: 9,
				},
			},
			chargecopro: {
				val: 0,
				per: 0,
				chargecoproEuro: true,
				isAuto: false,
				valAuto: 0,
				chargecoproPercentage: {
					val: 0.08,
					per: 8,
				},
			},
			apno: {
				val: 0,
				per: 0,
				apnoEuro: true,
				isAuto: false,
				valAuto: 0,
				apnoPercentage: {
					val: 0.02,
					per: 2,
				},
			},
			chargediverse: {
				val: 0,
				per: 0,
				chargediverseEuro: true,
				isAuto: false,
				valAuto: 0,
				chargediversePercentage: {
					val: 0.01,
					per: 1,
				},
			},
			gli: {
				val: 0,
				per: 0,
				gliEuro: true,
				isAuto: false,
				valAuto: 0,
				gliPercentage: {
					val: 0.04,
					per: 4,
				},
			},
			provision: {
				val: 0,
				per: 0,
				provisionEuro: true,
				isAuto: false,
				valAuto: 0,
				provisionPercentage: {
					val: 0.03,
					per: 3,
				},
			},
			totalOPEX: {
				val: 0,
				per: 0,
				valper: 0,
			},
			trevalorisationOPEX: {
				val: 1,
			},
			// },

			// FIN SIMULATION DATAS

			montantempr: {
				val: 0,
				per: 0,
				valper: 0,
			},

			montantemprunte: {
				val: 0,
				per: 0,
				montantemprunteEuro: true,
				isAuto: false,
				valAuto: 0,
				montantempruntePercentage: {
					val: 1,
					per: 100,
				},
			},

			apportPersoComposant: 0,
			apportPersoApport: 20000,

			apportfond: {
				val: 0,
				per: 0,
				apportfondEuro: true,
				isAuto: false,
				valAuto: 0,
				apportfondPercentage: {
					val: 0,
					per: 0,
				},
			},

			dureeempr: {
				val: 20,
				valmonth: 0,
				isAuto: false,
				valAuto: 240,
			},

			tinteretbon: 0,

			tinteretempr: {
				val: 0.013,
				per: 1.3,
				isAuto: false,
				tinteretemprPercentage: {
					val: 0.013,
					per: 1.3,
				},
			},

			tassuranceempr: {
				val: 0.0025,
				per: 0.25,
				isAuto: false,
				tassuranceemprPercentage: {
					val: 0.0025,
					per: 0.25,
				},
			},
			ftvatot: 0,
			fbancairetot: 0,
			empruntOption: {
				bien: "yes",
				tva: "yes",
				notaire: "yes",
				banque: "yes",
				agence: "yes",
				travaux: "yes",
				total: 0,
			},
			fbancaire: {
				val: 0,
				per: 0,
				fbancaireEuro: true,
				isAuto: false,
				valAuto: 0,
				fbancairePercentage: {
					val: 0.005,
					per: 0.5,
				},
			},
			travauxTotaux: 0,

			fgarantie: {
				val: 0,
				per: 0,
				fgarantieEuro: true,
				isAuto: false,
				valAuto: 0,
				fgarantiePercentage: {
					val: 0.02,
					per: 2,
				},
			},
			remboursementemprunt: "pas_differe",
			dureediffere: 24,
			ira: {
				val: null,
			},

			// fcrl: {
			//   val: 0,
			//   per: 2.5,
			//   fcrlEuro: false,
			//   isAuto: false,
			//   valAuto: 0,
			//   fcrlPercentage: {
			//     val: 0.025,
			//     per: 2.5,
			//   },
			// },

			annuite: {
				val: 0,
				per: 0,
				valper: 0,
			},

			tactualisation: {
				val: 0,
				per: 2,
			},

			formejuridique: [
				{
					selected: true,
					code: "lnmf",
					text: "Location nue (Régime micro foncier)",
					pinel: true,
				},
				{
					selected: false,
					code: "lnr",
					text: "Location nue (Régime réel)",
					pinel: true,
				},
				{
					selected: false,
					code: "lmnpbic",
					text: "LMNP Location meublée (Régime micro BIC)",
					pinel: false,
				},
				{
					selected: false,
					code: "lmnpr",
					text: "LMNP Location meublée (Régime réel)",
					pinel: false,
				},
				// {
				//   selected: false,
				//   code: 'sasis',
				//   text: 'Société anonyme simplifiée (SAS) à l\'IS',
				//   pinel: false,
				// },
				{
					selected: false,
					code: "sasuis",
					text: "Société anonyme simplifiée unipersonnelle (SASU) à l'IS",
					pinel: false,
				},
				{
					selected: false,
					code: "sciis",
					text: "Société civile immobilière (SCI) à l'IS ou SAS",
					pinel: false,
				},
				{
					selected: false,
					code: "sciirmf",
					text: "Société civile immobilière (SCI) à l'IR (régime micro foncier)",
					pinel: true,
				},
				{
					selected: false,
					code: "sciirr",
					text: "Société civile immobilière (SCI) à l'IR (régime réel)",
					pinel: true,
				},
				// {
				//   selected: false,
				//   code: 'lmp',
				//   text: 'LMP Location meublée professionnelle',
				//   pinel: false,
				// },
			],
			tmi: {
				val: 0,
				per: 0,
				isAuto: false,
				tmiPercentage: {
					val: 0.3,
					per: 30,
				},
			},
			fcomptable: {
				val: 1250,
			},

			fcotisation: {
				val: 500,
			},

			// TO UPGRADE
			dureedetention: {
				val: 20,
				valmonth: 240,
				isAuto: false,
				valAuto: 240,
			},
			// dureedetention: {
			//   val: 20,
			// },

			daterevente: "",
			achevementtravaux: "",
			estimreval: {
				val: 0,
				per: 0,
			},
			// TO REMOVE
			estimprixrevente: 0,

			plusvalue: 0,

			augmvaleur: 0,

			// Excel Cagnotte et base
			resComptSoumisImpot: [],

			resCoutTotalAcqui: [],

			timelineFonc: {},
			resCoutTotalFonc: {},
			resOperationNetFonc: {},
			resInvTravFonc: {},
			resFluxAIFonc: {},

			// Variable pour rassembler les valeurs indispensble au calcul de "Cagnotte et base"
			cagnotte_base: {
				resRevLoc: [],
				resFraisExploit: [],
				resStructureSCI: [],
				resInvTrav: [],
				resItr: [],
				resFraisBanc: [],
				resAssEmpr: [],
				resFraisNotaire: [],
				resFraisAgenceImmo: [],
			},
			// Variable pour rassembler les valeurs indispensble au calcul de la partie bancaire
			calcul_global: {
				resPrixAchat: [],
				resPrixRevente: [],
			},
			resultats: {},
			tva: {
				val: 0,
				per: 20,
				toggle: true,
			},
			infobulles: {
				changementDeFiscalite:
					"Si vous changez de régime fiscal, vous devrez attendre 2 ans avant de pouvoir revendre votre bien.",
				rendementBrut:
					"Ce rendement indicatif est calculé sur la base des loyers hors charges / coût total d'acquisition hors travaux",
				pinelplus:
					"Pour bénéficier des taux de réduction 2022, pour rappel 18% sur 9 ans et 21% sur 12 ans, le bien devra respecter plusieurs critères dont un emplacement dans les quartiers prioritaires de la politique de la ville (QPV), une meilleure qualité d'usage (surface, espaces extérieurs, agencement) ou encore avoir un DPE A (ou B pour le réhabilité). Liste non exhaustive.",
				fraisAmeublement:
					"Inscrivez ici le montant total des frais d'ameublement du bien. Sur cette somme, 500€ seront déductibles fiscalement en tant que charges. Le solde sera amorti selon la durée que vous aurez sélectionné dans le tableau d'amortissement comptable par composant ci-dessous.",
				fraisNotaires:
					"Ce que l’on appelle communément les frais de notaire sont majoritairement constitués de taxes reversées au département, à la commune et à l’État. Une partie concerne les formalités pour enregistrer la mutation et environ un quart des frais de notaire représentent la réelle rémunération du notaire.",
				fraisNotairesDetails:
					"Insérer un texte « plus de détails sur ces frais » à côté du champ avec l’affichage de la valeur exacte des droits d’enregistrement, formalités et débours, honoraires TTC du notaire. Ces infos sur sur la feuille « fiscalité » de l’excel.",
				prixAchatMoyenPourXm2:
					"Cette estimation du prix au m2 se base sur les transactions immobilières de la ville entière. Attention, selon la ville, il peut y avoir d’importantes disparités par quartier.",
				deleguerGestionOrganisme:
					"Vous avez choisi le dispositif fiscal Loc’Avantages. Pour profiter d’une plus grande réduction d’impôts, vous pouvez déléguer la gestion de votre bien à un organisme d’intermédiation comme Solibail. Ce choix est facultatif pour le dispositif Loc1 et Loc2 mais devient obligatoire si vous choisissez Loc3. Ce dernier vous confèrera une réduction d’impôts de 65% du loyer annuel pratiqué.",
				revalorisationAnnuelleMoyenne:
					"Attention cela ne signifie pas que les prix évolueront de la même manière sur les 5, 10 ou 20 prochaines années. Personne ne peut le définir.",
				// dans Renovation des travaux le tableau, a cote du petit renovation
				MontantTravauxARealiser:
					"N’inscrivez ici que les travaux d’entretien, de rénovation ou d’amélioration du bien. Comme le précise l’article 31 du code général des impôts, les travaux de construction, d’agrandissement et de reconstruction ne sont pas déductibles fiscalement. L’ouverture de combles et leur aménagement, par exemple, peuvent être considérés comme des travaux d’agrandissement de la surface locative.",
				annuiteConstanteEmprunt:
					"L’annuité constante représente le total des mensualités de remboursement d’emprunt par an qui comprend un part des intérêts dus à la banque et une part du capital remboursé. La part relative à l'assurance emprunteur est également comprise dans ce calcul. Plus de détails en cliquant sur 'Accéder au tableau d'amortissement d'emprunt'",
				regimeFiscalSASouSASU:
					"Vous êtes non redevable à la TVA même en société à l’IS tant que votre chiffre d’affaires, ici vos revenus locatifs annuels, ne dépassent pas 82 800€. Ce seuil est fixé par l’article 293 B du Code général des impôts (CGI).",
				nouveauChampsCRL:
					"Votre client louera son bien en SAS, SASU ou SCI à l’IS ? Pensez à provisionner la taxe contribution sur les revenus locatifs (CRL) qui correspond à 2,5% de ses revenus locatifs annuels. Plus d’infos <u><a target='_blank' href='https://www.service-public.fr/professionnels-entreprises/vosdroits/F32939'>ici</a></u>",
				travauxConstruction:
					"Sont considérés comme des travaux de construction, de reconstruction ou d’agrandissement tout travaux qui visent soit à créer ou augmenter de la surface habitable du bien soit à la modifier lourdement (modification du gros oeuvre). Par exemple, l’aménagement de combles peut être considéré comme des travaux d’agrandissement. Ces travaux ne seront pas déductibles des revenus fonciers de votre client. En revanche, s’il investit en société à l’IS (SCI, SAS, SARL) ou en LMNP, nous intégrerons ces travaux dans la base amortissable.",
				coutRevientTotal:
					"Selon le dispositif fiscal choisi, il faut réaliser des travaux dans le logement pour bénéficier d’une réduction d’impôts. C’est notamment le cas pour PINEL ANCIEN ou DENORMANDIE. Le coût de revient total est donc l’addition du prix d’achat majoré des frais de notaire, frais d’agence, travaux de rénovation (pour le Denormandie) ou travaux de reconstruction /transformation (pour le Pinel Ancien) ainsi que des frais de garantie bancaire. Ce montant servira de base au calcul de la réduction d’impôt relative au dispositif fiscal retenu.",
				travauxRenovationEntretien:
					"Comme le précise l’article 31 du code général des impôts, les travaux de rénovation ou d’entretien sont déductibles des revenus fonciers. Nous les déduirons donc de la base imposable de votre client.",
				exonerationTaxeFonciere:
					"Lors d’une construction nouvelle, ce qui est le cas dans le cadre du dispositif PINEL, votre client pourra être exonéré durant deux ans de la taxe foncière sur son bien locatif à compter de la fin des travaux et à condition qu’il fasse une déclaration auprès du centre des finances publiques.",
				surfacesAnnexes:
					"Il existe un calcul spécifique pour les surfaces annexes du logement soumis aux dispositifs fiscaux. Ouvrez la fenêtre pour y rentrer vos détails. Sachez que ces surfaces annexes seront plafonnées à 8m2 par logement. Attention, en appartement, la surface du stationnement n’est pas comprise.",
				dureeEngagement:
				"Pour voir l’impact maximum de l’avantage fiscal, sélectionnez 12 ans d’engagement. Dans votre déclaration, vous pouvez très bien commencer par 6 ans puis prolonger sur 9 ans puis enfin 12 ans si vous le désirez. Votre client aura ainsi les mains libres s’il veut revendre au bout de 6 ans. Dans tous les cas, la réduction d’impôts sera de 1,5% par an de la première à la 6ème année (si engagement 6 ans), 1,33% par an de la première à la 9ème année (si engagement 9 ans). En revanche si l'engagement est de 12 ans, on applique 1,33% pendant 9 ans et 0,67% par an de la 10ème à la 12ème année.",
				surfaceFiscale:
					"Cette surface servira de base pour le calcul du loyer plafonné à appliquer dans le cadre des dispositifs fiscaux. Elle est calculée en tenant compte de la surface habitable à laquelle on rajoute la surface des annexes (plafonnée elle aussi).",
				terrasseRDCJardin:
					"Attention, dans ce cas, leur surface n’est pas prise en compte dans le calcul de la surface fiscal sauf si un parking sous-terrain se situe sous ce jardin ou terrasse.",
				loyerMensuelPraticable:
					"La location avec ces dispositifs fiscaux est encadrée. Les locataires doivent respecter les plafonds de ressources en fonction de la zone et de la composition du foyer fiscal. Plus d'infos <u><a target='_blank' href='https://www.service-public.fr/particuliers/vosdroits/F31151'>ici</a></u>.",
				loyerMaximumPraticable:
					"Cette donnée est informative et représente le loyer de marché officiel de votre ville d’investissement adapté au dispositif choisie (Loc1, 2 ou 3). Faites-vous confirmer ce montant directement sur le <u><a target='_blank' href='https://monprojet.anah.gouv.fr/pb/inscription/nouvelle sur le mot ANAH)'>site de l’ANAH </a></u>.",
				loyersMaximumPraticablePINELouDENORMANDIE:
					"Attention, dans le cadre d’investissement avec dispositifs fiscaux, ne dépassez jamais les plafonds de loyers. Plusieurs investisseurs ont dû rembourser leur économie d’impôts déjà perçue à cause de quelques euros de plus sur leur loyer mensuel.",
				dateAchevementTravauxMiseLocation:
					"Cette date est importante car c’est elle qui déclenchera le début de la réduction d’impôts relative au dispositif fiscal que vous avez sélectionné. Cette dernière débute précisément au moment de la DAT (déclaration d’achèvement des travaux).",
				differeMiseLocationPremiereAnnee:
					"Votre client compte faire des travaux ? Il ne pense pas louer son bien tout de suite ? Mettez ici le nombre de mois durant lesquels le logement ne sera pas loué. Cela s’appliquera uniquement la première année d’exploitation.",
				typeDiffereEmprunt:
					"Dans le cadre d’un investissement locatif et notamment dans le cadre d’un achat dans le neuf, la banque peut accorder un différé de remboursement d’emprunt à l’investisseur. Si ce dernier est partiel, l’investisseur remboursera uniquement les intérêts de l’emprunt. S’il est total, l’investisseur ne paiera ni capital ni intérêts d’emprunts jusqu’à la fin du différé.",
				pourcentageDeductionFoncierAmortissementComptable:
					"Cette donnée vous permettra d’affiner la base d’amortissement comptable du bien. En effet, dans certaines villes, le foncier correspond à une grande proportion dans la valeur totale d’un bien. Par exemple la valeur du foncier sur un appartement à Paris sera plus importante (estimé à 30%) que celle d’un appartement dans un village de campagne.",
				ChangementFiscaliteEnCoursDeProjet:
					"Aestima vous permet de changer de régime fiscal au cours d’un même projet locatif. Par exemple, au terme d’un engagement PINEL de 12 ans, vous pouvez très bien simuler le projet de votre client en passant sur un LMNP au réel jusqu’à la revente. Attention, si vous choisissez LMNP au réel, LMP ou un montage en société en 1er choix, le changement de fiscalité ne sera pas possible.",
				cotisationsSocialesSSI_LMP:
					"Depuis le 1er janvier 2021, les investisseurs soumis au régime LMP doivent payer une cotisation au régime de la Sécurité Sociale des Indépendants (SSI). Cette cotisation est calculée sur la base imposable et équivaut en moyenne à 35%. Sachez que si la base imposable est nulle, une cotisation minimale de 1145€ sera à payer.",
				estimationRevente:
					"Ce taux vous permet de définir quelle sera l’évolution annuelle du prix du bien. Vous pouvez voir en direct dans l'encadré violet ci-dessous, la valeur estimée du bien locatif à la revente. Plus d’infos dans le <u><a target='_blank' href='https://www.aestima-immo.com/lexique'>lexique</a></u>.",
				taxeFonciere:
					"Inscrivez la taxe foncière déduite de la TEOM (taxe d’enlèvement des ordures ménagères) si votre client souhaite la facturer à son locataire. Plus d'infos dans le <a href='../lexique' target='_blank' class='font-medium underline'> lexique. </a>",
				chargesDeCopropriete:
					"Inscrivez les charges de copropriété non récupérables sur le locataire. Plus d'infos dans le <a href='../lexique' target='_blank' class='font-medium underline'> lexique. </a>",
				assuranceProprietaireNonOccupant:
					"Assurance qui assure le logement de votre client en tant que propriétaire non occupant. Plus d'infos dans le <a href='../lexique' target='_blank' class='font-medium underline'> lexique. </a>",
				chargesDeGestionLocative:
					"Honoraires de gestion si votre client souhaite confier la mise en location et la gestion de son bien et des locataires à une agence immobilière.",
				garantieLoyerImpayes:
					"Assurance qui permet de couvrir votre client si le locataire arrête de payer ses loyers. Plus d'infos dans le <a href='../lexique' target='_blank' class='font-medium underline'> lexique. </a>",
				provisionPetitTravauxEtAutresCharges:
					"Anticipez des petites réparations dans votre prévisionnel (chaudière à changer, problème électrique, etc). Pour autres charges, cela peut être les frais de ménage, alarme, abonnements divers, etc. Attention, ces sommes seront prises en compte chaque année.",
				tauxActualisation:
					"Ce taux représente le coût du capital. Il permet une meilleure précision sur la réelle rentabilité de l’investissement en mesurant à quelle vitesse l’argent va se déprécier dans le temps. Vous pouvez l’associer au taux d’inflation. Plus d’infos dans le <a href='../lexique' target='_blank' class='font-medium underline'> lexique. </a>",
				IRL: "Taux de revalorisation annuelle du montant des loyers. Ce taux est plafonné et mis à jour trimestriellement sur https://www.service-public.fr/particuliers/vosdroits/F13723. Plus d’infos dans le <a href='../lexique' target='_blank' class='font-medium underline'> lexique. </a>",
				vacanceLocative:
					"Nombre de jours de loyers perdus (par an) entre le départ d’un locataire et l’arrivée d’un nouveau. Plus d’infos dans le <a href='../lexique' target='_blank' class='font-medium underline'> lexique. </a>",
				honoraireConseiller:
					"Insérez ici les frais d'agence ou de chasse immobilière, honoraires de conseil, etc.",
				travauxDPE:
					"Depuis le 1er janvier 2023, le déficit foncier passe de 10700€ à 21400€ si les travaux réalisés dans le logement permettent une amélioration du DPE de G et F à C, B ou A.  Ce doublement est effectif jusqu'au 31 décembre 2025.",
			},
		};
	},
	metaInfo() {
		return {
			title: "Nouvelle Simulation - Aestima",
			meta: [
				{
					name: "description",
					content:
						"Cette page permet de créer une nouvelle simulation.",
				},
			],
			// keywords
		};
	},
	computed: {
		prctCoutRevient() {
			return (
				Math.round(
					((Number(this.travauxReno.year_0) +
						Number(this.travauxReno.year_1) +
						Number(this.travauxReno.year_2) +
						Number(this.travauxReno.year_3)) /
						this.getCoutRevientTotal()) *
						10000
				) / 100
			);
		},
		...mapGetters({
			// selected: 'projects/selected',
			listInvest: "invests/invests",
			globals: "projects/globals",
			user: "users/user",
			modalPDFLoader: "invests/modalPDFLoader",
			selected_localisation: "projects/selected_localisation",
			selected_type_project: "projects/selected_type_project",
			investSelected: "invests/selected",
			projectSelected: "projects/selected",
			getOwnerSimu: "projects/coworker_new_simulation",
		}),
		// Calcul nombre d'année travaux
		getAnneeTravaux() {
			const a = moment(`${this.achevementtravaux}-01`);
			const b = moment(`${this.dateacqui}-01`);
			return a.diff(b, "years") + 1;
		},
		loyerMaximumPraticableComputed() {
			// ETAPE 1 : retrouver le prix m2 plafonné en fonction de la zone du bien (A, B, Abis)
			let plafondZone;
			switch (this.zonage) {
				case "A":
					plafondZone = this.globals.zonea;
					break;
				case "Abis":
					plafondZone = this.globals.zoneabis;
					break;
				case "B1":
					plafondZone = this.globals.zoneb1;
					break;
				case "B2":
					plafondZone = this.globals.zoneb2;
					break;
				default:
					plafondZone = this.globals.zoneb2;
					break;
			}
			// ETAPE 2 : 0.7 + 19 / surface fiscale totale. Si le calcul est inférieur ou égal à 1.2 alors on prend le résultat, sinon on applique 1.20 (car c'est un plafond). ARRONDI 2 après virgule !
			const surfaceFiscale =
				Number.parseFloat(this.surface) +
				Number.parseFloat(this.surfaceannexe);
			let etape2 = 0.7 + 19 / surfaceFiscale;
			etape2 = Math.round(etape2 * 100) / 100;
			etape2 = Math.min(etape2, 1.2);
			// ETAPE 3 : coeff obtenu * prix m2 de la zone. PAS D'ARRONDI sur cette étape !!!
			const etape3 = etape2 * plafondZone;
			// ETAPE 4 : prix majoré (=Étape 3) * surface fiscale totale = PRIX PLAFONNE (IMPORTANT, ARRONDI A L'UNITE INFERIEURE ICI)
			const etape4 = Math.floor(etape3 * surfaceFiscale);
			this.loyerMaximumPraticable = etape4;
			return etape4;
		},
		// returnGetter: {
		// 	get() {
		// 		return this.selected
		// 	}
		// },
		// NEW FONCTION
		dateNow: {
			get: () => {
				const date = new Date();
				let month = date.getMonth() + 1;
				const year = date.getFullYear();
				if (month < 10) month = `0${month}`;
				const currentDate = `${year}-${month}`;
				return currentDate;
			},
			set(val) {},
		},
		datePlus36: {
			get: () => {
				if (document.querySelector("#dateacqui")) {
					const select = document.querySelector("#dateacqui");
					const date = new Date(select.value);
					const newDate = new Date(
						date.setMonth(date.getMonth() + 36)
					);
					const month = newDate.getMonth() + 1;
					const year = newDate.getFullYear();
					const dateP36 = `${year}-${month}`;
					return dateP36;
				}
			},
			set(val) {},
		},
		surfacefiscale: {
			get: () => {
				return this.surface + this.surfaceannexe;
			},
			set: () => {},
		},
		tinteretbonComputed: {
			get() {
				let totinteret = 0;
				if (this.dureeempr.val < 15)
					totinteret = this.globals.tauxinteretmoins15.per;
				if (this.dureeempr.val >= 15 && this.dureeempr.val <= 19)
					totinteret = this.globals.tauxinteret15.per;
				if (this.dureeempr.val >= 20 && this.dureeempr.val <= 24)
					totinteret = this.globals.tauxinteret20.per;
				if (this.dureeempr.val >= 25 && this.dureeempr.val <= 30)
					totinteret = this.globals.tauxinteret25.per;
				return totinteret;
			},
			set(val) {},
		},
		apportPersoCompoComputed() {
			return this.fraisTotaux - this.montantEmprunteComputed;
		},
		poidstrvcalc: {
			get() {
				this.poidstrv =
					this.trvame === 0 && this.surface === 0
						? 0
						: Number((this.trvame / this.surface).toFixed(2));
				return this.poidstrv;
			},
			set(val) {},
		},
		averagePriceComputed() {
			if (this.categcalc === "maison_individuelle")
				return {
					bas: this.averagePrice.basMaison,
					haut: this.averagePrice.hautMaison,
				};
			return {
				bas: this.averagePrice.basAppartement,
				haut: this.averagePrice.hautAppartement,
			};
		},
		rendementbrutcalc: {
			get() {
				this.rendementbrut =
					this.revenulocatif === 0 && this.coutacquisition === 0
						? 0
						: Number(
								(
									(Number(this.revenulocatif) /
										Number(this.coutacquisition)) *
									100
								).toFixed(2)
						  );
				return this.rendementbrut;
			},
			set(val) {},
		},
		categcalc: {
			get() {
				return this.categ;
			},
			set(val) {
				if (
					val === "immeuble_residentiel" ||
					val === "immeuble_de_rapport"
				) {
					this.isSecondForm = true;
				} else {
					this.isSecondForm = false;
				}
				this.categ = val;
			},
		},

		typeloc_surface_free: {
			get() {
				return this.typeloc_surface.filter((val) => {
					return val.isTaken === false;
				});
			},
			set(val) {},
		},
		revenulocatifexistant: {
			get() {
				return (
					this.typeloc_surface
						.map((item) => (item.isTaken === true ? item.loyer : 0))
						.reduce((prev, next) => Number(prev) + Number(next)) *
					12
				);
			},
			set(val) {},
		},
		revenulocatifenvisage: {
			get() {
				const filteredTypeLoc = this.typeloc_surface.filter(
					(res) => res.isTaken === false
				);
				if (filteredTypeLoc.length > 0) {
					return (
						filteredTypeLoc
							.map((item) => item.loyer)
							.reduce(
								(prev, next) => Number(prev) + Number(next)
							) * 12
					);
				}
			},
			set(val) {},
		},
		buypricetotcalc: {
			get() {
				return this.buyprice;
			},
			set(val) {
				this.buyprice = Number(val);
				this.buypricetot = Number(val);
				this.fbancaire.val =
					this.fbancaire.fbancairePercentage.val * this.buyprice;
				this.fgarantie.val =
					this.fgarantie.fgarantiePercentage.val * this.buyprice;
			},
		},
		typeBien: {
			get() {
				return this.type;
			},
			set(val) {
				this.type = val;
				this.setProjectType(val);
			},
		},
		loyerMoyen: {
			get() {
				let loyerArray = {
					min: 0,
					moy: 0,
					max: 0,
				};
				// let stp = this.selected_type_project
				// let sl = this.selected_localisation
				const sl =
					this.selected_localisation === null
						? 0
						: this.selected_localisation;
				// if(Object.keys(this.selected_localisation).length !== 0)
				if (
					sl.loyerAppartement === undefined ||
					sl.loyerAppartement[0] === undefined ||
					sl.loyerAppartement[0].Bas === undefined ||
					sl.loyerAppartement[0].Moyen === undefined ||
					sl.loyerAppartement[0].Haut === undefined
				) {
					this.loyermoy.min = 0;
					this.loyermoy.moy = 0;
					this.loyermoy.max = 0;
					return this.loyermoy;
				}
				if (sl !== 0 && (sl.loyerAppartement || sl.loyerMaison)) {
					if (this.categ === "appartement") {
						// if(sl.loyerAppartement[0]) {
						loyerArray = {
							min:
								this.surface *
								parseFloat(
									sl.loyerAppartement[0].Bas.replace(",", ".")
								),
							moy:
								this.surface *
								parseFloat(
									sl.loyerAppartement[0].Moyen.replace(
										",",
										"."
									)
								),
							max:
								this.surface *
								parseFloat(
									sl.loyerAppartement[0].Haut.replace(
										",",
										"."
									)
								),
						};
						// }
					} else if (this.categ === "maison_individuelle") {
						loyerArray = {
							min:
								this.surface *
								parseFloat(
									sl.loyerMaison[0].Bas.replace(",", ".")
								),
							moy:
								this.surface *
								parseFloat(
									sl.loyerMaison[0].Moyen.replace(",", ".")
								),
							max:
								this.surface *
								parseFloat(
									sl.loyerMaison[0].Haut.replace(",", ".")
								),
						};
					} else if (
						this.categ === "immeuble_residentiel" ||
						this.categ === "immeuble_de_rapport"
					) {
						loyerArray = {
							min:
								this.surface *
								parseFloat(
									sl.loyerMaison[0].Bas.replace(",", ".")
								),
							moy:
								this.surface *
								parseFloat(
									sl.loyerMaison[0].Moyen.replace(",", ".")
								),
							max:
								this.surface *
								parseFloat(
									sl.loyerMaison[0].Haut.replace(",", ".")
								),
						};
					}
				}

				this.loyermoy = loyerArray;
				return loyerArray;
			},
			set(val) {},
		},
		// END NEW FONCTION

		// FONCTION FORM SIMULATOR OLD
		coutacqui() {
			// this.coutacquisition = Number(this.buyprice) + Number(this.fnotaire) + Number(this.fagence)
			const tva =
				this.typeexploitation === "classique_neuf" ||
				this.typeexploitation === "pinel_neuf"
					? this.tva.val
					: 0;
			this.fnotaire.valAuto = Number(
				(this.buyprice * this.fnotaire.fnotairePercentage.val).toFixed(
					2
				)
			);
			this.fagence.valAuto = Number(
				(this.buyprice * this.fagence.fagencePercentage.val).toFixed(2)
			);
			let fn = this.fnotaire.val;
			let fa = this.fagence.val;
			if (this.fnotaire.isAuto === true) {
				fn = this.fnotaire.valAuto;
			}
			if (this.fagence.isAuto === true) {
				fa = this.fagence.valAuto;
			}

			return (this.coutacquisition = Number(
				(
					Number(this.buyprice) +
					Number(fn) +
					Number(fa) +
					Number(tva)
				).toFixed(2)
			));
		},
		fraisRelatifsRegimeFiscal() {
			const frais =
				Number.parseInt(this.fcotisation.val) +
				Number.parseInt(this.fcomptable.val);
			return frais;
		},
		tOPEX() {
			let tfonciere = this.tfonciere.val;
			let hgestionloc = this.hgestionloc.val;
			let chargecopro = this.chargecopro.val;
			let apno = this.apno.val;
			let chargediverse = this.chargediverse.val;
			let provision = this.provision.val;
			let gli = this.gli.val;
			if (this.tfonciere.isAuto === true) {
				tfonciere = this.tfonciere.valAuto;
			}
			if (this.hgestionloc.isAuto === true) {
				hgestionloc = this.hgestionloc.valAuto;
			}
			if (this.chargecopro.isAuto === true) {
				chargecopro = this.chargecopro.valAuto;
			}
			if (this.apno.isAuto === true) {
				apno = this.apno.valAuto;
			}
			if (this.chargediverse.isAuto === true) {
				chargediverse = this.chargediverse.valAuto;
			}
			if (this.provision.isAuto === true) {
				provision = this.provision.valAuto;
			}
			if (this.gli.isAuto === true) {
				gli = this.gli.valAuto;
			}

			this.totalOPEX.val =
				Number(tfonciere) +
				Number(hgestionloc) +
				Number(chargecopro) +
				Number(apno) +
				Number(chargediverse) +
				Number(provision) +
				Number(gli);
			this.totalOPEX.per = Number(
				((this.totalOPEX.val / this.revenulocatif) * 100).toFixed(2)
			);
			this.totalOPEX.valper = this.totalOPEX.val / this.revenulocatif;

			return Math.round(this.totalOPEX.val);
		},
		onChangeSigleFnotaireComputed: {
			get() {
				if (this.fnotaire.fnotaireEuro === true)
					return this.fnotaire.val;
				return this.fnotaire.per;
			},
			set(val) {
				if (this.fnotaire.fnotaireEuro === true) {
					this.fnotairetot = Number(val);
					this.fnotaire.val = Number(val);
					this.fnotaire.per = Number(
						(
							(Number(this.fnotaire.val) /
								Number(this.buyprice)) *
							100
						).toFixed(2)
					);
				} else if (this.fnotaire.fnotaireEuro === false) {
					this.fnotairetot = Number(
						((Number(val) / 100) * Number(this.buyprice)).toFixed(2)
					);
					this.fnotaire.per = Number(val);
					this.fnotaire.val = Number(
						(
							(Number(this.fnotaire.per) / 100) *
							Number(this.buyprice)
						).toFixed(2)
					);
				}

				if (this.fnotaire.fnotaireEuro === true) {
					if (val > Number(this.buyprice) * 0.08) {
						this.warning.isFN = true;
					} else {
						this.warning.isFN = false;
					}
				}
				if (this.fnotaire.fnotaireEuro === false) {
					if (val > 8) {
						this.warning.isFN = true;
					} else {
						this.warning.isFN = false;
					}
				}
			},
		},
		onChangeSigleFagenceComputed: {
			get() {
				if (this.fagence.fagenceEuro === true) return this.fagence.val;
				return this.fagence.per;
			},
			set(val) {
				if (this.fagence.fagenceEuro === true) {
					this.fagencetot = Number(val);
					this.fagence.val = Number(val);
					this.fagence.per = Number(
						(
							(Number(this.fagence.val) / Number(this.buyprice)) *
							100
						).toFixed(2)
					);
				} else if (this.fagence.fagenceEuro === false) {
					this.fagencetot = Number(
						((Number(val) / 100) * Number(this.buyprice)).toFixed(2)
					);
					this.fagence.per = Number(val);
					this.fagence.val = Number(
						(
							(Number(this.fagence.per) / 100) *
							Number(this.buyprice)
						).toFixed(2)
					);
				}

				if (this.fagence.fagenceEuro === true) {
					if (val > Number(this.buyprice) * 0.15) {
						this.warning.isFA = true;
					} else {
						this.warning.isFA = false;
					}
				}
				if (this.fagence.fagenceEuro === false) {
					if (val > 15) {
						this.warning.isFA = true;
					} else {
						this.warning.isFA = false;
					}
				}
			},
		},
		onChangeSigleFbancaireComputed: {
			get() {
				if (this.fbancaire.fbancaireEuro === true)
					return this.fbancaire.val;
				return this.fbancaire.per;
			},
			set(val) {
				const fgarantie =
					this.fgarantie.isAuto === true
						? Math.round(
								Number(
									this.buyprice *
										this.fgarantie.fgarantiePercentage.val
								)
						  )
						: this.fgarantie.fgarantieEuro === true
						? this.fgarantie.val
						: this.fgarantie.valAuto;

				if (this.fbancaire.fbancaireEuro === true) {
					this.fbancairetot = Number(val) + fgarantie;
					this.fbancaire.val = Number(val);
					this.fbancaire.per = Number(
						(
							(Number(this.fbancaire.val) /
								Number(this.buyprice)) *
							100
						).toFixed(2)
					);
				} else if (this.fbancaire.fbancaireEuro === false) {
					this.fbancairetot =
						Number(
							(
								(Number(val) / 100) *
								Number(this.buyprice)
							).toFixed(2)
						) + fgarantie;
					this.fbancaire.per = Number(val);
					this.fbancaire.val = Number(
						(
							(Number(this.fbancaire.per) / 100) *
							Number(this.buyprice)
						).toFixed(2)
					);
				}
			},
		},
		onChangeSigleFgarantieComputed: {
			get() {
				if (this.fgarantie.fgarantieEuro === true)
					return this.fgarantie.val;
				return this.fgarantie.per;
			},
			set(val) {
				const fbancaire =
					this.fbancaire.isAuto === true
						? Math.round(
								Number(
									this.buyprice *
										this.fbancaire.fbancairePercentage.val
								)
						  )
						: this.fbancaire.fbancaireEuro === true
						? this.fbancaire.val
						: this.fbancaire.valAuto;

				if (this.fgarantie.fgarantieEuro === true) {
					this.fbancairetot = Number(val) + fbancaire;
					this.fgarantie.val = Number(val);
					this.fgarantie.per = Number(
						(
							(Number(this.fgarantie.val) /
								Number(this.buyprice)) *
							100
						).toFixed(2)
					);
				} else if (this.fgarantie.fgarantieEuro === false) {
					this.fbancairetot =
						Number(
							(
								(Number(val) / 100) *
								Number(this.buyprice)
							).toFixed(2)
						) + fbancaire;
					this.fgarantie.per = Number(val);
					this.fgarantie.val = Number(
						(
							(Number(this.fgarantie.per) / 100) *
							Number(this.buyprice)
						).toFixed(2)
					);
				}
			},
		},
		onChangeSigleTfonciereComputed: {
			get() {
				if (this.tfonciere.tfonciereEuro === true)
					return this.tfonciere.val;
				return this.tfonciere.per;
			},
			set(val) {
				if (this.tfonciere.tfonciereEuro === true) {
					this.tfonciere.val = Number(val);
					this.tfonciere.per = Number(
						(
							(Number(this.tfonciere.val) /
								Number(this.revenulocatif)) *
							100
						).toFixed(2)
					);
				} else if (this.tfonciere.tfonciereEuro === false) {
					this.tfonciere.per = Number(val);
					this.tfonciere.val = Number(
						(
							(Number(this.tfonciere.per) / 100) *
							Number(this.revenulocatif)
						).toFixed(2)
					);
				}
			},
		},
		onChangeSigleHgestionlocComputed: {
			get() {
				if (this.hgestionloc.hgestionlocEuro === true)
					return this.hgestionloc.val;
				return this.hgestionloc.per;
			},
			set(val) {
				if (this.hgestionloc.hgestionlocEuro === true) {
					this.hgestionloc.val = Number(val);
					this.hgestionloc.per = Number(
						(
							(Number(this.hgestionloc.val) /
								Number(this.revenulocatif)) *
							100
						).toFixed(2)
					);
				} else if (this.hgestionloc.hgestionlocEuro === false) {
					this.hgestionloc.per = Number(val);
					this.hgestionloc.val = Number(
						(
							(Number(this.hgestionloc.per) / 100) *
							Number(this.revenulocatif)
						).toFixed(2)
					);
				}

				if (this.hgestionloc.hgestionlocEuro === true) {
					if (val > Number(this.revenulocatif) * 0.2) {
						this.warning.isHGL = true;
					} else {
						this.warning.isHGL = false;
					}
				} else if (this.hgestionloc.hgestionlocEuro === false) {
					if (val > 20) {
						this.warning.isHGL = true;
					} else {
						this.warning.isHGL = false;
					}
				}
				this.onChangeNouveauLoyerMensuel();
			},
		},
		onChangeSigleChargecoproComputed: {
			get() {
				if (this.chargecopro.chargecoproEuro === true)
					return this.chargecopro.val;

				return this.chargecopro.per;
			},
			set(val) {
				if (this.chargecopro.chargecoproEuro === true) {
					this.chargecopro.val = Number(val);
					this.chargecopro.per = Number(
						(
							(Number(this.chargecopro.val) /
								Number(this.revenulocatif)) *
							100
						).toFixed(2)
					);
				} else if (this.chargecopro.chargecoproEuro === false) {
					this.chargecopro.per = Number(val);
					this.chargecopro.val = Number(
						(
							(Number(this.chargecopro.per) / 100) *
							Number(this.revenulocatif)
						).toFixed(2)
					);
				}
			},
		},
		onChangeSigleApnoComputed: {
			get() {
				if (this.apno.apnoEuro === true) return this.apno.val;

				return this.apno.per;
			},
			set(val) {
				if (this.apno.apnoEuro === true) {
					this.apno.val = Number(val);
					this.apno.per = Number(
						(
							(Number(this.apno.val) /
								Number(this.revenulocatif)) *
							100
						).toFixed(2)
					);
				} else if (this.apno.apnoEuro === false) {
					this.apno.per = Number(val);
					this.apno.val = Number(
						(
							(Number(this.apno.per) / 100) *
							Number(this.revenulocatif)
						).toFixed(2)
					);
				}
				this.onChangeNouveauLoyerMensuel();

				if (this.apno.apnoEuro === true) {
					if (val > Number(this.revenulocatif) * 0.1) {
						this.warning.isPNO = true;
					} else {
						this.warning.isPNO = false;
					}
				} else if (this.apno.apnoEuro === false) {
					if (val > 10) {
						this.warning.isPNO = true;
					} else {
						this.warning.isPNO = false;
					}
				}
			},
		},
		onChangeSigleChargediverseComputed: {
			get() {
				if (this.chargediverse.chargediverseEuro === true) {
					return this.chargediverse.val;
				} else if (this.chargediverse.chargediverseEuro === false) {
					return this.chargediverse.per;
				}
			},
			set(val) {
				if (this.chargediverse.chargediverseEuro === true) {
					this.chargediverse.val = Number(val);
					this.chargediverse.per = Number(
						(
							(Number(this.chargediverse.val) /
								Number(this.revenulocatif)) *
							100
						).toFixed(2)
					);
				} else if (this.chargediverse.chargediverseEuro === false) {
					this.chargediverse.per = Number(val);
					this.chargediverse.val = Number(
						(
							(Number(this.chargediverse.per) / 100) *
							Number(this.revenulocatif)
						).toFixed(2)
					);
				}
			},
		},
		onChangeGLIComputed: {
			get() {
				if (this.gli.gliEuro === true) {
					return this.gli.val;
				} else if (this.gli.gliEuro === false) {
					return this.gli.per;
				}
			},
			set(val) {
				if (this.gli.gliEuro === true) {
					this.gli.val = Number(val);
					this.gli.per = Number(
						(
							(Number(this.gli.val) /
								Number(this.revenulocatif)) *
							100
						).toFixed(2)
					);
				} else if (this.gli.gliEuro === false) {
					this.gli.per = Number(val);
					this.gli.val = Number(
						(
							(Number(this.gli.per) / 100) *
							Number(this.revenulocatif)
						).toFixed(2)
					);
				}
				this.onChangeNouveauLoyerMensuel();
			},
		},

		onChangeSigleProvisionComputed: {
			get() {
				if (this.provision.provisionEuro === true) {
					return this.provision.val;
				} else if (this.provision.provisionEuro === false) {
					return this.provision.per;
				}
			},
			set(val) {
				if (this.provision.provisionEuro === true) {
					this.provision.val = Number(val);
					this.provision.per = Number(
						(
							(Number(this.provision.val) /
								Number(this.revenulocatif)) *
							100
						).toFixed(2)
					);
				} else if (this.provision.provisionEuro === false) {
					this.provision.per = Number(val);
					this.provision.val = Number(
						(
							(Number(this.provision.per) / 100) *
							Number(this.revenulocatif)
						).toFixed(2)
					);
				}
			},
		},
		// onChangeSigleFcrlComputed: {
		//   get() {
		//     if (this.fcrl.fcrlEuro) return this.fcrl.val
		//     else if (!this.fcrl.fcrlEuro) return this.fcrl.per
		//   },
		//   set(val) {
		//     if (this.fcrl.fcrlEuro) {
		//       this.fcrl.val = Number(val)
		//       this.fcrl.per = Number(
		//         ((Number(this.fcrl.val) / Number(this.revenulocatif)) * 100).toFixed(2),
		//       )
		//     } else if (this.fcrl.fcrlEuro === false) {
		//       this.fcrl.per = Number(val)
		//       this.fcrl.val = Number(
		//         ((Number(this.fcrl.per) / 100) * Number(this.revenulocatif)).toFixed(2),
		//       )
		//     }
		//   },
		// },

		rvacancelocComputed: {
			get() {
				return this.rvacanceloc.val;
			},
			set(val) {
				if (Number(val) > 20) {
					this.warning.isTVL = true;
				} else {
					this.warning.isTVL = false;
				}
				this.rvacanceloc.val = val;
			},
		},
		// FONCTION FORM INVESTISSEUR OLD
		montantEmprunteComputed: {
			get() {
				if (this.modefinancement === "composant") {
					this.montantemprunte.val =
						(this.empruntOption.bien === "yes"
							? Number.parseInt(this.buypricetot)
							: 0) +
						(this.empruntOption.notaire === "yes"
							? Number.parseInt(this.fnotairetot)
							: 0) +
						(this.empruntOption.agence === "yes"
							? Number.parseInt(this.fagencetot)
							: 0) +
						(this.empruntOption.travaux === "yes"
							? Number.parseInt(this.trvametot)
							: 0) +
						(this.empruntOption.banque === "yes"
							? Number.parseInt(this.fbancairetot)
							: 0) +
						(this.empruntOption.tva === "yes" &&
						(this.typeexploitation === "classique_neuf" ||
							this.typeexploitation === "pinel_neuf")
							? Number.parseInt(this.ftvatot)
							: 0);
				}
				if (this.modefinancement === "apport") {
					this.montantemprunte.val =
						this.fraisTotaux - this.apportPersoApport;
				}
				this.montantemprunte.valAuto =
					this.montantemprunte.val *
					this.montantemprunte.montantempruntePercentage.val;
				return this.montantemprunte.val;
			},
			set(val) {},
		},
		fraisTotaux() {
			// calcule les frais totaux maximum pour la methode de financement Apport
			let frais =
				Number(this.buyprice) +
				Number(this.fnotaire.val) +
				Number(this.fagence.val) +
				Number(this.travauxTotaux) +
				// Number(
				//   this.formeJuridiqueSelected([
				//     'lmnpbic',
				//     'lmnpr',
				//     'sasis',
				//     'sasuis',
				//     'sciis',
				//     'sciirmf',
				//     'sciirr',
				//   ]) === false
				//     ? 0
				//     : Number(this.fameublement)
				// ) +
				Number(this.fbancaire.val) +
				Number(this.fgarantie.val);

			// ajoute la TVA le cas echeant
			if (
				this.typeexploitation === "classique_neuf" ||
				this.typeexploitation === "pinel_neuf"
			) {
				frais += Number.parseInt(this.tva.val);
			}

			return frais;
		},
		fraisNotaireComputedInfos() {
			if (this.data_frais_notaire !== null) {
				return `<table class="text-center border border-collapse border-black">
    					<tbody>
    					    <tr>
    					        <td class="border border-black">Honoraires TTC du notaire</td>
    					        <td class="border border-black">${Number(
									this.data_frais_notaire.data
										.honoraire_notaire_ttc
								).toLocaleString("fr-FR", {
									minimumFractionDigits: 0,
								})}€</td>
    					    </tr>
    					    <tr>
    					        <td class="border border-black">Droits d'enregistrements et taxes</td>
    					        <td class="border border-black">${Number(
									this.data_frais_notaire.data
										.droit_enregistrement
								).toLocaleString("fr-FR", {
									minimumFractionDigits: 0,
								})}€</td>
    					    </tr>
    					    <tr>
    					        <td class="border border-black">Emoluments de formalités et débours</td>
    					        <td class="border border-black">${Number(
									this.data_frais_notaire.data
										.emolument_formalite_debours
								).toLocaleString("fr-FR", {
									minimumFractionDigits: 0,
								})}€</td>
    					    </tr>
    					    <tr>
    					        <td class="border border-black">Contributions à la sécurité immobilière</td>
    					        <td class="border border-black">${Number(
									this.data_frais_notaire.data
										.contribution_securite_immobiliere
								).toLocaleString("fr-FR", {
									minimumFractionDigits: 0,
								})}€</td>
    					    </tr>
    					    <tr class="font-bold">
    					        <td class="border border-black">Frais de notaire totaux</td>
    					        <td class="border border-black">${Number(
									this.data_frais_notaire.data.total
								).toLocaleString("fr-FR", {
									minimumFractionDigits: 0,
								})}€</td>
    					    </tr>
    					</tbody>
					</table>`;
			} else {
				return "";
			}
		},
		montantemprunteComputed: {
			get() {
				if (this.montantemprunte.montantemprunteEuro === true) {
					return this.montantemprunte.val;
				} else if (this.montantemprunte.montantemprunteEuro === false) {
					return this.montantemprunte.per;
				}
			},
			set(val) {
				// if(this.montantemprunte.montantemprunteEuro === false) {
				// 	this.montantemprunte.montantemprunteEuro = true
				// }else {
				// 	this.montantemprunte.montantemprunteEuro = false
				// }
				// if(Number(this[value].val) !== 0 && this.manuel.buyprice !=0 ) {
				if (this.montantemprunte.montantemprunteEuro === true) {
					this.montantemprunte.val = val;
					this.montantemprunte.per =
						(Number(val) / Number(this.montantempr.val)) * 100;
				} else if (this.montantemprunte.montantemprunteEuro === false) {
					this.montantemprunte.per = val;
					this.montantemprunte.val =
						(Number(val) / 100) * Number(this.montantempr.val);
				}
			},
		},
		sousapportfond() {
			if (this.apportfond.apportfondEuro === true) {
				if (Number(this.montantemprunte.isAuto === true)) {
					return (this.apportfond.val =
						Number(this.montantempr.val) -
						Number(this.montantemprunte.valAuto));
				} else {
					return (this.apportfond.val =
						Number(this.montantempr.val) -
						Number(this.montantemprunte.val));
				}
			} else if (this.apportfond.apportfondEuro === false) {
				return (this.apportfond.per =
					(Number(this.apportfond.val) /
						Number(this.montantempr.val)) *
					100);
			}
		},
		dureedetent: {
			get() {
				return this.dureedetention.val;
			},
			set(val) {
				this.dureedetention.valmonth = Number(val) * 12;
				this.dureedetention.val = Number(val);
			},
		},
		tinteretemprunt: {
			get() {
				return this.tinteretempr.per;
			},
			set(val) {
				if (Number(val) > 5) {
					this.warning.isTI = true;
				} else {
					this.warning.isTI = false;
				}
				this.tinteretempr.val = Number(val) / 100;
				this.tinteretempr.per = val;
			},
		},
		tassuranceemprunt: {
			get() {
				return this.tassuranceempr.per;
			},
			set(val) {
				this.tassuranceempr.val = Number(val) / 100;
				this.tassuranceempr.per = val;
			},
		},
		tactualisationComputed: {
			get() {
				return this.tactualisation.per;
			},
			set(val) {
				this.tactualisation.per = val;
				this.tactualisation.val = val / 100;
				if (val > 10) {
					this.warning.isTA = true;
				} else {
					this.warning.isTA = false;
				}
			},
		},
		tmical: {
			get() {
				return this.tmi.per;
			},
			set(val) {
				this.tmi.val = Number(val) / 100;
				this.tmi.per = val;
			},
		},
		datereventecal: {
			get() {
				const dateacquiSplit = this.dateacqui.split("-");

				this.daterevente = `${
					Number(dateacquiSplit[0]) + Number(this.dureedetention.val)
				}-${dateacquiSplit[1]}`.toString();
				return moment(new Date(String(this.daterevente))).format(
					"yyyy-MM"
				);
			},
		},
		estimationrevalcal: {
			get() {
				return this.estimreval.per;
			},
			set(val) {
				if (Number(val) > 5) {
					this.warning.isRA = true;
				} else {
					this.warning.isRA = false;
				}
				this.estimreval.per = val;
				this.estimreval.val = val / 100;
			},
		},
		estimationprixreventecal: {
			get() {
				const travauxTroisPremieresAnnees =
					Number(this.travauxReno.year_0) +
					Number(this.travauxReno.year_1) +
					Number(this.travauxReno.year_2) +
					Number(this.travauxConstru.year_0) +
					Number(this.travauxConstru.year_1) +
					Number(this.travauxConstru.year_2);

				if (this.dureedetention.isAuto === true) {
					if (
						["pinel_ancien", "denormandie"].includes(
							this.typeexploitation
						)
					) {
						this.estimprixrevente = Math.round(
							(Number(this.buyprice) +
								travauxTroisPremieresAnnees) *
								(1 + this.estimreval.val) **
									(this.dureedetention.valAuto / 12)
						);
					} else {
						this.estimprixrevente = Math.round(
							(Number(this.buyprice) +
								travauxTroisPremieresAnnees) *
								(1 + this.estimreval.val) **
									(this.dureedetention.valAuto / 12)
						);
					}
				} else {
					if (
						["pinel_ancien", "denormandie"].includes(
							this.typeexploitation
						)
					) {
						this.estimprixrevente = Math.round(
							(Number(this.buyprice) +
								travauxTroisPremieresAnnees) *
								(1 + this.estimreval.val) **
									this.dureedetention.val
						);
					} else {
						this.estimprixrevente = Math.round(
							(Number(this.buyprice) +
								travauxTroisPremieresAnnees) *
								(1 + this.estimreval.val) **
									this.dureedetention.val
						);
					}
				}

				return Math.round(
					Number(this.estimprixrevente.toFixed(2))
				).toLocaleString("fr-FR", {
					minimumFractionDigits: 0,
				});
			},
		},
		plusvaluecal: {
			get() {
				this.plusvalue =
					this.estimprixrevente -
					this.coutacquisition -
					this.travauxTotaux;
				return Math.round(Number(this.plusvalue.toFixed(2)));
			},
		},
		augmvaleurcal: {
			get() {
				this.augmvaleur =
					(this.estimprixrevente -
						this.coutacquisition -
						this.travauxTotaux) /
					(this.coutacquisition + this.travauxTotaux);
				return Number((this.augmvaleur * 100).toFixed(2));
			},
		},
	},
	watch: {
		engagement: function () {
			this.engagementPlusTravaux =
				Number(this.engagement) +
				Math.floor((this.appelDeFond.achevementTravaux + 1) / 12) -
				1;
		},
		loyermensuel: function () {
			this.revenulocatif = this.loyermensuel * 12;
		},
		buyprice: function (newFN, oldFN) {
			let neuf = false;
			let code = "";
			if (
				["classique_neuf", "pinel_neuf"].includes(this.typeexploitation)
			) {
				neuf = true;
			}
			if (typeof this.loc === "string") {
				if (this.loc.substr(0, 3) === "974") {
					code = this.loc.substr(0, 3);
				} else {
					code = this.loc.substr(0, 2);
				}
			}
			const data_globals = {
				notaire0a6500: this.globals.notaire0a6500,
				notaire6501a17000: this.globals.notaire6501a17000,
				notaire17001a60000: this.globals.notaire17001a60000,
				notaire60001etplus: this.globals.notaire60001etplus,
				emolument_formalite_debours: this.globals.montantfixeemolument,
			};
			if (oldFN !== 0) {
				this.data_frais_notaire = ProjectTools.calculFraisNotaire(
					newFN,
					neuf,
					code,
					true,
					data_globals
				);
				this.fnotaire.fnotairePercentage.val =
					this.data_frais_notaire.calcul;
				this.fnotaire.val = Math.round(
					this.fnotaire.fnotairePercentage.val * newFN
				);
				this.fnotaire.valAuto = Math.round(
					this.fnotaire.fnotairePercentage.val * newFN
				);
				this.fnotaire.per = (this.fnotaire.val * 100) / newFN;
				this.fnotairetot = this.fnotaire.val;
			}

			// this.fcrl.val = this.fcrl.fcrlPercentage.val * newFN;
			// this.fcrl.valAuto = this.fcrl.fcrlPercentage.val * newFN;

			// this.fbancaire.val = this.fbancaire.fbancairePercentage.val * newFN;
			this.fbancaire.valAuto =
				this.fbancaire.fbancairePercentage.val * newFN;

			// this.fgarantie.val = this.fgarantie.fgarantiePercentage.val * newFN;
			this.fgarantie.valAuto =
				this.fgarantie.fgarantiePercentage.val * newFN;

			this.fbancairetot =
				Number(this.fbancaire.val) + Number(this.fgarantie.val);
			this.ftvatot = Number(this.buyprice) * 0.2;
			if (
				this.typeexploitation === "classique_neuf" ||
				this.typeexploitation === "pinel_neuf"
			) {
				this.tva.val = (Number(this.buyprice) * this.tva.per) / 100;
				this.onChangeTvaVal();
			}
		},
		typeexploitation: (newFN) => {
			// if (newFN === 'classique_ancien' || newFN === 'pinel_neuf' && typeof this.ftvatot !== 'undefined') this.ftvatot = 0;
		},
		"fnotaire.val": function () {
			this.fnotairetot = this.fnotaire.val;
		},
		"fnotaire.isAuto": function (newFN, oldFN) {
			// if(newFN === true) {
			// 	this.fnotairetot = Number(this.buyprice * this.fnotaire.fnotairePercentage.val)
			// } else {
			// 	this.fnotairetot = this.fnotaire.val
			// }
		},
		"fagence.isAuto": function (newFA, oldFA) {
			if (newFA === true) {
				this.fagencetot = Math.round(
					Number(this.buyprice * this.fagence.fagencePercentage.val)
				);
			} else {
				this.fagencetot = this.fagence.val;
			}
		},
		dureeempr: function () {
			this.amortDataUpdate();
		},
		"dureeempr.val": function () {
			this.amortDataUpdate();
		},
		tinteretemprunt: function () {
			this.amortDataUpdate();
		},
		remboursementemprunt: function () {
			this.amortDataUpdate();
		},
		dureediffere: function () {
			this.amortDataUpdate();
		},
		"fgarantie.val": function () {
			this.amortDataUpdate();
		},
		tassuranceemprunt: function () {
			this.amortDataUpdate();
		},
		fameublement: function (newFN, oldFN) {
			if (this.fameublement >= 500) {
				this.fameublementDeduction = 500;
				this.fameublementDeduit = this.fameublement - 500;
			} else if (this.fameublement < 500) {
				this.fameublementDeduction = this.fameublement;
				this.fameublementDeduit = 0;
			}
			this.amortissementsparcomposant.ameublement.valAmo =
				this.fameublementDeduit;
			this.amortDataUpdate();
		},
		apportPersoApport: function () {
			this.amortDataUpdate();
		},
		fraisTotaux: function () {
			this.amortDataUpdate();
		},
		apportPersoCompoComputed: function () {
			this.amortDataUpdate();
		},
		montantEmprunteComputed: function () {
			this.amortDataUpdate();
		},
		trvame: function (newFA, oldFA) {
			this.trvametot =
				oldFA !== 0
					? Number(this.trvametot) - Number(oldFA) + Number(newFA)
					: Number(newFA);
		},
		travauxTotaux: function (newFA, oldFA) {
			this.trvametot = this.travauxTotaux;
		},
		"fbancaire.val": function (newFA, oldFA) {
			this.fbancairetot =
				Number(this.fbancaire.val) + Number(this.fgarantie.val);
		},
		"fgarantie.val": function (newFA, oldFA) {
			this.fbancairetot =
				Number(this.fbancaire.val) + Number(this.fgarantie.val);
		},
		buypricetot: function (newFA, oldFA) {
			if (newFA === "") {
				this.buypricetot = 0;
			}
		},
		fnotairetot: function (newFA, oldFA) {
			if (newFA === "") {
				this.fnotairetot = 0;
			}
		},
		fagencetot: function (newFA, oldFA) {
			if (newFA === "") {
				this.fagencetot = 0;
			}
		},
		trvametot: function (newFA, oldFA) {
			if (newFA === "") {
				this.trvametot = 0;
			}
		},
		fbancairetot: function (newFA, oldFA) {
			if (newFA === "") {
				this.fbancairetot = 0;
			}
		},
		revenulocatif: function (newFA, oldFA) {
			this.tfonciere.valAuto =
				Number(newFA) * this.tfonciere.tfoncierePercentage.val;
			this.hgestionloc.valAuto =
				Number(newFA) * this.hgestionloc.hgestionlocPercentage.val;
			this.chargecopro.valAuto =
				Number(newFA) * this.chargecopro.chargecoproPercentage.val;
			this.apno.valAuto = Number(newFA) * this.apno.apnoPercentage.val;

			// this.tfonciere.val = Math.round(Number(newFA) * this.tfonciere.tfoncierePercentage.val);
			// this.hgestionloc.val = Math.round(Number(newFA) * this.hgestionloc.hgestionlocPercentage.val);
			// this.chargecopro.val = Math.round(Number(newFA) * this.chargecopro.chargecoproPercentage.val);
			// this.apno.val = Math.round(Number(newFA) * this.apno.apnoPercentage.val);

			this.tfonciere.per = (this.tfonciere.val / Number(newFA)) * 100;
			this.hgestionloc.per = (this.hgestionloc.val / Number(newFA)) * 100;
			this.chargecopro.per = (this.chargecopro.val / Number(newFA)) * 100;
			this.apno.per = (this.apno.val / Number(newFA)) * 100;
			// this.fcrl.per = (this.fcrl.val / Number(newFA)) * 100;
		},
	},
	created() {
		const tir250 = ["0"];
		while (tir250.length <= 5000) {
			const numero = parseFloat(
				(+tir250[tir250.length - 1] + 0.05).toString()
			).toFixed(2);
			tir250.push(numero);
		}
		this.type_fisc[0].tmi = this.investSelected.tmi;
		if (this.projectSelected !== null) {
			this.name = this.projectSelected.name;
			this.trvame = this.projectSelected.carac.trvame;
			this.trvametot = this.projectSelected.carac.trvametot
				? this.projectSelected.carac.trvametot
				: 0;
			this.poidstrv = this.projectSelected.carac.poidstrv;
			this.datetrv = this.projectSelected.carac.datetrv;
			this.appelDeFond = this.projectSelected.carac.appelDeFond;
			this.categ = this.projectSelected.carac.categ;
			this.type = this.projectSelected.carac.type;
			this.loc = this.projectSelected.loc;
			if (
				typeof this.projectSelected.carac.amortissementsparcomposant !==
				"undefined"
			) {
				this.amortissementsparcomposant = {
					...this.amortissementsparcomposant,
					...this.projectSelected.carac.amortissementsparcomposant,
				};
			}
			this.remboursementemprunt =
				this.projectSelected.carac.remboursementemprunt;
			this.dureediffere = this.projectSelected.carac.dureediffere;
			this.loc.carac = this.projectSelected.loc.carac;
			this.loyermoy = this.projectSelected.carac.loyermoy;
			this.typeloc = this.projectSelected.carac.typeloc;
			this.surface = this.projectSelected.carac.surface;
			this.engagement = this.projectSelected.carac.engagement;
			this.engagementPlusTravaux =
				this.projectSelected.carac.engagementPlusTravaux;
			if (this.projectSelected.carac.achevementtravaux.length > 7) {
				this.achevementtravaux =
					this.projectSelected.carac.achevementtravaux.substr(0, 7);
			} else {
				this.achevementtravaux =
					this.projectSelected.carac.achevementtravaux;
			}
			this.exonerationTaxeFonciere =
				this.projectSelected.carac.exonerationTaxeFonciere;
			this.dateacqui = this.projectSelected.carac.dateacqui;
			this.datesignature = this.projectSelected.carac.datesignature
				? this.projectSelected.carac.datesignature
				: "";
			this.modefinancement = this.projectSelected.carac.modefinancement;
			this.typeloc_surface = this.projectSelected.carac.typeloc_surface;
			this.type_fisc = this.projectSelected.carac.type_fisc;
			this.typeexploitation = this.projectSelected.carac.typeexploitation;
			this.travauxReno = this.projectSelected.carac.travauxReno;
			this.travauxConstru = this.projectSelected.carac.travauxConstru;

			this.differeDeMiseEnLocation =
				this.projectSelected.carac.differeDeMiseEnLocation;
			this.dureediffere = this.projectSelected.carac.dureediffere;
			if (this.projectSelected.carac.empruntOption) {
				this.empruntOption = this.projectSelected.carac.empruntOption;
			}
			if (this.projectSelected.carac.cumulADF) {
				this.cumulADF = this.projectSelected.carac.cumulADF;
			}
			this.dividendeSCI = this.projectSelected.carac.dividendeSCI;
			this.fameublement = this.projectSelected.carac.fameublement
				? this.projectSelected.carac.fameublement
				: 0;
			this.fameublementDeduit = this.projectSelected.carac
				.fameublementDeduit
				? this.projectSelected.carac.fameublementDeduit
				: 0;
			this.fameublementDeduction = this.projectSelected.carac
				.fameublementDeduction
				? this.projectSelected.carac.fameublementDeduction
				: 0;
			this.dpe = this.projectSelected.carac.dpe;
			this.doubleDFTravauxEnergie =
				this.projectSelected.carac.doubleDFTravauxEnergie;
			this.pinelplus = this.projectSelected.carac.pinelplus;
			this.occupation = this.projectSelected.carac.occupation;
			this.trconstruction = this.projectSelected.carac.trconstruction;
			this.immeuble = this.projectSelected.carac.immeuble;
			this.surfaceannexe = this.projectSelected.carac.surfaceannexe;
			this.zonage = this.projectSelected.carac.zonage;
			this.zonageMalraux = this.projectSelected.carac.zonageMalraux;
			this.interlocuteur = this.projectSelected.carac.interlocuteur;
			this.fsociete = this.projectSelected.carac.fsociete;
			this.more_info = this.projectSelected.carac.more_info;
			this.buypricetot = this.projectSelected.carac.buypricetot;
			this.isbuyprice = this.projectSelected.carac.isbuyprice;
			this.buyprice = this.projectSelected.carac.buyprice;
			this.ftvatot = Number(this.buyprice) * 0.2;
			this.fnotairetot = this.projectSelected.carac.fnotairetot;
			this.fnotaire = this.projectSelected.carac.fnotaire;
			this.fagencetot = this.projectSelected.carac.fagencetot;
			this.fagence = this.projectSelected.carac.fagence;
			this.coutacquisition = this.projectSelected.carac.coutacquisition;
			this.loyermensuel = this.projectSelected.carac.loyermensuel;
			this.loyerMaximumPraticable =
				this.projectSelected.carac.loyerMaximumPraticable;
			this.revenulocatif = this.projectSelected.carac.revenulocatif;
			this.rendementbrut = this.projectSelected.carac.rendementbrut;
			this.trevalorisation = this.projectSelected.carac.trevalorisation;
			this.rvacanceloc = this.projectSelected.carac.rvacanceloc;
			this.tfonciere = this.projectSelected.carac.tfonciere;
			this.hgestionloc = this.projectSelected.carac.hgestionloc;
			this.chargecopro = this.projectSelected.carac.chargecopro;
			this.apno = this.projectSelected.carac.apno;
			this.chargediverse = this.projectSelected.carac.chargediverse;
			this.gli = this.projectSelected.carac.gli;
			this.provision = this.projectSelected.carac.provision;
			this.totalOPEX = this.projectSelected.carac.totalOPEX;
			this.trevalorisationOPEX =
				this.projectSelected.carac.trevalorisationOPEX;
			this.montantempr = this.projectSelected.carac.montantempr;
			this.montantemprunte = this.projectSelected.carac.montantemprunte;
			this.apportPersoComposant =
				this.projectSelected.carac.apportPersoComposant;
			this.apportPersoApport =
				this.projectSelected.carac.apportPersoApport;
			this.apportfond = this.projectSelected.carac.apportfond;
			this.dureeempr = this.projectSelected.carac.dureeempr;
			this.tinteretbon = this.projectSelected.carac.tinteretbon;
			this.tinteretempr = this.projectSelected.carac.tinteretempr;
			this.tassuranceempr = this.projectSelected.carac.tassuranceempr;
			this.fbancairetot = this.projectSelected.carac.fbancairetot;
			this.fbancaire = this.projectSelected.carac.fbancaire;
			this.fgarantie = this.projectSelected.carac.fgarantie;
			this.ira = this.projectSelected.carac.ira;
			this.annuite = this.projectSelected.carac.annuite;
			this.tactualisation = this.projectSelected.carac.tactualisation;
			this.formejuridique = this.projectSelected.carac.formejuridique;
			this.fcomptable = this.projectSelected.carac.fcomptable;
			// this.fcrl = this.projectSelected.carac.fcrl;
			this.fcotisation = this.projectSelected.carac.fcotisation;
			this.dureedetention = this.projectSelected.carac.dureedetention;
			this.daterevente = this.projectSelected.carac.daterevente;
			this.estimreval = this.projectSelected.carac.estimreval;
			this.estimprixrevente = this.projectSelected.carac.estimprixrevente;
			this.evolution.unAn = this.projectSelected.carac.evolution1an;
			this.evolution.cinqAns = this.projectSelected.carac.evolution5ans;
			this.averagePrice = this.projectSelected.carac.averagePrice;
			this.plusvalue = this.projectSelected.carac.plusvalue;
			this.augmvaleur = this.projectSelected.carac.augmvaleur;
			this.tva = this.projectSelected.carac.tva;
			this.modeimposition = this.projectSelected.carac.modeimposition;
			this.changementDeFiscalite =
				this.projectSelected.carac.changementDeFiscalite;
			this.nouveauLoyerMensuel =
				this.projectSelected.carac.nouveauLoyerMensuel;
			this.nouveauPNO = this.projectSelected.carac.nouveauPNO;
			this.nouveauGLI = this.projectSelected.carac.nouveauGLI;
			this.nouveauHgestionloc =
				this.projectSelected.carac.nouveauHgestionloc;
			this.isSecondForm = !!(
				this.categcalc === "immeuble_residentiel" ||
				this.categcalc === "immeuble_de_rapport"
			);
			this.imgs.principale =
				this.projectSelected.exports.imgPrincipale ?? "";
			this.imgs.photo1 =
				this.projectSelected.exports?.ImmeublePJ["0"] ?? "";
			this.imgs.photo2 =
				this.projectSelected.exports?.ImmeublePJ["1"] ?? "";
		} else {
			this.dateacqui = moment().format("YYYY-MM");
		}
	},
	mounted() {
		if (this.projectSelected === null) {
			if (this.globals.taxefonciere) {
				this.tfonciere.tfoncierePercentage = this.globals.taxefonciere;
			}
			if (this.globals.gestionlocative) {
				this.hgestionloc.hgestionlocPercentage =
					this.globals.gestionlocative;
			}
			if (this.globals.fraiscopropriete) {
				this.chargecopro.chargecoproPercentage =
					this.globals.fraiscopropriete;
			}
			if (this.globals.fraisassurancepno) {
				this.apno.apnoPercentage = this.globals.fraisassurancepno;
			}
			if (this.globals.gli) {
				this.gli.gliPercentage = this.globals.gli;
			}
			// if(this.globals.constitutionsociete){
			// 	this.fcrl.fcrlPercentage.val = this.globals.constitutionsociete
			// }
			if (this.globals.cfe) {
				this.fcotisation.val = this.globals.cfe;
			}
			if (this.globals.comptacga) {
				this.fcomptable.val = this.globals.comptacga;
			}
			if (this.globals.tauxinteret20.per) {
				this.tinteretemprunt = this.globals.tauxinteret20.per;
			}
		}

		document.addEventListener("wheel", (event) => {
			if (document.activeElement.type === "number") {
				document.activeElement.blur();
			}
		});
	},
	methods: {
		checkForTextarea(event) {
			if (event.target.tagName !== "TEXTAREA") {
				event.preventDefault();
			}
		},
		deleteImg(caseImg) {
			switch (caseImg) {
				case "principale":
					this.imgs.principale = "";
					this.file.imgPrincipale = null;
					break;
				case "photo1":
					this.imgs.photo1 = "";
					this.file.imgPhoto1 = null;
					break;
				case "photo2":
					this.imgs.photo2 = "";
					this.file.imgPhoto2 = null;
					break;
				default:
					break;
			}
		},
		onChangeNouveauLoyerMensuel() {
			this.nouveauPNO =
				(this.nouveauLoyerMensuel * 12 * this.apno.per) / 100;
			this.nouveauGLI =
				(this.nouveauLoyerMensuel * 12 * this.gli.per) / 100;
			this.nouveauHgestionloc =
				(this.nouveauLoyerMensuel * 12 * this.hgestionloc.per) / 100;
		},
		onChangeLoyerLot() {
			const totalLoyers = this.immeuble.lots.reduce((acc, lot) => {
				return acc + Number(lot.loyer);
			}, 0);
			this.loyermensuel = totalLoyers;
			this.revenulocatif = totalLoyers * 12;
			this.onChangeLoyerMoyen();
		},
		onChangeSurfaceLots() {
			const totalSurface = this.immeuble.lots.reduce((acc, lot) => {
				return acc + Number(lot.surface);
			}, 0);
			this.surface = totalSurface;
		},
		onChangeNombreLots() {
			while (this.immeuble.lots.length < this.immeuble.nombre_lot) {
				this.immeuble.lots.push({
					number: this.immeuble.lots.length + 1,
					surface: 0,
					occupied: false,
					loyer: 0,
					typeBien: "x",
				});
			}
			while (this.immeuble.lots.length > this.immeuble.nombre_lot) {
				this.immeuble.lots.pop();
			}
			this.onChangeLoyerLot();
			this.onChangeSurfaceLots();
		},
		...mapActions({
			setInvest: "invests/setInvest",
			setProject: "projects/setProject",
			getProjectsSummary: "projects/getProjectsSummary",
			updateProject: "projects/updateProject",
			setResults: "invests/setResults",
			setModalPDFLoader: "invests/setModalPDFLoader",
			setProjectLocalisation: "projects/setProjectLocalisation",
			setProjectType: "projects/setProjectType",
			setProjectSelected: "projects/setProjectSelected",
		}),
		onChangeModeFinancement() {
			if (this.modeFinancement === "fond_propre") {
				this.fgarantie.val = 0;
				this.onChangeSigleFbancaireComputed = 0;
			}
		},

		onChangeTvaPer() {
			const newVal =
				Math.floor(this.buyprice * (this.tva.per / 100) * 100) / 100;
			this.tva.val = newVal;
			this.ftvatot = this.tva.val;
		},
		onChangeTvaVal() {
			this.ftvatot = this.tva.val;
			const newVal =
				Math.floor(((this.tva.val * 100) / this.buyprice) * 100) / 100;
			this.tva.per = newVal;
		},
		onChangeLoyerMoyen() {
			if (this.projectSelected === null) {
				this.tfonciere.val = Math.round(
					Number(this.revenulocatif) *
						this.tfonciere.tfoncierePercentage.val
				);
				this.hgestionloc.val = Math.round(
					Number(this.revenulocatif) *
						this.hgestionloc.hgestionlocPercentage.val
				);
				this.chargecopro.val = Math.round(
					Number(this.revenulocatif) *
						this.chargecopro.chargecoproPercentage.val
				);
				this.apno.val = Math.round(
					Number(this.revenulocatif) * this.apno.apnoPercentage.val
				);
				this.apno.per = this.apno.apnoPercentage.per;
			}
		},
		showSurfaceModal() {
			this.surfaceModal = true;
		},
		closeSurfaceModal(surfaceannexe) {
			this.surfaceModal = false;
			this.surfaceannexe = surfaceannexe;
		},
		showAppelDeFondModal() {
			this.appelDeFondModal = true;
		},
		closeAppelDeFondModal(saved, appelDeFond, cumulADF) {
			if (saved) {
				this.appelDeFond = appelDeFond;
				this.cumulADF = cumulADF;
				this.dureediffere = appelDeFond.livraisonRemiseClefMois;
			}
			this.appelDeFondModal = false;
		},
		showAmortissementComposantModal() {
			this.amortissementComposantModal = true;
		},
		closeAmortissementComposantModal(amortissementsparcomposant) {
			this.amortissementComposantModal = false;

			this.amortissementsparcomposant = amortissementsparcomposant;
		},
		showAmortissementModal() {
			this.amortDataUpdate();
			this.amortissementModal = true;
		},
		closeAmortissementModal() {
			this.amortissementModal = false;
		},
		showPremiereUtilModal() {
			this.premiereUtilModal = true;
		},
		closePremiereUtilModal() {
			this.premiereUtilModal = false;
		},
		showTravauxModal() {
			this.travauxModal = true;
		},
		closeTravauxModal(
			travauxReno,
			travauxConstru,
			travauxRenoTotal,
			travauxConstruTotal
		) {
			for (const i in travauxReno)
				if (travauxReno[i] === "") travauxReno[i] = 0;
			for (const i in travauxConstru)
				if (travauxConstru[i] === "") travauxConstru[i] = 0;
			this.travauxModal = false;
			this.travauxReno = travauxReno;
			this.travauxConstru = travauxConstru;
			this.trvame = travauxRenoTotal;
			this.trconstruction = travauxConstruTotal;
		},
		getTravauxTotaux() {
			const trentretiens = Number.parseInt(this.trvame);
			const trconstruction = Number.parseInt(this.trconstruction);
			const fraisAmeublement = this.formeJuridiqueSelected([
				"sasuis",
				"sciis",
				"lmnpbic",
				"lmnpr",
			])
				? Number(this.fameublement)
				: 0;
			this.travauxTotaux =
				trentretiens + trconstruction + fraisAmeublement;
			return (trentretiens + trconstruction).toLocaleString("fr-FR", {
				minimumFractionDigits: 0,
			});
		},
		// Calcul travaux au m2
		getTravauxM2() {
			const total =
				Number.parseInt(this.trvame) +
				Number.parseInt(this.trconstruction);
			return (total / Number.parseInt(this.surface)).toLocaleString(
				"fr-FR",
				{
					minimumFractionDigits: 0,
				}
			);
		},
		// Cout de revient total du projet
		getCoutRevientTotal() {
			let calcul = 0;
			const cout_revient = this.coutacquisition;
			let cout_revient_total = 0;
			if (this.typeexploitation === "pinel_neuf") {
				cout_revient_total = cout_revient + this.fgarantie.val;
			}
			if (this.typeexploitation === "denormandie") {
				// Attention, très sale, il comparer la date d'acquisition et la date de mise en location
				const somme_travaux =
					+this.travauxReno.year_0 +
					+this.travauxReno.year_1 +
					+this.travauxReno.year_2 +
					+this.travauxReno.year_3;
				cout_revient_total =
					Number(cout_revient) +
					Number(this.fgarantie.val) +
					Number(somme_travaux);
			}
			if (
				this.typeexploitation === "pinel_ancien" ||
				this.typeexploitation === "malraux"
			) {
				// Attention, très sale, il comparer la date d'acquisition et la date de mise en location
				const somme_travaux =
					+this.travauxConstru.year_0 +
					+this.travauxConstru.year_1 +
					+this.travauxConstru.year_2 +
					+this.travauxConstru.year_3;

				cout_revient_total =
					Number(cout_revient) +
					Number(this.fgarantie.val) +
					Number(somme_travaux);
			}
			const surface_fiscale = +this.surface + +this.surfaceannexe;
			if (this.typeexploitation === "pinel_neuf") {
				// si cout de revient total pinel neuf <= 300 000 ET cout de revient total pinel neuf / surface fiscal <= 5500
				if (
					cout_revient_total <= 300000 &&
					cout_revient_total / surface_fiscale <= 5500
				) {
					// alors cout de revien total pinel neuf
					calcul = cout_revient_total;
				} else {
					// si cout de revient total > 300 000
					if (cout_revient_total > 300000) {
						calcul = 300000;
					} else {
						// si cout de revient total / surface fiscale > 5500
						if (cout_revient_total / surface_fiscale > 5500) {
							calcul = surface_fiscale * 5500;
						}
					}
				}
			}
			if (this.typeexploitation === "denormandie") {
				let somme_travaux = 0;
				somme_travaux =
					+this.travauxReno.year_0 +
					+this.travauxReno.year_1 +
					+this.travauxReno.year_2 +
					+this.travauxReno.year_3;
				if (somme_travaux / cout_revient_total >= 0.25) {
					// si cout de revient total pinel neuf <= 300 000 ET cout de revient total pinel neuf / surface fiscal <= 5500
					if (
						cout_revient_total <= 300000 &&
						cout_revient_total / surface_fiscale <= 5500
					) {
						// alors cout de revien total pinel neuf
						calcul = cout_revient_total;
					} else {
						// si cout de revient total > 300 000
						if (cout_revient_total > 300000) {
							calcul = 300000;
						} else {
							// si cout de revient total / surface fiscale > 5500
							if (cout_revient_total / surface_fiscale > 5500) {
								calcul = surface_fiscale * 5500;
							}
						}
					}
				} else calcul = cout_revient_total;
			}
			if (this.typeexploitation === "pinel_ancien") {
				// si cout de revient total pinel neuf <= 300 000 ET cout de revient total pinel neuf / surface fiscal <= 5500
				if (
					cout_revient_total <= 300000 &&
					cout_revient_total / surface_fiscale <= 5500
				) {
					// alors cout de revien total pinel neuf
					calcul = cout_revient_total;
				} else {
					// si cout de revient total > 300 000
					if (cout_revient_total > 300000) {
						calcul = 300000;
					} else {
						// si cout de revient total / surface fiscale > 5500
						if (cout_revient_total / surface_fiscale > 5500) {
							calcul = surface_fiscale * 5500;
						}
					}
				}
			}
			if (this.typeexploitation === "malraux")
				calcul = cout_revient_total;
			return calcul ?? 0;
		},
		getSurface() {
			return Number.parseInt(this.surface);
		},
		surfaceFiscale() {
			return (
				Number.parseFloat(this.surface) +
				Number.parseFloat(this.surfaceannexe)
			);
		},
		refreshEvolution() {
			const typeLocal =
				this.categ === "appartement" ? "Appartement" : "Maison";
			let nbPieceLocal = 0;
			if (typeLocal === "Appartement") {
				if (
					this.typeBien === "CH" ||
					this.typeBien === "ST" ||
					this.typeBien === "T1"
				) {
					nbPieceLocal = 1;
				} else if (this.typeBien === "T2") {
					nbPieceLocal = 2;
				} else if (this.typeBien === "T3") {
					nbPieceLocal = 3;
				} else if (this.typeBien === "T4") {
					nbPieceLocal = 4;
				} else if (this.typeBien === "T5") {
					nbPieceLocal = 5;
				}
			}
		},
		cityData() {
			// Check si la ville est comprise dans les JSON, puis update les valeurs prix moyen et evolution des prix
			// Si elle n'y figure pas, reset valeurs en null
			// Si besoin d'update le JSON, il y a un convertisseur xlxs dans /assets/cityData

			if (JSONEvolution[this.loc.carac.citycode]) {
				const ville = JSONPrix[this.loc.carac.citycode];
				this.evolution = ville.evolution;
			} else {
				this.evolution.unAn = null;
				this.evolution.cinqAns = null;
			}

			if (JSONPrix[this.loc.carac.citycode]) {
				const ville = JSONPrix[this.loc.carac.citycode];
				this.averagePrice = ville;
			} else {
				Object.keys(this.averagePrice).forEach(
					(i) => (this.averagePrice[i] = null)
				);
			}
		},
		numberWithSpaces(x) {
			if (isNaN(x) || x === undefined) return 0;
			const parts = x.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
			return parts.join(".");
		},
		// NEW METHODS
		typeFiscInvestTMI(index, event) {
			this.type_fisc[index].tmi = this.listInvest.filter(
				(val) => val._id === event.target.value
			)[0].tmi;
			this.type_fisc[index].nameInvest = this.listInvest.filter(
				(val) => val._id === event.target.value
			)[0].name;
		},
		addFieldDesc(value, fieldType) {
			fieldType.push({
				id: value.id + 1,
				type: "",
				surface: 0,
				isTaken: false,
				loyer: 0,
			});
		},
		removeFieldDesc(index, fieldType) {
			fieldType.splice(index, 1);
		},
		getDataEmpruntByMonth(mois) {
			let montantEmprunte = 0;
			if (
				["pinel_neuf", "neuf_classique"].includes(this.typeexploitation)
			) {
				montantEmprunte =
					this.montantemprunte.isAuto === true
						? Number(this.montantemprunte.valAuto)
						: Number(this.montantemprunte.val) *
						  (+this.appelDeFond.reservation / 100);
			}
		},
		/**
		 * Calcul des emprunts par années
		 */
		aconstantecal(option) {
			const montantemprunteCal = this.montantemprunte.isAuto
				? this.montantemprunte.valAuto
				: this.montantemprunte.val;

			// Change la valeur prise selon le mode de financement (composant / apport)
			let montantEmprunte;
			// Penser à ajouter LOC'AVANTAGES NEUF ici à l'avenir

			if (this.modefinancement === "composant") {
				montantEmprunte =
					this.montantemprunte.isAuto === true
						? Number(this.montantemprunte.valAuto)
						: Number(this.montantemprunte.val);
			} else {
				montantEmprunte = this.fraisTotaux - this.apportPersoApport;
			}

			// this.montantemprunte.valAuto = Number(this.montantemprunte.montantempruntePercentage.val) * Number(this.montantempr.val)
			this.apportfond.valAuto =
				Number(this.apportfond.apportfondPercentage.val) *
				Number(this.montantempr.val);
			this.fbancaire.valAuto =
				Number(this.fbancaire.fbancairePercentage.val) *
				Number(montantemprunteCal);
			this.fgarantie.valAuto =
				Number(this.fgarantie.fgarantiePercentage.val) *
				Number(montantemprunteCal);
			// this.fbancaire.valAuto = Number(this.fbancaire.fbancairePercentage.val) * Number(this.coutacquisition)
			// this.fgarantie.valAuto = Number(this.fgarantie.fgarantiePercentage.val) * Number(this.coutacquisition)

			// this.annuite.val = Number(this.montantemprunte.val) * (Number(this.tinteretempr.val) / (1 - (1 + Number(this.tinteretempr.val)) ** -Number(this.dureeempr.val)))

			const txInt =
				this.tinteretempr.isAuto === true
					? Number(this.tinteretempr.tinteretemprPercentage.val)
					: Number(this.tinteretempr.per) / 100;
			const dureeEmpruntMois =
				this.dureeempr.isAuto === true
					? Number(this.dureeempr.valAuto) * 12
					: Number(this.dureeempr.val) * 12;
			const typeDiffere = this.remboursementemprunt;

			let nbMoisDiffere;
			if (typeDiffere === "pas_differe" || typeDiffere === undefined)
				nbMoisDiffere = 0;
			else nbMoisDiffere = Number(this.dureediffere) ?? 0;
			const dureeEmprNonDif = dureeEmpruntMois - nbMoisDiffere;

			const formuleAnnuite =
				(montantEmprunte * txInt) /
				12 /
				(1 - (1 + txInt / 12) ** -dureeEmprNonDif);
			this.annuite.val =
				1 - (1 + txInt) ** -dureeEmpruntMois / 12 === 0
					? 0
					: formuleAnnuite;
			//         FORMULE CALCULEE PRECISION MENSUELLE

			this.annuite.valper = this.annuite.val / montantEmprunte;
			this.annuite.per = Number((this.annuite.valper * 100).toFixed(2));
			if (option === "data") {
				// si appellee de amortDataUpdate pour calculer les autres données
				return {
					amortPeriod: dureeEmpruntMois,
					amortCapStart: montantEmprunte,
					amortAnnuit: this.annuite.val,
					amortTxInt: txInt,
					amortAssur: this.tassuranceempr.val * montantEmprunte,
					nbMoisDiffere,
				};
			}
			if (!isNaN(this.annuite.val)) {
				return this.annuite.val;
			} else {
				return 0;
			}
		},
		amortDataUpdate() {
			const amortDataTemp = [];
			const annuiteData = this.aconstantecal("data");
			const moisDifferes = annuiteData.nbMoisDiffere;
			let resteAmort = annuiteData.amortCapStart;
			let sumResteAmort = annuiteData.amortCapStart; // idem maj chaque annee
			const txInt = annuiteData.amortTxInt;
			const dureeTotaleMois = annuiteData.amortPeriod;
			const typeDiffere = this.remboursementemprunt; // pas_differe, partiel ou total
			let id = 1;
			let sumAnnuit = 0; // Somme des mensualites chaque annee
			let sumIntAnnuel = 0; // Somme des interets chaque annee
			let sumCapRembAnnuel = 0; // Somme des capitaux rembourses chaque annee
			let sumAssurance = 0; // Somme des assurances
			let montantEmprAvecInt; // Variable qui sert au calcul du differe total
			let sumCapEnd = 0;

			// Chaque mois, on modelise le calcul de l'amortissement
			// A la fin de l'annee, on push dans un array la ligne a afficher
			const prn_debut = [];
			const assurance = [];
			const prn_rembourse = [];
			const prn_fin = [];
			const mensualites = [];
			const interets = [];
			const type_differe = [];
			const resInterets = [];
			const montant_emprunt =
				this.montantemprunte.isAuto === true
					? Number(this.montantemprunte.valAuto)
					: Number(this.montantemprunte.val);
			let year = 1;
			for (let mois = 0; mois < dureeTotaleMois; mois++) {
				let amortCapRembMensu = 0;
				type_differe.push(
					ProjectTools.calculTypeDiffere(
						mois + 1,
						this.remboursementemprunt,
						this.dureediffere
					)
				);
				if (
					["pinel_neuf", "classique_neuf"].includes(
						this.typeexploitation
					)
				) {
					// premier index
					if (mois === 0) {
						// PRN_debut
						prn_debut.push(
							this.montantemprunte.isAuto === true
								? Number(this.montantemprunte.valAuto)
								: Number(this.montantemprunte.val) *
										(+this.appelDeFond.reservation / 100)
						);
						// Interets
						interets.push((prn_debut[mois] * txInt) / 12);
						// Mensualités
						if (type_differe[mois] === mois + 1) {
							mensualites.push(
								(this.montantemprunte.val * txInt) /
									12 /
									(1 - (1 + txInt / 12) ** -dureeTotaleMois)
							);
						} else if (type_differe[mois] === "D1") {
							mensualites.push(interets[mois]);
						} else if (type_differe[mois] === "D2") {
							mensualites.push(0);
						} else {
							mensualites.push(0);
						}
						// PRN rembourse
						if (type_differe[mois] === mois + 1) {
							prn_rembourse.push(
								mensualites[mois] - interets[mois]
							);
						} else {
							prn_rembourse.push(0);
						}
						// PRN fin
						prn_fin.push(prn_debut[mois] - prn_rembourse[mois]);
						// si echance <= réservation adf 1 && prn debut < montant emprunt
						if (
							mois + 1 <=
								this.appelDeFond.livraisonRemiseClefMois &&
							prn_debut[mois] < montant_emprunt
						) {
							// assurance emprunt * prn_debut / 12
							assurance.push(
								(this.tassuranceempr.val * prn_debut[mois]) / 12
							);
						} else {
							assurance.push(
								(this.tassuranceempr.val * montant_emprunt) / 12
							);
						}
						sumResteAmort = prn_debut[mois];
					} else {
						// PRN_debut
						if (
							mois + 1 ===
							+this.appelDeFond.achevementFondationMois
						) {
							prn_debut.push(
								+prn_fin[mois - 1] +
									+(this.montantemprunte.isAuto === true
										? Number(this.montantemprunte.valAuto)
										: Number(this.montantemprunte.val) *
										  (+this.appelDeFond
												.achevementFondation /
												100))
							);
						} else if (
							mois + 1 ===
							+this.appelDeFond.miseEauAirMois
						) {
							prn_debut.push(
								+prn_fin[mois - 1] +
									+(this.montantemprunte.isAuto === true
										? Number(this.montantemprunte.valAuto)
										: Number(this.montantemprunte.val) *
										  (+this.appelDeFond.miseEauAir / 100))
							);
						} else if (
							mois + 1 ===
							+this.appelDeFond.achevementTravauxMois
						) {
							prn_debut.push(
								+prn_fin[mois - 1] +
									+(this.montantemprunte.isAuto === true
										? Number(this.montantemprunte.valAuto)
										: Number(this.montantemprunte.val) *
										  (+this.appelDeFond.achevementTravaux /
												100))
							);
						} else if (
							mois + 1 ===
							+this.appelDeFond.livraisonRemiseClefMois
						) {
							prn_debut.push(
								+prn_fin[mois - 1] +
									+(this.montantemprunte.isAuto === true
										? Number(this.montantemprunte.valAuto)
										: Number(this.montantemprunte.val) *
										  (+this.appelDeFond
												.livraisonRemiseClef /
												100))
							);
						} else {
							prn_debut.push(prn_fin[mois - 1]);
						}
						// Interets
						interets.push((prn_debut[mois] * txInt) / 12);
						// Mensualités
						// si différé = mois en cours && pas de différé
						if (
							type_differe[mois] === mois + 1 &&
							this.remboursementemprunt === "pas_differe"
						) {
							// meme calcul que index 0

							mensualites.push(
								(this.montantemprunte.val * txInt) /
									12 /
									(1 - (1 + txInt / 12) ** -dureeTotaleMois)
							);
							// sinon si différé = mois en cours && différé - 1 = "D1"
						} else if (
							type_differe[mois] === mois + 1 &&
							type_differe[mois - 1] === "D1"
						) {
							// Meme calcul que index 0 avec durrée du différé en +
							mensualites.push(
								(this.montantemprunte.val * txInt) /
									12 /
									(1 -
										(1 + txInt / 12) **
											-(
												dureeTotaleMois -
												+this.dureediffere
											))
							);
							// sinon si différé === D1
						} else if (type_differe[mois] === "D1") {
							mensualites.push(interets[mois]);
							// sinon si différé = d2
						} else if (type_differe[mois] === "D2") {
							mensualites.push(0);
							// sinon si différé = mois && differe - 1 === D2
						} else if (
							type_differe[mois] === mois + 1 &&
							type_differe[mois - 1] === "D2"
						) {
							// alors prn debut - 1 et meme calcul qu'en haut
							// (98324 * 0.013) / 12 / (1 - (1 + 0.013 / 12) ** -(240 - 12))
							// (prn_fin[mois - 1] * txInt) / 12 / (1 - (1 + txInt / 12) ** -(dureeTotaleMois - this.dureediffere))
							mensualites.push(
								(prn_fin[mois - 1] * txInt) /
									12 /
									(1 -
										(1 + txInt / 12) **
											-(
												dureeTotaleMois -
												+this.dureediffere
											))
							);
							// sinon si différé = mois && differe - 1 !== D2
						} else if (
							type_differe[mois] === mois + 1 &&
							type_differe[mois - 1] !== "D2"
						) {
							// alors mensualité -1
							mensualites.push(mensualites[mois - 1]);
						}
						// PRN rembourse
						if (type_differe[mois] === mois + 1) {
							prn_rembourse.push(
								mensualites[mois] - interets[mois]
							);
						} else {
							prn_rembourse.push(0);
						}
						// PRN fin
						if (
							type_differe[mois] === mois + 1 ||
							type_differe[mois] === "D1"
						) {
							prn_fin.push(
								prn_debut[mois] -
									mensualites[mois] +
									interets[mois]
							);
						} else if (type_differe[mois] === "D2") {
							prn_fin.push(prn_debut[mois] + interets[mois]);
						} else {
							prn_fin.push(0);
						}
						// assurance
						if (mois + 1 <= 36) {
							if (
								mois + 1 <=
									this.appelDeFond.livraisonRemiseClefMois &&
								prn_debut[mois] < montant_emprunt
							) {
								// assurance emprunt * prn_debut / 12
								assurance.push(
									(this.tassuranceempr.val *
										prn_debut[mois]) /
										12
								);
							} else {
								assurance.push(
									(this.tassuranceempr.val *
										montant_emprunt) /
										12
								);
							}
						} else {
							assurance.push(
								(this.tassuranceempr.val * montant_emprunt) / 12
							);
						}
					}
					sumIntAnnuel += interets[mois];
					sumAnnuit += mensualites[mois];
					sumCapRembAnnuel += prn_rembourse[mois];
					sumAssurance += assurance[mois];
					// si c'est le dernier mois de l'annee ou du pret, on push une ligne
					if (mois % 12 === 11 || mois === dureeTotaleMois - 1) {
						if (year === 1) {
							sumResteAmort = prn_debut[0];
							sumCapEnd = prn_fin[11];
						} else if (year === 2) {
							sumResteAmort = prn_fin[11];
							sumCapEnd = prn_fin[23];
						} else if (year === 3) {
							sumResteAmort = prn_fin[23];
							sumCapEnd = prn_fin[35];
						} else {
							sumResteAmort = amortDataTemp[year - 2].amortCapEnd;
							sumCapEnd = sumResteAmort - sumCapRembAnnuel;
						}
						amortDataTemp.push({
							id,
							amortCapStart: Math.round(sumResteAmort),
							amortAnnuit: Math.round(sumAnnuit),
							amortPartInt: Math.round(sumIntAnnuel),
							amortCapRemb: Math.round(sumCapRembAnnuel),
							amortCapEnd: Math.round(sumCapEnd),
							amortAssur: Math.round(sumAssurance),
						});

						id++;
						sumResteAmort -= sumCapRembAnnuel;
						resInterets.push(sumIntAnnuel);
						// reset des sommes
						sumAnnuit = 0;
						sumIntAnnuel = 0;
						sumAssurance = 0;
						sumCapRembAnnuel = 0;
						year++;
					}
					resteAmort -= amortCapRembMensu;
					// Calcul non neuf
				} else {
					let amortPartInt, amortPartMensu;

					// Differents traitements selon le type de differe
					if (typeDiffere === "partiel") {
						amortPartInt = (resteAmort * txInt) / 12;
						if (mois < moisDifferes) amortPartMensu = amortPartInt;
						else amortPartMensu = annuiteData.amortAnnuit;
					} else if (typeDiffere === "total") {
						if (mois < moisDifferes) {
							amortPartMensu = 0;
						} else {
							// Pour ne pas que la mensualite baisse, on set cette variable une seule fois
							if (mois === moisDifferes)
								montantEmprAvecInt = resteAmort;
							amortPartMensu = // formule mensualite modifiee pour differe total
								(montantEmprAvecInt * txInt) /
								12 /
								(1 -
									(1 + txInt / 12) **
										-(dureeTotaleMois - moisDifferes));
						}
						amortPartInt = (resteAmort * txInt) / 12;
					} else {
						// pas de differe
						amortPartInt = (resteAmort * txInt) / 12;
						amortPartMensu = annuiteData.amortAnnuit;
					}

					amortCapRembMensu = amortPartMensu - amortPartInt;
					// on incremente les sommes pour le recap annuel du tableau
					sumAnnuit += amortPartMensu;
					sumIntAnnuel += amortPartInt;
					sumCapRembAnnuel += amortPartMensu - amortPartInt;
					// si c'est le dernier mois de l'annee ou du pret, on push une ligne
					if (mois % 12 === 11 || mois === dureeTotaleMois - 1) {
						amortDataTemp.push({
							id,
							amortCapStart: Math.round(sumResteAmort),
							amortAnnuit: Math.round(sumAnnuit),
							amortPartInt: Math.round(sumIntAnnuel),
							amortCapRemb: Math.round(sumCapRembAnnuel),
							amortCapEnd: Math.abs(
								Math.round(sumResteAmort - sumCapRembAnnuel)
							),
							amortAssur: Math.round(annuiteData.amortAssur),
						});

						id++;
						sumResteAmort -= sumCapRembAnnuel;
						resInterets.push(sumIntAnnuel);

						// reset des sommes
						sumAnnuit = 0;
						sumIntAnnuel = 0;
						sumCapRembAnnuel = 0;
						year++;
					}
					resteAmort -= amortCapRembMensu;
				}
			}
			if (amortDataTemp) this.amortData = amortDataTemp;
		},
		APICall(search) {
			// //console.log("SEARCH ==>",search)
			if (search === "") return;
			return axios
				.get("/apigouv", {
					params: {
						search,
					},
				})
				.then((response) => {
					// //console.log("API response ===========>", response)
					// return
					return response.data;
				})
				.catch((e) => {
					// console.log("erreur =====>" , e)
					throw e;
				});
		},
		setLocalisation(loc) {
			this.error.pinel = false;
			// console.log("loc ======>", loc.properties, loc.geometry.coordinates)
			// ???
			this.loc.carac = {
				...loc.properties,
				coordinates: loc.geometry.coordinates,
			};
			// console.log("loc.carac",this.loc.carac)
			this.checkExploitation(this.typeexploitation);
			this.setProjectLocalisation(loc);
			this.refreshEvolution();
			this.cityData();
		},
		checkExploitationEvent(event) {
			// this.formejuridique.map((val) => (val.selected = false));
			// this.formejuridique[0].selected = true;
			if (this.typeexploitation === "malraux") this.dureeDetentionMin = 9;
			const select = document.querySelector("#formejuridique");
			select.value = "0";
			this.checkExploitation(event.target.value);
		},
		checkExploitation(value) {
			this.error.pinel = false;
			if (["pinel_neuf", "classique_neuf"].includes(value)) {
				this.remboursementemprunt = "partiel";
				this.dureediffere = this.appelDeFond.livraisonRemiseClefMois;
			}
			if (value === "pinel_neuf" || value === "pinel_ancien") {
				this.categcalc = "appartement";
				if (
					this.loc.carac.city === undefined ||
					!this.checkPinel(this.loc.carac.city)
				) {
					this.error.pinel = true;
				}
			} else if (value === "denormandie") {
				this.categcalc = "appartement";
				if (
					this.loc.carac.city === undefined ||
					!this.checkDenormandie(this.loc.carac.city)
				) {
					this.error.pinel = true;
				}
			}
			this.refreshEvolution();
		},
		checkPinel(ville) {
			this.error.pinel = false;
			const pinel = require("@/assets/json/pinel.json");
			const exist = Array.from(pinel.pinel).filter(
				(data) => data.ZONE_ABIS === ville
			);
			if (exist.length > 0) this.zonage = exist[0].ZONAGE;
			else this.zonage = "";
			return exist.length > 0;
		},
		checkDenormandie(ville) {
			this.error.pinel = false;
			const denormandie = require("@/assets/json/denormandie.json");
			const exist = Array.from(denormandie.denormandie).filter(
				(data) => data.ZONE_ABIS === ville
			);
			if (exist.length > 0) this.zonage = exist[0].ZONAGE;
			else this.zonage = "";
			return exist.length > 0;
		},
		addFieldFisc(value, fieldType) {
			fieldType.push({
				// id: index + 2,
				idInvest: "",
				nameInvest: "",
				apport: 0,
				capital: 50,
				tmi: 30,
			});
		},
		removeFieldFisc(index, fieldType) {
			fieldType.splice(index, 1);
		},
		formeJuridiqueSelected(value) {
			let bool = false;
			this.formejuridique.map((val) => {
				if (val.selected === true) {
					value.map((index) => {
						if (val.code === index) {
							return (bool = true);
						}
					});
				}

				// if(value.length === 1) {
				// 	if(val.selected === true & val.code === value) bool = true
				// } else if (value.length === 2) {

				// 	if(val.selected === true & (val.code === value[0] || val.code === value[1])) bool = true
				// } else if (value.length === 3) {
				// 	if(val.selected === true & (val.code === value[0] || val.code === value[1] || val.code === value[2])) bool = true
				// }
			});

			if (bool === true) return true;
			else return false;
		},
		formeJuridiqueSelectedContain(value) {
			let bool = false;
			this.formejuridique.map((val) => {
				if (val.selected === true) {
					value.map((fj) => {
						if (val.code.includes(fj)) bool = true;
					});
				}
			});

			if (bool === true) return true;
			else return false;
		},
		// END NEW METHODS

		// METHOD FORM SIMULATION OLD
		percentage(val) {
			return val / 100;
		},
		onChange(event) {
			// //////console.log(event.target)
		},
		treval(e) {
			if (Number(e.target.value) > 5) {
				this.warning.isTRA = true;
			} else {
				this.warning.isTRA = false;
			}
		},
		onChangeEngagement(event) {
			this.dureeDetentionMin = event.target.value;
			// const select = document.querySelector('#engagement');
			const select = {};
			if (this.dureedetent < this.dureeDetentionMin) {
				select.value = this.dureeDetentionMin;
				this.dureedetention.val = this.dureeDetentionMin;
			}
		},

		onChangeSigleAchatBienBtn(value, boolean) {
			this[value][`${value}Euro`] = boolean;
			// if (this.fnotaire.fnotaireEuro === false) {
			//   this.fnotaire.per = Number(((Number(this.fnotaire.val) / Number(this.buyprice)) * 100).toFixed(2))
			// } else {
			//   this.fnotaire.val = Number(((Number(this.fnotaire.per) / 100) * Number(this.buyprice)).toFixed(2))
			// }
		},
		onChangeDateAcqui() {
			if (document.querySelector("#achevementtravauxInput")) {
				const select = document.querySelector(
					"#achevementtravauxInput"
				);
				const date = new Date(this.dateacqui);
				const newDate = new Date(date.setMonth(date.getMonth() + 36));
				const month = newDate.getMonth() + 1;
				const year = newDate.getFullYear();
				const dateP36 = `${year}-${month}`;

				select.max = dateP36;
				select.min = this.dateacqui;
			}
		},
		onChangeSigleAchatBien(value) {
			if (this[value][`${value}Euro`] === false) {
				this[value][`${value}Euro`] = true;
			} else {
				this[value][`${value}Euro`] = false;
			}
			// if(Number(this[value].val) !== 0 && this.manuel.buyprice !=0 ) {
			if (this[value][`${value}Euro`] === true) {
				this[value].val = Number(
					(
						(Number(this[value].per) / 100) *
						Number(this.buyprice)
					).toFixed(2)
				);
			} else if (this[value][`${value}Euro`] === false) {
				this[value].per = Number(
					(
						(Number(this[value].val) / Number(this.buyprice)) *
						100
					).toFixed(2)
				);
			}
			// }
		},
		onChangeSigleLocatifBien(value, boolean) {
			this[value][`${value}Euro`] = boolean;
		},

		onSelect(e, state) {
			const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
			const file = e.target.files[0];
			const maxSize = 5 * 1024 * 1024;
			if (!allowedTypes.includes(file.type) && file.size > maxSize) {
				this.fileMessage = "";
				this.isfileError = true;
				this.fileMessage =
					"Le type du fichier n'est pas bon ! La taille de l'image est supérieure à 5MB !";
				alert(
					"Le type du fichier n'est pas bon ! La taille de l'image est supérieure à 5MB !"
				);
			} else if (!allowedTypes.includes(file.type)) {
				this.fileMessage = "";
				this.isfileError = true;
				this.fileMessage = "Le type du fichier n'est pas bon !";
			} else if (file.size > maxSize) {
				this.fileMessage = "";
				this.isfileError = true;
				this.fileMessage =
					"La taille de l'image est supérieure à 5MB !";
				alert(
					"Attention ! L'image n'a pas pu être chargée car elle dépasse 5 Mo. Sélectionnez-en une autre."
				);
			} else {
				switch (state) {
					case "principale":
						this.file.imgPrincipale = file;
						break;
					case "photo1":
						this.file.imgPhoto1 = file;
						break;
					case "photo2":
						this.file.imgPhoto2 = file;
						break;
				}
				this.fileMessage = "";
				this.isfileError = false;
			}
		},
		ecart_mois(date_max, date_min) {
			const explode_date_min = date_min.split("-");
			const explode_date_max = date_max.split("-");

			const mois_min = parseInt(explode_date_min[1]);
			const annee_min = parseInt(explode_date_min[0]);
			const mois_max = parseInt(explode_date_max[1]);
			const annee_max = parseInt(explode_date_max[0]);

			const ecart = (annee_max - annee_min) * 12 - mois_min + mois_max;
			return ecart;
		},
		// METHOD FORM INVESTISSEUR OLD
		toYear(val) {
			return val / 12;
		},
		onChangeSigle(value, boolean) {
			this[value][`${value}Euro`] = boolean;
		},
		allSelected(associates) {
			let selected = true;
			for (const i in associates) {
				if (i > 0) {
					if (associates[i].nameInvest === "") {
						selected = false;
					}
				}
			}
			return selected;
		},
		async onSubmit() {
			try {
				if (
					["pinel_neuf", "classique_neuf"].includes(
						this.typeexploitation
					) &&
					(this.appelDeFond.achevementFondationMois <= 0 ||
						this.appelDeFond.achevementTravauxMois <= 0 ||
						this.appelDeFond.livraisonRemiseClefMois <= 0 ||
						this.appelDeFond.miseEauAirMois <= 0)
				) {
					alert(
						"Veuillez saisir les données dans la popup Appels de Fonds"
					);
					const element = this.$refs.adf;
					const top = element.offsetTop;
					window.scrollTo(0, top);
				} else if (
					this.formeJuridiqueSelected([
						"sciis",
						"sciirmf",
						"sciirr",
						"sasis",
					]) &&
					!this.allSelected(this.type_fisc)
				) {
					console.log(
						"ssis",
						this.formeJuridiqueSelected([
							"sciis",
							"sciirmf",
							"sciirr",
							"sasis",
						]),
						"not selcted, ",
						!this.allSelected(this.type_fisc)
					);
					alert("Veuillez choisir tous vos associés.");
				} else if (
					this.ecart_mois(this.achevementtravaux, this.dateacqui) > 48
				) {
					alert("La date de détention est superieure à 48 mois.");
					const element = this.$refs.signature;
					const top = element.offsetTop;
					window.scrollTo(0, top);
				} else if (
					this.prctCoutRevient < 25 &&
					["denormandie"].includes(this.typeexploitation)
				) {
					alert(
						"Le montant des travaux de rénovation n'est pas suffisant pour déclencher la réduction d'impôts Denormandie. Il doit correspondre à au moins 25% du coût de revient du projet."
					);
					const element = this.$refs.coutRevient;
					const top = element.offsetTop;
					window.scrollTo(0, Math.abs(top.top));
				} else if (
					this.buypricetotcalc === 0 ||
					this.buypricetotcalc === ""
				) {
					alert("Veuillez entrer un prix d'achat.");
					const element = this.$refs.prixAchat;
					const top = element.offsetTop;
					window.scrollTo(0, top);
				} else if (!this.loc.carac.coordinates) {
					alert("Veuillez entrer une adresse");
					const element = this.$refs.adress;
					const top = element.offsetTop;
					window.scrollTo(0, top);
				} else if (
					this.loyermensuel === 0 ||
					this.loyermensuel === ""
				) {
					alert("Veuillez entrer un loyer mensuel pratiquable.");
					const element = this.$refs.loyermensuel;
					const top = element.offsetTop;
					window.scrollTo(0, top);
				} else {
					let formDatas = new FormData();
					if (
						!(
							this.file.imgPrincipale ||
							this.file.imgPhoto1 ||
							this.file.imgPhoto2
						)
					) {
						formDatas = null;
					} else {
						if (this.file.imgPrincipale)
							formDatas.append(
								"principale",
								this.file.imgPrincipale
							);
						if (this.file.imgPhoto1)
							formDatas.append("photo1", this.file.imgPhoto1);
						if (this.file.imgPhoto2)
							formDatas.append("photo2", this.file.imgPhoto2);
					}
					// return
					this.isloading_modal = true;
					const acquisition = moment(`${this.dateacqui}-01`);
					const achevement_travaux = moment(
						`${this.achevementtravaux}-01`
					);
					console.log("annee_fin_travaux");
					let annee_fin_travaux = achevement_travaux.diff(
						acquisition,
						"years"
					);
					let mois_fin_travaux = achevement_travaux.diff(
						acquisition.add(annee_fin_travaux, "Y"),
						"month"
					);
					if (mois_fin_travaux === 0 && annee_fin_travaux > 0) {
						mois_fin_travaux = 12;
						annee_fin_travaux = annee_fin_travaux - 1;
					}
					if (this.projectSelected === null) {
						const acquisition = moment(`${this.dateacqui}-01`);
						this.setProject({
							idUser: this.user._id,
							formDatas,
							body: {
								idInvest: this.investSelected._id,
								name: this.name,
								loc: {
									name: this.loc.name,
									carac: this.loc.carac,
								},
								isPaid: this.user.sub_level !== 0,
								carac: {
									annee_fin_travaux,
									mois_fin_travaux,
									achevementtravaux: this.achevementtravaux,
									empruntOption: this.empruntOption,
									categ: this.categ,
									type: this.type,
									surface: this.surface,
									surfaceannexe: this.surfaceannexe,
									typeloc_surface: this.typeloc_surface,
									type_fisc: this.type_fisc,
									dividendeSCI: this.dividendeSCI,
									fameublement: this.fameublement,
									fameublementDeduit: this.fameublementDeduit,
									fameublementDeduction:
										this.fameublementDeduction,
									fsociete: this.fsociete,
									evolution1an: this.evolution.unAn,
									evolution5ans: this.evolution.cinqAns,
									more_info: this.more_info,
									typeexploitation: this.typeexploitation,
									occupation: this.occupation,
									dpe: this.dpe,
									doubleDFTravauxEnergie:
										this.doubleDFTravauxEnergie,
									pinelplus: this.pinelplus,
									engagement: this.engagement,
									engagementPlusTravaux:
										this.engagementPlusTravaux,
									zonage: this.zonage,
									zonageMalraux: this.zonageMalraux,
									interlocuteur: this.interlocuteur,
									immeuble: this.immeuble,
									appelDeFond: this.appelDeFond,
									cumulADF: this.cumulADF,
									travauxReno: this.travauxReno,
									travauxConstru: this.travauxConstru,
									exonerationTaxeFonciere:
										this.exonerationTaxeFonciere,
									modefinancement: this.modefinancement,
									modeimposition: this.modeimposition,
									changementDeFiscalite:
										this.changementDeFiscalite,
									nouveauLoyerMensuel:
										this.nouveauLoyerMensuel,
									nouveauPNO: this.nouveauPNO,
									nouveauGLI: this.nouveauGLI,
									nouveauHgestionloc: this.nouveauHgestionloc,
									newformejuridique: this.newformejuridique,
									changementFormeJuridique:
										this.changementFormeJuridique,
									remboursementemprunt:
										this.remboursementemprunt,
									dureediffere: this.dureediffere,
									differeDeMiseEnLocation:
										this.differeDeMiseEnLocation,
									loyerMaximumPraticable:
										this.loyerMaximumPraticable,
									buyprice: this.buyprice,
									buypricetot: this.buypricetot,
									dateacqui: this.dateacqui,
									datesignature: this.datesignature,
									fnotaire: this.fnotaire,
									fnotairetot: this.fnotairetot,
									fagence: this.fagence,
									fagencetot: this.fagencetot,
									coutacquisition: this.coutacquisition,
									loyermensuel: this.loyermensuel,
									revenulocatif: this.revenulocatif,
									rendementbrut: this.rendementbrut,
									trevalorisation: this.trevalorisation,
									rvacanceloc: this.rvacanceloc,
									hgestionloc: this.hgestionloc,
									chargecopro: this.chargecopro,
									apno: this.apno,
									chargediverse: this.chargediverse,
									gli: this.gli,
									totalOPEX: this.totalOPEX,
									trevalorisationOPEX:
										this.trevalorisationOPEX,
									tfonciere: this.tfonciere,
									provision: this.provision,
									tva: this.tva,
									trvame: this.trvame,
									trvametot: this.trvametot,
									trconstruction: this.trconstruction,
									loyermoy: this.loyermoy,
									datetrv: this.datetrv,
									montantempr: this.montantempr,
									montantemprunte: this.montantemprunte,
									apportPersoComposant:
										this.apportPersoCompoComputed,
									apportPersoApport: this.apportPersoApport,
									apportfond: this.apportfond,
									dureeempr: this.dureeempr,
									tinteretempr: this.tinteretempr,
									tassuranceempr: this.tassuranceempr,
									fbancaire: this.fbancaire,
									fbancairetot: this.fbancairetot,
									fgarantie: this.fgarantie,
									ira: this.ira,
									annuite: this.annuite,
									tactualisation: this.tactualisation,
									formejuridique: this.formejuridique,
									tmi: this.tmi,
									fcomptable: this.fcomptable,
									// fcrl: this.fcrl,
									fcotisation: this.fcotisation,
									dureedetention: this.dureedetention,
									daterevente: this.daterevente,
									estimreval: this.estimreval,
									estimprixrevente: this.estimprixrevente,
									plusvalue: this.plusvalue,
									augmvaleur: this.augmvaleur,
									averagePrice: this.averagePrice,
									evolution: this.evolution,
									amortData: this.amortData,
									amortissementsparcomposant:
										this.amortissementsparcomposant,
								},
								owner: this.getOwnerSimu,
								exports: {
									imgPrincipale: this.imgs.principale,
									ImmeublePJ: {
										0: this.imgs.photo1,
										1: this.imgs.photo2,
									},
								},
							},
							res: {
								...this.resultats,
							},
						})
							.then(() => {
								this.isloading_modal = false;
								const idUser= this.user._id
								this.getProjectsSummary(idUser)
							})
							.catch((err) => {
								this.isloading_modal = false;
								console.log("Simulation resultat error", err);
							});
					} else {
						this.updateProject({
							id: this.projectSelected._id,
							idUser: this.user._id,
							formDatas,
							body: {
								idInvest: this.investSelected._id,
								name: this.name,
								loc: {
									name: this.loc.name,
									carac: this.loc.carac,
								},
								img: this.projectSelected.img,
								isPaid: this.user.sub_level !== 0,
								carac: {
									annee_fin_travaux,
									mois_fin_travaux,
									achevementtravaux: this.achevementtravaux,
									empruntOption: this.empruntOption,
									categ: this.categ,
									type: this.type,
									surface: this.surface,
									surfaceannexe: this.surfaceannexe,
									typeloc_surface: this.typeloc_surface,
									type_fisc: this.type_fisc,
									dividendeSCI: this.dividendeSCI,
									fameublement: this.fameublement,
									fameublementDeduit: this.fameublementDeduit,
									fameublementDeduction:
										this.fameublementDeduction,
									fsociete: this.fsociete,
									evolution1an: this.evolution.unAn,
									evolution5ans: this.evolution.cinqAns,
									more_info: this.more_info,
									typeexploitation: this.typeexploitation,
									occupation: this.occupation,
									dpe: this.dpe,
									doubleDFTravauxEnergie:
										this.doubleDFTravauxEnergie,
									pinelplus: this.pinelplus,
									engagement: this.engagement,
									engagementPlusTravaux:
										this.engagementPlusTravaux,
									zonage: this.zonage,
									zonageMalraux: this.zonageMalraux,
									interlocuteur: this.interlocuteur,
									immeuble: this.immeuble,
									appelDeFond: this.appelDeFond,
									cumulADF: this.cumulADF,
									travauxReno: this.travauxReno,
									travauxConstru: this.travauxConstru,
									exonerationTaxeFonciere:
										this.exonerationTaxeFonciere,
									modefinancement: this.modefinancement,
									modeimposition: this.modeimposition,
									changementDeFiscalite:
										this.changementDeFiscalite,
									nouveauLoyerMensuel:
										this.nouveauLoyerMensuel,
									nouveauPNO: this.nouveauPNO,
									nouveauGLI: this.nouveauGLI,
									nouveauHgestionloc: this.nouveauHgestionloc,
									newformejuridique: this.newformejuridique,
									changementFormeJuridique:
										this.changementFormeJuridique,
									remboursementemprunt:
										this.remboursementemprunt,
									dureediffere: this.dureediffere,
									differeDeMiseEnLocation:
										this.differeDeMiseEnLocation,
									loyerMaximumPraticable:
										this.loyerMaximumPraticable,
									buyprice: this.buyprice,
									buypricetot: this.buypricetot,
									dateacqui: this.dateacqui,
									datesignature: this.datesignature,
									fnotaire: this.fnotaire,
									fnotairetot: this.fnotairetot,
									fagence: this.fagence,
									fagencetot: this.fagencetot,
									coutacquisition: this.coutacquisition,
									loyermensuel: this.loyermensuel,
									revenulocatif: this.revenulocatif,
									rendementbrut: this.rendementbrut,
									trevalorisation: this.trevalorisation,
									rvacanceloc: this.rvacanceloc,
									hgestionloc: this.hgestionloc,
									chargecopro: this.chargecopro,
									apno: this.apno,
									chargediverse: this.chargediverse,
									gli: this.gli,
									totalOPEX: this.totalOPEX,
									trevalorisationOPEX:
										this.trevalorisationOPEX,
									tfonciere: this.tfonciere,
									provision: this.provision,
									tva: this.tva,
									trvame: this.trvame,
									trvametot: this.trvametot,
									trconstruction: this.trconstruction,
									loyermoy: this.loyermoy,
									datetrv: this.datetrv,
									montantempr: this.montantempr,
									montantemprunte: this.montantemprunte,
									apportPersoComposant:
										this.apportPersoCompoComputed,
									apportPersoApport: this.apportPersoApport,
									apportfond: this.apportfond,
									dureeempr: this.dureeempr,
									tinteretempr: this.tinteretempr,
									tassuranceempr: this.tassuranceempr,
									fbancaire: this.fbancaire,
									fbancairetot: this.fbancairetot,
									fgarantie: this.fgarantie,
									ira: this.ira,
									annuite: this.annuite,
									tactualisation: this.tactualisation,
									formejuridique: this.formejuridique,
									tmi: this.tmi,
									fcomptable: this.fcomptable,
									// fcrl: this.fcrl,
									fcotisation: this.fcotisation,
									dureedetention: this.dureedetention,
									daterevente: this.daterevente,
									estimreval: this.estimreval,
									estimprixrevente: this.estimprixrevente,
									plusvalue: this.plusvalue,
									augmvaleur: this.augmvaleur,
									averagePrice: this.averagePrice,
									evolution: this.evolution,
									amortData: this.amortData,
									amortissementsparcomposant:
										this.amortissementsparcomposant,
								},
								exports: {
									...this.projectSelected.exports,
									imgPrincipale: this.imgs.principale,
									ImmeublePJ: {
										0: this.imgs.photo1,
										1: this.imgs.photo2,
									},
								},
							},
							res: {
								...this.resultats,
							},
						})
							.then(() => {
								this.isloading_modal = false;
							})
							.catch((err) => {
								this.isloading_modal = false;
								console.log("Simulation resultat error", err);
							});
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		montantempruntecal() {
			if (this.montantemprunte.montantemprunteEuro === false) {
				this.montantemprunte.montantemprunteEuro = true;
			} else {
				this.montantemprunte.montantemprunteEuro = false;
			}
			// if(Number(this[value].val) !== 0 && this.manuel.buyprice !=0 ) {
			if (this.montantemprunte.montantemprunteEuro === true) {
				this.montantemprunte.val =
					(Number(this.montantemprunte.per) / 100) *
					Number(this.montantempr.val);
			} else if (this.montantemprunte.montantemprunteEuro === false) {
				this.montantemprunte.per =
					(Number(this.montantemprunte.val) /
						Number(this.montantempr.val)) *
					100;
			}
		},
		apportfondcal() {
			if (this.apportfond.apportfondEuro === false) {
				this.apportfond.apportfondEuro = true;
			} else {
				this.apportfond.apportfondEuro = false;
			}
			if (this.apportfond.apportfondEuro === true) {
				this.apportfond.val =
					(Number(this.apportfond.per) / 100) *
					Number(this.montantempr.val);
			} else if (this.apportfond.apportfondEuro === false) {
				this.apportfond.per =
					(Number(this.apportfond.val) /
						Number(this.montantempr.val)) *
					100;
			}
		},
		// fbancairecal() {
		//   if (this.fbancaire.fbancaireEuro === false) {
		//     this.fbancaire.fbancaireEuro = true
		//   } else {
		//     this.fbancaire.fbancaireEuro = false
		//   }
		//   // if(Number(this[value].val) !== 0 && this.manuel.buyprice !=0 ) {
		//   const montantemprunte = this.montantemprunte.isAuto ? this.montantemprunte.valAuto : this.montantemprunte.val
		//   if (this.fbancaire.fbancaireEuro === true) {
		//     // this.fbancaire.val = (Number(this.fbancaire.per) / 100) * Number(this.coutacquisition)
		//     this.fbancaire.val = (Number(this.fbancaire.per) / 100) * Number(montantemprunte)
		//   } else if (this.fbancaire.fbancaireEuro === false) {
		//     // this.fbancaire.val = (Number(this.fbancaire.per) / 100) * Number(this.coutacquisition)
		//     this.fbancaire.per = (Number(this.fbancaire.val) / Number(montantemprunte)) * 100
		//   }
		// },
		fgarantiecal() {
			if (this.fgarantie.fgarantieEuro === false) {
				this.fgarantie.fgarantieEuro = true;
			} else {
				this.fgarantie.fgarantieEuro = false;
			}

			const montantemprunte = this.montantemprunte.isAuto
				? this.montantemprunte.valAuto
				: this.montantemprunte.val;
			if (this.fgarantie.fgarantieEuro === true) {
				this.fgarantie.val =
					(Number(this.fgarantie.per) / 100) *
					Number(montantemprunte);
				// this.fgarantie.val = (Number(this.fgarantie.per) / 100) * Number(this.coutacquisition)
			} else if (this.fgarantie.fgarantieEuro === false) {
				this.fgarantie.per =
					(Number(this.fgarantie.val) / Number(montantemprunte)) *
					100;
				// this.fgarantie.per = (Number(this.fgarantie.val) / Number(this.coutacquisition)) * 100
			}
		},
		isFormSelected(event) {
			// La value 1 correspond à la valeur de l'option correspondant au champ SCI
			if (event.target.value === "1") {
				this.isNue = false;
				this.isSCI = true;
				// La value 2 correspond à la valeur de l'option correspondant au champ Location Nue
			} else if (event.target.value === "0") {
				this.isSCI = false;
				this.isNue = true;
			} else {
				this.isSCI = false;
				this.isNue = false;
			}
		},
		setFormeJurSelected(event) {
			this.isFormSelected(event);
			this.formejuridique.map((val) => (val.selected = false));
			this.formejuridique[event.target.value].selected = true;
			// if (
			// 	['lmnpbic', 'lmnpr', 'sasis', 'sasuis', 'sciis', 'sciirmf', 'sciirr'].includes(
			// 		this.formejuridique[event.target.value].code
			// 	)
			// ) {
			// 	this.trvametot += 1500;
			// } else {
			// 	if (this.trvametot !== 0) {
			// 		this.trvametot -= 1500;
			// 	}
			// }
			if (
				["sciis", "sciirr", "sciirmf", "sasis"].includes(
					this.formejuridique[event.target.value].code
				)
			) {
				if (this.type_fisc.length === 1) {
					this.type_fisc.push({
						// id: 1,
						idInvest: "",
						nameInvest: "",
						capital: 50,
						apport: 0,
						tmi: 30,
					});
				}
			} else {
				this.type_fisc.splice(1);
			}
		},

		//* ********************************
		// RESULTAT PARTIE
		//* ********************************
		timeline() {
			// Erreur : J'avais bouclé sur la durée de l'emprunt alors que c'est la durée de détention
			// let dureeempr = this.dureeempr.isAuto === true ? Number(this.dureeempr.valAuto / 12) : Number(this.dureeempr.val)
			const dureeempr =
				this.dureedetention.isAuto === true
					? Number(this.dureedetention.valAuto / 12)
					: Number(this.dureedetention.val);
			const daterev = this.daterevente;
			const dureeemprunt =
				this.dureeempr.isAuto === true
					? Number(this.dureeempr.valAuto / 12)
					: Number(this.dureeempr.val);

			const dateacquiSplit = this.dateacqui.split("-");

			const daterevSplit = daterev.split("-");
			// RES : PERIODES
			const period = [];
			const totalperiod = [];

			// RES : TIMELINE VALUES
			const resDateAcqui = [];
			const resTmpLoc = [];
			const resDateRevente = [];
			const resTimelineMvt = [];
			const resTimelineEmpr = [];
			const resTimelineChoixLMNPNU = [];
			const resTimelineChoixSCI = [];
			const resTimelineChoixSCIIR = [];

			let isFormJuridiqueSCI = false;
			let isFormJuridiqueSCIIR = false;
			let isFormJuridiqueLMNPNU = false;

			this.formejuridique.map((val) => {
				if (
					val.selected === true &&
					(val.code === "sasis" ||
						val.code === "sasuis" ||
						val.code === "sciis")
				) {
					isFormJuridiqueSCI = true;
				}
				if (
					val.selected === true &&
					(val.code === "sciirmf" || val.code === "sciirr")
				) {
					isFormJuridiqueSCIIR = true;
				}
				if (
					val.selected === true &&
					(val.code === "lnmf" ||
						val.code === "lnr" ||
						val.code === "lmnpbic" ||
						val.code === "lmnpr")
				) {
					isFormJuridiqueLMNPNU = true;
				}
			});

			for (let i = 0; i <= dureeempr; i++) {
				period.push(Number(dateacquiSplit[0]) + i);
				if (i === 0) {
					totalperiod.push(
						`${Number(dateacquiSplit[0]) + i}-${
							dateacquiSplit[1]
						}-01`
					);
				} else {
					totalperiod.push(
						`${Number(dateacquiSplit[0]) + i}-${
							dateacquiSplit[1]
						}-31`
					);
				}
				let calc = 0;
				if (Number(period[i]) < Number(daterevSplit[0])) {
					resTmpLoc.push(1);
				} else {
					resTmpLoc.push(0);
				}

				if (Number(period[i]) === Number(dateacquiSplit[0])) {
					resDateAcqui.push(1);
				} else {
					resDateAcqui.push(0);
				}

				if (Number(period[i]) === Number(daterevSplit[0])) {
					resDateRevente.push(1);
				} else {
					resDateRevente.push(0);
				}

				if (i <= Number(dureeemprunt) - 1 && resTmpLoc[i] === 1) {
					resTimelineEmpr.push(1);
				} else {
					resTimelineEmpr.push(0);
				}

				isFormJuridiqueLMNPNU === true
					? resTimelineChoixLMNPNU.push(1)
					: resTimelineChoixLMNPNU.push(0);
				isFormJuridiqueSCI === true
					? resTimelineChoixSCI.push(1)
					: resTimelineChoixSCI.push(0);
				isFormJuridiqueSCIIR === true
					? resTimelineChoixSCIIR.push(1)
					: resTimelineChoixSCIIR.push(0);

				calc = resDateAcqui[i] + resTmpLoc[i] + resDateRevente[i];
				resTimelineMvt.push(calc);
			}

			this.timelineFonc.resDateAcqui = resDateAcqui;
			this.timelineFonc.resTmpLoc = resTmpLoc;
			this.timelineFonc.resDateRevente = resDateRevente;
			this.timelineFonc.resTimelineEmpr = resTimelineEmpr;
			this.timelineFonc.resTimelineMvt = resTimelineMvt;
			this.timelineFonc.resTimelineChoixLMNPNU = resTimelineChoixLMNPNU;
			this.timelineFonc.resTimelineChoixSCI = resTimelineChoixSCI;
			this.timelineFonc.resTimelineChoixSCIIR = resTimelineChoixSCIIR;
			this.timelineFonc.period = period;
			this.timelineFonc.totalperiod = totalperiod;

			return this.resCoutTotal(
				resDateAcqui,
				resTmpLoc,
				resDateRevente,
				resTimelineMvt,
				resTimelineEmpr,
				dureeempr,
				period,
				resTimelineChoixLMNPNU,
				resTimelineChoixSCI,
				resTimelineChoixSCIIR
			);
		},
		resCoutTotal(
			resDateAcqui,
			resTmpLoc,
			resDateRevente,
			resTimelineMvt,
			resTimelineEmpr,
			dureeempr,
			period,
			resTimelineChoixLMNPNU,
			resTimelineChoixSCI,
			resTimelineChoixSCIIR
		) {
			const dateacqui = this.investSelected.date_acqui;

			// RES : COUT TOTAL ACQUISITION
			const resPrixAchat = [];
			const resPrixRevente = [];
			const resFraisNotaire = [];
			const resFraisAgenceImmo = [];
			const resCoutTotalAcqui = [];
			let resCoutTotalProfit = 0;

			for (let i = 0; i <= dureeempr; i++) {
				resPrixAchat.push(-resDateAcqui[i] * this.buyprice);
				resPrixRevente.push(resDateRevente[i] * this.estimprixrevente);
				if (i === 0) {
					if (this.fnotaire.isAuto === true) {
						resFraisNotaire.push(
							this.fnotaire.fnotairePercentage.val *
								resPrixAchat[i]
						);
					} else {
						resFraisNotaire.push(this.fnotaire.val);
					}

					if (this.fagence.isAuto === true) {
						resFraisAgenceImmo.push(
							-this.fagence.valAuto * resDateAcqui[i]
						);
					} else {
						resFraisAgenceImmo.push(
							-this.fagence.val * resDateAcqui[i]
						);
					}
				} else if (i === dureeempr) {
					resFraisNotaire.push(0);
					resFraisAgenceImmo.push(
						-(this.fagence.per / 100) * resDateAcqui[i]
					);
				} else {
					resFraisNotaire.push(0);
					resFraisAgenceImmo.push(
						-(this.fagence.per / 100) * resDateAcqui[i]
					);
				}
				resCoutTotalAcqui.push(
					resPrixAchat[i] +
						resPrixRevente[i] +
						-resFraisNotaire[i] +
						resFraisAgenceImmo[i]
				);
			}

			const reducer = (accumulator, currentValue) =>
				accumulator + currentValue;

			resCoutTotalProfit = resCoutTotalAcqui.reduce(reducer);

			this.resCoutTotalAcqui = resCoutTotalAcqui;

			this.cagnotte_base.resFraisNotaire = resFraisNotaire;
			this.cagnotte_base.resFraisAgenceImmo = resFraisAgenceImmo;
			this.calcul_global.resPrixAchat = resPrixAchat;
			this.calcul_global.resPrixRevente = resPrixRevente;

			this.resCoutTotalFonc.resPrixAchat = resPrixAchat;
			this.resCoutTotalFonc.resPrixRevente = resPrixRevente;
			this.resCoutTotalFonc.resFraisNotaire = resFraisNotaire;
			this.resCoutTotalFonc.resFraisAgenceImmo = resFraisAgenceImmo;
			this.resCoutTotalFonc.resCoutTotalAcqui = resCoutTotalAcqui;
			this.resCoutTotalFonc.resCoutTotalProfit = resCoutTotalProfit;

			return this.resOperationNet(
				dureeempr,
				resTmpLoc,
				resDateAcqui,
				resTimelineEmpr,
				resDateRevente,
				period,
				resCoutTotalAcqui,
				resTimelineChoixLMNPNU,
				resTimelineChoixSCI,
				resTimelineChoixSCIIR,
				resPrixRevente
			);
		},

		resOperationNet(
			dureeempr,
			resTmpLoc,
			resDateAcqui,
			resTimelineEmpr,
			resDateRevente,
			period,
			resCoutTotalAcqui,
			resTimelineChoixLMNPNU,
			resTimelineChoixSCI,
			resTimelineChoixSCIIR,
			resPrixRevente
		) {
			const resLoyerEnt = [];
			const resPertes = [];
			const resRevLoc = [];
			const resFraisCopro = [];
			const resTaxeFonc = [];
			const resFraisGestLoc = [];
			const resGLI = [];
			const resAutreCharge = [];
			const resAssPNO = [];
			const resProvPTE = [];

			const resFraisExploit = [];
			let resTotFraisExploit = [];

			const resStructureSCI = [];
			const resFraisComCGA = [];
			const resCFE = [];
			let resTotStructureSCI = [];
			let resTotFraisComCGA = [];
			let resTotCFE = [];

			const resOpeNet = [];
			let resTotOpeNet = [];

			const resTotLoyerEnt = [];
			const resTotPertes = [];

			let resTotRevLoc = [];
			let resTotFraisCopro = [];
			let resTotTaxeFonc = [];
			let resTotFraisGestLoc = [];
			let resTotGLI = [];
			let resTotAutreCharge = [];
			let resTotAssPNO = [];
			let resTotProvPTE = [];

			let isFormJuridiqueStructure = false;
			let isFormJuridiqueCFE = false;
			let isFormJuridiqueSociete = false;

			this.formejuridique.map((val) => {
				if (
					val.selected === true &&
					(val.code === "lmnpr" ||
						val.code === "sasis" ||
						val.code === "sasuis" ||
						val.code === "sciis")
				) {
					isFormJuridiqueStructure = true;
				}
			});

			this.formejuridique.map((val) => {
				if (
					val.selected === true &&
					(val.code === "lmnpr" ||
						val.code === "sasis" ||
						val.code === "sasuis" ||
						val.code === "sciis" ||
						val.code === "lmnpbic")
				) {
					isFormJuridiqueCFE = true;
				}
			});

			this.formejuridique.map((val) => {
				if (
					val.selected === true &&
					(val.code === "sasis" ||
						val.code === "sasuis" ||
						val.code === "sciis" ||
						val.code === "sciirmf" ||
						val.code === "sciirr")
				) {
					isFormJuridiqueSociete = true;
				}
			});

			for (let i = 0; i <= dureeempr; i++) {
				// if (i === 0) {
				// 	//PRIO CALCUL !!!
				// 	resLoyerEnt.push(
				// 		Math.round(
				// 			resDateAcqui[i] * Number(this.revenulocatif) +
				// 				resTmpLoc[i] * (1 + Number(this.trevalorisation.per) / 100)
				// 		)
				// 	);
				// } else {
				// 	resLoyerEnt.push(
				// 		Math.round(
				// 			resDateAcqui[i] * Number(this.revenulocatif) +
				// 				resTmpLoc[i] *
				// 					(1 + Number(this.trevalorisation.per) / 100) *
				// 					resLoyerEnt[i - 1]
				// 		)
				// 	);
				// }

				// if(this.rvacanceloc.isAuto === true) {
				// 	resPertes.push(Math.round(- resLoyerEnt[i] * this.rvacanceloc.rvacancelocPercentage.val))
				// } else {
				// 	resPertes.push(Math.round(- resLoyerEnt[i] * (Number(this.rvacanceloc.per) / 100)))
				// }

				// resPertes.push(
				// 	Math.round(-resLoyerEnt[i] * Number((this.rvacanceloc.val / 365).toFixed(4)))
				// );

				if (this.hgestionloc.isAuto === true) {
					resFraisGestLoc.push(
						Math.round(
							-resLoyerEnt[i] *
								this.hgestionloc.hgestionlocPercentage.val
						)
					);
				} else {
					resFraisGestLoc.push(
						Math.round(
							-resLoyerEnt[i] * (this.hgestionloc.per / 100)
						)
					);
				}
				// Autres Charges
				if (this.chargediverse.isAuto === true) {
					resAutreCharge.push(
						Math.round(
							-resTmpLoc[i] *
								this.chargediverse.chargediversePercentage.val
						)
					);
				} else {
					resAutreCharge.push(
						Math.round(-resTmpLoc[i] * this.chargediverse.val)
					);
				}

				// FRAIS COPRO
				if (this.chargecopro.isAuto === true) {
					i === 0
						? resFraisCopro.push(
								Math.round(
									-resDateAcqui[i] *
										this.chargecopro.valAuto +
										0 *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  )
						: resFraisCopro.push(
								Math.round(
									-resDateAcqui[i] *
										this.chargecopro.valAuto +
										resFraisCopro[i - 1] *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  );
				} else {
					i === 0
						? resFraisCopro.push(
								Math.round(
									-resDateAcqui[i] * this.chargecopro.val +
										0 *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  )
						: resFraisCopro.push(
								Math.round(
									-resDateAcqui[i] * this.chargecopro.val +
										resFraisCopro[i - 1] *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  );
				}

				// TAXE FONCIERE
				if (this.tfonciere.isAuto === true) {
					i === 0
						? resTaxeFonc.push(
								Math.round(
									-resDateAcqui[i] * this.tfonciere.valAuto +
										0 *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  )
						: resTaxeFonc.push(
								Math.round(
									-resDateAcqui[i] * this.tfonciere.valAuto +
										resTaxeFonc[i - 1] *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  );
				} else {
					i === 0
						? resTaxeFonc.push(
								Math.round(
									-resDateAcqui[i] * this.tfonciere.val +
										0 *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  )
						: resTaxeFonc.push(
								Math.round(
									-resDateAcqui[i] * this.tfonciere.val +
										resTaxeFonc[i - 1] *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  );
				}

				// GLI
				if (this.gli.isAuto === true) {
					i === 0
						? resGLI.push(
								Math.round(
									-resDateAcqui[i] * this.gli.valAuto +
										0 *
											(1 +
												this.trevalorisationOPEX.val /
													100) *
											resTmpLoc[i]
								)
						  )
						: resGLI.push(
								Math.round(
									-resDateAcqui[i] * this.gli.valAuto +
										resGLI[i - 1] *
											(1 +
												this.trevalorisationOPEX.val /
													100) *
											resTmpLoc[i]
								)
						  );
				} else {
					i === 0
						? resGLI.push(
								Math.round(
									-resDateAcqui[i] * this.gli.val +
										0 *
											(1 +
												this.trevalorisationOPEX.val /
													100) *
											resTmpLoc[i]
								)
						  )
						: resGLI.push(
								Math.round(
									-resDateAcqui[i] * this.gli.val +
										resGLI[i - 1] *
											(1 +
												this.trevalorisationOPEX.val /
													100) *
											resTmpLoc[i]
								)
						  );
				}

				// ASSURANCE PNO
				if (this.apno.isAuto === true) {
					i === 0
						? resAssPNO.push(
								Math.round(
									-resDateAcqui[i] * this.apno.valAuto +
										0 *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  )
						: resAssPNO.push(
								Math.round(
									-resDateAcqui[i] * this.apno.valAuto +
										resAssPNO[i - 1] *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  );
				} else {
					i === 0
						? resAssPNO.push(
								Math.round(
									-resDateAcqui[i] * this.apno.val +
										0 *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  )
						: resAssPNO.push(
								Math.round(
									-resDateAcqui[i] * this.apno.val +
										resAssPNO[i - 1] *
											(1 +
												this.trevalorisation.per /
													100) *
											resTmpLoc[i]
								)
						  );
				}

				// Provision petits travaux d'entretien
				if (this.provision.isAuto === true) {
					i === 0
						? resProvPTE.push(
								-resDateAcqui[i] * this.provision.valAuto +
									0 *
										(1 +
											Number(
												this.trevalorisationOPEX.val
											) /
												100) *
										resTmpLoc[i]
						  )
						: resProvPTE.push(
								-resDateAcqui[i] * this.provision.valAuto +
									resProvPTE[i - 1] *
										(1 +
											Number(
												this.trevalorisationOPEX.val
											) /
												100) *
										resTmpLoc[i]
						  );
				} else {
					i === 0
						? resProvPTE.push(
								-resDateAcqui[i] * this.provision.val +
									0 *
										(1 +
											Number(
												this.trevalorisationOPEX.val
											) /
												100) *
										resTmpLoc[i]
						  )
						: resProvPTE.push(
								-resDateAcqui[i] * this.provision.val +
									resProvPTE[i - 1] *
										(1 +
											Number(
												this.trevalorisationOPEX.val
											) /
												100) *
										resTmpLoc[i]
						  );
				}

				resFraisExploit.push(
					Math.round(
						resFraisCopro[i] +
							resTaxeFonc[i] +
							resFraisGestLoc[i] +
							resGLI[i] +
							resAssPNO[i] +
							resProvPTE[i]
					)
				);

				if (this.fcomptable.isAuto === true) {
					isFormJuridiqueStructure === true
						? resFraisComCGA.push(
								Math.round(
									resTmpLoc[i] * -this.fcomptable.valAuto
								)
						  )
						: resFraisComCGA.push(-0);
				} else {
					isFormJuridiqueStructure === true
						? resFraisComCGA.push(
								Math.round(resTmpLoc[i] * -this.fcomptable.val)
						  )
						: resFraisComCGA.push(-0);
				}
				let frais_societe = 0;
				// Addition du nouveau champs frais société au premier index du tableau frais comptable
				if (i === 0) {
					isFormJuridiqueSociete === true
						? (frais_societe = Number(this.fsociete))
						: (frais_societe = 0);
				}
				if (this.fcotisation.isAuto === true) {
					isFormJuridiqueCFE === true
						? resCFE.push(
								Math.round(
									resTmpLoc[i] * -this.fcotisation.valAuto
								)
						  )
						: resCFE.push(-0);
				} else {
					isFormJuridiqueCFE === true
						? resCFE.push(
								Math.round(resTmpLoc[i] * -this.fcotisation.val)
						  )
						: resCFE.push(-0);
				}
				isFormJuridiqueStructure === true
					? resStructureSCI.push(
							Math.round(
								resFraisComCGA[i] + resCFE[i] + frais_societe
							)
					  )
					: resStructureSCI.push(-0);

				if (!isFormJuridiqueStructure && isFormJuridiqueSociete) {
					resStructureSCI[i] = resStructureSCI[i] - frais_societe;
				}

				resRevLoc.push(Math.round(resLoyerEnt[i] + resPertes[i]));

				resOpeNet.push(
					Math.round(
						resRevLoc[i] + resFraisExploit[i] + resStructureSCI[i]
					)
				);

				// Push pour pouvoir mapper par la suite
				this.resComptSoumisImpot.push(0);
			}

			this.resComptSoumisImpot.map((val, index) => {
				this.resComptSoumisImpot[index] =
					-resFraisExploit[index] + -resStructureSCI[index];
			});

			const reducer = (accumulator, currentValue) =>
				accumulator + currentValue;

			// resTotLoyerEnt = resLoyerEnt.reduce(reducer);
			// resTotPertes = resPertes.reduce(reducer);
			resTotRevLoc = resRevLoc.reduce(reducer);
			resTotFraisGestLoc = resFraisGestLoc.reduce(reducer);
			resTotGLI = resGLI.reduce(reducer);
			resTotAutreCharge = resAutreCharge.reduce(reducer);
			resTotFraisCopro = resFraisCopro.reduce(reducer);
			resTotTaxeFonc = resTaxeFonc.reduce(reducer);
			resTotAssPNO = resAssPNO.reduce(reducer);
			resTotProvPTE = resProvPTE.reduce(reducer);
			resTotFraisExploit = Math.round(
				resTotFraisCopro +
					resTotTaxeFonc +
					resTotFraisGestLoc +
					resTotGLI +
					resTotAutreCharge +
					resTotAssPNO +
					resTotProvPTE
			);

			resTotStructureSCI = resStructureSCI.reduce(reducer);
			resTotFraisComCGA = resFraisComCGA.reduce(reducer);
			resTotCFE = resCFE.reduce(reducer);

			resTotOpeNet = resOpeNet.reduce(reducer);

			this.cagnotte_base.resRevLoc = resRevLoc;
			this.cagnotte_base.resFraisExploit = resFraisExploit;
			this.cagnotte_base.resStructureSCI = resStructureSCI;

			this.resOperationNetFonc.resLoyerEnt = resLoyerEnt;
			this.resOperationNetFonc.resPertes = resPertes;
			this.resOperationNetFonc.resRevLoc = resRevLoc;
			this.resOperationNetFonc.resFraisExploit = resFraisExploit;
			this.resOperationNetFonc.resFraisCopro = resFraisCopro;
			this.resOperationNetFonc.resTaxeFonc = resTaxeFonc;
			this.resOperationNetFonc.resFraisGestLoc = resFraisGestLoc;
			this.resOperationNetFonc.resGLI = resGLI;
			this.resOperationNetFonc.resAutreCharge = resAutreCharge;
			this.resOperationNetFonc.resAssPNO = resAssPNO;
			this.resOperationNetFonc.resProvPTE = resProvPTE;
			this.resOperationNetFonc.resStructureSCI = resStructureSCI;
			this.resOperationNetFonc.resFraisComCGA = resFraisComCGA;
			this.resOperationNetFonc.resCFE = resCFE;
			this.resOperationNetFonc.resOpeNet = resOpeNet;

			this.resOperationNetFonc.resTotLoyerEnt = resTotLoyerEnt;
			this.resOperationNetFonc.resTotPertes = resTotPertes;
			this.resOperationNetFonc.resTotRevLoc = resTotRevLoc;
			this.resOperationNetFonc.resTotFraisExploit = resTotFraisExploit;
			this.resOperationNetFonc.resTotFraisCopro = resTotFraisCopro;
			this.resOperationNetFonc.resTotTaxeFonc = resTotTaxeFonc;
			this.resOperationNetFonc.resTotFraisGestLoc = resTotFraisGestLoc;
			this.resOperationNetFonc.resTotGLI = resTotGLI;
			this.resOperationNetFonc.resTotAutreCharge = resTotAutreCharge;
			this.resOperationNetFonc.resTotAssPNO = resTotAssPNO;
			this.resOperationNetFonc.resTotProvPTE = resTotProvPTE;
			this.resOperationNetFonc.resTotStructureSCI = resTotStructureSCI;
			this.resOperationNetFonc.resTotFraisComCGA = resTotFraisComCGA;
			this.resOperationNetFonc.resTotCFE = resTotCFE;
			this.resOperationNetFonc.resTotOpeNet = resTotOpeNet;
			// return
			return this.resInvTrav(
				dureeempr,
				resTmpLoc,
				period,
				resDateAcqui,
				resTimelineEmpr,
				resDateRevente,
				resCoutTotalAcqui,
				resOpeNet,
				resTimelineChoixLMNPNU,
				resTimelineChoixSCI,
				resTimelineChoixSCIIR,
				resRevLoc,
				resPrixRevente
			);
		},

		resInvTrav(
			dureeempr,
			resTmpLoc,
			period,
			resDateAcqui,
			resTimelineEmpr,
			resDateRevente,
			resCoutTotalAcqui,
			resOpeNet,
			resTimelineChoixLMNPNU,
			resTimelineChoixSCI,
			resTimelineChoixSCIIR,
			resRevLoc,
			resPrixRevente
		) {
			const resInvTrav = [];
			const resFluxAvtDette = [];
			const resBalanceBanc = [];
			const resEmprunt = [];
			const resChargFin = [];

			const resFraisBanc = [];
			const resItr = [];
			const resRmbCap = [];
			const resAssEmpr = [];
			const resRmbDette = [];
			const resIndemRmbDette = [];

			const resCapitalRest = [];

			const resFluxAvtImpot = [];

			let resTotalInvTrav = [];
			let resTotFluxAvtDette = [];
			let resTotBalanceBanc = [];
			let resTotEmprunt = [];
			let resTotChargFin = [];

			let resTotFraisBanc = [];
			let resTotItr = [];
			let resTotRmbCap = [];
			let resTotAssEmpr = [];
			let resTotRmbDette = [];
			let resTotIndemRmbDette = [];

			let resTotCapitalRest = [];

			let resTotFluxAvtImpot = [];

			// let datetrvSplit = this.datetrv.split('-')
			let isFormeJuridiqueNu = false;
			this.formejuridique.map((val) => {
				if (
					val.selected === true &&
					(val.code === "lnr" || val.code === "lnmf")
				) {
					isFormeJuridiqueNu = true;
				}
			});

			for (let i = 0; i <= dureeempr; i++) {
				// if(Number(datetrvSplit[0]) === period[i]) {
				// } else {
				// 	resInvTrav.push(0)
				// }
				resInvTrav.push(
					isFormeJuridiqueNu === true
						? -(
								+this.travauxConstru[`year_${i}`] +
								+this.travauxReno[`year_${i}`]
						  )
						: -(
								+this.travauxConstru[`year_${i}`] +
								+this.travauxReno[`year_${i}`]
						  ) // - this.fameublement
				);

				resFluxAvtDette.push(
					resCoutTotalAcqui[i] + resOpeNet[i] + resInvTrav[i]
				);

				if (this.montantemprunte.isAuto === true) {
					resEmprunt.push(
						resDateAcqui[i] * this.montantemprunte.valAuto
					);

					if (this.tassuranceempr.isAuto === true) {
						resAssEmpr.push(
							Math.round(
								-resTimelineEmpr[i] *
									this.tassuranceempr.tassuranceemprPercentage
										.val *
									this.montantemprunte.valAuto
							)
						);
					} else {
						resAssEmpr.push(
							Math.round(
								-resTimelineEmpr[i] *
									this.tassuranceempr.val *
									this.montantemprunte.valAuto
							)
						);
					}
				} else {
					resEmprunt.push(resDateAcqui[i] * this.montantemprunte.val);

					if (this.tassuranceempr.isAuto === true) {
						resAssEmpr.push(
							Math.round(
								-resTimelineEmpr[i] *
									this.tassuranceempr.tassuranceemprPercentage
										.val *
									this.montantemprunte.val
							)
						);
					} else {
						resAssEmpr.push(
							Math.round(
								-resTimelineEmpr[i] *
									this.tassuranceempr.val *
									this.montantemprunte.val
							)
						);
					}
				}

				if (i === 0) {
					resBalanceBanc.push(0);
				} else {
					resBalanceBanc.push(resCapitalRest[i - 1]);
				}

				const fraisgarantie =
					this.fgarantie.isAuto === true
						? this.fgarantie.valAuto
						: this.fgarantie.val;
				const fraisbancaire =
					this.fbancaire.isAuto === true
						? this.fbancaire.valAuto
						: this.fbancaire.val;
				if (this.modefinancement === "fond_propre") {
					resFraisBanc.push(0);
				} else {
					resFraisBanc.push(
						-resDateAcqui[i] *
							(Number(fraisgarantie) + Number(fraisbancaire))
					);
				}

				// mAINTENANT ON CALCUL DANS LE CALCUL DU TABLEAU
				if (this.tinteretempr.isAuto === true) {
					resItr.push(
						Math.round(
							-resTimelineEmpr[i] *
								this.tinteretempr.tinteretemprPercentage.val *
								(resBalanceBanc[i] + resEmprunt[i])
						)
					);
				} else {
					resItr.push(
						Math.round(
							-resTimelineEmpr[i] *
								Number(this.tinteretempr.val) *
								(resBalanceBanc[i] + resEmprunt[i])
						)
					);
				}

				resRmbCap.push(
					-Math.round(
						-resTimelineEmpr[i] * (this.annuite.val + resItr[i])
					)
				);

				resRmbDette.push(
					Math.round(resDateRevente[i] * -resBalanceBanc[i])
				);

				const tauxinteret =
					this.tinteretempr.isAuto === true
						? this.tinteretempr.tinteretemprPercentage.val
						: this.tinteretempr.val;
				// TO-DO
				const indemnite =
					this.ira.val === null
						? (resBalanceBanc[i] * 6 * tauxinteret) / 12
						: this.ira.val;

				resIndemRmbDette.push(
					Math.round(-resDateRevente[i] * indemnite)
				);

				resCapitalRest.push(
					Math.round(resBalanceBanc[i]) +
						Math.round(resEmprunt[i]) +
						Math.round(resRmbCap[i])
				);

				resChargFin.push(
					Math.round(resFraisBanc[i]) +
						Math.round(resItr[i]) +
						Math.round(resRmbCap[i]) +
						Math.round(resAssEmpr[i])
				);

				resFluxAvtImpot.push(
					Math.round(resFluxAvtDette[i]) +
						Math.round(resEmprunt[i]) +
						Math.round(resChargFin[i]) +
						Math.round(resRmbDette[i]) +
						resIndemRmbDette[i]
				);
			}

			// this.resComptSoumisImpot.push((- resInvTrav[i]) + (- resItr[i]) + (- resFraisBanc[i]) + (- resAssEmpr[i]) + Number(this.resComptSoumisImpot[i]))

			this.resComptSoumisImpot.map((val, index) => {
				this.resComptSoumisImpot[index] =
					-resInvTrav[index] +
					-resItr[index] +
					-resFraisBanc[index] +
					-resAssEmpr[index] +
					val;
			});

			const reducer = (accumulator, currentValue) =>
				accumulator + currentValue;

			let resTotReno = 0;
			Object.keys(this.travauxReno).forEach((d) => {
				resTotReno += +this.travauxReno[d];
			});
			let resTotConstru = 0;
			Object.keys(this.travauxReno).forEach((d) => {
				resTotConstru += +this.travauxConstru[d];
			});
			const resTotalTravauxRenovation = resTotReno;
			const resTotalTravauxConstruction = resTotConstru;
			resTotalInvTrav = resInvTrav.reduce(reducer);
			resTotFluxAvtDette = resFluxAvtDette.reduce(reducer);
			resTotEmprunt = resEmprunt.reduce(reducer);
			resTotFraisBanc = resFraisBanc.reduce(reducer);
			resTotRmbCap = resRmbCap.reduce(reducer);
			resTotBalanceBanc = resBalanceBanc.reduce(reducer);
			resTotItr = resItr.reduce(reducer);
			resTotAssEmpr = resAssEmpr.reduce(reducer);
			resTotRmbDette = resRmbDette.reduce(reducer);
			resTotIndemRmbDette = resIndemRmbDette.reduce(reducer);
			resTotCapitalRest = resCapitalRest.reduce(reducer);
			resTotChargFin = resChargFin.reduce(reducer);
			resTotFluxAvtImpot = resFluxAvtImpot.reduce(reducer);

			this.cagnotte_base.resInvTrav = resInvTrav;
			this.cagnotte_base.resItr = resItr;
			this.cagnotte_base.resFraisBanc = resFraisBanc;
			this.cagnotte_base.resAssEmpr = resAssEmpr;

			this.resInvTravFonc.resInvTrav = resInvTrav;
			this.resInvTravFonc.resFluxAvtDette = resFluxAvtDette;
			this.resInvTravFonc.resBalanceBanc = resBalanceBanc;
			this.resInvTravFonc.resEmprunt = resEmprunt;
			this.resInvTravFonc.resChargFin = resChargFin;
			this.resInvTravFonc.resFraisBanc = resFraisBanc;
			this.resInvTravFonc.resItr = resItr;
			this.resInvTravFonc.resRmbCap = resRmbCap;
			this.resInvTravFonc.resAssEmpr = resAssEmpr;
			this.resInvTravFonc.resRmbDette = resRmbDette;
			this.resInvTravFonc.resIndemRmbDette = resIndemRmbDette;
			this.resInvTravFonc.resCapitalRest = resCapitalRest;
			this.resInvTravFonc.resFluxAvtImpot = resFluxAvtImpot;

			this.resInvTravFonc.resTotalTravauxRenovation =
				-resTotalTravauxRenovation;
			this.resInvTravFonc.resTotalTravauxConstruction =
				-resTotalTravauxConstruction;
			this.resInvTravFonc.resTotalInvTrav = resTotalInvTrav;
			this.resInvTravFonc.resTotFluxAvtDette = resTotFluxAvtDette;
			this.resInvTravFonc.resTotBalanceBanc = resTotBalanceBanc;
			this.resInvTravFonc.resTotEmprunt = resTotEmprunt;
			this.resInvTravFonc.resTotChargFin = resTotChargFin;
			this.resInvTravFonc.resTotFraisBanc = resTotFraisBanc;
			this.resInvTravFonc.resTotItr = resTotItr;
			this.resInvTravFonc.resTotRmbCap = resTotRmbCap;
			this.resInvTravFonc.resTotAssEmpr = resTotAssEmpr;
			this.resInvTravFonc.resTotRmbDette = resTotRmbDette;
			this.resInvTravFonc.resTotIndemRmbDette = resTotIndemRmbDette;
			this.resInvTravFonc.resTotCapitalRest = resTotCapitalRest;
			this.resInvTravFonc.resTotFluxAvtImpot = resTotFluxAvtImpot;

			return this.resFluxAI(
				dureeempr,
				resTmpLoc,
				period,
				resDateAcqui,
				resTimelineEmpr,
				resDateRevente,
				resCoutTotalAcqui,
				resOpeNet,
				resTimelineChoixLMNPNU,
				resTimelineChoixSCI,
				resTimelineChoixSCIIR,
				resRevLoc,
				resFluxAvtImpot,
				resTotFluxAvtImpot,
				resPrixRevente
			);
		},

		resFluxAI(
			dureeempr,
			resTmpLoc,
			period,
			resDateAcqui,
			resTimelineEmpr,
			resDateRevente,
			resCoutTotalAcqui,
			resOpeNet,
			resTimelineChoixLMNPNU,
			resTimelineChoixSCI,
			resTimelineChoixSCIIR,
			resRevLoc,
			resFluxAvtImpot,
			resTotFluxAvtImpot,
			resPrixRevente
		) {
			const resTimelineImpPositif = [];

			const timelineNU = [];
			const timelineSCI = [];
			const timelineLMNP = [];

			const timelineNUSCIreel = [];
			const timelineSCISASU = [];
			const timelineLMNPreel = [];
			const timelineNUSCImf = [];
			const timelineLMNPbic = [];

			const resAmorActif = [];
			const resBaseImposable = [];
			const resBaseImposableNU = [];
			const resBaseImposableSCILMNP = [];
			const resRetrCond = [];

			const resValTaxableSCI = [];

			const resImposition = [];
			const resImpTMISeul = [];
			const resImpSoc = [];
			const resRateIS = [];
			const resPrelSoc = [];
			const resCSG = [];
			const resPV = [];

			const resImpTMIAss = {
				associateFirst: [],
				associateSecond: [],
				associateThird: [],
				associateFourth: [],
			};

			const resPrelSocAss = {
				associateFirst: [],
				associateSecond: [],
				associateThird: [],
				associateFourth: [],
			};

			const resFluxAprImpot = [];
			const resCashFlowNonActu = [];
			const resVersDiv = [];
			const resCashFlowActu = [];
			const resCashFlowCumul = [];
			const resSommeInvest = [];

			let resTotAmorActif = [];
			let resTotBaseImposable = [];
			let resTotValTaxableSCI = [];

			let resTotImposition = [];
			let resTotImpTMISeul = [];
			let resTotImpSoc = [];
			let resTotPrelSoc = [];
			let resTotCSG = [];
			let resTotPV = [];

			const resTotImpTMIAss = {
				associateFirst: [],
				associateSecond: [],
				associateThird: [],
				associateFourth: [],
			};

			const resTotPrelSocAss = {
				associateFirst: [],
				associateSecond: [],
				associateThird: [],
				associateFourth: [],
			};

			let resTotFluxAprImpot = [];
			let resTotCashFlowNonActu = [];
			let resTotVersDiv = [];
			let resTotCashFlowActu = [];
			let resTotCashFlowCumul = [];
			let resTotSommeInvest = [];

			const resGrilleAmo = [];

			/**
			 * CADRAN
			 *
			 *  PERFORMANCE
			 *  -----
			 *  RENDEMENT TOTAL
			 */
			const performance = {
				sansDette: {},
				avecDette: {},
				avecFisc: {},
			};
			const rendementTotal = {
				total: {},
				plusval: {},
				revloc: {},
			};
			const repartitionBenef = {
				associateFirst: {},
				associateSecond: {},
				associateThird: {},
				associateFourth: {},
			};

			/********************************************************************************************************************************************************************************************************
			 *
			 * TABLEAU AMMORTISSEMENT DU BIEN
			 *
			 ********************************************************************************************************************************************************************************************************/

			const grilleAmo = [
				{
					duree: +this.amortissementsparcomposant.terrain.duree,
					valAmo:
						(this.buyprice + this.trconstruction) *
						(+this.amortissementsparcomposant.terrain.pourcentage /
							100),
					amoPa: 0,
				}, // terrain
				{
					duree: +this.amortissementsparcomposant.gros_oeuvre.duree,
					valAmo:
						(this.buyprice + this.trconstruction) *
						(+this.amortissementsparcomposant.gros_oeuvre
							.pourcentage /
							100),
					amoPa: 0,
				}, // gros oeuvre
				{
					duree: +this.amortissementsparcomposant.facade_etancheite
						.duree,
					valAmo:
						(this.buyprice + this.trconstruction) *
						(+this.amortissementsparcomposant.facade_etancheite
							.pourcentage /
							100),
					amoPa: 0,
				}, // facade
				{
					duree: +this.amortissementsparcomposant
						.installation_generales_techniques.duree,
					valAmo:
						(this.buyprice + this.trconstruction) *
						(+this.amortissementsparcomposant
							.installation_generales_techniques.pourcentage /
							100),
					amoPa: 0,
				}, // installation
				{
					duree: +this.amortissementsparcomposant.agencement.duree,
					valAmo:
						(this.buyprice + this.trconstruction) *
						(+this.amortissementsparcomposant.agencement
							.pourcentage /
							100),
					amoPa: 0,
				}, // agencement
				{
					duree: Number(
						this.amortissementsparcomposant.ameublement.duree
					),
					valAmo: Number(this.fameublementDeduit),
					amoPa: 0,
				}, // mobilier
			];

			const revalorisationLMNP =
				this.buyprice *
				(1 + this.estimreval.val) ** this.engagementPlusTravaux;
			const grilleAmoChangementLMNP = [
				{
					duree: +this.amortissementsparcomposant.terrain.duree,
					valAmo:
						revalorisationLMNP *
						(+this.amortissementsparcomposant.terrain.pourcentage /
							100),
					amoPa: 0,
				}, // terrain
				{
					duree: +this.amortissementsparcomposant.gros_oeuvre.duree,
					valAmo:
						revalorisationLMNP *
						(+this.amortissementsparcomposant.gros_oeuvre
							.pourcentage /
							100),
					amoPa: 0,
				}, // gros oeuvre
				{
					duree: +this.amortissementsparcomposant.facade_etancheite
						.duree,
					valAmo:
						revalorisationLMNP *
						(+this.amortissementsparcomposant.facade_etancheite
							.pourcentage /
							100),
					amoPa: 0,
				}, // facade
				{
					duree: +this.amortissementsparcomposant
						.installation_generales_techniques.duree,
					valAmo:
						revalorisationLMNP *
						(+this.amortissementsparcomposant
							.installation_generales_techniques.pourcentage /
							100),
					amoPa: 0,
				}, // installation
				{
					duree: +this.amortissementsparcomposant.agencement.duree,
					valAmo:
						revalorisationLMNP *
						(+this.amortissementsparcomposant.agencement
							.pourcentage /
							100),
					amoPa: 0,
				}, // agencement
				{
					duree: Number(
						this.amortissementsparcomposant.ameublement.duree
					),
					valAmo: Number(this.fameublementDeduit),
					amoPa: 0,
				}, // mobilier
			];

			grilleAmo.forEach((val, index) => {
				if (index !== 0) val.amoPa = val.valAmo / val.duree;
			});

			grilleAmoChangementLMNP.forEach((val, index) => {
				if (index !== 0) val.amoPa = val.valAmo / val.duree;
			});

			const grilleAmoLoop = [];
			for (let i = 0; i <= 50; i++) {
				if (
					this.changementDeFiscalite &&
					i < this.engagementPlusTravaux
				) {
					grilleAmoLoop.push(0);
				} else if (this.changementDeFiscalite) {
					grilleAmoLoop.push(
						Math.round(
							grilleAmoChangementLMNP[0].amoPa +
								(i <
								grilleAmoChangementLMNP[1].duree +
									this.engagementPlusTravaux
									? grilleAmoChangementLMNP[1].amoPa
									: 0) +
								(i <
								grilleAmoChangementLMNP[2].duree +
									this.engagementPlusTravaux
									? grilleAmoChangementLMNP[2].amoPa
									: 0) +
								(i <
								grilleAmoChangementLMNP[3].duree +
									this.engagementPlusTravaux
									? grilleAmoChangementLMNP[3].amoPa
									: 0) +
								(i <
								grilleAmoChangementLMNP[4].duree +
									this.engagementPlusTravaux
									? grilleAmoChangementLMNP[4].amoPa
									: 0) +
								(i <
								grilleAmoChangementLMNP[5].duree +
									this.engagementPlusTravaux
									? grilleAmoChangementLMNP[5].amoPa
									: 0) // rajouté pour les meubles
						)
					);
				} else {
					grilleAmoLoop.push(
						Math.round(
							grilleAmo[0].amoPa +
								(i < grilleAmo[1].duree
									? grilleAmo[1].amoPa
									: 0) +
								(i < grilleAmo[2].duree
									? grilleAmo[2].amoPa
									: 0) +
								(i < grilleAmo[3].duree
									? grilleAmo[3].amoPa
									: 0) +
								(i < grilleAmo[4].duree
									? grilleAmo[4].amoPa
									: 0) +
								(i < grilleAmo[5].duree
									? grilleAmo[5].amoPa
									: 0) // rajouté pour les meubles
						)
					);
				}
			}

			/********************************************************************************************************************************************************************************************************
			 *
			 * TABLEAU PLUS-VALUE DU BIEN
			 *
			 ********************************************************************************************************************************************************************************************************/

			const trvPV =
				this.trvame > this.buyprice * 0.15
					? Number(this.trvame)
					: Number(this.dureedetention.val) >= 5
					? Number(this.buyprice) * 0.15
					: Number(this.trvame);
			const prixAqMaj =
				Number(this.buyprice) + Number(this.fnotaire.val) + trvPV;
			const PVBrut =
				Number(resPrixRevente[resPrixRevente.length - 1]) - prixAqMaj;

			const grillePVTaxe = [
				{ range: [50001, 60000], per: 0.02, isRange: 0, taxe: 0 },
				{ range: [60001, 100000], per: 0.02, isRange: 0, taxe: 0 },
				{ range: [100001, 110000], per: 0.03, isRange: 0, taxe: 0 },
				{ range: [110001, 150000], per: 0.03, isRange: 0, taxe: 0 },
				{ range: [150001, 160000], per: 0.04, isRange: 0, taxe: 0 },
				{ range: [160001, 200000], per: 0.04, isRange: 0, taxe: 0 },
				{ range: [200001, 210000], per: 0.05, isRange: 0, taxe: 0 },
				{ range: [210001, 250000], per: 0.05, isRange: 0, taxe: 0 },
				{ range: [250001, 260000], per: 0.06, isRange: 0, taxe: 0 },
				{ range: [260001, 10000000], per: 0.06, isRange: 0, taxe: 0 },
			];

			const grilleIRPS = [];

			for (let i = 0; i <= 29; i++) {
				if (i < 5) {
					grilleIRPS.push([0, 0]);
				} else if (i === 21) {
					grilleIRPS.push([
						1,
						Number((grilleIRPS[i - 1][1] + 0.016).toFixed(4)),
					]);
				} else if (i > 21) {
					grilleIRPS.push([
						1,
						Number((grilleIRPS[i - 1][1] + 0.09).toFixed(4)),
					]);
				} else {
					grilleIRPS.push([
						Number((grilleIRPS[i - 1][0] + 0.06).toFixed(4)),
						Number((grilleIRPS[i - 1][1] + 0.0165).toFixed(4)),
					]);
				}
			}

			const PVBrutIR = grilleIRPS[this.dureedetention.val - 1][0];
			const PVBrutPS = grilleIRPS[this.dureedetention.val - 1][1];
			const PVNetIR = Math.round(PVBrut - PVBrut * PVBrutIR);
			const PVNetPS = Math.round(PVBrut - PVBrut * PVBrutPS);
			const impIR = Math.round(PVNetIR * this.globals.ImpotPluval.val);
			const impPS = Math.round(PVNetPS * this.globals.PSbenefice.val);
			let taxePrice = 0;

			grillePVTaxe.forEach((val, index) => {
				val.isRange =
					PVNetIR > val.range[0] && PVNetIR < val.range[1] ? 1 : 0;
				if (index % 2 === 0) {
					val.taxe =
						val.isRange *
						(val.per * PVNetIR -
							((val.range[1] - PVNetIR) * 1) / 20);
				} else {
					val.taxe = val.isRange * (val.per * PVNetIR);
				}

				taxePrice += val.taxe;
			});

			const taxeTotal = Math.round(impIR + impPS + taxePrice);

			/********************************************************************************************************************************************************************************************************
			 *
			 * FORME JURIDIQUE
			 *
			 ********************************************************************************************************************************************************************************************************/

			const formejuridiqueSelected = this.formejuridique.filter(
				(val) => val.selected === true
			)[0];

			const reducer = (accumulator, currentValue) =>
				accumulator + currentValue;

			const resFraisNotaireAchat = [];
			const resFraisAgenceAchat = [];
			const resResComptaImpo = [];
			const resResComptaImpoNU = [];

			const resCagnotteNUSCI = [];
			const resCagnotteOther = [];
			const resBaseImpMF = [];
			const resBaseImpMB = [];
			let inc = 0;
			for (let i = 0; i <= dureeempr; i++) {
				timelineNUSCIreel.push(
					formejuridiqueSelected.code === "lnr" ||
						formejuridiqueSelected.code === "sciirr"
						? 1
						: 0
				);
				timelineSCISASU.push(
					formejuridiqueSelected.code === "sasis" ||
						formejuridiqueSelected.code === "sciis" ||
						formejuridiqueSelected.code === "sasuis"
						? 1
						: 0
				);
				timelineLMNPreel.push(
					formejuridiqueSelected.code === "lmnpr" ? 1 : 0
				);
				timelineNUSCImf.push(
					formejuridiqueSelected.code === "lnmf" ||
						formejuridiqueSelected.code === "sciirmf"
						? 1
						: 0
				);
				timelineLMNPbic.push(
					formejuridiqueSelected.code === "lmnpbic" ? 1 : 0
				);

				/********************************************************************************************************************************************************************************************************
				 *
				 * CAGNOTTE & BASE
				 *  > NU LMNP SCI
				 * L15-23
				 ********************************************************************************************************************************************************************************************************/
				resAmorActif.push(
					-resTmpLoc[i] *
						(["lmnpr", "sasis", "sasuis", "sciis"].includes(
							formejuridiqueSelected.code
						) ||
						(this.changementDeFiscalite &&
							i >= this.engagementPlusTravaux)
							? grilleAmoLoop[i]
							: 0)
				);
				resFraisNotaireAchat.push(
					-(timelineLMNPreel[i] + timelineSCISASU[i]) *
						Number(this.cagnotte_base.resFraisNotaire[i])
				);
				resFraisAgenceAchat.push(
					-(timelineLMNPreel[i] + timelineSCISASU[i]) *
						Number(this.cagnotte_base.resFraisAgenceImmo[i])
				);
				resResComptaImpo.push(
					Number(this.cagnotte_base.resRevLoc[i]) -
						(-Number(this.cagnotte_base.resFraisExploit[i]) +
							-Number(this.cagnotte_base.resStructureSCI[i]) +
							-Number(this.cagnotte_base.resInvTrav[i]) +
							-Number(this.cagnotte_base.resItr[i]) +
							-Number(this.cagnotte_base.resFraisBanc[i]) +
							-Number(this.cagnotte_base.resAssEmpr[i]) +
							-Number(resAmorActif[i]) +
							Number(resFraisNotaireAchat[i]) +
							Number(resFraisAgenceAchat[i]))
				);
				resResComptaImpoNU.push(
					timelineNUSCIreel[i] * resResComptaImpo[i]
				);
				if (i < 10) {
					resCagnotteNUSCI.push(
						timelineNUSCIreel[i] *
							(i !== 0 && resCagnotteNUSCI[i - 1] < 0
								? resCagnotteNUSCI[i - 1] +
								  resResComptaImpoNU[i]
								: resResComptaImpoNU[i])
					);
					resCagnotteOther.push(
						timelineSCISASU[i] === 1 || timelineLMNPreel[i] === 1
							? i !== 0 && resCagnotteOther[i - 1] < 0
								? resCagnotteOther[i - 1] + resResComptaImpo[i]
								: resResComptaImpo[i]
							: 0
					);
				} else if (i === 10) {
					resCagnotteNUSCI.push(
						timelineNUSCIreel[i] *
							(resCagnotteNUSCI[i - 1] < 0 &&
							resResComptaImpo[inc] - resCagnotteNUSCI[i - 1] >= 0
								? resResComptaImpo[i] +
								  resCagnotteNUSCI[i - 1] -
								  resResComptaImpo[inc]
								: resCagnotteNUSCI[i - 1] < 0 &&
								  resResComptaImpo[inc] -
										resCagnotteNUSCI[i - 1] <
										0
								? resResComptaImpo[i]
								: resCagnotteNUSCI[i - 1] < 0
								? resCagnotteNUSCI[i - 1] + resResComptaImpo[i]
								: resCagnotteNUSCI[i - 1] >= 0
								? resResComptaImpo[i]
								: 0)
					);
					resCagnotteOther.push(
						(timelineSCISASU[i] + timelineLMNPreel[i]) *
							(resCagnotteOther[i - 1] < 0 &&
							resResComptaImpo[inc] - resCagnotteOther[i - 1] >= 0
								? resResComptaImpo[i] +
								  resCagnotteOther[i - 1] -
								  resResComptaImpo[inc]
								: resCagnotteOther[i - 1] < 0 &&
								  resResComptaImpo[inc] -
										resCagnotteOther[i - 1] <
										0
								? resResComptaImpo[i]
								: resCagnotteOther[i - 1] < 0
								? resCagnotteOther[i - 1] + resResComptaImpo[i]
								: resCagnotteOther[i - 1] >= 0
								? resResComptaImpo[i]
								: 0)
					);
					inc += 1;
				} else {
					resCagnotteNUSCI.push(
						timelineNUSCIreel[i] *
							((resCagnotteNUSCI[i - 1] < 0
								? resCagnotteNUSCI[i - 1] +
								  resResComptaImpoNU[i]
								: resResComptaImpoNU[i]) -
								(resCagnotteNUSCI[i - 1] +
									resResComptaImpo[i] >=
								0
									? 0
									: resResComptaImpo[inc]))
					);
					resCagnotteOther.push(
						(timelineSCISASU[i] === 1 || timelineLMNPreel[i] === 1
							? resCagnotteOther[i - 1] < 0
								? resCagnotteOther[i - 1] + resResComptaImpo[i]
								: resResComptaImpo[i]
							: 0) -
							(resCagnotteOther[i - 1] + resResComptaImpo[i] >= 0
								? 0
								: resResComptaImpo[inc])
					);
					inc += 1;
				}
				resBaseImpMF.push(
					timelineNUSCImf[i] > 0
						? Math.round(
								Number(this.cagnotte_base.resRevLoc[i]) *
									this.globals.revenuImpPer.microFonc.val
						  )
						: 0
				);
				resBaseImpMB.push(
					timelineLMNPbic[i] > 0
						? Math.round(
								Number(this.cagnotte_base.resRevLoc[i]) *
									this.globals.revenuImpPer.microBic.val
						  )
						: 0
				);
			}

			resTotAmorActif = resAmorActif.reduce(reducer);

			for (let i = 0; i <= dureeempr; i++) {
				resBaseImposable.push(
					resTmpLoc[i] *
						(resCagnotteNUSCI[i] +
							resCagnotteOther[i] +
							resBaseImpMF[i] +
							resBaseImpMB[i])
				);
				resValTaxableSCI.push(
					resDateRevente[i] *
						resTimelineChoixSCI[i] *
						(this.calcul_global.resPrixRevente[dureeempr] -
							(resTotAmorActif -
								this.calcul_global.resPrixAchat[0]))
				);

				resTimelineImpPositif.push(
					resBaseImposable[i] + resValTaxableSCI[i] > 0 ? 1 : 0
				);

				resImpTMISeul.push(
					-resTimelineImpPositif[i] *
						resTimelineChoixLMNPNU[i] *
						resBaseImposable[i] *
						(this.investSelected.tmi / 100)
				);

				resImpTMIAss.associateFirst.push(
					Math.round(
						formejuridiqueSelected.code === "sciirmf" ||
							formejuridiqueSelected.code === "sciirr"
							? -resTimelineImpPositif[i] *
									resTimelineChoixSCIIR[i] *
									resBaseImposable[i] *
									(this.type_fisc[0].tmi / 100) *
									(this.type_fisc[0].capital / 100)
							: 0
					)
				);
				resImpTMIAss.associateSecond.push(
					Math.round(
						formejuridiqueSelected.code === "sciirmf" ||
							formejuridiqueSelected.code === "sciirr"
							? this.type_fisc.length > 1
								? -resTimelineImpPositif[i] *
								  resTimelineChoixSCIIR[i] *
								  resBaseImposable[i] *
								  (this.type_fisc[1].tmi / 100) *
								  (this.type_fisc[1].capital / 100)
								: 0
							: 0
					)
				);
				resImpTMIAss.associateThird.push(
					Math.round(
						formejuridiqueSelected.code === "sciirmf" ||
							formejuridiqueSelected.code === "sciirr"
							? this.type_fisc.length > 2
								? -resTimelineImpPositif[i] *
								  resTimelineChoixSCIIR[i] *
								  resBaseImposable[i] *
								  (this.type_fisc[2].tmi / 100) *
								  (this.type_fisc[2].capital / 100)
								: 0
							: 0
					)
				);
				resImpTMIAss.associateFourth.push(
					Math.round(
						formejuridiqueSelected.code === "sciirmf" ||
							formejuridiqueSelected.code === "sciirr"
							? this.type_fisc.length > 3
								? -resTimelineImpPositif[i] *
								  resTimelineChoixSCIIR[i] *
								  resBaseImposable[i] *
								  (this.type_fisc[3].tmi / 100) *
								  (this.type_fisc[3].capital / 100)
								: 0
							: 0
					)
				);

				resPrelSocAss.associateFirst.push(
					Math.round(
						formejuridiqueSelected.code === "sciirmf" ||
							formejuridiqueSelected.code === "sciirr"
							? -resBaseImposable[i] *
									resTimelineImpPositif[i] *
									resTimelineChoixSCIIR[i] *
									this.globals.PSbenefice.val *
									(this.type_fisc[0].capital / 100)
							: 0
					)
				);
				resPrelSocAss.associateSecond.push(
					Math.round(
						formejuridiqueSelected.code === "sciirmf" ||
							formejuridiqueSelected.code === "sciirr"
							? this.type_fisc.length > 1
								? -resBaseImposable[i] *
								  resTimelineImpPositif[i] *
								  resTimelineChoixSCIIR[i] *
								  this.globals.PSbenefice.val *
								  (this.type_fisc[1].capital / 100)
								: 0
							: 0
					)
				);
				resPrelSocAss.associateThird.push(
					Math.round(
						formejuridiqueSelected.code === "sciirmf" ||
							formejuridiqueSelected.code === "sciirr"
							? this.type_fisc.length > 2
								? -resBaseImposable[i] *
								  resTimelineImpPositif[i] *
								  resTimelineChoixSCIIR[i] *
								  this.globals.PSbenefice.val *
								  (this.type_fisc[2].capital / 100)
								: 0
							: 0
					)
				);
				resPrelSocAss.associateFourth.push(
					Math.round(
						formejuridiqueSelected.code === "sciirmf" ||
							formejuridiqueSelected.code === "sciirr"
							? this.type_fisc.length > 3
								? -resBaseImposable[i] *
								  resTimelineImpPositif[i] *
								  resTimelineChoixSCIIR[i] *
								  this.globals.PSbenefice.val *
								  (this.type_fisc[3].capital / 100)
								: 0
							: 0
					)
				);

				const taxableAmount = resBaseImposable[i] + resValTaxableSCI[i];
				const isBelowThreshold =
					taxableAmount < this.globals.plafondImpositionIS;
				const isAboveThreshold =
					taxableAmount > this.globals.plafondImpositionIS;

				const belowThresholdTax =
					taxableAmount * this.globals.timpositionIS.val;
				const aboveThresholdTax =
					this.globals.plafondImpositionIS *
						this.globals.timpositionIS.val +
					(taxableAmount - this.globals.plafondImpositionIS) *
						this.globals.impositionIS.val;

				resRateIS[i] = belowThresholdTax
					? this.globals.timpositionIS.val
					: this.globals.impositionIS.val;

				const tax = isBelowThreshold
					? belowThresholdTax
					: isAboveThreshold
					? aboveThresholdTax
					: 0;
				const resImpSocValue =
					resTimelineImpPositif[i] * resTimelineChoixSCI[i] * -tax;

				resImpSoc.push(Math.round(resImpSocValue));
				resPrelSoc.push(
					Math.round(
						-resTimelineImpPositif[i] *
							resBaseImposable[i] *
							resTimelineChoixLMNPNU[i] *
							this.globals.PSbenefice.val
					)
				);
				resCSG.push(
					i === 0 ? 0 : -resPrelSoc[i - 1] * this.globals.CSG.val
				);
				resPV.push(
					Math.round(
						-resDateRevente[i] *
							(resTimelineChoixSCIIR[i] +
								resTimelineChoixLMNPNU[i]) *
							taxeTotal >
							0
							? 0
							: -resDateRevente[i] *
									(resTimelineChoixSCIIR[i] +
										resTimelineChoixLMNPNU[i]) *
									taxeTotal
					)
				);
				resImposition.push(
					resImpTMISeul[i] +
						resImpTMIAss.associateFirst[i] +
						resImpTMIAss.associateSecond[i] +
						resImpTMIAss.associateThird[i] +
						resImpTMIAss.associateFourth[i] +
						resImpSoc[i] +
						resPrelSoc[i] +
						resCSG[i] +
						resPrelSocAss.associateFirst[i] +
						resPrelSocAss.associateSecond[i] +
						resPrelSocAss.associateThird[i] +
						resPrelSocAss.associateFourth[i] +
						resPV[i]
				);

				resFluxAprImpot.push(resFluxAvtImpot[i] + resImposition[i]);
				resVersDiv.push(
					-(
						resFluxAprImpot[i] > 0 
							&& this.dividendeSCI === true
							&& this.formeJuridiqueSelected([
										'sciis',
										'sasis',
										'sasuis',
									])
						? resFluxAprImpot[i] * this.globals.flatTaxPer
						: // ? this.resTotBaseImposable * this.globals.flatTaxPer // -impot sur la societe
						  0) * resTimelineChoixSCI[i]
				);
				resCashFlowNonActu.push(resFluxAprImpot[i] + resVersDiv[i]);
				resCashFlowActu.push(
					resCashFlowNonActu[i] > 0
						? resCashFlowNonActu[i] /
								(1 +
									this.investSelected.params.params_taux.val /
										100) **
									i
						: resCashFlowNonActu[i]
				);
				resCashFlowCumul.push(
					(resTmpLoc[i] + resDateRevente[i]) *
						(resCashFlowActu[i] +
							(i === 0 ? 0 : resCashFlowCumul[i - 1]))
				);
				resSommeInvest.push(
					resTmpLoc[i] *
						(resCashFlowActu[i] <= 0 ? resCashFlowActu[i] : 0)
				);
			}

			resTotBaseImposable = resBaseImposable.reduce(reducer);
			resTotValTaxableSCI = resValTaxableSCI.reduce(reducer);
			resTotImposition = resImposition.reduce(reducer);
			resTotImpTMISeul = resImpTMISeul.reduce(reducer);
			resTotImpSoc = resImpSoc.reduce(reducer);
			resTotPrelSoc = resPrelSoc.reduce(reducer);
			resTotCSG = resCSG.reduce(reducer);
			resTotPV = resPV.reduce(reducer);
			resTotImpTMIAss.associateFirst =
				resImpTMIAss.associateFirst.reduce(reducer);
			resTotImpTMIAss.associateSecond =
				resImpTMIAss.associateSecond.reduce(reducer);
			resTotImpTMIAss.associateThird =
				resImpTMIAss.associateThird.reduce(reducer);
			resTotImpTMIAss.associateFourth =
				resImpTMIAss.associateFourth.reduce(reducer);
			resTotPrelSocAss.associateFirst =
				resPrelSocAss.associateFirst.reduce(reducer);
			resTotPrelSocAss.associateSecond =
				resPrelSocAss.associateSecond.reduce(reducer);
			resTotPrelSocAss.associateThird =
				resPrelSocAss.associateThird.reduce(reducer);
			resTotPrelSocAss.associateFourth =
				resPrelSocAss.associateFourth.reduce(reducer);

			resTotFluxAprImpot = resFluxAprImpot.reduce(reducer);
			resTotVersDiv = resVersDiv.reduce(reducer);
			resTotCashFlowNonActu = resCashFlowNonActu.reduce(reducer);
			resTotCashFlowActu = resCashFlowActu.reduce(reducer);
			resTotCashFlowCumul = resCashFlowCumul.reduce(reducer);
			resTotSommeInvest = resSommeInvest.reduce(reducer);

			this.resFluxAIFonc.resAmorActif = resAmorActif;
			this.resFluxAIFonc.resBaseImposable = resBaseImposable;
			this.resFluxAIFonc.resValTaxableSCI = resValTaxableSCI;
			this.resFluxAIFonc.resImpTMISeul = resImpTMISeul;
			this.resFluxAIFonc.resImpTMIAss = resImpTMIAss;
			this.resFluxAIFonc.resImpSoc = resImpSoc;
			this.resFluxAIFonc.resRateIS = resRateIS;
			this.resFluxAIFonc.resImpotIS = [];
			this.resFluxAIFonc.resDividendes = [];
			this.resFluxAIFonc.resPrelSoc = resPrelSoc;
			this.resFluxAIFonc.resCSG = resCSG;
			this.resFluxAIFonc.resPrelSocAss = resPrelSocAss;
			this.resFluxAIFonc.resPV = resPV;
			this.resFluxAIFonc.resImposition = resImposition;
			this.resFluxAIFonc.resFluxAprImpot = resFluxAprImpot;
			this.resFluxAIFonc.resVersDiv = resVersDiv;
			this.resFluxAIFonc.resCashFlowNonActu = resCashFlowNonActu;
			this.resFluxAIFonc.resCashFlowActu = resCashFlowActu;
			this.resFluxAIFonc.resCashFlowCumul = resCashFlowCumul;
			this.resFluxAIFonc.resSommeInvest = resSommeInvest;

			this.resFluxAIFonc.resTotAmorActif = resTotAmorActif;
			this.resFluxAIFonc.resTotBaseImposable = resTotBaseImposable;
			this.resFluxAIFonc.resTotValTaxableSCI = resTotValTaxableSCI;
			this.resFluxAIFonc.resTotImpTMISeul = resTotImpTMISeul;
			this.resFluxAIFonc.resTotImpTMIAss = resTotImpTMIAss;
			this.resFluxAIFonc.resTotImpSoc = resTotImpSoc;
			this.resFluxAIFonc.resTotPrelSoc = resTotPrelSoc;
			this.resFluxAIFonc.resTotCSG = resTotCSG;
			this.resFluxAIFonc.resTotPrelSocAss = resTotPrelSocAss;
			this.resFluxAIFonc.resTotPV = resTotPV;
			this.resFluxAIFonc.resTotImposition = resTotImposition;
			this.resFluxAIFonc.resTotFluxAprImpot = resTotFluxAprImpot;
			this.resFluxAIFonc.resTotVersDiv = resTotVersDiv;
			this.resFluxAIFonc.resTotCashFlowNonActu = resTotCashFlowNonActu;
			this.resFluxAIFonc.resTotCashFlowActu = resTotCashFlowActu;
			this.resFluxAIFonc.resTotCashFlowCumul = resTotCashFlowCumul;
			this.resFluxAIFonc.resTotSommeInvest = resTotSommeInvest;

			/*********************************************************************************************************************************************************************************************************
			 *
			 * >> CALCUL DU TIR
			 *    TIR SANS DETTE
			 *    TIR AVEC DETTE
			 *    TIR AVEC FISCA
			 *
			 *********************************************************************************************************************************************************************************************************/

			// sans dette
			const tirSD = this.TIRsansDette(
				this.resInvTravFonc.resFluxAvtDette
			);
			// avec dette
			const tirAD = this.TIRsansDette(
				this.resInvTravFonc.resFluxAvtImpot
			);
			// avec fiscalite TODO
			const tirAF = this.TIRsansDette(
				this.resFluxAIFonc.resCashFlowNonActu
			);
			// avec fiscalite
			const tir = this.TIRsansDette(this.resFluxAIFonc.resCashFlowActu);

			/*********************************************************************************************************************************************************************************************************
			 * CADRAN
			 *
			 *  PERFORMANCE
			 *  -----
			 *  RENDEMENT TOTAL
			 *********************************************************************************************************************************************************************************************************/

			// tir
			performance.sansDette.tir = tirSD;
			performance.avecDette.tir = tirAD;
			performance.avecFisc.tir = tirAF;
			performance.tir = tir;

			// van
			performance.avecFisc.van = resTotCashFlowActu;

			// indice de profitabilite
			// OLD
			// performance.sansDette.indprof = Number((Number(this.resInvTravFonc.resTotFluxAvtDette) / (- Number(resTotSommeInvest))).toFixed(2))
			// performance.avecDette.indprof = Number((Number(this.resInvTravFonc.resTotFluxAvtImpot) / (- Number(resTotSommeInvest))).toFixed(2))
			// performance.avecFisc.indprof = Number((Number(resTotFluxAprImpot) / (- Number(resTotSommeInvest))).toFixed(2))
			// NEW
			performance.avecFisc.indprof =
				resTotCashFlowActu / -resTotSommeInvest + 1;

			// effet de levier (emprunt)
			performance.avecDette.effetlevier = Number(
				(((tirAD / 100 - tirSD / 100) / (tirSD / 100)) * 100).toFixed(2)
			);

			// //taux de levier (rentabilite de l'emprunt)
			// performance.avecFisc.tlevier = Number(((- (1 - ((1 + (tirAF / 100)) / (1 + (tirSD / 100))))) * 100).toFixed(2))

			// Refait la somme du tableau resCoutTotalAcqui
			const resTotCoutTotalAcqui = resCoutTotalAcqui.reduce(reducer);
			rendementTotal.total.val = resTotCashFlowNonActu;
			rendementTotal.total.per = Number(
				(resTotCashFlowNonActu / resTotCashFlowNonActu).toFixed(2)
			);
			rendementTotal.plusval.val = resTotCoutTotalAcqui;
			rendementTotal.plusval.per = Number(
				(resTotCoutTotalAcqui / resTotCashFlowNonActu).toFixed(2)
			);
			rendementTotal.revloc.val =
				rendementTotal.total.val - rendementTotal.plusval.val;
			rendementTotal.revloc.per = Number(
				(rendementTotal.revloc.val / rendementTotal.total.val).toFixed(
					2
				)
			);

			repartitionBenef.associateFirst = this.type_fisc[0]
				? {
						per: this.type_fisc[0].capital / 100,
						val: -this.type_fisc[0].apport,
						profit:
							(rendementTotal.total.val *
								this.type_fisc[0].capital) /
							100,
						ratio:
							(rendementTotal.total.val *
								this.type_fisc[0].capital) /
							100 /
							-this.type_fisc[0].apport,
				  }
				: 0;
			repartitionBenef.associateSecond = this.type_fisc[1]
				? {
						per: this.type_fisc[1].capital / 100,
						val: -this.type_fisc[1].apport,
						profit:
							(rendementTotal.total.val *
								this.type_fisc[1].capital) /
							100,
						ratio:
							(rendementTotal.total.val *
								this.type_fisc[1].capital) /
							100 /
							-this.type_fisc[1].apport,
				  }
				: 0;
			repartitionBenef.associateThird = this.type_fisc[2]
				? {
						per: this.type_fisc[2].capital / 100,
						val: -this.type_fisc[2].apport,
						profit:
							(rendementTotal.total.val *
								this.type_fisc[2].capital) /
							100,
						ratio:
							(rendementTotal.total.val *
								this.type_fisc[2].capital) /
							100 /
							-this.type_fisc[2].apport,
				  }
				: 0;
			repartitionBenef.associateFourth = this.type_fisc[3]
				? {
						per: this.type_fisc[3].capital / 100,
						val: -this.type_fisc[3].apport,
						profit:
							(rendementTotal.total.val *
								this.type_fisc[3].capital) /
							100,
						ratio:
							(rendementTotal.total.val *
								this.type_fisc[3].capital) /
							100 /
							-this.type_fisc[3].apport,
				  }
				: 0;

			this.resFluxAIFonc.rendementTotal = rendementTotal;
			this.resFluxAIFonc.repartitionBenef = repartitionBenef;
			this.resFluxAIFonc.performance = performance;

			this.resultats = {
				...this.timelineFonc,
				...this.resCoutTotalFonc,
				...this.resOperationNetFonc,
				...this.resInvTravFonc,
				...this.resFluxAIFonc,
			};
			this.resultats = {
				timelineFonc: this.timelineFonc,
				resCoutTotalFonc: this.resCoutTotalFonc,
				resOperationNetFonc: this.resOperationNetFonc,
				resInvTravFonc: this.resInvTravFonc,
				resFluxAIFonc: this.resFluxAIFonc,
			};

			this.onSubmit();
		},
		TIRsansDette(flux) {
			// let dureeempr = this.dureeempr.isAuto === true ? Number(this.dureeempr.valAuto / 12) : Number(this.dureeempr.val)
			const dureeempr =
				this.dureedetention.isAuto === true
					? Number(this.dureedetention.valAuto / 12)
					: Number(this.dureedetention.val);
			let Tir = 0;
			const arrayTir = [];
			let arrayEachTir = [];
			const test = [];
			const reducer = (accumulator, currentValue) =>
				accumulator + currentValue;

			for (
				let i = 0;
				i <= this.globals.tactualisationPer.length - 1;
				i++
			) {
				for (let j = 0; j <= dureeempr; j++) {
					arrayEachTir.push(
						flux[j] /
							(1 + this.globals.tactualisationPer[i] / 100) ** j
					);
				}
				arrayTir.push(arrayEachTir);
				Tir = arrayEachTir.reduce(reducer);
				test.push(Tir);
				arrayEachTir = [];

				if (Tir <= 0) {
					return this.globals.tactualisationPer[i];
				}
			}
		},

		setAuto(val) {
			this.auto = val;
			if (this.auto === true) {
				this.montantemprunte.isAuto = true;
				this.apportfond.isAuto = true;
				this.dureeempr.isAuto = true;
				this.tinteretempr.isAuto = true;
				this.tassuranceempr.isAuto = true;
				this.fbancaire.isAuto = true;
				this.fgarantie.isAuto = true;
				this.tmi.isAuto = true;
				this.fcomptable.isAuto = true;
				this.fcotisation.isAuto = true;
				this.dureedetention.isAuto = true;
			} else {
				this.montantemprunte.isAuto = false;
				this.apportfond.isAuto = false;
				this.dureeempr.isAuto = false;
				this.tinteretempr.isAuto = false;
				this.tassuranceempr.isAuto = false;
				this.fbancaire.isAuto = false;
				this.fgarantie.isAuto = false;
				this.tmi.isAuto = false;
				this.fcomptable.isAuto = false;
				this.fcotisation.isAuto = false;
				this.dureedetention.isAuto = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.card {
	background: #ffffff;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
	grid-column: span 12 / span 12;
	grid-column-start: 3;
	grid-column-end: 12;
	padding: 0.75rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	padding-bottom: 1.75rem;
	margin-bottom: 2.5rem;
	margin-left: 2.5rem;
	margin-right: -2.5rem;
	border-radius: 0.85rem;
}

.card-header {
	margin-bottom: 2rem;
	margin-top: 0.5rem;
}

.card-no-top {
	background: #ffffff;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
	grid-column: span 12 / span 12;
	grid-column-start: 3;
	grid-column-end: 12;
	padding: 0.75rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	padding-bottom: 1.75rem;
	margin-top: -2.5rem;
	margin-bottom: 2.5rem;
	margin-left: 2.5rem;
	margin-right: -2.5rem;
	border-top-left-radius: 0rem;
	border-top-right-radius: 0rem;
	border-bottom-left-radius: 0.85rem;
	border-bottom-right-radius: 0.85rem;
}

.card-no-bot {
	background: #ffffff;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
	grid-column: span 12 / span 12;
	grid-column-start: 3;
	grid-column-end: 12;
	padding: 1.5rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	padding-bottom: 1.75rem;
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
	margin-left: 2.5rem;
	margin-right: -2.5rem;
	border-top-left-radius: 0.85rem;
	border-top-right-radius: 0.85rem;
	border-bottom-left-radius: 0rem;
	border-bottom-right-radius: 0rem;
}

.tips {
	background: #c7caff;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 37px;
	font-size: 0.85rem /* 13px */;
	font-weight: 400;
}

.bouton {
	border-radius: 0.375rem /* 6px */;
	--text-opacity: 1;
	color: #ffffff;
	color: rgba(255, 255, 255, var(--text-opacity));
	--bg-opacity: 1;
	background-color: #14a0eb;
	background-color: rgba(20, 160, 235, var(--bg-opacity));
	transition-property: background-color, border-color, color, fill, stroke,
		opacity, box-shadow, transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
	border-width: 1px;
	border-color: transparent;
}

.bouton:hover {
	--bg-opacity: 1;
	background-color: #0e1e3a;
	background-color: rgba(14, 30, 58, var(--bg-opacity));
}

.bouton:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
	--border-opacity: 1;
	border-color: #0e1e3a;
	border-color: rgba(14, 30, 58, var(--border-opacity));
	box-shadow: 0 0 0 3px rgba(180, 198, 252, 0.45);
}

.no-arrow {
	-webkit-appearance: none;
	margin: 0;
	-moz-appearance: textfield;
}

.m2-input {
	position: relative;
	margin-right: 12px;
}

.m2-input input {
	padding-right: 18px;
	text-align: end;
}

.m2-input:before {
	position: absolute;
	top: 8px;
	right: -27px;
	font-size: 0.8rem;
	content: "m²";
}
.euro-input {
	position: relative;
}

.euro-input input {
	padding-right: 18px;
	text-align: end;
}

.euro-input:before {
	position: absolute;
	top: 8px;
	right: 7px;
	font-size: 0.8rem;
	content: "€";
}

.percent-input {
	position: relative;
}

.percent-input input {
	padding-right: 18px;
	text-align: end;
}

.percent-input:before {
	position: absolute;
	top: 8px;
	right: 7px;
	font-size: 0.8rem;
	content: "%";
}
.bouton:active {
	--bg-opacity: 1;
	background-color: #0e1e3a;
	background-color: rgba(14, 30, 58, var(--bg-opacity));
}

.form-input {
	--border-opacity: 1;
	border-color: #d2d6dc;
	border-color: rgba(210, 214, 220, var(--border-opacity));
	border-radius: 0.375rem;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	transition-property: background-color, border-color, color, fill, stroke,
		opacity, box-shadow, transform;
	transition-duration: 150ms;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	font-size: 0.75rem;
	font-weight: 400;
	appearance: none;
	-webkit-appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}
.form-input:focus {
	--border-opacity: 1;
	border-color: #a4cafe;
	border-color: rgba(164, 202, 254, var(--border-opacity));
	outline: 2px solid transparent;
	outline-offset: 2px;
	box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
	appearance: none;
	-webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}
.form-input:active {
	--border-opacity: 1;
	border-color: #a4cafe;
	border-color: rgba(164, 202, 254, var(--border-opacity));
	outline: 2px solid transparent;
	outline-offset: 2px;
	box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
	appearance: none;
	-webkit-appearance: none;
}

.form-input:hover {
	--border-opacity: 1;
	border-color: #a4cafe;
	border-color: rgba(164, 202, 254, var(--border-opacity));
	outline: 2px solid transparent;
	outline-offset: 2px;
	box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
	appearance: none;
	-webkit-appearance: none;
}

input[type="file"] {
	display: none;
}

.texte-label {
	display: block;
	font-size: 0.75rem /* 12px */;
	line-height: 1.25rem /* 20px */;
	--text-opacity: 1;
	color: #374151;
	color: rgba(55, 65, 81, var(--text-opacity));
	font-weight: 500;
}

select:not(.forme-jur) {
	border: 1px solid #d2d6dc;
	color: #000000;
	font-weight: normal;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAApklEQVRIie2VQQrCMBBFH15CjQp6/3XBU1RasGAVFOo92kUzUCSlzXSyEP3wd8n7gcnMwF+GcsAFyIGtNXwDXIHW+wbsLOHVAC6uLUL2wD0AFz+BoxZ+mICLX8BJA3/MgKtDygi4uAiBViMB75jXeDWKO1+sM/E1yEKgsRo4xaOimi75N5WQOY22qJunRkXtzyzS5yQ1HXaiNQnHtcjRL5skC+eH1QFftG+pNdEy3AAAAABJRU5ErkJggg==");
}

.slider {
	-webkit-appearance: none;
	width: 90%;
	height: 15px;
	border-radius: 5px;
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
}

.slider:hover {
	opacity: 1;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #14a0eb;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #14a0eb;
	cursor: pointer;
}

.ampoule-picto {
	top: -1rem;
	left: -1rem;
}

.bg-3D-btn {
	transition: 0.1s;
	color: black;
	box-shadow: -4px 4px #14a0eb, -3px 3px #14a0eb, -2px 2px #14a0eb;
	border: 1px solid #14a0eb;
	border-radius: 3px;
}

.bg-3D-btn:active {
	box-shadow: -2px 2px #14a0eb, -1px 1px #14a0eb, -1px 1px #14a0eb;
}
</style>
