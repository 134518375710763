<template>
  <svg data-v-79ad0d38="" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="36" height="36" viewBox="0 0 172 172" style="fill: rgb(0, 0, 0);"><g data-v-79ad0d38="" fill="none" fill-rule="inherit" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="inherit" font-size="none" text-anchor="inherit" style="mix-blend-mode: normal;"><path data-v-79ad0d38="" d="M0,172v-172h172v172z" fill="none" fill-rule="nonzero"></path><g data-v-79ad0d38="" fill="#0C1E3B" fill-rule="evenodd"><path data-v-79ad0d38="" d="M161.92188,25.08333l-104.14062,105.26042l-47.70312,-47.47917l-10.07812,10.30208l57.78125,57.33333l114.21875,-115.11458z"></path></g></g></svg>
</template>

<script>
export default {
  name: "yes"
}
</script>

<style scoped>

</style>