<script>
import {Doughnut, mixins } from 'vue-chartjs'
// const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  // mixins: [reactiveProp],
  data() {
      return {
      }
    },
  props: ['chartdata', 'options', 'chartId'],
  mounted() {
    // console.log("this.chartdata",this.chartdata, "this.options", this.options)
    this.renderChart(this.chartdata, this.options)
  },
  methods: {
    update() {
      this.$data._chart.update()
    }
  }
}
</script>

<style>
</style>