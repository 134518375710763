<template>
	<div>
		<Header/>
		<section class="grid grid-cols-12 gap-1 bg-darkblueaestima-500 relative flex flex-row justify-start items-start md:pt-6 px-4 sm:px-0">
			<div class="xl:container xl:mx-auto relative text-left col-span-12 sm:col-start-2 sm:col-end-12 z-10">
				<h1 class="font-bold text-white head-title mb-4 leading-normal">Qui sommes-nous ?</h1>
				<p class="font-normal text-white head-subtitle mb-4 leading-normal">Une équipe à votre écoute, bien sûr !</p>
			</div>
		</section>
		<section class="xl:container xl:mx-auto grid grid-cols-12 gap-1 mx-4 sm:mx-0 mt-20">
			<h2 class="col-span-12 sm:col-start-2 sm:col-end-12 text-2xl md:text-4xl mb-4 font-normal text-left">
				<strong>Avant tout, des humains derrière la machine.</strong>
			</h2>
			<div class="col-span-12 sm:col-start-2 sm:col-end-12 md:mb-10">
				<p class="text-left">
					Nous sommes Meryl et Julien et avons co-fondé Aestima dans le but de rapprocher les professionnels immobiliers de leurs clients. Et ce, avec un équipement digital sur-mesure.
				</p>
				<div class="flex flex-row items-center mt-14 mb-10">
					<img class="h-32 w-auto" src="../../assets/julien_pres.png" alt="Julien co-fondateur photo">
					<p class="text-xl font-bold text-black ml-4">Julien</p>
				</div>
				<p class="italic text-left text-xl leading-loose">
					<img class="w-14 inline-block align-baseline" src="../../assets/icons/waw/aestima_quote.png" alt="quote icone"> L’idée de Aestima s’est manifestée après avoir travaillé 2 ans dans une société de marchand de biens à analyser et gérer des dizaines d’investissements immobiliers. Complexe et fastidieuse, l’analyse financière de ces projets devait être simplifiée. Pour les collègues, pour la direction et pour les clients. 
				</p>
				</br>
				<p class="italic text-left text-xl leading-loose">
					L’objectif derrière Aestima, c’est de réduire l’asymétrie d’information présente dans ce domaine et de l’automatiser pour permettre aux pros immobiliers de se focaliser sur l’essentiel : l’action !
				</p>
			</div>
			<div class="col-span-12 sm:col-start-2 sm:col-end-12">
				<div class="flex flex-row items-center mt-10 mb-10">
					<img class="h-32 w-auto" src="../../assets/meryl_pres.png" alt="Méryl co-fondatrice photo">
					<p class="text-xl font-bold text-black ml-4">Meryl</p>
				</div>
				<p class="italic text-left text-xl leading-loose">
					<img class="w-14 inline-block align-baseline" src="../../assets/icons/waw/aestima_quote.png" alt="quote icone"> De mon côté, j’ai voulu mettre en avant une dimension humaine dans ce monde très financier et rationnel.
					Issue de l’univers des agences immobilières, j’ai souhaité permettre aux conseillers immobiliers de fournir une prestation personnalisée et vulgarisée à leurs clients investisseurs. 
				</p>
				
			</div>
		</section>
		
		<section class="bg-section-light-blue">
			<div class="xl:container xl:mx-auto grid grid-cols-12 gap-1 text-justify px-4 md:px-0 py-10 mt-6 md:mt-10">
				<h2 class="col-span-12 sm:col-start-2 sm:col-end-12 text-2xl md:text-4xl mb-8 font-normal px-2 py-1 text-left">
					<strong>Ce qui guide nos actions chaque matin.</strong>
				</h2>
				<div class="col-start-2 col-end-12 grid grid-cols-12 sm:gap-14">
					<div class="col-span-12 sm:col-span-6 md:col-span-4 bg-white shadow-xl p-8 mb-6 sm:mb-0">
						<picture>
							<source srcset="../../assets/icons/waw/aestima_excellence.jpg" 
							media="(max-width:734px)">
							<source srcset="../../assets/icons/waw/aestima_excellence.jpg" 
							media="(min-width: 0px)">
							<img class="w-52 max-w-none m-auto" src="../../assets/icons/waw/aestima_excellence.jpg" alt="icon aestima gestion client">
						</picture>
						<h3 class="text-xl text-center my-4"><strong>L’excellence.</strong></h3>
						<p class="text-justify">Tout les jours, on veut une solution plus précise, plus complète et encore plus adaptée à VOS besoins.</p>
					</div>
					<div class="col-span-12 sm:col-span-6 md:col-span-4 bg-white shadow-xl p-8 mb-6 sm:mb-0">
						<picture>
							<source srcset="../../assets/icons/waw/aestima_determination.jpg" 
							media="(max-width:734px)">
							<source srcset="../../assets/icons/waw/aestima_determination.jpg" 
							media="(min-width: 0px)">
							<img class="w-52 max-w-none m-auto" src="../../assets/icons/waw/aestima_determination.jpg" alt="icon aestima gestion client">
						</picture>
						<h3 class="text-xl text-center my-4"><strong>La détermination.</strong></h3>
						<p class="text-justify">On crée, on modifie, on recrée et on recommencera s’il le faut pour vous fournir une solution sur-mesure.</p>
					</div>
					<div class="col-span-12 sm:col-span-6 md:col-span-4 bg-white shadow-xl p-8 mb-6 sm:mb-0">
						<picture>
							<source srcset="../../assets/icons/waw/aestima_pedagogie.jpg" 
							media="(max-width:734px)">
							<source srcset="../../assets/icons/waw/aestima_pedagogie.jpg" 
							media="(min-width: 0px)">
							<img class="w-52 max-w-none m-auto" src="../../assets/icons/waw/aestima_pedagogie.jpg" alt="icon aestima gestion client">
						</picture>
						<h3 class="text-xl text-center my-4"><strong>La pédagogie.</strong></h3>
						<p class="text-justify">On veut vous fournir l’utilisation la plus limpide possible pour être plus percutant avec votre client.</p>
					</div>
				</div>
			</div>
		</section>
		
		<section class="xl:container xl:mx-auto grid grid-cols-12 gap-1 mx-4 sm:mx-0 mt-20">
			<h2 class="col-span-12 sm:col-start-2 sm:col-end-12 text-2xl md:text-4xl mb-4 font-normal text-left">
				<strong>Nos convictions les plus profondes.</strong>
			</h2>
			<div class="col-span-12 sm:col-start-2 sm:col-end-12 md:mb-10">
				<div class="col-span-12 sm:col-start-2 sm:col-end-12 font-normal px-2 py-1 md:mb-10 text-justify">
					<p class="mb-8">
						Aujourd’hui, l’accès à <strong>la connaissance est illimité.</strong> Tout ce que vous voulez savoir peut se trouver en quelques clics sur le web. Nous constatons donc dans notre société actuelle que <strong>le fossé entre novices et experts se comble, en apparence du moins.</strong>
					</p>
					<p>
						Les médecins font face à des patients qui en savent plus qu’eux.
					</p>
					<p>
						Les avocats font face à des clients qui leur dictent les textes de lois.
					</p>
					<p class="mb-8">
						Les professionnels immobiliers font face à des clients qui pensent ne pas avoir besoin d’eux.
					</p>
					<p class="mb-8">La société semble donc tendre vers une <strong>autonomie totale</strong> sur tous les domaines ce qui <strong>met en péril peu à peu le métier d’expert. </strong></p>

					<p class="mb-8">Dans l’univers immobilier, nous voyons émerger des sociétés nouvelles permettant de tout piloter seul : sa gestion locative, la vente de sa maison, la gestion autonome de son patrimoine, etc.</p>

					<p class="mb-8">Mais nous, chez Aestima, <strong>nous sommes convaincus que les experts immobiliers ne disparaîtront pas.</strong> Ils resteront indispensables dans nos vies. Et ce pour trois raisons fondamentales dont nous aurons toujours besoin :</p>

					<h3 class="text-xl md:text-2xl"><strong>La connaissance,</strong></h3>
					<p class="mb-8">non pas par la théorie mais par l’expérience. Car comme dit Albert Einstein “ La connaissance s’acquiert par l’expérience, tout le reste n’est que de l’information”.</p>

					<h3 class="text-xl md:text-2xl"><strong>Le gain de temps</strong></h3>
					<p class="mb-8">permis par la délégation. Nous avons tous 24 heures dans une journée, et cela n’est pas prêt de changer ! Même si nous acquérons la connaissance, nous ne pouvons pas consacrer tout notre temps à chaque domaine de notre vie.</p>

					<h3 class="text-xl md:text-2xl"><strong>La sécurité et la responsabilité.</strong></h3>
					<p class="mb-8">Confier une mission à un expert nous couvre auprès d’une autorité juridique, auprès de l’Etat, auprès d’un acheteur. Dans un monde de plus en plus régie par la sécurité et l’assurance, cette tendance se renforcera.</p>

					<p class="mb-8">Les professionnels immobiliers ne disparaîtront donc pas. Certes. <strong>Mais ils devront exceller pour justifier leur valeur ajoutée face à des clients de plus en plus avertis</strong>. Ils devront constamment faire évoluer leur manière de travailler. C’est une évidence.</p>

					<p class="mb-8">Comme le disait Darwin, “les espèces qui survivent ne sont pas les espèces les plus fortes, ni les plus intelligentes, mais celles qui s'adaptent le mieux aux changements.”</p>

					<p class="mb-8">Et la <strong>mission de Aestima</strong>, c’est de gérer la question “comment s’adapter ?” et de <strong>vous fournir toutes les solutions pour faire face aux changements de demain</strong>.</p>

					<p class="mb-8">Soyez les espèces qui survivent, celles qui s’adaptent grâce à Aestima.</p>

					<p class="mb-8">La Team Aestima.</p>
				</div>
				<div class="col-span-12 sm:col-start-2 sm:col-end-12 flex flex-col sm:flex-row justify-center mx-4 sm:mx-0 mb-10 sm:mb-0 sm:mt-8">
					<router-link to="/register" class="text-md sm:text-lg md:text-base mr-1 sm:mr-3 font-bold text-white px-6 py-4 bg-aestiblue-light rounded-lg aestima-button mt-6 sm:mt-0 sm:mb-3">Prenez le train du changement</router-link>
					<a href="https://calendly.com/contact-aestima/45min?" target="_blank" class="text-md sm:text-lg md:text-base ml-1 sm:ml-3 font-bold text-black px-2 py-4 bg-transparent rounded-lg underline mt-6 sm:mt-0 sm:mb-3">Contacter l’équipe commerciale</a>
				</div>
			</div>
		</section>
		<Footer/>
	</div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
export default {
	name: 'WAW',
	data() {
		return {
		}
	},
	components: {
		Footer,
		Header
	},
	metaInfo(){
		return {
			title: "Qui Sommes-Nous ? - Aestima",
			meta: [{
				name: 'description',
				content: "Cette page est une description de l'outil Aestima."
			}]
			//keywords
		}
	},
}
</script>

<style scoped>
.h-header {
	height: 100%;
}
.aestima-button {
	transition: 0.5s;
	background-color: #14A0EB;
}
/* 
video {
	position: relative;
	width: 100% !important;
	height: auto;
} */

.aestima-button:hover {
	background-color: #ffffff;
	border: 2px solid #14A0EB;
	color: #14A0EB;
	transform: scale(1.1);
}

.aestima-title-vw {
}

.aestima-par-vw {
}
/* Medium (md) */
@media (min-width: 768px) {
	.h-header {
		height: 50rem;
	}

	.aestima-title-vw {
		font-size: 2.8vw !important;
	}

	.aestima-par-vw {
		font-size: 1.2vw !important;
	}
}

.head-title {
	font-size: 2.8rem;
}

.head-subtitle {
	font-size: 1.375rem;
}

.bg-section-light-blue {
	background: rgba(20, 160, 235, 0.05);
}

</style>

