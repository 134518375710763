<template>
	<div v-if="!selectedInvest">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;  margin-top:20px; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
			<circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25" stroke-dasharray="70.68583470577033 25.561944901923447">
			<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
			</circle>
		</svg>
	</div>
	<div v-else class="sm:px-6 lg:px-8 text-left mx-10 relative">
			<div class="bg-lightblueaestima-100 py-4 px-2 rounded-md">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-white normal-case">ENRICHISSEMENT LIÉ À L'INVESTISSEMENT</h3>
						</div>
					</div>
				</div>
			</div>

			<div class="hidden sm:block">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>
			
			<div>
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="inline-block text-lg font-medium leading-6 text-gray-900 normal-case">Tenant compte d'un taux d'actualisation de</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base"> <b> Comme expliqué précédemment, ce taux mesure à quelle vitesse l’argent va se déprécier dans le temps </b>. Il regroupe plusieurs éléments mais <b> se rapproche sensiblement du taux d’inflation. </b> Plus il est élevé, plus il aura un impact négatif sur les futurs revenus.</span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case">{{selectedInvest.carac.tactualisation.per}} %</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div class="mt-10">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case">Profit total (non actualisé)</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base"> Ce montant regroupe tout ce que vous aurez dépensé et gagné durant la durée de votre investissement. Si le total est positif, cela signifie que vous gagnerez de l’argent au terme de votre investissement. Attention, cette donnée ne tient pas compte de l’actualisation (ou de la dépréciation de valeur liée à l’inflation).</span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{Math.round(Number(selectedInvest.result.rendementTotal.total))}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div>
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case pl-4">Dont revenus locatifs nets </h3>
						</div>
					</div>
					<div class="md:col-span-1 text-right text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case">{{Math.round(Number(selectedInvest.result.rendementTotal.revloc))}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div>
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case pl-4">Dont plus-value de revente </h3>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case">{{Math.round(Number(selectedInvest.result.rendementTotal.plusval))}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div  class="mt-10">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0 relative">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case">VAN (Valeur Actuelle Nette) ou profit total actualisé</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base"> <b> Cet indicateur est important et beaucoup plus parlant que le TIR car il nous indique la somme totale gagnée au terme de l’investissement en tenant compte de l’actualisation (dépréciation de la valeur de l’argent dans le temps). </b> On parle aussi de profit total actualisé.</span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{Math.round(Number(selectedInvest.result.resTotCashFlowActu))}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div  class="mt-10">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0 relative">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case">L'emprunt a amélioré la performance de votre investissement de</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base"> <b> Ce ratio permet d'analyser l'impact de la dette sur notre investissement.</b> Dans le cas où la dette rend notre investissement plus performant, on parle d'effet de levier. À l'inverse, si la dette vient diminuer votre rentabilité, alors on parle d'effet de massue. Lorsque l’effet de levier est de 5% par exemple, cela signifie que le recours à l’emprunt permet d’améliorer la performance de l’investissement de 5%. Pour qu’il y ait effet de levier il faut que le coût de l’emprunt soit inférieur à la rentabilité de l’investissement. </span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{selectedInvest.result.performance.avecDette.effetlevier}} %</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div  class="mt-10">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case">Financement de l'investissement :</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base"> Ces données correspondent au coût total de votre investissement sur la durée choisie. Il comprend le prix d’acquisition mais également tous les frais liés. Il vous permet d’analyser de quelle manière vous avez payé toutes ces charges : par les loyers ou par votre épargne.</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div>
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case pl-4">Par les revenus locatifs</h3>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case">{{Math.round(Number(selectedInvest.result.resTotRevLoc))}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div>
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case pl-4">Par l'apport d'épargne</h3>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case">{{Math.round(Number(selectedInvest.result.resTotSommeInvest * -1))}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div class="hidden sm:block mt-10">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>
			
			<div>
				<div class="bg-lightblueaestima-100 py-4 px-2 rounded-md">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0 relative">
							<h3 class="inline-block text-lg font-bold leading-6 text-white normal-case">CASH FLOWS SUR L'OPERATION</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div class="hidden sm:block">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>
			
			<div>
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case">Flux de trésorerie annuel moyen jusqu'à la fin de l'investissement</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base"><b> Les flux de trésorerie (ou Cash-flows en anglais) représentent la somme d’argent que l’investisseur gagnera ou devra sortir de sa poche chaque année ou chaque mois pour financer le bien et ses charges. </b> On entend souvent que si le loyer nous permet de payer l’emprunt, on ne perd pas d’argent. Mais on oublie généralement toutes les autres dépenses relatives au bien (taxe foncière, charges de copropriété, etc.).</span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{fluxT}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div>
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case pl-4">Equivalence mensuelle </h3>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case">{{eqM}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div class="mt-10">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0 relative">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case">Votre effort d'épargne (-) ou gain en capital (+) total pour cet investissement s'élève à : 
								<div class="tooltip inline-block">
									<QuestionMark/>
									<span class="tooltiptext text-base"><b> On parle d’effort d’épargne dès que les flux de trésorerie de l’opération sont négatifs. </b> L’investisseur devra alors régulièrement rajouter des fonds à son investissement pour payer l’ensemble des charges.</span>
								</div>
							</h3>
							<!-- <h3 v-if="Math.round(Number(selectedInvest.result.resTotSommeInvest)) >= 0" class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case">Votre gain en capital total pour cet investissement s'élève à :</h3>
							<h3 v-else class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case">Votre effort d'épargne total pour cet investissement s'élève à :</h3> -->
							
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{Math.round(Number(selectedInvest.result.resTotSommeInvest))}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div  class="mt-10">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case">Total d’impôts payés pour cet investissement</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base"> Vous retrouverez ici le montant d’impôt total payé pour cet investissement. Il comprend l’impôt sur le revenu ou les sociétés, selon votre statut ainsi que l’impôt sur la plus-value lors de la revente.</span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{-1 * Math.round(Number(selectedInvest.result.resTotImposition))}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div>
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case pl-4">Dont imposition sur la plus-value immobilière</h3>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-medium leading-6 text-gray-900 normal-case">{{-1 * iPV}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div  class="mt-10">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case">Perte de revenus liée à la vacance locative</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base"> Vous avez ici une précision concernant la valeur des loyers perdus à cause du manque de locataires. Plus le pourcentage saisi lors de la simulation est fort et plus ce montant est élevé.</span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{Math.round(Number(selectedInvest.result.resTotPertes))}} €</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div class="hidden sm:block mt-10">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>
			
			<div class="bg-lightblueaestima-100 py-4 px-2 rounded-md">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-white normal-case">PERFORMANCES FINANCIÈRES DE L'INVESTISSEMENT</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div class="hidden sm:block">
				<div class="py-5">
					<div class="border-t border-gray-200"></div>
				</div>
			</div>
			
			<div>
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0 relative">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case pl-4">Rentabilité de l'opération avant emprunt(s) et fiscalité</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base">Ce ratio nous permet de connaître <b> la rentabilité de l’investissement en tenant compte de sa durée.</b> Aussi appelé le taux de rendement interne (TIR) en finance, c’est un ratio très important car il tient compte de l’actualisation (dépréciation de la valeur de l’argent dans le temps) dans son calcul. Donc, plus un investissement est long et plus le TIR sera faible. </span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{selectedInvest.result.performance.sansDette.tir}} %</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div class="mt-4">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case pl-4">Rentabilité après emprunt(s)</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base">Ce taux de rentabilité vous permet de comparer la performance de votre investissement avant et après l’emprunt bancaire. Si le taux est plus important que le TIR avant dettes alors l’emprunt aura amélioré votre investissement. </span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{selectedInvest.result.performance.avecDette.tir}} %</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div class="mt-4">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case pl-4">Rentabilité après emprunt(s) et fiscalité</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base">C’est ce taux qu’il faudra retenir pour déterminer la rentabilité de votre investissement. Ce taux de rentabilité vous permet de voir la performance finale de votre investissement après le paiement de vos impôts liés au bien. </span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{selectedInvest.result.performance.avecFisc.tir}} %</h3>
						</div>
					</div>
				</div>
			</div>
			
			<!-- HIDDEN -->
			<div class="mt-4 hidden">
				<div class="md:grid md:grid-cols-2 md:gap-6">
					<div class="md:col-span-1">
						<div class="px-4 sm:px-0">
							<h3 class="inline-block text-lg font-bold leading-6 text-gray-900 normal-case pl-4">Pour chaque euro investi, vous avez gagné</h3>
							<div class="tooltip inline-block">
								<QuestionMark/>
								<span class="tooltiptext text-base">Cet indicateur vous montre d’une manière originale la performance des fonds que vous avez investi dans cet investissement. En terme financier, on parle d’indice de profitabilité. Il répond à la question : par combien ont été multipliés les fonds que j’ai investi dans ce projet. Si la réponse est 2,5 alors pour 1€ investi vous aurez récupéré 2,5€.</span>
							</div>
						</div>
					</div>
					<div class="md:col-span-1 text-right">
						<div class="px-4 sm:px-0">
							<h3 class="text-lg font-bold leading-6 text-gray-900 normal-case">{{selectedInvest.result.performance.avecFisc.indprof}}</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div class="flex flex-col mt-10 mb-10">
				<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div class="shadow border-b border-gray-200 sm:rounded-lg">
						<table class="min-w-full divide-y divide-gray-200">
						<thead>
							<tr>
								<th scope="col" class="text-center px-6 py-3 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider">
									{{thead[0]}}
								</th>
								<th scope="col" class="text-center px-6 py-3 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider">
									{{thead[1]}}
									</br>
									<div class="tooltip block">
										<QuestionMark/>
										<span class="tooltiptext text-base">Vos loyers annuels + taux d'IRL.</span>
									</div>
								</th>
								<th scope="col" class="text-center px-6 py-3 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider">
									{{thead[2]}}
									</br>
									<div class="tooltip block">
										<QuestionMark/>
										<span class="tooltiptext text-base">Ensemble des charges locatives (taxe foncière, PNO, gestion locative, etc.).</span>
									</div>
								</th>
								<th scope="col" class="text-center px-6 py-3 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider">
									{{thead[3]}}
									</br>
									<div class="tooltip block">
										<QuestionMark/>
										<span class="tooltiptext text-base">Ensemble des charges liées à votre SCI ou votre montage LMNP (CFE et comptabilité).</span>
									</div>
								</th>
								<th scope="col" class="text-center px-6 py-3 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider">
									{{thead[4]}}
									</br>
									<div class="tooltip block">
										<QuestionMark/>
										<span class="tooltiptext text-base">Année 1 : Frais de dossier d'ouverture de l'emprunt + annuité constante + frais de garanties bancaires + montant annuel d'assurance emprunteur. Année 2 et autres : annuités + assurance emprunteur.</span>
									</div>
								</th>
								<th scope="col" class="text-center px-6 py-3 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider">
									{{thead[5]}}
									</br>
									<div class="tooltip block">
										<QuestionMark/>
										<span class="tooltiptext text-base">Montant des gros travaux (inscrits à l'année où vous les avez prévus).</span>
									</div>
								</th>
								<th scope="col" class="text-center px-6 py-3 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider">
									{{thead[6]}}
									</br>
									<div class="tooltip block">
										<QuestionMark/>
										<span class="tooltiptext text-base">Montant d'impôts que vous devrez payer chaque année. Pour l'année de revente, ce montant est majoré, si il y a lieu, de l'impôt sur la plus-value immobilière.</span>
									</div>
								</th>
								<th scope="col" class="text-center px-6 py-3 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider">
									{{thead[7]}}
									</br>
									<div class="tooltip block">
										<QuestionMark/>
										<span class="tooltiptext text-base">Ce qui sort de votre poche (-) ou qui rentre (+) chaque année. Cette valeur est impactée par le taux d'actualisation que vous avez saisi. (apport fonds propres - montant CAPEX + frais de notaires).</span>
									</div>
								</th>
								<th scope="col" class="text-center px-6 py-3 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider">
									{{thead[8]}}
								</th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr v-for="(res,index) in getResult" :key="index">
								<td class="p-2  whitespace-nowrap">
									<span class="px-2 inline-flex text-xs leading-5 font-bold rounded-full">
									Année {{index + 1}}
									</span>
									<div v-if="index + 1 == 1" class="tooltip inline-block">
										<QuestionMark/>
										<span class="tooltiptext text-base">L'année 1 = Période 1. La période débute lors du premier mois de l'acquisition et dure 12 mois.</br> <b>Exemple :</b> si le bien est acquis en Juin 2022, année 1 = Juin 2022 --> Juin 2023.</span>
									</div>
								</td>
								<td class="px-6  whitespace-nowrap">
									<span class="px-2 inline-flex text-xs leading-5 font-normal rounded-full">
									{{res[1]}} €
									</span>
								</td>
								<td class="px-6  whitespace-nowrap">
									<span class="px-2 inline-flex text-xs leading-5 font-normal rounded-full">
									{{Math.round(res[2])}} €
									</span>
								</td>
								<td class="px-6  whitespace-nowrap">
									<span class="px-2 inline-flex text-xs leading-5 font-normal rounded-full">
									{{res[3] * -1}} €
									</span>
								</td>
								<td class="px-6  whitespace-nowrap">
									<span class="px-2 inline-flex text-xs leading-5 font-normal rounded-full">
									{{res[4]}} €
									</span>
								</td>
								<td class="px-6  whitespace-nowrap">
									<span class="px-2 inline-flex text-xs leading-5 font-normal rounded-full">
									{{res[5]}} €
									</span>
								</td>
								<td class="px-6  whitespace-nowrap">
									<span class="px-2 inline-flex text-xs leading-5 font-normal rounded-full">
									{{res[6]}} €
									</span>
								</td>
								<td class="px-6  whitespace-nowrap">
									<span class="px-2 inline-flex text-xs leading-5 font-bold rounded-full">
									{{res[7]}} €
									</span>
								</td>
								<td class="px-6  whitespace-nowrap">
									<span class="px-2 inline-flex text-xs leading-5 font-bold rounded-full">
									{{res[8]}} €
									</span>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<th scope="col" class="px-6 py-3 bg-green-100 text-green-800 text-left text-xs font-bold uppercase tracking-wider">
									{{tfoot[0]}}
								</th>
								<th scope="col" class="px-6 py-3 bg-green-100 text-green-800 text-left text-xs font-bold uppercase tracking-wider">
									{{tfoot[1]}} €
								</th>
								<th scope="col" class="px-6 py-3 bg-green-100 text-green-800 text-left text-xs font-bold uppercase tracking-wider">
									{{tfoot[2]}} €
								</th>
								<th scope="col" class="px-6 py-3 bg-green-100 text-green-800 text-left text-xs font-bold uppercase tracking-wider">
									{{tfoot[3] * -1}} €
								</th>
								<th scope="col" class="px-6 py-3 bg-green-100 text-green-800 text-left text-xs font-bold uppercase tracking-wider">
									{{tfoot[4]}} €
								</th>
								<th scope="col" class="px-6 py-3 bg-green-100 text-green-800 text-left text-xs font-bold uppercase tracking-wider">
									{{tfoot[5]}} €
								</th>
								<th scope="col" class="px-6 py-3 bg-green-100 text-green-800 text-left text-xs font-bold uppercase tracking-wider">
									{{tfoot[6]}} €
								</th>
								<th scope="col" class="px-6 py-3 bg-green-100 text-green-800 text-left text-xs font-bold uppercase tracking-wider">
									{{tfoot[7]}} €
								</th>
								<th scope="col" class="px-6 py-3 bg-green-100 text-green-800 text-left text-xs font-bold uppercase tracking-wider">
									{{tfoot[8]}}
								</th>
							</tr>
						</tfoot>
						</table>
					</div>
					</div>
				</div>
			</div>
			
			<!-- <pre>{{getResult}}</pre> -->

			<!-- <pre>{{selectedInvest}}</pre> -->
			
			<!-- This example requires Tailwind CSS v2.0+ -->
			<div v-if="modalPDF == true" class="absolute inset-0 overflow-hidden z-20">
				<div class="absolute inset-0 overflow-hidden">
					<!--
					Background overlay, show/hide based on slide-over state.

					Entering: "ease-in-out duration-500"
						From: "opacity-0"
						To: "opacity-100"
					Leaving: "ease-in-out duration-500"
						From: "opacity-100"
						To: "opacity-0"
					-->
					<div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
					<section class="absolute inset-y-0 right-0 pl-10 max-w-full flex" aria-labelledby="slide-over-heading">
					<!--
						Slide-over panel, show/hide based on slide-over state.

						Entering: "transform transition ease-in-out duration-500 sm:duration-700"
						From: "translate-x-full"
						To: "translate-x-0"
						Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
						From: "translate-x-0"
						To: "translate-x-full"
					-->
					<div class="relative w-screen max-w-xl">
						<!--
						Close button, show/hide based on slide-over state.

						Entering: "ease-in-out duration-500"
							From: "opacity-0"
							To: "opacity-100"
						Leaving: "ease-in-out duration-500"
							From: "opacity-100"
							To: "opacity-0"
						-->
						<div class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
						<button @click="setModalPDF(false)" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
							<span class="sr-only">Close panel</span>
							<!-- Heroicon name: x -->
							<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
						</div>
						<div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
						<div class="px-4 sm:px-6">
							<h2 id="slide-over-heading" class="text-xl font-semibold text-gray-900">
							Informations avant votre export PDF
							</h2>
						</div>
						<div class="relative flex-1 px-4 sm:px-6">
							<!-- Replace with your content -->
							<div class="absolute inset-0 px-4 sm:px-6">
								<form class="mt-8" @submit.prevent="toPDF()">
									<div class="rounded-md shadow-sm">
										<div class="col-span-6 sm:col-span-4 mb-6">
											<label for="estimreval" class="block text-sm font-medium leading-5 text-gray-700">Nom de l'export PDF</label>
											<input
											required
											placeholder="export-1"
											v-model="dataPDF.namePDF" id="agence" type="text" maxlength="15" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</div>
										<div class="grid grid-cols-7 gap-6 mb-6">
											<div class="col-span-7 sm:col-span-3">
												<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">Nom de l'agence</label>
												<input
												required
												placeholder="Agence Immo"
												v-model="dataPDF.nameAgency" id="agence" type="text" maxlength="30" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
											</div>
											<div class="col-span-7 sm:col-span-4">
												<label for="mail" class="block text-sm font-medium leading-5 text-gray-700">Logo de l'agence <b class="text-red-600">(URL Adresse de l'image)</b></label>
												<input
												placeholder="Clique droit + Copier l'adresse de l'image"
												v-model="dataPDF.logoAgency" id="mail" type="text" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
											</div>
										</div>
										<div class="col-span-6 sm:col-span-4 mb-6">
											<label for="estimreval" class="block text-sm font-medium leading-5 text-gray-700">Présentation du bien</label>
											<textarea
											required
											placeholder="Votre présentation du bien..."
											rows="7"
											maxlength="425"
											v-model="dataPDF.pres" id="estimreval" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></textarea>
										</div>
										<div class="col-span-6 sm:col-span-4 mb-6">
											<label for="estimreval" class="block text-sm font-medium leading-5 text-gray-700">Synthèse de l'analyse</label>
											<textarea
											required
											placeholder="Votre synthèse..."
											rows="7"
											maxlength="1025"
											@keypress="synthKP()"
											v-model="dataPDF.synth" id="estimreval" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></textarea>
										</div>
									</div>

									<div class="mt-10">
										<button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out">
										<span class="absolute left-0 inset-y-0 flex items-center pl-3">
											<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
											width="14" height="14"
											viewBox="0 0 172 172"
											style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M72.76923,0c-10.95673,0 -19.84615,8.88942 -19.84615,19.84615v52.92308h-21.5c-9.32872,0 -11.29267,1.4988 -3.30769,9.50962l43.62019,43.62019c14.54868,14.54868 14.36779,14.60036 28.94231,0l43.41346,-43.62019c7.98497,-8.01081 5.32332,-9.50962 -3.10096,-9.50962h-21.91346v-52.92308c0,-10.95673 -8.88942,-19.84615 -19.84615,-19.84615zM0,125.69231v26.46154c0,10.95673 8.88942,19.84615 19.84615,19.84615h132.30769c10.95673,0 19.84615,-8.88942 19.84615,-19.84615v-26.46154h-13.23077v26.46154c0,3.64363 -2.97176,6.61538 -6.61538,6.61538h-132.30769c-3.64363,0 -6.61538,-2.97176 -6.61538,-6.61538v-26.46154z"></path></g></g></svg>
										</span>
										Télécharger pdf
										</button>
									</div>
									<svg v-if="modalPDFLoader == true"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;  margin-top:20px; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
										<circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25" stroke-dasharray="70.68583470577033 25.561944901923447">
										<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
										</circle>
									</svg>
								</form>
							</div>
							<!-- /End replace -->
						</div>
						</div>
					</div>
					</section>
				</div>
			</div>
	{{izi}}
	</div>
</template>

<script>
// @ is an alias to /src
import SignIn from '@/components/SignIn.vue'
import QuestionMark from '@/components/SVG/QuestionMark.vue'
import LeftSimulatorHome from '@/components/LeftSimulatorHome'
import { mapGetters, mapActions } from "vuex"
import axios from "axios"
import moment from 'moment';

export default {
	name: 'Investor',
	data() {
		return {
			fluxT: 0,
			eqM: 0,
			iPV: 0,
			resultArray: [],
			thead: [],
			tfoot: [],
			isFormPDF: true,
			dataPDF : {
				namePDF: '',
				nameAgency: '',
				nameClient: '',
				logoAgency: '',
				pres: '',
				synth: '',
				idProject: '',
				idInvest: '',
			}
		}
	},
	components: {
		SignIn,
		LeftSimulatorHome,
		QuestionMark
	},
	created () {
		// this.fluxTreso()
		// this.eqMensu()
		// this.impoPV()
	},
	metaInfo(){
		return {
			title: `Résultats de ${this.selectedInvest.name} - Aestima`,
			meta: [{
				name: 'description',
				content: "Cette page décrit les résultats de l'estimation d'un bien."
			}]
			//keywords
		}
	},
	computed: {
		...mapGetters({
			selectedProject: 'projects/selected',
			selectedInvest: 'invests/selected',
			modalPDF: 'invests/modalPDF',
			modalPDFLoader: 'invests/modalPDFLoader',
		}),
		izi(){
			let ft = 0
			let em = 0
			let iv = 0

			let dureedetention = this.selectedInvest.carac.dureedetention.isAuto ?
			Number(this.selectedInvest.carac.dureedetention.valAuto) / 12 :
			Number(this.selectedInvest.carac.dureedetention.val)
			
			for (let i = 0; i <= dureedetention; i++) {
				// if(i != 0 && i != dureedetention) {
				if(i != dureedetention) {
					ft += this.selectedInvest.result.resCashFlowActu[i]
					em += this.selectedInvest.result.resCashFlowActu[i]
				}
			}
			
			ft = Math.round(ft / (dureedetention))

			this.fluxT = ft
			
			em = Math.round(em / (dureedetention))
			
			em = Math.round(em / 12)
			
			this.eqM = em
			
			if(this.selectedInvest.result.resImposition[dureedetention]) {
				iv = this.selectedInvest.result.resImposition[dureedetention]
			} else {
				iv = 0
			}

			this.iPV = iv
		},
		getResult() {
			let isSCILMNP = false
			let fcomp = 0
			let fcot = 0
			let fstatpro = []
			this.resultArray = []
			let tmp = []
			// this.thead = [ "Période", "Revenus locatifs", "Frais d'exploitation", "Frais statut professionnel", "Total charges financières", "Investissement CAPEX", "Imposition", "Trésorerie actualisée", "Trésorerie cumulée actualisée"]
			this.thead = [ "Période", "Revenus locatifs", "Frais d'exploitation", "Frais statut professionnel", "Flux financiers", "Investissement CAPEX", "Imposition", "Trésorerie actualisée", "Trésorerie cumulée actualisée"]

			let dateacquiSplit = this.selectedInvest.carac.dateacqui.split('-')
			
			// let duremp = this.selectedInvest.carac.dureeempr.isAuto == true ? Number(this.selectedInvest.carac.dureeempr.valAuto / 12) : Number(this.selectedInvest.carac.dureeempr.val)
			let duremp = this.selectedInvest.carac.dureedetention.isAuto == true ? Number(this.selectedInvest.carac.dureedetention.valAuto / 12) : Number(this.selectedInvest.carac.dureedetention.val)
			this.selectedInvest.carac.formejuridique.map((val) => {
				if(val.selected == true && (val.text == "Montage d'une SCI (imposée à l'IS)" || val.text == "Location LMNP (imposé à l'IR)")){
					isSCILMNP = true
				}
			})
			
			fcomp = this.selectedInvest.carac.fcomptable.isAuto == true ? this.selectedInvest.carac.fcomptable.valAuto : this.selectedInvest.carac.fcomptable.val
			fcot = this.selectedInvest.carac.fcotisation.isAuto == true ? this.selectedInvest.carac.fcotisation.valAuto : this.selectedInvest.carac.fcotisation.val
			for (let i = 0; i <= duremp; i++) {
				tmp.push(Number(dateacquiSplit[0]) + i)
				tmp.push(this.selectedInvest.result.resRevLoc[i])
				tmp.push(this.selectedInvest.result.resFraisExploit[i])
				if(isSCILMNP) {
					tmp.push(Number(fcomp) + Number(fcot))
					fstatpro.push(Number(fcomp) + Number(fcot))
				} else {
					tmp.push(0)
					fstatpro.push(0)
				}
				tmp.push(this.selectedInvest.result.resChargFin[i])
				tmp.push(this.selectedInvest.result.resInvTrav[i])
				tmp.push(this.selectedInvest.result.resImposition[i])
				tmp.push(this.selectedInvest.result.resCashFlowActu[i])
				tmp.push(this.selectedInvest.result.resCashFlowCumul[i])
				
				this.resultArray.push(tmp)
				tmp = []
			}
			
			const reducer = (accumulator, currentValue) => accumulator + currentValue;
			
			this.tfoot = [ "TOTAL", Math.round(Number(this.selectedInvest.result.resTotRevLoc)), Math.round(Number(this.selectedInvest.result.resTotFraisExploit)), Math.round(Number(fstatpro.reduce(reducer))), Math.round(Number(this.selectedInvest.result.resTotChargFin)), Math.round(Number(this.selectedInvest.result.resTotalInvTrav)), Math.round(Number(this.selectedInvest.result.resTotImposition)), Math.round(Number(this.selectedInvest.result.resTotCashFlowActu)), ""]

			return this.resultArray
		}
	
	},
    methods: {
		...mapActions({
			exportPDF: 'invests/exportPDF',
			setModalPDF: 'invests/setModalPDF',
			setModalPDFLoader: 'invests/setModalPDFLoader',
      setUserRefreshed: 'users/setUserRefreshed',
		}),
		fluxTreso(){
			let imp = 0
			let dureedetention = this.selectedInvest.carac.dureedetention.isAuto ?
			Number(this.selectedInvest.carac.dureedetention.valAuto) / 12 :
			Number(this.selectedInvest.carac.dureedetention.val)
			for (let i = 0; i <= dureedetention; i++) {
				// if(i != 0 && i != dureedetention) {
				if(i != dureedetention) {
					imp += this.selectedInvest.result.resCashFlowActu[i]
				}
			}
			
			imp = Math.round(imp / (dureedetention))
						
			return this.fluxT = imp
		},
		eqMensu(){
			let imp = 0
			let dureedetention = this.selectedInvest.carac.dureedetention.isAuto ?
			Number(this.selectedInvest.carac.dureedetention.valAuto) / 12 :
			Number(this.selectedInvest.carac.dureedetention.val)
			for (let i = 0; i <= dureedetention; i++) {
				// if(i != 0 && i != dureedetention) {
				if(i != dureedetention) {
					imp += this.selectedInvest.result.resCashFlowActu[i]
				}
			}
			
			imp = Math.round(imp / (dureedetention))
			
			imp = Math.round(imp / 12)
			
			return this.eqM = imp
		},
		impoPV() {
			let imp = 0
			let dureedetention = this.selectedInvest.carac.dureedetention.isAuto ?
			Number(this.selectedInvest.carac.dureedetention.valAuto) / 12 :
			Number(this.selectedInvest.carac.dureedetention.val)
			if(this.selectedInvest.result.resImposition[dureedetention]) {
				imp = this.selectedInvest.result.resImposition[dureedetention]
			} else {
				imp = 0
			}

			return this.iPV = imp
		},
		synthKP() {
			if (event.keyCode == 13) {
				return event.preventDefault();
			}
		},
		toPDF() {
			this.dataPDF.nameClient = this.selectedInvest.name
			this.dataPDF.idProject = this.selectedProject._id
			this.dataPDF.idInvest = this.selectedInvest._id
			this.setModalPDFLoader(true)
			this.exportPDF(this.dataPDF)
			.then((response) => {
				const blob = new Blob([response.data], {type: 'application/pdf'})
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `${this.dataPDF.namePDF}.pdf`
				link.click()
				this.setModalPDFLoader(false)
				this.setModalPDF(false)

			})
			.catch(err => {
        console.log(this.user)
				console.log(err)
			})
		},
	}
}
</script>

<style scoped>
.input-symbol-euro {
    position: relative;
}
.input-symbol-euro input {
    padding-left:25px;
}
.input-symbol-euro:before {
    position: absolute;
	top: 7px;
    content:"€";
    left: 5px;
}

.input-symbol-percentage {
    position: relative;
}
.input-symbol-percentage input {
    padding-left:25px;
}
.input-symbol-percentage:before {
    position: absolute;
	top: 7px;
    content:"%";
    left: 5px;
}

.input-symbol-year {
    position: relative;
}
.input-symbol-year input {
    padding-left:25px;
}
.input-symbol-year:before {
    position: absolute;
	top: 7px;
    content:"mois";
    right: 150px;
}


select:not(.forme-jur) {
	background-color: #14A0EB !important;
	color: #FFFFFF;
	font-weight: bold;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
}

.bg-3D-btn {
	transition: 0.1s;
	color:black;
	box-shadow: -4px 4px #14A0EB, -3px 3px #14A0EB, -2px 2px #14A0EB;
	border: 1px solid #14A0EB;
	border-radius: 3px;
}

.bg-3D-btn:active {
	box-shadow: -2px 2px #14A0EB, -1px 1px #14A0EB, -1px 1px #14A0EB;
}

.tooltip {
	margin-left: 0.3rem;
	/* position: relative; */
	/* display: inline-block; */
	border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 20rem;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 10px;

	/* Position the tooltip */
	position: absolute;
	z-index: 1;
	left: 0;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

@media (min-width: 768px) {
	.tooltip {
		position: relative;
		display: inline-block;
		border-bottom: 1px dotted black;
	}

	.tooltip .tooltiptext {
		/* visibility: hidden; */
		width: 30rem;
		background-color: black;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 10px;

		/* Position the tooltip */
		position: absolute;
		z-index: 1;
	}
}
</style>
