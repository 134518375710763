<template>
	<svg xmlns="http://www.w3.org/2000/svg"
	class="h-5 w-5 ml- text-blueaestima-500 group-hover:blueaestima-500 transition ease-in-out duration-150"
	viewBox="0 0 256 256">
		<rect width="256" height="256" fill="none" />
		<polyline
			points="86 110 128 152 170 110"
			fill="none"
			stroke="#fff"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="24"
		/>
		<line
			x1="128"
			y1="40"
			x2="128"
			y2="152"
			fill="none"
			stroke="#fff"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="24"
		/>
		<path
			d="M216,152v56a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V152"
			fill="none"
			stroke="#fff"
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="24"
		/>
	</svg>
</template>

<script>
export default {
	name: 'DownloadIcon',
};
</script>
