<template>
  <div class="flex flex-col w-full gap-5 max-w-3xl align-middle mx-auto">
    <form class="mx-10 md:text-right" @submit.prevent="toUpdate()">
      <div class="mt-3 text-left rounded-md shadow-sm">
        <div class="mb-6 gap-6">
          <div class="">
            <label for="name" class="block text-sm font-medium text-gray-700 leading-5">Nom</label>
            <input
              id="name"
              v-model="userUpdate.name"
              type="text"
              maxlength="30"
              class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
          </div>
          <div class="">
            <label for="firstname" class="block text-sm font-medium text-gray-700 leading-5">Prénom</label>
            <input
              id="firstname"
              v-model="userUpdate.firstname"
              type="text"
              maxlength="30"
              class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >
          </div>
        </div>
      </div>
      <div class="mt-10">
        <button
          type="submit"
          class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent group leading-5 rounded-md bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out"
        >
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="14"
              height="14"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g
                fill="none"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style="mix-blend-mode: normal"
              >
                <path d="M0,172v-172h172v172z" fill="none" />
                <g fill="#ffffff">
                  <path
                    d="M86,17.2c-20.4508,0 -34.4,15.00037 -34.4,36.70677c0,14.81972 7.46041,31.02657 17.2,40.67083v14.35573c-5.73333,17.2 -51.6,5.73333 -51.6,45.86667h137.6c0,-40.13333 -45.86667,-28.66667 -51.6,-45.86667v-14.35573c9.73959,-9.64427 17.2,-25.85111 17.2,-40.67083c0,-21.7064 -13.9492,-36.70677 -34.4,-36.70677z"
                  />
                </g>
              </g>
            </svg>
          </span>
          Enregistrer
        </button>
      </div>
    </form>
    <form v-if="user.coworkerLimit" class="md:text-right" @submit.prevent="">
      <div class="p-10 mt-4 text-left rounded-lg shadow-sm">
        <h3 class="mb-4 font-bold">
          Collaborateurs
        </h3>
        <div v-for="coworker in coworkers" :key="coworker.id" class="flex text-sm items-center justify-between w-full p-4 mb-4 bg-blue-50 rounded-lg shadow-md">
          <div class="flex items-center">
            <VSwatches v-model="coworker.color">
              <div id="pastille" slot="trigger" :value="coworker.color" readonly class="form__input__element flex items-center justify-center w-10 h-10 mr-4 rounded-full shadow-md cursor-pointer" :style="{ backgroundColor: coworker.color }">
                <div class="font-bold leading-none text-white">
                  {{ initials(coworker.name) }}
                </div>
              </div>
            </VSwatches>
            <input v-model="coworker.name" class="font-semibold bg-blue-50 rounded-md px-1 py-0.5" type="text" placeholder="Nom">
          </div>
          <div class="flex flex-col text-center gap-2 ">
            <input v-model="coworker.email" class="bg-blue-50 rounded-md px-1 py-0.5" type="email" placeholder="Email">
            <input v-model="coworker.phone" class="bg-blue-50 rounded-md px-1 py-0.5" type="tel" placeholder="Téléphone">
          </div>
          <button class="flex items-center justify-center w-10 h-10 mr-4 rounded-full shadow-md bg-red-100" @click="deleteCoworker(coworker.id)">
            <svg class="w-6 h-6 text-red-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M5.293 6.293a1 1 0 011.414 0L10 9.586l3.293-3.293a1 1 0 111.414 1.414L11.414
                    11l3.293 3.293a1 1 0 01-1.414 1.414L10 12.414l-3.293
                    3.293a1 1 0 01-1.414-1.414L8.586 11 5.293 7.707a1 1 0
                    010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div v-if="coworkers.length < user.coworkerLimit" class="flex items-center justify-between w-full p-4 mb-4 bg-blue-50 rounded-lg shadow-md">
          <button class="flex items-center justify-center w-10 h-10 mr-4 rounded-full shadow-md bg-lightblueaestima-100" @click="createCoworker()">
            <svg class="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M10 3a1 1 0 00-1 1v5H4a1 1 0 100 2h5v5a1 1 0 102 0v-5h5a1 1 0 100-2h-5V4a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </form>

    <Subscribe id="subscribe"/>

    <form class="md:text-right" @submit.prevent="toUpdateMDP()">
      <div class="p-10 mt-4 text-left rounded-lg shadow-sm">
        <h3 class="mb-4 font-bold">
          Changer de mot de passe ?
        </h3>
        <div class="mb-6 gap-6">
          <div class="">
            <label for="password" class="block text-sm font-medium text-gray-700 leading-5">Mot de passe</label>
            <div class="relative">
              <input
                id="password"
                v-model="userUpdateMDP.password"
                required
                type="password"
                maxlength="30"
                class="z-0 block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                @click="setTypeBoolean('showPassword')"
              >
              <i
                v-if="showPassword === true"
                class="absolute z-10 text-sm cursor-pointer right-3 top-2 hover:font-medium"
                @click="changeType('password')"
              >Afficher</i>
            </div>
          </div>
          <div class="">
            <label for="cpassword" class="block text-sm font-medium text-gray-700 leading-5">Confirmation Mot de passe</label>
            <div class="relative">
              <input
                id="cpassword"
                v-model="userUpdateMDP.cpassword"
                required
                type="password"
                class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                @click="setTypeBoolean('showCPassword')"
              >
              <i
                v-if="showCPassword === true"
                class="absolute z-10 text-sm cursor-pointer right-3 top-2 hover:font-medium"
                @click="changeType('cpassword')"
              >Afficher</i>
            </div>
          </div>
        </div>

        <div
          v-if="isSamePasword === true"
          class="inline-flex items-center justify-center w-full px-4 py-2 mt-6 text-xs font-semibold text-red-800 bg-red-100 border border-red-800 border-solid rounded-lg leading-5"
        >
          <svg
            class="w-10 h-10 p-2 mr-2 text-red-800 bg-white rounded-full"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span>Vos deux mots de passe doivent être identiques !</span>
        </div>

        <div class="mt-10">
          <button
            type="submit"
            class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent group leading-5 rounded-md bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out"
          >
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="14"
                height="14"
                viewBox="0 0 172 172"
                style="fill: #000000"
              >
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <path d="M0,172v-172h172v172z" fill="none" />
                  <g fill="#ffffff">
                    <path
                      d="M86,6.61538c-21.99099,0 -39.69231,17.70132 -39.69231,39.69231v13.23077c-14.54868,0 -26.46154,11.91286 -26.46154,26.46154v52.92308c0,14.54868 11.91286,26.46154 26.46154,26.46154h79.38462c14.54868,0 26.46154,-11.91286 26.46154,-26.46154v-52.92308c0,-14.54868 -11.91286,-26.46154 -26.46154,-26.46154v-13.23077c0,-21.99099 -17.70132,-39.69231 -39.69231,-39.69231zM86,19.84615c15.06551,0 26.46154,11.39603 26.46154,26.46154v13.23077h-52.92308v-13.23077c0,-15.06551 11.39603,-26.46154 26.46154,-26.46154zM86,80.00481c11.24099,0 19.84615,8.60517 19.84615,19.84615c0,7.93329 -5.29747,15.34976 -13.23077,17.98558v7.85577h-13.23077v-7.23558c0,-5.94351 3.38522,-10.62079 8.68269,-12.61058c2.63582,-0.67187 4.54808,-3.35937 4.54808,-5.99519c0,-3.97956 -2.63581,-6.61538 -6.61538,-6.61538c-3.97956,0 -6.61538,2.63582 -6.61538,6.61538h-13.23077c0,-11.24099 8.60517,-19.84615 19.84615,-19.84615zM79.38462,132.30769h13.23077v13.23077h-13.23077z"
                    />
                  </g>
                </g>
              </svg>
            </span>
            Changer de mot de passe
          </button>
        </div>
      </div>
    </form>

    <form class="md:text-right" @submit.prevent="toUpdate()">
      <div class="flex flex-col p-10 mt-4 text-left rounded-lg shadow-sm gap-2">
        <span class="mb-4 font-bold">Nom de votre société</span>
        <input
          v-model="userUpdate.info_society"
          type="text"
          class="w-full px-4 py-2 text-sm font-medium text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none leading-5 transition duration-150 ease-in-out shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
          placeholder="Nouveau nom de votre société"
        >
        <button
          type="submit"
          class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent group leading-5 rounded-md bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out"
        >
          <span class="absolute inset-y-0 left-0 flex items-center pl-3 font-bold">$</span>
          Changer de nom de société
        </button>
      </div>
    </form>

    <form class="md:text-right" @submit.prevent="toUpdateLogo()">
      <div class="flex flex-col p-10 mt-4 text-left rounded-lg shadow-sm gap-2">
        <span class="mb-4 font-bold">Logo de votre Société</span>
        <img v-if="customLogo" :src="customLogo" class="flex rounded-lg place-self-center w-60">
        <label
          for="logo"
          class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent group leading-5 rounded-md bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out"
          :class="
            file
              ? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110'
              : 'bg-white'
          "
        >Parcourir</label>
        <input
          id="logo"
          hidden
          type="file"
          name="logo"
          accept=".jpg,.png,.pdf"
          @change="uploadLogo($event)"
        >
        <button
          v-if="file"
          type="submit"
          class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent group leading-5 rounded-md bg-darkblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out"
        >
          Valider le changement de logo
        </button>
      </div>
    </form>

    <div class="mt-3">
      <button class="px-6 py-2 font-bold text-white bg-red-700 rounded-md" @click="activePopUp(true)">
        Supprimer mon compte
      </button>
    </div>

    <!-- MODAL -->
    <div v-if="isModal === true" class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div
          class="inline-block overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10"
              >
                <svg
                  class="w-6 h-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 id="modal-headline" class="text-lg font-medium text-gray-900 leading-6">
                  Attention !
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ messageModal }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              @click="deleteAccount()"
            >
              Supprimer
            </button>
            <button
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="isModal = false"
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
    <PDialog :visible.sync="displayDialog" :closable="false" :modal="true" header="Mot de passe requis" class="flex place-content-center">
      <div class="flex flex-col items-center gap-7 w-80">
        <div>
          <p>Veuillez entrez votre mot de passe administrateur pour acceder au profil.</p>
          <PPassword toggleMask :feedback="false" v-model="inputLockPassword" class="mt-2" @keypress.enter="checkLockPassword"/>
        </div>
        <div class="flex gap-4">
          <router-link to="/Home">
            <PButton class="flex p-button-danger p-button-outlined" label="Annuler" icon="pi pi-times" @click="displayDialog = false" />
          </router-link>
          <PButton v-if="inputLockPassword" class="flex" label="Valider" icon="pi pi-check" @click="checkLockPassword" />
        </div>
      </div>
    </PDialog>
    <PToast />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import VSwatches from 'vue-swatches'
import Subscribe from '@/views/payment/Subscribe.vue'
import { debounce } from '@/tools/debounce'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  name: 'Profile',
  components: {
    Subscribe,
    VSwatches,
  },
  props: {},
  data() {
    return {
      file: null,
      customLogo: '',
      userUpdate: {
        name: '',
        firstname: '',
        info_society: '',
      },
      userUpdateMDP: {
        password: '',
        cpassword: '',
      },
      isSamePasword: false,
      showPassword: false,
      showCPassword: false,
      isModal: false,
      messageModal:
        'Voulez-vous supprimer votre compte ? Cette décision sera irrémédiable et entrainera la perte de vos crédits.',
      coworkers: [],
      displayDialog: false,
      inputLockPassword: '',
    }
  },
  metaInfo() {
    return {
      title: 'Mon Profil - Aestima',
      meta: [
        {
          name: 'description',
          content: 'Cette page est la page de profil de l\'utilisateur.',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
      user: 'users/user',
      listProj: 'projects/projects',
      globals: 'projects/globals',
    }),
  },
  watch: {
    coworkers: {
      handler() {
        this.startUpdateCoworkers()
      },
      deep: true,
    },
  },
  created() {
    this.userUpdate.name = this.user.name
    this.userUpdate.firstname = this.user.firstname
    this.customLogo = this.user.customLogo
    this.userUpdate.info_society = this.user.info_society
    this.startUpdateCoworkers = debounce(this.startUpdateCoworkers, 1000)
    this.coworkers = this.user.coworkers
    if (this.user.coworkerLimit > 0) {
      this.displayDialog = true
    }
  },
  methods: {
    ...mapActions({
      signOut: 'users/logout',
      getProjects: 'projects/getProjects',
      setProjectSelected: 'projects/setProjectSelected',
      updateUser: 'users/updateUser',
      updateUserMDP: 'users/updateUserMDP',
      deleteUser: 'users/deleteUser',
      updateCoworkers: 'users/updateCoworkers',
    }),
    async checkLockPassword() {
      try {
        await axios.patch(`/user/lock_password/validate/${this.user._id}`, { inputLockPassword: this.inputLockPassword })
        this.displayDialog = false
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Erreur', detail: "Votre mot de passe est incorrect", life: 3000 })
        this.inputLockPassword = ''
        setTimeout(() => {
          this.$router.push({ name: 'Home' })
        }, 1500)
      }
    },
    uploadLogo(event) {
      this.file = event.target.files[0]
    },
    toUpdateLogo() {
      const formData = new FormData()
      formData.append('logo', this.file)
      axios.post(`user/logo/${this.user._id}`, formData).then((res) => {
        this.customLogo = res.data.path
      })
    },
    initials(name) {
      if (!name) return ''
      const first = name.split(' ')[0].toUpperCase()[0]
      if (!name.split(' ')[1]) return first
      const second = name.split(' ')[1].toUpperCase()[0]
      return `${first}${second}`
    },
    activePopUp(boolean) {
      this.isModal = boolean
    },
    setTypeBoolean(show) {
      this[show] = true
    },
    changeType(id) {
      document.getElementById(id).focus()
      if (document.getElementById(id).type === 'password') {
        document.getElementById(id).type = 'text'
      } else {
        document.getElementById(id).type = 'password'
      }
    },
    toUpdate() {
      const updatedUser = {
        ...this.user,
        name: this.userUpdate.name,
        firstname: this.userUpdate.firstname,
      }
      this.updateUser({
        id: this.user._id,
        user: updatedUser,
      })
    },
    toUpdateMDP() {
      if (this.userUpdateMDP.password !== this.userUpdateMDP.cpassword) {
        this.isSamePasword = true
        setTimeout(() => {
          this.isSamePasword = false
        }, 3000)
      } else {
        this.updateUserMDP({
          id: this.user._id,
          password: this.userUpdateMDP,
        })
      }
    },
    createCoworker() {
      const id = uuidv4()
      this.coworkers.push({
        id,
        name: 'Prénom Nom',
        email: 'email@email.com',
        color: '#F87171',
        phone: '06 12 34 56 78',
      })
    },
    startUpdateCoworkers() {
      this.updateCoworkers({
        id: this.user._id,
        coworkers: this.coworkers,
      })
    },
    deleteCoworker(id) {
      this.coworkers = this.coworkers.filter(collaborateur => collaborateur.id !== id)
    },
    deleteAccount() {
      this.deleteUser({
        userId: this.user._id,
      })
        .then(() => {
          this.activePopUp(false)
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
}
</script>

<style scoped>
.shadow-inner {
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.12);
}

.box-billing {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 32px;
}

.box-border {
  border-radius: 4px;
  border: 1px solid rgb(49, 52, 62);
}

.box-bt {
  border-top: 1px solid rgb(49, 52, 62);
}
</style>
