import Vue from 'vue'
import VueRouter from 'vue-router'
import LogIn from '../views/LogIn.vue'
import Home from '../views/Home.vue'
import Simulator from '../views/Simulator.vue'
import SimulatorNew from '../views/SimulatorNew.vue'
import InvestorNew from '../views/InvestorForm.vue'
import InvestorResult from '../views/InvestorResult.vue'
import Profile from '../views/Profile.vue'
import FAQTechnique from '../views/FAQTechnique.vue'
import Subscribe from '../views/payment/Subscribe.vue'
import Credit from '../views/payment/Credit.vue'
import SubscribeRe from '../views/SubscribeRe.vue'
import Admin from '../views/Admin.vue'
import SearchInvestor from '../views/SearchInvestor.vue'
import ForgotPassword from '../views/reset/ForgotPassword.vue'
import ResetPassword from '../views/reset/ResetPassword.vue'
import HomeLanding from '../views/Landing/Home.vue'
import HIW from '../views/Landing/HIW.vue'
import WAW from '../views/Landing/WAW.vue'
import Pricing from '../views/Landing/Pricing.vue'
import Register from '../views/Landing/SignUp.vue'
import Contact from '../views/Landing/Contact.vue'
import CGV from '../views/Landing/CGV.vue'
import CGA from '../views/Landing/CGA.vue'
import Legal from '../views/Landing/Legal.vue'
import FicheTech from '../views/Tech/FicheTech.vue'

/** New Routes v2 */
import FAQ from '../views/Landing/FAQ.vue'
import Rent from '../views/Landing/How/Rent.vue'
import Bank from '../views/Landing/How/Bank.vue'
import Strategy from '../views/Landing/How/Strategy.vue'
import InvestSCI from '../views/Landing/How/InvestSCI.vue'
import Lexique from '../views/Landing/Lexique.vue'
import SignVersion from '../views/Landing/SignVersion.vue'

import Archive from '../views/Archive.vue'
import NotFound from '../views/NotFound.vue'

import Comparateur from '../views/Comparator.vue'

import Results from '../views/Results.vue'
import Optimisation from '../views/Optimisation.vue'

import rapportFinancierSeul from '../components/PDF/rapportFinancierSeul.vue'

// Refonte v3
import NewHome from '../views/NewHome.vue'
import MesDossiers from '../views/MesDossiers.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeLanding',
    component: HomeLanding,
  },
  { path: '/404', component: NotFound },
  { path: '*', redirect: '/404' },
  {
    path: '/Comment-ca-marche',
    name: 'HIWLanding',
    component: HIW,
  },
  {
    path: '/Qui-sommes-nous',
    name: 'WAWLanding',
    component: WAW,
  },
  {
    path: '/rentabilite-locative-nette',
    name: 'Rent',
    component: Rent,
  },
  {
    path: '/monter-dossier-bancaire',
    name: 'Bank',
    component: Bank,
  },
  {
    path: '/strategie-investissement-immobilier',
    name: 'Strategy',
    component: Strategy,
  },
  {
    path: '/investir-sci',
    name: 'InvestSCI',
    component: InvestSCI,
  },
  {
    path: '/lexique',
    name: 'Lexique',
    component: Lexique,
  },
  {
    path: '/faq',
    name: 'FAQLanding',
    component: FAQ,
  },
  {
    path: '/FAQTechnique',
    name: 'FAQTechnique',
    component: FAQTechnique,
  },
  {
    path: '/tarifs',
    name: 'PricingLanding',
    component: Pricing,
  },
  {
    path: '/Contact',
    name: 'ContactLanding',
    component: Contact,
  },
  {
    path: '/acces-simulateur',
    name: 'SignVersion',
    component: SignVersion,
    redirect: '/Register',
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/archives',
    name: 'Archive',
    component: Archive,
  },
  // {
  //   path: '/Register/confirmation',
  //   name: 'MailConfirm',
  //   component: MailConfirm,
  //   props: {
  //     header: true,
  //     content: true
  //  },
  // },
  // {
  //   path: '/confirmation',
  //   name: 'MailConfirmed',
  //   component: MailConfirmed,
  // },
  {
    path: '/CGV',
    name: 'CGVLanding',
    component: CGV,
  },
  {
    path: '/CGA',
    name: 'CGALanding',
    component: CGA,
  },
  {
    path: '/mentions-legales',
    name: 'LegalLanding',
    component: Legal,
  },
  // {
  //   path: '/a-propos',
  //   name: 'ProposLanding',
  //   component: Propos,
  // },
  {
    path: '/MesDossiers/Resultats',
    name: 'Results',
    component: Results,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/MesDossiers/Optimisation',
    name: 'Optimisation',
    component: Optimisation,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/LogIn',
    name: 'LogIn',
    component: LogIn,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next({ name: 'Home' })
      }
      next()
    },
  },
  {
    path: '/Home',
    name: 'Home',
    component: NewHome,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/MesDossiers',
    name: 'MesDossiers',
    component: MesDossiers,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/MesDossiers',
    name: 'Simulator',
    component: Simulator,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/Investisseur',
    name: 'SearchInvestor',
    component: SearchInvestor,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/MesDossiers/form',
    name: 'SimulatorForm',
    component: SimulatorNew,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/investisseur/form',
    name: 'Investor',
    component: InvestorNew,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/Investisseur/Resultats',
    name: 'InvestorResult',
    component: InvestorResult,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  // {
  //   path: '/Aide',
  //   name: 'Aide',
  //   component: Aide,
  //   beforeEnter: (to, from, next) => {
  //     if(localStorage.getItem('token')) {
  //       next()
  //     } else {
  //       next({ name: 'LogIn' })
  //     }
  //   }
  // },
  {
    path: '/Profil',
    name: 'Profile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/Abonnement',
    name: 'Subscribe',
    component: Subscribe,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/Credit',
    name: 'Credit',
    component: Credit,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/De-retour',
    name: 'SubscribeRe',
    component: SubscribeRe,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/Fiches',
    name: 'FicheTech',
    component: FicheTech,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/Comparateur',
    name: 'Comparateur',
    component: Comparateur,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  {
    path: '/rapportFinancierSeul',
    name: 'rapportFinancierSeul',
    component: rapportFinancierSeul,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next({ name: 'LogIn' })
      }
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // optional, if you want smooth scroll
        behavior: 'smooth',
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
