<template>
	<div>
		<!-- <Header/> -->
		<div class="grid grid-cols-12 ml-6 mt-6">
			<a @click="goBack()" class="col-span-11 sm:col-span-1 text-lg font-semibold text-white  py-3 bg-aestiblue-light rounded-full aestima-button sm:mt-0">Retour</a>
			<!-- <a @click="backHistory()">Return</a> -->
		</div>
		<div class="grid grid-cols-12 gap-1 relative flex flex-row justify-center items-start pb-10 sm:pb-28">

			<div class="relative text-center md:text-left col-span-12 sm:col-start-2 sm:col-end-12 z-30 mb-10 flex flex-col justify-between items-center">
				<router-link to="/">
					<img class="hidden sm:block h-36 w-auto" alt="Logo Aestima" src="../../assets/logo_aestima-white.png">
				</router-link>
				<h1 class="text-3xl font-bold my-10">Nouvelle version en cours de développement</h1>
				<div class="mb-6 inline-block" v-show="isSafari">
					<div class="bg-aestimalight-50 p-6 rounded-lg">
						<div class="px-8 py-2 bg-lightblueaestima-100 rounded-lg flex flex-row justify-center items-center">
							<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
							width="44" height="44"
							viewBox="0 0 172 172"
							style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M86,18.14063c-37.35625,0 -67.85937,30.50313 -67.85937,67.85938c0,37.35625 30.50313,67.85938 67.85938,67.85938c37.35625,0 67.85938,-30.50312 67.85938,-67.85937c0,-37.35625 -30.50312,-67.85937 -67.85937,-67.85937zM86,26.20313c32.92188,0 59.79688,26.875 59.79688,59.79688c0,32.92188 -26.875,59.79688 -59.79687,59.79688c-32.92187,0 -59.79687,-26.875 -59.79687,-59.79687c0,-32.92187 26.875,-59.79687 59.79688,-59.79687zM86,41.65625c-2.28437,0 -4.03125,1.74687 -4.03125,4.03125v33.32605c-2.41875,1.34375 -4.03125,4.0302 -4.03125,6.98645c0,4.43438 3.62812,8.0625 8.0625,8.0625c2.95625,0 5.6427,-1.6125 6.98645,-4.03125h19.88855c2.28437,0 4.03125,-1.74688 4.03125,-4.03125c0,-2.28437 -1.74688,-4.03125 -4.03125,-4.03125h-19.88855c-0.67187,-1.20937 -1.74583,-2.28333 -2.9552,-2.9552v-33.32605c0,-2.28438 -1.74688,-4.03125 -4.03125,-4.03125z"></path></g></g></svg>
						
							<p class="text-white font-bold text-2xl ml-4">19 Juillet 2021</p>
						</div>
					</div>
				</div>
				<div id="clockdiv" class="mb-6 hidden sm:inline-block" v-show="!isSafari">
					<div>
						<span class="days">{{ days | two_digits }}</span>
						<div class="smalltext">Jours</div>
					</div>
					<div class="ml-4">
						<span class="hours">{{ hours | two_digits }}</span>
      					<div class="smalltext">Heures</div>
					</div>
					<div class="ml-4">
						<span class="minutes">{{ minutes | two_digits }}</span>
     					<div class="smalltext">Minutes</div>
					</div>
					<div class="ml-4 mt-4 sm:mt-0">
						<span class="seconds">{{ seconds | two_digits }}</span>
      					<div class="smalltext">Secondes</div>
					</div>
				</div>
				
			</div>

			
			<div class="col-span-12 sm:col-start-2 sm:col-end-7 sm:border-r-2 sm:border-black sm:py-10 my-10">
				<a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe6D5Q8_b3eRZky_INmEFs0aCMFYgwuGUKC3OQKkm1kN-Jd2g/viewform" class="whitespace-nw text-md sm:text-xl font-bold text-white px-10 sm:px-16 py-6 bg-aestiblue-light rounded-full aestima-button">S’inscrire à la nouvelle version</a>
				<p class="font-bold mt-8">et soyez prévenu dès la sortie de l’outil.</p>
			</div>
			<div class="col-span-12 sm:col-start-7 sm:col-end-12 sm:py-10 my-10">
				<router-link to="/Register" class="whitespace-nw text-md sm:text-xl font-bold text-white px-10 sm:px-16 py-6 bg-aestiblue-light rounded-full aestima-button">Continuer sur l’ancienne version</router-link>
				<p class="font-bold mt-8">et profitez-en gratuitement jusqu’à la </br> sortie du nouvel outil.</p>
			</div>
			
		</div>
		
		<div class="text-center mb-10">
			<router-link to="/contact" class="text-lg font-semibold text-white px-20 py-3 bg-aestiblue-light rounded-lg aestima-button">Nous contacter</router-link>
		</div>
		

		<Footer/>
	</div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'

export default {
	name: 'SignVersion',
	data() {
		return {
			now: Math.trunc((new Date()).getTime() / 1000),
			date: "2021-07-19 10:00:00",
			isSafari: /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)),
		}
	},
	components: {
		Footer,
		Header
	},
	mounted() {
		window.setInterval(() => {
			this.now = Math.trunc((new Date()).getTime() / 1000);
		},1000);
	},
	created: function() {
	},
	filters: {
		two_digits: function (value) {
			 if (value < 0) {
				return '00';
			}
			if (value.toString().length <= 1) {
				return `0${value}`;
			}
			return value;
		}
	},
	computed: {
		dateInMilliseconds() {
			return Math.trunc(Date.parse(this.date) / 1000)
		},
		seconds() {
			return (this.dateInMilliseconds - this.now) % 60;
		},
		minutes() {
			return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
		},
		hours() {
			return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
		},
		days() {
			return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
		}
	},
	methods: {
		goBack() {
			window.history.back()
		},
		getTimeRemaining(endtime) {
			const total = Date.parse(endtime) - Date.parse(new Date());
			const seconds = Math.floor((total / 1000) % 60);
			const minutes = Math.floor((total / 1000 / 60) % 60);
			const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
			const days = Math.floor(total / (1000 * 60 * 60 * 24));
			
			return {
				total,
				days,
				hours,
				minutes,
				seconds
			};
		},
		updateClock(endtime) {
			const t = this.getTimeRemaining(endtime);
			
			console.log(t)

			// daysSpan.innerHTML = t.days;
			// hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
			// minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
			// secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

			if (t.total <= 0) {
			clearInterval(timeinterval);
			}
		},
		initializeClock(id, endtime) {
			// const clock = document.getElementById(id);
			// console.log(clock)
			// console.log(id)
			// console.log(endtime)
			// const daysSpan = clock.querySelector('.days');
			// const hoursSpan = clock.querySelector('.hours');
			// const minutesSpan = clock.querySelector('.minutes');
			// const secondsSpan = clock.querySelector('.seconds');

			

			this.updateClock(endtime);
			const timeinterval = setInterval(updateClock, 1000);
		},
		
		// backHistory() {
		// 	console.log(window.history)
		// 	window.history.go(-1); return false;
		// }
	},
	metaInfo(){
		return {
			title: "Accès Simulateur - Aestima",
			meta: [{
				name: 'description',
				content: "Cette page permet d'accéder à l'une des deux versions de l'outil Aestima."
			}]
			//keywords
		}
	},
}
</script>

<style scoped>
.h-header {
	height: 100%;
}
.aestima-button {
	transition: 0.5s;
	background-color: #14A0EB;
}
/* 
video {
	position: relative;
	width: 100% !important;
	height: auto;
} */

.aestima-button:hover {
	background-color: #ffffff;
	border: 2px solid #14A0EB;
	color: #14A0EB;
	transform: scale(1.1);
}


#clockdiv{
	font-family: sans-serif;
	color: #fff;
	font-weight: 100;
	text-align: center;
	font-size: 30px;
}

#clockdiv > div{
	padding: 10px;
	border-radius: 3px;
	background: #5fc2f7;
	display: inline-block;
}

#clockdiv div > span{
	padding: 15px;
	border-radius: 3px;
	background: #14A0EB;
	display: inline-block;
}

.smalltext{
	padding-top: 5px;
	font-size: 16px;
}

.whitespace-nw {
	white-space: nowrap;
}

.bg-aestimalight-50 {
	background: #5fc2f7;
}
</style>

