<template>
	<div>
		<Header/>
		<div class="grid grid-cols-11 gap-1 bg-darkblueaestima-500 relative flex flex-row justify-center items-start">
			<div class="relative text-center col-span-11 sm:col-start-4 sm:col-end-9 z-10 flex justify-center">
				<h1 class="s1-title font-normal text-white text-4xl md:text-6xl mb-4 aestima-title-vw leading-none text-center sm:text-left"> Comment définir la bonne </br> <b class="font-bold">stratégie d’investissement ?</b> </h1>
				<!-- <p class="text-gray-300 font-medium text-2xl">Essayez la version gratuite</p>
				<p class="text-white font-medium text-2xl">durant 1 mois</p> -->
			</div>
			<!-- <a href="/presentation" class="col-span-11 sm:col-start-5 sm:col-end-8 uppercase text-sm font-semibold text-white px-12 py-6 bg-aestiblue-light rounded-full aestima-button mb-10">DEMARRER MON ESSAI GRATUIT</a>	 -->
		</div>
		
		<div class="mx-10 sm:mx-20 text-left mt-10 smooth">
			<div class="grid grid-cols-12 gap-1 relative flex flex-row justify-center items-start mb-6">
				<div class="hidden sticky top-16 sm:block sm:col-start-1 sm:col-end-3">
					<div class=" flex flex-col font-semibold">
						<a href="#introduction" class="my-2">Introduction</a>
						<a href="#pourquoi_investir" class="my-2">Pourquoi voulez-vous investir?</a>
						<a href="#prendre_risques" class="my-2">Prêt à prendre des risques?</a>
						<a href="#combien_temps" class="my-2">Sur combien de temps investir ?</a>
						<a href="#stategie_aestima" class="my-2">4 stratégies Aestima</a>
						<a href="#conclusion" class="my-2">Conclusion</a>
					</div>
				</div>
				<div class="col-span-12 sm:col-start-4 sm:col-end-12 text-justify">
				
					<div class="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center mb-10">
						<div class="flex flex-row">
							<div class="rounded-full w-16 h-16 overflow-hidden flex justify-center items-center">
								<img class="w-20" src="../../../assets/julien_profil.png" alt="Profil Julien">
							</div>
							<div class="flex flex-col justify-center ml-6">
								<address class="not-italic font-bold text-lg">Julien</address> 
								<span class="text-sm">Mis à jour le <time pubdate datetime="2021-04-09" title="9 avril 2021">09 avril 2021</time></span>
							</div>
						</div>
						<div class="p-2 mt-6 sm:mt-0 bg-gray-100 rounded-full relative clipboard cursor-pointer" @click="clipboard()">
							<div>{{linkCopied}}</div>
							<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
							width="22" height="22"
							viewBox="0 0 172 172"
							style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#000000"><path d="M43,50.16667c-20.94817,0 -37.7007,17.97568 -35.66536,39.34668c1.77733,18.63333 18.48575,32.31999 37.20508,32.31999h19.96029c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667h-20.28223c-11.63867,0 -22.07378,-8.72072 -22.67578,-20.35222c-0.65217,-12.39833 9.20301,-22.64778 21.45801,-22.64778h21.5c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667zM107.5,50.16667c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h20.28223c11.63867,0 22.07378,8.72072 22.67578,20.35222c0.65217,12.39833 -9.20301,22.64778 -21.45801,22.64778h-21.5c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h21.5c20.94817,0 37.7007,-17.97568 35.66536,-39.34668c-1.77733,-18.63334 -18.48574,-32.31999 -37.20508,-32.31999zM57.33333,78.83333c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h57.33333c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667z"></path></g></g></svg>
						</div>
					</div>
					
					<p>
						Tout le monde n’a pas la même définition du mot investir. Pour certains, <b>investir son argent</b>  signifie le placer sur une opération qui, après analyse approfondie, promet une certaine sécurité du placement ainsi qu’une rentabilité adéquate (comme le définit Benjamin Graham, l’un des pères de l’investissement financier).
					</p>
					</br>
					<p>
						Pour d’autres, investir peut signifier spéculer (<b>investir son argent</b> dans le but de revendre plus cher pour générer une plus-value) ou encore générer des revenus réguliers.
					</p>
					</br>
					<p>
						Nous allons nous concentrer ici sur l’<b>investissement immobilier</b>. Il y a plusieurs manières d’<b>investir dans la pierre</b>, avec plusieurs objectifs et motivations. Quels sont les vôtres ? Savez-vous comment définir la <b>stratégie d’investissement immobilier</b> qui vous ressemble le plus ? C’est ce que nous allons voir ici.
					</p>
					
					<div class="flex flex-col items-center relative my-6 mb-16">
						<img class="w-full flex justify-center items-center self-center" src="@/assets/article/strategie-investissement-immobilier.png" title="définir un stratégie d’investissement immobilier pour investir son argent ?" alt="définir un stratégie d’investissement immobilier pour investir son argent ?">
						<div class="relative bottom-0 w-full h-10 bg-img-desc flex justify-start items-center pl-4 -m-10">
							<p class="text-xs sm:text-base">Définir la bonne <b>stratégie d’investissement immobilier</b> pour <b>investir son argent</b>.</p>
						</div>
					</div>
					
			
					<div class="mt-10" id="pourquoi_investir" ref="pourquoi_investir">
						<div class="flex flex-col">
							<p class="text-2xl">Etape 1</p>
							<h2 class="font-bold text-2xl mb-6">Pourquoi voulez-vous investir votre argent dans la pierre ?</h2>
						</div>
						<p>
							La première étape consiste à vous poser la question : Pourquoi je veux effectuer un <b>investissement immobilier</b> ? 
						</p>
						</br>
						<p>
							Selon votre situation, ces raisons peuvent être variées et la liste ci-dessous n’est pas exhaustive :
						</p>
						</br>
						<ul class="round-tiret mb-4 pl-16 mt-4">
							<li>Préparer votre avenir en ayant un revenu régulier sur le long terme.</li>
							<li class="my-2">Générer un complément de revenu le plus rapidement possible.</li>
							<li>Se sentir en sécurité en devenant propriétaire à moindre frais.</li>
							<li class="my-2">Réduire votre imposition en vous constituant un patrimoine immobilier.</li>
							<li>Effectuer une plus-value de revente en investissant dans un bien sous-évalué.</li>
							<li class="my-2">Placer son épargne au meilleur taux de rentabilité.</li>
							<li>Diversifier ses placements financiers en profitant de l’effet de levier bancaire.</li>
						</ul>
						</br>
						<p>
							En bref, vous devez définir votre objectif principal d’investissement pour lister vos critères de recherche et ainsi focaliser votre énergie sur le <b>projet immobilier</b> qui vous convient le plus. 
						</p>
						
						<div class="my-10 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative">
							<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../../../assets/picto/ampoule.png" alt="">
							<p class="text-lightblueaestima-100 relative z-10">
								En <b>investissement immobilier</b> comme dans la vie, il n’y a pas de vent favorable
             					pour celui qui ne sait pas où il va.
							</p>
						</div>
					</div>
					
					<div class="mt-10" id="prendre_risques" ref="prendre_risques">
						<div class="flex flex-col">
							<p class="text-2xl">Etape 2</p>
							<h2 class="font-bold text-2xl mb-6">Projet immobilier - Êtes-vous prêt à prendre des risques ?</h2>
						</div>
						
						<p>
							Le projet d’<b>investir dans la pierre</b> doit être accompagné de ce questionnement pour passer à l’action en pleine conscience. Quelle est votre résistance au risque ? 
						</p>
						</br>
						<p>
							Dans le monde financier, risque et rendement vont souvent de pair. Plus le rendement est élevé, plus le risque l’est aussi. A l’inverse, plus un placement est sécurisé et plus le rendement est faible (comme par exemple le livret A. Il n’y a aucune possibilité de perte mais en contrepartie le rendement est quasiment nul).
						</p>
						</br>
						<p>
							Heureusement ce fonctionnement n’est pas automatique et vous pouvez réussir à dénicher des pépites en prenant un minimum de risques. Seulement, la fameuse pépite est le rêve de tous investisseurs et les occasions se font rares.
						</p>
						</br>
						<p>
							Pour définir correctement votre résistance au risque, demandez-vous :
						</p>
						</br>
						<ul class="round-tiret mb-4 pl-16 mt-4">
							<li>Ai-je assez d’argent de côté pour assumer les charges et pour gérer les mauvaises surprises ? (loyers impayés, forte vacance locative, etc.).</li>
							<li class="my-2">Mes revenus sont-ils stables et suffisants pour supporter l’effort d’épargne mensuel (solde des charges non couvertes par les loyers) que me demande mon investissement ?</li>
							<li>Ai-je assez de trésorerie d’avance ou d’épargne pour faire face à des frais imprévus ? (travaux de copropriété, réparations, litiges, etc.).</li>
							<li class="my-2">Suis-je prêt à envisager une moins-value de revente ? (Retournement du marché immobilier, emplacement non attrayant, etc.).</li>
							<li>Ai-je l'apport nécessaire pour faire financer mon <b>projet immobilier</b> et assumer les frais de démarrage? Il faut donc savoir se <a href="https://www.bienici.com/article/l-apport-personnel-en-immobilier-de-quoi-s-agit-il" target="_blank" class="text-lightblueaestima-100"><u>constituer un apport personnel</u></a>.</li>
						</ul>
						</br>
						<div class="my-10 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative">
							<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../../../assets/picto/ampoule.png" alt="">
							<p class="text-lightblueaestima-100 relative z-10">
								<b>Bon à savoir :</b> Projetez-vous dans la gestion quotidienne de votre bien locatif pour envisager toutes les possibilités et voir comment vous vous sentez par rapport aux différents scénarios d’<b>investissement immobilier</b>. Estimez également le temps que   vous êtes à y consacrer (gestion des locataires, AG de copropriété, administratif, etc.)
							</p>
						</div>
						
						<div class="flex justify-center my-20">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg font-semibold text-white px-2 py-3 bg-aestiblue-light rounded-lg aestima-button text-center sm:mt-0"><h3>Définir mon profil d’investisseur immobilier</h3></router-link>
						</div>
					</div>
					
					<div class="mt-10" id="combien_temps" ref="combien_temps">
						<div class="flex flex-col">
							<p class="text-2xl">Etape 3</p>
							<h2 class="font-bold text-2xl mb-6">Sur combien de temps voulez-vous investir dans votre projet immobilier ?</h2>
						</div>
						<p>
							En terme financier, on parle d’horizon de placement. Tout conseiller en <b>investissement immobilier</b> doit connaître ce paramètre avant d’aller plus loin.
						</p>
						</br>
						<p>
							Votre réponse à cette question sera fortement relative à votre objectif d’<b>investissement immobilier</b> défini en étape 1. Si vous souhaitez préparer votre avenir, on imagine bien que la revente ne sera pas prévue à court terme. En revanche, une personne qui <b>investit son argent</b> dans le seul but de faire une plus-value aura un horizon de placement plus flexible.
						</p>
						</br>
						<p>
							En <b>investissement immobilier</b>, le temps joue contre vous. Tenez-en compte dans votre stratégie. En effet, l’inflation grignote votre pouvoir d’achat car 1€ de demain vaut moins que 1€ d’aujourd’hui. Pour mesurer cela, il existe le ratio financier de performance appelé TIR pour taux interne de rendement. Il permet d’analyser, entre autres, l’impact de l’inflation sur votre <b>investissement immobilier</b>. Il vous permettra de prendre conscience qu’avec 500€ par mois perçus dans 30 ans, vous pourrez peut-être acheter l’équivalent de 300€ d’aujourd’hui. Pour illustrer ce phénomène imaginez qu’avec 1€ dans 10 ans nous ne pourrons sûrement acheter que ½ baguette de pain contre une entière aujourd’hui. 
						</p>
						</br>
						<p>
							C’est notamment pour cette raison que les experts en <b>investissements immobiliers</b> du monde entier ne gardent, en moyenne, pas un actif immobilier plus de 5 ans.
						</p>
						
						<div class="flex justify-center my-20">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg font-semibold text-white px-2 py-3 bg-aestiblue-light rounded-lg aestima-button text-center sm:mt-0"><h3>Simuler et sécuriser mon investissement immobilier</h3></router-link>
						</div>
					</div>
					
					<div class="mt-10" id="stategie_aestima" ref="stategie_aestima">
						<h2 class="font-bold text-2xl mb-6">Focus sur les 4 stratégies d’investissement immobilier de Aestima</h2>
						<p>
							<router-link target="_blank" to="/Register" class="text-lightblueaestima-100"><u>L’outil Aestima</u></router-link> consacre toute une partie à la définition et saisie de vos objectifs d'<b>investissement immobilier</b>. Ces objectifs, précisément définis, vous permettront d’affiner votre <router-link to="/rentabilite-locative-nette"><u>calcul de la rentabilité</u></router-link> et, donc, de mieux anticiper vos frais. Pour vous guider au mieux nous avons classé les différents objectifs d’<b>investissement immobilier</b> à travers quatre stratégies.
						</p>
						</br>
						<h3 class="font-bold text-6xl flex flex-row items-center">
							<img class="w-aauto h-14 mb-10 sm:m-0" src="@/assets/article-number-1-black.png" title="Stratégie Patrimoine" alt="Stratégie Patrimoine">
							<div class='text-lg ml-2'>
								<div class='font-normal'>
									Stratégie d’investissement immobilier
								</div>
								<div>
									Stratégie « Patrimoine »
								</div>
							</div>
						</h3>
						</br>
						<p>
							Vous avez un profil plutôt sécuritaire et vous privilégiez la sécurité de votre <b>investissement immobilier</b> plutôt que le rendement. Vous investissez généralement sur le long terme.
						</p>
						</br>
						<p>
							Vous optez plutôt pour un <b>projet immobilier</b> avec une faible rentabilité à condition que vous soyez au meilleur emplacement où la vacance locative est inexistante et les chances de plus-value de revente sont maximisées.  Plutôt passif, vous favorisez une gestion sécurisée et automatique de votre <b>investissement immobilier</b> (souscription à une garantie loyers impayés appelée GLI et gestion locative par agence).
						</p>
						</br>
						<p>
							Votre objectif est souvent de valoriser votre patrimoine et assurer votre avenir de manière sécurisée.
						</p>
						
						<div class="flex justify-center my-20">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg font-semibold text-white px-2 py-3 bg-aestiblue-light rounded-lg aestima-button text-center sm:mt-0"><h4>Simuler et sécuriser mon investissement</h4></router-link>
						</div>
						
						<h3 class="font-bold text-6xl flex flex-row items-center">
							<img class="w-aauto h-14 mb-10 sm:m-0" src="@/assets/article-number-2-black.png" title="Stratégie Serein" alt="Stratégie Serein">
							<div class='text-lg ml-2'>
								<div class='font-normal'>
									Stratégie d’investissement immobilier
								</div>
								<div>
									Stratégie « Serein »
								</div>
							</div>
						</h3>

						</br>
						<p>
							Votre sensibilité au risque est plutôt équilibrée et vous souhaitez <b>investir votre argent</b> le plus intelligemment possible. Vous investissez généralement sur le moyen terme en optimisant tous les paramètres pour générer des rendements avec un minimum d’effort d’épargne.
						</p>
						</br>
						<p>
							Votre quête de performances reste modérée et vous préférerez les villes stables économiquement avec un marché immobilier dense quitte à perdre quelques points de rentabilité. Vous minimiserez le poids des charges en optimisant les loyers et la fiscalité de votre <b>investissement immobilier</b>. L’analyse financière poussée sera une bonne chose pour vous afin d’anticiper, comparer tous les scénarios et investir de la manière la plus optimisée possible (durée de détention / fiscalité / mensualité d’emprunt / cash-flow). 
						</p>
						</br>
						<p>
							Votre objectif est souvent d’<b>investir dans la pierre</b> pour avoir des rendements supérieurs aux placements financiers et en mesurant au maximum le risque. L’effort d’épargne vous importe et vous voulez le réduire au maximum.
						</p>
						
						<div class="flex justify-center my-20">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg font-semibold text-white px-2 py-3 bg-aestiblue-light rounded-lg aestima-button text-center sm:mt-0"><h4>Simuler et optimiser mon investissement immobilier</h4></router-link>
						</div>
						
						<h3 class="font-bold text-6xl flex flex-row items-center">
							<img class="w-aauto h-14 mb-10 sm:m-0" src="@/assets/article-number-3-black.png" title="Stratégie Revenus" alt="Stratégie Revenus">
							<div class='text-lg ml-2'>
								<div class='font-normal'>
									Stratégie d’investissement immobilier
								</div>
								<div>
									Stratégie « Revenus »
								</div>
							</div>
						</h3>
						</br>
						<p>
							L'évolution future des prix immobiliers vous importent peu car votre principal objectif est de générer des revenus ou cash-flows positifs dès l’année suivant votre <b>investissement immobilier</b>. Vous êtes un investisseur actif et renseigné et votre tolérance au risque est plutôt élevée.
						</p>
						</br>
						<p>
							Vous intégrez le risque de vacance locative à vos calculs et vous gardez le bien aussi longtemps qu’il générera des cash-flows positifs. Votre horizon de placement est donc plutôt sur le moyen/ long terme. Pour arriver à de telles performances, vous optimisez tous les paramètres pour maximiser vos flux de trésorerie et vos rendements de votre <b>projet immobilier</b>.
						</p>
						</br>
						<p>
							Le choix de la ville vous importe peu hormis le risque de vacance locative, vous minimiserez le poids des charges en optimisant les loyers et la fiscalité de votre investissement. L’analyse financière poussée sera une bonne chose pour vous afin d’anticiper et comparer tous les scénarios afin d’investir de la manière la plus optimisée (durée de détention / fiscalité / mensualité d’emprunt / cash-flow, etc.). 
						</p>
						</br>
						<p>
							Votre objectif principal est le cash-flow positif immédiat et tant que cela dure, vous ne revendrez pas votre bien.
						</p>
						
						<div class="flex justify-center my-20">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg font-semibold text-white px-2 py-3 bg-aestiblue-light rounded-lg aestima-button text-center sm:mt-0"><h4>Simuler et maximiser mes cash-flows</h4></router-link>
						</div>
						
						<h3 class="font-bold text-6xl flex flex-row items-center">
							<img class="w-aauto h-14 mb-10 sm:m-0" src="@/assets/article-number-4-black.png" title="Stratégie Valeur" alt="Stratégie Valeur">
							<div class='text-lg ml-2'>
								<div class='font-normal'>
									Stratégie d’investissement immobilier
								</div>
								<div>
									Stratégie « Valeur »
								</div>
							</div>
						</h3>
					
						</br>
						<p>
							A l’instar des marchands de biens, vous pistez constamment les offres et démarchez même directement les propriétaires pour acquérir un bien, à vos yeux, sous-évalué : Prix inférieur à celui pratiqué sur le marché, division possible entraînant une plus-value, lourds travaux à prévoir freinant les acheteurs, etc.
						</p>
						</br>
						<p>
							Vous êtes plutôt à l’aise avec le risque. Vous savez que pour dégager de belles performances vous devez envisager des scénarios que d’autres investisseurs ou le propriétaire lui-même, n’avaient pas imaginé (justifiant un prix inférieur).
						</p>
						</br>
						<p>
							Votre objectif principal étant de générer rapidement une plus-value de revente, votre horizon de placement est plutôt sur du court terme.
						</p>
						</br>
						<p>
							Vous avez généralement une solidité financière importante vous permettant de ne pas accorder beaucoup d’importance à l’effort d’épargne et à l’optimisation des charges.
							En revanche, vous êtes focalisé sur l’optimisation des travaux en termes de prix, temps et esthétique pour maximiser votre prix de revente.
						</p>
						
						<div class="flex justify-center my-20">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg font-semibold text-white px-2 py-3 bg-aestiblue-light rounded-lg aestima-button text-center sm:mt-0"><h4>Maximiser les performances de mon opération</h4></router-link>
						</div>
						
						<div class="my-10 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative">
							<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../../../assets/picto/ampoule.png" alt="">
							<p class="text-lightblueaestima-100 relative z-10">
								<b>A retenir :</b> Vous ne devez pas rentrer dans une de ces cases, votre profil d’<b>investisseur immobilier</b> peut être à la jonction de deux stratégies décrites plus haut. Nous les avons créées pour vous permettre de vous identifier et définir votre cap.
							</p>
						</div>
						
						<p id="conclusion" ref="conclusion">
							Comme un sportif haut niveau doit avoir un objectif pour gagner, vous devez prendre le temps de comprendre ce qui vous pousse à <b>investir votre argent</b> pour définir précisément votre objectif d’<b>investissement immobilier</b>.
						</p>
						</br>
						<p>
							Si vous souhaitez vous lancer dans un <b>investissement immobilier</b> par vous-même, documentez-vous, parlez-en avec d’autres investisseurs (et non pas des proches qui ne l’ont jamais fait car cela pourrait vous refroidir plus qu’autre chose) et envisagez plusieurs scénarios d’<b>investissements immobiliers</b>.
						</p>
						</br>
						<p>
							Cette dernière astuce vous permettra d’anticiper les frais, de prévoir d’éventuelles surprises, et surtout de vous projeter dans ce <b>projet immobilier</b> qui fera désormais partie intégrante de votre vie. Grâce à l’énergie que vous mettrez dans cette préparation, vous pourrez acheter un bien immobilier qui vous correspond totalement, sans devoir le vendre plus rapidement que prévu car les charges sont trop élevées ou la gestion est trop complexe (mais en gardant à l’esprit que le risque 0 n’existe pas). Vous pourrez alors souffler et <b>investir dans la pierre</b> en pleine conscience !
						</p>
						
						<div class="flex justify-center my-20">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg font-semibold text-white px-2 py-3 bg-aestiblue-light rounded-lg aestima-button text-center sm:mt-0"><h4>Définir mon profil d’investisseur immobilier et mes objectifs avec Aestima</h4></router-link>
						</div>
					</div>
					
				</div>
			</div>
				


		</div>

	<Footer/>
</div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
export default {
	name: 'Rent',
	data() {
		return {
			posBoxB: 0,
			posBox: 0,
			linkCopied: 'copier le lien',
		}
	},
	components: {
		Footer,
		Header
	},
	metaInfo(){
		return {
			title: "Définissez VOTRE stratégie d’investissement immobilier - Aestima.",
			meta: [
				{
					name: 'description',
					content: "Définir votre profil d’investisseur vous permettra de réaliser un projet locatif adapté à VOS objectifs. Alors, quel profil d’investisseur êtes-vous ?"
				},
				{
					property: "og:url",
    				content: `${process.env.VUE_APP_HOSTNAME_DEV}/strategie-investissement-immobilier`,
				},
				{
					property: "og:title",
    				content: "Définissez VOTRE stratégie d’investissement immobilier - Aestima.",
				},
				{
					property: "og:description",
    				content: "Définir votre profil d’investisseur vous permettra de réaliser un projet locatif adapté à VOS objectifs. Alors, quel profil d’investisseur êtes-vous ?",
				},
				{
					property: "og:image",
    				content: "@/assets/article/strategie-investissement-immobilier.png",
				},
				{
					property: "twitter:url",
    				content: `${process.env.VUE_APP_HOSTNAME_DEV}/strategie-investissement-immobilier`,
				},
				{
					property: "twitter:title",
    				content: "Définissez VOTRE stratégie d’investissement immobilier - Aestima.",
				},
				{
					property: "twitter:description",
    				content: "Définir votre profil d’investisseur vous permettra de réaliser un projet locatif adapté à VOS objectifs. Alors, quel profil d’investisseur êtes-vous ?",
				},
				{
					property: "twitter:image",
    				content: "@/assets/article/strategie-investissement-immobilier.png",
				},
				
			]
			//keywords
		}
	},
	created() {
		window.addEventListener('scroll', this.calcPosOfBox());
    	
  	},
	destroy() {
		window.addEventListener('scroll', this.calcPosOfBox());
	},
	computed: {

	},
	methods: {
		calcPosOfBox() {
			const rendement_brut = document.getElementById("rendement_brut");
			// const rendement_b = this.$refs["rendement_b"].getBoundingClientRect();
			// this.posBoxB = rendement_brut.y;
			// this.posBox = rendement_b.y;
			// console.log(this.posBoxB)
			// console.log(this.posBox)
			// console.log("window.scrollY", window.scrollY)

		},
		clipboard() {
			// var copyText = window.location.href
			const copyText = document.createElement('textarea');
  			copyText.value = window.location.href;
			document.body.appendChild(copyText);
			copyText.select();
  			copyText.setSelectionRange(0, 99999);
			document.execCommand("copy");
			document.body.removeChild(copyText);
			this.linkCopied = "Lien copié"
		},
	},
}
</script>

<style scoped>

.clipboard div{
	padding: 0.3rem 0.6rem 0.3rem 0.6rem;
	background: #0C1E3B;
	border-radius: 5px;
	color: white;
	font-size: 0.8rem;
	position: absolute;
	white-space: nowrap;
	top: 0;
	transition: 0.2s;
	transform: translateX(0);
	opacity: 0;
}

.clipboard:hover div {
	opacity: 1;
	transform: translateX(3rem);
}

.h-header {
	height: 100%;
}
.aestima-button {
	transition: 0.5s;
	background-color: #14A0EB;
}
/* 
video {
	position: relative;
	width: 100% !important;
	height: auto;
} */

.aestima-button:hover {
	background-color: #ffffff;
	border: 2px solid #14A0EB;
	color: #14A0EB;
	transform: scale(1.1);
}

.aestima-title-vw {
}

.aestima-par-vw {
}
/* Medium (md) */
@media (min-width: 768px) {
	.h-header {
		height: 50rem;
	}

	.aestima-title-vw {
		font-size: 2.8vw !important;
	}

	.aestima-par-vw {
		font-size: 1.2vw !important;
	}
}

.shadow-inner {
	box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);
}

/** SWITCH */

.switch {
  position: relative;
  display: inline-block;
  width: 10rem;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0C1E3B;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 0.2rem;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 0.2rem;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(7.8rem);
  -ms-transform: translateX(7.8rem);
  transform: translateX(7.8rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.smooth {
	scroll-behavior: smooth;
}

.ampoule-picto {
	top: -1rem;
	left: -1rem;
}

.bg-img-desc {
	background: rgba(20, 160, 235,0.44);
}

.round-tiret li {
	list-style: inside;
}

.round-tiret-check li {
	list-style: url('../../../assets/picto/aestima-tiret.png');
}

</style>

