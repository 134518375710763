<template>
  <div id="defaultModal" tabindex="-1" aria-hidden="true" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal justify-center items-center flex">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="relative my-auto px-4 h-full md:h-auto">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="flex justify-center items-center p-5">
          <h3 class="font-bold text-black" style="font-size: 20px">Répartition et ventilation des composants amortissables</h3>
        </div>
        <div class="p-6 space-y-6">
          <div class="grid grid-cols-3 px-5">
            <div class="col-span-1">
              <p class="head-text">Type</p>
              <div class="content-text my-2 flex flex-row">
                <p>Terrain</p>
                <QuestionMarkv2 class="ml-4 place-self-center leading-5" :text="`Règle N°1 : La valeur du terrain (ou foncier) sur lequel est implanté le bien ne s'amortit jamais. Inscrivez donc le pourcentage de la valeur du bien qui correspond au foncier pour le déduire du calcul. Ce taux dépend bien entendu de l'emplacement du bien. Dans certaines villes, la valeur du foncier est proportionnellement plus élevée comme par exemple à Paris où elle peut être estimée à 30%.`" />
              </div>
              <p class="content-text my-2">Gros oeuvre (Structure et toiture)</p>
              <p class="content-text my-2">Façade et étanchéité</p>
              <p class="content-text my-2">Installations générales et techniques</p>
              <p class="content-text my-2">Agencement (cloisons etc)</p>
              <p class="content-text my-2 font-bold">Total</p>
            </div>
            <div class="col-span-1">
              <p class="head-text">Pourcentage</p>
              <div class="percent-input">
                <input required type="number" v-model="amortissements.terrain.pourcentage" id="terrain_pourcentage" min="0" max="100" class="form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <div class="percent-input">
                <input required type="number" v-model="amortissements.gros_oeuvre.pourcentage" id="gros_oeuvre_pourcentage" min="0" max="100" class="form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <div class="percent-input">
                <input required type="number" v-model="amortissements.facade_etancheite.pourcentage" id="facade_etancheite_pourcentage" min="0" max="100" class="form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <div class="percent-input">
                <input required type="number" v-model="amortissements.installation_generales_techniques.pourcentage" id="installation_generale_pourcentage" min="0" max="100" class="form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <div class="percent-input">
                <input required type="number" v-model="amortissements.agencement.pourcentage" id="agencement_pourcentage" min="0" max="100" class="form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <div class="percent-input">
              <p class="head-text w-2/3 text-right pr-8 -mb-2">{{ total }}</p>
            </div>
            </div>
            <div class="col-span-1">
              <p class="head-text">Durée</p>
              <div class="annee-input">
                <input required type="number" v-model="amortissements.terrain.duree" disabled id="terrain_duree" min="0" max="100" class="bg-gray-300 form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <div class="annee-input">
                <input required type="number" v-model="amortissements.gros_oeuvre.duree" id="gros_oeuvre_duree" min="0" max="100" class="form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <div class="annee-input">
                <input required type="number" v-model="amortissements.facade_etancheite.duree" id="facade_etancheite_duree" min="0" max="100" class="form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <div class="annee-input">
                <input required type="number" v-model="amortissements.installation_generales_techniques.duree" id="installation_generale_duree" min="0" max="100" class="form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
              <div class="annee-input">
                <input required type="number" v-model="amortissements.agencement.duree" id="agencement_duree" min="0" max="100" class="form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
              </div>
            </div>
          </div>
          <div class="grid grid-cols-3 px-5 h-5">
            <div class="grid col-span-1 mt-2 content-text">Ameublement </div>
                <div class="grid col-span-1 mt-2 text-right content-text w-2/3"> {{amortissements.ameublement.valAmo}} €</div>
            <div class="grid annee-input col-span-1">
              <input required type="number" v-model="amortissements.ameublement.duree" id="ameublement_duree" min="0" max="100" class="form-input block w-2/3 py-2 my-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                </div>
          </div>
          <div class="mb-6 mt-12">
            <div class="px-4 py-3 text-right sm:px-6 flex justify-center items-center">
              <span class="inline-flex rounded-md shadow-sm">
                <a @click="close" class="cursor-pointer whitespace-no-wrap inline-flex font-bold items-center justify-center px-15 py-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"> Enregistrer les modifications </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionMarkv2 from '@/components/SVG/QuestionMarkv2.vue';

export default {
  name: 'AmortissementParComposant',
  components: {
    QuestionMarkv2,
  },
  props: {
    amortissements: Object,
  },
  created() {
  },
  computed: {
    total() {
      return +this.amortissements.terrain.pourcentage + +this.amortissements.gros_oeuvre.pourcentage + +this.amortissements.facade_etancheite.pourcentage + +this.amortissements.installation_generales_techniques.pourcentage + +this.amortissements.agencement.pourcentage;
    },
  },
  methods: {
    close() {
      this.$emit('close', this.amortissements);
    },
  },
};
</script>

<style lang="scss" scoped>
.head-text {
  font-size: 15px;
  line-height: 40px;
  font-weight: bold;
}

.content-text {
  font-size: 14px;
  line-height: 40px;
}

.subtitle {
  font-size: 14px;
  margin-top: 60px;
}

.percent-input {
	 position: relative;
 }

 .percent-input input {
     padding-right:18px ;
     text-align:end;
 }

 .percent-input:before {
     position: absolute;
     top: 8px;
     right: 102px;
	 font-size: 0.80rem;
     content:"%";
 }
.annee-input {
	 position: relative;
 }

 .annee-input input {
     padding-right:18px ;
     text-align:end;
 }

 .annee-input:before {
     position: absolute;
     top: 8px;
     right: 52px;
	 font-size: 0.80rem;
     content:"ans";
 }

</style>
