<template>
  <div>
    <Header />
    <div
      class="grid grid-cols-11 gap-1 bg-darkblueaestima-500 relative flex flex-row justify-center items-start"
    >
      <div class="relative text-left col-span-11 sm:col-start-3 sm:col-end-11 z-10 pb-7">
        <h1
          class="s1-title font-bold text-white text-4xl md:text-6xl aestima-title-vw leading-normal"
        >
          Vos questions ? Nos réponses !
        </h1>
        <span class="text-white text-2xl">Foire aux questions.</span>
      </div>
    </div>
    <div class="bg pt-10 text-left">
      <div class="grid grid-cols-12">
        <h2 class="col-span-11 sm:col-start-3 font-bold">Foire aux questions</h2>
        <div class="col-span-11 col-start-3 col-end-11">
          <input
            v-model="filterValue"
            id="faq-quest"
            name="faq-quest"
            type="text"
            class="mt-6 sm:mb-6 appearance-none relative block w-full px-10 py-3 border border-gray-300 placeholder-gray-700 text-gray-900 focus:outline-none focus:ring-lightblueoh-100 focus:border-darkblueoh-100 focus:z-10 sm:text-sm"
            placeholder="Recherchez des réponses..."
          />

          <div class="card mt-5 mb-15 py-5 px-10">
            <div v-if="filterValue === ''">
              <div v-for="title in faqTitle">
                <div class="flex flex-row align-middle mb-5">
                  <img class="faq-img" :src="title.img" alt="" />
                  <h3 class="ml-10 my-auto" v-html="title.name"></h3>
                </div>
                <PAccordion>
                  <PAccordionTab
                    v-for="resp in faqQuest"
                    v-if="resp.faqTitle === title.id"
                    :header="resp.question"
                  >
                    <p class="mb-6 text-justify" v-html="resp.response"></p>
                  </PAccordionTab>
                </PAccordion>
              </div>
            </div>
            <div v-else class="flex flex-row items-center">
              <PAccordion>
                <PAccordionTab v-for="event in searchevents" :header="event.question">
                  <p class="mb-6 text-justify" v-html="event.response"></p>
                </PAccordionTab>
              </PAccordion>
            </div>
            <div class="flex justify-center my-10">
              <router-link
                target="_blank"
                to="/contact"
                class="text-lg text-center font-semibold px-16 py-2 bg-aestiblue-light rounded-lg aestima-button mt-20 sm:mt-0"
                ><span class="text-white hover:text-black"
                  >Nous contacter</span
                ></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Landing/Footer.vue";
import Header from "@/components/Landing/Header.vue";
export default {
  name: "FAQ",
  data() {
    return {
      month: true,
      filterValue: "",
      faqTitle: [
        {
          id: "account",
          name: "Création de compte et lancement",
          img: require("@/assets/icons/faq1.jpg"),
        },
        {
          id: "abonnement",
          name: "Mes abonnements",
          img: require("@/assets/icons/faq3.jpg"),
        },
        {
          id: "compte",
          name: "Mon compte",
          img: require("@/assets/icons/faq4.jpg"),
        },
        {
          id: "paiement",
          name: "Paiement",
          img: require("@/assets/icons/faq5.jpg"),
        },
      ],
      faqQuest: [
        {
          id: "1",
          question: "Sur quels supports puis-je utiliser Aestima ?",
          response:
            "Aestima est un outil exclusivement en ligne. Le principal avantage est donc que vous pouvez " +
            "l’utiliser de n’importe où avec n’importe quel support (ordinateur, tablette). Pour cela, il vous suffit " +
            "simplement de créer gratuitement votre compte et de vous identifier. Il vous faudra toutefois avoir une " +
            "connexion internet stable pour utiliser Aestima.",
          faqTitle: "account",
        },
        {
          id: "2",
          question: "Puis-je essayer gratuitement Aestima ?",
          response:
            "Oui et durant 30 jours. Aestima est une solution que nous avons voulu ouverte et sans engagement. Pour vous permettre de tester le logiciel de la manière la plus optimisée possible, nous avons volontairement mis une longue période d'essai, résiliable à tout moment. Une fois le 30ème jour passé, l'abonnement devient payant et votre CB est débitée de la première mensualité. Pour rappel, nos offres sont sans engagement et vous pouvez résilier à tout moment.",
          faqTitle: "account",
        },
        {
          id: "5",
          question: "Mon abonnement sera-t-il renouvelé automatiquement ?",
          response:
            "Tout plan d'abonnement se renouvelle automatiquement. Si vous ne souhaitez pas être facturé, " +
            "annulez votre abonnement avant la date d'expiration, sinon le système débitera automatiquement votre " +
            "carte.",
          faqTitle: "abonnement",
        },
        {
          id: "6",
          question: "Puis-je annuler mon abonnement annuel au cours de l’année ?",
          response:
            "Lorsque vous souscrivez à un abonnement annuel, vous bénéficiez d’une forte réduction. Vous payez " +
            "donc, en avance, une seule fois pour l’année. Ainsi, vous pouvez vous désinscrire au cours de ces 12 " +
            "mois, mais il n’y aura pas de remboursement. Au terme de ces 12 mois, vous pourrez poursuivre sur un " +
            "autre abonnement annuel, mensuel ou bien vous désinscrire, tout simplement.",
          faqTitle: "abonnement",
        },
        {
          id: "7",
          question:
            "Y a-t-il des fonctionnalités supplémentaires qui seront ajoutées une fois que je me serai abonné ?",
          response:
            "Aestima est en perpétuelle évolution, et nous avons beaucoup d’idées et d’ambition pour poursuivre " +
            "l’aventure ! Donc, oui, il est fort à parier que, durant votre abonnement, vous pourrez bénéficier en " +
            "avant-première de nouvelles fonctionnalités et évolutions que nous apporterons à l’outil.",
          faqTitle: "abonnement",
        },
        {
          id: "8",
          question:
            "Vous avez un outil en ligne. Mais si j’ai une question sur le fonctionnement de la plateforme ou " +
            "l’analyse des résultats, puis-je avoir quelqu’un au téléphone ?",
          response:
            "Pour ce qui concerne une question ou un besoin d’aide sur l’outil Aestima, notre équipe de support " +
            "clientèle est disponible 5 jours sur 7 pour vous vous accompagner. Vous avez également, à votre " +
            "disposition,  les tutoriels qui traitent chaque question que vous pouvez avoir et qui vous guident, de " +
            "manière ludique, dans chaque étape de l’utilisation de l’outil.<br/>" +
            "Concernant un accompagnement sur l’analyse et l’interprétation des résultats de vos simulations, c’est " +
            "une prestation payante disponible dans les abonnements « initiés » et « professionnel ».",
          faqTitle: "abonnement",
        },
        {
          id: "9",
          question:
            "Comment saisir mon code promotion / code de réduction lié à mon abonnement ?",
          response: `<div class='flex flex-row'>
<p class="flex-auto mr-5">Pour activer votre code de réduction à votre abonnement, faites évoluer votre abonnement directement
sur votre espace utilisateur dans l’onglet paramètres, “Mon abonnement” ou directement sur la page tarifs.
Pour cela, sélectionnez l’abonnement de votre choix, remplissez les étapes d’inscription et vous
arriverez sur la page de paiement Stripe. Sur la partie gauche de l’interface Stripe, vous aurez alors la
possibilité d’insérer votre code et la réduction s’appliquera directement sur le montant à payer.</p>
<img class="flex-auto" style="max-width: 412px; max-height: 276px" src=${require("@/assets/code_promo.png")} />
</div>
`,
          faqTitle: "abonnement",
        },
        {
          id: "10",
          question: "Comment me désinscrire et/ou supprimer mon compte ?",
          response:
            "Si vous êtes en abonnement payant et que vous souhaitez revenir à un abonnement gratuit, il vous " +
            "suffit simplement de vous rendre dans la rubrique « mon abonnement » dans vos paramètres et de cliquer " +
            "sur « gérer mon abonnement ». Si vous préférez supprimer définitivement votre compte Aestima, cliquer " +
            "sur le bouton « supprimer mon compte » dans la même rubrique.",
          faqTitle: "compte",
        },
        {
          id: "11",
          question:
            "Lorsque je m’inscris, puis-je à tout moment me désinscrire et demander la suppression de 100% de " +
            "mes informations intégrées à Aestima lors de mon inscription et de mon utilisation ?",
          response:
            "La réponse est : un grand OUI ! Lors de votre désinscription, nous nous engageons à n’utiliser ni " +
            "à enregistrer aucune de vos données. Elles seront immédiatement et automatiquement supprimées de manière " +
            "définitive comme le veut le règlement RGPD.",
          faqTitle: "compte",
        },
        {
          id: "12",
          question: "Comment payer mon abonnement Aestima en ligne ?",
          response:
            "Lorsque vous vous inscrirez à l’abonnement de votre choix ou que vous ferez évoluer votre " +
            "abonnement depuis votre espace utilisateur, vous serez redirigé vers notre partenaire de paiement Stripe. " +
            "Vous devez saisir votre carte bancaire. Ensuite, c’est très simple, vous serez débité chaque mois du " +
            "montant de votre abonnement via cette même carte bancaire.",
          faqTitle: "paiement",
        },
        {
          id: "13",
          question:
            "Est-ce que le paiement en ligne est totalement sécurisé et fiable ? ",
          response:
            "Le paiement en ligne sur Aestima est 100% sécurisé grâce à notre partenaire Stripe, plateforme de " +
            "paiement en ligne reconnue mondialement depuis plus de 10ans.",
          faqTitle: "paiement",
        },
        {
          id: "14",
          question: "Comment récupérer ma facture Aestima ?",
          response: `
            <div class="flex flex-col gap-3">
              <p>Une fois sur votre espace il vous suffit de cliquer sur votre logo en haut à droite de l'écran et d'aller sur l'onglet "Mon profil et abonnement". De là, vous verrez un encart nommé "gestion des abonnements", cliquez sur le bouton "gérer mon abonnement". Vous serez alors redirigé vers la plateforme de paiement sur laquelle vous pourrez consulter l'historique de facturation et télécharger la facture de votre choix</p>
              <img class="mt-5 object-contain" style="max-height: 276px" src=${require("@/assets/faq/facture1.png")} />
              <img class="mt-5 object-contain" style="max-height: 276px" src=${require("@/assets/faq/facture2.png")} />
              <img class="mt-5 object-contain" style="max-height: 276px" src=${require("@/assets/faq/facture3.png")} />
            </div>
          `,
          faqTitle: "paiement",
        },
      ],
    };
  },
  components: {
    Footer,
    Header,
  },
  computed: {
    searchevents: function () {
      const filterValue = this.filterValue.toLowerCase();

      const filter = (event) => {
        // console.log(event.response.toLowerCase().includes(filterValue))
        return (
          event.response.toLowerCase().includes(filterValue) ||
          event.question.toLowerCase().includes(filterValue)
        );
      };

      return this.faqQuest.filter(filter);
    },
  },
  metaInfo() {
    return {
      title: "FAQ - Aestima",
      meta: [
        {
          name: "description",
          content: "Cette page décrit la FAQ de la solution Aestima.",
        },
      ],
      //keywords
    };
  },
};
</script>

<style lang="scss" scoped>
body {
  scroll-behavior: smooth;
}

h2 {
  color: #0c1e3b;
  font-size: 40px;
}

h3 {
  color: #0c1e3b;
  font-size: 25px;
  font-weight: bold;
}

h4 {
  color: black;
  font-size: 18px;
  font-weight: bold;
}

p {
  color: black;
  font-weight: 500;
  font-size: 16px;
}

input {
  border: 1px solid rgba(0, 0, 0, 0.31);
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.card {
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
}

.faq {
  &-img {
    height: 103px;
    width: 118px;
  }
}

.aestima-button {
  transition: 0.5s;
  background-color: #14a0eb;
}

.aestima-button:hover {
  background-color: #ffffff;
  border: 2px solid #14a0eb;
  color: #14a0eb;
  transform: scale(1.1);
}

.aestima-title-vw {
}

.aestima-par-vw {
}
/* Medium (md) */
@media (min-width: 768px) {
  .h-header {
    height: 50rem;
  }

  .aestima-title-vw {
    font-size: 2.8vw !important;
  }

  .aestima-par-vw {
    font-size: 1.2vw !important;
  }
}

.shadow-inner {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/** SWITCH */

.switch {
  position: relative;
  display: inline-block;
  width: 10rem;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0c1e3b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0.2rem;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0.2rem;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(7.8rem);
  -ms-transform: translateX(7.8rem);
  transform: translateX(7.8rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
