<template>
	<div class="w-full grid grid-cols-12 gap-6 px-4 sm:px-10">
		<div class="relative col-span-12 sm:col-start-1 sm:col-end-9 flex flex-col justify-start items-start py-10 mt-popup">
			<p class="text-left text-sm">Vous allez utiliser <b>1 crédit Aestima</b> pour débloquer un accès illimité aux fonctionnalités suivantes 
pour la simulation sélectionnée “NOM DU BIEN” :</p>
			<ul class="text-left flex flex-col justify-start items-start ml-10 my-10">
				<li class="flex flex-row items-center justify-start mt-4">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
						width="20" height="20"
						viewBox="0 0 172 172"
						style=" fill:#000000;"><g fill="none" fill-rule="none" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none" fill-rule="nonzero"></path><g fill="#14A0EB" fill-rule="evenodd"><path d="M161.92188,25.08333l-104.14062,105.26042l-47.70312,-47.47917l-10.07812,10.30208l57.78125,57.33333l114.21875,-115.11458z"></path></g></g></svg>
					</span>
					<p class="text-black text-normal font-bold sm:text-normal ml-4 text-left">Affichage des résultats financiers complets</p>
				</li>
				<li class="flex flex-row items-center justify-start mt-4">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
						width="20" height="20"
						viewBox="0 0 172 172"
						style=" fill:#000000;"><g fill="none" fill-rule="none" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none" fill-rule="nonzero"></path><g fill="#14A0EB" fill-rule="evenodd"><path d="M161.92188,25.08333l-104.14062,105.26042l-47.70312,-47.47917l-10.07812,10.30208l57.78125,57.33333l114.21875,-115.11458z"></path></g></g></svg>
					</span>
					<p class="text-black text-normal font-bold sm:text-normal ml-4 text-left">Affichage de l’état du marché locatif de la ville</p>
				</li>
				<li class="flex flex-row items-center justify-start mt-4">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
						width="20" height="20"
						viewBox="0 0 172 172"
						style=" fill:#000000;"><g fill="none" fill-rule="none" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none" fill-rule="nonzero"></path><g fill="#14A0EB" fill-rule="evenodd"><path d="M161.92188,25.08333l-104.14062,105.26042l-47.70312,-47.47917l-10.07812,10.30208l57.78125,57.33333l114.21875,-115.11458z"></path></g></g></svg>
					</span>
					<p class="text-black text-normal font-bold sm:text-normal ml-4 text-left">Export du dossier bancaire</p>
				</li>
				<li class="flex flex-row items-center justify-start mt-4">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
						width="20" height="20"
						viewBox="0 0 172 172"
						style=" fill:#000000;"><g fill="none" fill-rule="none" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none" fill-rule="nonzero"></path><g fill="#14A0EB" fill-rule="evenodd"><path d="M161.92188,25.08333l-104.14062,105.26042l-47.70312,-47.47917l-10.07812,10.30208l57.78125,57.33333l114.21875,-115.11458z"></path></g></g></svg>
					</span>
					<p class="text-black text-normal font-bold sm:text-normal ml-4 text-left">Export du rapport financier</p>
				</li>
				<li class="flex flex-row items-center justify-start mt-4">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
						width="20" height="20"
						viewBox="0 0 172 172"
						style=" fill:#000000;"><g fill="none" fill-rule="none" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none" fill-rule="nonzero"></path><g fill="#14A0EB" fill-rule="evenodd"><path d="M161.92188,25.08333l-104.14062,105.26042l-47.70312,-47.47917l-10.07812,10.30208l57.78125,57.33333l114.21875,-115.11458z"></path></g></g></svg>
					</span>
					<p class="text-black text-normal font-bold sm:text-normal ml-4 text-left">Support téléphonique d’accompagnement pour votre projet </p>
				</li>
			</ul>
			
			<p class="text-sm text-left"><i>
				Attention ! <b>Vous ne pourrez plus modifier la simulation une fois que le crédit sera utilisé.</b></br>
				Pensez à vérifier que le financement saisi dans la simulation corresponde à votre demande d’emprunt.</br>
				La fiche investisseur restera, quant à elle, modifiable.
			</i></p>
			
			<div class="flex items-start mt-10">
				<input required id="cgv" type="checkbox" class="form-checkbox h-4 w-4 text-lightblueaestima-100 transition duration-150 ease-in-out">
				<label for="cgv" class="ml-2 block text-xs text-gray-900 text-left">
					Je renonce expressément à mon droit de rétractation de 14 jours pour les prestations et contenus </br> numériques dont je bénéficierai dès l’exécution de la présente commande.
				</label>
			</div>
			
			<router-link to="/" class="w-10/12 mt-10 font-bold relative flex justify-center py-2 px-4 border border-transparent text-md leading-5 rounded-sm text-white bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out">
				UTILISER 1 CREDIT POUR LA SIMULATION {{}}
			</router-link>
		</div>
		<div class="relative  col-span-12 sm:col-start-9 sm:col-end-13 flex flex-col justify-end items-center py-6 mt-popup">
			<div class="border relative border-black w-full h-2/5 flex flex-col items-center justify-around">
				<div class='flex flex-row items-center'>
					<img class="bg-lightblueaestima-100 self-start p-2 h-10 w-auto absolute top-4 left-4" src="@/assets/picto/aestima-diamond.png" alt="Crédit Aestima pictogramme">
					<h2 class="ml-4 font-bold">Crédits Aestima</h2>
				</div>
				<div class="my-4 sm:m-0 flex flex-row items-center">
					<p>Crédits Restants</p>
					<span class="bg-lightblueaestima-100 px-4 py-2 text-white font-bold ml-16">0</span>
				</div>
				
				<form class="w-4/5 sm:w-1/2 mb-4 sm:m-0">
				<!-- <form @submit.prevent="toManage()"> -->
					<button class="w-full group relative flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-sm text-white bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out">
						Rajouter des crédits
					</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import axios from "axios"

export default {
	name: 'Credit',
	data() {
			return {

			}
		},
	props: {
	},
	metaInfo(){
		return {
			title: `Mon Abonnement - Aestima`,
			meta: [{
				name: 'description',
				content: "Cette page est la page d'abonnement de l'utilisateur."
			}]
			//keywords
		}
	},
	components: {
	},
	created: function () {
		axios.post("/session-un-sub", {userId: this.user._id})
			.then(function(result) {
			
			})
			.catch(err => {
				console.log("message 1",err)
			})
  	},
	computed: {
		...mapGetters({
			authenticated: 'users/authenticated',
			user: 'users/user',
			listProj: 'projects/projects',
			user: 'users/user'
		}),
	},
	methods: {
		...mapActions({
			signOut: 'users/logout',
			getProjects: 'projects/getProjects',
			setProjectSelected: 'projects/setProjectSelected',
			updateUser: 'users/updateUser',
			updateUserMDP: 'users/updateUserMDP',
		}),
		deleteCheckoutSession(userId) {
			return axios.post("/delete-checkout-session", {userId: userId})
			.then(function(result) {
				return result.data;
			})
			.catch(err => {
				console.log("message 1",err)
			})
		},
		async toManage() {
			return await axios.post("/create-portal-session-customer", {userId: this.user._id})
			.then(function(result) {
				// return result.data;
				window.location.href = result.data.url
			})
			.catch(err => {
				console.log("message 1",err)
			})
		},
		toBilling() {
			//sub_IqHuykfArlDLVs
			this.deleteCheckoutSession(this.user._id).then(data => {
				console.log("wohoooooooo",data)
			})
			// this.createCheckoutSession(process.env.VUE_APP_STRIPE_PRICE_ID).then(function(data) {
			// // Call Stripe.js method to redirect to the new Checkout page
			// stripe
			// 	.redirectToCheckout({
			// 		sessionId: data.sessionId
			// 	})
			// 	.then(handleResult => {
			// 		console.log("handleResult", handleResult)
			// 	})
			// 	.catch(err => {
			// 		console.log("message 2",err)
			// 	})
			// });
			
		}
	},
}
</script>

<style scoped>
.shadow-inner {
	box-shadow: 0 15px 35px 0 rgba(60,66,87,.08), 0 5px 15px 0 rgba(0,0,0,.12);
}

.box-billing {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 32px;
}

.box-border {
	border-radius: 4px;
    border: 1px solid rgb(49, 52, 62);
}

.box-bt {
	border-top: 1px solid rgb(49, 52, 62);
}

.mt-popup {
	margin-top: -65px;
}
</style>