<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal justify-center items-center flex"
  >
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="relative my-auto px-4 h-full md:h-auto">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="flex justify-center items-center p-5">
          <h3 class="font-bold text-black" style="font-size: 30px">Détails des surfaces annexes</h3>
        </div>
        <div class="p-10 space-y-1">
          <div class="flex flex-row justify-between">
            <label for="balcon" class="block my-auto text-sm font-medium leading-5 text-gray-700"
              >Balcon</label
            >
            <div class="flex gap-2 justify-end">
              <input
                required
                v-model.number="balcon"
                id="balcon"
                class="w-1/2 text-right form-input block py-2 px-3 border border-gray-300 rounded-2xl shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <div class="place-self-center">m²</div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <label for="terrasseEtage" class="block my-auto text-sm font-medium leading-5 text-gray-700"
              >Terrasse étage</label
            >
            <div class="flex gap-2 justify-end">
              <input
                required
                v-model.number="terrasseEtage"
                id="terrasseEtage"
                class="w-1/2 text-right form-input block py-2 px-3 border border-gray-300 rounded-2xl shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <div class="place-self-center">m²</div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <label for="loggia" class="block my-auto text-sm font-medium leading-5 text-gray-700"
              >Loggia</label
            >
            <div class="flex gap-2 justify-end">
              <input
                required
                v-model.number="loggia"
                id="loggia"
                class="w-1/2 text-right form-input block py-2 px-3 border border-gray-300 rounded-2xl shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <div class="place-self-center">m²</div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <label for="garage" class="block my-auto text-sm font-medium leading-5 text-gray-700"
              >Garage</label
            >
            <div class="flex gap-2 justify-end">
              <input
                required
                v-model.number="garage"
                id="garage"
                class="w-1/2 text-right form-input block py-2 px-3 border border-gray-300 rounded-2xl shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <div class="place-self-center">m²</div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <label for="veranda" class="block my-auto text-sm font-medium leading-5 text-gray-700"
              >Véranda</label
            >
            <div class="flex gap-2 justify-end">
              <input
                required
                v-model.number="veranda"
                id="veranda"
                class="w-1/2 text-right form-input block py-2 px-3 border border-gray-300 rounded-2xl shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <div class="place-self-center">m²</div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <label for="remise" class="block my-auto text-sm font-medium leading-5 text-gray-700"
              >Remise</label
            >
            <div class="flex gap-2 justify-end">
              <input
                required
                v-model.number="remise"
                id="remise"
                class="w-1/2 text-right form-input block py-2 px-3 border border-gray-300 rounded-2xl shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <div class="place-self-center">m²</div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <label for="cave" class="block my-auto text-sm font-medium leading-5 text-gray-700">Cave</label>
            <div class="flex gap-2 justify-end">
              <input
                required
                v-model.number="cave"
                id="cave"
                class="w-1/2 text-right form-input block py-2 px-3 border border-gray-300 rounded-2xl shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <div class="place-self-center">m²</div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <label for="sousSol" class="block my-auto text-sm font-medium leading-5 text-gray-700"
              >Sous sol</label
            >
            <div class="flex gap-2 justify-end">
              <input
                required
                v-model.number="sousSol"
                id="sousSol"
                class="w-1/2 text-right form-input block py-2 px-3 border border-gray-300 rounded-2xl shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <div class="place-self-center">m²</div>
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <div class="flex flex-row">
              <label for="terrasseRDC" class="block my-auto text-sm font-medium leading-5 text-gray-700"
                >Terrasse RDC plein pied ou jardin</label
              >
              <QuestionMarkv2
                class="ml-2 place-self-center leading-5"
                :text="`Attention, dans ce cas, leur surface n’est pas prise en compte dans le calcul de la surface fiscal sauf si un parking sous-terrain se situe sous ce jardin ou terrasseEtage.`"
              />
            </div>
            <div class="flex gap-2 justify-end">
              <input
                required
                v-model.number="terrasseRDC"
                id="terrasseRDC"
                class="w-1/2 text-right form-input block py-2 px-3 border border-gray-300 rounded-2xl shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <div class="place-self-center">m²</div>
            </div>
          </div>
        </div>
        <div class="flex flex-col px-10 gap-4">
          <div class="flex justify-between">
            <p class="font-bold text-center">Total des surfaces annexes :</p>
            <p class="font-bold">{{ surfaceAnnexe() }} m2</p>
          </div>
          <div class="flex justify-between">
            <p class="font-bold text-center">Surface totale prise en compte :</p>
            <p class="font-bold">{{ surfaceTotal() }} m2</p>
          </div>
        </div>
        <div class="my-6">
          <div class="px-4 py-3 text-right sm:px-6 flex justify-center items-center">
            <span class="inline-flex rounded-lg shadow-sm">
              <a
                @click="close"
                class="whitespace-no-wrap inline-flex items-center justify-center px-15 py-4 border border-transparent text-base leading-6 font-medium rounded-lg text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
              >
                Enregistrer les modifications
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionMarkv2 from '@/components/SVG/QuestionMarkv2.vue';

export default {
  name: 'Surface',
  props: {
    surface: Number,
  },
  components: {
    QuestionMarkv2,
  },
  data() {
    return {
      balcon: 0,
      terrasseEtage: 0,
      loggia: 0,
      garage: 0,
      veranda: 0,
      remise: 0,
      cave: 0,
      sousSol: 0,
      terrasseRDC: 0,
      total: 0,
    };
  },
  methods: {
    close() {
      const surfaceAnnexe = this.surfaceAnnexe();
      this.$emit('close', surfaceAnnexe);
    },
    surfaceAnnexe() {
      // On ignore totalement Garage et Terrasse RDC Plein pied
      const total =
        (this.balcon + this.loggia + this.veranda + this.remise + this.cave + this.sousSol) / 2 +
        Math.min(this.terrasseEtage, 4.5);
      const plusPetit = Math.min(total, 8);
      return Math.floor(plusPetit * 10) / 10;
    },
    surfaceTotal() {
      return Number.parseInt(this.surface) + this.surfaceAnnexe();
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
