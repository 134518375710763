<template>
	<div>
		<div class="mb-10">

			<div class="mb-10">
				<div class="md:grid md:grid-cols-12 md:gap-2">
					<div class="sm:col-span-12">
						<div class="px-4 sm:p-3 sm:mx-20 flex flex-row items-center bg-lightblueaestima-100 rounded-md">
							<img class="bg-lightblueaestima-100 w-10 h-auto" src="@/assets/picto/form/aestima-graph.png" alt="pictogramme profil pro">
							<h2 class="ml-2 text-lg text-white font-bold leading-6 normal-case">VALEURS A AJUSTER POUR BOOSTER LA PERFORMANCE DE LA SIMULATION</h2>
						</div>
					</div>
					<div class="mt-5 sm:mt-0 sm:col-start-2 sm:col-end-12">
						<div class=" sm:rounded-md sm:overflow-hidden">
							<div class="px-4 py-5 bg-white sm:p-6 grid grid-cols-6 gap-6 flex items-start">
								<!-- <pre>{{newProject}}</pre> -->
								<!-- <div class="col-span-6 sm:col-span-3 text-left">
								<label for="fameublement" class="block text-sm font-medium leading-5 text-gray-700">Prix d’achat (hors frais d’acquisition)</label>
								<div class="flex flex-row items-center whitespace-no-wrap">
									<input requi v-model.number="buypricetotcalc" type="range" :min="buypriceRange.min" :max="buypriceRange.max" step="500" class="slider" id="buyprice">
									<div class="ml-4">{{newProject.carac.buyprice}} €</div>
									
								</div>
							</div> -->
								<!-- <div class="col-span-6 sm:col-span-3 text-left">
								<label for="fameublement" class="block text-sm font-medium leading-5 text-gray-700">Choix du régime fiscal d’investissement</label>
								<select v-model="filterSelectedFormeJuridique" @change="setFormeJurSelected($event)" id="formejuridique" class="forme-jur mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
									<option  v-for="(fj,index) in newProject.carac.formejuridique" :key="fj.text" 
									:value="index">{{fj.text}}</option>
								</select>
							</div> -->
								<div class="col-span-6 sm:col-span-3 text-left">
									<label for="fameublement" class="block text-sm font-medium leading-5 text-gray-700">Loyer mensuel hors charges</label>
									<div class="flex flex-row items-center whitespace-no-wrap">
										<input requi v-model.number="loyermensuelcalc" type="range" :min="loyerRange.min" :max="loyerRange.max" step="10" class="slider" id="loyermensuel">
										<div class="ml-4">{{ newProject.carac.loyermensuel }} €</div>
									</div>
								</div>
								<div v-show="formeJuridiqueSelected(['sciis', 'sasis', 'sasuis'])" class="col-span-6 sm:col-span-3">
									<div class="grid grid-cols-8 gap-6 flex justify-between items-center">
										<div class="col-span-6 sm:col-span-5 relative text-left">
											<label for="inputDividende" class="inline-block text-sm font-medium leading-5 text-gray-700">Versement des dividendes aux associés de la SCI</label>
										</div>
										<div class="col-span-6 sm:col-span-1 flex items-center">
											<input v-model="newProject.carac.dividendeSCI" id="inputDividende" type="checkbox" class="focus:ring-lightblueaestima-100 h-4 w-4 text-lightblueaestima-100 border-gray-300 rounded ">
										</div>
									</div>
								</div>
								<div class="col-span-6 sm:col-span-3 text-left">
									<label for="fameublement" class="block text-sm font-medium leading-5 text-gray-700">Durée de détention du bien</label>
									<div class="flex flex-row items-center whitespace-no-wrap">
										<input requi v-model.number="dureedetent" type="range" min="5" max="30" step="1" class="slider" id="dureedetention">
										<div class="ml-4">{{ newProject.carac.dureedetention.val }} années</div>
									</div>
								</div>
								<div class="col-span-6 sm:col-span-3 text-left">
									<label for="dureeempr" class="block text-sm font-medium leading-5 text-gray-700">Durée de l'emprunt (en années)</label>
									<div class="flex flex-row items-center whitespace-no-wrap">
										<input requi v-model="dureeemprcalc" type="range" min="5" max="30" step="1" class="slider" id="dureeempr">
										<div class="ml-4">{{ newProject.carac.dureeempr.val }} années</div>
									</div>
								</div>
								<div class="col-span-6 sm:col-span-3 text-left">
									<label for="fameublement" class="block text-sm font-medium leading-5 text-gray-700">Estimation de revalorisation annuelle du marché immobilier</label>
									<div class="flex flex-row items-center whitespace-no-wrap">
										<!-- <div class="input-symbol-percentage">
										<input requi  type="number" step="any" max="10" v-model="estimationrevalcal" id="estimreval" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
									</div>
									<div class="ml-4">{{newProject.carac.estimreval.per}} %</div> -->
										<input requi v-model.number="estimationrevalcal" type="range" min="-10" max="10" step="0.25" class="slider" id="loyermensuel">
										<div class="ml-4">{{ newProject.carac.estimreval.per }} %</div>
									</div>
								</div>
								<!-- <div class="my-4 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative sm:col-start-1 sm:col-end-5">
								<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../assets/picto/ampoule.png" alt="">
									<div>
										<div class="flex flex-row items-center justify-between text-lightblueaestima-100 relative z-10">
											<p class="mr-10"><b>Loyer moyen / m²  </b></p>
											<div class="flex flex-col text-center">
												<p class="text-lightblueaestima-100">Bas</p>
												<p v-if="loyerMoyen.min == 0" class="text-lightblueaestima-100">Pas assez de référence</p>
												<p v-else class="text-lightblueaestima-100">{{Math.round(loyerMoyen.min)}} €</p>
											</div>
											<div class="flex flex-col text-center">
												<p class="text-lightblueaestima-100 font-bold">Moyen</p>
												<p v-if="loyerMoyen.moy == 0" class="text-lightblueaestima-100">Pas assez de référence</p>
												<p v-else class="text-lightblueaestima-100">{{Math.round(loyerMoyen.moy)}} €</p>
											</div>
											<div class="flex flex-col text-center">
												<p class="text-lightblueaestima-100">Haut</p>
												<p v-if="loyerMoyen.max == 0" class="text-lightblueaestima-100">Pas assez de référence</p>
												<p v-else class="text-lightblueaestima-100">{{Math.round(loyerMoyen.max)}} €</p>
											</div>
										</div>
									</div>
							</div> -->
								<div class="my-4 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative col-span-3">
									<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../assets/picto/ampoule.png" alt="">
									<div>
										<div class="flex flex-row text-left items-center text-lightblueaestima-100 relative z-10">
											<b>Estimation du prix de revente net </b>
											<div class="col-span-6 sm:col-span-2 flex items-center">
												<input requi type="text" step="any" :value="estimationprixreventecal" id="estimprixrevente" class="sm:text-lg text-right font-bold mt-1 focus:border-none focus:outline-none block w-full py-2 px-3  transition duration-150 ease-in-out sm:leading-5" readonly>
												<span class="font-bold">€</span>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
						<div>
							<button @click="timeline()" class=" bg-lightblueaestima-100 font-normal text-white px-6 py-3">Lancer l'optimisation</button>
						</div>
					</div>
				</div>
			</div>

			<div>
				<div class="md:grid md:grid-cols-11 md:gap-2">
					<div class="sm:col-span-11">
						<div class="px-4 sm:p-3 sm:mx-20 flex flex-row items-center bg-lightblueaestima-100 rounded-md">
							<img class="bg-lightblueaestima-100 w-10 h-auto" src="@/assets/picto/form/aestima-graph.png" alt="pictogramme profil pro">
							<h2 class="ml-2 text-lg text-white font-bold leading-6 normal-case">IMPACT DE CES VARIATIONS SUR LES RATIOS DE PERFORMANCES DE L’INVESTISSEMENT</h2>
						</div>
					</div>
					<template v-if="resultats != null">
						<div class="sm:mt-6 sm:col-start-2 sm:col-span-3 flex flex-col">
							<p class="text-base font-normal"><span class="text-xl font-bold">Apport à fournir à l’achat</span></br>avant d’encaisser des loyers et de payer des charges</p>
							<p class="text-lg text-lightblueaestima-100 font-bold"><span class="text-xl">{{ numberWithSpaces(Math.round(this.resCoutTotalFonc.resCoutTotalAcqui[0] + this.resInvTravFonc.resEmprunt[0] + this.resInvTravFonc.resFraisBanc[0] + this.resInvTravFonc.resInvTrav[0])) }}</span> €</p>
							<!-- <p class="text-lg text-lightblueaestima-100 font-bold"><span class="text-xl">{{numberWithSpaces(Math.round(this.resCoutTotalFonc.resCoutTotalAcqui[0]))}} / {{numberWithSpaces(Math.round(this.resInvTravFonc.resEmprunt[0]))}} / {{numberWithSpaces(Math.round(this.resInvTravFonc.resFraisBanc[0]))}} / {{numberWithSpaces(Math.round(this.resInvTravFonc.resInvTrav[0]))}}</span> €</p> -->
						</div>
						<div class="sm:mt-6 sm:col-span-3 flex flex-col">
							<p class="text-base font-normal"><span class="text-xl font-bold">Cash-flow mensuel</span></br>avant remboursement de l’emprunt</p>
							<p class="text-lg text-lightblueaestima-100 font-bold"><span class="text-xl">{{ numberWithSpaces(cashFlowComputed[0]) }}</span> €</p>
						</div>
						<div class="sm:mt-6 sm:col-span-3 flex flex-col">
							<p class="text-base font-normal"><span class="text-xl font-bold">Cash-flow mensuel</span></br>après remboursement de l’emprunt</p>
							<p class="text-lg text-lightblueaestima-100 font-bold"><span class="text-xl">{{ numberWithSpaces(cashFlowComputed[1]) }}</span> €</p>
						</div>
						<div class="sm:mt-6 sm:col-start-2 sm:col-span-3 flex flex-col">
							<p class="text-xl font-bold">Rentabilité nette après fiscalité</p>
							<p :class="{ 'text-red-600': Number(this.resFluxAIFonc.performance.avecFisc.tir) == 0, 'text-lightblueaestima-100': Number(this.resFluxAIFonc.performance.avecFisc.tir) != 0 }" class="text-lg font-bold"><span class="text-xl">{{ Number(this.resFluxAIFonc.performance.avecFisc.tir) == 0 ? "Négatif" : this.resFluxAIFonc.performance.avecFisc.tir + "%" }}</span> </p>
						</div>
						<pre></pre>
						<div class="sm:mt-6 sm:col-span-3 flex flex-col">
							<p class="text-xl font-bold">Profit généré par l’investissement</p>
							<p class="text-lg text-lightblueaestima-100 font-bold"><span class="text-xl">{{ numberWithSpaces(Math.round(this.resFluxAIFonc.rendementTotal.total.val)) }}</span> €</p>
						</div>
						<div class="sm:mt-6 sm:col-span-3 flex flex-col">
							<p class="text-xl font-bold">Imposition totale de l’investissement</p>
							<p class="text-lg text-lightblueaestima-100 font-bold"><span class="text-xl">{{ numberWithSpaces(Math.round(-this.resFluxAIFonc.resTotImposition - this.resFluxAIFonc.resTotVersDiv)) }}</span> €</p>
						</div>
						<div class="sm:mt-6 sm:col-start-2 sm:col-span-4 flex flex-col">
							<p class="text-xl font-bold">Mode de financement de l’investissement</p>
							<div class="my-2 pl-10 w-full">
								<div class="flex flex-row items-center justify-between">
									<div class="flex flex-row items-center justify-between w-3/5">
										<p class="font-semibold text-xs">Financé par les revenus locatifs</p>
										<span class="w-10 h-4 chart-blue block rounded-md"></span>
									</div>
									<p class="text-lg text-black font-base"><span class="text-lg">{{ numberWithSpaces(Math.round(this.resOperationNetFonc.resTotRevLoc)) }}</span> €</p>
								</div>
								<div class="flex flex-row items-center justify-between">
									<div class="flex flex-row items-center justify-between w-3/5">
										<p class="font-semibold text-xs">Financé par l’apport d’épargne</p>
										<span class="w-10 h-4 chart-blue-light block rounded-md"></span>
									</div>
									<p class="text-lg text-black font-base"><span class="text-lg">{{ numberWithSpaces(-Math.round(this.resFluxAIFonc.resTotSommeInvest)) }}</span> €</p>
								</div>
							</div>
							<doughnut-chart :width="150" :height="150" class="mt-4" :options="doughnutPieOptions(false)" :chartdata="doughnutChartdata(['Financé par les revenus locatifs: %', 'Dont impôt sur la plus-value de revente: %'], [],
							[apportEpargneGraphComputed[0], apportEpargneGraphComputed[1]], ['#0C1E3B', '#14A0EB'])" />
						</div>
						<div class="sm:mt-6 sm:col-start-7 sm:col-span-4 flex flex-col">
							<p class="text-xl font-bold">Répartition de l’imposition totale sur l’investissement</p>
							<div class="my-2 pl-10 w-full">
								<div class="flex flex-row items-center justify-between">
									<div class="flex flex-row items-center justify-between w-3/5">
										<p class="font-semibold text-xs text-left">Dont impôt payé sur les revenus locatifs</p>
										<span class="w-10 h-4 chart-blue block rounded-md"></span>
									</div>
									<p class="text-lg text-black font-base"><span class="text-lg">{{ numberWithSpaces(-Math.round(this.resFluxAIFonc.resTotImpTMISeul + this.resFluxAIFonc.resTotImpTMIAss.associateFirst + this.resFluxAIFonc.resTotImpTMIAss.associateSecond + this.resFluxAIFonc.resTotImpTMIAss.associateThird + this.resFluxAIFonc.resTotImpTMIAss.associateFourth + this.resFluxAIFonc.resTotImpSoc + this.resFluxAIFonc.resTotPrelSoc + this.resFluxAIFonc.resTotCSG + this.resFluxAIFonc.resTotPrelSocAss.associateFirst + this.resFluxAIFonc.resTotPrelSocAss.associateSecond + this.resFluxAIFonc.resTotPrelSocAss.associateThird + this.resFluxAIFonc.resTotPrelSocAss.associateFourth)) }}</span> €</p>

								</div>
								<div class="flex flex-row items-center justify-between">
									<div class="flex flex-row items-center justify-between w-3/5">
										<p class="font-semibold text-xs text-left">Dont impôt sur la plus-value de revente</p>
										<span class="w-10 h-4 chart-blue-light block rounded-md"></span>
									</div>
									<p class="text-lg text-black font-base"><span class="text-lg">{{ numberWithSpaces(Math.round(-this.resFluxAIFonc.resTotPV)) }}</span> €</p>
								</div>
							</div>
							<doughnut-chart :width="150" :height="150" class="mt-4" :options="doughnutPieOptions(false)" :chartdata="doughnutChartdata(['Dont impôt payé sur les revenus locatifs: %', 'Dont impôt sur la plus-value de revente: %'], [],
							[repartitionImpotGraphComputed[0], repartitionImpotGraphComputed[1]], ['#0C1E3B', '#14A0EB'])" />
						</div>
					</template>
				</div>
			</div>


		</div>

	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import axios from "axios"
import moment from 'moment'
import DoughnutChart from '@/components/Charts/DoughnutChart.vue'

export default {
	name: 'Optimisation',
	data() {
		return {
			newProject: null,
			loyerRange: { min: 0, max: 0 },
			buypriceRange: { min: 0, max: 0 },

			timelineFonc: {},
			resCoutTotalFonc: {},
			resOperationNetFonc: {},
			resInvTravFonc: {},
			resFluxAIFonc: {},

			resComptSoumisImpot: [],

			// Variable pour rassembler les valeurs indispensble au calcul de "Cagnotte et base"
			cagnotte_base: {
				resRevLoc: [],
				resFraisExploit: [],
				resStructureSCI: [],
				resInvTrav: [],
				resItr: [],
				resFraisBanc: [],
				resAssEmpr: [],
				resFraisNotaire: [],
				resFraisAgenceImmo: [],
			},
			// Variable pour rassembler les valeurs indispensble au calcul de la partie bancaire
			calcul_global: {
				resPrixAchat: [],
				resPrixRevente: [],
			},
			resultats: null,
		}
	},
	props: {
	},
	metaInfo() {
		return {
			title: `Optimisation - Aestima`,
			meta: [{
				name: 'description',
				content: "Cette page est la page d'optimisation."
			}]
			//keywords
		}
	},
	components: {
		DoughnutChart,
	},
	mounted() {

	},
	created: function () {
		this.newProject = { ...this.project_optimisation }
		this.loyerRange = {
			min: 0.5 * Number(this.newProject.carac.loyermensuel),
			max: 1.5 * Number(this.newProject.carac.loyermensuel)
		}
		this.buypriceRange = {
			min: 0.7 * Number(this.newProject.carac.buyprice),
			max: 1.2 * Number(this.newProject.carac.buyprice)
		}

		// ////console.log(this.newProject.carac.type_fisc)

		let bool = false
		this.newProject.carac.formejuridique.map(val => {

			if (val.selected == true) {
				['sciis', 'sciirmf', 'sciirr', 'sasis'].map(index => {
					if (val.code == index) {
						return bool = true
					}
				})
			}
		})

		if (bool == false) {
			this.newProject.carac.type_fisc = [
				{
					idInvest: this.investSelected._id,
					nameInvest: this.investSelected.name,
					capital: 50,
					apport: 0,
					tmi: this.investSelected.tmi,
				},
				{
					idInvest: this.investSelected._id,
					nameInvest: this.investSelected.name,
					capital: 50,
					apport: 0,
					tmi: this.investSelected.tmi,
				},
			]
		}

		this.newProject.name = this.newProject.name + ' Optimisé'
	},
	computed: {
		...mapGetters({
			authenticated: 'users/authenticated',
			user: 'users/user',
			listProj: 'projects/projects',
			projectSelected: 'projects/selected',
			project_optimisation: 'projects/project_optimisation',
			investSelected: 'invests/selected',
			globals: 'projects/globals',
		}),
		apportEpargneGraphComputed: {
			get: function () {

				return [Math.round((Math.round(-Math.round(this.resOperationNetFonc.resTotRevLoc)) * 100) / Math.round(this.resOperationNetFonc.resTotRevLoc - this.resFluxAIFonc.resTotSommeInvest)),
				Math.round((-Math.round(this.resFluxAIFonc.resTotSommeInvest) * 100) / Math.round(this.resOperationNetFonc.resTotRevLoc - this.resFluxAIFonc.resTotSommeInvest))]
			},
			set: function (val) {
			}
		},
		repartitionImpotGraphComputed: {
			get: function () {

				return [Math.round((Math.round(-Math.round(this.resFluxAIFonc.resTotImpTMISeul + this.resFluxAIFonc.resTotImpTMIAss.associateFirst + this.resFluxAIFonc.resTotImpTMIAss.associateSecond + this.resFluxAIFonc.resTotImpTMIAss.associateThird + this.resFluxAIFonc.resTotImpTMIAss.associateFourth + this.resFluxAIFonc.resTotImpSoc + this.resFluxAIFonc.resTotPrelSoc + this.resFluxAIFonc.resTotCSG + this.resFluxAIFonc.resTotPrelSocAss.associateFirst + this.resFluxAIFonc.resTotPrelSocAss.associateSecond + this.resFluxAIFonc.resTotPrelSocAss.associateThird + this.resFluxAIFonc.resTotPrelSocAss.associateFourth)) * 100) / Math.round(-this.resFluxAIFonc.resTotImposition - this.resFluxAIFonc.resTotVersDiv)),
				Math.round((Math.round(-this.resFluxAIFonc.resTotPV) * 100) / Math.round(-this.resFluxAIFonc.resTotImposition - this.resFluxAIFonc.resTotVersDiv))]
			},
			set: function (val) {
			}
		},
		cashFlowComputed: {
			get: function () {
				let cashFlowAvantRemb = 0
				let cashFlowAvantRembCounter = 0
				let cashFlowApresRemb = 0
				let cashFlowApresRembCounter = 0
				for (let i = 0; i <= this.timelineFonc.resTimelineEmpr.length; i++) {
					if (i > 0 && this.timelineFonc.resTimelineEmpr[i] == 1) {
						// console.log(`this.resFluxAIFonc.resCashFlowActu ${i}`, this.resFluxAIFonc.resCashFlowActu[i])
						cashFlowAvantRemb += this.resFluxAIFonc.resCashFlowActu[i]
						cashFlowAvantRembCounter += 1
					}
					if (this.timelineFonc.resTimelineEmpr[i] == 0 && i != this.timelineFonc.resTimelineEmpr.length - 1) {
						cashFlowApresRemb += this.resFluxAIFonc.resCashFlowActu[i]
						cashFlowApresRembCounter += 1
					}
				}
				return [isNaN(Number(Math.round((cashFlowAvantRemb / cashFlowAvantRembCounter) / 12))) ? 0 : Math.round((cashFlowAvantRemb / cashFlowAvantRembCounter) / 12), isNaN(Number(Math.round((cashFlowApresRemb / cashFlowApresRembCounter) / 12))) ? 0 : Math.round((cashFlowApresRemb / cashFlowApresRembCounter) / 12)]
			},
			set: function (val) {
			}
		},
		buypricetotcalc: {
			get: function () {

				return this.newProject.carac.buyprice
			},
			set: function (val) {
				this.newProject.carac.buyprice = Number(val)

				this.newProject.carac.fnotaire.val = this.newProject.carac.fnotaire.fnotairePercentage.val * val
				this.newProject.carac.fnotaire.valAuto = this.newProject.carac.fnotaire.fnotairePercentage.val * val
				this.newProject.carac.fnotaire.per = (this.newProject.carac.fnotaire.val * 100) / val


				this.newProject.carac.fbancaire.val = this.newProject.carac.fbancaire.fbancairePercentage.val * val
				this.newProject.carac.fbancaire.valAuto = this.newProject.carac.fbancaire.fbancairePercentage.val * val

				this.newProject.carac.fgarantie.val = this.newProject.carac.fgarantie.fgarantiePercentage.val * val
				this.newProject.carac.fgarantie.valAuto = this.newProject.carac.fgarantie.fgarantiePercentage.val * val
				// this.newProject.carac.montantemprunte.val = this.newProject.carac.buypricetot + this.newProject.carac.fnotairetot + this.newProject.carac.fagencetot + this.newProject.carac.trvametot + this.newProject.carac.fbancairetot
				// this.newProject.carac.montantemprunte.val = Number(val) + this.newProject.carac.fnotairetot + this.newProject.carac.fagencetot + this.newProject.carac.trvametot + this.newProject.carac.fbancairetot
				this.newProject.carac.montantemprunte.val = Number(val) + this.newProject.carac.fnotairetot + this.newProject.carac.fagencetot + this.newProject.carac.fbancairetot
				this.newProject.carac.montantemprunte.valAuto = this.newProject.carac.montantemprunte.val * this.newProject.carac.montantemprunte.montantempruntePercentage.val
				console.log("val / this.newProject.carac.fnotairetot /this.newProject.carac.fagencetot / this.newProject.carac.fbancairetot", val, this.newProject.carac.fnotairetot, this.newProject.carac.fagencetot, this.newProject.carac.fbancairetot)

				this.newProject.carac.coutacquisition = Number((Number(this.newProject.carac.buyprice) + Number(this.newProject.carac.fnotaire.val) + Number(this.newProject.carac.fagence.val)).toFixed(2))

				this.newProject.carac.estimprixrevente = Math.round(Number(this.newProject.carac.buyprice) * (1 + this.newProject.carac.estimreval.val) ** this.newProject.carac.dureedetention.val)
			}
		},
		estimationrevalcal: {
			get() {
				return this.newProject.carac.estimreval.per

			},
			set(val) {
				this.newProject.carac.estimreval.per = val
				this.newProject.carac.estimreval.val = val / 100
			}
		},
		estimationprixreventecal: {
			get() {
				if (this.newProject.carac.dureedetention.isAuto == true) {
					this.newProject.carac.estimprixrevente = Math.round(Number(this.newProject.carac.buyprice) * (1 + this.newProject.carac.estimreval.val) ** (this.newProject.carac.dureedetention.valAuto / 12))
					////console.log('yes')
				} else {
					this.newProject.carac.estimprixrevente = Math.round(Number(this.newProject.carac.buyprice) * (1 + this.newProject.carac.estimreval.val) ** this.newProject.carac.dureedetention.val)
					////console.log('no', this.newProject.carac.buyprice, this.newProject.carac.estimreval.val, this.newProject.carac.dureedetention.val, this.newProject.carac.estimprixrevente)
				}
				return Math.round(Number(this.newProject.carac.estimprixrevente.toFixed(2)))

			},
		},

		dureeemprcalc: {
			get() {
				return this.newProject.carac.dureeempr.val

			},
			set(val) {
				// Calcul annuité constante
				this.newProject.carac.dureeempr.val = val
				this.newProject.carac.dureeempr.valAuto = val * 12
				let me = this.newProject.carac.montantemprunte.isAuto == true ? Number(this.newProject.carac.montantemprunte.valAuto) : Number(this.newProject.carac.montantemprunte.val)
				let ti = (this.newProject.carac.tinteretempr.isAuto == true ? Number(this.newProject.carac.tinteretempr.tinteretemprPercentage.val) : (Number(this.newProject.carac.tinteretempr.per) / 100))
				let di = this.newProject.carac.dureeempr.isAuto == true ? Number(this.newProject.carac.dureeempr.valAuto) : Number(this.newProject.carac.dureeempr.val)

				this.newProject.carac.aconstante.val = Math.round(me * (ti / (1 - (1 + ti) ** -Number(di))))
				this.newProject.carac.aconstante.valper = Number(this.newProject.carac.aconstante.val) / me
				this.newProject.carac.aconstante.per = Number((this.newProject.carac.aconstante.valper * 100).toFixed(2))
			}
		},
		dureedetent: {
			get() {
				return this.newProject.carac.dureedetention.val

			},
			set(val) {
				this.newProject.carac.dureedetention.valmonth = Number(val) * 12
				this.newProject.carac.dureedetention.val = Number(val)

				let dateacquiSplit = this.investSelected.date_acqui.split('-')

				this.newProject.carac.daterevente = (Number(dateacquiSplit[0]) + Number(this.newProject.carac.dureedetention.val) + "-" + dateacquiSplit[1]).toString()
			}
		},
		loyermensuelcalc: {
			get() {
				return this.newProject.carac.loyermensuel

			},
			set(val) {
				this.newProject.carac.loyermensuel = Number(val)
				// TODO
				this.newProject.carac.revenulocatif = Number(val) * 12
				// this.newProject.carac.revenulocatif =  this.isSecondForm == false ? Number(val) * 12 : (this.typeloc_surface.map(item => item.loyer).reduce((prev, next) => Number(prev) + Number(next))) * 12

			}
		},
		plusvaluecal: {
			get() {
				this.newProject.carac.plusvalue = this.newProject.carac.estimprixrevente - this.newProject.carac.coutacquisition
				return Math.round(Number(this.newProject.carac.plusvalue.toFixed(2)))

			},
		},
		filterSelectedFormeJuridique: {
			get() {
				let indexForm = 0
				this.newProject.carac.formejuridique.map((val, index) => {
					if (val.selected == true) {
						indexForm = index
					}
				})
				return indexForm
			},
			set: function (val) {

			}

		},

	},
	methods: {
		...mapActions({
			signOut: 'users/logout',
			getProjects: 'projects/getProjects',
			setProjectSelected: 'projects/setProjectSelected',
			updateUser: 'users/updateUser',
			updateUserMDP: 'users/updateUserMDP',
			setProjectOptimisation: 'projects/setProjectOptimisation',
			setProjectOptimisationSelected: 'projects/setProjectOptimisationSelected',
			setProjectOptimisationRun: 'projects/setProjectOptimisationRun',
		}),
		setFormeJurSelected(event) {
			// this.isFormSelected(event)
			// ////console.log("ye")
			this.newProject.carac.formejuridique.map(val => val.selected = false)
			this.newProject.carac.formejuridique[event.target.value].selected = true
			////console.log(this.newProject.carac.formejuridique)
		},
		numberWithSpaces(x) {
			var parts = x.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
			return parts.join(".");
		},
		formeJuridiqueSelected(value) {

			let bool = false
			this.newProject.carac.formejuridique.map(val => {

				if (val.selected == true) {
					value.map(index => {
						if (val.code == index) {
							return bool = true
						}
					})
				}
			})

			if (bool == true) return true
			else return false
		},
		doughnutChartdata(labels, label, data, colors) {
			let datasets = []

			let colorArray = []

			if (colors.length > 2) {
				colorArray = [
					colors[0],
					colors[1],
					colors[2],
					colors[3],
				]
			} else {
				colorArray = [
					colors[0],
					colors[1],
				]
			}

			datasets.push({
				data: data,
				label: 'doughnut',
				borderWidth: 1,
				pointRadius: 2,
				pointHitRadius: 30,
				lineTension: 0.3,
				backgroundColor: colorArray,
				borderColor: colorArray,
				pointBorderColor: '#2554FF',
			})


			let chartdata = {
				labels: labels,
				datasets: datasets,
			}

			return chartdata
		},
		doughnutPieOptions(isAxes) {
			let options = {
				scales: {
					yAxes: [{
						ticks: { min: 0 },
						gridLines: {
							display: false
						},
						display: false,
					}],
					xAxes: [{
						ticks: { min: 0 },
						gridLines: {
							display: false
						},
						display: false,
					}]
				},
				legend: {
					display: false
				},
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					animateScale: true,
					animateRotate: true
				},
				tooltips: {
					enabled: true
				},
				aspectRatio: 1,
				cutoutPercentage: 70,
			}

			return options
		},
		async onSubmit() {

			this.newProject.results = { ...this.resultats }
			this.setProjectOptimisationSelected(this.newProject)
			this.setProjectOptimisationRun(true)
			// this.isloading_modal = true

		},
		//*********************************
		// RESULTAT PARTIE
		//*********************************
		timeline() {
			this.timelineFonc = {},
				this.resCoutTotalFonc = {},
				this.resOperationNetFonc = {},
				this.resInvTravFonc = {},
				this.resFluxAIFonc = {},
				this.results = []
			//Erreur : J'avais bouclé sur la durée de l'emprunt alors que c'est la durée de détention
			// let dureeempr = this.dureeempr.isAuto == true ? Number(this.dureeempr.valAuto / 12) : Number(this.dureeempr.val)
			let dureeempr = this.newProject.carac.dureedetention.isAuto == true ? Number(this.newProject.carac.dureedetention.valAuto / 12) : Number(this.newProject.carac.dureedetention.val)
			let dateacqui = this.investSelected.date_acqui
			let daterev = this.newProject.carac.daterevente
			let dureeemprunt = this.newProject.carac.dureeempr.isAuto == true ? Number(this.newProject.carac.dureeempr.valAuto / 12) : Number(this.newProject.carac.dureeempr.val)

			let dateacquiSplit = dateacqui.split('-')
			//////console.log("dateacqui",dateacqui)
			//////console.log("dateacquiSplit1",dateacquiSplit[0])
			//////console.log("dateacquiSplit2",dateacquiSplit[1])

			let daterevSplit = daterev.split('-')
			//RES : PERIODES
			let period = []
			let totalperiod = []

			//RES : TIMELINE VALUES
			let resDateAcqui = []
			let resTmpLoc = []
			let resDateRevente = []
			let resTimelineMvt = []
			let resTimelineEmpr = []
			let resTimelineChoixLMNPNU = []
			let resTimelineChoixSCI = []
			let resTimelineChoixSCIIR = []

			let isFormJuridiqueSCI = false
			let isFormJuridiqueSCIIR = false
			let isFormJuridiqueLMNPNU = false

			this.newProject.carac.formejuridique.map((val) => {
				if (val.selected == true && (val.code == "sasis" || val.code == "sasuis" || val.code == "sciis")) {
					isFormJuridiqueSCI = true
				}
				if (val.selected == true && (val.code == "sciirmf" || val.code == "sciirr")) {
					isFormJuridiqueSCIIR = true
				}
				if (val.selected == true && (val.code == "lnmf" || val.code == "lnr" || val.code == "lmnpbic" || val.code == "lmnpr")) {
					isFormJuridiqueLMNPNU = true
				}
			})

			for (let i = 0; i <= dureeempr; i++) {
				period.push(Number(dateacquiSplit[0]) + i)
				totalperiod.push(Number(dateacquiSplit[0]) + i + "-" + 1)
				let calc = 0
				if (Number(period[i]) < Number(daterevSplit[0])) {
					resTmpLoc.push(1)
				} else {
					resTmpLoc.push(0)
				}

				if (Number(period[i]) == Number(dateacquiSplit[0])) {
					resDateAcqui.push(1)
				} else {
					resDateAcqui.push(0)
				}

				if (Number(period[i]) == Number(daterevSplit[0])) {
					resDateRevente.push(1)
				} else {
					resDateRevente.push(0)
				}

				if ((i <= (Number(dureeemprunt) - 1)) && resTmpLoc[i] == 1) {
					resTimelineEmpr.push(1)
				} else {
					resTimelineEmpr.push(0)
				}

				isFormJuridiqueLMNPNU == true ? resTimelineChoixLMNPNU.push(1) : resTimelineChoixLMNPNU.push(0)
				isFormJuridiqueSCI == true ? resTimelineChoixSCI.push(1) : resTimelineChoixSCI.push(0)
				isFormJuridiqueSCIIR == true ? resTimelineChoixSCIIR.push(1) : resTimelineChoixSCIIR.push(0)

				calc = resDateAcqui[i] + resTmpLoc[i] + resDateRevente[i]
				resTimelineMvt.push(calc)
			}

			// ////console.log("PARTIE TIMELINE : TIMELINE + ANNEES")
			////console.log("TIMELINE resDateAcqui", resDateAcqui)
			////console.log("TIMELINE resTmpLoc", resTmpLoc)
			////console.log("TIMELINE resDateRevente", resDateRevente)
			////console.log("TIMELINE resTimelineEmpr", resTimelineEmpr)
			////console.log("TIMELINE resTimelineMvt", resTimelineMvt)
			////console.log("TIMELINE resTimelineChoixLMNPNU", resTimelineChoixLMNPNU)
			////console.log("TIMELINE resTimelineChoixSCI", resTimelineChoixSCI)
			////console.log("TIMELINE resTimelineChoixSCIIR", resTimelineChoixSCIIR)
			// ////console.log("TIMELINE period", period)
			// ////console.log("TIMELINE Totalperiod", totalperiod)

			this.timelineFonc.resDateAcqui = resDateAcqui
			this.timelineFonc.resTmpLoc = resTmpLoc
			this.timelineFonc.resDateRevente = resDateRevente
			this.timelineFonc.resTimelineEmpr = resTimelineEmpr
			this.timelineFonc.resTimelineMvt = resTimelineMvt
			this.timelineFonc.resTimelineChoixLMNPNU = resTimelineChoixLMNPNU
			this.timelineFonc.resTimelineChoixSCI = resTimelineChoixSCI
			this.timelineFonc.resTimelineChoixSCIIR = resTimelineChoixSCIIR
			this.timelineFonc.period = period
			this.timelineFonc.totalperiod = totalperiod

			return this.resCoutTotal(resDateAcqui, resTmpLoc, resDateRevente, resTimelineMvt, resTimelineEmpr, dureeempr, period, resTimelineChoixLMNPNU, resTimelineChoixSCI, resTimelineChoixSCIIR)

		},
		resCoutTotal(resDateAcqui, resTmpLoc, resDateRevente, resTimelineMvt, resTimelineEmpr, dureeempr, period, resTimelineChoixLMNPNU, resTimelineChoixSCI, resTimelineChoixSCIIR) {
			let dateacqui = this.investSelected.date_acqui

			//RES : COUT TOTAL ACQUISITION
			let resPrixAchat = []
			let resPrixRevente = []
			let resFraisNotaire = []
			let resFraisAgenceImmo = []
			let resCoutTotalAcqui = []
			let resCoutTotalProfit = 0

			for (let i = 0; i <= dureeempr; i++) {
				resPrixAchat.push(- resDateAcqui[i] * this.newProject.carac.buyprice)
				resPrixRevente.push(resDateRevente[i] * this.newProject.carac.estimprixrevente)
				if (i == 0) {
					if (this.newProject.carac.fnotaire.isAuto == true) {
						resFraisNotaire.push(this.newProject.carac.fnotaire.fnotairePercentage.val * resPrixAchat[i])
					} else {
						resFraisNotaire.push((this.newProject.carac.fnotaire.per / 100) * resPrixAchat[i])
					}

					if (this.newProject.carac.fagence.isAuto == true) {
						resFraisAgenceImmo.push(- this.newProject.carac.fagence.valAuto * resDateAcqui[i])
					} else {
						resFraisAgenceImmo.push(-this.newProject.carac.fagence.val * resDateAcqui[i])
					}

				} else if (i == dureeempr) {
					resFraisNotaire.push(0)
					resFraisAgenceImmo.push(-(this.newProject.carac.fagence.per / 100) * resDateAcqui[i])
				} else {
					resFraisNotaire.push(0)
					resFraisAgenceImmo.push(-(this.newProject.carac.fagence.per / 100) * resDateAcqui[i])
				}
				resCoutTotalAcqui.push(resPrixAchat[i] + resPrixRevente[i] + resFraisNotaire[i] + resFraisAgenceImmo[i])


			}

			const reducer = (accumulator, currentValue) => accumulator + currentValue;

			this.resCoutTotalProfit = resCoutTotalAcqui.reduce(reducer)

			this.resCoutTotalAcqui = resCoutTotalAcqui

			// ////console.log("PARTIE 1 : Cout TOTAL ACQUISITION")
			// ////console.log("resPrixAchat", resPrixAchat)
			// ////console.log("resPrixRevente", resPrixRevente)
			// ////console.log("resFraisNotaire", resFraisNotaire)
			// ////console.log("resFraisAgenceImmo", resFraisAgenceImmo)
			// ////console.log("resCoutTotalAcqui", resCoutTotalAcqui)
			// ////console.log("Coût total d'acquisition TOT", resCoutTotalProfit)

			this.cagnotte_base.resFraisNotaire = resFraisNotaire
			this.cagnotte_base.resFraisAgenceImmo = resFraisAgenceImmo
			this.calcul_global.resPrixAchat = resPrixAchat
			this.calcul_global.resPrixRevente = resPrixRevente

			this.resCoutTotalFonc.resPrixAchat = resPrixAchat
			this.resCoutTotalFonc.resPrixRevente = resPrixRevente
			this.resCoutTotalFonc.resFraisNotaire = resFraisNotaire
			this.resCoutTotalFonc.resFraisAgenceImmo = resFraisAgenceImmo
			this.resCoutTotalFonc.resCoutTotalAcqui = resCoutTotalAcqui
			this.resCoutTotalFonc.resCoutTotalProfit = resCoutTotalProfit

			return this.resOperationNet(dureeempr, resTmpLoc, resDateAcqui, resTimelineEmpr, resDateRevente, period, resCoutTotalAcqui, resTimelineChoixLMNPNU, resTimelineChoixSCI, resTimelineChoixSCIIR, resPrixRevente)


		},

		resOperationNet(dureeempr, resTmpLoc, resDateAcqui, resTimelineEmpr, resDateRevente, period, resCoutTotalAcqui, resTimelineChoixLMNPNU, resTimelineChoixSCI, resTimelineChoixSCIIR, resPrixRevente) {

			let resLoyerEnt = []
			let resPertes = []
			let resRevLoc = []
			let resFraisCopro = []
			let resTaxeFonc = []
			let resFraisGestLoc = []
			let resGLI = []
			let resAutreCharge = []
			let resAssPNO = []
			let resProvPTE = []

			let resFraisExploit = []
			let resTotFraisExploit = []

			let resStructureSCI = []
			let resFraisComCGA = []
			let resCFE = []
			let resTotStructureSCI = []
			let resTotFraisComCGA = []
			let resTotCFE = []

			let resOpeNet = []
			let resTotOpeNet = []

			let resTotLoyerEnt = []
			let resTotPertes = []

			let resTotRevLoc = []
			let resTotFraisCopro = []
			let resTotTaxeFonc = []
			let resTotFraisGestLoc = []
			let resTotGLI = []
			let resTotAutreCharge = []
			let resTotAssPNO = []
			let resTotProvPTE = []

			let isFormJuridiqueStructure = false
			let isFormJuridiqueCFE = false
			let isFormJuridiqueSociete = false

			this.newProject.carac.formejuridique.map((val) => {
				if (val.selected == true && (val.code == "lmnpr" || val.code == "sasis" || val.code == "sasuis" || val.code == "sciis")) {
					isFormJuridiqueStructure = true
				}
			})

			this.newProject.carac.formejuridique.map((val) => {
				if (val.selected == true && (val.code == "lmnpr" || val.code == "sasis" || val.code == "sasuis" || val.code == "sciis" || val.code == "lmnpbic")) {
					isFormJuridiqueCFE = true
				}
			})

			this.newProject.carac.formejuridique.map((val) => {
				if (val.selected == true && (val.code == "sasis" || val.code == "sasuis" || val.code == "sciis" || val.code == "sciirmf" || val.code == "sciirr")) {
					isFormJuridiqueSociete = true
				}
			})

			for (let i = 0; i <= dureeempr; i++) {
				if (i == 0) {
					//PRIO CALCUL !!!
					resLoyerEnt.push(Math.round(resDateAcqui[i] * Number(this.newProject.carac.revenulocatif) + resTmpLoc[i] * (1 + Number(this.newProject.carac.trevalorisation.per) / 100)))
				} else {
					resLoyerEnt.push(Math.round(resDateAcqui[i] * Number(this.newProject.carac.revenulocatif) + resTmpLoc[i] * (1 + Number(this.newProject.carac.trevalorisation.per) / 100) * resLoyerEnt[i - 1]))
				}

				// if(this.rvacanceloc.isAuto == true) {
				// 	resPertes.push(Math.round(- resLoyerEnt[i] * this.rvacanceloc.rvacancelocPercentage.val))
				// } else {
				// 	resPertes.push(Math.round(- resLoyerEnt[i] * (Number(this.rvacanceloc.per) / 100)))
				// }

				resPertes.push(Math.round(- resLoyerEnt[i] * Number((this.newProject.carac.rvacanceloc.val / 365).toFixed(4))))

				if (this.newProject.carac.hgestionloc.isAuto == true) {
					resFraisGestLoc.push(Math.round(- resLoyerEnt[i] * this.newProject.carac.hgestionloc.hgestionlocPercentage.val))
				} else {
					resFraisGestLoc.push(Math.round(- resLoyerEnt[i] * (this.newProject.carac.hgestionloc.per / 100)))
				}
				//Autres Charges
				if (this.newProject.carac.chargediverse.isAuto == true) {
					resAutreCharge.push(Math.round(- resTmpLoc[i] * this.newProject.carac.chargediverse.chargediversePercentage.val))
				} else {
					resAutreCharge.push(Math.round(- resTmpLoc[i] * (this.newProject.carac.chargediverse.val)))
				}

				//FRAIS COPRO
				if (this.newProject.carac.chargecopro.isAuto == true) {
					i == 0 ?
						resFraisCopro.push(Math.round(- resDateAcqui[i] * this.newProject.carac.chargecopro.valAuto + 0 * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i])) :
						resFraisCopro.push(Math.round(- resDateAcqui[i] * this.newProject.carac.chargecopro.valAuto + resFraisCopro[i - 1] * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i]))
				} else {
					i == 0 ?
						resFraisCopro.push(Math.round(- resDateAcqui[i] * this.newProject.carac.chargecopro.val + 0 * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i])) :
						resFraisCopro.push(Math.round(- resDateAcqui[i] * this.newProject.carac.chargecopro.val + resFraisCopro[i - 1] * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i]))
				}

				//TAXE FONCIERE
				if (this.newProject.carac.tfonciere.isAuto == true) {
					i == 0 ?
						resTaxeFonc.push(Math.round(- resDateAcqui[i] * this.newProject.carac.tfonciere.valAuto + 0 * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i])) :
						resTaxeFonc.push(Math.round(- resDateAcqui[i] * this.newProject.carac.tfonciere.valAuto + resTaxeFonc[i - 1] * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i]))
				} else {
					i == 0 ?
						resTaxeFonc.push(Math.round(- resDateAcqui[i] * this.newProject.carac.tfonciere.val + 0 * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i])) :
						resTaxeFonc.push(Math.round(- resDateAcqui[i] * this.newProject.carac.tfonciere.val + resTaxeFonc[i - 1] * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i]))
				}

				//GLI
				if (this.newProject.carac.gli.isAuto == true) {
					i == 0 ?
						resGLI.push(Math.round(- resDateAcqui[i] * this.newProject.carac.gli.valAuto + 0 * (1 + (this.newProject.carac.trevalorisationOPEX.val / 100)) * resTmpLoc[i])) :
						resGLI.push(Math.round(- resDateAcqui[i] * this.newProject.carac.gli.valAuto + resGLI[i - 1] * (1 + (this.newProject.carac.trevalorisationOPEX.val / 100)) * resTmpLoc[i]))
				} else {
					i == 0 ?
						resGLI.push(Math.round(- resDateAcqui[i] * this.newProject.carac.gli.val + 0 * (1 + (this.newProject.carac.trevalorisationOPEX.val / 100)) * resTmpLoc[i])) :
						resGLI.push(Math.round(- resDateAcqui[i] * this.newProject.carac.gli.val + resGLI[i - 1] * (1 + (this.newProject.carac.trevalorisationOPEX.val / 100)) * resTmpLoc[i]))
				}

				//ASSURANCE PNO
				if (this.newProject.carac.apno.isAuto == true) {
					i == 0 ?
						resAssPNO.push(Math.round(- resDateAcqui[i] * this.newProject.carac.apno.valAuto + 0 * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i])) :
						resAssPNO.push(Math.round(- resDateAcqui[i] * this.newProject.carac.apno.valAuto + resAssPNO[i - 1] * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i]))
				} else {
					i == 0 ?
						resAssPNO.push(Math.round(- resDateAcqui[i] * this.newProject.carac.apno.val + 0 * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i])) :
						resAssPNO.push(Math.round(- resDateAcqui[i] * this.newProject.carac.apno.val + resAssPNO[i - 1] * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i]))
				}

				//Provision petits travaux d'entretien
				if (this.newProject.carac.provision.isAuto == true) {
					i == 0 ?
						resProvPTE.push(Math.round(- resDateAcqui[i] * this.newProject.carac.provision.valAuto + 0 * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i])) :
						resProvPTE.push(Math.round(- resDateAcqui[i] * this.newProject.carac.provision.valAuto + resProvPTE[i - 1] * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i]))
				} else {
					i == 0 ?
						resProvPTE.push(Math.round(- resDateAcqui[i] * this.newProject.carac.provision.val + 0 * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i])) :
						resProvPTE.push(Math.round(- resDateAcqui[i] * this.newProject.carac.provision.val + resProvPTE[i - 1] * (1 + (this.newProject.carac.trevalorisation.per / 100)) * resTmpLoc[i]))
				}

				resFraisExploit.push(Math.round(resFraisCopro[i] + resTaxeFonc[i] + resFraisGestLoc[i] + resGLI[i] + resAssPNO[i] + resProvPTE[i]))

				if (this.newProject.carac.fcomptable.isAuto == true) {
					isFormJuridiqueStructure == true ?
						resFraisComCGA.push(Math.round(resTmpLoc[i] * (- this.newProject.carac.fcomptable.valAuto))) :
						resFraisComCGA.push(-0)
				} else {
					isFormJuridiqueStructure == true ?
						resFraisComCGA.push(Math.round(resTmpLoc[i] * (- this.newProject.carac.fcomptable.val))) :
						resFraisComCGA.push(-0)
				}
				//Addition du nouveau champs frais société au premier index du tableau frais comptable
				if (i == 0) {
					isFormJuridiqueSociete == true ?
						resFraisComCGA[i] -= Number(this.newProject.carac.fsociete) :
						resFraisComCGA[i] -= 0
				}
				if (this.newProject.carac.fcotisation.isAuto == true) {
					isFormJuridiqueCFE == true ?
						resCFE.push(Math.round(resTmpLoc[i] * (- this.newProject.carac.fcotisation.valAuto))) :
						resCFE.push(-0)
				} else {
					isFormJuridiqueCFE == true ?
						resCFE.push(Math.round(resTmpLoc[i] * (- this.newProject.carac.fcotisation.val))) :
						resCFE.push(-0)
				}

				// TO-DO : CFE COMPTÉ POUR LMNP RÉEL ALORS QUE ÇA NE DEVRAIT PAS
				isFormJuridiqueStructure == true ?
					resStructureSCI.push(Math.round(resFraisComCGA[i] + resCFE[i])) :
					resStructureSCI.push(-0)

				resRevLoc.push(Math.round(resLoyerEnt[i] + resPertes[i]))

				resOpeNet.push(Math.round(resRevLoc[i] + resFraisExploit[i] + resStructureSCI[i]))

				// Push pour pouvoir mapper par la suite
				this.resComptSoumisImpot.push(0)
			}

			this.resComptSoumisImpot.map((val, index) => {
				// //////console.log("FRAIS D'EXPLOIT",(- resFraisExploit[index]))
				// //////console.log("FRAIS DE STRUCTURE SCI",(- resStructureSCI[index]))
				this.resComptSoumisImpot[index] = (- resFraisExploit[index]) + (- resStructureSCI[index])
				// this.resComptSoumisImpot[index] = resRevLoc[index] - (- resFraisExploit[index]) + (- resStructureSCI[index])

			})

			const reducer = (accumulator, currentValue) => accumulator + currentValue;

			resTotLoyerEnt = resLoyerEnt.reduce(reducer)
			resTotPertes = resPertes.reduce(reducer)
			resTotRevLoc = resRevLoc.reduce(reducer)
			resTotFraisGestLoc = resFraisGestLoc.reduce(reducer)
			resTotGLI = resGLI.reduce(reducer)
			resTotAutreCharge = resAutreCharge.reduce(reducer)
			resTotFraisCopro = resFraisCopro.reduce(reducer)
			resTotTaxeFonc = resTaxeFonc.reduce(reducer)
			resTotAssPNO = resAssPNO.reduce(reducer)
			resTotProvPTE = resProvPTE.reduce(reducer)
			resTotFraisExploit = Math.round(resTotFraisCopro + resTotTaxeFonc + resTotFraisGestLoc + resTotGLI + resTotAutreCharge + resTotAssPNO + resTotProvPTE)

			resTotStructureSCI = resStructureSCI.reduce(reducer)
			resTotFraisComCGA = resFraisComCGA.reduce(reducer)
			resTotCFE = resCFE.reduce(reducer)

			resTotOpeNet = resOpeNet.reduce(reducer)

			// ////console.log("PARTIE 2 : RESULTAT OPERATIONNEL NET")
			// ////console.log("Loyers entrants", resLoyerEnt)
			// ////console.log("Perte liées à la vacance locative / loyers impayés", resPertes)
			// ////console.log("Revenus locatifs", resRevLoc)

			// ////console.log("Frais d'exploitation", resFraisExploit)

			// ////console.log("Frais de copropriété", resFraisCopro)
			// ////console.log("Taxe Foncière", resTaxeFonc)
			// ////console.log("Frais de gestion locative", resFraisGestLoc)
			// ////console.log("Garantie loyers impayés (GLI)", resGLI)
			// ////console.log("Autres charges", resAutreCharge)
			// ////console.log("Assurance PNO", resAssPNO)
			// ////console.log("Provisions annuelles  pour petits travaux d'entretien", resProvPTE)

			// ////console.log("Frais de structure SCI", resStructureSCI)
			// ////console.log("Frais comptable + CGA", resFraisComCGA)
			// ////console.log("CFE", resCFE)
			// ////console.log("RESULTAT OPERATIONNEL NET", resOpeNet)

			this.cagnotte_base.resRevLoc = resRevLoc
			this.cagnotte_base.resFraisExploit = resFraisExploit
			this.cagnotte_base.resStructureSCI = resStructureSCI

			this.resOperationNetFonc.resLoyerEnt = resLoyerEnt
			this.resOperationNetFonc.resPertes = resPertes
			this.resOperationNetFonc.resRevLoc = resRevLoc
			this.resOperationNetFonc.resFraisExploit = resFraisExploit
			this.resOperationNetFonc.resFraisCopro = resFraisCopro
			this.resOperationNetFonc.resTaxeFonc = resTaxeFonc
			this.resOperationNetFonc.resFraisGestLoc = resFraisGestLoc
			this.resOperationNetFonc.resGLI = resGLI
			this.resOperationNetFonc.resAutreCharge = resAutreCharge
			this.resOperationNetFonc.resAssPNO = resAssPNO
			this.resOperationNetFonc.resProvPTE = resProvPTE
			this.resOperationNetFonc.resStructureSCI = resStructureSCI
			this.resOperationNetFonc.resFraisComCGA = resFraisComCGA
			this.resOperationNetFonc.resCFE = resCFE
			this.resOperationNetFonc.resOpeNet = resOpeNet

			//////console.log("------------------TOTAUX-------------------")
			//////console.log("resTotLoyerEnt", resTotLoyerEnt)
			//////console.log("resTotPertes", resTotPertes)
			//////console.log("resTotRevLoc", resTotRevLoc)

			//////console.log("Frais d'exploitation TOTAL", resTotFraisExploit)
			//////console.log("Frais de copropriété TOTAL", resTotFraisCopro)
			//////console.log("Taxe Foncière TOTAL", resTotTaxeFonc)
			//////console.log("Frais de gestion locative TOTAL", resTotFraisGestLoc)
			//////console.log("Autres charges TOTAL", resTotAutreCharge)
			//////console.log("Assurance PNO TOTAL", resTotAssPNO)
			//////console.log("Povision Petit Travaux TOTAL", resTotProvPTE)

			//////console.log("Frais de structure SCI TOTAL", resTotStructureSCI)
			//////console.log("Frais comptable + CGA TOTAL", resTotFraisComCGA)
			//////console.log("CFE TOTAL", resTotCFE)
			//////console.log("RESULTAT OPERATIONNEL NET TOTAL", resTotOpeNet)



			this.resOperationNetFonc.resTotLoyerEnt = resTotLoyerEnt
			this.resOperationNetFonc.resTotPertes = resTotPertes
			this.resOperationNetFonc.resTotRevLoc = resTotRevLoc
			this.resOperationNetFonc.resTotFraisExploit = resTotFraisExploit
			this.resOperationNetFonc.resTotFraisCopro = resTotFraisCopro
			this.resOperationNetFonc.resTotTaxeFonc = resTotTaxeFonc
			this.resOperationNetFonc.resTotFraisGestLoc = resTotFraisGestLoc
			this.resOperationNetFonc.resTotGLI = resTotGLI
			this.resOperationNetFonc.resTotAutreCharge = resTotAutreCharge
			this.resOperationNetFonc.resTotAssPNO = resTotAssPNO
			this.resOperationNetFonc.resTotProvPTE = resTotProvPTE
			this.resOperationNetFonc.resTotStructureSCI = resTotStructureSCI
			this.resOperationNetFonc.resTotFraisComCGA = resTotFraisComCGA
			this.resOperationNetFonc.resTotCFE = resTotCFE
			this.resOperationNetFonc.resTotOpeNet = resTotOpeNet
			// return
			return this.resInvTrav(dureeempr, resTmpLoc, period, resDateAcqui, resTimelineEmpr, resDateRevente, resCoutTotalAcqui, resOpeNet, resTimelineChoixLMNPNU, resTimelineChoixSCI, resTimelineChoixSCIIR, resRevLoc, resPrixRevente)
		},

		resInvTrav(dureeempr, resTmpLoc, period, resDateAcqui, resTimelineEmpr, resDateRevente, resCoutTotalAcqui, resOpeNet, resTimelineChoixLMNPNU, resTimelineChoixSCI, resTimelineChoixSCIIR, resRevLoc, resPrixRevente) {
			let resInvTrav = []
			let resFluxAvtDette = []
			let resBalanceBanc = []
			let resEmprunt = []
			let resChargFin = []


			let resFraisBanc = []
			let resItr = []
			let resRmbCap = []
			let resAssEmpr = []
			let resRmbDette = []
			let resIndemRmbDette = []

			let resCapitalRest = []

			let resFluxAvtImpot = []

			let resTotalInvTrav = []
			let resTotFluxAvtDette = []
			let resTotBalanceBanc = []
			let resTotEmprunt = []
			let resTotChargFin = []

			let resTotFraisBanc = []
			let resTotItr = []
			let resTotRmbCap = []
			let resTotAssEmpr = []
			let resTotRmbDette = []
			let resTotIndemRmbDette = []

			let resTotCapitalRest = []

			let resTotFluxAvtImpot = []

			// let datetrvSplit = this.datetrv.split('-')

			for (let i = 0; i <= dureeempr; i++) {
				// if(Number(datetrvSplit[0]) == period[i]) {
				// } else {
				// 	resInvTrav.push(0)
				// }
				if (i == 0) {
					resInvTrav.push(-this.newProject.carac.trvame - this.newProject.carac.fameublement)
				} else {
					resInvTrav.push(0)
				}

				resFluxAvtDette.push(resCoutTotalAcqui[i] + resOpeNet[i] + resInvTrav[i])

				if (this.newProject.carac.montantemprunte.isAuto == true) {
					resEmprunt.push(resDateAcqui[i] * this.newProject.carac.montantemprunte.valAuto)

					if (this.newProject.carac.tassuranceempr.isAuto == true) {
						resAssEmpr.push(Math.round(- resTimelineEmpr[i] * this.newProject.carac.tassuranceempr.tassuranceemprPercentage.val * this.newProject.carac.montantemprunte.valAuto))
					} else {
						resAssEmpr.push(Math.round(- resTimelineEmpr[i] * this.newProject.carac.tassuranceempr.val * this.newProject.carac.montantemprunte.valAuto))
					}
				} else {
					resEmprunt.push(resDateAcqui[i] * this.newProject.carac.montantemprunte.val)

					if (this.newProject.carac.tassuranceempr.isAuto == true) {
						resAssEmpr.push(Math.round(- resTimelineEmpr[i] * this.newProject.carac.tassuranceempr.tassuranceemprPercentage.val * this.newProject.carac.montantemprunte.val))
					} else {
						resAssEmpr.push(Math.round(- resTimelineEmpr[i] * this.newProject.carac.tassuranceempr.val * this.newProject.carac.montantemprunte.val))
					}
				}

				if (i == 0) {
					resBalanceBanc.push(0)
				} else {
					resBalanceBanc.push(resCapitalRest[i - 1])
				}

				let fraisgarantie = this.newProject.carac.fgarantie.isAuto == true ? this.newProject.carac.fgarantie.valAuto : this.newProject.carac.fgarantie.val
				let fraisbancaire = this.newProject.carac.fbancaire.isAuto == true ? this.newProject.carac.fbancaire.valAuto : this.newProject.carac.fbancaire.val

				resFraisBanc.push(- resDateAcqui[i] * (Number(fraisgarantie) + Number(fraisbancaire)))

				if (this.newProject.carac.tinteretempr.isAuto == true) {
					resItr.push(Math.round(- resTimelineEmpr[i] * this.newProject.carac.tinteretempr.tinteretemprPercentage.val * (resBalanceBanc[i] + resEmprunt[i])))
				} else {
					resItr.push(Math.round(- resTimelineEmpr[i] * Number(this.newProject.carac.tinteretempr.val) * (resBalanceBanc[i] + resEmprunt[i])))
				}

				resRmbCap.push(Math.round(- resTimelineEmpr[i] * ((this.newProject.carac.aconstante.val) + resItr[i])))

				resRmbDette.push(Math.round(resDateRevente[i] * (- resBalanceBanc[i])))

				let tauxinteret = this.newProject.carac.tinteretempr.isAuto == true ? this.newProject.carac.tinteretempr.tinteretemprPercentage.val : this.newProject.carac.tinteretempr.val
				// TO-DO
				let indemnite = this.newProject.carac.ira.val == null ? resBalanceBanc[i] * 6 * tauxinteret / 12 : this.newProject.carac.ira.val

				resIndemRmbDette.push(Math.round(-resDateRevente[i] * indemnite))

				// //////console.log("PREAAAAAAAAAAMBULE -- 1", Math.round(resBalanceBanc[i]) , Math.round(resEmprunt[i]) , Math.round(resRmbCap[i]))
				// //////console.log("PREAAAAAAAAAAMBULE -- 2", resBalanceBanc[i] , resEmprunt[i] , resRmbCap[i])

				// if(i >= 19) {
				// 	resCapitalRest.push((Math.round(resBalanceBanc[i]) + Math.round(resEmprunt[i])) + Math.round(resRmbCap[i]))
				// } else {
				// 	resCapitalRest.push((resBalanceBanc[i] + resEmprunt[i]) + resRmbCap[i])
				// }
				resCapitalRest.push((Math.round(resBalanceBanc[i]) + Math.round(resEmprunt[i])) + Math.round(resRmbCap[i]))

				resChargFin.push(Math.round(resFraisBanc[i]) + Math.round(resItr[i]) + Math.round(resRmbCap[i]) + Math.round(resAssEmpr[i]))

				resFluxAvtImpot.push(Math.round(resFluxAvtDette[i]) + Math.round(resEmprunt[i]) + Math.round(resChargFin[i]) + Math.round(resRmbDette[i]) + resIndemRmbDette[i])
			}

			// this.resComptSoumisImpot.push((- resInvTrav[i]) + (- resItr[i]) + (- resFraisBanc[i]) + (- resAssEmpr[i]) + Number(this.resComptSoumisImpot[i]))

			this.resComptSoumisImpot.map((val, index) => {
				this.resComptSoumisImpot[index] = (- resInvTrav[index]) + (- resItr[index]) + (- resFraisBanc[index]) + (- resAssEmpr[index]) + val
			})


			const reducer = (accumulator, currentValue) => accumulator + currentValue;

			resTotalInvTrav = resInvTrav.reduce(reducer)
			resTotFluxAvtDette = resFluxAvtDette.reduce(reducer)
			resTotEmprunt = resEmprunt.reduce(reducer)
			resTotFraisBanc = resFraisBanc.reduce(reducer)
			resTotRmbCap = resRmbCap.reduce(reducer)
			resTotBalanceBanc = resBalanceBanc.reduce(reducer)
			resTotItr = resItr.reduce(reducer)
			resTotAssEmpr = resAssEmpr.reduce(reducer)
			resTotRmbDette = resRmbDette.reduce(reducer)
			resTotIndemRmbDette = resIndemRmbDette.reduce(reducer)
			resTotCapitalRest = resCapitalRest.reduce(reducer)
			resTotChargFin = resChargFin.reduce(reducer)
			resTotFluxAvtImpot = resFluxAvtImpot.reduce(reducer)


			////console.log("PARTIE 3 : FLUX AVANT DETTES")
			////console.log("Investissement travaux de rénovation",resInvTrav)
			////console.log("Flux Avant Dettes",resFluxAvtDette)
			////console.log("PARTIE 4 : FLUX NET AVANT IMPOTS")
			////console.log("BALANCE BANCAIRE TOT",resBalanceBanc)
			////console.log("EMPRUNT TOT",resEmprunt)
			////console.log("TOTAL CHARGE FINANCIERE",resChargFin)
			////console.log("Frais BANCAIRE TOT",resFraisBanc)
			////console.log("INTERET TOT",resItr)
			////console.log("Remboursement du capital TOT",resRmbCap)
			////console.log("ASSURANCE EMPRUNTEUR TOT",resAssEmpr)
			////console.log("REMBOURSSEMENT DETTE TOT",resRmbDette)
			////console.log("Indemnités de remboursement anticipé (IRA)",resIndemRmbDette)
			////console.log("Captital Restant TOT",resCapitalRest)
			////console.log("Flux net avant impôts",resFluxAvtImpot)

			this.cagnotte_base.resInvTrav = resInvTrav
			this.cagnotte_base.resItr = resItr
			this.cagnotte_base.resFraisBanc = resFraisBanc
			this.cagnotte_base.resAssEmpr = resAssEmpr

			this.resInvTravFonc.resInvTrav = resInvTrav
			this.resInvTravFonc.resFluxAvtDette = resFluxAvtDette
			this.resInvTravFonc.resBalanceBanc = resBalanceBanc
			this.resInvTravFonc.resEmprunt = resEmprunt
			this.resInvTravFonc.resChargFin = resChargFin
			this.resInvTravFonc.resFraisBanc = resFraisBanc
			this.resInvTravFonc.resItr = resItr
			this.resInvTravFonc.resRmbCap = resRmbCap
			this.resInvTravFonc.resAssEmpr = resAssEmpr
			this.resInvTravFonc.resRmbDette = resRmbDette
			this.resInvTravFonc.resIndemRmbDette = resIndemRmbDette
			this.resInvTravFonc.resCapitalRest = resCapitalRest
			this.resInvTravFonc.resFluxAvtImpot = resFluxAvtImpot

			//////console.log("------ Flux net avant impots TOT -------")
			// ////console.log("resTotalInvTrav TOT",resTotalInvTrav)
			// ////console.log("Flux Avant Dettes TOT",resTotFluxAvtDette)
			//////console.log("BALANCE BANCAIRE TOT",resTotBalanceBanc)
			//////console.log("EMPRUNT TOT",resTotEmprunt)
			//////console.log("TOTAL CHARGE FINANCIERE TOT",resTotChargFin)
			//////console.log("Frais BANCAIRE TOT",resTotFraisBanc)
			//////console.log("INTERET TOT",resTotItr)
			//////console.log("Remboursement du capital TOT",resTotRmbCap)
			//////console.log("ASSURANCE EMPRUNTEUR TOT",resTotAssEmpr)
			//TODO
			//////console.log("REMBOURSSEMENT DETTE TOT",resTotRmbDette)
			//////console.log("Captital Restant TOT",resTotCapitalRest)
			//////console.log("Flux net avant impôts TOT",resTotFluxAvtImpot)

			this.resInvTravFonc.resTotalInvTrav = resTotalInvTrav
			this.resInvTravFonc.resTotFluxAvtDette = resTotFluxAvtDette
			this.resInvTravFonc.resTotBalanceBanc = resTotBalanceBanc
			this.resInvTravFonc.resTotEmprunt = resTotEmprunt
			this.resInvTravFonc.resTotChargFin = resTotChargFin
			this.resInvTravFonc.resTotFraisBanc = resTotFraisBanc
			this.resInvTravFonc.resTotItr = resTotItr
			this.resInvTravFonc.resTotRmbCap = resTotRmbCap
			this.resInvTravFonc.resTotAssEmpr = resTotAssEmpr
			this.resInvTravFonc.resTotRmbDette = resTotRmbDette
			this.resInvTravFonc.resTotIndemRmbDette = resTotIndemRmbDette
			this.resInvTravFonc.resTotCapitalRest = resTotCapitalRest
			this.resInvTravFonc.resTotFluxAvtImpot = resTotFluxAvtImpot

			return this.resFluxAI(dureeempr, resTmpLoc, period, resDateAcqui, resTimelineEmpr, resDateRevente, resCoutTotalAcqui, resOpeNet, resTimelineChoixLMNPNU, resTimelineChoixSCI, resTimelineChoixSCIIR, resRevLoc, resFluxAvtImpot, resTotFluxAvtImpot, resPrixRevente)
		},

		resFluxAI(dureeempr, resTmpLoc, period, resDateAcqui, resTimelineEmpr, resDateRevente, resCoutTotalAcqui, resOpeNet, resTimelineChoixLMNPNU, resTimelineChoixSCI, resTimelineChoixSCIIR, resRevLoc, resFluxAvtImpot, resTotFluxAvtImpot, resPrixRevente) {
			let resTimelineImpPositif = []

			let timelineNU = []
			let timelineSCI = []
			let timelineLMNP = []

			let timelineNUSCIreel = []
			let timelineSCISASU = []
			let timelineLMNPreel = []
			let timelineNUSCImf = []
			let timelineLMNPbic = []

			let resAmorActif = []
			let resBaseImposable = []
			let resBaseImposableNU = []
			let resBaseImposableSCILMNP = []
			let resRetrCond = []

			let resValTaxableSCI = []

			let resImposition = []
			let resImpTMISeul = []
			let resImpSoc = []
			let resPrelSoc = []
			let resCSG = []
			let resPV = []

			let resImpTMIAss = {
				associateFirst: [],
				associateSecond: [],
				associateThird: [],
				associateFourth: [],
			}

			let resPrelSocAss = {
				associateFirst: [],
				associateSecond: [],
				associateThird: [],
				associateFourth: [],
			}

			let resFluxAprImpot = []
			let resCashFlowNonActu = []
			let resVersDiv = []
			let resCashFlowActu = []
			let resCashFlowCumul = []
			let resSommeInvest = []

			let resTotAmorActif = []
			let resTotBaseImposable = []
			let resTotValTaxableSCI = []

			let resTotImposition = []
			let resTotImpTMISeul = []
			let resTotImpSoc = []
			let resTotPrelSoc = []
			let resTotCSG = []
			let resTotPV = []

			let resTotImpTMIAss = {
				associateFirst: [],
				associateSecond: [],
				associateThird: [],
				associateFourth: [],
			}

			let resTotPrelSocAss = {
				associateFirst: [],
				associateSecond: [],
				associateThird: [],
				associateFourth: [],
			}

			let resTotFluxAprImpot = []
			let resTotCashFlowNonActu = []
			let resTotVersDiv = []
			let resTotCashFlowActu = []
			let resTotCashFlowCumul = []
			let resTotSommeInvest = []

			let resGrilleAmo = []

			/**
			 * CADRAN
			 *
			 *  PERFORMANCE
			 *  -----
			 *  RENDEMENT TOTAL
			 */
			let performance = {
				sansDette: {},
				avecDette: {},
				avecFisc: {},
			}
			let rendementTotal = {
				total: {},
				plusval: {},
				revloc: {},
			}
			let repartitionBenef = {
				associateFirst: {},
				associateSecond: {},
				associateThird: {},
				associateFourth: {},
			}



			//////console.log("------------------- PLUS VALUE TOTAL -----------------------", plusvalTOT)

			/********************************************************************************************************************************************************************************************************
			 *
			 * TABLEAU AMMORTISSEMENT DU BIEN
			 *
			 ********************************************************************************************************************************************************************************************************/
			let grilleAmo = []
			if (typeof this.newProject.carac.amortissementsparcomposant != 'undefined') {
				grilleAmo = [
					{ duree: +this.newProject.carac.amortissementsparcomposant.terrain.duree, valAmo: this.newProject.carac.buyprice * (+this.newProject.carac.amortissementsparcomposant.terrain.pourcentage / 100), amoPa: 0 }, // terrain
					{ duree: +this.newProject.carac.amortissementsparcomposant.gros_oeuvre.duree, valAmo: this.newProject.carac.buyprice * (+this.newProject.carac.amortissementsparcomposant.gros_oeuvre.pourcentage / 100), amoPa: 0 }, // gros oeuvre
					{ duree: +this.newProject.carac.amortissementsparcomposant.facade_etancheite.duree, valAmo: this.newProject.carac.buyprice * (+this.newProject.carac.amortissementsparcomposant.facade_etancheite.pourcentage / 100), amoPa: 0 }, // facade
					{ duree: +this.newProject.carac.amortissementsparcomposant.installation_generales_techniques.duree, valAmo: this.newProject.carac.buyprice * (+this.newProject.carac.amortissementsparcomposant.installation_generales_techniques.pourcentage / 100), amoPa: 0 }, // installation
					{ duree: +this.newProject.carac.amortissementsparcomposant.agencement.duree, valAmo: this.newProject.carac.buyprice * (+this.amortissementsparcomposant.agencement.pourcentage / 100), amoPa: 0 }, // agencement
					{ duree: 7, valAmo: this.fameublement, amoPa: 0 }, // mobilier
				];
			} else {
				grilleAmo = [
					{ duree: 0, valAmo: this.newProject.carac.buyprice * 0.15, amoPa: 0 },
					{ duree: 50, valAmo: this.newProject.carac.buyprice * 0.45, amoPa: 0 },
					{ duree: 20, valAmo: this.newProject.carac.buyprice * 0.10, amoPa: 0 },
					{ duree: 15, valAmo: this.newProject.carac.buyprice * 0.15, amoPa: 0 },
					{ duree: 15, valAmo: this.newProject.carac.buyprice * 0.15, amoPa: 0 },
					{ duree: 7, valAmo: this.newProject.carac.fameublement, amoPa: 0 },
				]
			}
			let grilleAmoTot = 0

			grilleAmo.forEach((val, index) => {
				if (index != 0) {
					val.amoPa = val.valAmo / val.duree
				}
				grilleAmoTot += val.amoPa
			})
			let grilleAmoLoop = []
			for (let i = 0; i <= 29; i++) {
				grilleAmoLoop.push(Math.round(grilleAmo[0].amoPa + grilleAmo[1].amoPa + (i < grilleAmo[2].duree ? grilleAmo[2].amoPa : 0) + (i < grilleAmo[3].duree ? grilleAmo[3].amoPa : 0) + (i < grilleAmo[4].duree ? grilleAmo[4].amoPa : 0) + (i < grilleAmo[5].duree ? grilleAmo[5].amoPa : 0)))
			}

			/********************************************************************************************************************************************************************************************************
			 *
			 * TABLEAU PLUS-VALUE DU BIEN
			 *
			 ********************************************************************************************************************************************************************************************************/

			let trvPV = this.newProject.carac.trvame > this.newProject.carac.buyprice * 0.15 ? Number(this.newProject.carac.trvame) : (Number(this.newProject.carac.dureedetention.val) >= 5 ? Number(this.newProject.carac.buyprice) * 0.15 : Number(this.newProject.carac.trvame))
			let prixAqMaj = Number(this.newProject.carac.buyprice) + Number(this.newProject.carac.fnotaire.val) + trvPV
			let PVBrut = Number(resPrixRevente[resPrixRevente.length - 1]) - prixAqMaj
			// ////console.log("------------------- trvPV  -----------------------", trvPV )
			// ////console.log("------------------- prixAqMaj  -----------------------", prixAqMaj )
			// ////console.log("------------------- PVBrut / resPrixRevente  -----------------------", PVBrut, resPrixRevente[resPrixRevente.length - 1] )

			let grillePVTaxe = [
				{ range: [50001, 60000], per: 0.02, isRange: 0, taxe: 0 },
				{ range: [60001, 100000], per: 0.02, isRange: 0, taxe: 0 },
				{ range: [100001, 110000], per: 0.03, isRange: 0, taxe: 0 },
				{ range: [110001, 150000], per: 0.03, isRange: 0, taxe: 0 },
				{ range: [150001, 160000], per: 0.04, isRange: 0, taxe: 0 },
				{ range: [160001, 200000], per: 0.04, isRange: 0, taxe: 0 },
				{ range: [200001, 210000], per: 0.05, isRange: 0, taxe: 0 },
				{ range: [210001, 250000], per: 0.05, isRange: 0, taxe: 0 },
				{ range: [250001, 260000], per: 0.06, isRange: 0, taxe: 0 },
				{ range: [260001, 10000000], per: 0.06, isRange: 0, taxe: 0 },
			]

			let grilleIRPS = []

			for (let i = 0; i <= 29; i++) {
				if (i < 5) {
					grilleIRPS.push([0, 0])
				}
				else if (i == 21) {
					grilleIRPS.push([1, Number((grilleIRPS[i - 1][1] + 0.016).toFixed(4))])
				}
				else if (i > 21) {
					grilleIRPS.push([1, Number((grilleIRPS[i - 1][1] + 0.09).toFixed(4))])
				} else {
					grilleIRPS.push([Number((grilleIRPS[i - 1][0] + 0.06).toFixed(4)), Number((grilleIRPS[i - 1][1] + 0.0165).toFixed(4))])
				}
			}

			let PVBrutIR = grilleIRPS[this.newProject.carac.dureedetention.val - 1][0]
			let PVBrutPS = grilleIRPS[this.newProject.carac.dureedetention.val - 1][1]
			let PVNetIR = Math.round(PVBrut - (PVBrut * PVBrutIR))
			let PVNetPS = Math.round(PVBrut - (PVBrut * PVBrutPS))
			let impIR = Math.round(PVNetIR * this.globals.ImpotPluval.val)
			let impPS = Math.round(PVNetPS * this.globals.PSbenefice.val)
			let taxePrice = 0

			// ////console.log("------------------- PVBrutIR / PVBrutPS  -----------------------", PVBrutIR, PVBrutPS )
			// ////console.log("------------------- PVNetIR / PVNetPS  -----------------------", PVNetIR, PVNetPS )
			// ////console.log("------------------- impIR / impPS  -----------------------", impIR, impPS )


			grillePVTaxe.forEach((val, index) => {
				val.isRange = PVNetIR > val.range[0] && PVNetIR < val.range[1] ? 1 : 0
				if (index % 2 == 0) { val.taxe = val.isRange * (val.per * PVNetIR - (val.range[1] - PVNetIR) * 1 / 20) }
				else { val.taxe = val.isRange * (val.per * PVNetIR) }

				taxePrice += val.taxe
			})
			// ////console.log("------------------- grillePVTaxe -----------------------", grillePVTaxe)

			let taxeTotal = Math.round(impIR + impPS + taxePrice)
			////console.log("------------------- taxeTotal -----------------------", impIR +"+"+ impPS +"+"+ taxePrice +"="+ taxeTotal)

			/********************************************************************************************************************************************************************************************************
			 *
			 * FORME JURIDIQUE
			 *
			 ********************************************************************************************************************************************************************************************************/

			let formejuridiqueSelected = this.newProject.carac.formejuridique.filter(val => val.selected == true)[0]


			////console.log("------------------- formejuridiqueSelected -----------------------", formejuridiqueSelected)
			// ////console.log("------------------- grilleAmoLoop -----------------------", grilleAmoLoop)

			const reducer = (accumulator, currentValue) => accumulator + currentValue;

			let resFraisNotaireAchat = []
			let resFraisAgenceAchat = []
			let resResComptaImpo = []
			let resResComptaImpoNU = []

			let resCagnotteNUSCI = []
			let resCagnotteOther = []
			let resBaseImpMF = []
			let resBaseImpMB = []
			let inc = 0
			for (let i = 0; i <= dureeempr; i++) {

				timelineNUSCIreel.push((formejuridiqueSelected.code == 'lnr' || formejuridiqueSelected.code == 'sciirr') ? 1 : 0)
				timelineSCISASU.push((formejuridiqueSelected.code == 'sasis' || formejuridiqueSelected.code == 'sciis' || formejuridiqueSelected.code == 'sasuis') ? 1 : 0)
				timelineLMNPreel.push((formejuridiqueSelected.code == 'lmnpr') ? 1 : 0)
				timelineNUSCImf.push((formejuridiqueSelected.code == 'lnmf' || formejuridiqueSelected.code == 'sciirmf') ? 1 : 0)
				timelineLMNPbic.push((formejuridiqueSelected.code == 'lmnpbic') ? 1 : 0)

				/********************************************************************************************************************************************************************************************************
				 *
				 * CAGNOTTE & BASE
				 *  > NU LMNP SCI
				 * L15-23
				 ********************************************************************************************************************************************************************************************************/
				resAmorActif.push(-resTmpLoc[i] * ((formejuridiqueSelected.code == 'lmnpr' || formejuridiqueSelected.code == 'sasis' || formejuridiqueSelected.code == 'sasuis' || formejuridiqueSelected.code == 'sciis') ? grilleAmoLoop[i] : 0))
				resFraisNotaireAchat.push(-(timelineLMNPreel[i] + timelineSCISASU[i]) * Number(this.cagnotte_base.resFraisNotaire[i]))
				resFraisAgenceAchat.push(-(timelineLMNPreel[i] + timelineSCISASU[i]) * Number(this.cagnotte_base.resFraisAgenceImmo[i]))
				resResComptaImpo.push(Number(this.cagnotte_base.resRevLoc[i]) - ((- Number(this.cagnotte_base.resFraisExploit[i])) + (- Number(this.cagnotte_base.resStructureSCI[i])) + (- Number(this.cagnotte_base.resInvTrav[i])) + (- Number(this.cagnotte_base.resItr[i])) + (- Number(this.cagnotte_base.resFraisBanc[i])) + (- Number(this.cagnotte_base.resAssEmpr[i])) + (-Number(resAmorActif[i])) + Number(resFraisNotaireAchat[i]) + Number(resFraisAgenceAchat[i])))
				resResComptaImpoNU.push(timelineNUSCIreel[i] * resResComptaImpo[i])
				if (i < 10) {
					resCagnotteNUSCI.push(timelineNUSCIreel[i] * (i != 0 && resCagnotteNUSCI[i - 1] < 0 ? resCagnotteNUSCI[i - 1] + resResComptaImpoNU[i] : resResComptaImpoNU[i]))
					resCagnotteOther.push(timelineSCISASU[i] == 1 || timelineLMNPreel[i] == 1 ? (i != 0 && resCagnotteOther[i - 1] < 0 ? resCagnotteOther[i - 1] + resResComptaImpo[i] : resResComptaImpo[i]) : 0)
				} else if (i == 10) {
					resCagnotteNUSCI.push(timelineNUSCIreel[i] * (resCagnotteNUSCI[i - 1] < 0 && resResComptaImpo[inc] - resCagnotteNUSCI[i - 1] >= 0 ? resResComptaImpo[i] + resCagnotteNUSCI[i - 1] - resResComptaImpo[inc] : (resCagnotteNUSCI[i - 1] < 0 && resResComptaImpo[inc] - resCagnotteNUSCI[i - 1] < 0 ? resResComptaImpo[i] : (resCagnotteNUSCI[i - 1] < 0 ? resCagnotteNUSCI[i - 1] + resResComptaImpo[i] : (resCagnotteNUSCI[i - 1] >= 0 ? resResComptaImpo[i] : 0)))))
					resCagnotteOther.push((timelineSCISASU[i] + timelineLMNPreel[i]) * (resCagnotteOther[i - 1] < 0 && resResComptaImpo[inc] - resCagnotteOther[i - 1] >= 0 ? resResComptaImpo[i] + resCagnotteOther[i - 1] - resResComptaImpo[inc] : (resCagnotteOther[i - 1] < 0 && resResComptaImpo[inc] - resCagnotteOther[i - 1] < 0 ? resResComptaImpo[i] : (resCagnotteOther[i - 1] < 0 ? resCagnotteOther[i - 1] + resResComptaImpo[i] : (resCagnotteOther[i - 1] >= 0 ? resResComptaImpo[i] : 0)))))
					inc += 1
				} else {
					resCagnotteNUSCI.push(timelineNUSCIreel[i] * ((resCagnotteNUSCI[i - 1] < 0 ? resCagnotteNUSCI[i - 1] + resResComptaImpoNU[i] : resResComptaImpoNU[i]) - (resCagnotteNUSCI[i - 1] + resResComptaImpo[i] >= 0 ? 0 : resResComptaImpo[inc])))
					resCagnotteOther.push((timelineSCISASU[i] == 1 || timelineLMNPreel[i] == 1 ? (resCagnotteOther[i - 1] < 0 ? resCagnotteOther[i - 1] + resResComptaImpo[i] : resResComptaImpo[i]) : 0) - (resCagnotteOther[i - 1] + resResComptaImpo[i] >= 0 ? 0 : resResComptaImpo[inc]))
					inc += 1
				}
				resBaseImpMF.push(timelineNUSCImf[i] > 0 ? Math.round(Number(this.cagnotte_base.resRevLoc[i]) * this.globals.revenuImpPer.microFonc.val) : 0)
				resBaseImpMB.push(timelineLMNPbic[i] > 0 ? Math.round(Number(this.cagnotte_base.resRevLoc[i]) * this.globals.revenuImpPer.microBic.val) : 0)

				// resFluxAprImpot.push(resFluxAvtImpot[i] + resImposition[i])
				// //////console.log("maximonstre", resFluxAvtImpot[i] + "+" + resImposition[i] + "  =  " + resFluxAprImpot[i])
				// if(resFluxAprImpot[i] > 0) {
				// 	resCashFlowActu.push(Math.round(resFluxAprImpot[i] / ((1 + this.tactualisation.val)**i)))
				// } else {
				// 	resCashFlowActu.push(resFluxAprImpot[i])
				// }

				// if(i == 0) {
				// 	resCashFlowCumul.push((resTmpLoc[i] + resDateRevente[i]) * (resCashFlowActu[i]))
				// } else {
				// 	resCashFlowCumul.push((resTmpLoc[i] + resDateRevente[i]) * (resCashFlowActu[i] + resCashFlowCumul[i-1]))
				// }

				// if(resFluxAprImpot[i] < 0) {
				// 	resSommeInvest.push(resFluxAprImpot[i])
				// } else {
				// 	resSommeInvest.push(0)
				// }
			}

			////console.log("------------------- cagnotte -----------------------", this.cagnotte_base)
			// ////console.log("------------------- timelines -----------------------", timelineNUSCIreel, timelineSCISASU, timelineLMNPreel, timelineNUSCImf, timelineLMNPbic)
			////console.log("------------------- resAmorActif -----------------------",resAmorActif)
			////console.log("------------------- resFraisNotaireAchat -----------------------",resFraisNotaireAchat)
			////console.log("------------------- resFraisAgenceAchat -----------------------",resFraisAgenceAchat)
			////console.log("------------------- resResComptaImpo -----------------------",resResComptaImpo)
			////console.log("------------------- resResComptaImpoNU -----------------------",resResComptaImpoNU)
			////console.log("------------------- resCagnotteNUSCI -----------------------",resCagnotteNUSCI)
			////console.log("------------------- resCagnotteOther -----------------------",resCagnotteOther)
			////console.log("------------------- resBaseImpMF -----------------------",resBaseImpMF)
			////console.log("------------------- resBaseImpMB -----------------------",resBaseImpMB)

			resTotAmorActif = resAmorActif.reduce(reducer)

			for (let i = 0; i <= dureeempr; i++) {
				resBaseImposable.push(resTmpLoc[i] * (resCagnotteNUSCI[i] + resCagnotteOther[i] + resBaseImpMF[i] + resBaseImpMB[i]))
				resValTaxableSCI.push(resDateRevente[i] * resTimelineChoixSCI[i] * (this.calcul_global.resPrixRevente[dureeempr] - (resTotAmorActif - this.calcul_global.resPrixAchat[0])))

				resTimelineImpPositif.push(resBaseImposable[i] + resValTaxableSCI[i] > 0 ? 1 : 0)

				resImpTMISeul.push(-resTimelineImpPositif[i] * resTimelineChoixLMNPNU[i] * resBaseImposable[i] * (this.investSelected.tmi / 100))

				resImpTMIAss.associateFirst.push(Math.round((formejuridiqueSelected.code == 'sciirmf' || formejuridiqueSelected.code == 'sciirr') ? -resTimelineImpPositif[i] * resTimelineChoixSCIIR[i] * resBaseImposable[i] * (this.newProject.carac.type_fisc[0].tmi / 100) * (this.newProject.carac.type_fisc[0].capital / 100) : 0))
				resImpTMIAss.associateSecond.push(Math.round((formejuridiqueSelected.code == 'sciirmf' || formejuridiqueSelected.code == 'sciirr') ? (this.newProject.carac.type_fisc.length > 1 ? -resTimelineImpPositif[i] * resTimelineChoixSCIIR[i] * resBaseImposable[i] * (this.newProject.carac.type_fisc[1].tmi / 100) * (this.newProject.carac.type_fisc[1].capital / 100) : 0) : 0))
				resImpTMIAss.associateThird.push(Math.round((formejuridiqueSelected.code == 'sciirmf' || formejuridiqueSelected.code == 'sciirr') ? (this.newProject.carac.type_fisc.length > 2 ? -resTimelineImpPositif[i] * resTimelineChoixSCIIR[i] * resBaseImposable[i] * (this.newProject.carac.type_fisc[2].tmi / 100) * (this.newProject.carac.type_fisc[2].capital / 100) : 0) : 0))
				resImpTMIAss.associateFourth.push(Math.round((formejuridiqueSelected.code == 'sciirmf' || formejuridiqueSelected.code == 'sciirr') ? (this.newProject.carac.type_fisc.length > 3 ? -resTimelineImpPositif[i] * resTimelineChoixSCIIR[i] * resBaseImposable[i] * (this.newProject.carac.type_fisc[3].tmi / 100) * (this.newProject.carac.type_fisc[3].capital / 100) : 0) : 0))

				resPrelSocAss.associateFirst.push(Math.round((formejuridiqueSelected.code == 'sciirmf' || formejuridiqueSelected.code == 'sciirr') ? -resBaseImposable[i] * resTimelineImpPositif[i] * resTimelineChoixSCIIR[i] * this.globals.PSbenefice.val * (this.newProject.carac.type_fisc[0].capital / 100) : 0))
				resPrelSocAss.associateSecond.push(Math.round((formejuridiqueSelected.code == 'sciirmf' || formejuridiqueSelected.code == 'sciirr') ? (this.newProject.carac.type_fisc.length > 1 ? -resBaseImposable[i] * resTimelineImpPositif[i] * resTimelineChoixSCIIR[i] * this.globals.PSbenefice.val * (this.newProject.carac.type_fisc[1].capital / 100) : 0) : 0))
				resPrelSocAss.associateThird.push(Math.round((formejuridiqueSelected.code == 'sciirmf' || formejuridiqueSelected.code == 'sciirr') ? (this.newProject.carac.type_fisc.length > 2 ? -resBaseImposable[i] * resTimelineImpPositif[i] * resTimelineChoixSCIIR[i] * this.globals.PSbenefice.val * (this.newProject.carac.type_fisc[2].capital / 100) : 0) : 0))
				resPrelSocAss.associateFourth.push(Math.round((formejuridiqueSelected.code == 'sciirmf' || formejuridiqueSelected.code == 'sciirr') ? (this.newProject.carac.type_fisc.length > 3 ? -resBaseImposable[i] * resTimelineImpPositif[i] * resTimelineChoixSCIIR[i] * this.globals.PSbenefice.val * (this.newProject.carac.type_fisc[3].capital / 100) : 0) : 0))

				resImpSoc.push(Math.round(resTimelineImpPositif[i] * resTimelineChoixSCI[i] * -(resBaseImposable[i] + resValTaxableSCI[i] < this.globals.plafondImpositionIS ? (resBaseImposable[i] + resValTaxableSCI[i]) * this.globals.timpositionIS.val : (resBaseImposable[i] + resValTaxableSCI[i] > this.globals.plafondImpositionIS ? (this.globals.plafondImpositionIS * this.globals.timpositionIS.val) + (resBaseImposable[i] + resValTaxableSCI[i] - this.globals.plafondImpositionIS) * this.globals.impositionIS.val : 0))))
				resPrelSoc.push(Math.round(-resTimelineImpPositif[i] * resBaseImposable[i] * resTimelineChoixLMNPNU[i] * this.globals.PSbenefice.val))
				resCSG.push(i == 0 ? 0 : -resPrelSoc[i - 1] * this.globals.CSG.val)
				resPV.push(Math.round(-resDateRevente[i] * (resTimelineChoixSCIIR[i] + resTimelineChoixLMNPNU[i]) * taxeTotal > 0 ? 0 : -resDateRevente[i] * (resTimelineChoixSCIIR[i] + resTimelineChoixLMNPNU[i]) * taxeTotal))
				resImposition.push(resImpTMISeul[i] + resImpTMIAss.associateFirst[i] + resImpTMIAss.associateSecond[i] + resImpTMIAss.associateThird[i] + resImpTMIAss.associateFourth[i] + resImpSoc[i] + resPrelSoc[i] + resCSG[i] + resPrelSocAss.associateFirst[i] + resPrelSocAss.associateSecond[i] + resPrelSocAss.associateThird[i] + resPrelSocAss.associateFourth[i] + resPV[i])

				resFluxAprImpot.push(resFluxAvtImpot[i] + resImposition[i])
				resVersDiv.push(-(resFluxAprImpot[i] > 0 && this.newProject.carac.dividendeSCI == true ? resFluxAprImpot[i] * this.globals.flatTaxPer : 0) * resTimelineChoixSCI[i])
				resCashFlowNonActu.push(resFluxAprImpot[i] + resVersDiv[i])
				resCashFlowActu.push(resCashFlowNonActu[i] > 0 ? resCashFlowNonActu[i] / (Math.pow(1 + (this.investSelected.params.params_taux.val / 100), i)) : resCashFlowNonActu[i])
				resCashFlowCumul.push((resTmpLoc[i] + resDateRevente[i]) * (resCashFlowActu[i] + (i == 0 ? 0 : resCashFlowCumul[i - 1])))
				resSommeInvest.push(resTmpLoc[i] * (resCashFlowActu[i] <= 0 ? resCashFlowActu[i] : 0))
			}

			// ////console.log("------------------- this.type_fisc -----------------------",resTimelineImpPositif, resTimelineChoixSCIIR, resBaseImposable, (this.type_fisc[0].tmi/100) , (this.type_fisc[0].capital/100))
			////console.log("------------------- resTimelineImpPositif -----------------------",resTimelineImpPositif)
			////console.log("------------------- resTimelineChoixLMNPNU -----------------------",resTimelineChoixLMNPNU)
			// ////console.log("------------------- resTimelineChoixSCIIR -----------------------",resTimelineChoixSCIIR)
			////console.log("------------------- resAmorActif -----------------------",resAmorActif)
			////console.log("------------------- resBaseImposable -----------------------",resBaseImposable)
			////console.log("------------------- resValTaxableSCI -----------------------",resValTaxableSCI)
			////console.log("------------------- resImpTMISeul -----------------------",resImpTMISeul)
			////console.log("------------------- resImpTMIAss -----------------------",resImpTMIAss)
			////console.log("------------------- resPrelSocAss -----------------------",resPrelSocAss)
			////console.log("------------------- resImpSoc -----------------------",resImpSoc)
			////console.log("------------------- resPrelSoc -----------------------",resPrelSoc)
			////console.log("------------------- resCSG -----------------------",resCSG)
			////console.log("------------------- resPV -----------------------",resPV)
			////console.log("------------------- TOTAL PLUS-VALUE -----------------------",taxeTotal)
			////console.log("------------------- IMPOSITION -----------------------",resImposition)
			////console.log("-- Flux net Après impôts --",resFluxAprImpot)
			////console.log("-- Cash flows actualisés --",resCashFlowActu)
			////console.log("-- Si versement du dividende (spécial SCI à l'IS/ SAS /SASU) imposé à la flat tax --",resVersDiv)
			////console.log("-- Cash flows non actualisés  --",resCashFlowNonActu)
			////console.log("-- Cash flows actualisés cumulés --",resCashFlowCumul)
			////console.log("-- Somme investie sur l'investissement --",resSommeInvest)



			resTotBaseImposable = resBaseImposable.reduce(reducer)
			resTotValTaxableSCI = resValTaxableSCI.reduce(reducer)
			resTotImposition = resImposition.reduce(reducer)
			resTotImpTMISeul = resImpTMISeul.reduce(reducer)
			resTotImpSoc = resImpSoc.reduce(reducer)
			resTotPrelSoc = resPrelSoc.reduce(reducer)
			resTotCSG = resCSG.reduce(reducer)
			resTotPV = resPV.reduce(reducer)
			resTotImpTMIAss.associateFirst = resImpTMIAss.associateFirst.reduce(reducer)
			resTotImpTMIAss.associateSecond = resImpTMIAss.associateSecond.reduce(reducer)
			resTotImpTMIAss.associateThird = resImpTMIAss.associateThird.reduce(reducer)
			resTotImpTMIAss.associateFourth = resImpTMIAss.associateFourth.reduce(reducer)
			resTotPrelSocAss.associateFirst = resPrelSocAss.associateFirst.reduce(reducer)
			resTotPrelSocAss.associateSecond = resPrelSocAss.associateSecond.reduce(reducer)
			resTotPrelSocAss.associateThird = resPrelSocAss.associateThird.reduce(reducer)
			resTotPrelSocAss.associateFourth = resPrelSocAss.associateFourth.reduce(reducer)

			resTotFluxAprImpot = resFluxAprImpot.reduce(reducer)
			resTotVersDiv = resVersDiv.reduce(reducer)

			resTotCashFlowNonActu = resCashFlowNonActu.reduce(reducer)
			resTotCashFlowActu = resCashFlowActu.reduce(reducer)
			resTotCashFlowCumul = resCashFlowCumul.reduce(reducer)
			resTotSommeInvest = resSommeInvest.reduce(reducer)


			this.resFluxAIFonc.resAmorActif = resAmorActif
			this.resFluxAIFonc.resBaseImposable = resBaseImposable
			this.resFluxAIFonc.resValTaxableSCI = resValTaxableSCI
			this.resFluxAIFonc.resImpTMISeul = resImpTMISeul
			this.resFluxAIFonc.resImpTMIAss = resImpTMIAss
			this.resFluxAIFonc.resImpSoc = resImpSoc
			this.resFluxAIFonc.resPrelSoc = resPrelSoc
			this.resFluxAIFonc.resCSG = resCSG
			this.resFluxAIFonc.resPrelSocAss = resPrelSocAss
			this.resFluxAIFonc.resPV = resPV
			this.resFluxAIFonc.resImposition = resImposition
			this.resFluxAIFonc.resFluxAprImpot = resFluxAprImpot
			this.resFluxAIFonc.resVersDiv = resVersDiv
			this.resFluxAIFonc.resCashFlowNonActu = resCashFlowNonActu
			this.resFluxAIFonc.resCashFlowActu = resCashFlowActu
			this.resFluxAIFonc.resCashFlowCumul = resCashFlowCumul
			this.resFluxAIFonc.resSommeInvest = resSommeInvest

			//////console.log("Résultat comptable soumis à impôts",this.resComptSoumisImpot)

			//////console.log("---------------TOTAL Flux Net Après Impôts----------------")
			////console.log("Amortissement de l'actif (en SCI) TOT",resTotAmorActif)
			////console.log("Base imposable TOT",resTotBaseImposable)
			////console.log("Valeur du bien taxable en SCI (partie non amortie) TOT",resTotValTaxableSCI)
			////console.log("Impôt sur le revenu TMI suivant le statut TOT",resTotImpTMISeul)
			////console.log("Impôt sur les sociétés selon le statut TOT",resTotImpSoc)
			////console.log("Prélèvements sociaux TOT",resTotPrelSoc)
			////console.log("CSG récupérable (revient dans notre poche) TOT",resTotCSG)
			////console.log("Plus value immobilière (en statut LMNP et NU) TOT",resTotPV)
			////console.log("-- Imposition TOT --",resTotImposition)
			////console.log("-- Flux net Après impôts TOT --",resTotFluxAprImpot)
			////console.log("-- Cash flows actualisés TOT --",resTotCashFlowActu)
			////console.log("-- Si versement du dividende (spécial SCI à l'IS/ SAS /SASU) imposé à la flat tax TOT --",resTotVersDiv)
			////console.log("-- Cash flows non actualisés  TOT --",resTotCashFlowNonActu)
			////console.log("-- Cash flows actualisés cumulés TOT --",resTotCashFlowCumul)
			////console.log("-- Somme investie sur l'investissement TOT --",resTotSommeInvest)

			this.resFluxAIFonc.resTotAmorActif = resTotAmorActif
			this.resFluxAIFonc.resTotBaseImposable = resTotBaseImposable
			this.resFluxAIFonc.resTotValTaxableSCI = resTotValTaxableSCI
			this.resFluxAIFonc.resTotImpTMISeul = resTotImpTMISeul
			this.resFluxAIFonc.resTotImpTMIAss = resTotImpTMIAss
			this.resFluxAIFonc.resTotImpSoc = resTotImpSoc
			this.resFluxAIFonc.resTotPrelSoc = resTotPrelSoc
			this.resFluxAIFonc.resTotCSG = resTotCSG
			this.resFluxAIFonc.resTotPrelSocAss = resTotPrelSocAss
			this.resFluxAIFonc.resTotPV = resTotPV
			this.resFluxAIFonc.resTotImposition = resTotImposition
			this.resFluxAIFonc.resTotFluxAprImpot = resTotFluxAprImpot
			this.resFluxAIFonc.resTotVersDiv = resTotVersDiv
			this.resFluxAIFonc.resTotCashFlowNonActu = resTotCashFlowNonActu
			this.resFluxAIFonc.resTotCashFlowActu = resTotCashFlowActu
			this.resFluxAIFonc.resTotCashFlowCumul = resTotCashFlowCumul
			this.resFluxAIFonc.resTotSommeInvest = resTotSommeInvest

			/*********************************************************************************************************************************************************************************************************
			 *
			 * >> CALCUL DU TIR
			 *    TIR SANS DETTE
			 *    TIR AVEC DETTE
			 *    TIR AVEC FISCA
			 * 
			 *********************************************************************************************************************************************************************************************************/

			//sans dette
			let tirSD = this.TIRsansDette(this.resInvTravFonc.resFluxAvtDette)

			//avec dette
			let tirAD = this.TIRsansDette(this.resInvTravFonc.resFluxAvtImpot)

			//avec fiscalite TODO
			let tirAF = this.TIRsansDette(this.resFluxAIFonc.resCashFlowNonActu)

			//avec fiscalite
			let tir = this.TIRsansDette(this.resFluxAIFonc.resCashFlowActu)

			/*********************************************************************************************************************************************************************************************************
			 * CADRAN
			 *
			 *  PERFORMANCE
			 *  -----
			 *  RENDEMENT TOTAL
			 *********************************************************************************************************************************************************************************************************/

			//tir
			performance.sansDette.tir = tirSD
			performance.avecDette.tir = tirAD
			performance.avecFisc.tir = tirAF
			performance.tir = tir

			//van
			performance.avecFisc.van = resTotCashFlowActu

			//indice de profitabilite
			// OLD
			// performance.sansDette.indprof = Number((Number(this.resInvTravFonc.resTotFluxAvtDette) / (- Number(resTotSommeInvest))).toFixed(2))
			// performance.avecDette.indprof = Number((Number(this.resInvTravFonc.resTotFluxAvtImpot) / (- Number(resTotSommeInvest))).toFixed(2))
			// performance.avecFisc.indprof = Number((Number(resTotFluxAprImpot) / (- Number(resTotSommeInvest))).toFixed(2))
			// NEW
			performance.avecFisc.indprof = (resTotCashFlowActu / (-resTotSommeInvest)) + 1

			//effet de levier (emprunt)
			performance.avecDette.effetlevier = Number(((((tirAD / 100) - (tirSD / 100)) / (tirSD / 100)) * 100).toFixed(2))

			// //taux de levier (rentabilite de l'emprunt)
			// performance.avecFisc.tlevier = Number(((- (1 - ((1 + (tirAF / 100)) / (1 + (tirSD / 100))))) * 100).toFixed(2))

			// Refait la somme du tableau resCoutTotalAcqui
			let resTotCoutTotalAcqui = resCoutTotalAcqui.reduce(reducer)
			rendementTotal.total.val = resTotCashFlowNonActu
			rendementTotal.total.per = Number((resTotCashFlowNonActu / resTotCashFlowNonActu).toFixed(2))
			rendementTotal.plusval.val = resTotCoutTotalAcqui
			rendementTotal.plusval.per = Number((resTotCoutTotalAcqui / resTotCashFlowNonActu).toFixed(2))
			rendementTotal.revloc.val = rendementTotal.total.val - rendementTotal.plusval.val
			rendementTotal.revloc.per = Number((rendementTotal.revloc.val / rendementTotal.total.val).toFixed(2))

			repartitionBenef.associateFirst = this.newProject.carac.type_fisc[0] ? {
				per: this.newProject.carac.type_fisc[0].capital / 100,
				val: -(this.newProject.carac.type_fisc[0].apport),
				profit: rendementTotal.total.val * this.newProject.carac.type_fisc[0].capital / 100,
				ratio: (rendementTotal.total.val * this.newProject.carac.type_fisc[0].capital / 100) / (-this.newProject.carac.type_fisc[0].apport),
			} : 0
			repartitionBenef.associateSecond = this.newProject.carac.type_fisc[1] ? {
				per: this.newProject.carac.type_fisc[1].capital / 100,
				val: -(this.newProject.carac.type_fisc[1].apport),
				profit: rendementTotal.total.val * this.newProject.carac.type_fisc[1].capital / 100,
				ratio: (rendementTotal.total.val * this.newProject.carac.type_fisc[1].capital / 100) / (-this.newProject.carac.type_fisc[1].apport),
			} : 0
			repartitionBenef.associateThird = this.newProject.carac.type_fisc[2] ? {
				per: this.newProject.carac.type_fisc[2].capital / 100,
				val: -(this.newProject.carac.type_fisc[2].apport),
				profit: rendementTotal.total.val * this.newProject.carac.type_fisc[2].capital / 100,
				ratio: (rendementTotal.total.val * this.newProject.carac.type_fisc[2].capital / 100) / (-this.newProject.carac.type_fisc[2].apport),
			} : 0
			repartitionBenef.associateFourth = this.newProject.carac.type_fisc[3] ? {
				per: this.newProject.carac.type_fisc[3].capital / 100,
				val: -(this.newProject.carac.type_fisc[3].apport),
				profit: rendementTotal.total.val * this.newProject.carac.type_fisc[3].capital / 100,
				ratio: (rendementTotal.total.val * this.newProject.carac.type_fisc[3].capital / 100) / (-this.newProject.carac.type_fisc[3].apport),
			} : 0

			////console.log("-- PERFORMANCE RENDEMENT TOTAL --", rendementTotal)
			////console.log("-- PERFORMANCE RENDEMENT TOTAL --", repartitionBenef)
			////console.log("-- PERFORMANCE TOTAL --", performance)

			this.resFluxAIFonc.rendementTotal = rendementTotal
			this.resFluxAIFonc.repartitionBenef = repartitionBenef
			this.resFluxAIFonc.performance = performance

			// this.resultats = {
			// 	...this.timelineFonc,
			// 	...this.resCoutTotalFonc,
			// 	...this.resOperationNetFonc,
			// 	...this.resInvTravFonc,
			// 	...this.resFluxAIFonc,
			// }
			this.resultats = {
				timelineFonc: this.timelineFonc,
				resCoutTotalFonc: this.resCoutTotalFonc,
				resOperationNetFonc: this.resOperationNetFonc,
				resInvTravFonc: this.resInvTravFonc,
				resFluxAIFonc: this.resFluxAIFonc,
			}

			//console.log("---------------VARIABLES RESULTATS DES 5 FONCTIONS----------------")
			//console.log("------------------------------->", this.timelineFonc)
			//console.log("------------------------------->", this.resCoutTotalFonc)
			//console.log("------------------------------->", this.resOperationNetFonc)
			//console.log("------------------------------->", this.resInvTravFonc)
			//console.log("------------------------------->", this.resFluxAIFonc)
			//console.log("------------------------------->", this.resultats)
			// console.log("results",this.resultats)
			// console.log("newproj",this.newProject)
			// return
			this.onSubmit()
		},

		TIRsansDette(flux) {
			// let dureeempr = this.dureeempr.isAuto == true ? Number(this.dureeempr.valAuto / 12) : Number(this.dureeempr.val)
			let dureeempr = this.newProject.carac.dureedetention.isAuto == true ? Number(this.newProject.carac.dureedetention.valAuto / 12) : Number(this.newProject.carac.dureedetention.val)

			let Tir = 0
			let arrayTir = []
			let arrayEachTir = []
			let test = []
			const reducer = (accumulator, currentValue) => accumulator + currentValue;

			for (let i = 0; i <= this.globals.tactualisationPer.length; i++) {
				for (let j = 0; j <= dureeempr; j++) {
					arrayEachTir.push(flux[j] / (1 + (this.globals.tactualisationPer[i] / 100)) ** j)
				}
				arrayTir.push(arrayEachTir)
				Tir = arrayEachTir.reduce(reducer)
				test.push(Tir)
				arrayEachTir = []

				if (Tir <= 0) {
					return this.globals.tactualisationPer[i]
				}
			}
		},
	},
}
</script>

<style scoped>
.chart-blue {
	background-color: #0C1E3B;
}

.chart-blue-light {
	background-color: #14A0EB;
}

.chart-orange {
	background-color: #EB7B14;
}

.chart-red {
	background-color: #F91515;
}

.chart-yellow {
	background-color: rgba(235, 175, 20, 0.6);
}

.chart-grey {
	background-color: rgba(95, 95, 95, 0.4);
}

.h-40-c {
	height: 40rem;
}

.h-27-c {
	height: 27rem;
}

.li-box {
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
	transition: 0.2s;
	cursor: pointer;
	background-color: #FFFFFF;
	padding: 10px 0px 10px 0px;
	margin-bottom: 0.1rem;
}

.li-box:hover {
	transform: scale(1.03);
}

.bg-green-pointer {
	background-color: #55EF0C;
}

.bg-red-pointer {
	background-color: #F11212;
}

.slider {
	-webkit-appearance: none;
	width: 80%;
	height: 12px;
	border-radius: 5px;
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
}

.slider:hover {
	opacity: 1;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #14A0EB;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #14A0EB;
	cursor: pointer;
}

.ampoule-picto {
	top: -1rem;
	left: -1rem;
}
</style>