<template>
  <div id="defaultModal" tabindex="-1" aria-hidden="true"
       class="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75 transition-opacity">
    <div class="relative p-3 pt-5 bg-white border border-gray-300 dark:bg-gray-700 rounded-xl w-max-content">
      <iframe class="z-50" width="560" height="315"
              :src="stringYt + videoId"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
      <button class="absolute p-2 border rounded-lg bg-lightblueaestima-500 hover:bg-darkblueaestima-100 z-60 top-2 right-2" @click="close" aria-label="Close">
         Fermer
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalYoutube',
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      stringYt: 'https://www.youtube.com/embed/',
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
  .head-text {
    font-size: 15px;
    line-height: 40px;
  }
  .content-text {
    font-size: 20px;
    line-height: 40px;
  }
  .subtitle {
    font-size: 20px;
    margin-top: 60px;
  }
</style>
