<template>
  <div>
    <div class="grid grid-cols-11 gap-0 relative flex flex-row justify-center items-start h-screen">
      <div class="bg-dark relative text-center hidden sm:block col-span-3 h-full">
        <div class="grid grid-cols-12 ml-6 mt-6">
          <button
            @click="goBack()"
            class="col-span-11 sm:col-span-5 text-lg font-semibold text-white py-3 bg-aestiblue-light rounded-full aestima-button mt-10 sm:mt-0"
          >
            Retour
          </button>
        </div>
        <img class="text-center signup-right-img mx-auto" src="../../assets/img.png" alt="S'inscrire" />
        <h2 class="font-bold signup-right-p">La protection de vos données</h2>
        <ul class="signup-right-list">
          <li>Notre solution respecte et applique le RGPD.</li>
          <li>Vos données sont cryptées et stockées en France.</li>
          <li>
            Notre solution est authentifiée HTTPS ce qui assure la confidentialité et la sécurité de vos
            données et de vos connexions.
          </li>
          <li>
            Nous disposons d’un système de protection anti-DDoS contre la fraude et tentatives de piratage.
          </li>
        </ul>
        <router-link
          target="_blank"
          to="/contact"
          class="text-lg underline text-center font-semibold px-16 py-2 rounded-lg mt-20 sm:mt-0"
          ><h2>Contactez-nous pour toutes questions</h2></router-link
        >
      </div>
      <div class="bg-white relative text-center col-span-12 sm:col-span-8 mx-4 sm:mx-0 h-full">
        <div class="flex justify-center items-center mt-10">
          <img
            class="hidden sm:block h-24 w-auto self-center"
            alt="Logo Aestima"
            src="../../assets/logo_aestima-white.png"
          />
        </div>
        <div class="relative z-10 col-span-4 col-start-8 pt-0 px-0 sm:p-10 text-left">
          <h1 class="text-3xl font-bold text-center">
            S'inscrire {{ subSelected }} <br />
            <span class="text-sm font-normal"
              >ou
              <router-link to="login"><u class="text-lightblueaestima-100">se connecter</u></router-link> si
              vous avez déjà un compte.
            </span>
            <br />
          </h1>
          <div class="flex flex-col mb-3 sm:px-32">
            <svg
              class="cursor-pointer mb-2"
              v-show="step !== 0"
              @click="stepBack"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30"
              height="30"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g
                fill="none"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="inherit"
                font-size="none"
                text-anchor="inherit"
                style="mix-blend-mode: normal"
              >
                <path d="M0,172v-172h172v172z" fill="none"></path>
                <g>
                  <path
                    d="M2.65391,86c0,-46.02344 37.32266,-83.34609 83.34609,-83.34609c46.02344,0 83.34609,37.32266 83.34609,83.34609c0,46.02344 -37.32266,83.34609 -83.34609,83.34609c-46.02344,0 -83.34609,-37.32266 -83.34609,-83.34609z"
                    fill="#14a0eb"
                  ></path>
                  <path
                    d="M121.77734,75.85469c-15.31875,0 -30.60391,0 -45.92266,0c3.89688,-3.89687 7.79375,-7.79375 11.69063,-11.69062c9.00312,-9.00313 -5.00547,-22.91094 -14.04219,-13.87422c-9.50703,9.50703 -19.01406,19.01406 -28.52109,28.52109c-3.82969,3.82969 -3.72891,10.17891 0.06719,13.94141c9.50703,9.50703 19.01406,19.01406 28.52109,28.52109c9.00312,9.00313 22.91094,-5.00547 13.87422,-14.04219c-3.86328,-3.86328 -7.76016,-7.76016 -11.62344,-11.62344c15.25156,0 30.50312,0 45.75469,0c12.79922,0 12.9,-19.75312 0.20156,-19.75312z"
                    fill="#ffffff"
                  ></path>
                </g>
              </g>
            </svg>
            <div class="flex flex-col">
              <p v-if="step !== 0" class="text-xl font-bold">Etape {{ step }}</p>
              <p v-if="step === 1" class="text-xl">Inscription</p>
              <p v-else-if="step === 2" class="text-xl">Finaliser mon inscription</p>
            </div>
          </div>
          <form v-show="step === 0" class="sm:px-32 flex flex-col items-center gap-6" @submit.prevent="">
            <div class="flex">
              <div class="flex gap-2 font-bold text-lg">
                <p>Au mois</p>
                <PSwitch v-model="subscribeYearly"/>
                <p>A l'année</p>
              </div>
            </div>
            <div class="liste-formules">
              <div class="formule border-4 rounded-xl border-gray-300 bg">
                <div class="cadre">
                  <h1>Plan initié</h1>
                  <p>Adapté à un usage périodique</p>
                </div>

                <div class="sous-titre">
                  <h1>30 jours gratuits</h1>
                  <p>puis {{ subscribeYearly ? priceIniYear.costByYear + "&euro; par an"  : priceIniMonth.costByMonth +"&euro; par mois" }}</p>
                  <p v-if="subscribeYearly">(soit {{ subscribeYearly ? priceIniYear.costByMonth : priceIniMonth.costByMonth }}&euro; par mois)</p>
                </div>
                <div class="separator"/>
                <ul class="liste-avantages">
                  <li class="avantages"><img src="https://api.iconify.design/mdi/tick-circle.svg?color=%2314a0eb" class="icone-avantage"><p>Limité à 4 clients enregistrés</p></li>
                  <li class="avantages"><img src="https://api.iconify.design/mdi/tick-circle.svg?color=%2314a0eb" class="icone-avantage"><p>Limité à 3 simulations par client</p></li>
                  <li class="avantages"><img src="https://api.iconify.design/mdi/tick-circle.svg?color=%2314a0eb" class="icone-avantage"><p>Tous montages et dispositifs fiscaux</p></li>
                  <li class="avantages"><img src="https://api.iconify.design/mdi/tick-circle.svg?color=%2314a0eb" class="icone-avantage"><p>Comparaison de simulations</p></li>
                  <li class="avantages"><img src="https://api.iconify.design/mdi/tick-circle.svg?color=%2314a0eb" class="icone-avantage"><p>Personnalisation et édition illimité des rapports</p></li>
                </ul>
                <PButton @click="savePriceAndStep(subscribeYearly, 'initie')">Choisir</PButton>
              </div>

              <div class="formule border-4 rounded-xl border-lightblueaestima-100 bg relative" style="box-shadow: 0 0 20px rgba(48, 48, 48, 0.5);">
                <div class="absolute w-52 text-sm top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-6 py-2 rounded-full bg-lightblueaestima-100 flex place-content-center font-bold text-white">
                  Choix de nos clients
                </div>
                <div class="cadre">
                  <h1>Plan professionnel</h1>
                  <p>Adapté à un usage récurent</p>
                </div>

                <div class="sous-titre">
                  <h1>30 jours gratuits</h1>
                  <p>puis {{ subscribeYearly ? priceProYear.costByYear + "&euro; par an"  : priceProMonth.costByMonth +"&euro; par mois" }}</p>
                  <p v-if="subscribeYearly">(soit {{ subscribeYearly ? priceProYear.costByMonth : priceProMonth.costByMonth }}&euro; par mois)</p>
                </div>
                <div class="separator"/>
                <ul class="liste-avantages">
                  <li class="avantages font-bold"><img src="https://api.iconify.design/mdi/tick-circle.svg?color=%2314a0eb" class="icone-avantage"><p>Tous les avantages du plan initié</p></li>
                  <li class="avantages"><img src="https://api.iconify.design/mdi/tick-circle.svg?color=%2314a0eb" class="icone-avantage"><p>Enregistrement de clients illimité</p></li>
                  <li class="avantages"><img src="https://api.iconify.design/mdi/tick-circle.svg?color=%2314a0eb" class="icone-avantage"><p>Enregistrement de simulations illimité</p></li>
                  <li class="avantages"><img src="https://api.iconify.design/mdi/tick-circle.svg?color=%2314a0eb" class="icone-avantage"><p>Utilisation du logiciel en équipe</p></li>
                  <li class="avantages"><img src="https://api.iconify.design/mdi/tick-circle.svg?color=%2314a0eb" class="icone-avantage"><p>Passage au LMNP après dispositif fiscal</p></li>
                </ul>
                <PButton @click="savePriceAndStep(subscribeYearly, 'pro')">Choisir</PButton>
              </div>
            </div>
            <router-link to="tarifs"><u class="text-lightblueaestima-100">Accéder aux descriptifs détaillés des abonnements</u></router-link>
          </form>
          <form v-show="step === 1" class="mt-8 sm:px-32" @submit.prevent="steped()">
            <div class="rounded-md shadow-sm">
              <div>
                <label for="email" class="block text-sm font-medium leading-5 text-gray-700">E-mail *</label>
                <input
                  v-model="email"
                  required
                  type="email"
                  id="email"
                  class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              <div class="mt-6 text-center">
                <button
                  type="submit"
                  class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out"
                >
                  <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg
                      class="h-5 w-5 text-blueaestima-500 group-hover:blueaestima-500 transition ease-in-out duration-150"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  Suivant
                </button>
              </div>
            </div>
          </form>
          <form v-show="step === 2" class="mt-8 sm:px-32" @submit.prevent="submit">
            <input type="hidden" name="remember" value="true" />

            <div class="rounded-md shadow-sm">
              <div class="mt-4">
                <label for="name" class="block text-sm font-medium leading-5 text-gray-700">Nom *</label>
                <input
                  v-model="name"
                  required
                  type="text"
                  id="name"
                  class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              <div class="mt-4">
                <label for="firstname" class="block text-sm font-medium leading-5 text-gray-700"
                  >Prénom *</label
                >
                <input
                  v-model="firstname"
                  required
                  type="text"
                  id="firstname"
                  class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              <div class="mt-4">
                <label for="password" class="block text-sm font-medium leading-5 text-gray-700"
                  >Mot de passe *</label
                >
                <div class="flex flx-row items-center">
                  <input
                    v-model="password"
                    required
                    type="password"
                    id="password"
                    class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                  <label class="px-2 cursor-pointer" for="password" @click="changeType('password')">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="24"
                      height="24"
                      viewBox="0 0 172 172"
                      style="fill: #000000"
                    >
                      <g
                        fill="none"
                        fill-rule="nonzero"
                        stroke="none"
                        stroke-width="1"
                        stroke-linecap="butt"
                        stroke-linejoin="miter"
                        stroke-miterlimit="10"
                        stroke-dasharray=""
                        stroke-dashoffset="0"
                        font-family="none"
                        font-weight="inherit"
                        font-size="none"
                        text-anchor="inherit"
                        style="mix-blend-mode: normal"
                      >
                        <path d="M0,172v-172h172v172z" fill="none"></path>
                        <g fill="#14a0eb">
                          <path
                            d="M86,28.66667c-57.33333,0 -78.83333,57.33333 -78.83333,57.33333c0,0 21.5,57.33333 78.83333,57.33333c57.33333,0 78.83333,-57.33333 78.83333,-57.33333c0,0 -21.5,-57.33333 -78.83333,-57.33333zM86,50.16667c19.78717,0 35.83333,16.04617 35.83333,35.83333c0,19.78717 -16.04617,35.83333 -35.83333,35.83333c-19.78717,0 -35.83333,-16.04617 -35.83333,-35.83333c0,-19.78717 16.04617,-35.83333 35.83333,-35.83333zM86,64.5c-11.87412,0 -21.5,9.62588 -21.5,21.5c0,11.87412 9.62588,21.5 21.5,21.5c11.87412,0 21.5,-9.62588 21.5,-21.5c0,-11.87412 -9.62588,-21.5 -21.5,-21.5z"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </label>
                </div>
              </div>
              <div class="mt-4">
                <label for="info_know" class="block text-sm font-medium leading-5 text-gray-700"
                  >Comment nous avez-vous connu ? *</label
                >
                <!-- <input required v-model="info_know" type="text" id="info_know" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"> -->

                <select
                  required
                  class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  v-model="info_know"
                  name="info_know"
                  id="info_know"
                >
                  <option disabled value="">Choisissez une situation</option>
                  <option value="Bouche à oreille">Bouche à oreille</option>
                  <option value="Contact Linkedin">Contact Linkedin</option>
                  <option value="Recherche sur Google">Recherche sur Google</option>
                  <option value="Réseaux sociaux">Réseaux sociaux</option>
                  <option value="Autre">Autre</option>
                </select>
              </div>
              <div class="mt-4">
                <label for="info_work" class="block text-sm font-medium leading-5 text-gray-700"
                  >Vous êtes ? *</label
                >

                <select
                  required
                  class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  v-model="info_work"
                  name="info_work"
                  id="info_work"
                >
                  <option disabled value="">Choisissez votre statut</option>
                  <option value="Agent immobilier">Agent immobilier</option>
                  <!-- <option value="Aspirant investisseur immobilier">Aspirant investisseur immobilier</option> -->
                  <option value="Chasseur immobilier">Chasseur immobilier</option>
                  <option value="Conseiller en gestion de patrimoine">
                    Conseiller en gestion de patrimoine
                  </option>
                  <option value="Conseiller en investissements">Conseiller en investissements</option>
                  <option value="Constructeur immobilier">Constructeur</option>
                  <!-- <option value="Investisseur immobilier">Investisseur immobilier</option> -->
                  <option value="Mandataire immobilier">Mandataire immobilier</option>
                  <option value="Marchand de biens">Marchand de biens</option>
                  <option value="Promoteur immobilier">Promoteur immobilier</option>
                </select>
              </div>
              <div class="mt-4">
                <label for="info_phone" class="block text-sm font-medium leading-5 text-gray-700"
                  >Numéro de téléphone
                </label>
                <input
                  v-model="info_phone"
                  type="text"
                  id="info_phone"
                  class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            <div class="flex items-start mt-4">
              <input
                required
                id="rgpd"
                type="checkbox"
                class="form-checkbox h-4 w-4 text-lightblueaestima-100 transition duration-150 ease-in-out"
              />
              <label for="rgpd" class="ml-2 block text-xs leading-5 text-gray-900">
                En cochant cette case, j'accepte que les informations saisies soient exploitées dans le cadre
                de la demande d'essai gratuit et de la relation commerciale qui peut en découler dans le
                respect des normes RGPD.
              </label>
            </div>

            <div class="flex items-start mt-4">
              <input
                required
                id="cgv"
                type="checkbox"
                class="form-checkbox h-4 w-4 text-lightblueaestima-100 transition duration-150 ease-in-out"
              />
              <label for="cgv" class="ml-2 block text-xs leading-5 text-gray-900">
                En cochant cette case, j'accepte
                <a class="underline text-lightblueaestima-100" target="_blank" href="/CGV"
                  >les conditions générales de vente</a
                >
                et je déclare en avoir pris connaissance.
              </label>
            </div>

            <div
              v-if="modal"
              class="mt-6 px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-lg bg-red-100 text-red-800 border-red-800 border border-solid flex justify-center items-center w-full"
            >
              <svg
                class="h-10 w-10 p-2 mr-2 text-red-800 bg-white rounded-full"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <span>{{ message }}</span>
            </div>

            <div class="mt-6 text-center">
              <button
                type="submit"
                :disabled="email === '' || name === '' || firstname === '' || password === ''"
                class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out"
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    class="h-5 w-5 text-blueaestima-500 group-hover:blueaestima-500 transition ease-in-out duration-150"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Créer un compte
              </button>
              <svg
                v-if="modalPDFLoader"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="display: inline-block; shape-rendering: auto; margin-top: 1.4rem"
                width="50px"
                height="50px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="#14a0eb"
                  stroke-width="8"
                  r="25"
                  stroke-dasharray="70.68583470577033 25.561944901923447"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1.6949152542372883s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                  ></animateTransform>
                </circle>
              </svg>
            </div>
          </form>
        </div>
        <div class="signup-data">
          <p class="text-left signup-data-p">
            Vous êtes seul responsable des données que vous inscrivez. Conformément à l'article 34 de la loi
            du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, les informations
            nominatives sont destinées uniquement à Aestima Immobilier pour l'exécution de ses services. Vous
            bénéficiez d'un droit d'accès, de rectification que vous pouvez exercer directement sur la page
            <router-link target="_blank" to="/contact">contact</router-link> ou en adressant un message à :
            contact@aestima-immo.com
          </p>
        </div>
      </div>
      <div class="col-span-12">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'

let stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY),
  elements = stripe.elements(),
  card = undefined

export default {
  name: 'SignUp',
  data() {
    return {
      step: 0,
      email: '',
      name: '',
      firstname: '',
      password: '',
      code: '',
      info_know: '',
      info_work: '',
      info_society: '',
      info_phone: '',

      message: '',
      modal: false,

      subscribeYearly: false, 
      subscriptionPlan: '',

      priceIniMonth: {
        id: process.env.VUE_APP_STRIPE_PRICE_ID_INI_MONTH,
        costByMonth: 39,
        costByYear: 468
      },
      priceIniYear: {
        id: process.env.VUE_APP_STRIPE_PRICE_ID_INI_YEAR,
        costByMonth: 29,
        costByYear: 348
      },
      priceProMonth: {
        id: process.env.VUE_APP_STRIPE_PRICE_ID_PRO_MONTH,
        costByMonth: 59,
        costByYear: 708
      },
      priceProYear: {
        id: process.env.VUE_APP_STRIPE_PRICE_ID_PRO_YEAR,
        costByMonth: 44,
        costByYear: 528
      },

      selectedPrice: {
        id: 0,
        costByMonth: 0,
        costByYear: 0
      }
    }
  },
  components: {
    Footer,
    Header,
  },
  metaInfo() {
    return {
      title: 'Inscription - Aestima',
      meta: [
        {
          name: 'description',
          content: "Cette page permet l'inscription des utilisateurs sur Aestima",
        },
      ],
      //keywords
    }
  },
 
  computed: {
    ...mapGetters({
      modalPDFLoader: 'invests/modalPDFLoader',
      stripePrice: 'users/stripePrice',
    }),
    subSelected() {
      let title = ''
      if (this.stripePrice.id === process.env.VUE_APP_STRIPE_PRICE_ID_INI_MONTH)
        title = "mensuellement à l'offre Initié"
      if (this.stripePrice.id === process.env.VUE_APP_STRIPE_PRICE_ID_INI_YEAR)
        title = "annuellement à l'offre Initié"
      if (this.stripePrice.id === process.env.VUE_APP_STRIPE_PRICE_ID_PRO_MONTH)
        title = "mensuellement à l'offre Professionnel"
      if (this.stripePrice.id === process.env.VUE_APP_STRIPE_PRICE_ID_PRO_YEAR)
        title = "annuellement à l'offre Professionnel"
      return title
    },
  },
  mounted() {
    if(this.$route.query.price ==0) this.step= 0
    else if(this.stripePrice.id !== 0) this.step = 1
  },
  methods: {
    ...mapActions({
      signIn: 'users/login',
      signUp: 'users/signUp',
      setModalPDFLoader: 'invests/setModalPDFLoader',
      setStayConnected: 'users/setStayConnected',
      setUserRefreshed: 'users/setUserRefreshed',
      setStripePriceSelected: 'users/setStripePriceSelected',
    }),
    changeType(id) {
      if (document.getElementById(id).type === 'password') {
        document.getElementById(id).setAttribute('type', 'text')
      } else {
        document.getElementById(id).setAttribute('type', 'password')
      }
    },
    goBack() {
      this.stripePrice.id = 0
      window.history.back()
    },
    steped() {
      this.step = this.step + 1
    },
    stepBack() {
      this.setStripePriceSelected({ id: 0, isTrial: true })
      this.step = this.step - 1
    },

    savePriceAndStep(subscribeYearly, subscriptionPlan) {
      if (subscribeYearly) {
        if (subscriptionPlan === 'initie') {
          this.selectedPrice = this.priceIniYear
        } else {
          this.selectedPrice = this.priceProYear
        }
      } else {
        if (subscriptionPlan === 'initie') {
          this.selectedPrice = this.priceIniMonth
        } else {
          this.selectedPrice = this.priceProMonth
        }
      }
      this.setStripePriceSelected({ id: this.selectedPrice.id, isTrial: true })
      this.steped()
    },

    async submit() {
      this.modal = false
      this.setModalPDFLoader(true)
      try {
        const signupResponse = await axios.post('/signup', {
          email: this.email,
          password: this.password,
          name: this.name,
          firstname: this.firstname,
          code: this.code,
          info_know: this.info_know,
          info_work: this.info_work,
          info_society: this.info_society,
          info_phone: this.info_phone,
        })
        const userId = signupResponse.data.newUser._id
        console.log('userId', userId)
        this.setModalPDFLoader(false)

        const customerResponse = await axios.post('/create-customer', {
          email: this.email,
          firstName: this.firstname,
          lastName: this.name,
          phone: this.info_phone,
          userId: userId,
        })
        console.log('TEST')
        const customerId = customerResponse.data.data.id
        console.log('NEWUSER', customerId)

        if (this.stripePrice.id === 0) {
          this.$router.replace({ name: 'LogIn' })
          location.reload()
        } else {
          const sessionResponse = await axios.post('/create-checkout-session', {
            price: this.stripePrice.id,
            customerId: customerId,
          })
          await stripe.redirectToCheckout({
            sessionId: sessionResponse.data.id,
          })
        }
      } catch (err) {
        this.setModalPDFLoader(false)
        console.log(err)
        this.message = err.response.data.message
        this.modal = true
      }

      this.setStayConnected()
      try {
        await this.signIn({
          email: this.email,
          password: this.password,
        })
      } catch (err) {
        this.setModalPDFLoader(false)
        console.log(err)
        this.message = err.response.data.message
        this.modal = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.signup {
  &-right {
    &-img {
      height: 164px;
      width: 164px;
      margin-top: 36px;
    }
    &-p {
      margin-top: 20px;
      font-size: 19px;
    }
    &-list {
      list-style: disc;
      padding-inline-start: 40px;
      text-align: start;
      li {
        line-height: 30px;
        margin-top: 15px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  &-data {
    &-p {
      padding: 10px;
      font-weight: 500;
      font-size: 8px;
    }
    a {
      text-decoration: underline;
      color: #14a0eb;
    }
  }
}

.bg-dark {
  background-color: #f3fafe;
  padding: 0 20px;
}

.h-header {
  height: 100%;
}
.aestima-button {
  transition: 0.5s;
  background-color: #14a0eb;
}
/* 
video {
	position: relative;
	width: 100% !important;
	height: auto;
} */

.aestima-button:hover {
  background-color: #ffffff;
  border: 2px solid #14a0eb;
  color: #14a0eb;
  transform: scale(1);
}

.aestima-title-vw {
}

.aestima-par-vw {
}
/* Medium (md) */
@media (min-width: 768px) {
  .h-header {
    height: 50rem;
  }

  .aestima-title-vw {
    font-size: 2.8vw !important;
  }

  .aestima-par-vw {
    font-size: 1.2vw !important;
  }
}

.shadow-inner {
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.12);
}

.bg-img-desc {
  background: rgba(20, 160, 235, 0.44);
}

.round-tiret li {
  list-style: inside;
}

.round-tiret-check li {
  list-style: url('../../assets/picto/aestima-tiret.png');
}

.liste-formules {
  @apply flex gap-5 w-full
}

.formule {
  @apply flex flex-col gap-5 p-2 w-full pt-6 justify-between

}

.cadre {
  @apply flex flex-col items-center border-2 border-blue-300 mx-4 p-2 rounded-xl bg-white
}

.cadre h1 {
  @apply text-2xl font-bold
}

.cadre p {
  @apply text-base font-normal
}

.sous-titre {
  @apply flex flex-col items-center p-2 h-20
}

.sous-titre h1 {
  @apply text-2xl font-bold
}

.sous-titre p {
  @apply text-base font-normal
}

.separator {
  @apply border-b-2 border-gray-300 mx-16
}

.liste-avantages {
  @apply flex flex-col gap-3 list-inside pl-6
}

.avantages {
  @apply flex gap-3 items-center
}

.icone-avantage {
  @apply h-8
}

::v-deep .p-button {
  @apply bg-lightblueaestima-100 flex font-bold place-content-center border-4 border-lightblueaestima-100
}

::v-deep .p-button:hover {
  @apply bg-white text-lightblueaestima-100 border-lightblueaestima-100
}

::v-deep .p-inputswitch-checked .p-inputswitch-slider {
  @apply bg-lightblueaestima-100
}

::v-deep .p-inputswitch-checked:hover .p-inputswitch-slider:hover {
  @apply bg-lightblueaestima-100
}

</style>
