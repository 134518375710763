import Vue from 'vue'
import Vuex from 'vuex'
import users from './users'
import projects from './projects'
import invests from './invests'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const modules = {
  users,
  projects,
  invests,
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  modules,
})
