<template>
  <div id="defaultModal" tabindex="-1" aria-hidden="true" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 justify-center items-center flex">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="relative p-4 w-full max-w-4xl h-full">
      <div class="relative p-5  bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="flex justify-center items-center p-5">
          <h2 class="font-bold text-black" style="font-size: 20px">
            Notes investisseurs
          </h2>
          <a @click="close" class="p-1 cursor-pointer whitespace-no-wrap inline-flex font-bold items-center justify-center ml-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150">
            Retour
          </a>
        </div>
        <div>
          <h3 class="font-bold">Ajout d'une note</h3>
          <textarea rows="5" v-model="note" class='block px-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'></textarea>
          <button @click="ajoutNote()" class="mt-3 inline-flex justify-center items-center rounded-md cursor-pointer border border-transparent shadow-sm px-1 py-1 bg-green-500 text-base font-medium text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 mr-1 sm:text-sm">Sauvegarde de la note</button>
        </div>
        <div>
          <h3 class="font-bold">Historique</h3>
          <div class="overflow-y-auto h-96">
            <div class="relative flex flex-col bg-gray-100 m-3 p-2 border rounded border-gray-800" v-for="note in notesComputed" :key="note.date">
            <div @click="supprimeNote(note)" class="cursor-pointer hover:bg-red-400 absolute top-0 right-0 m-1 p-1 bg-red-600 rounded ">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 172 172" style=" fill:#000000;">
                <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                  <path d="M0,172v-172h172v172z" fill="none"></path>
                  <g fill="#ffffff">
                    <path d="M71.08424,17.80469c-7.0389,0 -12.63112,5.39079 -12.63112,12.42969v7.72656h5.375v-7.72656c0,-4.07526 3.18086,-7.05469 7.25612,-7.05469h29.83151c4.07526,0 7.25612,2.97943 7.25612,7.05469v7.72656h5.375v-7.72656c0,-7.0389 -5.59188,-12.42969 -12.63112,-12.42969zM40.58151,40.64844c-3.71066,0 -6.71875,3.00809 -6.71875,6.71875c0,3.71066 3.00809,6.71875 6.71875,6.71875h90.83698c3.71066,0 6.71875,-3.00809 6.71875,-6.71875c0,-3.71066 -3.00809,-6.71875 -6.71875,-6.71875zM43.33594,59.46094v82.97656c0,6.70296 4.756,12.09375 10.88451,12.09375h63.55911c6.12851,0 10.88451,-5.39079 10.88451,-12.09375v-82.97656zM66.25842,69.6073c1.45292,0.04259 2.60868,1.23265 2.60876,2.68619v63.49219c0,1.48427 -1.20323,2.6875 -2.6875,2.6875c-1.48427,0 -2.6875,-1.20323 -2.6875,-2.6875v-63.49219c0.00004,-0.7264 0.29413,-1.42184 0.81525,-1.92788c0.52112,-0.50604 1.2249,-0.77959 1.95099,-0.75831zM86.07874,69.6073c1.45292,0.04259 2.60868,1.23265 2.60876,2.68619v63.49219c0,1.48427 -1.20323,2.6875 -2.6875,2.6875c-1.48427,0 -2.6875,-1.20323 -2.6875,-2.6875v-63.49219c0.00004,-0.7264 0.29413,-1.42184 0.81525,-1.92788c0.52112,-0.50604 1.2249,-0.77959 1.95099,-0.75831zM105.89905,69.6073c1.45292,0.04259 2.60868,1.23265 2.60876,2.68619v63.49219c0,1.48427 -1.20323,2.6875 -2.6875,2.6875c-1.48427,0 -2.6875,-1.20323 -2.6875,-2.6875v-63.49219c0.00004,-0.7264 0.29413,-1.42184 0.81525,-1.92788c0.52112,-0.50604 1.2249,-0.77959 1.95099,-0.75831z"></path>
                  </g>
                </g>
              </svg>

            </div>
            <p>{{ note.text }}</p>
            <p class="font-bold text-xs">{{ formatDate(note.date) }}</p>
          </div>
          </div>
          <p v-if="!investisseur.notes" class="text-red-500">Aucune notes pour cet investisseur</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import moment from 'moment'

export default {
  name: "NotesInvestisseur",
  props: {
    investisseur: Object,
  },
  data() {
    return {
      note: ""
    }
  },
  methods: {
    ...mapActions({
      setInvestNote: 'invests/setInvestNote',
      removeInvestNote: 'invests/removeInvestNote',
    }),
    //Envoie une note
    ajoutNote() {
      this.setInvestNote({
        idInvest: this.investisseur._id,
        note: {
          text: this.note,
          date: new Date()
        }
      }).then((res) => {
        this.investisseur.notes = res
      })
    },
    //Supprime une note
    supprimeNote(note) {
      if(confirm('Êtes vous sûr de vouloir supprimer cette note ?')){
        this.removeInvestNote({
        idInvest: this.investisseur._id,
        note: {
          text: note.text,
          date: note.date
        }
      }).then((res) => {
        this.investisseur.notes = res

      })
      }
    },

    //Formate la date
    formatDate(date) {
      return moment(date).format('YYYY/MM/DD HH:mm:ss')
    },
    close() {
      this.$emit('close', this.investisseur);
    },
  },
  computed: {
    notesComputed(){
      return this.investisseur.notes.sort(function (a, b) {
						return new Date(b.date).getTime() - new Date(a.date).getTime()
					})
    }
  }
}
</script>

<style lang="scss" scoped>
.head-text {
  font-size: 15px;
  line-height: 40px;
}

.content-text {
  font-size: 14px;
  line-height: 40px;
}

.subtitle {
  font-size: 14px;
  margin-top: 60px;
}

// #overlay {
//   position: absolute;
//   /* display: none; */
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 55;
//   cursor: pointer;
// }
</style>