<template>
	<footer class="bg-darkblueaestima-500 z-50 flex flex-col text-white sm:text-left relative">
		<div class="custom-shape-divider-top-1659731539">
			<svg
				data-name="Layer 1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 1200 120"
				preserveAspectRatio="none"
			>
				<path
					d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
					class="shape-fill"
				></path>
			</svg>
		</div>

		<div id="ligne1" class="flex flex-col sm:flex-row gap-1/12 p-12 mt-14 justify-between">
			<div id="aPropos" class="flex flex-col mb-8">
				<span class="text-lg font-bold">À propos de Aestima</span>
        <div class="text-xs w-80 text-justify">
          Aestima Immo est un logiciel SaaS qui permet aux conseillers en gestion de patrimoine et conseillers en investissement immobilier  de réaliser, seul ou en équipe, leurs simulations financières et fiscales avec précision via une interface intuitive tout en proposant des rapports vulgarisés et entièrement personnalisables à leurs clients.
        </div>
				<ul class="text-sm font-medium mt-4">
					<li>
						<router-link to="/Qui-sommes-nous"> Qui sommes-nous ?</router-link>
					</li>
					<li class="mt-1">
						<router-link to="/Contact"> Contact</router-link>
					</li>
					<li class="mt-1">
						<router-link to="/CGV"> Conditions générales de vente (CGV)</router-link>
					</li>
					<li class="mt-1">
						<a href="/CGA"> Conditions générales d’affiliation (CGA)</a>
					</li>
				</ul>
			</div>

			<div id="ressources" class="flex flex-col">
				<span class="text-lg font-bold">Les ressources Aestima</span>
				<ul class="text-sm font-medium mt-4">
					<li>
						<router-link to="/register">Outil de simulation en ligne</router-link>
					</li>
					<li class="mt-1">
						<router-link to="/faq">Foire aux questions (FAQ)</router-link>
					</li>
					<li class="mt-1">
						<a target="_blank" href="https://www.youtube.com/channel/UCnxO49T-fOYOpfjv3A28mlw">Tutoriels vidéos</a>
					</li>
					<!-- 
						<li class="mt-1">
							<router-link to="/strategie-investissement-immobilier">
								Comment définir la bonne stratégie d’investissement ?</router-link>
              </li>
              <li class="mt-1">
				  <router-link to="/rentabilite-locative-nette">
					  Comment calculer une rentabilité ?</router-link>
              </li>
              <li class="mt-1">
                <router-link to="/monter-dossier-bancaire">
                Comment monter mon dossier bancaire ?</router-link>
              </li>
              <li class="mt-1">
                <router-link to="/investir-sci">
					Investir en SCI, une bonne solution ?</router-link>
              </li> 
              -->
					<li class="mt-1">
						<router-link to="/lexique">
							Lexique de l’investissement immobilier</router-link>
					</li>
					<li class="mt-1">
						<a target="_blank" href="https://www.linkedin.com/company/71833960/">Communauté professionnelle</a>
					</li>
				</ul>

				<div id="reseaux" class="flex flex-row gap-4 mt-4 justify-center sm:justify-start">
					<a
						id="linkedin"
						target="_blank"
						href="https://www.linkedin.com/company/aestima-immo/"
						class="hover:bg-black hover:border-black border-solid border border-white rounded-full p-2"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							width="23"
							height="23"
							viewBox="0 0 172 172"
							style="fill: #000000"
						>
							<g
								fill="none"
								fill-rule="nonzero"
								stroke="none"
								stroke-width="1"
								stroke-linecap="butt"
								stroke-linejoin="miter"
								stroke-miterlimit="10"
								stroke-dasharray=""
								stroke-dashoffset="0"
								font-family="none"
								font-weight="none"
								font-size="none"
								text-anchor="none"
								style="mix-blend-mode: normal"
							>
								<path d="M0,172v-172h172v172z" fill="none"></path>
								<g fill="#ffffff">
									<path
										d="M139.75,0h-107.5c-17.80469,0 -32.25,14.44531 -32.25,32.25v107.5c0,17.80469 14.44531,32.25 32.25,32.25h107.5c17.80469,0 32.25,-14.44531 32.25,-32.25v-107.5c0,-17.80469 -14.44531,-32.25 -32.25,-32.25zM53.18149,146.00361h-26.71996l-0.15504,-80.00481h26.71995zM39.14964,55.53305h-0.15505c-8.73437,0 -14.36779,-6.02103 -14.36779,-13.51502c0,-7.67488 5.81431,-13.48919 14.70373,-13.48919c8.88942,0 14.34194,5.8143 14.52283,13.48919c0,7.49399 -5.65926,13.51502 -14.70372,13.51502zM145.82272,146.00361h-26.95253v-43.46515c0,-10.51743 -2.79087,-17.67548 -12.19712,-17.67548c-7.18389,0 -11.0601,4.83233 -12.92067,9.50962c-0.69772,1.67969 -0.8786,3.97957 -0.8786,6.33113v45.29988h-27.08173l-0.15505,-80.00481h27.08173l0.15505,11.29267c3.46274,-5.34916 9.22536,-12.92067 23.02463,-12.92067c17.10697,0 29.89844,11.16346 29.89844,35.17007v46.46274z"
									></path>
								</g>
							</g>
						</svg>
					</a>

					<a
						id="instagram"
						target="_blank"
						href="https://www.instagram.com/aestima_immo/"
						class="mr-2 hover:bg-black hover:border-black border-solid border border-white rounded-full p-2"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							width="24"
							height="24"
							viewBox="0 0 172 172"
							style="fill: #000000"
						>
							<g
								fill="none"
								fill-rule="nonzero"
								stroke="none"
								stroke-width="1"
								stroke-linecap="butt"
								stroke-linejoin="miter"
								stroke-miterlimit="10"
								stroke-dasharray=""
								stroke-dashoffset="0"
								font-family="none"
								font-weight="none"
								font-size="none"
								text-anchor="none"
								style="mix-blend-mode: normal"
							>
								<path d="M0,172v-172h172v172z" fill="none"></path>
								<g fill="#ffffff">
									<path
										d="M59.125,17.91667c-22.72192,0 -41.20833,18.48642 -41.20833,41.20833v53.75c0,22.72192 18.48642,41.20833 41.20833,41.20833h53.75c22.72192,0 41.20833,-18.48642 41.20833,-41.20833v-53.75c0,-22.72192 -18.48642,-41.20833 -41.20833,-41.20833zM121.83333,43c3.95958,0 7.16667,3.20708 7.16667,7.16667c0,3.956 -3.20708,7.16667 -7.16667,7.16667c-3.95958,0 -7.16667,-3.21067 -7.16667,-7.16667c0,-3.95958 3.20708,-7.16667 7.16667,-7.16667zM86,50.16667c19.7585,0 35.83333,16.07483 35.83333,35.83333c0,19.7585 -16.07483,35.83333 -35.83333,35.83333c-19.7585,0 -35.83333,-16.07483 -35.83333,-35.83333c0,-19.7585 16.07483,-35.83333 35.83333,-35.83333zM86,60.91667c-13.85314,0 -25.08333,11.23019 -25.08333,25.08333c0,13.85314 11.23019,25.08333 25.08333,25.08333c13.85314,0 25.08333,-11.23019 25.08333,-25.08333c0,-13.85314 -11.23019,-25.08333 -25.08333,-25.08333z"
									></path>
								</g>
							</g>
						</svg>
					</a>
				</div>
			</div>
			<div id="AMF" class="flex mt-6 sm:w-1/6 sm:ml-1/6">
				<div class="flex flex-row gap-4 sm:flex-col items-center sm:items-end">
					<img
						class="sm:w-48 w-28"
						src="../../assets/icons/home/aestima_amf.png"
						alt="logo amf"
					/>
					<p class="mt-3 text-xxs text-center sm:text-right">
						Les fondateurs de la solution Aestima sont diplômés de Master de finance et
						d’immobilier et certifiés par l’Autorité des Marchés financiers.
					</p>
				</div>
			</div>
		</div>

		<hr class="w-11/12 place-self-center" />

		<div id="celuidubas">
			<div
				id="ligne2"
				class="flex flex-row px-12 pt-6 pb-6 justify-between place-items-center"
			>
				<div class="flex justify-center items-center">
					<router-link to="/">
						<img
							src="../../assets/logo_aestima.png"
							alt="logo détouré de l'entreprise aestima"
							class="w-32"
						/>
					</router-link>
				</div>
				<p class="">© {{ year }} AESTIMA. Tous droits réservés.</p>
				<a class="">
					<router-link to="/mentions-legales"> Mentions légales</router-link>
				</a>
			</div>
		</div>
	</footer>
</template>

<script>
// import { mapGetters, mapActions } from "vuex"
export default {
	name: 'Footer',
	data() {
		return {};
	},
	props: {},
	computed: {
		// ...mapGetters({
		//   authenticated: 'users/authenticated',
		//   user: 'users/user'
		// })
		year() {
			return new Date().getFullYear();
		},
	},
	methods: {
		// ...mapActions("users", ["login"]),
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-aestima-dark {
	background: #0e1e3a !important;
}

.text-xxs {
	font-size: 0.6rem;
}
.custom-shape-divider-top-1659293971 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	line-height: 0;
}

.custom-shape-divider-top-1659293971 svg {
	position: relative;
	display: block;
	width: calc(100% + 1.3px);
	height: 30px;
}

.custom-shape-divider-top-1659293971 .shape-fill {
	fill: #f0f5fa;
}
.custom-shape-divider-top-1659731539 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	line-height: 0;
}

.custom-shape-divider-top-1659731539 svg {
	position: relative;
	display: block;
	width: calc(100% + 1.3px);
	height: 70px;
}

.custom-shape-divider-top-1659731539 .shape-fill {
	fill: #f0f5fa;
}
</style>
