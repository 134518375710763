<template>
  <div class="wrapper z-40">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 172 172" style="fill: #000000">
      <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g fill="#14a0eb">
          <path d="M86,6.88c-43.62608,0 -79.12,35.49392 -79.12,79.12c0,43.62608 35.49392,79.12 79.12,79.12c43.62608,0 79.12,-35.49392 79.12,-79.12c0,-43.62608 -35.49392,-79.12 -79.12,-79.12zM92.18168,127.0564c0,0.74304 -0.49536,1.23152 -1.23152,1.23152h-9.37744c-0.74648,0 -1.23496,-0.49192 -1.23496,-1.23152v-10.60896c0,-0.7396 0.49192,-1.23152 1.23496,-1.23152h9.37744c0.7396,0 1.23152,0.49536 1.23152,1.23152zM103.03488,80.04192l-8.26632,11.352c-2.46648,3.32992 -3.20952,5.0568 -3.20952,9.25016v3.94568c0,0.7396 -0.49192,1.23152 -1.23152,1.23152h-8.14248c-0.7396,0.01376 -1.23152,-0.4816 -1.23152,-1.2212v-5.0568c0,-4.93984 1.10768,-7.52672 3.698,-11.10776l8.26976,-11.352c4.31376,-5.92024 5.79296,-8.75824 5.79296,-12.95504c0,-7.03136 -4.93296,-11.4724 -11.96776,-11.4724c-6.90752,0 -11.34856,4.19336 -12.82776,11.72008c-0.12384,0.7396 -0.61576,1.11112 -1.3588,0.98728l-7.77096,-1.3588c-0.74304,-0.12384 -1.11112,-0.61576 -0.99072,-1.3588c1.85416,-11.84392 10.36816,-19.61832 23.19936,-19.61832c13.31968,0 22.3256,8.75824 22.3256,20.97368c0,5.92368 -2.09152,10.24088 -6.28832,16.04072z"></path>
        </g>
      </g>
    </svg>
    <div class="tooltip">
      <p v-html="text"></p>
      <p v-html="signature"></p>
    </div>
    <!-- <img :src="{img}" alt=""> -->
  </div>
</template>

<script>
export default {
  name: 'QuestionMarkv2bottom',
  props: {
    text: { type: String },
    signature: { type: String },
    img: { type: String },
  },
};
</script>

<style scoped>
.wrapper {
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.wrapper .tooltip {
  background: #c7caff;
  top: 100%;
  color: #2c3e50;
  display: block;
  left: -14rem;
  font-size: small;
  margin-top: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  width: 30rem;
  height: fit-content;
  z-index: 999999;
  border-radius: 30px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
  bottom: -20px;
  content: ' ';
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #c7caff 10px;
  top: -10px;
  content: ' ';
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
  display: none;
}

.lte8 .wrapper:hover .tooltip {
  display: block;
}
</style>
