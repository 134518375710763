<template>
    <div class="autocomplete">
		<input
			class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out text-xs sm:leading-5"	
			type="text" @input="onChange"
			v-model="search" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter.prevent="onEnter"
		/>
		<ul id="autocomplete-results"
			v-show="isOpen" class="autocomplete-results">
			<li class="loading flex justify-center items-center h-full"
				v-if="isLoading">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" display: block; shape-rendering: auto;" width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
					<circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25" stroke-dasharray="70.68583470577033 25.561944901923447">
					<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
					</circle>
				</svg>
			</li>
			<li v-else
				v-for="(result, i) in results"
				:key="i" @click="setResult(result)" class="autocomplete-result"
				:class="{ 'is-active': i === arrowCounter }">
				{{ result.properties.label }}
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
        name: "AutoComplete",
        props: {
			items: [],
			api: { type: Function },
			isAsync: null,
			value: ''
        },

        data() {
			return {
				isOpen: false,
				results: [],
				search: '',
				isLoading: false,
				arrowCounter: 0,
				_items:[],
				perPage:100,
				pageNumber:1
			};
        },
		computed: {
			...mapGetters({
				user: 'users/user',
			}),
		},
        methods: {
			async onChange() {
				// Let's warn the parent (whatever using this component) that a change was made
				this.$emit('input', this.search);
				
				// Is the data given by an outside ajax request? (server-side)
				if (this.isAsync && this.api != null) {
					this.isLoading = true;
					this.isOpen = true;
					let apigouv = await this.api(this.search);
					// console.log("apigouv ======>", apigouv)
					// this.results = apigouv.features.map(v=>v);
					
					if(apigouv) {
						this.results = apigouv.features.map(v=>v);
					} else {
						return
					}
					// console.log("apigouv ======>", this.results)
					// return
					this.isLoading = false;
				} else {
					// Let's filter our internal array (client-side)
					this.filterResults();
					this.isOpen = true
				}
			},
			filterResults() {
				// first uncapitalize all the things
				this.results = this.items.filter((item) => {
					return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
				});
			},
			setResult(result) {
				this.search = result.properties.label;
				this.isOpen = false;
				// emit the changes so the parent component will get notified
				this.$emit('selected', result);
			},
			onArrowDown(evt) {
				if (this.arrowCounter < this.results.length) {
					this.arrowCounter = this.arrowCounter + 1;
				}
			},
			onArrowUp() {
				if (this.arrowCounter > 0) {
					this.arrowCounter = this.arrowCounter - 1;
				}
			},
			onEnter() {
				this.search = this.results[this.arrowCounter];
				this.isOpen = false;
				this.arrowCounter = -1;
				this.$emit('input', this.search);
			},
			// this will close the drop-down section when the user click some where
			// out side of the component
			handleClickOutside(evt) {
				if (!this.$el.contains(evt.target)) {
					this.isOpen = false;
					this.arrowCounter = -1;
				}
			},
        },
        watch: {

        },
    }
</script>

<style scoped>
	.autocomplete {
        position: relative;
    }

    .autocomplete-results {
        z-index: 1000;
        position: absolute;
        padding: 0;
        margin: 0;
        border: 1px solid rgb(186, 206, 228);
        border-radius: 4px;
        height: 120px;
        overflow: auto;
        width: 100%;
        background-color: white;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
        background-color: white;
    }

    .autocomplete-result.is-active,
    .autocomplete-result:hover {
        background-color: #14a0eb;
        color: white;
    }
</style>