<template>
  <div class="flex flex-row">
	  <LeftSignin/>
    <div class="w-full sm:w-2/3">
      <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full">
          <img class="mx-auto h-36 w-auto" src="../../assets/logo_aestima-white.svg" alt="Workflow">
          <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Renseignez l'adresse email liée à votre compte
          </h2>
          <form class="mt-8" @submit.prevent="submit">
            <div class="rounded-md shadow-sm">
              <div>
              <input required v-model="email" aria-label="Email address" name="email" type="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Email address">
              </div>
            </div>
            <div class="mt-6">
              <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out">
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg class="h-5 w-5 text-blueaestima-500 group-hover:blueaestima-500 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                </svg>
              </span>
              Envoyer
              </button>
            </div>
            <svg v-if="modalPDFLoader == true"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto;  margin-top:20px; background: none; display: block; shape-rendering: auto;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
              <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25" stroke-dasharray="70.68583470577033 25.561944901923447">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
              </circle>
            </svg>
            <div v-if="isSend == true" class="mt-6 px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-lg bg-green-100 text-green-800 border-green-800  border border-solid flex justify-center items-center">
              <svg class="h-10 w-10 p-2 mr-2 text-green-800 bg-white rounded-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <span>Un email a été envoyé à l'adresse mail : {{email}}</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftSignin from '@/components/LeftSignin.vue'
import { mapGetters, mapActions } from "vuex"

export default {
  name: 'ForgotPassword',
  data() {
		return {
      email: '',
      isSend: false,
		}
  },
  metaInfo(){
		return {
			title: `Mot de passe oublié - Aestima`,
			meta: [{
				name: 'description',
				content: "Cette page permet de changer de mot de passe lorsqu'il a été oublié."
			}]
			//keywords
		}
	},
  components: {
    LeftSignin,
  },
  computed: {
		...mapGetters({
			modalPDFLoader: 'invests/modalPDFLoader',
    }),
  },
  methods: {
    ...mapActions({
      sendRequestForgotPassword: 'users/sendRequestForgotPassword',
      setModalPDFLoader: 'invests/setModalPDFLoader',
    }),
    submit() {
      this.setModalPDFLoader(true)
      this.sendRequestForgotPassword({
        email: this.email
      })
      .then(() => {
        this.setModalPDFLoader(false)
        this.isSend = true
        setTimeout(() => {
					this.isSend = false
				}, 6000);
      })
    },
	},
}
</script>

<style>

</style>