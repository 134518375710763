import store from '@/store'
import axios from 'axios'
store.subscribe((mutation) => {
	switch(mutation.type) {
		case 'users/SET_TOKEN':
			if (mutation.payload) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`
				localStorage.setItem('token', mutation.payload)
			} else {
				axios.defaults.headers.common['Authorization'] = null
				// localStorage.setItem('token', null)
				localStorage.removeItem('token')

			}
			break;
			
		case 'users/SET_REFRESH_TOKEN':
			if (mutation.payload) {
				// axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`
				localStorage.setItem('refresh_token', mutation.payload)
			} else {
				// axios.defaults.headers.common['Authorization'] = null
				// localStorage.setItem('token', null)
				localStorage.removeItem('refresh_token')

			}
			break;
	}
})