export default class Investor {
  /**
   * Retourne la part fiscale d'un investisseur sans enfant
   * @param {*} situation La situation
   * @returns
   */
  static partsFiscaleSansEnfant(situation) {
    if (situation === 'celibataire' || situation === 'divorce' || situation === 'separe') return 1;
    if (situation === 'marie' || situation === 'veuf' || situation === 'pacse') return 2;
    return 0;
  }
  /**
   * Retourne la part fiscale d'un investisseur avec enfant
   *  @param {*} situation La situation
   *  @param {*} nbr le nombre d'enfants
   * @returns
   */
  static partsFiscaleEnfant(situation, nbr) {
    let parts = 0;
    if (Number(nbr) === 1) parts = 0.5;
    else if (Number(nbr) === 2) parts = 1;
    else if (Number(nbr) > 0) parts = Number(nbr) - 1;
    return parts + this.partsFiscaleSansEnfant(situation);
  }

  /**
   * Retourne le revenu fiscal d'un investisseur pour une année
   * @param {*} fiscalite L'object fiscalité
   * @param {*} i L'année voulue
   * @returns
   */
  static revenueFiscale(fiscalite, i) {
    if (fiscalite == null) {
      return 0;
    } else {
      return (
        +(fiscalite[i].salaire - fiscalite[i].frais) +
        +fiscalite[i].fonciers +
        +fiscalite[i].bic +
        +fiscalite[i].autrebic +
        +fiscalite[i].rcm -
        +fiscalite[i].deduction
      );
    }
  }
  /**
   * Retourne le quotient familial d'un investisseur pour une année
   * @param {*} fiscalite L'object fiscalité
   * @param {*} i L'année voulue
   * @returns
   */
  static quotientFamilial(fiscalite, i) {
    let revenue = this.revenueFiscale(fiscalite, i);
    if (fiscalite == null) {
      return 0;
    } else {
      return Math.round(revenue / this.partsFiscaleEnfant(fiscalite[0].situation, fiscalite[0].enfants));
    }
  }
  /**
   * Retourne la TMI variable d'un investisseur pour une année
   * @param {*} fiscalite L'object fiscalité
   * @param {*} i L'année voulue
   * @param {*} tmis Tableau des TMIS en BDD mongo
   * @returns
   */
  static getTMI(fiscalite, i, tmis) {
    const tmisArray = Object.keys(tmis).filter((t) =>
      tmis[t].start === undefined
        ? 0
        : (tmis[t].start < this.quotientFamilial(fiscalite, i) && tmis[t].end === undefined) ||
          tmis[t].end > this.quotientFamilial(fiscalite, i)
    );
    return tmisArray[0];
  }
  /**
   * Retourne la TMI d'un nombre passé en paramètre
   * @param {*} nombre Le nombre
   * @param {*} tmis Tableau des TMIS en BDD mongo
   * @returns
   */
  static getTMINombre(nombre, tmis) {
    const tmisArray = Object.keys(tmis).filter((t) =>
      tmis[t].start === undefined
        ? 0
        : (tmis[t].start < nombre && tmis[t].end === undefined) || tmis[t].end > nombre
    );
    return tmisArray[0];
  }

  /**
   * Retourne les impots sur le revenus via la fiscalité et l'année
   * @param {*} base Base imposable
   * @param {*} i L'année
   * @param {*} tmis Le tableau de TMI en BDD Mongo
   * @returns
   */
  static impotIR(base, i, tmis) {
    let restant = base;
    let impot = 0;
    let tmi = tmis[0];
    if (tmi.end === undefined || tmi.end > restant) return 0;
    else restant = restant - tmi.end;
    tmi = tmis[11];
    if (tmi.end === undefined || tmi.end > restant) {
      impot += restant * 0.11;
      return impot;
    } else {
      impot += tmi.end * 0.11;
      restant -= tmi.end;
    }
    tmi = tmis[30];
    if (tmi.end === undefined || tmi.end > restant) {
      impot += restant * 0.3;
      return impot;
    } else {
      impot += tmi.end * 0.3;
      restant -= tmi.end;
    }
    tmi = tmis[41];
    if (tmi.end === undefined || tmi.end > restant) {
      impot += restant * 0.41;
      return impot;
    } else {
      impot += tmi.end * 0.41;
      restant -= tmi.end;
    }
    tmi = tmis[45];
    impot += restant * 0.45;
    return impot;
  }

  /**
   * Calcule l'imposition de revenu global en ce basant sur la feuille Excel
   * @param {Number} quotient_familial Le quotient familial qui se base sur son calcul excel
   * @param {Number} tmi La tranche de taxe (0 ,11 ,30 ,41 ,45 ...)
   * @param {Object} tmis Le tableau de TMI en BDD Mongo
   */
  static imposition_revenu_global(quotient_familial, tmi, tmis, situation) {
    //Ici on fait les calculs pour avoir les base rapide et IR rapide des tranches
    let calculs_tmis = [];
    Object.keys(tmis).forEach((element, index) => {
      let calcul_base_rapide = 0;
      let calcul_ir_rapide = 0;
      if (Object.keys(tmis).length == ++index) {
        calcul_base_rapide = null;
        calcul_ir_rapide = null;
      } else {
        calcul_base_rapide = tmis[element].end - tmis[element].start;
        calcul_ir_rapide = Math.round(calcul_base_rapide * (element / 100));
      }
      calculs_tmis.push({ tmi: element, ...tmis[element], calcul_base_rapide, calcul_ir_rapide });
    });
    //Ensuite en fonction de la tranche on calcule l'imposition
    let imposition = 0;
    calculs_tmis.forEach((calcul, index) => {
      if (tmi == calcul.tmi) {
        //0 taxe au premier palier qui est 0
        if (index == 0) {
          return;
        } else {
          let somme_calcul_ir_rapide = 0;
          for (let i = 0; i < index; i++) {
            somme_calcul_ir_rapide += calculs_tmis[i].calcul_ir_rapide;
          }
          imposition = Math.round(
            somme_calcul_ir_rapide + (quotient_familial - calcul.start) * (calcul.tmi / 100)
          );
        }
      }
    });
    imposition = imposition * situation;
    return imposition;
  }
}
