<template>
<div>
		<l-map
		:center="[polygon.centre.coordinates[1], polygon.centre.coordinates[0]]"
		:zoom="zoom"
		:minZoom="minZoom"
		:maxZoom="maxZoom"
		class="map"
		ref="map"
		:options="{
			zoomControl: false,
			zoomDelta: zoomDelta,
			zoomSnap: zoomDelta,
			dragging: true,
		}"
		>
		<l-tile-layer
			:url="url"
			:options="{
				maxZoom: 25
			}"
		>
		</l-tile-layer>
		
		<!-- <l-polygon :lngs-lat="polygon.contour.coordinates[0]" :color="color"></l-polygon> -->
		<l-geo-json :geojson="polygon.contour"></l-geo-json>

		<l-control-zoom position="topright"></l-control-zoom>
		</l-map>
</div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LControlZoom, LPolygon, LGeoJson } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
 components: {
   LMap,
   LTileLayer,
   LMarker,
   LControlZoom,
   LPolygon,
   LGeoJson,
 },
  props: {
		polygon: Object,
	},
 data () {
   return {
		// url: 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
		url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
		// url: 'http://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png',
		// url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		zoomObject: {
			start: 0,
			end: 0,
		},
		zoom: 12,
		minZoom: 10,
		maxZoom: 13,
		zoomDelta: 1,
		color: 'green',
		
   }
 },
 mounted () {
	//  console.log("polygon =====>",this.polygon.contour.coordinates[0])
	//  console.log("polygon centre =====>",this.polygon.centre.coordinates)
 },
 methods: {
	zoomUpdate (zoom) {
		this.zoom = zoom;
   },
   zoomStart (zoom) {
   },
   zoomEnd (zoom) {
   },
   centerUpdated (center) {
   },
   
 },
 computed: {
 }
}
</script>

<style>
 .map {
   position: absolute;
   width: 100%;
   height: 100%;
   overflow :hidden
 } 
 
 .cursor {
	transition: 0.2s;
 }
 .cursor:hover {
	border-radius: 100%;
	padding: 3px;
	background: rgba(20,160,235,0.4);
	width:10rem;
	height:10rem;
 }


</style>