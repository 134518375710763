<template>
  <div
    tabindex="-1"
    class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal justify-center items-center flex"
  >
    <div class="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>
    <div class="relative my-auto p-8 w-full h-full md:h-auto">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="absolute right-2 top-2 p-4 z-100 cursor-pointer" @click="closeAndDiscard($event)">
          <CloseIcon />
        </div>
        <div class="flex justify-center items-center p-5">
          <h3 class="font-bold text-black" style="font-size: 30px">
            Tableau détaillé et évolutif de la fiscalité du client année par année
          </h3>
        </div>
        <div class="p-6 overflow-y-auto overflow-x-auto">
          <table class="w-full table-fixed text-sm text-left text-gray-500 rounded-lg border">
            <thead class="text-xs text-white" style="background-color: #0c1e3b">
              <tr>
                <th scope="col" class="col1 py-3">N° de période</th>
                <th v-for="(n, i) in 30" scope="col" class="value marges">
                  {{ i + 1 }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white">
                <th scope="row" class="rowStyle">Parts fiscales du foyer sans enfants</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <input
                    type="number"
                    min="1"
                    v-model.number="fiscalite[i].partssansenfants"
                    @change="propagate(i, 'partssansenfants', 1)"
                    :placeholder="partsFiscalesAdultes(i)"
                  />
                </th>
              </tr>
              <tr class="bg-white dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="rowStyle">Nombres d'enfants</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <input
                    type="number"
                    commentaire="!!! en fait on a ici le nombre d'enfants et non de PF"
                    :min="partsFiscalesAdultes(i)"
                    v-model.number="fiscalite[i].enfants"
                    @change="propagate(i, 'enfants', fiscalite[i].enfants)"
                    :placeholder="partsFiscalesTot(i)"
                  />
                </th>
              </tr>
              <tr class="bg-gray-200 border-b dark:bg-gray-700 dark:border-gray-600">
                <th scope="row" class="rowStyle">Parts fiscales du foyer avec enfants</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <p>{{ partsFiscalesTot(i) }}</p>
                </th>
              </tr>
              <tr class="bg-white">
                <th scope="row" class="rowStyle text-gray-900">Traitements et salaires</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <input
                    type="number"
                    min="0"
                    v-model.number="fiscalite[i].salaire"
                    @change="propagate(i, 'salaire', 0)"
                    :placeholder="fiscalite[0].salaire"
                  />
                </th>
              </tr>
              <tr class="bg-white">
                <th scope="row" class="rowStyle text-gray-900">Frais professionnels déductibles</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <input
                    type="number"
                    min="0"
                    v-model.number="fiscalite[i].frais"
                    @change="propagate(i, 'frais', 0)"
                    :placeholder="fiscalite[0].salaire / 10"
                  />
                </th>
              </tr>
              <!-- <tr class="bg-white">
                <th scope="row" class="rowStyle text-gray-900">Traitements et salaires nets</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <input
                    type="number"
                    min="0"
                    :value="fiscalite[i].salaire - fiscalite[i].frais"
                    disabled
                    placeholder="0€"
                  />
                </th>
              </tr> -->
              <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="rowStyle text-gray-900">Revenus fonciers nets actuels</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <input
                    type="number"
                    min="0"
                    v-model.number="fiscalite[i].fonciers"
                    @change="propagate(i, 'fonciers', 0)"
                    :placeholder="fiscalite[0].fonciers"
                  />
                </th>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="rowStyle text-gray-900">Revenus BIC issus de la location meublée</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <input
                    type="number"
                    min="0"
                    v-model.number="fiscalite[i].bic"
                    @change="propagate(i, 'bic', 0)"
                    :placeholder="fiscalite[0].bic"
                  />
                </th>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="rowStyle text-gray-900">Autres revenus BIC / BNC existants</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <input
                    type="number"
                    min="0"
                    v-model.number="fiscalite[i].autrebic"
                    @change="propagate(i, 'autrebic', 0)"
                    :placeholder="fiscalite[0].autrebic"
                  />
                </th>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="rowStyle text-gray-900">
                  Revenus RCM (dividendes, placements) existants
                </th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <input
                    type="number"
                    min="0"
                    v-model.number="fiscalite[i].rcm"
                    @change="propagate(i, 'rcm', 0)"
                    :placeholder="fiscalite[0].rcm"
                  />
                </th>
              </tr>
              <tr class="bg-white dark:bg-gray-800">
                <th scope="row" class="rowStyle text-gray-900">
                  Abattements / déductions (pensions, +65 ans) existants
                </th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <input
                    type="number"
                    min="0"
                    v-model.number="fiscalite[i].deduction"
                    @change="propagate(i, 'deduction', 0)"
                    :placeholder="fiscalite[0].deduction"
                  />
                </th>
              </tr>
              <tr class="bg-gray-200 dark:bg-gray-800">
                <th scope="row" class="px-6 text-xs font-bold text-gray-900">
                  Revenu net imposable (RNI) avant projet
                </th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  <p>{{ RNI(i) }}</p>
                </th>
              </tr>
              <tr class="text-xs text-white" style="background-color: #0c1e3b">
                <th scope="row" class="px-6 py-2 font-bold text-white">
                  Taux marginal d’imposition (TMI) par année
                </th>
                <th v-for="(n, i) in 30" scope="col" class="px-6">{{ getImpot(i).tmi * 100 }} %</th>
              </tr>
              <tr class="bg-gray-200 dark:bg-gray-800">
                <th scope="row" class="rowStyle text-gray-900">Quotient familial retenu pour le calcul</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  {{ getImpot(i).qf }}
                </th>
              </tr>
              <tr class="bg-gray-200 dark:bg-gray-800">
                <th scope="row" class="rowStyle text-gray-900">Impôt sur le revenu indicatif par année</th>
                <th v-for="(n, i) in 30" scope="col" class="marges">
                  {{ getImpot(i).impot }}
                </th>
              </tr>
            </tbody>
          </table>
          <div class="my-6">
            <div class="px-4 pt-6 pb-8 text-right sm:px-6 flex items-center">
              <div class="-ml-12 absolute mt-8 w-full">
                <div class="flex justify-evenly">
                  <button
                    @click="closeAndSave($event)"
                    class="whitespace-no-wrap inline-flex font-bold items-center justify-center px-15 py-4 border border-transparent text-base leading-6 rounded-md text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
                  >
                    Enregistrer
                  </button>
                  <button
                    @click="closeAndDiscard($event)"
                    class="whitespace-no-wrap inline-flex font-bold items-center justify-center px-15 py-4 border border-transparent text-base leading-6 rounded-md text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseIcon from '@/components/SVG/CloseIcon.vue'

export default {
  name: 'Fiscalite.vue',
  Data() {
    return {
      fiscaliteOriginale: {},
      impotsIndicatifs: [],
    }
  },
  components: {
    CloseIcon,
  },
  props: {
    fiscalite: Object,
  },
  computed: {
    ...mapGetters({
      tmi: 'projects/tmi',
    }),
  },
  created() {
    this.impotsIndicatifs = new Array(30).fill(0)
  },
  methods: {
    closeAndSave(event) {
      event.preventDefault()
      this.$emit('close', true, this.fiscalite, this.getImpot(0).tmi, this.RNI(0), this.impotsIndicatifs)
    },
    closeAndDiscard(event) {
      event.preventDefault()
      this.$emit('close', false, this.fiscaliteOriginale)
    },
    propagate(i, row, min) {
      const length = Object.keys(this.fiscalite).length
      if (this.fiscalite[i][row] < min) this.fiscalite[i][row] = min
      for (let j = i + 1; j < length; j++) {
        this.fiscalite[j][row] = this.fiscalite[i][row]
        if (this.fiscalite[j][row] < min) this.fiscalite[j][row] = min
      }
    },
    partsFiscalesAdultes(i) {
      if(this.fiscalite[i].partssansenfants && this.fiscalite[i].partssansenfants != 0) return this.fiscalite[i].partssansenfants
      let situation = this.fiscalite[i].situation
      if (situation === 'celibataire' || situation === 'divorce' || situation === 'separe') return 1
      if (situation === 'marie' || situation === 'veuf' || situation === 'pacse') return 2
    },
    partsFiscalesEnfants(i) {
      let nbEnfants = this.fiscalite[i].enfants
      if (nbEnfants == 0) return 0
      if (nbEnfants == 1) return 0.5
      else return nbEnfants - 1
    },
    partsFiscalesTot(i) {
      return this.partsFiscalesAdultes(i) + this.partsFiscalesEnfants(i)
    },
    RNI(i) {
      return (
        this.fiscalite[i].salaire -
        this.fiscalite[i].frais +
        this.fiscalite[i].fonciers +
        this.fiscalite[i].bic +
        this.fiscalite[i].autrebic +
        this.fiscalite[i].rcm -
        this.fiscalite[i].deduction
      )
    },
    quotientFamilial(i, parts) {
      return Math.round(this.RNI(i) / parts)
    },
    getTMI(i) {
      const tmis = Object.keys(this.tmi).filter((t) =>
        this.tmi[t].start === undefined
          ? 0
          : (this.tmi[t].start < this.quotientFamilial(i, this.partsFiscalesTot(i)) &&
              this.tmi[t].end === null) ||
            this.tmi[t].end > this.quotientFamilial(i, this.partsFiscalesTot(i))
      )
      return tmis[0] + '%'
    },
    getImpot(i) {
      let baremeEnfant = 3184
      let impotCalcul1 = this.impotRefacto(i, this.partsFiscalesAdultes(i), false)
      let impotCalcul2 = this.impotRefacto(i, this.partsFiscalesTot(i), true)
      // if (i == 1) console.log('QF1', impotCalcul1.qf, 'tmi1', impotCalcul1.tmi, 'impot1', impotCalcul1.impot)
      // if (i == 1) console.log('QF2', impotCalcul2.qf, 'tmi2', impotCalcul2.tmi, 'impot2', impotCalcul2.impot)
      // if (i == 1) console.log('QF3', impotSelect.qf, 'tmi3', impotSelect.tmi, 'impot3', impotSelect.impot)
      if (impotCalcul1.impot - impotCalcul2.impot <= baremeEnfant*this.partsFiscalesEnfants(this.fiscalite[i].enfants)) {
        this.impotsIndicatifs[i] = impotCalcul2.impot
	      return impotCalcul2
      } else {
	      impotCalcul1.impot -= baremeEnfant*this.partsFiscalesEnfants(this.fiscalite[i].enfants)
        this.impotsIndicatifs[i] = impotCalcul1.impot
	      return impotCalcul1
      }
    },
    impotRefacto(i, parts) {
      let impot = 0
      let taux = 0
      let qf = this.RNI(i) / parts
      let resteImposable = qf
      let tmiAdmin = []
      let tranches = Object.keys(this.tmi)
      tranches.map((t, i) => tmiAdmin.push([Number(tranches[i]) / 100, this.tmi[t].start]))
      // tmiAdmin => [[0, 0], [11, 10226], [30, 26071], [41, 74546], [45, 160337]]
      for (let i = 0; i < tmiAdmin.length; i++) {
        taux = tmiAdmin[i][0]
        let seuilPrev = tmiAdmin[i][1]
        let seuil
        console.log('impot', impot)

        if (tmiAdmin[i + 1]) seuil = tmiAdmin[i + 1][1]
        else seuil = 999999999

        if (resteImposable < seuil - seuilPrev) {
          impot += resteImposable * taux
          break
        } else {
          impot += (seuil - seuilPrev) * taux
          resteImposable -= seuil - seuilPrev
        }
      }
      impot *= parts
      return { impot: Math.round(impot), tmi: taux, qf: Math.round(qf) }
    },
    impotIR(i) {
      let restant = this.quotientFamilial(i, this.partsFiscalesTot(i))
      let impot = 0
      let tmi = this.tmi[0]
      if (tmi.end === undefined || tmi.end > restant) return 0
      else restant = restant - tmi.end
      tmi = this.tmi[11]
      if (tmi.end === undefined || tmi.end > restant) {
        impot += restant * 0.11
        return impot
      } else {
        impot += tmi.end * 0.11
        restant -= tmi.end
      }
      tmi = this.tmi[30]
      if (tmi.end === undefined || tmi.end > restant) {
        impot += restant * 0.3
        return impot
      } else {
        impot += tmi.end * 0.3
        restant -= tmi.end
      }
      tmi = this.tmi[41]
      if (tmi.end === undefined || tmi.end > restant) {
        impot += restant * 0.41
        return impot
      } else {
        impot += tmi.end * 0.41
        restant -= tmi.end
      }
      tmi = this.tmi[45]
      impot += restant * 0.45
      return impot
    },
  },
  mounted() {
    this.fiscaliteOriginale = JSON.parse(JSON.stringify(this.fiscalite))
  },
}
</script>

<style scoped>
table {
  width: 100%;
}

.col1 {
  padding-left: 1.5rem;
  width: 22rem;
}

.value {
  width: 100px;
  /* border: #248831 solid; */
}
.rowStyle {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  font-size: 0.75rem;
  color: #161e2e;
  /* width: 24rem; */
}

.marges {
  /* centers the content */
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

input {
  width: 80px;
}
</style>
