import axios from 'axios'
import router from '../router/index.js'
import ProjectTools from '../tools/Project.js'

export default {
  namespaced: true,
  state: {
    projects_list: [],
    projects_summary: [],
    project_modal: false,
    project_message: '',
    project_selected: {},
    globals: {},
    tmi: {},
    selected_localisation: {},
    selected_type_project: {},
    project_optimisation: {},
    project_optimisation_selected: {},
    project_optimisation_run: false,
    project_comparison: {},
    project_bank_pdf_boolean: false,
    coworker_new_simulation: {},
  },
  getters: {
    project(state) {
      return state.project
    },
    projects(state) {
      return state.projects_list
    },
    projects_summary(state) {
      return state.projects_summary
    },
    modal(state) {
      return state.project_modal
    },
    message(state) {
      return state.project_message
    },
    selected(state) {
      return state.project_selected
    },
    globals(state) {
      return state.globals
    },
    tmi(state) {
      return state.tmi
    },
    selected_localisation(state) {
      return state.selected_localisation
    },
    selected_type_project(state) {
      return state.selected_type_project
    },
    project_optimisation(state) {
      return state.project_optimisation
    },
    project_optimisation_selected(state) {
      return state.project_optimisation_selected
    },
    project_optimisation_run(state) {
      return state.project_optimisation_run
    },
    project_comparison(state) {
      return state.project_comparison
    },
    project_bank_pdf_boolean(state) {
      return state.project_bank_pdf_boolean
    },
    forme_juridique_project(state) {
      return state.project_selected.carac.formejuridique.filter(d => d.selected)[0]
    },
    coworker_new_simulation(state) {
      return state.coworker_new_simulation
    },
  },
  mutations: {
    SET_PROJECTS_LIST(state, proj) {
      state.projects_list = proj
    },
    SET_PROJECTS_SUMMARY(state, proj) {
      state.projects_summary = proj
    },
    SET_MODAL(state, modal) {
      state.project_modal = true
      state.project_message = modal
    },
    UNSET_MODAL(state) {
      state.project_modal = false
      state.project_message = ''
    },
    SET_PROJECT_SELECTED(state, proj) {
      state.project_selected = proj
    },
    SET_GLOBALS(state, globals) {
      state.globals = globals
    },
    UNSET_STATE(state) {
      state.projects_list = []
      state.projects_summary = []
      state.project_modal = false
      state.project_message = ''
      state.project_selected = {}
    },
    SET_SELECTED_LOCALISATION(state, loc) {
      state.selected_localisation = loc
    },
    SET_SELECTED_TYPE_PROJECT(state, type) {
      state.selected_type_project = type
    },
    SET_PROJECT_OPTIMISATION(state, proj) {
      state.project_optimisation = proj
    },
    SET_PROJECT_OPTIMISATION_RUN(state, boolean) {
      state.project_optimisation_run = boolean
    },
    SET_PROJECT_OPTIMISATION_SELECTED(state, proj) {
      state.project_optimisation_selected = proj
    },
    SET_PROJECT_COMPARISON(state, proj) {
      state.project_comparison = proj
    },
    SET_BANK_PDF_BOOLEAN(state) {
      state.project_bank_pdf_boolean = !state.project_bank_pdf_boolean
    },
    SET_TMI(state, tmi) {
      state.tmi = tmi
    },
    SET_COWORKER_NEW_SIMULATION(state, coworker) {
      state.coworker_new_simulation = coworker
    },
  },
  actions: {
    async getProjects({ dispatch, commit, getters }, id) {
      console.log('GET PROJECTS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
      // await axios
      //   .get('/projects', {
      //     params: {
      //       idUser: id,
      //     },
      //   })
      //   .then((response) => {
      //     commit('SET_PROJECTS_LIST', response.data)
      //     if (response.data.length > 0 && getters.selected) {
      //       if (getters === undefined || Object.keys(getters.selected).length === 0) {
      //         commit('SET_PROJECT_SELECTED', response.data[0])
      //       }
      //     }
      //   })
      //   .catch((e) => {
      //     throw e
      //   })
    },

    async getProject({ dispatch, commit, getters }, params) {
      await axios
        .get(`/project/${params.idProject}`, {
          params: {
            idUser: params.idUser,
          },
        })
        .then((response) => {
          commit('SET_PROJECT_SELECTED', response.data)
        })
        .catch((e) => {
          throw e
        })
    },

    async getProjectsSummary({ commit, getters }, id) {
      console.log("Get Project Summary")
      const timestamp = new Date().getTime()
      await axios
        .get('/projects/summary', {
          params: {
            idUser: id,
            _: timestamp,
          },
        })
        .then((response) => {
          commit('SET_PROJECTS_SUMMARY', response.data)
          commit('SET_PROJECTS_LIST', response.data)

          if (response.data.length > 0 && getters.selected) {
            if (getters === undefined || !Object.keys(getters.selected).length) {
              commit('SET_PROJECT_SELECTED', response.data[0])
            }
          }
        })
        .catch((e) => {
          throw e
        })
    },

    async setProject({ dispatch, commit, getters, rootGetters }, { idUser, body, formDatas, res }) {
      try {
        const response = await axios.post(`/project/${idUser}`, body)

        const idProject = response.data.id

        const res2 = await axios.patch(`/project/result/${idProject}`, { result: res })
        res2.data.proj = await ProjectTools.setDataProject(
          res2.data.proj,
          rootGetters['projects/globals'],
          rootGetters['invests/selected'],
          rootGetters['projects/tmi']
        )

        const res3 = await axios.patch(`/project/result/${idProject}`, { result: res2.data.proj.results })

        await dispatch('getProjectsSummary', idUser)
        await commit('SET_PROJECT_SELECTED', res3.data.proj)

        if (formDatas) {
          const reponseImage = await axios.post(`/project/image/${idProject}`, formDatas)
          await commit('SET_PROJECT_SELECTED', reponseImage.data.proj)
        }
        await commit('SET_MODAL', response.data.message)

        await dispatch('invests/getInvestsList', idUser, { root: true })

        setTimeout(() => commit('UNSET_MODAL', null), 3000)
      } catch (e) {
        throw e
      }
      router.push('/MesDossiers/Resultats')
    },

    async setProjectImg({ dispatch, commit, rootGetters }, { id, formData }) {
      await axios
        .patch(`/invest/image/${id}`, formData)
        .then((response) => {
          commit('projects/SET_MODAL', response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 3000)

          dispatch('getInvestsList', rootGetters['users/user']._id)
          router.push('/MesDossiers')
        })
        .catch((e) => {
          throw e
        })
    },

    async updateOwner({ commit }, { id, owner }) {
      await axios
        .patch(`/project/${id}/owner`, owner)
        .catch((e) => {
          throw e
        })
    },

    async unlockProject({ dispatch, commit }, { idUser, idProject }) {
      const body = { idUser }
      await axios
        .patch(`/project/result/paid/${idProject}`, body)
        .then((response) => {
          // Après avoir enregistré les neo résultats dans l'investisseur
          // On refresh la list d'investisseur
          dispatch('invests/getInvestsList', idUser, {
            root: true,
          })

          dispatch('getProjectsSummary', idUser)

          dispatch('users/setUserRefreshed', null, {
            root: true,
          })

          // Puis, on remplit la liste de projet selectionné avec l'object créé
          commit('SET_PROJECT_SELECTED', response.data.proj)

          commit('SET_MODAL', response.data.message)
          setTimeout(() => {
            commit('UNSET_MODAL', null)
          }, 3000)

          // On fait une redirection vers la page des résultats
          // router.push("/Investisseur/Resultats")
          // router.push("/MesDossiers/Resultats")
        })
        .catch((e) => {
          throw e
        })
    },

    async setProjectOpti({ dispatch, commit }, { idUser, idInvest, body }) {
      body.idInvest = idInvest
      await axios
        .post(`/project/optimisation/${idUser}`, body)
        .then((response) => {
          // Après avoir enregistré les neo résultats dans l'investisseur
          // On refresh la list d'investisseur
          dispatch('invests/getInvestsList', idUser, { root: true })

          dispatch('getProjectsSummary', idUser)

          // Puis, on remplit la liste de projet selectionné avec l'object créé
          commit('SET_PROJECT_SELECTED', response.data.proj)

          commit('SET_MODAL', response.data.message)
          setTimeout(() => {
            commit('UNSET_MODAL', null)
          }, 3000)

          // On fait une redirection vers la page des résultats
          // router.push("/Investisseur/Resultats")

          router.push('/MesDossiers/Resultats')
        })
        .catch((e) => {
          throw e
        })
    },

    async updateProject(
      { dispatch, commit, getters, rootGetters },
      { id, idUser, formDatas, body, res },
    ) {
      await axios
        .patch(`/project/${id}`, body)
        .then((response) => {
          // Mise en place des calculs via l'outils projet
          // TIMELINE
          const reducer = (accumulator, currentValue) => accumulator + currentValue
          const investisseur = rootGetters['invests/selected']

          response.data.proj.results.resCoutTotalFonc = res.resCoutTotalFonc
          response.data.proj.results.resFluxAIFonc = res.resFluxAIFonc
          response.data.proj.results.resInvTravFonc = res.resInvTravFonc
          response.data.proj.results.resOperationNetFonc = res.resOperationNetFonc
          response.data.proj.results.timelineFonc = res.timelineFonc

          res.timelineFonc.timelineAchevementTravauxClassiqueNeuf
            = ProjectTools.getArrayTotalTimelineAchevementTravauxMiseLocationClassiqueNeuf(
              response.data.proj,
            )
          response.data.proj.results.timelineFonc.timelineAchevementTravauxClassiqueNeuf
            = res.timelineFonc.timelineAchevementTravauxClassiqueNeuf
          res.timelineFonc.timelineAchevementTravauxPinelNeuf
            = ProjectTools.getArrayTotalTimelineAchevementTravauxMiseLocationPinelNeuf(
              response.data.proj,
            )
          response.data.proj.results.timelineFonc.timelineAchevementTravauxPinelNeuf
            = res.timelineFonc.timelineAchevementTravauxPinelNeuf
          res.timelineFonc.timelineAchevementTravauxPinelAncienDenormandie
            = ProjectTools.getArrayTotalTimelineAchevementTravauxMiseLocationPinelAncienDenormandie(
              response.data.proj,
            )
          response.data.proj.results.timelineFonc.timelineAchevementTravauxPinelAncienDenormandie
            = res.timelineFonc.timelineAchevementTravauxPinelAncienDenormandie
          res.timelineFonc.timelineTempsLocationPinelAncienDenormandie
            = ProjectTools.getArrayTotalTimelineTempsLocationPinelAncienDenormandie(
              response.data.proj,
            )
          response.data.proj.results.timelineFonc.timelineTempsLocationPinelAncienDenormandie
            = res.timelineFonc.timelineTempsLocationPinelAncienDenormandie
          res.timelineFonc.timelineTempsLocationPinelNeuf
            = ProjectTools.getArrayTotalTimelineTempsLocationPinelNeuf(response.data.proj)
          response.data.proj.results.timelineFonc.timelineTempsLocationPinelNeuf
            = res.timelineFonc.timelineTempsLocationPinelNeuf
          res.timelineFonc.timelineTempsLocationNeufClassique
            = ProjectTools.getArrayTotalTimelineTempsLocationNeufClassique(response.data.proj)
          response.data.proj.results.timelineFonc.timelineTempsLocationNeufClassique
            = res.timelineFonc.timelineTempsLocationNeufClassique
          res.timelineFonc.timelineReductionImpotPinelNeuf
            = ProjectTools.getArrayTotalTimelineReductionImpotPinelNeuf(response.data.proj)
          response.data.proj.results.timelineFonc.timelineReductionImpotPinelNeuf
            = res.timelineFonc.timelineReductionImpotPinelNeuf
          res.timelineFonc.timelineReductionImpotPinelAncienDenormandie
            = ProjectTools.getArrayTotalTimelineReductionImpotPinelAncienDenormandie(
              response.data.proj,
            )
          response.data.proj.results.timelineFonc.timelineReductionImpotPinelAncienDenormandie
            = res.timelineFonc.timelineReductionImpotPinelAncienDenormandie
          res.timelineFonc.timelineChoixJuridiqueNuReelSCIIRReel
            = ProjectTools.getArrayTimelineChoixJuridiqueNuReelSCIIRReel(response.data.proj)
          response.data.proj.results.timelineFonc.timelineChoixJuridiqueNuReelSCIIRReel
            = res.timelineFonc.timelineChoixJuridiqueNuReelSCIIRReel

          // Données diverses
          res.resOperationNetFonc.resLoyerEnt = ProjectTools.getArrayLoyer(response.data.proj)
          response.data.proj.results.resOperationNetFonc.resLoyerEnt
            = res.resOperationNetFonc.resLoyerEnt

          res.resOperationNetFonc.resPertes = ProjectTools.getArrayPertes(response.data.proj)
          response.data.proj.results.resOperationNetFonc.resPertes
            = res.resOperationNetFonc.resPertes
          res.resOperationNetFonc.resRevLoc = ProjectTools.getArrayRevenusLocatifs(
            response.data.proj,
          )
          response.data.proj.results.resOperationNetFonc.resRevLoc
            = res.resOperationNetFonc.resRevLoc
          res.resOperationNetFonc.resStructureSCI = ProjectTools.getArrayFraisStructureEntreprise(
            response.data.proj,
          )
          response.data.proj.results.resOperationNetFonc.resStructureSCI
            = res.resOperationNetFonc.resStructureSCI

          const globals = rootGetters['projects/globals']
          const data_globals_reduction_impots = {
            reduction_1_9: globals.reductionannee1a9,
            reduction_10_12: globals.reductionannee10a12,
          }
          res.resOperationNetFonc.reductionImpots = ProjectTools.getArrayReductionImpots(
            response.data.proj,
            data_globals_reduction_impots,
            investisseur
          )
          response.data.proj.results.resOperationNetFonc.reductionImpots
            = res.resOperationNetFonc.reductionImpots
          res.resOperationNetFonc.resAssPNO = ProjectTools.getArrayAssurancePNO(response.data.proj)
          response.data.proj.results.resOperationNetFonc.resAssPNO
            = res.resOperationNetFonc.resAssPNO
          res.resOperationNetFonc.resGLI = ProjectTools.getArrayGarantieLoyerImpaye(
            response.data.proj,
          )
          response.data.proj.results.resOperationNetFonc.resGLI = res.resOperationNetFonc.resGLI
          res.resOperationNetFonc.resFraisGestLoc = ProjectTools.getArrayFraisGestionLocative(
            response.data.proj,
          )
          response.data.proj.results.resOperationNetFonc.resFraisGestLoc
            = res.resOperationNetFonc.resFraisGestLoc
          res.resOperationNetFonc.resFraisCopro = ProjectTools.getArrayFraisCopropriete(
            response.data.proj,
          )
          response.data.proj.results.resOperationNetFonc.resFraisCopro
            = res.resOperationNetFonc.resFraisCopro
          res.resOperationNetFonc.resTaxeFonc = ProjectTools.getArrayTaxeFonciere(
            response.data.proj,
          )
          response.data.proj.results.resOperationNetFonc.resTaxeFonc
            = res.resOperationNetFonc.resTaxeFonc
          res.resOperationNetFonc.resFraisExploit = ProjectTools.getArrayFraisExploitation(
            response.data.proj,
          )
          res.resOperationNetFonc.resTotFraisExploit
            = res.resOperationNetFonc.resFraisExploit.reduce(reducer)
          response.data.proj.results.resOperationNetFonc.resFraisExploit
            = res.resOperationNetFonc.resFraisExploit
          response.data.proj.results.resOperationNetFonc.resTotFraisExploit
            = res.resOperationNetFonc.resTotFraisExploit

          res.resOperationNetFonc.resAutresFrais = ProjectTools.getArrayAutresFraisDeductibles(
            response.data.proj,
          )
          response.data.proj.results.resOperationNetFonc.resAutresFrais
            = res.resOperationNetFonc.resAutresFrais
          res.resInvTravFonc.resItr = ProjectTools.getArrayInteretCagnotteEtBase(response.data.proj)
          res.resInvTravFonc.resTotItr = res.resInvTravFonc.resItr.reduce(reducer)
          response.data.proj.results.resInvTravFonc.resItr = res.resInvTravFonc.resItr
          response.data.proj.results.resInvTravFonc.resTotItr = res.resInvTravFonc.resTotItr
          res.resFluxAIFonc.resBaseImposable = ProjectTools.getArrayBaseImposable(
            response.data.proj,
          )
          response.data.proj.results.resFluxAIFonc.resBaseImposable
            = res.resFluxAIFonc.resBaseImposable

          res.resInvTravFonc.resEmprunt = ProjectTools.getArrayEmprunt(response.data.proj)
          response.data.proj.results.resInvTravFonc.resEmprunt = res.resInvTravFonc.resEmprunt
          res.resInvTravFonc.resRmbCap = ProjectTools.getArrayRemboursementCapital(
            response.data.proj,
          )
          response.data.proj.results.resInvTravFonc.resRmbCap = res.resInvTravFonc.resRmbCap
          res.resInvTravFonc.resBalanceBancaire = ProjectTools.getArrayBalanceBancaire(
            response.data.proj,
          )
          response.data.proj.results.resInvTravFonc.resBalanceBancaire
            = res.resInvTravFonc.resBalanceBancaire
          res.resInvTravFonc.resRmbDette = ProjectTools.getArrayRemboursementDette(
            response.data.proj,
          )
          response.data.proj.results.resInvTravFonc.resRmbDette = res.resInvTravFonc.resRmbDette
          response.data.proj.results.resCoutTotalFonc = res.resCoutTotalFonc
          res.resInvTravFonc.resInvestissement = ProjectTools.getArrayCoutInvestissement(
            response.data.proj,
          ).array_investissement
          response.data.proj.results.resInvTravFonc.resInvestissement
            = res.resInvTravFonc.resInvestissement

          // Pour les données de résultats

          res.resInvTravFonc.resIndemRmbDette = ProjectTools.getArrayIRA(response.data.proj)
          res.resInvTravFonc.resTotIndemRmbDette
            = res.resInvTravFonc.resIndemRmbDette.reduce(reducer)
          response.data.proj.results.resInvTravFonc.resIndemRmbDette
            = res.resInvTravFonc.resIndemRmbDette
          response.data.proj.results.resInvTravFonc.resTotIndemRmbDette
            = res.resInvTravFonc.resTotIndemRmbDette

          const retourPV = ProjectTools.calculTotalPlusValueRevente(response.data.proj)

          res.resFluxAIFonc.plusValueRevente = retourPV.calcul
          res.resCoutTotalFonc.resPrixAchat = retourPV.array_prix_achat
          res.resCoutTotalFonc.resTVA = retourPV.array_tva

          // TIR

          const data_cagnotte = ProjectTools.getArrayBaseImposableNetteEtCagnotte(
            response.data.proj,
            investisseur,
          )
          res.resFluxAIFonc.resBaseImposableNette = data_cagnotte.array_base_imposable_nette
          response.data.proj.results.resFluxAIFonc.resBaseImposableNette
            = res.resFluxAIFonc.resBaseImposableNette
          res.resFluxAIFonc.resCagnotteReportable = data_cagnotte.array_cagnotte
          response.data.proj.results.resFluxAIFonc.resCagnotteReportable
            = res.resFluxAIFonc.resCagnotteReportable
          const calculImpotPlusValue = ProjectTools.calculTotalImpositionPlusValue(
            response.data.proj,
            globals,
          )
          res.resFluxAIFonc.resPV[res.resFluxAIFonc.resPV.length - 1]
            = -calculImpotPlusValue.total_impots
          response.data.proj.results.resFluxAIFonc.resPv = res.resFluxAIFonc.resPV
          response.data.proj.results.resFluxAIFonc.resPV[
            response.data.proj.results.resFluxAIFonc.resPV.length - 1
          ] = -calculImpotPlusValue.total_impots

          res.resFluxAIFonc.resTotPV = res.resFluxAIFonc.resPV.reduce(reducer)
          response.data.proj.results.resFluxAIFonc.resTotPV
            = res.resFluxAIFonc.resPV.reduce(reducer)
          const tmis = rootGetters['projects/tmi']
          const dataImpositionDetaille = []
          const dureeempr
            = response.data.proj.carac.dureedetention.isAuto === true
              ? Number(response.data.proj.carac.dureedetention.valAuto / 12)
              : Number(response.data.proj.carac.dureedetention.val)
          const imposition = []
          const imposition_revenus_locatifs = []
          const imposition_plus_value = []
          const imposition_dividende = []
          for (let index = 0; index <= dureeempr; index++) {
            const resultat = ProjectTools.calculTableauImposition(
              response.data.proj,
              index,
              dataImpositionDetaille,
              investisseur,
              tmis,
              globals
            )
            imposition_revenus_locatifs.push(
              resultat.data_imposition.imposition_revenu_bien_locatif
              + resultat.data_imposition.results_impots_is,
            )
            imposition_plus_value.push(
              resultat.data_imposition.results_impots_cession + resultat.data_imposition.plus_value,
            )
            imposition_dividende.push(
              resultat.data_imposition.results_dividendes
              + resultat.data_imposition.results_prevelement_sociaux_dividende,
            )
            dataImpositionDetaille.push(resultat.tableau_imposition)
            // if (dataImpositionDetaille[index][9] < 0) {
            //   imposition.push(Math.abs(dataImpositionDetaille[index][9]));
            // } else {
            // }
            imposition.push(dataImpositionDetaille[index][9])
          }
          res.resFluxAIFonc.resImposition = imposition
          response.data.proj.results.resFluxAIFonc.resImposition = res.resFluxAIFonc.resImposition
          res.resFluxAIFonc.resImpositionRevenusLocatifs = imposition_revenus_locatifs
          response.data.proj.results.resFluxAIFonc.resImpositionRevenusLocatifs
            = imposition_revenus_locatifs
          res.resFluxAIFonc.resImpositionPlusValue = imposition_plus_value
          response.data.proj.results.resFluxAIFonc.resImpositionPlusValue = imposition_plus_value
          res.resFluxAIFonc.resImpositionDividende = imposition_dividende
          response.data.proj.results.resFluxAIFonc.resImpositionDividende = imposition_dividende
          res.resFluxAIFonc.resTotImpositionRevenusLocatifs = imposition_revenus_locatifs.reduce(
            (a, b) => a + b,
          )
          response.data.proj.results.resFluxAIFonc.resTotImpositionRevenusLocatifs
            = res.resFluxAIFonc.resTotImpositionRevenusLocatifs
          res.resFluxAIFonc.resTotImpositionPlusValue = imposition_plus_value.reduce(
            (a, b) => a + b,
          )
          response.data.proj.results.resFluxAIFonc.resTotImpositionPlusValue
            = res.resFluxAIFonc.resTotImpositionPlusValue
          res.resFluxAIFonc.resTotImpositionDividende = imposition_dividende.reduce((a, b) => a + b)
          response.data.proj.results.resFluxAIFonc.resTotImpositionDividende
            = res.resFluxAIFonc.resTotImpositionDividende
          res.resFluxAIFonc.resTotImposition = imposition.reduce((a, b) => a + b)
          response.data.proj.results.resFluxAIFonc.resTotImposition = imposition.reduce(
            (a, b) => a + b,
          )

          // Diagrammes
          res.resOperationNetFonc.resOpeNet = ProjectTools.getArrayResultatOperationnelNet(
            response.data.proj,
          )
          response.data.proj.results.resOperationNetFonc.resOpeNet
            = res.resOperationNetFonc.resOpeNet

          res.resFluxAIFonc.resFluxAvtDette = ProjectTools.getArrayFluxAvantDettes(
            response.data.proj,
          )
          response.data.proj.results.resFluxAIFonc.resFluxAvtDette
            = res.resFluxAIFonc.resFluxAvtDette

          res.resFluxAIFonc.resFluxAvtImpot = ProjectTools.getArrayFluxNetsAvantImpots(
            response.data.proj,
          )
          response.data.proj.results.resFluxAIFonc.resFluxAvtImpot
            = res.resFluxAIFonc.resFluxAvtImpot

          res.resFluxAIFonc.resFluxApresImpots = ProjectTools.getArrayFluxNetsApresImpots(
            response.data.proj,
          )
          response.data.proj.results.resFluxAIFonc.resFluxApresImpots
            = res.resFluxAIFonc.resFluxApresImpots

          res.resFluxAIFonc.resCashFlowNonActu = ProjectTools.getArrayCashFlowNonActu(
            response.data.proj,
          )
          response.data.proj.results.resFluxAIFonc.resCashFlowNonActu
            = res.resFluxAIFonc.resCashFlowNonActu
          res.resFluxAIFonc.resTotCashFlowNonActu
            = response.data.proj.results.resFluxAIFonc.resCashFlowNonActu.reduce((a, b) => a + b)
          response.data.proj.results.resFluxAIFonc.resTotCashFlowNonActu
            = res.resFluxAIFonc.resTotCashFlowNonActu

          res.resFluxAIFonc.resCashFlowActu = ProjectTools.getArrayCashFlowActu(response.data.proj)
          response.data.proj.results.resFluxAIFonc.resCashFlowActu
            = res.resFluxAIFonc.resCashFlowActu
          res.resFluxAIFonc.resTotCashFlowActu
            = response.data.proj.results.resFluxAIFonc.resCashFlowActu.reduce((a, b) => a + b)
          response.data.proj.results.resFluxAIFonc.resTotCashFlowActu
            = res.resFluxAIFonc.resTotCashFlowActu

          res.resFluxAIFonc.resCashFlowCumul = ProjectTools.getArrayCashFlowCumul(
            response.data.proj,
          )
          response.data.proj.results.resFluxAIFonc.resCashFlowCumul
            = res.resFluxAIFonc.resCashFlowCumul

          res.resFluxAIFonc.resSommeInvest = ProjectTools.getArraySommeInvestie(response.data.proj)
          response.data.proj.results.resFluxAIFonc.resSommeInvest = res.resFluxAIFonc.resSommeInvest
          res.resFluxAIFonc.resTotSommeInvest
            = response.data.proj.results.resFluxAIFonc.resSommeInvest.reduce((a, b) => a + b)
          response.data.proj.results.resFluxAIFonc.resTotSommeInvest
            = res.resFluxAIFonc.resTotSommeInvest

          res.resFluxAIFonc.economieFiscale = ProjectTools.calculTotalEconomieFiscale(
            response.data.proj,
          )
          response.data.proj.results.resFluxAIFonc.economieFiscale
            = res.resFluxAIFonc.economieFiscale

          response.data.proj.results.resFluxAIFonc.plusValueRevente
            = res.resFluxAIFonc.plusValueRevente
          response.data.proj.results.resCoutTotalFonc.resPrixAchat
            = res.resCoutTotalFonc.resPrixAchat
          response.data.proj.results.resCoutTotalFonc.resTVA = res.resCoutTotalFonc.resTVA

          res.resFluxAIFonc.revenuLocatifTotalApresEndettement
            = ProjectTools.calculRevenuLocatifTotalApresEndettement(response.data.proj)
          response.data.proj.results.resFluxAIFonc.revenuLocatifTotalApresEndettement
            = res.resFluxAIFonc.revenuLocatifTotalApresEndettement

          const tir = ProjectTools.calculToutTIR(
            response.data.proj,
            rootGetters['projects/globals'].tactualisationPer,
          )
          res.resFluxAIFonc.performance.sansDette.tir = tir.sansDette
          res.resFluxAIFonc.performance.avecDette.tir = tir.avecDette
          res.resFluxAIFonc.performance.avecFisc.tir = tir.cashFlowNonActu
          res.resFluxAIFonc.performance.tir = tir.cashFlowActu
          res.resFluxAIFonc.performance.avecDette.effetlevier = Number(
            (((tir.avecDette / 100 - tir.sansDette / 100) / (tir.sansDette / 100)) * 100).toFixed(2),
          )

          if (formDatas) {
            axios.post(`/project/image/${id}`, formDatas)
          }

          axios
            .patch(`/project/result/${id}`, {
              result: res,
            })
            .then((res) => {
              // Après avoir enregistré les neo résultats dans l'investisseur
              // On refresh la list d'investisseur
              dispatch('invests/getInvestsList', idUser, {
                root: true,
              })

              dispatch('getProjectsSummary', idUser)
              // Puis, on remplit la liste de projet selectionné avec l'object créé
              commit('SET_PROJECT_SELECTED', res.data.proj)

              commit('SET_MODAL', response.data.message)
              setTimeout(() => {
                commit('UNSET_MODAL', null)
              }, 3000)

              // On fait une redirection vers la page des résultats
              // router.push("/Investisseur/Resultats")
              this.setProjectOptimisation(res.data.proj)
              this.setProjectSelectedResult(res.data.proj)
              router.push('/MesDossiers/Resultats')
            })
            .catch((e) => {
              router.push('/MesDossiers/Resultats')
              throw e
            })
        })
        .catch((e) => {
          throw e
        })
    },

    setProjectSelected({ dispatch, commit }, { proj, index }) {
      if (proj != null) {
        dispatch('invests/getInvests', proj._id, {
          root: true,
        })
        proj.index = index
      }
      commit('SET_PROJECT_SELECTED', proj)
    },

    setProjectSelectedResult({ commit }, proj) {
      commit('SET_PROJECT_SELECTED', proj)
    },

    async updateTmi({ dispatch, commit }, tmi) {
      await axios
        .post('/tmi/update', tmi)
        .then((response) => {
          commit('SET_TMI', tmi)
          commit('SET_MODAL', response.data.message)
          router.push('/Home')
        })
        .catch(error => console.error(error))
    },

    async updateGlobals({ dispatch, commit }, { id, globals }) {
      await axios
        .patch(`/globals/${id}`, globals)
        .then((response) => {
          // Met à jour les nouvelles valeurs globales dans vuex
          dispatch('setGlobals', response.data.globals)
          commit('SET_MODAL', response.data.message)
          setTimeout(() => {
            commit('UNSET_MODAL')
          }, 3000)
          router.push('/Home')
        })
        .catch((error) => {
          throw error
        })
    },

    async duplicateProject({ dispatch, commit }, { idProject, idInvest, idUser }) {
      const body = { idInvest }
      // Appel de l'api pour modifier les valeurs globales
      await axios
        .patch(`/project/duplicate/${idProject}`, body)
        .then((response) => {
          // Après avoir enregistré les neo résultats dans l'investisseur
          // On refresh la list d'investisseur
          dispatch('invests/getInvestsList', idUser, {
            root: true,
          })

          dispatch('getProjectsSummary', idUser, {
            root: true,
          })

          // Puis, on remplit la liste de projet selectionné avec l'object créé
          commit('SET_PROJECT_SELECTED', response.data.proj)

          commit('SET_MODAL', response.data.message)
          setTimeout(() => {
            commit('UNSET_MODAL', null)
          }, 3000)
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    async setProjectByInvestor({ dispatch, commit }, proj) {
      dispatch('invests/getInvests', proj._id, {
        root: true,
      })
      commit('SET_PROJECT_SELECTED', proj)
    },

    setGlobals({ commit }, globals) {
      commit('SET_GLOBALS', globals)
    },

    setTmi({ commit }, tmi) {
      commit('SET_TMI', tmi)
    },

    async deleteProject({ dispatch, commit, rootGetters }, { idProject, idInvest }) {
      await axios
        .delete('/project/delete', {
          params: {
            idProject,
            idInvest,
          },
        })
        .then((response) => {
          dispatch('invests/getInvestsList', rootGetters['users/user']._id, {
            root: true,
          })

          dispatch('getProjectsSummary', rootGetters['users/user']._id)

          commit('SET_MODAL', response.data.message)
          setTimeout(() => {
            commit('UNSET_MODAL')
          }, 3000)
        })
        .catch((e) => {
          throw e
        })
    },

    async generateBankPDF({ commit }, { formData, data, idProj, idInv }) {
      if (formData == null) {
        const params = {
          datas: data,
          proj: idProj,
          inv: idInv,
        }
        return axios.get('/export/pdf_v2', {
          params,
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
            // 'Access-Control-Allow-Origin': '*',
          },
        })
      } else {
        return await axios
          .post('/export/image', formData)
          .then((response) => {
            const params = {
              datas: data,
              proj: idProj,
              inv: idInv,
              files: response.data.filesName,
              logo: response.data.logo,
            }
            return axios.get('/export/pdf', {
              params,
              responseType: 'arraybuffer',
              headers: {
                Accept: 'application/pdf',
                // 'Access-Control-Allow-Origin': '*',
              },
            })
          })
          .catch((e) => {
            throw e
          })
      }
    },

    async setBankPDFBool({ dispatch, commit }) {
      commit('SET_BANK_PDF_BOOLEAN')
    },

    async setProjectLocalisation({ dispatch, commit }, loc) {
      commit('SET_SELECTED_LOCALISATION', loc)
    },

    async setProjectType({ dispatch, commit }, type) {
      commit('SET_SELECTED_TYPE_PROJECT', type)
    },

    async setProjectOptimisation({ dispatch, commit }, proj) {
      commit('SET_PROJECT_OPTIMISATION', proj)
    },
    async setProjectOptimisationSelected({ dispatch, commit }, proj) {
      commit('SET_PROJECT_OPTIMISATION_SELECTED', proj)
    },
    async setProjectOptimisationRun({ dispatch, commit }, boolean) {
      commit('SET_PROJECT_OPTIMISATION_RUN', boolean)
    },
    async setProjectComparison({ dispatch, commit }, proj) {
      commit('SET_PROJECT_COMPARISON', proj)
    },
  },
}
