<template>
  <div id="etude_marche">
    <div class="px-5 py-5 mt-5 card">
      <div class="flex flex-row align-middle card-header">
        <img src="@/assets/icons/etude_marche_1.jpg" class="h-20" alt="l’acquisition du bien" />
        <div class="flex flex-col my-auto ml-5">
          <p class="text-xl font-bold ">Etude de marché relative à cet investissement</p>
          <p class="text-sm">L'emplacement ne changera pas. D'où l'importance de bien analyser en prenant en compte
            toutes les variables. Et ne l'oubliez pas, "il y a 3 règles d'or pour investir dans l'immobilier :
            l'emplacement, l'emplacement, l'emplacement".</b></p>

          <!-- {{ showPositif }} -->
        </div>
      </div>
    </div>
    <!-- HISTORIQUE TRANSACTIONS  -->
    <!--
    <div class="px-5 py-5 mt-5 card">
      <div class="flex flex-row align-middle card-header">
        <img src="@/assets/icons/etude_marche_2.jpg" class="h-20" alt="Présentation" />
        <div class="flex flex-col my-auto ml-5">
          <p class="text-xl font-bold ">Historique de transactions immobilières sur votre secteur</p>
        </div>
      </div>
      <div class="mt-5 sm:mt-0 sm:col-start-2 sm:col-end-12">
        <div class="sm:rounded-md">
          <div v-if="isPaid">
            <div class="flex items-center py-5 bg-white grid grid-cols-11">
              <div
                class="flex flex-col items-start justify-start w-full px-3 pt-4 bg-gray-100 col-span-11 sm:col-start-1 sm:col-end-4 h-40-c">
                <h3 class="text-xl font-bold text-left">
                  Biens vendus autour de <br />
                  {{ this.projectSelected.loc.carac.name }},
                  {{ this.projectSelected.loc.carac.city }}
                </h3>
                <div v-if="this.showLinear" class="flex flex-col items-center my-6 sm:flex-row">
                  <svg class="w-2/6" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60" height="60"
                    viewBox="0 0 172 172" style="fill: #000000">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                      stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                      font-family="none" font-weight="none" font-size="none" text-anchor="none"
                      style="mix-blend-mode: normal">
                      <path d="M0,172v-172h172v172z" fill="none"></path>
                      <g fill="#14a0eb">
                        <path
                          d="M86,17.52474c-1.71731,0 -3.43944,0.60458 -4.80111,1.83366l-66.30566,59.6849c-2.72333,2.451 -0.9815,6.9567 2.6735,6.9567h11.09993v0.02799l57.33333,-51.69238l43,38.6888v63.14225h-28.66667v-21.5h8.34244c3.66934,0 5.40434,-4.53403 2.65951,-6.9707l-38.49284,-34.20964c-2.71616,-2.41516 -6.80206,-2.41516 -9.51823,0l-38.49284,34.20964c-2.74483,2.43667 -1.02383,6.9707 2.64551,6.9707h8.35645v21.5c0,7.91917 6.41417,14.33333 14.33333,14.33333h43h7.16667h28.66667c7.91917,0 14.33333,-6.41417 14.33333,-14.33333v-50.16667h11.09993c3.655,0 5.38967,-4.5057 2.6735,-6.9567l-66.30566,-59.6849c-1.36525,-1.22908 -3.0838,-1.83366 -4.80111,-1.83366z">
                        </path>
                      </g>
                    </g>
                  </svg>
                  <div class="flex flex-col items-start w-4/6">
                    <p class="text-base font-bold">
                      {{ sameAdresse.length }}
                    </p>
                    <p class="text-xs text-left">
                      biens vendus autour de cette adresse <br />
                      <span class="text-xxs">(Dans un rayon de 250m)</span>
                    </p>
                  </div>
                </div>
                <ul v-if="this.showLinear" class="h-48 overflow-y-scroll">
                  <li @click="indexListDVFSet(index, marker)" class="li-box" v-for="(marker, index) in sameAdresse"
                    :key="
                      marker.properties.numero_plan +
                      Math.floor(Math.random() * 10000) +
                      Math.floor(Math.random() * 10000)
                    ">
                    <p class="px-2 text-xs font-semibold text-left">
                      {{ marker.properties.numero_voie }}
                      {{ marker.properties.type_voie }}
                      {{ marker.properties.voie }} -
                      {{ marker.properties.commune }}
                    </p>
                    <p class="px-2 mt-2 text-xs font-normal text-left">
                      <b class="font-semibold text-lightblueaestima-100">{{ marker.sameAdresse.length }}</b>
                      vente(s) pour cette adresse
                    </p>
                    <ul v-show="indexListDVF == index" class="my-2">
                      <li :key="
                        Math.floor(Math.random() * 10000) +
                        adr.properties.numero_plan +
                        Math.floor(Math.random() * 10000)
                      " v-for="adr in marker.sameAdresse"
                        class="flex flex-col justify-start px-2 mx-2 mb-2 bg-gray-100 rounded-md">
                        <p class="text-xs text-left">
                          <b class="font-semibold text-lightblueaestima-100">{{
                              adr.properties.nombre_pieces_principales
                          }}</b>
                          pièce(s) -
                          <b class="font-semibold text-lightblueaestima-100">{{
                              adr.properties.surface_relle_bati
                          }}</b>
                          m²
                        </p>
                        <p class="text-xs text-left">
                          <b class="font-semibold">{{ adr.properties.valeur_fonciere }} €</b><br />
                          <em class="saledate_list">
                            Vendu en
                            <b class="font-semibold">{{
                                new Date(adr.properties.date_mutation).toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                })
                            }}</b></em>
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
                <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  style="display: block; shape-rendering: auto" width="50px" height="50px" viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid">
                  <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25"
                    stroke-dasharray="70.68583470577033 25.561944901923447">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                      dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                  </circle>
                </svg>
              </div>
              <div
                class="relative flex flex-col items-start justify-start w-full h-full text-left col-span-11 sm:col-start-4 sm:col-end-12">
                <Map v-if="this.showLinear" @setCenter="setEmitCenter" :center="center" :datas="dvf"
                  @zoom="setZoomDVF" />
                <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  style="display: block; shape-rendering: auto" width="50px" height="50px" viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid">
                  <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25"
                    stroke-dasharray="70.68583470577033 25.561944901923447">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                      dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                  </circle>
                </svg>
              </div>
              <div
                class="relative flex flex-col items-start justify-start text-left col-span-11 sm:col-start-2 sm:col-end-11">
                <div class="flex flex-row items-center self-center justify-center mt-4">
                  <svg class="transform -rotate-45" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35"
                    height="35" viewBox="0 0 172 172" style="fill: #000000">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                      stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                      font-family="none" font-weight="none" font-size="none" text-anchor="none"
                      style="mix-blend-mode: normal">
                      <path d="M0,172v-172h172v172z" fill="none"></path>
                      <g fill="#14a0eb">
                        <path
                          d="M63.64,0c-16.1336,0 -29.24,13.1064 -29.24,29.24c0,10.4576 5.504,19.61123 13.76,24.80563v-8.7411c-4.2312,-4.0592 -6.88,-9.76933 -6.88,-16.06453c0,-12.3152 10.0448,-22.36 22.36,-22.36c12.3152,0 22.36,10.0448 22.36,22.36c0,6.2952 -2.6488,12.00533 -6.88,16.06453v8.7411c8.256,-5.1944 13.76,-14.34803 13.76,-24.80563c0,-16.1336 -13.1064,-29.24 -29.24,-29.24zM63.63328,20.78781c-3.32304,0 -8.59328,2.22799 -8.59328,8.21703v71.77641c-2.924,1.7544 -13.76,6.48483 -13.76,23.05875c0,13.76 8.65584,24.61916 13.7264,31.5714c5.08432,6.97288 11.28917,16.36204 30.28141,16.56844c0.12728,0.00688 28.23219,0.02015 28.23219,0.02015c15.21856,0 30.96,-11.57904 30.96,-30.96v-59.91781c0,-4.73 -2.56554,-8.88219 -8.48578,-8.88219c-5.92024,0 -8.62015,4.08758 -8.62015,8.7075v8.4925c0,1.89888 -1.53768,3.44 -3.44,3.44c-1.90232,0 -3.53406,-1.54112 -3.53406,-3.44v-15.5539c0.07224,-4.15552 -2.50266,-8.5261 -8.5261,-8.5261c-6.02344,0 -8.62015,4.68823 -8.62015,8.62015v12.06688c0,1.89888 -1.53768,3.44 -3.44,3.44c-1.89888,0 -3.49703,-1.54112 -3.50047,-3.44v-15.50688c0.00344,-3.47784 -2.68073,-8.62015 -8.55969,-8.62015c-5.8824,0 -8.62016,5.14231 -8.62016,8.62015v18.89985c0,1.89888 -1.54112,3.44 -3.44,3.44c-1.89888,0 -3.45344,-1.54112 -3.45344,-3.44v-60.03203c0,-6.32616 -5.14264,-8.62016 -8.60672,-8.62016z">
                        </path>
                      </g>
                    </g>
                  </svg>
                  <p class="ml-4 text-sm">Cliquez sur un point pour afficher son historique de vente.</p>
                </div>
                <div class="relative px-6 py-4 my-10 border-2 rounded-lg tips col-span-6 sm:col-span-4">
                  <img class="absolute z-0 w-auto max-h-10 ampoule-picto" src="@/assets/picto/light.png" alt="" />
                  <p>Portez attention au nombre de transactions realisées dans le quartier. Plus il y en aura, plus
                    votre client aura une chance de revendre son bien rapidement. On appele cela la <b>liquidité du
                      marché</b></p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img v-else class="inline-block w-full mx-2 hauto" src="@/assets/results-map.png" alt="image floutée map" />
        </div>
      </div>
    </div>
    -->
    <!-- FIN HISTORIQUE TRANSACTIONS  -->
    <!-- ANALYSE DEMOGRAPHIQUE -->
    <div class="px-5 py-5 mt-5 card">
      <div class="flex flex-row align-middle card-header">
        <img src="@/assets/icons/etude_marche_3.jpg" class="h-20" alt="Présentation" />
        <div class="flex flex-col my-auto ml-5">
          <p class="text-xl font-bold ">Analyse démographique de la ville</p>
        </div>
      </div>



      <div class="flex flex-col mt-10 gap-12 ml-28">
        <div class="flex flex-col justify-center sm:rounded-md place-items-start gap-5">
          <div class="items-start justify-start text-left">
            <h3 class="font-bold">Type d'occupants des logements</h3>
          </div>
          <div class="flex flex-row justify-between gap-5">
            <div class="flex flex-col justify-between text-left labels grow">
              <div class="flex flex-col justify-between grow gap-3">
                <div class="flex flex-row justify-between grow">
                  <p>Propriétaires</p>
                  <span class="block w-10 h-4 ml-5 chart-blue rounded-md"></span>
                </div>
                <div class="flex flex-row justify-between grow">
                  <p>Locataires</p>
                  <span class="block w-10 h-4 ml-5 chart-blue-light rounded-md"></span>
                </div>
                <div class="flex flex-row justify-between grow">
                  <p>Occupants logés gratuitement</p>
                  <span class="block w-10 h-4 ml-5 chart-orange rounded-md"></span>
                </div>
              </div>
              <div class="flex flex-row items-center justify-between grow">
                <p class="text-lg font-bold text-lightblueaestima-100">Pourcentage de locataires :</p>
                <div v-if="isPaid">
                  <p v-if="showLinear && chartInsee.occ" class="text-xl font-bold text-lightblueaestima-100">
                    <span class="ml-2 text-xl">{{ chartInsee.occ.loc }}%</span>
                  </p>
                  <div v-else class="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                      style="display: block; shape-rendering: auto" width="50px" height="50px" viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid">
                      <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25"
                        stroke-dasharray="70.68583470577033 25.561944901923447">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                          dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                      </circle>
                    </svg>
                  </div>
                </div>
                <img v-else class="inline-block w-auto h-12 mx-2" src="@/assets/results-percent.png"
                  alt="image floutée pourcentage" />
              </div>
            </div>
            <div class='ml-16'>
              <div v-if="isPaid">
                <pie-chart :style="'width: 150px !important; height: 150px !important'"
                  v-if="showLinear && chartInsee.occ" :options="barPieOptions(false)" :chartdata="
                    pieChartdata(
                      ['Propriétaires', 'Locataires', 'Occupants logés gratuitement'],
                      [],
                      [chartInsee.occ.pro, chartInsee.occ.loc, chartInsee.occ.free]
                    )
                  " />
                <div v-else class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="display: block; shape-rendering: auto" width="50px" height="50px" viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25"
                      stroke-dasharray="70.68583470577033 25.561944901923447">
                      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                        dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                    </circle>
                  </svg>
                </div>
              </div>
              <img v-else class="inline-block w-auto mx-2 h-80" src="@/assets/results-chart-pie.png"
                alt="image floutée chart pie" />
            </div>
          </div>
        </div>
        <div id="insee-3" class="flex flex-col gap-5 place-items-start">
          <div class="flex flex-col items-start justify-start text-left">
            <h3 class="font-bold">Répartition de la population par type d'activité</h3>
          </div>
          <div class="flex flex-row items-start justify-start text-left grow gap-5">
            <div class ='labels'>
              <div class="flex flex-row justify-between">
                <p>Actifs ayant un emploi</p>
                <span class="block w-10 h-4 ml-5 chart-blue rounded-md"></span>
              </div>
              <div class="flex flex-row justify-between mt-2">
                <p>Étudiants</p>
                <span class="block w-10 h-4 ml-5 chart-blue-light rounded-md"></span>
              </div>
              <div class="flex flex-row justify-between mt-2">
                <p>Retraités</p>
                <span class="block w-10 h-4 ml-5 chart-orange rounded-md"></span>
              </div>
              <div class="flex flex-row justify-between mt-2">
                <div class="flex flex-row items-center">
                  <p>Autres</p>
                  <QuestionMark class="ml-2"
                    :text="`Comprend les chômeurs et autres inactifs (enfant moins de 15 ans, incapacité de travailler, H/F au foyer, etc.`" />
                </div>
                <span class="block w-10 h-4 ml-5 chart-grey rounded-md"></span>
              </div>
              <div class="flex flex-row items-center justify-between mt-5">
                <p class="text-lg font-bold text-lightblueaestima-100">Taux d’étudiants : </p>
                <div v-if="isPaid">
                  <p v-if="showLinear && chartInsee.pop" class="text-xl font-bold text-lightblueaestima-100">
                    <span class="ml-2 text-xl">{{ chartInsee.pop.etu }}%</span>
                  </p>
                  <div v-else class="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                      style="display: block; shape-rendering: auto" width="50px" height="50px" viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid">
                      <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25"
                        stroke-dasharray="70.68583470577033 25.561944901923447">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                          dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                      </circle>
                    </svg>
                  </div>
                </div>
                <img v-else class="inline-block w-auto h-12 mx-2" src="@/assets/results-percent.png"
                  alt="image floutée pourcentage" />
              </div>
            </div>

            <div class='ml-16'>
              <div v-if="isPaid">
                <pie-chart :style="'width: 150px !important; height: 150px !important'" :width="150" :height="150"
                  v-if="showLinear && chartInsee.pop" class="" :options="barPieOptions(false)" :chartdata="
                    pieChartdata(
                      ['Actifs ayant un emploi', 'Étudiants', 'Retraités', 'Autres'],
                      [],
                      [chartInsee.pop.act, chartInsee.pop.etu, chartInsee.pop.ret, chartInsee.pop.aut]
                    )
                  " />
                <div v-else class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="display: block; shape-rendering: auto" width="50px" height="50px" viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25"
                      stroke-dasharray="70.68583470577033 25.561944901923447">
                      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                        dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                    </circle>
                  </svg>
                </div>
              </div>
              <img v-else class="inline-block w-auto mx-2 h-80" src="@/assets/results-chart-pie.png"
                alt="image floutée chart pie" />
            </div>
          </div>
        </div>
        <div id="insee-2" class="flex flex-col gap-5 place-items-start">
          <h3 class="font-bold">Répartition par types de biens</h3>
          <div class="flex gap-5">
            <div class="flex flex-col justify-start text-left labels gap-3 ">
              <div>
                <div class="flex flex-row justify-between">
                  <p>Résidences principales</p>
                  <span class="block w-10 h-4 ml-5 chart-blue rounded-md"></span>
                </div>
                <div class="flex flex-row justify-between my-2">
                  <p>Résidences secondaires</p>
                  <span class="block w-10 h-4 ml-5 chart-blue-light rounded-md"></span>
                </div>
                <div class="flex flex-row justify-between">
                  <div class="flex flex-row items-center">
                    <p>Logements vacants</p>
                    <QuestionMark class="ml-2"
                      :text="`Indicateur qui donne une légère tendance sur la tension locative de la ville. En effet, un logement peut être vacant si le propriétaire rencontre des difficultés pour trouver un locataire mais pas seulement. Cela peut aussi être lié à des propriétaires qui laissent leur bien locatif en sommeil ou encore à des logements insalubres ne pouvant pas être mis en location.`" />
                  </div>
                  <span class="block w-10 h-4 ml-5 chart-orange rounded-md"></span>
                </div>
              </div>
              <div class="flex flex-row items-center justify-between">
                <p class="text-lg font-bold text-lightblueaestima-100">Taux de logement vacants :</p>
                <div v-if="isPaid">
                  <p v-if="showLinear && chartInsee.rep" class="text-xl font-bold text-lightblueaestima-100">
                    <span class="ml-2 text-xl">{{ chartInsee.rep.lv }}%</span>
                  </p>
                  <div v-else class="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                      style="display: block; shape-rendering: auto" width="50px" height="50px" viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid">
                      <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25"
                        stroke-dasharray="70.68583470577033 25.561944901923447">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                          dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                      </circle>
                    </svg>
                  </div>
                </div>
                <img v-else class="inline-block w-auto h-12 mx-2" src="@/assets/results-percent.png"
                  alt="image floutée pourcentage" />
              </div>
            </div>
            <div class='ml-16'>
              <div v-if="isPaid">
                <pie-chart :style="'width: 150px !important; height: 150px !important'" :width="150" :height="150"
                  v-if="showLinear && chartInsee.rep" class="" :options="barPieOptions(false)" :chartdata="
                    pieChartdata(
                      ['Résidences principales', 'Résidences secondaires', 'Logements vacants'],
                      [],
                      [chartInsee.rep.rp, chartInsee.rep.rs, chartInsee.rep.lv]
                    )
                  " />
                <div v-else class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="display: block; shape-rendering: auto" width="50px" height="50px" viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25"
                      stroke-dasharray="70.68583470577033 25.561944901923447">
                      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                        dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                    </circle>
                  </svg>
                </div>
              </div>
              <img v-else class="inline-block w-auto mx-2 h-80" src="@/assets/results-chart-pie.png"
                alt="image floutée chart pie" />
            </div>
          </div>
        </div>
      <div>
        <p>Source des données : INSEE {{ inseeYear }}</p>
      </div>
      </div>
    </div>
    <!-- FIN ANALYSE DEMOGRAPHIQUE -->
    <!-- EVOLUTION POPULATION -->
    <div class="px-5 py-5 mt-5 card">
      <div class="flex flex-row align-middle card-header">
        <img src="@/assets/icons/etude_marche_4.jpg" class="h-20" alt="Présentation" />
        <div class="flex flex-col my-auto ml-5">
          <p class="text-xl font-bold ">Évolution de la population de  {{this.projectSelected.loc.carac.city == 'Paris' ? this.projectSelected.loc.carac.district : this.projectSelected.loc.carac.city }} </p>
        </div>
      </div>
      <div class="mt-5 sm:mt-0 sm:col-start-2 sm:col-end-12">
        <div class="grid grid-cols-12">
          <div class="col-start-1 col-end-4">
            <p>Nombre d'habitants : <span class="font-semibold text-lightblueaestima-100">{{
                Number(getDataPopulation[13]).toLocaleString()
            }}</span></p>
          </div>
          <div class="col-start-4 col-end-8">
            <p v-if="isPaid">Évolution sur 19 ans : <span v-if="isPaid" class="ml-2 font-semibold" :class="{
              'text-red-600':
                getEvolutionPopulation < 0,
              'text-green-600':
                getEvolutionPopulation >= 0,
            }">
                {{
                    getEvolutionPopulation >= 0
                      ? "+" +
                      getEvolutionPopulation
                      : getEvolutionPopulation
                }}
                %
              </span></p>

            <img v-else class="w-1/12" src="@/assets/blur/blur-title.png" alt="image floutée map" />
          </div>
        </div>
        <div class="flex flex-row justify-between w-full mt-2 py-15 place-items-center">
          <div v-if="isPaid">
              <div class='w-full'>
            <line-chart class="flex flex-grow w-full h-80" :options="lineOptions(false)" :chartdata="
              lineChartData(chartLabelsPopulation, ['Population'], getDataPopulation, ['line'], ['#0C1E3B'])
            " />
    </div>
          </div>
          <img v-else class="inline-block w-full mx-2 hauto" src="@/assets/results-chart-line.png"
            alt="image floutée chart line" />
        </div>
      </div>
    </div>
    <!-- FIN EVOLUTION POPULATION -->
    <div class="px-8 mt-10 card card-p">
      <div class="flex flex-row align-middle card-header">
        <img src="@/assets/icons/etude_marche_1.jpg" class="h-20" alt="Présentation" />
        <div class="flex flex-col my-auto ml-5">
          <p class="text-xl font-bold ">Marché immobilier et locatif de la ville</p>
        </div>

      </div>
      <div v-if="
        projectSelected.carac.averagePrice.basMaison &&
        projectSelected.carac.surface
      " class="col-start-3 col-span-9">
        <div class="relative px-6 py-4 mb-8 border-2 rounded-lg">
          <img class="absolute z-0 w-auto max-h-10 ampoule-picto" src="@/assets/icons/inspector.png" alt="" />
          <div class="flex flex-row justify-between">
            <div class="flex flex-col w-4/5">
              <p class="pt-4 font-bold text-md">
                Prix d’achat moyen à
                {{
                    projectSelected.loc.carac != undefined ? projectSelected.loc.carac.city : ''
                }}
              </p>
              <p class="mt-4 text-sm">pour {{ projectSelected.carac.surface }} m2</p>
            </div>
            <div class="flex flex-col">
              <img style="max-height: 50px" src="@/assets/progressbar.png" alt="" />
              <p class="mt-4 text-right">
                de
                <span class="font-bold">{{
                    (
                      priceBas * projectSelected.carac.surface
                    ).toLocaleString('fr-FR', {
                      minimumFractionDigits: 0,
                    })
                }}
                  €</span>
                à
                <span class="font-bold">{{
                    (
                      priceHaut *
                      projectSelected.carac.surface
                    ).toLocaleString('fr-FR', {
                      minimumFractionDigits: 0,
                    })
                }}
                  €</span>
              </p>
            </div>
          </div>
          <div class="flex flex-row justify-between mt-1 text-sm italic">
            <p>soit un prix au m2</p>
            <p>
              de
              {{
                  priceBas.toLocaleString('fr-FR', {
                    minimumFractionDigits: 0,
                  })
              }}
              € à
              {{
                  priceHaut.toLocaleString('fr-FR', {
                    minimumFractionDigits: 0,
                  })
              }}
              €
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-0 sm:col-start-2 sm:col-end-12">
        <div class="text-sm col-start-3 col-span-8">
          <div class="relative px-6 py-4 my-10 border-2 rounded-lg">
            <div class="flex flex-row justify-between">
              <div class="flex flex-col">
                <p class="font-bold">
                  Loyer moyen pour {{ projectSelected.carac.surface }}m2
                </p>
                <p v-if="projectSelected.loc.carac">
                  à
                  {{ projectSelected.loc.carac.city }}
                </p>
              </div>
              <div class="flex flex-col">
                <img style="max-height: 50px" src="@/assets/progressbar.png" alt="" />
              </div>
            </div>
            <div class="flex flex-row justify-between mt-5">
              <p>En location nue</p>
              <p>
                de
                <span class="font-bold">{{
                    this.loyerMoyen.max == 0
                      ? 'Inconnu'
                      : projectSelected.carac.surface < 50 ? Math.round(this.loyerMoyen.max * 0.95) + ' €' :
                        Math.round(this.loyerMoyen.max * 0.7) + ' €'
                }}</span>
                    à
                    <span class="font-bold">{{
                        this.loyerMoyen.max == 0
                          ? 'Inconnu'
                          : projectSelected.carac.surface < 50 ? Math.round(this.loyerMoyen.max * 1.05) + ' €' :
                            Math.round(this.loyerMoyen.max * 0.8) + ' €'
                    }}</span>
              </p>
            </div>
            <div class="flex flex-row justify-between mt-2">
              <p>En location meublée</p>
              <p>
                de
                <span class="font-bold">{{
                    this.loyerMoyen.max == 0
                      ? 'Inconnu'
                      : projectSelected.carac.surface < 50 ? Math.round(this.loyerMoyen.max * 1.15) + ' €' :
                        Math.round(this.loyerMoyen.max * 0.8) + ' €'
                }}</span>
                    à
                    <span class="font-bold">{{
                        this.loyerMoyen.max == 0
                          ? 'Inconnu'
                          : projectSelected.carac.surface < 50 ? Math.round(this.loyerMoyen.max * 1.2) + ' €' :
                            Math.round(this.loyerMoyen.max * 0.85) + ' €'
                    }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-start-4 col-span-7" v-if="projectSelected.carac.evolution.unAn">
        <div class="relative flex flex-row items-center justify-between px-4 py-4 border-2 rounded-lg">
          <p class="font-bold">{{ 'Evolution des prix immobilliers sur ' + projectSelected.loc.carac.city }}</p>
          <div class="flex flex-col">
            <div class="flex flex-row justify-end mt-2 text-sm gap-2">
              <div class="flex flex-row">
                <p class="my-auto mr-1">
                  Sur 12 mois glissé
                </p>
              </div>
              <img v-if="projectSelected.carac.evolution.unAn < 0" src="@/assets/icons/regress.png" class="my-auto"
                style="height: 25px" alt="" />
              <img v-else src="@/assets/icons/progress.png" class="my-auto" style="height: 25px" alt="" />
              <div class="flex flex-row">
                <p class="my-auto font-bold" :class="{
                  'text-red-600': projectSelected.carac.evolution.unAn < 0,
                  'text-green-600': projectSelected.carac.evolution.unAn >= 0,
                }">
                  {{
                      projectSelected.carac.evolution.unAn >= 0
                        ? '+' + projectSelected.carac.evolution.unAn
                        : projectSelected.carac.evolution.unAn
                  }}
                  %
                </p>
              </div>
            </div>
            <div class="flex flex-row justify-end mt-2 text-sm gap-2">
              <div class="flex flex-row">
                <p class="my-auto mr-1">
                  Sur les 5 dernières années
                </p>
              </div>
              <img v-if="projectSelected.carac.evolution.cinqAns < 0" src="@/assets/icons/conseil.png" class="my-auto"
                style="height: 25px" />
              <img v-else src="@/assets/icons/progress.png" class="my-auto" style="height: 25px" />
              <div class="flex flex-row">
                <p class="my-auto font-bold" :class="{
                  'text-red-600': projectSelected.carac.evolution.cinqAns < 0,
                  'text-green-600': projectSelected.carac.evolution.cinqAns >= 0,
                }">
                  {{
    projectSelected.carac.evolution.cinqAns >= 0
      ? '+' + projectSelected.carac.evolution.cinqAns
      : projectSelected.carac.evolution.cinqAns
                  }}
                  %
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionMark from "@/components/SVG/QuestionMark";
import InfoLine from "@/components/result/InfoLine";
import Map from "@/components/Map/Map.vue";
import MapPolygon from "@/components/Map/MapPolygon.vue";
import BarChart from "@/components/Charts/BarChart.vue";
import LineChart from "@/components/Charts/LineChart.vue";
import PieChart from "@/components/Charts/PieChart.vue";
import { mapGetters } from "vuex";
import populationEvolution from '@/assets/cityData/populationEvolution.json'

export default {
  name: "EtudeMarche",
  components: {
    QuestionMark,
    InfoLine,
    Map,
    MapPolygon,
    PieChart,
    LineChart,
    BarChart
  },
  props: {
    cashFlowComputed: {
      type: Array
    },
    chartInsee: {
      type: Object
    },
    apport: {
      type: Number
    },
    chartDatas: {
      type: Array
    },
    salePrice: {
      type: Object
    },
    showLinear: {
      type: Boolean
    },
    dvf: {
      type: Array
    },
    indexListDVF: {
      type: Number || null
    },
    messageStrategy: {
      type: Array
    },
    strategyIndex: {
      type: Number || null
    },
    chartLabels: {
      type: Array
    },
  },
  mounted() {
    this.center = [this.projectSelected.loc.carac.coordinates[1], this.projectSelected.loc.carac.coordinates[0]];
  },
  created() {
  },
  computed: {
    ...mapGetters({
      projectSelected: 'projects/selected',
      investSelected: 'invests/selected',
      user: "users/user",
    }),
    loyerMoyen() {
      return this.projectSelected.carac.loyermoy
    },
    getEvolutionPopulation() {
      let calcul = 0
      if (this.populations) {
        calcul = ((this.populations.PMUN18 - this.populations.PSDC99) / this.populations.PSDC99 * 100).toFixed(2)
      }
      return calcul
    },
    getDataPopulation() {
      this.populations = Object.values(populationEvolution).find(data => data.CODGEO == this.projectSelected.loc.carac.citycode)
      if (this.populations) {
        this.chartDatasPopulation.push(this.populations.PSDC99,
          this.populations.PMUN06,
          this.populations.PMUN07,
          this.populations.PMUN08,
          this.populations.PMUN09,
          this.populations.PMUN10,
          this.populations.PMUN11,
          this.populations.PMUN12,
          this.populations.PMUN13,
          this.populations.PMUN14,
          this.populations.PMUN15,
          this.populations.PMUN16,
          this.populations.PMUN17,
          this.populations.PMUN18)
      }
      return this.chartDatasPopulation
    },
    titleCategBien: {
      get: function () {
        let categName = "";
        if (this.projectSelected.carac.categ == "appartement") {
          categName = "Appartements";
        } else if (this.projectSelected.carac.categ == "maison_individuelle") {
          categName = "Maisons";
        } else if (this.projectSelected.carac.categ == "immeuble_residentiel") {
          categName = "Appartements";
        } else if (this.projectSelected.carac.categ == "immeuble_de_rapport") {
          categName = "Appartements";
        }

        return categName;
      },
      set: function (val) { },
    },
    showPositif: {
      get: function () {
        return true;
      },
      set: function (val) {
      }
    },
    showNegatif: {
      get: function () {
        return true;
      },
      set: function (val) {
      }
    },
    priceBas: {
      get: function () {
        if (this.projectSelected.carac.categ == 'appartement') {
          return this.projectSelected.carac.averagePrice.basAppartement;
        } else {
          return this.projectSelected.carac.averagePrice.basMaison
        }
      }
    },
    priceHaut: {
      get: function () {
        if (this.projectSelected.carac.categ == 'appartement') {
          return this.projectSelected.carac.averagePrice.hautAppartement;
        } else {
          return this.projectSelected.carac.averagePrice.hautMaison
        }
      }
    },
    showConseil: {
      get: function () {
        return true;
      },
      set: function (val) {
      }
    },
    isPaid: {
      get: function () {
        return this.user.sub_level != 0
      },
      set: function (val) { },
    },
    sameAdresse: {
      get: function () {
        let arrayAdresse = [];
        arrayAdresse = this.dvf.filter((v, i, a) => {
          return (
            this.dvf.findIndex(
              (t) => t.properties.lat === v.properties.lat && t.properties.lon === v.properties.lon
            ) === i
          );
        });

        arrayAdresse.map((value, index) => {
          arrayAdresse[index].sameAdresse = this.dvf.filter((data) => {
            return value.properties.lon == data.properties.lon && value.properties.lat == data.properties.lat;
          });
        });

        return arrayAdresse;
      },
      set: function (val) { },
    },
  },
  data: function () {
    return {
      center: [],
      inseeYear: "2017",
      chartLabelsPopulation: ["1999", "2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018"],
      chartDatasPopulation: [],
      show : false,
      populations: {}
    }
  },
  methods: {
    lineOptions(isAxes) {
      let options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
              },
              gridLines: {
                display: true,
              },
              display: true,
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
              },
              gridLines: {
                display: false,
              },
              display: true,
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: false,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      };
      return options;
    },
    lineChartData(labels, label, data, type, isDark) {
      // let darkBlue = '#0C1E3B'
      // let lightBlue = '#14A0EB'
      // let red = '#D45B40'
      // let yellow = '#ECCA51'
      let dataLine = []
      for (let i = 0; i < data.length; i++) {
        dataLine.push(data[i])
        // datasets.push({
        //   label: label[i],
        //   data: data[i],
        //   fill: true,
        //   fillColor: "rgba(20, 160, 235, 1)",
        //   tension: 0,
        //   order: i + 1,
        //   pointRadius: 2,
        //   pointHitRadius: 30,
        //   lineTension: 0.3,
        //   backgroundColor: ["rgba(20, 160, 235, 0.2)"],
        //   borderColor: ["rgba(20, 160, 235, 1)"],
        //   borderWidth: 3,
        // });
      }

      let datasets = [{
        label: "Population",
        backgroundColor: ["rgba(20, 160, 235, 0.2)"],
        data: dataLine
      }];

      let chartdata = {
        labels: labels,
        datasets: datasets,
      };

      this.show = true
      return chartdata;
    },
    barPieOptions(isAxes) {
      let options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
              display: false,
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              display: false,
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        pieceLabel: {
          render: function (d) {
            return d.label + " (" + d.percentage + "%)";
          },
          fontColor: "#000",
          position: "outside",
          segment: true,
        },
        tooltips: {
          enabled: true,
        },
        aspectRatio: 1,
      };

      return options;
    },
    pieChartdata(labels, label, data) {
      let datasets = [];

      datasets.push({
        data: data,
        label: "Bar Chart",
        borderWidth: 1,
        backgroundColor: ["rgba(12, 30, 59, 1)", "rgba(20, 160, 235, 1)", "rgba(235, 123, 20, 1)"],
        borderColor: ["rgba(12, 30, 59, 1)", "rgba(20, 160, 235, 1)", "rgba(235, 123, 20, 1)"],
        pointBorderColor: "#2554FF",
      });

      let chartdata = {
        labels: labels,
        datasets: datasets,
      };
      return chartdata;
    },
    setEmitCenter(center) {
      this.center = [center[0], center[1]];
    },
    setZoomDVF(value) {
      // console.log("======> zoom value1: ", this.zoomValue)
      // this.zoomValue = this.zoomValue + value
      // console.log("======> zoom value2: ", this.zoomValue)
      // // return
      // axios
      // .get('/apidvfmap', {
      // 	params: {
      // 		dist: this.zoomValue + value,
      // 	}
      // })
      // .then(response => {
      // 	this.dvf = response.data.features
      // 	// this.center = response.data.features[0]
      // 	// console.log(this.center)
      // })
      // .catch(error => {throw error})
    },
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 40px;
}

.card {
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  &-header {
    &-img {
      width: 259px;
      height: 215px;
    }
  }

  &-line {
    margin-top: 30px;
  }

  &-p {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.blue {
  color: #14A0EB;
}

.red {
  color: #EB7B14;
}

.preicon {
  max-height: 80px;
}

.describe {
  padding-left: 10px;
  font-size: 18px;
}

.li-box {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  cursor: pointer;
  background-color: #ffffff;
  padding: 10px 0px 10px 0px;
  margin-bottom: 0.1rem;
}

.li-box:hover {
  transform: scale(1.03);
}

.tips {
  background: #c7caff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 37px;
  font-size: 0.85rem
    /* 13px */
  ;
  font-weight: 400;
}

.ampoule-picto {
  top: -1rem;
  left: -1rem;
}

.labels{
  width: 23rem;
}
</style>
