<template>
	<div class="grid grid-cols-12 gap-1 flex justify-start items-start">
		<div class="sm:pl-10 col-span-12 sm:col-start-1 sm:col-end-5">
			<input class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
			v-model="search"
			type="text" name="" id="" placeholder="Recherchez le nom de l'investisseur">
			<div class="w-full rounded-md bg-white shadow-lg mt-4">
				<ul
				class="max-h-96 text-left rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
					<li v-for="item in resultQuery" :key="item.name"
					class="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-lightblueaestima-100 hover:text-white"
					@click="filterProject(item.idProject)">{{item.name}}</li>
				</ul>
			</div>
			
		</div>
		<div class="col-span-12 sm:col-start-6 sm:col-end-12">
			<ul
			class="max-h-56 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
				<li v-for="item in projectSearch" :key="item.name"
				@click="pushToProject(item)"
				class="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-lightblueaestima-100 hover:text-white flex justify-between items-center"
				>
					<div class="flex flex-row justify-center items-center">
						<img :src="`${STATIC_URL}/` + item.img.data" :alt="item.name" class="flex-shrink-0 h-auto w-28 rounded-full">
						<span class="block truncate text-2xl font-semibold ml-6">
							{{item.name}}
						</span>
					</div>
					<span class="text-lg font-semibold">Voir le projet</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
	name: 'SearchInvestor',
	data() {
			return {
				STATIC_URL: process.env.VUE_APP_API_HOSTNAME_DEV,
				search: null,
				arraySearch: [],
				projectSearch: []
			}
		},
	props: {
	},
	metaInfo(){
		return {
			title: `Recherche par investisseur - Aestima`,
			meta: [{
				name: 'description',
				content: "Cette page permet de rechercher un investisseur pour trouver ses biens."
			}]
			//keywords
		}
	},
	components: {
	},
	created: function () {
		this.getInvestsList(this.user._id)
		.then(() => {
			this.arraySearch = this.invests
		})
		.catch(err => {
		})
  	},
	computed: {
		...mapGetters({
			authenticated: 'users/authenticated',
			user: 'users/user',
			listProj: 'projects/projects',
			invests: 'invests/investsList'
		}),
		resultQuery(){
			if(this.search){
				return this.invests.filter((item)=>{
					return this.search.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
				})
			}else{
				return this.invests;
			}
		},
	},
	methods: {
		...mapActions({
			signOut: 'users/logout',
			getProjects: 'projects/getProjects',
			setProjectSelected: 'projects/setProjectSelected',
			updateUser: 'users/updateUser',
			updateUserMDP: 'users/updateUserMDP',
			setProjectByInvestor: 'projects/setProjectByInvestor',
			getInvestsList: 'invests/getInvestsList',
		}),
		filterProject(idProject){
			this.projectSearch = []
			this.listProj.map(val => {
				if(val._id == idProject) {this.projectSearch.push(val)}
			})
		},
		pushToProject(project) {
			this.setProjectByInvestor(project)
			.then(() => {
				this.$router.replace({
					name: 'Simulator'
				})
			})
		}
	},
}
</script>

<style scoped>
.shadow-inner {
	box-shadow: 0 15px 35px 0 rgba(60,66,87,.08), 0 5px 15px 0 rgba(0,0,0,.12);
}
</style>