<template>
	<div class="w-full grid grid-cols-12 gap-0">
		<div class="fc md:text-right col-span-12 sm:col-start-1 sm:col-end-5 sm:ml-10 bg-white shadow overflow-hidden sm:rounded-lg h-auto">
			<div class="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-center items-center">
				<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				width="38" height="38"
				viewBox="0 0 172 172"
				style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#14a0eb"><path d="M102.125,10.75c-8.89025,0 -16.125,7.23475 -16.125,16.125v3.58333h17.91667c8.9655,0 16.75108,5.09908 20.64616,12.54167h13.39551c2.967,0 5.375,2.408 5.375,5.375c0,2.967 -2.408,5.375 -5.375,5.375h-10.75v10.75h10.75c2.967,0 5.375,2.408 5.375,5.375c0,2.967 -2.408,5.375 -5.375,5.375h-10.75v32.25h17.91667c8.89025,0 16.125,-7.23475 16.125,-16.125v-64.5c0,-8.89025 -7.23475,-16.125 -16.125,-16.125zM60.91667,37.625c-8.89025,0 -16.125,7.23475 -16.125,16.125v3.58333h10.75v-3.58333c0,-2.96342 2.41158,-5.375 5.375,-5.375h43c2.96342,0 5.375,2.41158 5.375,5.375v64.5c0,2.96342 -2.41158,5.375 -5.375,5.375h-10.75v10.75h10.75c8.89025,0 16.125,-7.23475 16.125,-16.125v-64.5c0,-8.89025 -7.23475,-16.125 -16.125,-16.125zM26.875,64.5c-8.89025,0 -16.125,7.23475 -16.125,16.125v64.5c0,8.89025 7.23475,16.125 16.125,16.125h43c8.89025,0 16.125,-7.23475 16.125,-16.125v-64.5c0,-8.89025 -7.23475,-16.125 -16.125,-16.125zM34.04167,96.75h28.66667c2.967,0 5.375,2.408 5.375,5.375c0,2.967 -2.408,5.375 -5.375,5.375h-28.66667c-2.967,0 -5.375,-2.408 -5.375,-5.375c0,-2.967 2.408,-5.375 5.375,-5.375zM34.04167,118.25h28.66667c2.967,0 5.375,2.408 5.375,5.375c0,2.967 -2.408,5.375 -5.375,5.375h-28.66667c-2.967,0 -5.375,-2.408 -5.375,-5.375c0,-2.967 2.408,-5.375 5.375,-5.375z"></path></g></g></svg>
				<h1 class="text-xl font-semibold ml-4">Fiches Techniques</h1>
			</div>
			<ul class="text-left">
				<li @click="setFiche('AP')" class="bg-gray-50 px-4 py-5 sm:px-4 cursor-pointer hover:bg-lightblueaestima-100 hover:text-white flex justify-start items-center">
					<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
					width="20" height="20"
					viewBox="0 0 172 172"
					style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#0E1E3A"><path d="M104.533,153.467l34.60067,-34.60067c2.6875,-2.6875 4.19967,-6.33533 4.19967,-10.13367v-80.066c0,-7.88333 -6.45,-14.33333 -14.33333,-14.33333h-86c-7.88333,0 -14.33333,6.45 -14.33333,14.33333v114.66667c0,7.88333 6.45,14.33333 14.33333,14.33333h51.39933c3.79833,0 7.44617,-1.51217 10.13367,-4.19967zM93.16667,146.91667v-39.41667h39.41667z"></path></g></g></svg>
					<h2 class="ml-4">Choisir la bonne assurance de prêt</h2>
				</li>
				<li @click="setFiche('GB')" class="bg-gray-50 px-4 py-5 sm:px-4 cursor-pointer hover:bg-lightblueaestima-100 hover:text-white flex justify-start items-center">
					<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
					width="20" height="20"
					viewBox="0 0 172 172"
					style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#0E1E3A"><path d="M104.533,153.467l34.60067,-34.60067c2.6875,-2.6875 4.19967,-6.33533 4.19967,-10.13367v-80.066c0,-7.88333 -6.45,-14.33333 -14.33333,-14.33333h-86c-7.88333,0 -14.33333,6.45 -14.33333,14.33333v114.66667c0,7.88333 6.45,14.33333 14.33333,14.33333h51.39933c3.79833,0 7.44617,-1.51217 10.13367,-4.19967zM93.16667,146.91667v-39.41667h39.41667z"></path></g></g></svg>
					<h2 class="ml-4">Garanties bancaires, laquelle choisir ?</h2>
				</li>
				<li @click="setFiche('FD')" class="bg-gray-50 px-4 py-5 sm:px-4 cursor-pointer hover:bg-lightblueaestima-100 hover:text-white flex justify-start items-center">
					<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
					width="20" height="20"
					viewBox="0 0 172 172"
					style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#0E1E3A"><path d="M104.533,153.467l34.60067,-34.60067c2.6875,-2.6875 4.19967,-6.33533 4.19967,-10.13367v-80.066c0,-7.88333 -6.45,-14.33333 -14.33333,-14.33333h-86c-7.88333,0 -14.33333,6.45 -14.33333,14.33333v114.66667c0,7.88333 6.45,14.33333 14.33333,14.33333h51.39933c3.79833,0 7.44617,-1.51217 10.13367,-4.19967zM93.16667,146.91667v-39.41667h39.41667z"></path></g></g></svg>
					<h2 class="ml-4">Fiche deficit foncier</h2>
				</li>
				<li @click="setFiche('FM')" class="bg-gray-50 px-4 py-5 sm:px-4 cursor-pointer hover:bg-lightblueaestima-100 hover:text-white flex justify-start items-center">
					<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
					width="20" height="20"
					viewBox="0 0 172 172"
					style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#0E1E3A"><path d="M104.533,153.467l34.60067,-34.60067c2.6875,-2.6875 4.19967,-6.33533 4.19967,-10.13367v-80.066c0,-7.88333 -6.45,-14.33333 -14.33333,-14.33333h-86c-7.88333,0 -14.33333,6.45 -14.33333,14.33333v114.66667c0,7.88333 6.45,14.33333 14.33333,14.33333h51.39933c3.79833,0 7.44617,-1.51217 10.13367,-4.19967zM93.16667,146.91667v-39.41667h39.41667z"></path></g></g></svg>
					<h2 class="ml-4">Focus sur le montage en SCI</h2>
				</li>
			</ul>
		</div>
		<div class="md:text-right col-span-12 sm:col-start-5 sm:col-end-13 sm:ml-10">
			<img v-if="isFiche == 'AP'" src="../../assets/tech/AP.jpg" alt="Choisir la bonne assurance de prêt">
			<img v-if="isFiche == 'GB'" src="../../assets/tech/GB.jpg" alt="EN SAVOIR PLUS SUR LES GARANTIES BANCAIRES D’EMPRUNT">
			<img v-if="isFiche == 'FD'" src="../../assets/tech/FD.jpg" alt="FICHE DEFICIT FONCIER">
			<img v-if="isFiche == 'FM'" src="../../assets/tech/FM1.jpg" alt="FOCUS SUR LE MONTAGE EN SCI">
			<img v-if="isFiche == 'FM'" src="../../assets/tech/FM2.png" alt="FOCUS SUR LE MONTAGE EN SCI">
			
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
	name: 'FicheTech',
	data() {
			return {
				isFiche: null,
			}
		},
	props: {
	},
	metaInfo(){
		return {
			title: `Mes Fiches Techniques - Aestima`,
		}
	},
	components: {
	},
	created: function () {

  	},
	computed: {
		...mapGetters({
			authenticated: 'users/authenticated',
			user: 'users/user',
			listProj: 'projects/projects',
			user: 'users/user'
		}),
	},
	methods: {
		...mapActions({
			signOut: 'users/logout',
			getProjects: 'projects/getProjects',
			setProjectSelected: 'projects/setProjectSelected',
			updateUser: 'users/updateUser',
			updateUserMDP: 'users/updateUserMDP',
		}),
		setFiche(fiche) {
			this.isFiche = fiche
		}

	},
}
</script>

<style scoped>
img {
    pointer-events: none;
}
li svg {
	height: auto;
	width: 1rem;
}
.fc {
	height: fit-content;
}
.shadow-inner {
	box-shadow: 0 15px 35px 0 rgba(60,66,87,.08), 0 5px 15px 0 rgba(0,0,0,.12);
}
</style>