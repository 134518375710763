<template>
	<div>
		<Header/>
		<div class="flex flex-col justify-center items-center py-10">
			<img class="w-4/4 sm:w-2/4 h-auto" src="@/assets/picto/aestima-404.png" alt="">
			<router-link to="/" class="text-md sm:text-2xl md:text-xl mx-10 sm:mx-0 font-bold text-white px-6 py-4 bg-aestiblue-light rounded-full aestima-button sm:mt-0 sm:mb-3"><p>Retour vers accueil</p></router-link>
		</div>
		<Footer/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'

export default {
	name: 'NotFound',
	data() {
			return {

			}
		},
	props: {
	},
	components: {
		Header,
		Footer
	},
	metaInfo(){
		return {
			title: `Page non trouvée - 404`,
			meta: [{
				name: 'description',
				content: "Cette page est la page erreur 404."
			}]
			//keywords
		}
	},
	created: function () {
		
  	},
	computed: {
		...mapGetters({
		}),
	},
	methods: {
		...mapActions({
		}),
	
	},
}
</script>

<style scoped>
.shadow-inner {
	box-shadow: 0 15px 35px 0 rgba(60,66,87,.08), 0 5px 15px 0 rgba(0,0,0,.12);
}

.box-billing {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 32px;
}

.box-border {
	border-radius: 4px;
    border: 1px solid rgb(49, 52, 62);
}

.box-bt {
	border-top: 1px solid rgb(49, 52, 62);
}

.mt-popup {
	margin-top: -65px;
}

.aestima-button {
	transition: 0.5s;
	background-color: #14A0EB;
}
/* 
video {
	position: relative;
	width: 100% !important;
	height: auto;
} */

.aestima-button:hover {
	background-color: #ffffff;
	border: 2px solid #14A0EB;
	color: #14A0EB;
	transform: scale(1);
}
</style>