<template>
	<div
		id="defaultModal"
		tabindex="-1"
		aria-hidden="true"
		class="content-text ml-2 hidden  md:w-56 lg:w-64 fixed top-[2px] left-0  bg-white text-black md:grid grid-cols-1 gap-6 pr-6 py-5 pl-2 rounded-lg shadow dark:bg-gray-700"
	>
		<div>
<div class="prop"><a href="javascript:document.getElementById('infos').scrollIntoView(true);"><img class="pictobg" src="../assets/picto/sidebar/CoordonneesB.png" alt=""><img class="picto" src="../assets/picto/sidebar/CoordonneesN.png" alt=""><a class="relative left-12">Coordonnées du client</a></a></div> <!-- ces <a> sont comme ca car sinon ils ne marchemt pas sur firefox -->
<div class="prop"><a href="javascript:document.getElementById('stratégie').scrollIntoView(true);"><img class="pictobg" src="../assets/picto/sidebar/StrategieB.png" alt=""><img class="picto" src="../assets/picto/sidebar/StrategieN.png" alt=""><a class="relative left-12">Stratégie d'investissement</a></a></div>
<div class="prop"><a href="javascript:document.getElementById('situation').scrollIntoView(true);"><img class="pictobg" src="../assets/picto/sidebar/SituationFiscaleB.png" alt=""><img class="picto" src="../assets/picto/sidebar/SituationFiscaleN.png" alt=""><a class="relative left-12">Situation fiscale</a></a></div>
<div class="prop"><a href="javascript:document.getElementById('critères').scrollIntoView(true);"><img class="pictobg" src="../assets/picto/sidebar/CriteresB.png" alt=""><img class="picto" src="../assets/picto/sidebar/CriteresN.png" alt=""><a class="relative left-12"">Critères de recherche</a></a></div>
<div class="prop"><a href="javascript:document.getElementById('solvabilité').scrollIntoView(true);"><img class="pictobg" src="../assets/picto/sidebar/SolvabiliteB.png" alt=""><img class="picto" src="../assets/picto/sidebar/SolvabiliteN.png" alt=""><a class="relative left-12">Etude de solvabilité</a></a></div>
<hr class="ml-2">
<div class="mt-4 ml-2"><button class="flex">Enregistrer le profil du client</button></div>
	</div>

	</div>
</template>

<script>
export default {
	name: 'SidebarInvestor',
	props: {},
	methods: {
		close() {},
	},
	data() {
		return {

		};
	},
	// computed: {
	// 	currentPage() {
    //   return this.$route.name
    // },
	//}

};
</script>

<style lang="scss" scoped>

.title {
	background: #000680;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	position: fixed;
	top: 200px;
	left: 15px;
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
}
.content-text {
	font-size: 13px;
	line-height: 40px;

}

.picto {
	position:fixed;
	left: 20px;
	width: 34px;
	height: 34px;
	margin-right: 12px;
	opacity: 1;
}
.pictobg {
	position:fixed;
	left: 20px;
	width: 34px;
	height: 34px;
	margin-right: 8px;
	opacity: 1;

}

.prop{
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;
}

.prop:hover {
  color: rgb(255, 255, 255);
  --bg-opacity: 1;
  background-color: #0e1e3a;
  background-color: rgba(14, 30, 58, var(--bg-opacity));
}

.prop:hover .picto{
	background-color: aqua;
	opacity: 0;

}




button {
	background-color: rgb(218, 218, 218);
	border: transparent;
	border-radius: 0.5rem;
	padding: 6%;
	margin-top: 10px;
	line-height : 1
}
button:hover {
	background-color: #14A0EB;
	color: #fff;
}

</style>
