<template>
  <div>
    <div class="h-16 bg-darkblueaestima-500 flex justify-between px-6 relative z-50">
      <div class="flex gap-10 place-items-center">
        <router-link to="/" class="cursor-pointer flex place-items-center gap-2">
          <img src="@/assets/logo_aestima.png" class="h-12" alt="logo">
        </router-link>
      </div>
      <div v-click-outside="dd_out" class="relative cursor-pointer flex place-items-center"
        @click="dd_profile = !dd_profile">
        <div>
          <img :src="user.customLogo || default_avatar" class="h-12">
          <div v-if="dd_profile" class="absolute right-0 w-48 mt-2 shadow-lg origin-top-right rounded-md posi">
            <div class="py-1 text-left bg-white rounded-md shadow-xs" role="menu" aria-orientation="vertical"
              aria-labelledby="user-menu">
              <router-link to="/Profil"
                class="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                role="menuitem">
                Mon Profil et Abonnement
              </router-link>
              <router-link to="/FAQTechnique"
                class="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                role="menuitem">
                Tutoriels et FAQ
              </router-link>
              <router-link to="/Lexique"
                class="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                role="menuitem">
                Lexique
              </router-link>
              <a href="https://airtable.com/appjmzPBlPaLpsMtP/shrMGcApWTiXKgh51" target="_blank"
                class="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                role="menuitem">
                Envoyer un ticket
              </a>
              <router-link v-if="user.type === 'admin'" to="/Admin"
                class="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                role="menuitem">
                Espace Admin
              </router-link>
              <a href="#"
                class="block px-4 py-2 text-sm text-gray-700 leading-5 hover:bg-red-400 hover:text-white focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                role="menuitem" @click="logout">Se déconnecter</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-link v-if="user.sub_level === 0" :to="{ name: 'Profile', hash: '#subscribe' }">
      <PMessage severity="warn" :closable="false" class="mx-4">
        Mode limité : Vous n'êtes plus abonné à Aestima, vous avez donc un accès limité aux fonctionnalités. Cliquez ici pour mettre à jour votre abonnement.
      </PMessage>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppHeader',
  directives: {
    'click-outside': {
      bind(el, binding) {
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) binding.value(e)
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
      },
      unbind(el) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
      },
    },
  },
  data() {
    return {
      dd_profile: false,
      default_avatar: 'https://api.iconify.design/mdi:account-circle.svg?color=%23ffffff',
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
      user: 'users/user',
    }),
  },
  methods: {
    dd_out() {
      this.dd_profile = false
    },
    logout() {
      this.$store.dispatch('users/logout')
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>

<style scoped>
  ::v-deep .p-message {
    @apply ml-3 bg-red-200 border-red-600
  }
  ::v-deep .p-message .p-message-icon {
    @apply text-red-600
  }

  ::v-deep .p-message .p-message-text {
    @apply text-red-600
  }

  ::v-deep .p-message .p-message-warn {
    @apply text-red-600 bg-red-600
  }

</style>