<template>
  <div class="mt-6 text-left">
    <div class="overflow-hidden bg-white shadow sm:rounded-lg">
		<div class="px-4 py-5 border-b border-gray-200 sm:px-6">
			<h2 v-if="Object.keys(this.selected).length > 0" class="text-lg font-medium text-gray-900 leading-6">
			{{selected.name}} {{selected.firstname === undefined ? "" : selected.firstname}}
			</h2>
			<h2 v-else-if="invests.length > 0" class="text-lg font-medium text-gray-900 leading-6">
			{{invests[0].name}} {{invests[0].firstname === undefined ? "" : invests[0].firstname}}
			</h2>
			<h2 v-else class="text-lg font-medium text-gray-900 leading-6">

			</h2>
		</div>
		<div>
			<dl>
			<div class="px-4 py-5 bg-gray-50 grid grid-cols-6 gap-1 sm:px-6">
				<dt class="text-sm font-medium text-gray-500 leading-5 col-span-3 sm:col-span-5">
				TMI :
				</dt>
				<dd v-if="Object.keys(this.selected).length > 0" class="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-1">
				{{selected.tmi}}%
				</dd>
				<dd v-else-if="invests.length > 0" class="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-1">
				{{invests[0].tmi}}%
				</dd>
				<dd v-else class="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-1">

				</dd>
			</div>
			<div class="px-4 py-5 bg-gray-50 grid grid-cols-6 gap-1 sm:px-6">
				<dt class="text-sm font-medium text-gray-500 leading-5 col-span-3 sm:col-span-4">
				Budget total :
				</dt>
				<dd v-if="Object.keys(this.selected).length > 0" class="mt-1 text-sm text-right text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-2">
				{{selected.search.search_budget}}€
				</dd>
				<dd v-else-if="invests.length > 0" class="mt-1 text-sm text-right text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-2">
				{{invests[0].search.search_budget}}€
				</dd>
				<dd v-else class="mt-1 text-sm text-right text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-2">
				</dd>
			</div>
			<!-- <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
				<dt class="text-sm font-medium text-gray-500 leading-5">
				Montant des travaux souhaité :
				</dt>
				<dd class="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
				{{selected.carac.coutacquisition}} €
				</dd>
			</div> -->
			<div class="px-4 py-5 bg-gray-50 grid grid-cols-6 gap-1 sm:px-6">
				<dt class="text-sm font-medium text-gray-500 leading-5 col-span-3 sm:col-span-4">
				Zone de recherche :
				</dt>
				<dd v-if="Object.keys(this.selected).length > 0" class="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-2">
				{{selected.search.search_pref}}
				</dd>
				<dd v-else-if="invests.length > 0" class="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-2">
				{{invests[0].search.search_pref}}
				</dd>
				<dd v-else class="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-2">
				</dd>
			</div>
			<div class="px-4 py-5 bg-gray-50 grid grid-cols-6 gap-1 sm:px-6">
				<dt class="text-sm font-medium text-gray-500 leading-5 col-span-3 sm:col-span-5">
				Objectif de rentabilité :
				</dt>
				<dd v-if="Object.keys(this.selected).length > 0" class="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-1">
				{{selected.params.params_renta}}%
				</dd>
				<dd v-else-if="invests.length > 0" class="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-1">
				{{invests[0].params.params_renta}}%
				</dd>
				<dd v-else class="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 col-span-3 sm:col-span-1">
				</dd>
			</div>
			</dl>
		</div>
	</div>
	<div v-if="selected.mail || selected.phone">
		<h3 class="my-4 font-bold text-center text-gray-900 text-normal leading-6">Coordonnées de l'investisseur</h3>
		<div class="flex flex-col mb-4 gap-2">
			<div v-if="selected.mail" class="flex flex-row items-center justify-start">
				<div class="px-3">
					<img class="w-auto h-6" src="../assets/picto/aestima-email.png" alt="icon mail">
				</div>
				<p v-if="Object.keys(this.selected).length > 0" class="ml-4">{{selected.mail}}</p>
				<p v-else-if="invests.length > 0" class="ml-4">{{invests[0].mail}}</p>
			</div>
            <div v-if="selected.coEmail" class="flex flex-row items-center justify-start">
                <div class="px-3">
                    <img class="w-auto h-6" src="../assets/picto/aestima-email.png" alt="icon mail">
                </div>
                <p v-if="Object.keys(this.selected).length > 0" class="ml-4">{{selected.coEmail}}</p>
                <p v-else-if="invests.length > 0" class="ml-4">{{invests[0].coEmail}}</p>
            </div>
			<div v-if="selected.phone" class="flex flex-row items-center justify-start">
				<div class="px-3">
					<img class="w-auto h-6" src="../assets/picto/aestima-phone.png" alt="icon mail">
				</div>
				<p v-if="Object.keys(this.selected).length > 0" class="ml-4">{{selected.phone}}</p>
				<p v-else-if="invests.length > 0" class="ml-4">{{invests[0].phone}}</p>
			</div>
            <div v-if="selected.coPhone" class="flex flex-row items-center justify-start">
                <div class="px-3">
                    <img class="w-auto h-6" src="../assets/picto/aestima-phone.png" alt="icon mail">
                </div>
                <p v-if="Object.keys(this.selected).length > 0" class="ml-4">{{selected.coPhone}}</p>
                <p v-else-if="invests.length > 0" class="ml-4">{{invests[0].coPhone}}</p>
            </div>
		</div>
		<!-- <div class="text-center">
			<router-link to="/Home"
				class="px-3 py-2 text-sm font-medium text-white rounded-md leading-5 focus:outline-none hover:bg-lightblueaestima-100 focus:text-white focus:bg-lightblueaestima-100 transition duration-150 ease-in-out bg-lightblueaestima-100"
			>Partager un avis de recherche</router-link>
		</div> -->
	</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
  name: 'LeftSimulatorHome',
  data() {
		return {

		}
	},
  props: {
  },
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
	  user: 'users/user',
	  selected: 'invests/selected',
	  invests: 'invests/invests',
	})
  },
  methods: {
		...mapActions("users", ["login"]),
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
