<template>
  <div id="vue_ensemble">
    <div class="px-5 py-5 mt-5 card">
      <div class="flex flex-row align-middle card-header">
        <img src="../../assets/icons/faq20.png" class="h-20" alt="l’acquisition du bien" />
        <div class="flex flex-col my-auto ml-5">
          <p class="text-xl font-bold ">Analyse et conseils sur l’opportunité d’investissement</p>
          <p class="text-sm">Avant d’aller dans le détail, on vous propose un tour d’horizon pour analyser avec justesse le projet de votre client. Pour rappel, son objectif principal est de <b>{{ phraseStrategie }}</b>.</p>

          <!-- {{ showPositif }} -->
        </div>
      </div>
    </div>
    <!-- POINTS POSITIF -->
    <div v-if="!isPointsPositifsEmpty" class="px-8 mt-10 card card-p">
      <div class="grid grid-cols-12">
        <div class="flex flex-col my-auto ml-5 col-span-12">
          <p class="text-xl font-bold ">Points positifs</p>
        </div>
        <div id="pointPositif" class="col-span-12">
          <!-- STRAT == PATRIMOINE -->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'retraite' && rentaApresImpot() > 3" :img="positif">
            À première vue, cette rentabilité après impôts de
            <b class='blue'>{{ rentaApresImpot() }}%</b> paraît
            correct. Vous pouvez peut-être encore l’améliorer en changeant de régime fiscal ou en faisant varier la
            durée de détention de l’investissement. Pour cela, utilisez le module d’optimisation.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'retraite' && Number(apport) === 0" :img="positif">
            Si votre client arrive à se faire financer
            réellement la somme inscrite sur votre simulation, il n’aura
            quasiment pas d’apport à fournir au moment de l’achat.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'retraite' && cashFlowComputed[0] >= 0" :img="positif">
            Votre client aura la chance de générer un
            excédent de trésorerie chaque mois de <b class='blue'>{{ numberWithSpaces(cashFlowComputed[0]) }}€</b> car
            ses loyers financeront plus de 100% des charges.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'retraite' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) >= 50)" :img="positif">
            Concernant la ville
            d’investissement sélectionnée, son marché locatif semble
            dynamique avec <b class="blue">{{ chartInsee.pop.act }}%</b> de la population qui est active
            avec un emploi.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'retraite' && evolution5ans > 0" :img="positif">
            Bonne nouvelle, les prix immobiliers de la ville d’investissement semblent stables et sont en
            croissance de <b class="blue">{{ evolution5ans }}%</b> sur les 5 dernières années.
          </InfoLine>
          <!-- FIN STRAT == PATRIMOINE -->
          <!-- STRAT == SEREIN -->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && rentaApresImpot() > 6" :img="positif">
            À première vue, cette rentabilité après impôts de <b class="blue">{{ rentaApresImpot() }}%</b> paraît correct. Vous pouvez peut-être encore
            l’améliorer en changeant de régime fiscal ou en faisant varier la durée de détention de l’investissement.
            Pour cela, utilisez le module d’optimisation.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && Number(apport) === 0" :img="positif">
            Si votre client arrive à se faire financer réellement la somme inscrite sur votre simulation,
            il n’aura quasiment pas d’apport à fournir au moment de l’achat.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && cashFlowComputed[0] >= 0" :img="positif">
            Votre client aura la chance de générer un excédent de trésorerie chaque mois
            de <b class="blue">{{ numberWithSpaces(cashFlowComputed[0]) }}€</b>
            car ses loyers financeront plus de 100% des charges.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) >= 50)" :img="positif">
            Concernant la ville d’investissement sélectionnée, son marché locatif semble dynamique
            avec <b class="blue">{{ Number(chartInsee.pop.act) }}%</b> de la population qui est active avec un emploi.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && evolution5ans" :img="positif">
            Bonne nouvelle, les prix immobiliers de la ville d’investissement semblent stables
            et sont en croissance de <b class="blue">{{ evolution5ans }}%</b> sur les 5 dernières années.
          </InfoLine>
          <!-- FIN STRAT == SEREIN -->
          <!-- STRAT == REVENUS -->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && Number(apport) === 0" :img="positif">
            Si votre client arrive à se faire financer réellement la somme inscrite sur votre simulation,
            il n’aura quasiment pas d’apport à fournir au moment de l’achat.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && cashFlowComputed[0] > 0" :img="positif">
            Cette simulation correspond parfaitement à la stratégie de votre client. Cet investissement
            locatif lui permettra de générer un excédent de trésorerie (cash-flow) chaque mois de
            <b class="blue">{{ cashFlowComputed[0] }}€</b> car
            ses loyers financeront plus de 100% des charges et de la fiscalité du projet.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) >= 50)" :img="positif">
            Concernant la ville d’investissement sélectionnée, son marché locatif semble dynamique
            avec <b class="blue">{{ Number(chartInsee.pop.act) }}%</b> de la population qui est active avec un emploi.
          </InfoLine>
          <!-- FIN STRAT == REVENUS -->
          <!-- STRAT == DEFISCALISER -->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && Number(apport) === 0" :img="positif">
            Si votre client arrive à se faire financer réellement la somme inscrite sur votre simulation,
            il n’aura quasiment pas d’apport à fournir au moment de l’achat.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && cashFlowComputed[0] > 0" :img="positif">
            Votre client aura la chance de générer un excédent de trésorerie chaque mois
            de <b class="blue">{{ numberWithSpaces(cashFlowComputed[0]) }}€</b> car ses loyers financeront plus de 100% des charges.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) >= 50)" :img="positif">
            Concernant la ville d’investissement sélectionnée, son marché locatif semble dynamique
            avec <b class="blue">{{ Number(chartInsee.pop.act) }}%</b> de la population qui est active avec un emploi.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && evolution5ans" :img="positif">
            Bonne nouvelle, les prix immobiliers de la ville d’investissement semblent stables et
            sont en croissance de <b class="blue">{{ evolution5ans }}%</b> sur les 5 dernières années.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' &&
        (projectSelected.typeexploitation === 'pinel_neuf' || projectSelected.typeexploitation === 'pinel_ancien'
          || projectSelected.typeexploitation === 'denormandie')" :img="positif">
            Cet investissement aura permis à votre client d’économiser plus de
            <b class="blue">ECONOMIE FISCALE TOTALE</b> durant les <b class="blue">{{ projectSelected.engagement }} ans</b> du dispositif fiscal sélectionné.
          </InfoLine>
          <!-- FIN STRAT == DEFISCALISER -->
        </div>
      </div>
    </div>
    <!-- FIN POINT POSITIF -->
    <!-- POINT NEGATIF -->
    <div v-if="!isPointsDeVigilanceEmpty" class="px-8 mt-10 card card-p">
    <!-- <div v-if="!isPointsDeVigilanceEmpty" class="px-8 mt-10 card card-p"> -->
      <div class="grid grid-cols-12">
        <div class="flex flex-col my-auto ml-5 col-span-12">
          <p class="text-xl font-bold ">Points de vigilance</p>
        </div>
        <div id="pointNegatif" class="col-span-12">
          <!-- STRAT == PATRIMOINE -->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'retraite' && rentaApresImpot() < 3" :img="negatif">
            Au vu de la stratégie de votre client, cette rentabilité après impôts de
            <b class="red">{{ rentaApresImpot() }}%</b> paraît faible.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'retraite' && Number(apportAFournir) > 0" :img="negatif">
            Si cette simulation aboutit sur un achat, votre client devra payer <b class="red">{{ Number(apportAFournir) }}€</b> de
            sa poche dès la signature chez le notaire. Sensibilisez-le sur le fait que cette somme soit bien
            disponible au moment de l’achat.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'retraite' && cashFlowComputed[1] < 0" :img="negatif">
            Votre client devra prévoir de débourser chaque mois <b class="red">{{ -cashFlowComputed[1] }}€</b> pour payer les charges du bien non couvertes par le loyer.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'retraite' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) < 50)" :img="negatif">
            Concernant la ville d’investissement sélectionnée, attention à son marché locatif qui semble peu
            dynamique avec seulement <b class="red">{{ Number(chartInsee.pop.act) }}%</b> de la population qui
            est active avec un emploi.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'retraite' && evolution5ans < 0" :img="negatif">
            Attention, votre client s’expose à un risque de perte de valeur de son futur bien. Même si le passé ne
            prédit pas le futur, les prix immobiliers de sa ville d’investissement sont en baisse de
            {{ evolution5ans }}% sur les 5 dernières années.
          </InfoLine>
          <!-- FIN STRAT == PATRIMOINE -->
          <!-- STRAT == SEREIN -->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && rentaApresImpot() < 6" :img="negatif">
            Au vu de la stratégie de votre client, cette rentabilité après impôts de
            <b class="red">{{ rentaApresImpot() }}%</b> paraît faible.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && Number(apportAFournir) > 0" :img="negatif">
            Si cette simulation aboutit sur un achat, votre client devra payer <b class="red">{{ Number(apportAFournir) }}€</b> de sa poche
            dès la signature chez le notaire. Sensibilisez-le sur le fait que cette somme soit bien disponible
            au moment de l’achat.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && cashFlowComputed[1] < 0" :img="negatif">
            Votre client devra débourser chaque mois
            <b class="red">{{ cashFlowComputed[1] }}€</b> pour payer les
            charges du bien non couvertes par le loyer.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) < 50)" :img="negatif">
            Concernant la ville d’investissement sélectionnée, attention à son marché locatif qui semble peu
            dynamique avec seulement <b class="red">{{ Number(chartInsee.pop.act) }}%</b> de la population qui
            est active avec un emploi.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && evolution5ans < 0" :img="negatif">
            Attention, votre client s’expose à une potentielle perte de valeur future de son bien. Le passé ne
            prédit pas le futur mais les prix immobiliers de la ville d’investissement sont en baisse
            de {{ evolution5ans }}% sur les 5 dernières années.
          </InfoLine>
          <!-- FIN STRAT == SEREIN -->
          <!-- STRAT == REVENUS -->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && Number(apportAFournir) > 0" :img="negatif">
            Si cette simulation aboutit sur un achat, votre client devra payer <b class="red">{{ Number(apportAFournir) }}€</b> de sa poche
            dès la signature chez le notaire. Sensibilisez-le sur le fait que cette somme soit bien disponible
            au moment de l’achat.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && cashFlowComputed[0] <= 0" :img="negatif">
            Attention, le cœur de la stratégie de votre client est de dégager des excédents de trésorerie chaque mois.
            Malheureusement ce n’est pas le cas avec cette simulation car il devra
            débourser <b class="red">{{ Math.abs(cashFlowComputed[0]) }}€</b> chaque mois pour payer les charges du bien non
            couvertes par le loyer.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) < 50)" :img="negatif">
            Concernant la ville d’investissement sélectionnée, attention à son marché locatif qui semble peu
            dynamique avec seulement <b class="red">{{ Number(chartInsee.pop.act) }}%</b> de la population
            qui est active avec un emploi.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && evolution5ans < 0" :img="negatif">
            Attention, votre client s’expose à une potentielle perte de valeur future de son bien. Le passé ne prédit
            pas le futur mais les prix immobiliers de la ville d’investissement sont en baisse de
            <b class="red">{{ evolution5ans }}%</b> sur les 5 dernières années.
          </InfoLine>
          <!-- FIN STRAT == REVENUS -->
          <!-- STRAT == DEFISCALISER -->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && Number(apportAFournir) > 0" :img="negatif">
            Si cette simulation aboutit sur un achat, votre client devra payer <b class="red">{{ Number(apportAFournir) }}€</b> de
            sa poche dès la signature chez le notaire. Sensibilisez-le sur le fait que cette somme soit bien
            disponible au moment de l’achat.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && cashFlowComputed[0] <= 0" :img="negatif">
            Même si la stratégie de votre client est de défiscaliser ses revenus via cet investissement immobilier,
            sensibilisez-le néanmoins sur le fait qu’il devra apporter un effort d’épargne de
            <b class="red">{{ Math.abs(cashFlowComputed[0]) }}€</b> chaque mois pour payer les charges du bien non
            couvertes par le loyer.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) < 50)" :img="negatif">
            Concernant la ville d’investissement sélectionnée, attention à son marché locatif qui semble peu dynamique
            avec seulement <b class="red">{{ Number(chartInsee.pop.act) }}%</b> de la population qui est active avec un emploi.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && evolution5ans < 0" :img="negatif">
            Attention, le cœur de la stratégie de votre client est justement de revendre son bien avec
            une plus-value. Ici, le marché immobilier de la ville choisie est en baisse
            de <b class="red">{{ evolution5ans }}%</b> sur les 5 dernières années. Le passé ne prédit pas l’avenir mais donne une tendance
            des prix. Assurez-vous avec lui qu’il ait bien acheté en dessous du prix du marché pour
            avoir plus de chances de réaliser une plus-value.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && projectSelected.carac.averagePrice.end > (salePrice.averageHigh * 1.25)" :img="negatif">
            Attention, le prix d’achat de cet investissement semble très élevé par rapport aux prix immobiliers actuels.
            Il y a un risque de surpayer l’investissement et de revente à perte.
          </InfoLine>
          <!-- FIN STRAT == DEFISCALISER -->
          <InfoLine v-if="projectSelected.carac.typeexploitation === 'malraux' && projectSelected.results.resFluxAIFonc.resCagnotteMalrauxPerdue.reduce((a,c) => a + c, 0) > 0" :img="negatif">
            Attention, au vu de l'imposition du client, toute la réduction d'impôt Malraux n'a pas pu être appliquée. La perte est de <b class="red">{{ projectSelected.results.resFluxAIFonc.resCagnotteMalrauxPerdue.reduce((a,c) => a + c, 0) }} €</b>.
          </InfoLine>

        </div>
      </div>
    </div>
    <!-- FIN POINT NEGATIF -->
    <!-- CONSEIL -->
    <!-- <div class="px-8 mt-10 card card-p">
      <div class="grid grid-cols-12">
        <div class="flex flex-col my-auto ml-5 col-span-12">
          <p class="text-xl font-bold blue ">Conseils pour améliorer les points de vigilance :</p>
        </div>
        <div id="pointConseil" class="col-span-12">
          <!- - STRAT == PATRIMOINE - ->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && rentaApresImpot() < 5" :img="conseil">
            Astuce : Pour l’améliorer, essayez de changer de régime fiscal pour voir les évolutions ou
            faites varier la durée de détention de l’investissement.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && Number(apport) > 0" :img="conseil">
            Astuce : Si l’apport à fournir à l’achat est trop important pour votre client, invitez-le à négocier
            avec son banquier pour englober plus de frais dans l’emprunt ou redirigez-le vers l’achat d’un bien
            locatif moins cher.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && cashFlowComputed[0] < 0" :img="conseil">
            Astuce : Si votre client veut limiter son effort d’épargne mensuel, invitez-le à négocier avec son
            banquier pour allonger la durée de l’emprunt. L’impact sur les cash-flows sera immédiat.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) < 50)" :img="conseil">
            Astuce : Analysez le pourcentage d’étudiants et s’il est supérieur à 15%, conseillez peut-être à
            votre client de s’orienter vers une stratégie de logements de petites surfaces meublés pour les séduire.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'patrimoine' && evolution() <= 0" :img="conseil">
            Astuce : Avec une telle stratégie, votre client semble être à la recherche d’un
            investissement immobilier sécurisé, invitez-le donc peut-être à changer de ville d’investissement.
          </InfoLine>
          <!- - FIN STRAT == PATRIMOINE - ->
          <!- - STRAT == SEREIN - ->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'serein' && rentaApresImpot() < 10" :img="conseil">
            Astuce : Pour l’améliorer, essayez de changer de régime fiscal pour voir
            les évolutions ou faites varier la durée de détention de l’investissement.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'serein' && Number(apport) > 0" :img="conseil">
            Astuce : Si l’apport à fournir à l’achat est trop important pour votre client, invitez-le à négocier
            avec son banquier pour englober plus de frais dans l’emprunt ou redirigez-le vers l’achat d’un bien
            locatif moins cher.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'serein' && cashFlowComputed[0] < 0" :img="conseil">
            Astuce : Si votre client veut limiter son effort d’épargne mensuel, invitez-le à négocier avec son
            banquier pour allonger la durée de l’emprunt. L’impact sur les cash-flows sera immédiat.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'serein' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) < 50)" :img="conseil">
            Astuce : Analysez le pourcentage d’étudiants et s’il est supérieur à 15%, conseillez peut-être à votre
            client de s’orienter vers une stratégie de logements de petites surfaces meublés pour les séduire.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'serein' && evolution() <= 0" :img="conseil">
            Astuce : Avec une telle stratégie, votre client semble être à la recherche d’un investissement
            immobilier sécurisé, invitez-le donc peut-être à changer de ville d’investissement.
          </InfoLine>
          <!- - FIN STRAT == SEREIN - ->
          <!- - STRAT == REVENUS - ->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && Number(apport) > 0" :img="conseil">
            Astuce : Si l’apport à fournir à l’achat est trop important pour votre client, invitez-le à
            négocier avec son banquier pour englober plus de frais dans l’emprunt ou redirigez-le vers l’achat d’un
            bien locatif moins cher.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && cashFlowComputed[0] <= 0" :img="conseil">
            Astuce : Pour tenter de le transformer en cash-flow positif, invitez votre client à négocier avec son
            banquier pour allonger la durée de l’emprunt. L’impact sur les cash-flows sera immédiat. Si votre
            situation le permet, passez également en régime LMNP au réel pour diminuer son imposition.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && (chartInsee.pop !== undefined && Number(chartInsee.pop.act) < 50)" :img="conseil">
            Astuce : Analysez le pourcentage d’étudiants et s’il est supérieur à 15%, conseillez peut-être à votre
            client de s’orienter vers une stratégie de logements de petites surfaces meublés pour les séduire.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'revenus' && evolution() <= 0" :img="conseil">
            Astuce : Pour limiter une décote trop importante, sensibilisez bien votre client sur le fait
            d’acheter en dessous du prix du marché..
          </InfoLine>
          <!- - FIN STRAT == REVENUS - ->
          <!- - STRAT == DEFISCALISER - ->
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && Number(apport) > 0" :img="conseil">
            Si l’apport à fournir à l’achat est trop important pour votre client, invitez-le à négocier avec son
            banquier pour englober plus de frais dans l’emprunt ou redirigez-le vers l’achat d’un bien locatif moins cher.
          </InfoLine>
          <InfoLine v-if="investSelected.strategy.typestrategy === 'defiscaliser' && evolution() <= 0" :img="conseil">
            Astuce : Analysez le pourcentage d’étudiants et s’il est supérieur à 15%, invitez votre client à choisir
            un bien locatif neuf d’une petite surface pour séduire les futurs acquéreurs.
          </InfoLine>
          <!- - FIN STRAT == DEFISCALISER - ->

        </div>
      </div>
    </div> -->
    <!-- FIN CONSEIL -->
    <div class="px-8 mt-10 card card-p">
      <div class="flex flex-row align-middle card-header">
        <img src="../../assets/icons/faq21.png" class="h-20" alt="Présentation" />
        <div class="flex flex-col my-auto ml-5 col-span-12">
          <p class="text-xl font-bold">Présentation des principaux ratios de performances</p>
        </div>


        <!-- FIN FISCALITE TOTAL -->
      </div>
      <div class="mt-5 sm:mt-0 sm:col-start-2 sm:col-end-12">
        <div class="sm:rounded-md">
          <div class="flex flex-col mb-10">
            <div>
              <div class="flex justify-center my-3 items-center">
                <div class="flex">Rentabilité nette après fiscalité</div>
                <QuestionMarkv2 class="ml-2" :text="`${infobulles.rentabilite}`" />
                <div 
                  v-if="projectSelected.results.resFluxAIFonc.performance.avecFisc.tir !== 0"
                  :class="{'text-red-600': Number(projectSelected.results.resFluxAIFonc.performance.avecFisc.tir) <= 0 , ' text-lightblueaestima-100 font-bold': Number(projectSelected.results.resFluxAIFonc.performance.avecFisc.tir) != 0}"
                  class="ml-10 font-bold"
                >
                  {{ projectSelected.results.resFluxAIFonc.performance.avecFisc.tir }}%
                </div>
                <img
                  v-else
                  class="w-auto h-10 mx-2"
                  alt="symbole infini"
                  src="https://api.iconify.design/ion/infinite.svg?color=%230e9f6e"
                />
              </div>
            </div>
            <div
              v-if="projectSelected.results.resFluxAIFonc.performance.avecFisc.tir === 0"
              class="text-center text-green-500 text-sm"
            >
              Taux de rentabilité infini. Il n'a pas pu être calculé car ce projet n'enregistre aucun flux négatif (en d'autres termes il ne coûte rien à l'investisseur)
            </div>
            <div>
              <p class="flex justify-center my-3">
                Fiscalité totale de l'investissement
                    <QuestionMarkv2 class="ml-2" :text="`${infobulles.fiscalite}`" />
                    <span :class="{'text-red-600': -Math.round(projectSelected.results.resFluxAIFonc.resTotImposition - projectSelected.results.resFluxAIFonc.resTotVersDiv) == 0 , ' text-lightblueaestima-100 font-bold': -Math.round(-projectSelected.results.resFluxAIFonc.resTotImposition - projectSelected.results.resFluxAIFonc.resTotVersDiv) != 0}"
                         class="ml-10 font-bold"><span>{{-Math.round(projectSelected.results.resFluxAIFonc.resTotImposition - projectSelected.results.resFluxAIFonc.resTotVersDiv)}} €</span></span>
                  </p>
                </div>
          </div>
          <div class="flex items-center bg-white grid grid-cols-12 gap-6">

            <div class="flex flex-col justify-center col-span-4 sm:col-start-1 sm:col-end-5">
              <div class="flex flex-col items-center w-full">

                <div class="flex flex-row">
                  <p class="text-xs text-center font-base">
                    <span class="text-base font-semibold">Apport à fournir à l’achat</span>
                    <br />avant d’encaisser des loyers et de payer des charges
                  </p>
                  <QuestionMarkv2
                    class="ml-2"
                    :text="`Calculé en déduisant le montant prêté par la banque du coût total de l’investissement.
                  Selon le département dans lequel vous investissez, vous pouvez avoir droit à des aides. Plus d’information sur : <u><a target='_blank' href='https://www.anil.org/aides-locales/'>https://www.anil.org/aides-locales/</a></u>`"
                  />
                  <QuestionMarkv2
                    class="ml-2"
                    :text="`${infobulles.apport}`"
                    :calculatrice="true"
                  />
                </div>
                <p class="text-lg font-bold text-lightblueaestima-100">
                  <span class="text-xl">{{ numberWithSpaces(Math.round(apportAFournir)) }}</span>
                  €
                </p>
              </div>
              <!-- <div class="flex line-div">
                <div class="line-up"></div>
              </div> -->
            </div>

            <div class="flex flex-col justify-center pr-10 col-span-4 sm:col-start-5 sm:col-end-9">
              <div class="flex flex-col items-center w-full">
                <div class="flex flex-row">
                  <div class="flex flex-col text-xs text-center font-base">
                    <div class="text-base font-semibold">Cash-flow mensuel</div>
                    <div class='text-sm' v-if="projectSelected.carac.modefinancement != 'fond_propre'">durant remboursement de l’emprunt</div>
                  </div>
                  <QuestionMarkv2  v-if="projectSelected.carac.modefinancement != 'fond_propre'" class="ml-2" :text="`${infobulles.cashflowPendant}`" />
                  <QuestionMarkv2  v-if="projectSelected.carac.modefinancement != 'fond_propre'" class="ml-2" :text="`Montant que vous gagnerez (+) ou devrez rajouter de votre poche (-) chaque mois pour financer le bien et ses charges pendant toute la période durant laquelle vous rembourserez votre emprunt bancaire.`" :calculatrice="true" />
                </div>
                <div class="flex flex-row items-center">
                  <p class="text-lg font-bold text-lightblueaestima-100">
                    <span class="text-xl">{{ numberWithSpaces(cashFlowComputed[0]) }}</span>
                    €
                  </p>
                  <!-- <div class="z-40 wrapper" v-if="messageStrategy[strategyIndex].cashflowMens != ''">
                    <img class="inline-block w-auto h-6 mx-2" src="@/assets/picto/Panneau.png" alt="pictogramme panneau attention" />
                    <div class="tooltip" v-html="messageStrategy[strategyIndex].cashflowMens"></div>
                  </div> -->
                </div>
              </div>
            </div>

            <div class="flex flex-col justify-center pl-10 col-span-4 sm:col-start-9 sm:col-end-12" v-if="projectSelected.carac.dureeempr.val < projectSelected.carac.dureedetention.val">
              <div class="flex flex-col items-center w-full">
                <div class="flex flex-row">
                  <p class="text-xs text-center font-base">
                    <span class="text-base font-semibold">Cash-flow mensuel</span>
                    <br />après remboursement de l’emprunt
                  </p>
                  <QuestionMarkv2 class="ml-2" :text="`Montant que vous gagnerez (+) ou devrez rajouter de votre poche (-) chaque mois pour financer le bien et ses charges après avoir fini de  rembourser votre emprunt bancaire.`" :calculatrice="true" />
                </div>
                <p class="text-lg font-bold text-lightblueaestima-100">
                  <span class="text-xl">{{ numberWithSpaces(cashFlowComputed[1]) }}</span>
                  €
                </p>
              </div>
            </div>
          </div>
          <img src="@/assets/icons/resultats_financiers/80_emprunt.png" alt="CashFlow" v-if="projectSelected.carac.dureeempr.val < projectSelected.carac.dureedetention.val && projectSelected.carac.modefinancement != 'fond_propre'" />
          <img src="@/assets/icons/resultats_financiers/100_emprunt.png" alt="CashFlow" v-if="projectSelected.carac.dureeempr.val >= projectSelected.carac.dureedetention.val && projectSelected.carac.modefinancement != 'fond_propre'" />
          <img src="@/assets/icons/resultats_financiers/100_fonds_propre.png" alt="CashFlow" v-if="projectSelected.carac.modefinancement == 'fond_propre'" />

          <div class="flex flex-col mt-10 text-left col-span-6 sm:col-start-1 sm:col-end-5">
            <div class="flex flex-row items-center justify-end w-full">
              <div class="flex flex-col">
                <div class="flex">
                  <p class="text-base font-semibold">Profit total</p>
                  <QuestionMarkv2 class="ml-2" :text="`${infobulles.profitTotal}`" :calculatrice="true" />
                </div>
                <p>après revente</p>
              </div>
              <p class="ml-5 text-lg font-bold text-lightblueaestima-100">
                <span class="text-xl">{{numberWithSpaces(Math.round(projectSelected.results.resFluxAIFonc.resTotCashFlowNonActu))}}</span>
                €
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
      <!--FIN CASHFLOW -->
    </div>
  </div>
  </div>
</template>

<script>
import QuestionMarkv2 from "@/components/SVG/QuestionMarkv2";
import InfoLine from "@/components/result/InfoLine";
import PointPositif from "../../assets/icons/point_positif.png";
import PointNegatif from "../../assets/icons/point_vigilance.png";
import PointConseil from "../../assets/icons/conseil.png";
import { mapGetters } from "vuex";
export default {
  name: "VueEnsemble",
  components: {
    QuestionMarkv2,
    InfoLine
  },
  props: {
    cashFlowComputed: {
      type: Array
    },
    chartInsee: {
      type: Object
    },
    apport: {
      type: Number
    },
    chartDatas: {
      type: Array
    },
    salePrice: {
      type: Object
    },
    messageStrategy: {
      type: Array,
    },
    strategyIndex: {
      type: Number || null,
    },
  },
  created() {
    this.evolution5ans = this.projectSelected.carac.evolution5ans ?? null
  },
  computed: {
    ...mapGetters({
      projectSelected: 'projects/selected',
      investSelected: 'invests/selected',
    }),
    apportAFournir(){
      return  Math.abs(this.projectSelected.results.resCoutTotalFonc.resPrixAchat[0])
            + Math.abs(this.projectSelected.results.resCoutTotalFonc.resFraisNotaire[0] )
            + Math.abs(this.projectSelected.results.resCoutTotalFonc.resFraisAgenceImmo[0])
            + Math.abs(this.projectSelected.results.resCoutTotalFonc.resTVA[0])
            + Math.abs(Number(this.projectSelected.carac.travauxReno.year_0) )
            + Math.abs(Number(this.projectSelected.carac.travauxConstru.year_0) )
	    + Math.abs(Number(this.fraisAmeublement()))
            + Math.abs(this.projectSelected.results.resInvTravFonc.resFraisBanc[0] )
            - Math.abs(this.projectSelected.results.resInvTravFonc.resEmprunt[0])
    },
    showPositif: {
      get: function () {
        return true;
      },
      set: function (val) {
      }
    },
    isPointsPositifsEmpty() {
      const strategy = this.investSelected.strategy.typestrategy;
      const conditions = [
        // Conditions for strategy "retraite"
        strategy === 'retraite' && this.rentaApresImpot() > 3,
        strategy === 'retraite' && Number(this.apport) === 0,
        strategy === 'retraite' && this.cashFlowComputed[0] >= 0,
        strategy === 'retraite' && this.chartInsee.pop !== undefined && Number(this.chartInsee.pop.act) >= 50,
        strategy === 'retraite' && this.evolution5ans > 0,

        // Conditions for strategy "patrimoine"
        strategy === 'patrimoine' && this.rentaApresImpot() > 6,
        strategy === 'patrimoine' && Number(this.apport) === 0,
        strategy === 'patrimoine' && this.cashFlowComputed[0] >= 0,
        strategy === 'patrimoine' && this.chartInsee.pop !== undefined && Number(this.chartInsee.pop.act) >= 50,
        strategy === 'patrimoine' && this.evolution5ans,

        // Conditions for strategy "revenus"
        strategy === 'revenus' && Number(this.apport) === 0,
        strategy === 'revenus' && this.cashFlowComputed[0] > 0,
        strategy === 'revenus' && this.chartInsee.pop !== undefined && Number(this.chartInsee.pop.act) >= 50,

        // Conditions for strategy "defiscaliser"
        strategy === 'defiscaliser' && Number(this.apport) === 0,
        strategy === 'defiscaliser' && this.cashFlowComputed[0] > 0,
        strategy === 'defiscaliser' && this.chartInsee.pop !== undefined && Number(this.chartInsee.pop.act) >= 50,
        strategy === 'defiscaliser' && this.evolution5ans,
        strategy === 'defiscaliser' && (this.projectSelected.typeexploitation === 'pinel_neuf' || this.projectSelected.typeexploitation === 'pinel_ancien' || this.projectSelected.typeexploitation === 'denormandie'),
      ];

      return conditions.every(condition => !condition);
    },
    isPointsDeVigilanceEmpty() {
      const strategy = this.investSelected.strategy.typestrategy;
      const conditions = [
        // Conditions for strategy "retraite"
        strategy === 'retraite' && this.rentaApresImpot() < 3,
        strategy === 'retraite' && Number(this.apport) > 0,
        strategy === 'retraite' && this.cashFlowComputed[1] < 0,
        strategy === 'retraite' && this.chartInsee.pop !== undefined && Number(this.chartInsee.pop.act) < 50,
        strategy === 'retraite' && this.evolution5ans < 0,
        
        // Conditions for strategy "patrimoine"
        strategy === 'patrimoine' && this.rentaApresImpot() < 6,
        strategy === 'patrimoine' && Number(this.apport) > 0,
        strategy === 'patrimoine' && this.cashFlowComputed[1] < 0,
        strategy === 'patrimoine' && this.chartInsee.pop !== undefined && Number(this.chartInsee.pop.act) < 50,
        strategy === 'patrimoine' && this.evolution5ans < 0,
        
        // Conditions for strategy "revenus"
        strategy === 'revenus' && Number(this.apport) > 0,
        strategy === 'revenus' && this.cashFlowComputed[0] <= 0,
        strategy === 'revenus' && this.chartInsee.pop !== undefined && Number(this.chartInsee.pop.act) < 50,
        strategy === 'revenus' && this.evolution5ans < 0,
        
        // Conditions for strategy "defiscaliser"
        strategy === 'defiscaliser' && Number(this.apport) > 0,
        strategy === 'defiscaliser' && this.cashFlowComputed[0] <= 0,
        strategy === 'defiscaliser' && this.chartInsee.pop !== undefined && Number(this.chartInsee.pop.act) < 50,
        strategy === 'defiscaliser' && this.evolution5ans < 0,
        strategy === 'defiscaliser' && this.projectSelected.carac.averagePrice.end > (this.salePrice.averageHigh * 1.25),
      ];

      return conditions.every(condition => !condition);
    },
    phraseStrategie(){
      switch (this.investSelected.strategy.typestrategy){
        case 'retraite':
          return 'avoir des revenus locatifs pour sa retraite'
        case 'patrimoine':
          return 'avoir un patrimoine à moindre coût'
        case 'revenus':
          return 'générer des revenus immédiatement'
        case 'defiscaliser':
          return 'défiscaliser ses revenus'
      }
    },
    showNegatif: {
      get: function () {
        return true;
      },
      set: function (val) {
      }
    },
    showConseil: {
      get: function () {
        return true;
      },
      set: function (val) {
      }
    },
  },
  data: function () {
    return {
      positif: PointPositif,
      negatif: PointNegatif,
      conseil: PointConseil,
      infobulles: {
        apport: "Coût total d’acquisition + travaux à prévoir à l’achat + frais d’obtention d’emprunt (dossier et garantie) - montant emprunté.",
        cashflowPendant: "Somme des flux de trésorerie annuels de l'année 1 à l'année 20 <br>/ 20 (nombre d'années de remboursement d'emprunt)<br><br>Nous obtenons le cash-flow moyen annuel. On redivise cette donnée par 12 pour avoir le cash-flow mensuel.",
        cashflowApres: "Somme des flux de trésorerie annuels de l'année 21 à l'année de revente <br>/ nombre d'années restantes entre la fin de l'emprunt et la revente du bien<br><br>Nous obtenons le cash-flow moyen annuel. On redivise cette donnée par 12 pour avoir le cash-flow mensuel.",
        fiscalite: "Montant total d’impôt payé pour cet investissement. Il comprend l’impôt sur le revenu ou les sociétés, selon votre statut ainsi que l’impôt sur la plus-value lors de la revente. (Prise en compte de la taxation sur les dividendes via la flat tax uniquement si l’option a été choisie).",
        rentabilite: "Ce ratio nous permet de connaître la rentabilité finale de l’investissement tenant compte de tous les frais y compris de la fiscalité et de la valeur de revente du bien.",
        profitTotal : `Pour expliquer simplement à votre client de quoi est composé le profit, il suffit de faire :
          </br></br>Somme des loyers durant toute la durée de détention + prix de revente de l’investissement – prix d’achat frais compris - somme des charges locatives – sommes des charges d’emprunt – imposition totale du projet.
          </br></br>Le profit est aussi égal au prix de revente (après déduction de l'impôt sur la plus-value) auquel on soustrait l’effort d’épargne fourni tout au long du projet.`
      }
    }
  },
  methods: {
          formeJuridiqueSelected() {
                    // check witch item in projectSelected.carac.formeJuridique.item.selected is true and return item.code
                         for (let item of this.projectSelected.carac.formejuridique) {
                                     if (item.selected === true) {
                                                   return item.code
                                                           }
                                                                 }
                                                                      return null
                                                                          },

    fraisAmeublement() {
	    if(['sasuis', 'sciis', 'lmnpbic', 'lmnpr'].includes(this.formeJuridiqueSelected())) {
            return this.projectSelected.carac.fameublement
	    } else {
	    return 0
	    }
    },
    evolution() {
      return Number((((Number(this.chartDatas[this.chartDatas.length - 1]) - Number(this.chartDatas[0])) / Number(this.chartDatas[0])) * 100).toFixed(1));
    },
    rentaApresImpot() {
      const TIR = Number(this.projectSelected.results.resFluxAIFonc.performance.avecFisc.tir);
      if (TIR === 0) return 10000
      else return TIR
    },
    numberWithSpaces(x) {
      if (isNaN(x) || x == undefined) return 0;
      var parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join('.');
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 40px;
}

.line-div {
  position: relative;
}

.line-up {
  position: absolute;
  border-left: 1px solid black;
  height: 200px;
  left: 5%;
  margin-left: -3px;
  top: 0;
}

.line-bottom {
  position: absolute;
  border-left: 1px solid black;
  height: 50px;
  left: 75%;
  bottom: 55px;
}

.card {
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  &-header {
    &-img {
      width: 259px;
      height: 215px;
    }
  }

  &-line {
    margin-top: 20px;
  }

  &-p {
    padding-top: 24px;
    padding-bottom: 32px;
  }
}

.infobulle {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-transform: none;
}

.blue {
  color: #14A0EB;
}

.red {
  color: #EB7B14;
}

.preicon {
  max-height: 80px;
}

.describe {
  padding-left: 10px;
  font-size: 18px;
}
</style>
