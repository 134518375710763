<template>
  <div class="bg-azur flex flex-col px-4 py-12 h-screen w-72 gap-3">
    <div
      class="item"
      :class="{ 'bg-blue-200': $route.name === 'NewHome' }"
      @mouseenter="currentHomeIcon = homeIconHover" @mouseleave="currentHomeIcon = homeIcon"
      @click="$router.push({ name: 'Home' })"
    >
      <img
        :src="currentHomeIcon"
        class="pl-1 h-6 hover:text-cool-gray-50"
      >
      Tableau de bord
    </div>
    <div
      class="item"
      :class="{ 'bg-blue-200': $route.name === 'MesDossiers' }"
      @mouseenter="currentFolderIcon = folderIconHover" @mouseleave="currentFolderIcon = folderIcon"
      @click="$router.push({ name: 'MesDossiers' })"
    >
      <img :src="currentFolderIcon" class="pl-1 h-6">
      Mes dossiers
    </div>
    <div class="hidden">
      preload
      <img :src="homeIconHover">
      <img :src="folderIconHover">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppSidebar',
  data() {
    return {
      homeIcon: 'https://api.iconify.design/material-symbols:home-rounded.svg',
      homeIconHover: 'https://api.iconify.design/material-symbols:home-rounded.svg?color=%23ffffff',
      currentHomeIcon: 'https://api.iconify.design/material-symbols:home-rounded.svg',
      folderIcon: 'https://api.iconify.design/material-symbols:folder-open-outline-rounded.svg',
      folderIconHover: 'https://api.iconify.design/material-symbols:folder-open-outline-rounded.svg?color=%23ffffff',
      currentFolderIcon: 'https://api.iconify.design/material-symbols:folder-open-outline-rounded.svg',
    }
  },
}
</script>

<style scoped>
.bg-azur {
  background-color: #f3fbff;
}

.item {
  display: flex;
  padding: 1rem 0.5rem;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  border-radius: 0.8rem;
}

.item:hover {
  background-color: #14a0eb;
  color: white;
  cursor: pointer;
  font-weight: 700;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
</style>
