<template>
	<div>
		<Header/>
		<div class="grid grid-cols-11 gap-1 bg-darkblueaestima-500 relative flex flex-row justify-center items-start">
			<div class="relative text-center col-span-11 sm:col-start-4 sm:col-end-11 z-10 flex justify-center">
				<h1 class="s1-title text-white text-4xl md:text-6xl mb-4 aestima-title-vw leading-none text-center sm:text-left">Projet immobilier :</br> <b>Comment monter mon dossier bancaire?</b> </h1>
				<!-- <p class="text-gray-300 font-medium text-2xl">Essayez la version gratuite</p>
				<p class="text-white font-medium text-2xl">durant 1 mois</p> -->
			</div>
			<!-- <a href="/presentation" class="col-span-11 sm:col-start-5 sm:col-end-8 uppercase text-sm font-semibold text-white px-12 py-6 bg-aestiblue-light rounded-full aestima-button mb-10">DEMARRER MON ESSAI GRATUIT</a>	 -->
		</div>
		
		<div class="mx-10 sm:mx-20 text-left mt-10 smooth">
			<div class="grid grid-cols-12 gap-1 relative flex flex-row justify-center items-start mb-6">
				<div class="hidden sticky top-16 sm:block sm:col-start-1 sm:col-end-3">
					<div class=" flex flex-col font-semibold">
						<a href="#introduction" class="my-2">Introduction</a>
						<a href="#pourquoi_dossier" class="my-2">Pourquoi monter un dossier?</a>
						<a href="#comment_dossier" class="my-2">Comment monter mon dossier?</a>
						<a href="#quels_documents" class="my-2">Quels documents à fournir ?</a>
						<a href="#quand_dossier" class="my-2">Quand monter mon dossier ?</a>
						<a href="#conseils_demande" class="my-2">Conseils pour votre demande</a>
						<a href="#conclusion" class="my-2">Conclusion</a>
					</div>
				</div>
				<div class="col-span-12 sm:col-start-4 sm:col-end-12 text-justify">
					<div class="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center mb-10">
						<div class="flex flex-row">
							<div class="rounded-full w-16 h-16 overflow-hidden flex justify-center items-center">
								<img class="w-20" src="../../../assets/meryl_profil.png" alt="Profil Méryl">
							</div>
							<div class="flex flex-col justify-center ml-6">
								<address class="not-italic font-bold text-lg">Méryl</address> 
								<span class="text-sm">Mis à jour le <time pubdate datetime="2021-04-07" title="7 avril 2021">07 avril 2021</time></span>
							</div>
						</div>
						<div class="p-2 mt-6 sm:mt-0 bg-gray-100 rounded-full relative clipboard cursor-pointer" @click="clipboard()">
							<div>{{linkCopied}}</div>
							<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
							width="22" height="22"
							viewBox="0 0 172 172"
							style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#000000"><path d="M43,50.16667c-20.94817,0 -37.7007,17.97568 -35.66536,39.34668c1.77733,18.63333 18.48575,32.31999 37.20508,32.31999h19.96029c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667h-20.28223c-11.63867,0 -22.07378,-8.72072 -22.67578,-20.35222c-0.65217,-12.39833 9.20301,-22.64778 21.45801,-22.64778h21.5c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667zM107.5,50.16667c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h20.28223c11.63867,0 22.07378,8.72072 22.67578,20.35222c0.65217,12.39833 -9.20301,22.64778 -21.45801,22.64778h-21.5c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h21.5c20.94817,0 37.7007,-17.97568 35.66536,-39.34668c-1.77733,-18.63334 -18.48574,-32.31999 -37.20508,-32.31999zM57.33333,78.83333c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h57.33333c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667z"></path></g></g></svg>
						</div>
					</div>
					<p id="introduction">Une <b>demande d’emprunt</b> pour <b>obtenir un prêt immobilier</b> doit toujours être accompagnée d’un <b>dossier bancaire</b>. Il doit être bien monté afin de <b>convaincre votre banquier</b> et maximiser vos chances.</p>
					
					<div class="flex justify-center mb-6 sm:my-10">
						<router-link target="_blank" to="/acces-simulateur" class="text-sm sm:text-lg text-center font-semibold text-white px-6 py-3 bg-aestiblue-light rounded-lg aestima-button mt-20 sm:mt-0"><h3>Monter mon dossier bancaire avec Aestima</h3></router-link>
					</div>
					
					<p>Commençons par quelques chiffres :</p>
					<ul class="round-tiret mb-4 pl-16 mt-4">
						<li>1 personne sur 7 se voit refuser sa <b>demande d’emprunt</b>*.</li>
						<li class="my-2">45% des demandeurs estiment qu’il est difficile de <b>convaincre son banquier</b> pour une <b>demande d’emprunt</b>*.</li>
					</ul>
					</br>
					<p>
						Les conditions d’accès au crédit immobilier s’étant récemment assouplies, il n’en reste pas moins difficile d’<b>obtenir un prêt immobilier</b> pour son projet. Cette réalité reste d’autant plus forte pour les investisseurs. En effet, les banques sont incitées à ne plus financer le <b>projet</b> d’investissement <b>immobilier</b> au-delà du prix du bien et des travaux (non financement des frais d’agence immobilière, frais de notaire, frais bancaire, etc.).
					</p>
					</br>
					<p>
						Le saviez-vous ? Il faut, en moyenne, compter 25 jours pour <b>monter un dossier bancaire</b> de <b>demande d’emprunt</b>.
					</p>
					</br>
					<p>
						En effet, <b>monter un dossier bancaire</b> nécessite beaucoup d’implication. Il requiert un certain nombre de documents et d’informations à fournir. Il reste néanmoins un allié redoutable afin de mettre tous les avantages de votre <b>projet immobilier</b> en avant.
						Un <b>dossier bancaire</b> mal monté avec des informations et éléments manquants peut gravement vous desservir. Certaines personnes voient leur prêt immobilier être refusé pour cette raison. Être sérieux dans sa <b>demande d’emprunt</b> et <b>monter un dossier bancaire</b> à la perfection permet de mieux <b>convaincre votre banquier</b>.
					</p>
					
					<div class="my-10 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative">
						<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../../../assets/picto/ampoule.png" alt="">
						<p class="text-lightblueaestima-100 relative z-10">
							<b>A retenir </b> : La banque reste une entreprise. Elle veut gagner de l’argent en minimisant les risques.
						</p>
					</div>
					
					<div class="flex flex-col items-center relative my-6 mb-16">
						<img class="w-3/3 flex justify-center items-center self-center" src="@/assets/article/monter-dossier-bancaire.png" title="calcul rentabilité locative nette" alt="calcul rentabilité locative nette">
						<div class="relative bottom-0 w-full h-10 bg-img-desc flex justify-start items-center pl-4 -m-10">
							<p class="text-xs sm:text-base"><b> Monter mon dossier bancaire pour obtenir mon prêt immobilier. </b></p>
						</div>
					</div>
					
					<div class="mt-10" id="pourquoi_dossier" ref="pourquoi_dossier">
						<h2 class="font-bold text-2xl mb-6"><span class="text-lightblueaestima-100">Demande d’emprunt,</span> </br>Pourquoi monter un dossier bancaire ?</h2>
						<p>
							Gardez en tête que l’objectif final est <b>d’obtenir votre prêt immobilier</b>. Un <b>dossier bancaire</b> complet est la condition pour obtenir un <b>crédit immobilier</b> mais aussi :
						</p>
						</br>
						<ul class="round-tiret mb-4 pl-16 mt-4">
							<li>Créer un programme de financement limpide pour vous et votre banquier. D’ailleurs, ce programme de financement se crée en fonction de votre profil d’investisseur. Faut-il encore savoir <router-link to="/strategie-investissement-immobilier"><u>comment définir la bonne stratégie d’investissement</u></router-link> ?</li>
							<li class="my-2">Avoir la possibilité d’anticiper votre capacité d’emprunt.</li>
							<li>Faire également un point sur votre <b>projet immobilier</b> et ses performances financières (s’il s’agit d’un investissement locatif).</li>
						</ul>
						</br>
						<p>
							Vous l’avez compris, les objectifs sont multiples. 
						</p>
						
						<div class="flex justify-center sm:my-10">
							<router-link target="_blank" to="/acces-simulateur" class="text-sm sm:text-lg text-center font-semibold text-white px-6 py-3 bg-aestiblue-light rounded-lg aestima-button mt-20 sm:mt-0"><h3>Je veux que l'outil Aestima m'accompagne pour </br> monter un dossier bancaire parfait</h3></router-link>
						</div>
					</div>
					
					<div class="mt-10" id="comment_dossier" ref="comment_dossier">
						<h2 class="font-bold text-2xl mb-6">Comment monter un dossier bancaire convaincant </br> <span class="text-lightblueaestima-100">pour obtenir mon prêt immobilier ?</span></h2>
						<p>
							Il existe nombre de stratégies à appliquer afin de valoriser au maximum votre <b>dossier bancaire</b> et maximiser vos chances pour <b>obtenir le prêt immobilier</b>.
						</p>
						</br>
						<p>
							Il faut, avant tout, savoir valoriser votre profil en justifiant le fait que vous êtes sérieux, raisonnable et épargnant. Bref, un profil sécuritaire avec une situation stable. Sachez justifier d’une bonne gestion de vos comptes et d’une bonne santé financière.
						</p>
						</br>
						<p>
							Valoriser son profil veut également dire mettre en avant les points avantageux qui peuvent <b>convaincre le banquier</b> : vous êtes en CDI, vous êtes propriétaire de votre résidence principale, vous avez de l’expérience dans l’investissement immobilier, vous avez <router-link target="_blank" to="/register"><u>effectué une simulation financière</u></router-link> qui prouve que ce projet immobilier est viable, etc.
						</p>
						</br>
						<p>
							Au-delà de l’aspect concret de votre profil, accordez du temps pour <b>monter votre dossier bancaire</b>. N’oubliez aucun document ni information. Cela ralentirait vos démarches voire desservir gravement votre <b>demande d’emprunt</b>.
						</p>
						
						<div class="flex justify-center sm:my-10">
							<router-link target="_blank" to="/acces-simulateur" class="text-sm sm:text-lg font-semibold text-white px-12 py-3 bg-aestiblue-light rounded-lg aestima-button mt-20 sm:mt-0"><h3>Simuler gratuitement mon investissement</h3></router-link>
						</div>
					</div>
					
					<div class="mt-10" id="quels_documents" ref="quels_documents">
						<h2 class="font-bold text-2xl mb-6">Quels documents sont à joindre à mon dossier bancaire ?</h2>
						<p>
							Dans un premier temps, réunissez tous les documents au cours de votre recherche. De cette façon, vous gagnez du temps lorsque vous repérez un bien immobilier correspondant à vos critères.
						</p>
						</br>
						
						<h3><b><u>Identité et profil :</u></b></h3>
						<ul class="round-tiret mb-4 pl-16 mt-4">
							<li>
								Carte d’identité en cours de validité
							</li>
							<li>
								Livret de famille
							</li>
							<li>
								Contrat de mariage ou Pacs
							</li>
							<li>
								Justificatif de domicile (facture d’eau ou d’électricité de moins de 3 mois)
							</li>
							<li>
								Bail OU quittance de loyer OU acte de propriété
							</li>
							<li>
								Dernier avis d’imposition
							</li>
						</ul>
						</br>
						<h3><b><u>Activité professionnelle :</u></b></h3>
						<ul class="round-tiret mb-4 pl-16 mt-4">
							<li>
								Contrat de travail
							</li>
							<li>
								3 derniers bulletins de salaire 
							</li>
						</ul>
						</br>
						<h3><b><u>Situation financière :</u></b></h3>
						<ul class="round-tiret mb-4 pl-16 mt-4">
							<li>
								3 derniers relevés de compte
							</li>
							<li>
								Avis d’imposition
							</li>
							<li>
								Offre de prêt + tableau d’amortissement (prêt personnel ou immobilier en cours)
							</li>
							<li>
								Tout document justifiant votre apport, votre épargne et les montants à votre disposition
							</li>
						</ul>
						</br>
						
						<div class="my-10 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative">
							<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../../../assets/picto/ampoule.png" alt="">
							<p class="text-lightblueaestima-100 relative z-10">
								<b>Bon à savoir </b> : si votre apport provient de la vente d’un autre bien immobilier, vous devez présenter le compromis de vente ou une attestation du notaire.
							</p>
						</div>
						
						<h3><b><u>Concernant le projet :</u></b></h3>
						<ul class="round-tiret mb-4 pl-16 mt-4">
							<li>
								Compromis de vente 
							</li>
							<li class="mt-2">
								Attestations de revenus locatifs délivrées par 2 agents immobiliers distincts (ou bail du locataire en place) = pour justifier des revenus locatifs
							</li>
						</ul>
						</br>
						<p>
							Par la suite, rédigez votre <b>demande d’emprunt</b> à travers un <b>dossier bancaire</b> bien établi. Cela vous permettra de rassembler, dans un même document, l’ensemble des éléments que les banques demandent sur vous (votre profil, votre parcours, vos habitudes de consommation, etc.).
						</p>
						
					</div>
					
					<div class="mt-10" id="quand_dossier" ref="quand_dossier">
						<h2 class="font-bold text-2xl mb-6"><span class="text-lightblueaestima-100">Demande d’emprunt,</span> </br>Quand monter mon dossier bancaire ?</h2>
						<p>
							Nous avons tous tendance à repousser le moment où il faut mettre son nez dans la paperasse. Cependant, au plus tôt vous aurez constitué votre <b>dossier bancaire</b>, au plus vite vous mettez les chances de votre côté. Montrez à votre banquier que vous êtes réactif et organisé ! <b>Convaincre le banquier</b>, c’est votre priorité !
						</p>
						</br>
						<p>
							Ce <b>dossier bancaire</b> devra être établi entre la signature du compromis et le premier rendez-vous chez votre banquier.
						</p>
						
						<div class="flex justify-center sm:my-10">
							<router-link target="_blank" to="/acces-simulateur" class="text-sm sm:text-lg font-semibold text-white px-12 py-3 bg-aestiblue-light rounded-lg aestima-button mt-20 sm:mt-0"> <h3>Monter mon dossier bancaire personnalisé </h3></router-link>
						</div>
					</div>
					
					<div class="mt-10" id="conseils_demande" ref="conseils_demande">
						<h2 class="font-bold text-2xl mb-6"><span class="text-lightblueaestima-100">Projet immobilier,</span> </br>4 conseils pour monter votre dossier bancaire.</h2>
						<p>
							Conseil n°1 </br>
							<b>Aplanir vos comptes.</b>
						</p>
						</br>
						<p>
							Sachez justifier des dépenses anormalement élevées, des dépenses plutôt « douteuses » pour le banquier (casinos, jeux en ligne, etc.) ou encore des moments où vous êtes en déficit. Lorsque vous êtes dans l’une de ces situations, retardez votre <b>demande d’emprunt</b> de 3 mois, dans l’attente d’aplanir vos comptes. Nous vous conseillons également de régler vos crédits à la consommation. Ces derniers ne sont pas spécialement mal vus par les banques mais réduisent votre capacité d’endettement.
						</p>
						
						<div class="my-10 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative">
							<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../../../assets/picto/ampoule.png" alt="">
							<p class="text-lightblueaestima-100 relative z-10">
								<b>Bon à savoir </b> : En général, les banques contrôlent les comptes des 3 derniers mois.
							</p>
						</div>
						
						<p>
							Conseil n°2 </br>
							<b>Un apport personnel sera le bienvenu.</b>
						</p>
						</br>
						<p>
							Un apport personnel jusqu’à 10% de la somme totale peut être un argument. En effet, cela appuie votre dossier, réduit vos mensualités et la somme totale à emprunter. Sachez donc vous construire une assise financière et mettre cet atout de bon gestionnaire en avant. D'ailleurs, l'article de financer.com donne de précieux <a target="_blank" href="https://financer.com/fr/guides/economiser-petit-salaire/"><u>conseils pour épargner chaque mois</u></a>, même avec de petits revenus.
						</p>
						
						<div class="my-10 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative">
							<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../../../assets/picto/ampoule.png" alt="">
							<p class="text-lightblueaestima-100 relative z-10">
								<b>Bon à savoir </b> : L’apport rassure aussi à la banque (si hypothèque) en lui permettant de  récupérer un bien valant plus que la somme prêtée. 
							</p>
							<p class="text-xs mt-1 text-lightblueaestima-100 relative z-10">(Si achat T2 d’une valeur 150 000€ avec emprunt de 120 000€, 30 000€ de marge de manœuvre pour la banque).</p>
						</div>
						
						<p>
							Conseil n°3 </br>
							<b>Votre profil : Plusieurs points forts.</b>
						</p>
						</br>
						<p>
							Sachez vous mettre en avant. Patrimoine varié, placement en épargne, aucun crédit en cours, propriétaire de votre résidence principale, situation financière et professionnelle stable… Beaucoup d’éléments comme ces derniers (et autres) peuvent être mis en avant.
							Pensez à tous les compléments de revenus dont vous bénéficiez. Ils font partie de vos revenus mensuels (pensions, revenus fonciers, revenus de placements financiers, etc.).
						</p>
					
						</br>
						<p>
							Conseil n°4 </br>
							<b>Votre dossier complet.</b>
						</p>
						</br>
						<p>
							Comme indiqué en amont, nous vous conseillons de rassembler l’ensemble des documents nécessaires, en anticipant la demande du banquier. Cependant, notez que les banques demandent les originaux (qui vous seront restitués après vérification).
						</p>
						
						<div class="my-10 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative">
							<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../../../assets/picto/ampoule.png" alt="">
							<p class="text-lightblueaestima-100 relative z-10">
								<b>Bon à savoir </b> : Pour chaque demande, préparez les copies ET les originaux.
							</p>
						</div>
						
						<p id="conclusion">
							<router-link target=’blank’ to=”/register”><u>L’outil Aestima vous permet d’éditer et de générer automatiquement votre <b>dossier bancaire</b> avec l’appui de votre simulation d’investissement immobilier</u></router-link>. Pensée avec des banquiers, cette fonctionnalité Aestima a été créée pour vous faciliter la tâche au maximum en rassemblant l’ensemble des informations et documents à mettre en avant dans votre <b>demande d’emprunt.</b>
						</p>
						
					</div>
					
					<div class="flex justify-center sm:my-20">
						<router-link target="_blank" to="/acces-simulateur" class="text-sm sm:text-lg font-semibold text-white px-12 py-3 bg-aestiblue-light rounded-lg aestima-button mt-20 sm:mt-0"> <h3>Simuler mon investissement et constituer mon dossier bancaire </h3></router-link>
					</div>
					
				</div>
			</div>
				


		</div>

	<Footer/>
</div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
export default {
	name: 'Strategy',
	data() {
		return {
			posBoxB: 0,
			posBox: 0,
			linkCopied: 'copier le lien',
		}
	},
	components: {
		Footer,
		Header
	},
	created() {
		window.addEventListener('scroll', this.calcPosOfBox());
    	
  	},
	destroy() {
		window.addEventListener('scroll', this.calcPosOfBox());
	},
	computed: {

	},
	methods: {
		calcPosOfBox() {
			const rendement_brut = document.getElementById("rendement_brut");
			// const rendement_b = this.$refs["rendement_b"].getBoundingClientRect();
			// this.posBoxB = rendement_brut.y;
			// this.posBox = rendement_b.y;
			// console.log(this.posBoxB)
			// console.log(this.posBox)
			// console.log("window.scrollY", window.scrollY)

		},
		clipboard() {
			// var copyText = window.location.href
			const copyText = document.createElement('textarea');
  			copyText.value = window.location.href;
			document.body.appendChild(copyText);
			copyText.select();
  			copyText.setSelectionRange(0, 99999);
			document.execCommand("copy");
			document.body.removeChild(copyText);
			this.linkCopied = "Lien copié"
		},
	},
	metaInfo(){
		return {
			title: "Projet immobilier : Comment monter LE dossier bancaire parfait - Aestima",
			meta: [{
				name: 'description',
				content: "Soyez stratégique pour monter un dossier bancaire. C’est la clé pour obtenir un prêt immobilier. Voici les astuces à connaître absolument !"
			}]
			//keywords
		}
	},
}
</script>

<style scoped>

.clipboard div{
	padding: 0.3rem 0.6rem 0.3rem 0.6rem;
	background: #0C1E3B;
	border-radius: 5px;
	color: white;
	font-size: 0.8rem;
	position: absolute;
	white-space: nowrap;
	top: 0;
	transition: 0.2s;
	transform: translateX(0);
	opacity: 0;
}

.clipboard:hover div {
	opacity: 1;
	transform: translateX(3rem);
}

.h-header {
	height: 100%;
}
.aestima-button {
	transition: 0.5s;
	background-color: #14A0EB;
}
/* 
video {
	position: relative;
	width: 100% !important;
	height: auto;
} */

.aestima-button:hover {
	background-color: #ffffff;
	border: 2px solid #14A0EB;
	color: #14A0EB;
	transform: scale(1);
}

.aestima-title-vw {
}

.aestima-par-vw {
}
/* Medium (md) */
@media (min-width: 768px) {
	.h-header {
		height: 50rem;
	}

	.aestima-title-vw {
		font-size: 2.8vw !important;
	}

	.aestima-par-vw {
		font-size: 1.2vw !important;
	}
}

.shadow-inner {
	box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);
}

/** SWITCH */

.switch {
  position: relative;
  display: inline-block;
  width: 10rem;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0C1E3B;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 0.2rem;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 0.2rem;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(7.8rem);
  -ms-transform: translateX(7.8rem);
  transform: translateX(7.8rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.smooth {
	scroll-behavior: smooth;
}

.ampoule-picto {
	top: -1rem;
	left: -1rem;
}

.bg-img-desc {
	background-color: rgba(20, 160, 235,0.6);
}

.round-tiret li {
	list-style: inside;
}

.round-tiret-check li {
	list-style: url('../../../assets/picto/aestima-tiret.png');
}

</style>

