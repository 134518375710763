<template>
  <div class="card-line flex gap-2 place-items-center">
      <img :src="img" class="preicon" alt="" />
      <p class="text-justify describe text-sm"><slot/></p>
  </div>
</template>

<script>
export default {
  name: "InfoLine",
  props: {
    img: { type: String },
  },
}
</script>

<style scoped>
.preicon{
  max-height: 36px;
  max-width: 34px;
}

.describe{
  padding-left: 10px;
}
</style>