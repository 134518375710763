<template>
  <div class="overflow-hidden flex gap-3 w-full" @click="clickInvestor(investor, 1)">
    <img
      v-if="investor.img" :src="investor.img.data" :alt="investor.name"
      class="flex-shrink-0 w-6 h-6 rounded-full"
    >
    <img
      v-else-if="investor.gender === 'homme'" src="../assets/picto/homme.png"
      alt="picto aestima bonhomme homme" class="flex-shrink-0 w-6 h-6 rounded-full"
    >
    <img
      v-else-if="investor.gender === 'societe'" src="../assets/picto/societe.png"
      alt="picto aestima societe" class="flex-shrink-0 w-6 h-6 rounded-full"
    >
    <img
      v-else src="../assets/picto/femme.png" alt="picto aestima bonhomme femme"
      class="flex-shrink-0 w-6 h-6 rounded-full"
    >
    <div class="overflow-hidden">
      <span v-if="investor.gender === 'societe'" class="block font-normal truncate text-left">
        {{ investor.forme }} {{ investor.nameSociete }}
      </span>
      <span v-else class="block truncate text-left">
        {{ investor.gender === 'homme' ? "Mr" : "Mme" }} {{ investor.name === undefined ? "" : investor.name }}<span
          v-if="investor.coInvestor"
        > et {{ investor.coGender === 'homme' ? "Mr" : "Mme" }} {{ investor.coName
          === undefined ? "" : investor.coName }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectInvestor',
  props: {
    investor: {
      type: Object,
      required: true,
    },
  },
  emits: ['clickInvestor'],
  methods: {
    clickInvestor(investor, index) {
      this.$emit('clickInvestor', investor, index)
    },
  },
}
</script>
