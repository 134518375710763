<template>
  <div class="relative w-full h-full p-4 mt-20 mb-5 gap-6 sm:px-10 border border-black">
      <h2 class="mt-8 mb-4 font-bold sm:m-0">Gestion des abonnements</h2>
      <div v-if="user.sub_level === 0">
        <div class="my-4 sm:m-0">
          <p class="mb-2">Choisissez votre offre d'abonnement Aestima : </p>
          <ul class="px-5">
            <li
              @click="updateSubSelected(sub)"
              :class="{ 'border-2 border-lightblueaestima-100': sub.selected === true }"
              class="flex flex-col items-start justify-start px-4 py-2 m-1 text-left rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200"
              v-for="sub in subSelected"
              :key="sub.id"
            >
              <p class="text-xs font-bold">{{ sub.name }}</p>
              <p class="text-sm">{{ sub.price }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <div class="my-4 sm:m-0">
          <p>Abonnement</p>
          <p v-if='user.sub_level==1' class="font-bold text-lightblueaestima-100">Initié</p>
          <p v-else class="font-bold text-lightblueaestima-100">Professionel</p>
          <p>Paiement</p>
          <p v-if="user.sub_period=='month'" class="font-bold text-lightblueaestima-100">Mensuel</p>
          <p v-else class="font-bold text-lightblueaestima-100">Annuel</p>
        </div>
      </div>
      <form v-if="user.sub_level == 0" class="mb-4 sm:m-0" @submit.prevent="toSubscibe()">
        <button
          v-if="subSelected.filter((sel) => sel.selected == true).length != 0"
          class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-sm group leading-5 bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out"
        >
          <p>
            S'abonner à l'offre <br />
            <b class="font-semibold">{{ subSelected.filter((sel) => sel.selected == true)[0].name }}</b>
          </p>
        </button>
        <button
          v-else
          disabled="disabled"
          class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-sm cursor-auto group leading-5 transition duration-150 ease-in-out"
        >
          S'abonner
        </button>
      </form>
      <form v-else class="w-4/5 mb-4 sm:w-1/2 sm:m-0" @submit.prevent="toManage()">
        <button
          class="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-sm group leading-5 bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out"
        >
          Gérer mon abonnement
        </button>
      </form>
      <svg
        v-if="modalPDFLoader == true"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="display: inline-block; shape-rendering: auto; margin-top: 1.4rem"
        width="50px"
        height="50px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="#14a0eb"
          stroke-width="8"
          r="25"
          stroke-dasharray="70.68583470577033 25.561944901923447"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1.6949152542372883s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';

let stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY),
  elements = stripe.elements(),
  card = undefined;

export default {
  name: 'Subscribe',
  data() {
    return {
      subSelected: [
        {
          id: process.env.VUE_APP_STRIPE_PRICE_ID_INI_MONTH,
          period: 'month',
          subLevel: 1,
          name: 'Initié mensuel',
          price: '39 €/mois HT',
          selected: false,
        },
        {
          id: process.env.VUE_APP_STRIPE_PRICE_ID_PRO_MONTH,
          period: 'month',
          subLevel: 2,
          name: 'Professionnel mensuel',
          price: '59 €/mois HT',
          selected: false,
        },
        {
          id: process.env.VUE_APP_STRIPE_PRICE_ID_INI_YEAR,
          period: 'year',
          subLevel: 1,
          name: 'Initié annuel',
          price: '348 €/an HT (soit 29€/mois)',
          selected: false,
        },
        {
          id: process.env.VUE_APP_STRIPE_PRICE_ID_PRO_YEAR,
          period: 'year',
          subLevel: 2,
          name: 'Professionnel annuel',
          price: '528 €/an HT (soit 44€/mois)',
          selected: false,
        },
      ],
    };
  },
  props: {},
  metaInfo() {
    return {
      title: `Mon Abonnement - Aestima`,
      meta: [
        {
          name: 'description',
          content: "Cette page est la page d'abonnement de l'utilisateur.",
        },
      ],
      //keywords
    };
  },
  components: {},
  created: function () {
    // axios.post("/session-un-sub", {userId: this.user._id})
    // .then(function(result) {
    // })
    // .catch(err => {
    // 		console.log("message 1",err)
    // })
  },
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
      user: 'users/user',
      listProj: 'projects/projects',
      stripePrice: 'users/stripePrice',
      modalPDFLoader: 'invests/modalPDFLoader',
    }),
    curentSub() {
      return this.subSelected.filter((sub) => this.user.priceId == sub.id);
    },
  },

  methods: {
    ...mapActions({
      signOut: 'users/logout',
      getProjects: 'projects/getProjects',
      setProjectSelected: 'projects/setProjectSelected',
      updateUser: 'users/updateUser',
      updateUserMDP: 'users/updateUserMDP',

      selectedStripePrice: 'users/setStripePriceSelected',
      setModalPDFLoader: 'invests/setModalPDFLoader',
    }),

    updateSubSelected(sub) {
      this.subSelected.map((val) => {
        return val.id == sub.id ? (val.selected = true) : (val.selected = false);
      });
    },

    deleteCheckoutSession(userId) {
      return axios
        .post('/delete-checkout-session', { userId: userId })
        .then(function (result) {
          return result.data;
        })
        .catch((err) => {
          console.log('message 1', err);
        });
    },

    async toManage() {
      this.setModalPDFLoader(true);
      return await axios
        .post('/create-customer-portal-session', { customerId: this.user.customerId })
        .then((result) => {
          this.setModalPDFLoader(false);
          window.location.href = result.data.url;
        })
        .catch((err) => {
          this.setModalPDFLoader(false);
          console.log('message 1', err.message);
        });
    },

    async toSubscibe() {
      this.setModalPDFLoader(true);
      let lePrice = this.subSelected.filter((val) => val.selected == true)[0].id;
      axios
        .post('/create-checkout-session', { customerId: this.user.customerId, price: lePrice })
        .then((res) => {
          this.setModalPDFLoader(false);
          stripe
            .redirectToCheckout({
              sessionId: res.data.id,
            })
            .then((handleResult) => {
              if (handleResult.error) {
                alert(handleResult.error.message);
              }
            });
        })
        .catch((err) => {
          this.setModalPDFLoader(false);
          console.log('erro message', err);
        });
    },
  },
};
</script>

<style scoped>
.shadow-inner {
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.08), 0 5px 15px 0 rgba(0, 0, 0, 0.12);
}

.box-billing {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 32px;
}

.box-border {
  border-radius: 4px;
  border: 1px solid rgb(49, 52, 62);
}

.box-bt {
  border-top: 1px solid rgb(49, 52, 62);
}

.mt-popup {
  margin-top: -65px;
}
</style>
