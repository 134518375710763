<template>
  <div>
    <SignIn />
  </div>
</template>

<script>
// @ is an alias to /src
import SignIn from '@/components/SignIn.vue'
import LeftSignin from '@/components/LeftSignin.vue'
import { mapGetters, mapActions } from "vuex"

export default {
  name: 'LogIn',
  data() {
		return {
			email: "",
      password: "",
      cssProps: {
        backgroundImage: `url(${require('@/assets/login_img.jpg')})`
      }
		}
  },
  metaInfo(){
		return {
			title: `Se connecter - Aestima`,
			meta: [{
				name: 'description',
				content: "Cette page permet de se connecter à l'outil d'analyse financière Aestima."
			}]
			//keywords
		}
	},
  components: {
    SignIn,
    LeftSignin
  },
    methods: {
		// ...mapActions("users", ["login"]),
	},
}
</script>

<style scoped>
.bg-overlay::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right,#0E1E3A,#0E1E3A);
    opacity: .6; 
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
</style>
