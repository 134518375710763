import axios from 'axios'
import router from '../router/index.js'

export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
    refreshToken: null,
    isStayConnected: false,
    isAuthFailed: {
      isFailed: false,
      message: null,
    },
    stripePrice: {
      id: 0,
      isTrial: false,
    },
    isTourLoading: false,
  },
  getters: {
    authenticated(state) {
      return state.token && state.user
    },
    user(state) {
      return state.user
    },
    authStatus(state) {
      return state.isAuthFailed
    },
    isStayConnected(state) {
      return state.isStayConnected
    },
    stripePrice(state) {
      return state.stripePrice
    },
    isTourLoading(state) {
      return state.isTourLoading
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_REFRESH_TOKEN(state, token) {
      state.refreshToken = token
    },
    SET_USER(state, data) {
      state.user = data
    },

    SET_USER_AUTH_FAILED(state, message) {
      state.isAuthFailed.isFailed = true
      state.isAuthFailed.message = message
    },
    SET_USER_STAY_CONNECTED(state) {
      state.isStayConnected = true
    },
    UNSET_USER_AUTH_FAILED(state) {
      state.isAuthFailed.isFailed = false
      state.isAuthFailed.message = ''
    },
    UNSET_STATE(state) {
      state.isAuthFailed.isFailed = false
      state.isAuthFailed.message = ''
    },
    UNSET_USER_STAY_CONNECTED(state) {
      state.isStayConnected = false
    },
    SET_USER_STRIPE_PRICE(state, priceId) {
      state.stripePrice.id = priceId.id
      state.stripePrice.isTrial = priceId.isTrial
    },
    SET_TOUR_LOADING(state, boolean) {
      state.isTourLoading = boolean
    },
  },
  actions: {
    async signUp({ dispatch, commit }, body) {
      await axios
        .post('/signup', body)
        .then((res) => {
          return resolve(res)
          // dispatch('attempt', {token: res.data.token,refresh_token: null})
          // .then(() => {
          // 	router.push("/Home")
          // })
        })
        .catch((error) => {
          commit('projects/SET_MODAL', error.response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 5000)
        })
    },

    async setPdfParams(
      { dispatch, commit, rootGetters },
      { idInvest, idSimu, idUser, params, retries = 5 },
    ) {
      console.log('Sending out PDF request', retries)
      const dateNow = new Date().getTime()
      let res

      try {
        const response = await axios.post(
          `/export/pdf_v2/${idSimu}/${idInvest}/${idUser}/${dateNow}`,
          params,
        )
        console.log('PDF Success :', response.data.url)
        window.open(response.data.url, '_blank')
        res = 'success'
      } catch (err) {
        console.log('PDF Error :', err)
        res = 'error'

        // Retry the request
        if (retries > 0) {
          console.log(`Retrying PDF params set. Remaining retries: ${retries - 1}`)
          await new Promise(resolve => setTimeout(resolve, 1000))
          res = await dispatch('setPdfParams', {
            idInvest,
            idSimu,
            idUser,
            params,
            retries: retries - 1,
          })
        } else {
          console.log('No more retries left.')
        }
      }
      return res
    },

    async contact({ dispatch, commit }, body) {
      await axios
        .post('/contact', body)
        .then(() => {})
        .catch((error) => {
          commit('projects/SET_MODAL', error.response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 5000)
        })
    },

    async login({ commit, dispatch, getters }, body) {
      const stayConnected = getters.isStayConnected
      await axios
        .post('/login', body)
        .then((response) => {
          if (stayConnected) {
            dispatch('attempt', {
              token: response.data.token,
              refresh_token: response.data.refreshToken,
            })
          } else {
            dispatch('attempt', { token: response.data.token, refresh_token: null })
          }
        })
        .catch((error) => {
          commit('SET_USER_AUTH_FAILED', error.response.data.message)
          setTimeout(() => {
            commit('UNSET_USER_AUTH_FAILED')
          }, 3000)
        })
    },

    async attempt({ dispatch, commit, state }, { token, refresh_token }) {
      // Set la date de dernière connexion de l'utilisateur
      // axios.patch(`/user/update/lastLogin/${id}`)
      // .then(tokens => {
      // 	// console.log("then attempt",  {token: tokens.data.token,refresh_token: tokens.data.refreshToken})
      // 	dispatch('attempt', {token: tokens.data.token,refresh_token: tokens.data.refreshToken})
      // })
      // .catch(err => {
      // 	localStorage.removeItem('token')
      // })

      try {
        if (token) {
          commit('SET_TOKEN', token)

          await axios
            .get('/whoami')
            .then((res) => {
              const user = res.data.user
              user.exportThisMonth = res.data.exportThisMonth
              commit('SET_REFRESH_TOKEN', refresh_token || null)
              dispatch('projects/getProjectsSummary', res.data.user._id, { root: true })
              dispatch('invests/getInvestsList', res.data.user._id, { root: true })
              commit('SET_USER', user)
              dispatch('getGlobals')
              dispatch('getTMI')
            })
            .catch(() => {
              commit('SET_TOKEN', null)
              if (refresh_token) {
                dispatch('attempt', { token: null, refresh_token })
              } else {
                commit('SET_REFRESH_TOKEN', null)
              }
            })
        } else {
          if (refresh_token) {
            await axios
              .post('/auth/refresh_token', { refreshToken: refresh_token })
              .then((tokens) => {
                commit('SET_TOKEN', tokens.data.token)
                commit('SET_REFRESH_TOKEN', tokens.data.refreshToken)
                axios
                  .get('/whoami')
                  .then((res) => {
                    const user = res.data.user
                    user.exportThisMonth = res.data.exportThisMonth
                    dispatch('projects/getProjectsSummary', res.data.user._id, { root: true })
                    dispatch('invests/getInvestsList', res.data.user._id, { root: true })
                    commit('SET_USER', user)
                    dispatch('getGlobals')
                    dispatch('getTMI')
                  })
                  .catch((err) => {
                    console.log('err refresh_token whoami ====>', err)
                  })
              })
              .catch((err) => {
                console.log('err refresh_token ====>', err)
                throw err
              })
          } else {
            commit('SET_TOKEN', null)
            commit('SET_REFRESH_TOKEN', null)
          }
        }
      } catch (e) {
        commit('SET_TOKEN', null)
        commit('SET_REFRESH_TOKEN', null)
        commit('SET_USER', null)
      }
    },

    async updateUser({ commit }, { id, user }) {
      await axios
        .patch(`/user/update/${id}`, user)
        .then((response) => {
          commit('projects/SET_MODAL', response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 3000)

          router.push('/Home')

          axios
            .get('/whoami')
            .then((res) => {
              const user = res.data.user
              user.exportThisMonth = res.data.exportThisMonth
              commit('SET_USER', user)
            })
            .catch((err) => {
              throw err
            })
        })
        .catch((e) => {
          throw e
        })
    },

    async updateCoworkers({ commit }, { id, coworkers }) {
      await axios
        .patch(`/user/coworkers/${id}`, coworkers)
        .then(() => {
          axios
            .get('/whoami')
            .then((res) => {
              const user = res.data.user
              commit('SET_USER', user)
            })
            .catch((err) => {
              throw err
            })
        })
        .catch((e) => {
          throw e
        })
    },

    async updateCoworkerLimit({ commit }, { id, coworkerLimit }) {
      const response = await axios.patch(`/user/coworkerLimit/${id}`, { coworkerLimit })
      const updatedUser = response.data
      return updatedUser
    },
    
    async updateLockPassword({ commit }, { id, lockPassword }) {
      await axios.patch(`/user/lock_password/${id}`, { lockPassword })
    },

    async updateUserMDP({ commit }, { id, password }) {
      await axios
        .patch(`/user/update/password/${id}`, password)
        .then((response) => {
          commit('projects/SET_MODAL', response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 3000)

          router.push('/Home')

          axios
            .get('/whoami')
            .then((res) => {
              const user = res.data.user
              user.exportThisMonth = res.data.exportThisMonth
              commit('SET_USER', user)
            })
            .catch((err) => {
              throw err
            })
        })
        .catch((e) => {
          throw e
        })
    },

    async updateUserTour({ commit }, { id, isTour }) {
      await axios
        .patch(`/user/update/tour/${id}`, { isTour })
        .then((response) => {
          const user = response.data.user
          user.exportThisMonth = response.data.exportThisMonth
          commit('SET_USER', user)
        })
        .catch((e) => {
          throw e
        })
    },

    async getGlobals({ dispatch }) {
      await axios
        .get('/globals')
        .then((res) => {
          dispatch('projects/setGlobals', res.data.globals, { root: true })
        })
        .catch((error) => {
          throw error
        })
    },

    async getTMI({ dispatch }) {
      await axios
        .get('/tmi/getAll')
        .then((res) => {
          const data = res.data.tmi
          const tmi = {
            0: {
              start: data['0'].start,
              end: data['0'].end,
            },
            11: {
              start: data['1'].start,
              end: data['1'].end,
            },
            30: {
              start: data['2'].start,
              end: data['2'].end,
            },
            41: {
              start: data['3'].start,
              end: data['3'].end,
            },
            45: {
              start: data['4'].start,
              end: data['4'].end,
            },
          }
          dispatch('projects/setTmi', tmi, { root: true })
        })
        .catch((error) => {
          throw error
        })
    },

    async sendRequestForgotPassword({ dispatch, commit }, { email }) {
      await axios
        .post('/auth/forgot_password', { email })
        .then((res) => {
          /// /console.log(res)
        })
        .catch((error) => {
          throw error
        })
    },

    async sendRequestResetPassword({ dispatch, commit }, { token, newPassword, verifyPassword }) {
      await axios
        .post('/auth/reset_password', {
          token,
          newPassword,
          verifyPassword,
        })
        .then((res) => {
          /// /console.log(res)
        })
        .catch((error) => {
          throw error.response.data.message
        })
    },

    async setStayConnected({ commit }) {
      commit('SET_USER_STAY_CONNECTED')
    },

    async unsetStayConnected({ commit }) {
      commit('UNSET_USER_STAY_CONNECTED')
    },

    async deleteUser({ dispatch, commit }, { userId }) {
      // console.log("userId ===>",userId)
      // return
      await axios
        .delete(`/user/${userId}`)
        .then((res) => {
          dispatch('logout').then(() => {
            router.push('/Home')
          })
        })
        .catch((error) => {
          commit('projects/SET_MODAL', error.response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 5000)
        })
    },

    async logout({ commit }) {
      commit('SET_TOKEN', null)
      commit('SET_REFRESH_TOKEN', null)
      commit('SET_USER', null)
      commit('UNSET_STATE')
      commit('projects/UNSET_STATE', null, { root: true })
      commit('invests/UNSET_STATE', null, { root: true })
      localStorage.removeItem('itemProject')
    },

    async setUserRefreshed({ commit }) {
      await axios
        .get('/whoami')
        .then((res) => {
          const user = res.data.user
          user.exportThisMonth = res.data.exportThisMonth
          commit('SET_USER', user)
        })
        .catch((error) => {
          console.log(error)
          throw error.response.data.message
        })
    },

    /** Set priceID selected on Pricing page */
    setStripePriceSelected({ commit }, priceId) {
      commit('SET_USER_STRIPE_PRICE', priceId)
    },

    setTourLoading({ commit }, boolean) {
      commit('SET_TOUR_LOADING', boolean)
    },
  },
}
