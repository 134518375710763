<template>
	<div class="mx-10">
		 <!-- <div class="w-full sm:w-4/4">
			<div class="flex flex-col text-left">
				<div class="-my-2 overflow-x-auto">
				<div class="py-2 align-middle inline-block min-w-full">
					<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead>
						<tr>
							<th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
							Date de création
							</th>
							<th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
							Nom de l’investisseur
							</th>
							<th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
							Email
							</th>
							<th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
							Téléphone
							</th>
							<th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
							Budget d'investissement
							</th>
							<th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
							Zone de recherche
							</th>
							<th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
							Nombre de simulations
							</th>
							<th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
							Date d'archivage
							</th>
							
						</tr>
						</thead>
						<tbody v-if="invests.length > 0" class="bg-white divide-y divide-gray-200">
						<tr v-for="inv in investsArchived" :key="inv._id">
							<td class="px-6 py-4 whitespace-no-wrap">{{inv.created | formatDate}}</td>
							<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{{inv.name}}</td>
							<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{{inv.mail}}</td>
							<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{{inv.phone}}</td>
							<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{{inv.search.search_budget}}</td>
							<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{{inv.search_pref}}</td>
							<td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">{{inv.name}}</td>
							<td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium flex flex-row justify-center items-center">{{inv.archive.archivedAt | formatDate}}</td>
						</tr>
						</tbody>
					</table>
					</div>
				</div>
				</div>
			</div>
			</div> -->
		
		<div>
			<div class="flex flex-row justify-start items-center mt-8">
				<div class="bg-lightblueaestima-100 p-1">
					<img class="w-8 h-auto" src="../assets/picto/aestima_tab_suivi.png" alt="Pictogramme archive">
				</div>
				<h2 class="text-xl font-bold ml-3">Tableau de suivi de mes dossiers archivés</h2>
			</div>
			<div class="my-10">
				<table class="table">
					<tr>
						<th class="font-bold text-sm">Date de création</th>
						<th class="font-bold text-sm">Nom de l’investisseur</th>
						<th class="font-bold text-sm">Email</th>
						<th class="font-bold text-sm">Téléphone</th>
						<th class="font-bold text-sm">Budget d'investissement</th>
						<th class="font-bold text-sm">Zone de recherche</th>
						<th class="font-bold text-sm">Nombre de simulations</th>
						<th class="font-bold text-sm">Date d'archivage</th>
					</tr>
					<tr>
						<tr v-for="inv in investsArchived" :key="inv._id">
							<td>{{inv.created | formatDate}}</td>
							<td>{{inv.name}}</td>
							<td>{{inv.mail == "" ? "Non précisé" : inv.mail}}</td>
							<td>{{inv.phone == "" ? "Non précisé" : inv.phone}}</td>
							<td>{{inv.search.search_budget}} €</td>
							<td>{{inv.search.search_no_pref == false ? inv.search.search_pref : 'Pas de préférence'}}</td>
							<td>{{inv.projects.length}}</td>
							<td>{{inv.archive.archivedAt | formatDate}}</td>
							<button @click="archive(inv._id)"
							class="font-normal text-xs bg-lightblueaestima-100 text-white px-1 py-1"
							>Enlever des archives</button>
						</tr>
					</tr>
				</table>
			</div>
		</div>
		
		<div>
			<div class="flex flex-row justify-start items-center">
				<div class="bg-lightblueaestima-100 p-1">
					<img class="w-8 h-auto" src="../assets/picto/aestima_suivi.png" alt="Pictogramme Suivi de performance">
				</div>
				<h2 class="text-xl font-bold ml-3">Suivi de vos performances</h2>
			</div>
			<div class=" gap-1 flex justify-around items-center mt-6 px-1/12">
				<div class="flex flex-row">
					<img class="h-28 w-auto" src="../assets/picto/aestima_archive_invest.png" alt="">
					<div class="flex flex-col justify-center items-center">
						<h3 class="text-left font-bold text-lg ">Nombre total d'investisseurs </br>  <span class="font-normal text-sm">enregistrés et archivés</span></h3>
						<p class="text-lightblueaestima-100 font-bold text-3xl">{{investsArchived.length}}</p>
					</div>
				</div>
				<div class="flex flex-row">
					<img class="h-28 w-auto" src="../assets/picto/aestima_archive_simulation.png" alt="">
					<div class="flex flex-col justify-center items-center">
						<h3 class="text-left font-bold text-lg ">Nombre total de simulations </br>  <span class="font-normal text-sm">d’investissements, enregistrées et archivées</span></h3>
						<p class="text-lightblueaestima-100 font-bold text-3xl">{{investsArchivedProjects}}</p>
					</div>
				</div>
				<!-- <div class="col-span-12 sm:col-start-9 sm:col-end-13 flex flex-row">
					<img class="h-28 w-auto" src="../assets/picto/aestima_archive_cumul.png" alt="">
					<div class="flex flex-col justify-center items-center">
						<h3 class="text-left font-bold text-lg ">Cumul des budgets d’investissements </br>  <span class="font-normal text-sm">de vos clients investisseurs archivés</span></h3>
						<p class="text-lightblueaestima-100 font-bold text-3xl">{{cumulBudgetInvest}} €</p>
					</div>
				</div> -->
			</div>
		</div>
		
		
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import moment from 'moment'

export default {
	name: 'Archive',
	data() {
		return {
			STATIC_URL: process.env.VUE_APP_API_HOSTNAME_DEV
		}
	},
	props: {
	},
	metaInfo(){
		return {
			title: "Mes dossiers archivés - Aestima",
			meta: [{
				name: 'description',
				content: "Cette page liste les dossiers archivés de l'utilisateur."
			}]
			//keywords
		}
	},
	components: {
	},
	created: function() {
		////console.log(process.env.VUE_APP_API_HOSTNAME_LOC)
	},
	filters: {
		formatDate: function (value) {
			 if (value) {
				return moment(String(value)).format('DD/MM/YYYY hh:mm')
			}
		}
	},
	computed: {
		...mapGetters({
			authenticated: 'users/authenticated',
			user: 'users/user',
			invests: 'invests/invests',
		}),
		investsArchived() {
			return this.invests.filter((val) => {
				return val.archive.isArchived == true
			})
		},
		investsArchivedProjects() {
			let projects = 0
			this.invests.map((val) => {
				if(val.archive.isArchived == true){
					projects += val.projects.length
				}
			})
			return projects
		},
		cumulBudgetInvest() {
			let budget = 0
			this.invests.map((val) => {
				if(val.archive.isArchived == true){
					budget += Number(val.search.search_budget)
				}
			})
			return budget
		}
	},
	methods: {
		...mapActions({
			setProjectSelected: 'projects/setProjectSelected',
			archiveInvest: 'invests/archiveInvest',
		}),
		archive(archive){
			this.archiveInvest({
				idInvest: archive,
				boolean: false,
			})
		},
		
	},
}
</script>
<style scoped>
table td, table th {
	border: 1px solid #000000;
  	padding: 8px;
}
table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #14A0EB;
  color: white;
}
</style>
