<template>
  <div class="relative sm:w-1/3 hidden sm:flex flex-col justify-between items-start px-10 py-6 bg-cover bg-repeat bg-center rounded-r-3xl bg-overlay" :style="cssProps">
	  <a href="/" class="z-10 whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150">
        Retour
      </a>
      <h2 v-if="currentPage == 'LogIn'" class="z-10 text-left text-white text-6xl font-bold leading-none">Accès <br/> à votre <br/> simulateur</h2>
      <h2 v-else-if="currentPage == 'ForgotPassword'" class="z-10 text-left text-white text-6xl font-bold leading-none">Mot <br/> de passe <br/> oublié ?</h2>
      <h2 v-else-if="currentPage == 'ResetPassword'" class="z-10 text-left text-white text-6xl font-bold leading-none">Réinitialiser <br/> votre <br/> Mot de passe</h2>
      <div class="z-10 flex flex-row justify-evenly w-1/3">
        <a target="_blank" href="https://www.facebook.com/meryl.aestima/" class="mr-2 hover:bg-black hover:border-black border-solid border border-white rounded-full p-2">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="24" height="24"
          viewBox="0 0 172 172"
          style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M125.59583,64.5h-25.2625v-14.33333c0,-7.396 0.602,-12.05433 11.2015,-12.05433h13.38733v-22.79c-6.5145,-0.67367 -13.06483,-1.00333 -19.62233,-0.989c-19.44317,0 -33.63317,11.87517 -33.63317,33.67617v16.4905h-21.5v28.66667l21.5,-0.00717v64.50717h28.66667v-64.5215l21.973,-0.00717z"></path></g></g></svg>
        </a>
        <a target="_blank" href="https://www.instagram.com/aestima_immo/" class="mr-2 hover:bg-black hover:border-black border-solid border border-white rounded-full p-2">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="24" height="24"
          viewBox="0 0 172 172"
          style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M59.125,17.91667c-22.72192,0 -41.20833,18.48642 -41.20833,41.20833v53.75c0,22.72192 18.48642,41.20833 41.20833,41.20833h53.75c22.72192,0 41.20833,-18.48642 41.20833,-41.20833v-53.75c0,-22.72192 -18.48642,-41.20833 -41.20833,-41.20833zM121.83333,43c3.95958,0 7.16667,3.20708 7.16667,7.16667c0,3.956 -3.20708,7.16667 -7.16667,7.16667c-3.95958,0 -7.16667,-3.21067 -7.16667,-7.16667c0,-3.95958 3.20708,-7.16667 7.16667,-7.16667zM86,50.16667c19.7585,0 35.83333,16.07483 35.83333,35.83333c0,19.7585 -16.07483,35.83333 -35.83333,35.83333c-19.7585,0 -35.83333,-16.07483 -35.83333,-35.83333c0,-19.7585 16.07483,-35.83333 35.83333,-35.83333zM86,60.91667c-13.85314,0 -25.08333,11.23019 -25.08333,25.08333c0,13.85314 11.23019,25.08333 25.08333,25.08333c13.85314,0 25.08333,-11.23019 25.08333,-25.08333c0,-13.85314 -11.23019,-25.08333 -25.08333,-25.08333z"></path></g></g></svg>
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/aestima-immo/" class="hover:bg-black hover:border-black border-solid border border-white rounded-full p-2">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="23" height="23"
          viewBox="0 0 172 172"
          style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M139.75,0h-107.5c-17.80469,0 -32.25,14.44531 -32.25,32.25v107.5c0,17.80469 14.44531,32.25 32.25,32.25h107.5c17.80469,0 32.25,-14.44531 32.25,-32.25v-107.5c0,-17.80469 -14.44531,-32.25 -32.25,-32.25zM53.18149,146.00361h-26.71996l-0.15504,-80.00481h26.71995zM39.14964,55.53305h-0.15505c-8.73437,0 -14.36779,-6.02103 -14.36779,-13.51502c0,-7.67488 5.81431,-13.48919 14.70373,-13.48919c8.88942,0 14.34194,5.8143 14.52283,13.48919c0,7.49399 -5.65926,13.51502 -14.70372,13.51502zM145.82272,146.00361h-26.95253v-43.46515c0,-10.51743 -2.79087,-17.67548 -12.19712,-17.67548c-7.18389,0 -11.0601,4.83233 -12.92067,9.50962c-0.69772,1.67969 -0.8786,3.97957 -0.8786,6.33113v45.29988h-27.08173l-0.15505,-80.00481h27.08173l0.15505,11.29267c3.46274,-5.34916 9.22536,-12.92067 23.02463,-12.92067c17.10697,0 29.89844,11.16346 29.89844,35.17007v46.46274z"></path></g></g></svg>
        </a>
      </div>
  </div>
</template>

<script>
export default {
	name: 'LeftSignin',
	data() {
		return {
			cssProps: {
				backgroundImage: `url(${require('@/assets/login_img.jpg')})`
			}	
		}
	},
	computed: {
		currentPage() {
			return this.$route.name
		}
	},
	methods: {
		
	},
}
</script>

<style scoped>
.bg-overlay::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right,#0E1E3A,#0E1E3A);
    opacity: .6;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}
</style>