<template>
  <div class="bg flex flex-col">
    <button class="button-blue my-4 place-self-end mr-8 sticky" @click="displayModalExportPdf = true">
      Exporter en PDF
    </button>
    <div v-if="displayModalExportPdf" id="modaleExportPdf" class="flex top-0 fixed w-full z-50 h-screen overflow-auto">
      <div class="fixed w-screen  h-full bg-gray-300 bg-opacity-75 z-10" />
      <div v-if="loadingPdf" class="bg-white absolute top-20 left-1/2 transform -translate-x-1/2">
        <img src="../assets/icons/blue_spinner.gif">
      </div>
      <div
        v-else
        class="absolute my-20 z-20 flex overflow-auto flex-col bg border border-gray-100 shadow-md px-12 py-10 rounded-xl gap-4 left-1/2 transform -translate-x-1/2 "
        style="width: 700px;"
      >
        <button
          class="absolute top-2 right-2 w-8 h-8 rounded-full bg-white text-lightblueaestima-100 font-bold border-2 border-lightblueaestima-100 flex items-center justify-center hover:text-white hover:bg-red-400"
          @click="displayModalExportPdf = false"
        >
          X
        </button>
        <h1 class="text-2xl font-bold mb-10">
          Informations à saisir avant votre export
        </h1>
        <form class="flex flex-col gap-7 text-left" @submit.prevent="exportToPdf">
          <div v-if="user.coworkerLimit && user.coworkers.length > 0" class="flex flex-col gap-2">
            <div class="flex flex-col gap-3">
              <label class="font-bold">
                1. Collaborateur en charge de la comparaison
              </label>
              <PDropdown
                v-model="pdfExporter" :options="user.coworkers" option-label="name"
                placeholder="Selectionnez un collaborateur" class="w-full"
              >
                <template #value="slotProps">
                  <div v-if="pdfExporter" class="flex gap-2">
                    <div
                      id="pastille" slot="trigger" :value="slotProps.value.color" readonly
                      class="form__input__element flex items-center justify-center w-10 h-10 mr-4 rounded-full shadow-md cursor-pointer"
                      :style="{ backgroundColor: slotProps.value.color }"
                    >
                      <div class="font-bold leading-none text-white">
                        {{ initials(slotProps.value.name) }}
                      </div>
                    </div>
                    <div class="my-auto font-semibold">
                      {{ slotProps.value.name }}
                    </div>
                  </div>
                  <div v-else>
                    {{ slotProps.placeholder }}
                  </div>
                </template>
                <template #option="slotProps">
                  <div class="flex gap-2">
                    <VSwatches v-model="slotProps.option.color">
                      <div
                        id="pastille" slot="trigger" :value="slotProps.option.color" readonly
                        class="form__input__element flex items-center justify-center w-10 h-10 mr-4 rounded-full shadow-md cursor-pointer"
                        :style="{ backgroundColor: slotProps.option.color }"
                      >
                        <div class="font-bold leading-none text-white">
                          {{ initials(slotProps.option.name) }}
                        </div>
                      </div>
                    </VSwatches>
                    <span class="my-auto rounded-md px-1 py-0.5">{{ slotProps.option.name }}</span>
                  </div>
                </template>
              </PDropdown>
            </div>
          </div>
          <div v-else />
          <div class="flex flex-col gap-3">
            <label class="font-bold">
              {{ 1 + (user.coworkerLimit && user.coworkers.length > 0) }}. Description du PDF
            </label>
            <textarea
              v-model="pdfDescription" type="text"
              class="rounded-lg text-black placeholder-gray-500 p-3 h-40 border border-gray-300" maxlength="150"
              :placeholder="placeholderDescription"
            />
            <div class="flex place-self-end" :class="pdfDescription.length === 150 ? 'text-red-600' : ''">
              {{ pdfDescription.length }} / 150
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <label class="font-bold">
              {{ 2 + (user.coworkerLimit && user.coworkers.length > 0) }}. Nom du PDF
            </label>
            <input
              v-model="pdfName" type="text"
              class="rounded-lg placeholder-gray-500 text-black p-3 border border-gray-300 h-12"
              :placeholder="placeholderName"
            >
          </div>
          <button type="submit" class="button-blue border border-black font-bold flex place-content-center gap-3">
            <DownloadIcon />
            Télécharger la comparaison en PDF
          </button>
        </form>
      </div>
    </div>

    <div ref="divToExport" :class="exportingToPdf === true ? 'Exported-main-div' : 'Main-div'">
      <div :class="exportingToPdf ? 'flex flex-col' : 'hidden'" class="Page overflow-hidden relative couleur-client">
        <img class="absolute ville z-0 left-0 top-0" src="../assets/pdf/fontPdf.jpg">
        <div class="absolute w-32 h-32 pt-4 flex flex-col place-items-center z-20 top-0 left-32">
          <img class="object-scale-down logo w-24 h-24 hidden" :src="base64Logo">
          <div class="z-40 text-center entreprise couleur-client hidden">
            <!-- <svg viewBox="0 0 150 30"> -->
            <div class="text-base">
              {{ user.info_society }}
            </div>
            <!-- </svg> -->
          </div>
        </div>
        <img class="absolute z-0 w-full h-full left-0 top-0" src="../assets/pdf/bg_p1_half.png">
        <!-- <div id="empty" class="z-20 w-full empty" /> -->
        <div class="absolute right-0 bottom-96 z-30 pr-8 text-right">
          <div class=" bigValue-text uppercase">
            Comparaison <br>de simulations
          </div>
          <div class="z-20 mt-6 text-2xl text-end">
            Pour le projet locatif de
            <span v-if="investSelected.gender !== 'societe'">{{ investSelected.name }} {{ investSelected.firstname
            }}</span>
            <span v-else>{{ investSelected.forme }} {{ investSelected.nameSociete }}</span>
            <span
              v-if="investSelected.gender !== 'societe' && investSelected.coInvestor && (investSelected.coName || investSelected.coPrenom)"
            >
              et de
              {{ investSelected.coName }}
              {{ investSelected.coPrenom }}</span>
          </div>
        </div>
        <div class="absolute z-20 flex justify-end px-8 text-base text-center gap-7 place-items-center bottom-0 pb-10">
          <div class="w-full">
            <div v-if="pdfExporter">
              Rapport réalisé par {{ pdfExporter ? pdfExporter.name : `${user.name} ${user.firstname}` }} <br>
              <div v-if="pdfExporter.email">
                {{ pdfExporter.email }}
              </div>
              <div v-if="pdfExporter.phone">
                {{ pdfExporter.phone }}
              </div>
              Le {{ new Date().toLocaleDateString('fr-FR') }}
            </div>
            <div v-else>
              Rapport réalisé par {{ user.name }} {{ user.firstname }} <br>
              <div v-if="user.email">
                Email: {{ user.email }}
              </div>
              <div v-if="user.info_phone">
                Téléphone: {{ user.info_phone }}
              </div>
              Le {{ new Date().toLocaleDateString('fr-FR') }}
            </div>
          </div>
          <div class="separator" />
          <div class="w-full text-justify">
            {{ pdfDescription }}
          </div>
        </div>
      </div>

      <div :class="{ Page: exportingToPdf }" class="flex flex-col relative">
        <img :src="base64Logo" class="Exported-logo hidden" :class="exportingToPdf ? '' : 'hidden'">
        <div :class="exportingToPdf ? 'Exported-card' : 'Card'">
          <div class="flex flex-row place-items-center">
            <img src="@/assets/icons/faq15.png" alt="logo" :class="exportingToPdf ? 'Exported-img' : 'Img'">
            <div>
              <h1 class="Titre">
                Comparaison générale des principales variables
              </h1>
            </div>
          </div>
          <table class="w-full" :class="exportingToPdf ? 'Exported-table' : 'Table'">
            <thead>
              <tr>
                <th class="px-4 py-2" />
                <th v-for=" item in simulations " :key="item.id" class="Headers">
                  {{ item.nom }}
                </th>
              </tr>
            </thead>
            <tbody v-for=" item in compGeneralArray " :key="item.id">
              <tr>
                <th class="Label flex flex-row gap-2">
                  {{ item.title }}
                  <QuestionMarkv2 v-if="item.infobulle && !exportingToPdf" :text="item.infobulle" />
                </th>
                <td
                  v-for="(i, index) in item.values " :key="index" class="Cell"
                  :class="item.important ? 'Important' : ''"
                >
                  {{ i }}
                </td>
              </tr>
              <tr v-if="item.lineAfter" class="h-6">
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div :class="{ Page: exportingToPdf }" class="flex flex-col gap-10 relative">
        <img :src="base64Logo" :class="exportingToPdf ? '' : 'hidden'" class="Exported-logo hidden">
        <div :class="exportingToPdf ? 'Exported-card' : 'Card'">
          <div class="flex flex-row place-items-center">
            <img src="@/assets/icons/faq17.png" alt="logo" :class="exportingToPdf ? 'Exported-img' : 'Img'">
            <div>
              <h1 class="Titre">
                Rentabilité, enrichissement et trésorerie des projets
              </h1>
            </div>
          </div>
          <table class="w-full" :class="exportingToPdf ? 'Exported-table' : 'Table'">
            <thead>
              <tr>
                <th class="px-4 py-2" />
                <th v-for=" item in simulations " :key="item.id" class="Headers">
                  {{ item.nom }}
                </th>
              </tr>
            </thead>
            <tbody v-for=" item in rentabiliteArray " :key="item.id">
              <tr>
                <th class="Label">
                  {{ item.title }}
                </th>
                <td
                  v-for="(i, index) in item.values " :key="index" class="Cell"
                  :class="item.important ? 'Important' : ''"
                >
                  {{ i }}
                </td>
              </tr>
              <tr v-if="item.lineAfter" class="h-5">
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div>

        <div :class="exportingToPdf ? 'Exported-card' : 'Card'">
          <div class="flex flex-row place-items-center">
            <img src="@/assets/icons/faq19.png" alt="logo" :class="exportingToPdf ? 'Exported-img' : 'Img'">
            <div>
              <h1 class="Titre">
                Fiscalité des projets
              </h1>
            </div>
          </div>
          <table class="w-full" :class="exportingToPdf ? 'Exported-table' : 'Table'">
            <thead>
              <tr>
                <th class="px-4 py-2" />
                <th v-for=" item in simulations " :key="item.id" class="Headers">
                  {{ item.nom }}
                </th>
              </tr>
            </thead>
            <tbody v-for=" item in fiscaliteArray " :key="item.id">
              <tr>
                <th class="Label">
                  {{ item.title }}
                </th>
                <td
                  v-for="(i, index) in item.values " :key="index" class="Cell"
                  :class="item.important ? 'Important' : ''"
                >
                  {{ i }}
                </td>
              </tr>
              <tr v-if="item.lineAfter" class="h-5">
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import html2pdf from 'html2pdf.js'
import DownloadIcon from '@/components/SVG/DownloadIcon.vue'
import LockIcon from '@/components/SVG/LockIcon.vue'
import QuestionMarkv2 from '@/components/SVG/QuestionMarkv2.vue'

export default {
  name: 'Comparator',
  components: {
    QuestionMarkv2,
    DownloadIcon,
    LockIcon,
  },
  props: {
  },
  data() {
    return {
      STATIC_URL: process.env.VUE_APP_API_HOSTNAME_DEV,
      svgColor: '#cccccc',
      compGeneralArray: [
        { title: 'Ville d\'investissement', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Surface (en m2)', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Coût total d\'acquisition', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Prix au m2 net', values: [], important: false, lineAfter: true },
        { title: 'Loyers mensuels H.C.', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Charges mensuelles', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Estimation totale des travaux', values: [], important: false, lineAfter: true },
        { title: 'Durée de détention', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Prix de revente', values: [], important: false, lineAfter: true },
        { title: 'Type d\'exploitation retenu', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Régime fiscal retenu', values: [], important: false, lineAfter: true },
        { title: 'Profit total généré', values: [], important: false, lineAfter: false, infobulle: 'Pour expliquer simplement à votre client de quoi est composé le profit, il suffit de faire : Somme des loyers durant toute la durée de détention + prix de revente de l’investissement – prix d’achat frais compris - somme des charges locatives – sommes des charges d’emprunt – imposition totale du projet. Le profit est aussi égal au prix de revente auquel on soustrait l’effort d’épargne fourni tout au long du projet.' },
        { title: 'Effort d\'épargne total', values: [], important: false, lineAfter: true, infobulle: 'Somme de tous les flux annuels de trésorerie négatifs. On ne tient pas compte des flux positifs qui s’en suivent pour faire prendre conscience au client de l’effort à fournir nécessaire avant de récupérer le premier euro de son projet.' },
        { title: 'Cash-flow mensuel', values: [], important: true, lineAfter: false, infobulle: 'Si l’emprunt est de 20 ans  la  . Nous obtenons le cash-flow moyen annuel. On redivise cette donnée par 12 pour avoir le cash-flow mensuel.' },
        { title: 'Rentabilité nette après impôts', values: [], important: true, lineAfter: false, infobulle: '' },
      ],
      compLocatifrray: [
        { title: 'Ville du bien locatif sélectionné' },
        { title: 'Type de biens' },
        { title: 'Surface des biens analysés' },
        { title: 'Loyer mensuel pris en compte' },
        { title: 'Prix des loyers moyens par type de biens' },
        // {title: "Rendement brut moyen"},
        // {title: "Tension locative (basée sur 100%)."},
        // {title: "Population active et étudiante"},
      ],
      rentabiliteArray: [
        { title: 'Rentabilité nette après impositon', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Profit total généré', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Flux de trésorie moyen', values: [], important: false, lineAfter: false, infobulle: '' },
      ],
      fiscaliteArray: [
        { title: 'Régime fiscal retenu', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Type d\'exploitation retenu', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Economie fiscale réalisée', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Fiscalité sur le revenu locatif', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Imposition sur la plus value de revente', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Imposition sur les dividendes', values: [], important: false, lineAfter: false, infobulle: '' },
        { title: 'Imposition totale des projets', values: [], important: true, lineAfter: false, infobulle: '' },
      ],
      base64Logo: '',
      simulations: [],
      loaderInsee: false,
      exportingToPdf: false,
      pdfExporter: null,
      placeholderDescription: 'Présentation de différentes simulations réalisées pour le client avec comparaison des principaux ratios financiers (rentabilité, effort d\'épargne, fiscalité, régime fiscal, etc.)',
      placeholderName: 'Comparaison',
      displayModalExportPdf: false,
      pdfName: '',
      pdfDescription: '',
      loadingPdf: false,
    }
  },
  metaInfo() {
    return {
      title: 'Comparaison des simulations - Aestima',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Cette page décrit les dossiers en cours de l\'utilisateur.',
      }],
      // keywords
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
      user: 'users/user',
      listProj: 'projects/projects',
      listInv: 'invests/invests',
      ProjectComparison: 'projects/project_comparison',
      investSelected: 'invests/selected',
    }),

  },
  async created() {
    // console.log(process.env.VUE_APP_API_HOSTNAME_LOC)
    console.log(this.investSelected)
    this.simulations = this.ProjectComparison
    for (const simulation of this.simulations) {
      this.compGeneralArray[0].values.push(simulation.cityName)
      this.compGeneralArray[1].values.push(`${simulation.surface} m2`)
      this.compGeneralArray[2].values.push(`${simulation.coutTotalAcquisition.toLocaleString('FR-fr')} €`)
      this.compGeneralArray[3].values.push(`${Math.round(simulation.prixm2Net).toLocaleString('FR-fr')} €`)
      this.compGeneralArray[4].values.push(`${simulation.loyerMensuel.toLocaleString('FR-fr')} €`)
      this.compGeneralArray[5].values.push(`${Math.round(simulation.chargesMensuelles / 12).toLocaleString('FR-fr')} €`)
      this.compGeneralArray[6].values.push(`${simulation.estimationTotaleTravaux.toLocaleString('FR-fr')} €`)
      this.compGeneralArray[7].values.push(`${simulation.dureeDetention} ans`)
      this.compGeneralArray[8].values.push(`${simulation.prixRevente.toLocaleString('FR-fr')} €`)
      this.compGeneralArray[9].values.push(this.capitalizeWords(simulation.typeExploitation.replace('_', ' ')))
      this.compGeneralArray[10].values.push(simulation.regimeFiscal)
      this.compGeneralArray[11].values.push(`${Math.round(simulation.profitTotal).toLocaleString('FR-fr')} €`)
      this.compGeneralArray[12].values.push(`${Math.abs(simulation.effortTotal).toLocaleString('FR-fr')} €`)
      this.compGeneralArray[13].values.push(`${simulation.cashFlowMensuel.toLocaleString('FR-fr')} €`)
      this.compGeneralArray[14].values.push(`${simulation.rentabiliteApresImpots}%`) // ?  apres ou avant ?

      this.rentabiliteArray[0].values.push(`${simulation.rentabiliteApresImpots}%`)
      this.rentabiliteArray[1].values.push(`${Math.round(simulation.profitTotal).toLocaleString('FR-fr')} €`)
      this.rentabiliteArray[2].values.push(`${simulation.cashFlowMensuel.toLocaleString('FR-fr')} €`)

      this.fiscaliteArray[0].values.push(simulation.regimeFiscal)
      this.fiscaliteArray[1].values.push(this.capitalizeWords(simulation.typeExploitation.replace('_', ' ')))
      this.fiscaliteArray[2].values.push(`${simulation.economieFiscaleTotale} €`)
      this.fiscaliteArray[3].values.push(`${Math.abs(Math.round(simulation.impotRevenuLocatif)).toLocaleString('FR-fr')} €`)
      this.fiscaliteArray[4].values.push(`${Math.abs(Math.round(simulation.impotSurPlusValue)).toLocaleString('FR-fr')} €`)
      this.fiscaliteArray[5].values.push(`${Math.abs(Math.round(simulation.impotSurDividendes)).toLocaleString('FR-fr')} €`)
      this.fiscaliteArray[6].values.push(`${Math.abs(Math.round(simulation.impositionTotale)).toLocaleString('FR-fr')} €`)
    }
    this.simulations.forEach((simulation) => {
      this.placeholderName += ` ${simulation.nom} -`
    })
    // this.base64Logo = await this.getBase64ImagePromise(this.user.customLogo)
    //  if (this.base64Logo.startsWith('data:application/octet-stream')) {
    //    this.base64Logo = this.base64Logo.replace('data:application/octet-stream', 'data:image/png')
    //  }
  },
  methods: {
    ...mapActions({
      signOut: 'userslogout',
      getProjects: 'projects/getProjects',
      setProjectSelected: 'projects/setProjectSelected',
      setInvestSelected: 'invests/setInvestSelected',
    }),
    initials(name) {
      if (!name) return ''
      const first = name.split(' ')[0].toUpperCase()[0]
      if (!name.split(' ')[1]) return first
      const second = name.split(' ')[1].toUpperCase()[0]
      return `${first}${second}`
    },
    async getBase64Image(imgUrl) {
      try {
        const response = await axios.get(imgUrl, {
          responseType: 'blob',
          headers: {
          },
        })
        const reader = new FileReader()
        reader.readAsDataURL(response.data)
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            resolve(reader.result)
          }
          reader.onerror = reject
        })
      } catch (error) {
        console.error('Error getting image as Base64:', error)
        throw error
      }
    },
    async getBase64ImagePromise(img) {
      return new Promise((resolve, reject) => {
        this.getBase64Image(img, (base64) => {
          resolve(base64)
        })
      })
    },
    async exportToPdf() {
      this.loadingPdf = true
      this.exportingToPdf = true
      if (this.pdfName === '') {
        this.pdfName = this.placeholderName
      }
      if (this.pdfDescription === '') {
        this.pdfDescription = this.placeholderDescription
      }
      this.$nextTick(() => {
        const divToExport = this.$refs.divToExport

        if (!divToExport) return

        const opt = {
          filename: this.pdfName,
          image: { type: 'jpg', quality: 0.3 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }
        const contentClone = divToExport.cloneNode(true)

        html2pdf().from(contentClone).set(opt).save()
        this.exportingToPdf = false
      })
      // attendre que le pdf soit généré pour remettre le loader à false
      setTimeout(() => {
        this.loadingPdf = false
        this.displayModalExportPdf = false
      }, 2000)
    },
    numberWithSpaces(x) {
      if (isNaN(x) || x === undefined) return 0
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    },
    capitalizeWords(str) {
      const words = str.split(' ')

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1)
      }

      return words.join(' ')
    },
  },
}
</script>

<style scoped>
.Titre {
  @apply text-xl font-bold text-left
}

.Img {
  @apply w-40
}

.Exported-img {
  @apply w-20
}

.Table {
  @apply mt-10 table-fixed
}

.Exported-table {
  @apply table-fixed
}

.Card {
  min-width: 700px;
  @apply w-full p-5 bg-white border border-gray-200 rounded-md
}

.Exported-card {
  @apply w-full px-5
}

.Page {
  width: 210mm;
  height: 297mm;
  overflow: hidden;
  @apply p-10 overflow-hidden
}

.Cell {
  @apply px-4 py-2 text-center
}

.Label {
  @apply px-4 py-2 text-left
}

.Headers {
  @apply px-4 py-2 bg-darkblueaestima-500 text-white
}

.Important {
  @apply bg-darkblueaestima-500 text-white border-none font-bold
}

.Main-div {
  @apply flex flex-col px-32 pb-10 gap-10
}

.Exported-main-div {
  font-size: 70%;
  @apply flex flex-col
}

.button-blue {
  @apply bg-lightblueaestima-100 rounded text-white shadow-md border-2 border-lightblueaestima-100 px-2 py-1;
}

.button-blue:hover {
  @apply scale-110;
}

.button-white {
  @apply bg-white rounded-xl text-lightblueaestima-100 font-bold shadow-md border-2 border-lightblueaestima-100 px-2 py-1;
}

.button-white:hover {
  @apply bg-lightblueaestima-500 text-white border-white;
}

.Exported-logo {
  @apply bottom-4 h-16 z-50 absolute left-1/2 transform -translate-x-1/2;
}

.ville {
  width: 100%;
  height: auto;
  /* object-fit: contain; */
  top: -120px;
}

.entreprise {
  font-weight: 700;
  font-size: 1.5vw;
}

.couleur-client {
  color: #26364e;
}

.bigValue-text {
  font-size: 2.5rem;
  font-weight: 900;
}

.separator {
  background-color: #26364e;
  width: 6px;
  height: 8rem;
}
</style>
