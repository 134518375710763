<template>
	<div
		id="defaultModal"
		tabindex="-1"
		aria-hidden="true"
		class="overflow-y-auto overflow-x-hidden mx-14 fixed right-0 left-0 z-50 inset-8 justify-center items-center flex"
	>
	<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
		<div class="relative my-auto px-4 h-full md:h-auto">
			<div
				class="relative bg-white rounded-xl dark:bg-gray-700 border-gray-400 border-2"
			>
				<div class="flex justify-center items-center p-9">
					<h3 class="font-bold text-black" style="font-size: 30px">
						Tableau d'amortissement d'emprunt par annuités constantes
					</h3>
					<div @click="close" class="cursor-pointer absolute right-10 text-5xl bg-red-500 rounded-lg h-10 w-10 flex place-content-center place-items-center hover:bg-red-800 focus:outline-none focus:shadow-outline-indigo transition ease-in-out duration-150">&#215;</div>
				</div>

				<div class="border-solid border-2 border-darkblueaestima-500 rounded-2xl mx-8">
					<div class="pz-6 pb-0 overflow-y-auto overflow-x-auto">
						<div
							class="grid grid-cols-7 px-5 space-x-6 bg-darkblueaestima-500 rounded-lg text-white text-sm place-items-center"
						>
							<div class="col-span-1">
								<p class="head-text">N° de période</p>
							</div>
							<div class="col-span-1">
								<p class="head-text">Capital début de période</p>
							</div>
							<div class="col-span-1">
								<p class="head-text">Annuités constantes</p>
							</div>
							<div class="col-span-1"><p class="head-text">Part des intérêts</p></div>
							<div class="col-span-1">
								<p class="head-text">Part du capital remboursé</p>
							</div>
							<div class="col-span-1">
								<p class="head-text">Capital fin de période</p>
							</div>
							<div class="col-span-1">
								<p class="head-text">Assurance emprunteur</p>
							</div>
						</div>
						<div class="grid grid-cols-7 px-5 place-items-center">
							<ul class="col-span-1 content-text my-2">
								<li v-for="(item, i) in amortData" :key="'z'+i+item.id">
									{{ item.id }}
								</li>
							</ul>
							<ul class="col-span-1 content-text my-2">
								<li v-for="(item, i) in amortData" :key="'a'+i+item.amortPeriod">
									{{
										item.amortCapStart.toLocaleString('fr-FR', {
											minimumFractionDigits: 0,
										})
									}}
									€
								</li>
							</ul>
							<ul class="col-span-1 content-text my-2">
								<li v-for="(item, i) in amortData" :key="'b'+i+item.amortAnnuit">
									{{
										item.amortAnnuit.toLocaleString('fr-FR', {
											minimumFractionDigits: 0,
										})
									}}
									€
								</li>
							</ul>
							<ul class="col-span-1 content-text my-2">
								<li v-for="(item, i) in amortData" :key="'c'+i+item.amortPartInt">
									{{
										item.amortPartInt.toLocaleString('fr-FR', {
											minimumFractionDigits: 0,
										})
									}}
									€
								</li>
							</ul>
							<ul class="col-span-1 content-text my-2">
								<li v-for="(item, i) in amortData" :key="'d'+i+item.amortCapRemb">
									{{
										Math.max(item.amortCapRemb, 0).toLocaleString('fr-FR', {
											minimumFractionDigits: 0,
										})
									}}
									€
								</li>
							</ul>
							<ul class="col-span-1 content-text my-2">
								<li v-for="(item, i) in amortData" :key="'e'+i+item.amortCapEnd">
									{{
										item.amortCapEnd.toLocaleString('fr-FR', {
											minimumFractionDigits: 0,
										})
									}}
									€
								</li>
							</ul>
							<ul class="col-span-1 content-text my-2">
								<li v-for="(item, i) in amortData" :key="'f'+i+item.amortAssur">
									{{
										item.amortAssur.toLocaleString('fr-FR', {
											minimumFractionDigits: 0,
										})
									}}
									€
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="my-6">
					<div class="px-4 py-3 text-right sm:px-6 flex justify-center items-center">
						<span class="inline-flex rounded-md shadow-sm">
							<a
								@click="close"
								class="cursor-pointer whitespace-no-wrap inline-flex items-center justify-center px-15 py-4 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
							>
								Fermer le tableau et revenir au formulaire
							</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Amortissement',
	props: {
		amortData: Array,
	},
	methods: {
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss" scoped>
.head-text {
	font-size: 16px;
	line-height: 35px;
	text-align: center;
}
.content-text {
	font-size: 15px;
	line-height: 30px;
}
.subtitle {
	font-size: 20px;
	margin-top: 60px;
}
</style>
