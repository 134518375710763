<template>
  <div>
    <div>{{ numberOfProjects }}</div>
    <div v-for="pic in picsOfProjects" :key="pic">
      {{ pic }}
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    projectsId: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    numberOfProjects() {
      return this.projects.length
    },
    projects() {
      return this.listProj.filter(proj => this.projectsId.includes(proj._id))
    },
  },
}
</script>

<style lang="">

</style>
