<script>
import { Bar, Pie, mixins } from 'vue-chartjs'
// const { reactiveProp } = mixins

export default {
  extends: Bar,
  // mixins: [reactiveProp],
  data() {
      return {
      }
    },
  props: ['chartdata', 'options'],
  mounted() {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

<style>
</style>