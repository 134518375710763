<template>
  <div class="">
    <PremiereUtil v-if="tuto" class="w-11/12 ml-7" @close="noMoreTour" />
    <div v-if="listInv.length > 0" class="pt-10">
      <ul v-if="investsList.length > 0" class="relative -mt-10">
        <!-- <ul class="flex flex-col flex-wrap items-center w-full sm:flex-row justify-evenly"> -->
        <div class="flex flex-no-wrap w-full overflow-hidden whitespace-no-wrap sm:flex-row wrapper-box">
          <li
            v-for="(inv, index) in investsList"
            :key="inv.name"
            class="flex-col items-center justify-center inline-block w-4/5 mx-8 my-2 mb-4 cursor-pointer sm:w-1/5 flexx card-hover"
            @click="selectedInvest(inv, index)"
          >
            <div class="relative h-32 mb-3 overflow-hidden w-36 rounded-xl">
              <!-- TO-DO : URL TO TRANSFORM AS ENV VAR -->
              <img v-if="inv.img" class="w-auto h-48" :src="inv.img.data" :alt="inv.name">
              <img v-else-if="inv.gender === 'homme'" class="w-auto h-32" src="../assets/picto/homme.png">
              <img v-else-if="inv.gender === 'societe'" class="w-auto h-32" src="../assets/picto/societe.png">
              <img
                v-else
                class="w-auto h-32"
                src="../assets/picto/femme.png"
                alt="picto aestima bonhomme femme"
              >
            </div>
            <p v-if="inv.gender !== 'societe'" class="font-bold text-normal">
              {{ inv.name }} {{ inv.firstname }}
            </p>
            <p v-else class="font-bold text-normal">
              {{ inv.forme }} {{ inv.nameSociete }}
            </p>
            <p>{{ inv.loc }}</p>
            <!-- <p>{{proj.price}}</p> -->
          </li>
        </div>
        <button
          class="opacity-50 absolute-center left-4 hover:opacity-100 outline-none-c"
          @mouseover="svgColor = '#14A0EB'"
          @mouseleave="svgColor = '#cccccc'"
          @click="scroll_left()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="44"
            height="44"
            viewBox="0 0 172 172"
            style="fill: #000000"
          >
            <g
              fill="none"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
              style="mix-blend-mode: normal"
            >
              <path d="M0,172v-172h172v172z" fill="none" />
              <g>
                <path
                  d="M2.65391,86c0,-46.02344 37.32266,-83.34609 83.34609,-83.34609c46.02344,0 83.34609,37.32266 83.34609,83.34609c0,46.02344 -37.32266,83.34609 -83.34609,83.34609c-46.02344,0 -83.34609,-37.32266 -83.34609,-83.34609z"
                  :fill="svgColor"
                />
                <path
                  d="M77.73594,86.90703c10.31328,-10.31328 20.62656,-20.62656 30.93984,-30.93984c9.00312,-9.00313 -5.00547,-22.91094 -14.04219,-13.87422c-12.63125,12.63125 -25.2625,25.2625 -37.89375,37.89375c-3.82969,3.82969 -3.69531,10.17891 0.06719,13.94141c12.63125,12.63125 25.2625,25.2625 37.89375,37.89375c9.00313,9.00313 22.91094,-5.00547 13.87422,-14.04219c-10.27969,-10.31328 -20.55938,-20.59297 -30.83906,-30.87266z"
                  fill="#ffffff"
                />
              </g>
            </g>
          </svg>
        </button>
        <button
          class="opacity-50 absolute-center right-4 hover:opacity-100 outline-none-c"
          @mouseover="svgColor = '#14A0EB'"
          @mouseleave="svgColor = '#cccccc'"
          @click="scroll_right()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="44"
            height="44"
            viewBox="0 0 172 172"
            style="fill: #000000"
          >
            <g
              fill="none"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
              style="mix-blend-mode: normal"
            >
              <path d="M0,172v-172h172v172z" fill="none" />
              <g>
                <path
                  d="M2.65391,86c0,-46.02344 37.32266,-83.34609 83.34609,-83.34609c46.02344,0 83.34609,37.32266 83.34609,83.34609c0,46.02344 -37.32266,83.34609 -83.34609,83.34609c-46.02344,0 -83.34609,-37.32266 -83.34609,-83.34609z"
                  :fill="svgColor"
                />
                <path
                  d="M120.06406,79.88594c-12.63125,-12.63125 -25.2625,-25.2625 -37.89375,-37.89375c-9.00313,-9.00313 -22.91094,5.00547 -13.87422,14.04219c10.27969,10.27969 20.59297,20.55937 30.87266,30.87266c-10.31328,10.31328 -20.62656,20.62656 -30.93984,30.93984c-9.00313,9.00312 5.00547,22.91094 14.04219,13.87422c12.63125,-12.63125 25.2625,-25.2625 37.89375,-37.89375c3.7625,-3.79609 3.66172,-10.17891 -0.10078,-13.94141z"
                  fill="#ffffff"
                />
              </g>
            </g>
          </svg>
        </button>
      </ul>
      <div v-else class="flex flex-row items-center mx-8 mb-10 text-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="64"
          height="64"
          viewBox="0 0 172 172"
          style="fill: #000000"
        >
          <g
            fill="none"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <path d="M0,172v-172h172v172z" fill="none" />
            <g fill="#14a0eb">
              <path
                d="M45.86667,17.2c-15.76389,0 -28.66667,12.90277 -28.66667,28.66667v74.53333c0.00169,0.1908 0.0129,0.38141 0.03359,0.57109c0.30996,15.50075 13.06179,28.09558 28.63307,28.09558h103.2c3.1663,-0.00032 5.73302,-2.56703 5.73333,-5.73333v-103.2c-0.00032,-3.1663 -2.56703,-5.73302 -5.73333,-5.73333h-97.46667v-11.46667c-0.00032,-3.1663 -2.56703,-5.73302 -5.73333,-5.73333zM51.6,45.86667h91.73333v91.73333h-97.46667c-9.56597,0 -17.2,-7.63403 -17.2,-17.2c0,-9.56597 7.63403,-17.2 17.2,-17.2c3.1663,-0.00032 5.73302,-2.56703 5.73333,-5.73333zM103.2,57.33333l10.27969,10.27969l-16.01302,16.01302l-13.14636,-13.14636c-2.23904,-2.23811 -5.86825,-2.23811 -8.10729,0l-11.46667,11.46667c-1.49778,1.43802 -2.10113,3.5734 -1.57735,5.5826c0.52378,2.0092 2.09284,3.57826 4.10204,4.10204c2.0092,0.52378 4.14458,-0.07957 5.5826,-1.57735l7.41302,-7.41302l13.14636,13.14636c2.23904,2.23811 5.86825,2.23811 8.10729,0l20.06667,-20.06667l10.27969,10.27969v-28.66667zM68.8,108.93333c-2.06765,-0.02924 -3.99087,1.05709 -5.03322,2.843c-1.04236,1.78592 -1.04236,3.99474 0,5.78066c1.04236,1.78592 2.96558,2.87225 5.03322,2.843h57.33333c2.06765,0.02924 3.99087,-1.05709 5.03322,-2.843c1.04236,-1.78592 1.04236,-3.99474 0,-5.78066c-1.04236,-1.78592 -2.96558,-2.87225 -5.03322,-2.843z"
              />
            </g>
          </g>
        </svg>

        <h2 class="mb-2 text-2xl">
          Votre liste d'investisseurs est vide !
        </h2>
      </div>

      <div class="mx-8">
        <h2 class="relative z-10 mt-2 font-bold text-left text-xl-c">
          Mes dernières simulations réalisées
        </h2>
        <ul v-if="lastListProj.length > 0" class="relative flex flex-col flex-wrap w-full sm:flex-col">
          <li
            v-for="(proj, index) in lastListProj"
            :key="proj.name"
            class="flex-row items-center justify-start inline-block w-full mt-4 border border-gray-200 rounded-lg cursor-pointer sm:w-full flexx"
            @click="selectedProject(proj, index)"
          >
            <div class="relative h-24 overflow-hidden rounded-lg">
              <img
                v-if="!proj.exports || proj.exports.imgPrincipale == null || proj.exports.imgPrincipale === ''"
                class="w-auto h-32 mx-16"
                src="../assets/picto/societe.png"
                alt="picto aestima bonhomme"
              >
              <img v-else class="w-48 h-auto un-img" :src="proj.exports.imgPrincipale" :alt="proj.name">
            </div>
            <div class="flex flex-row items-center justify-between w-full pl-12 pr-16">
              <div class="flex flex-col items-start justify-start">
                <div class="flex flex-col items-start">
                  <div class="flex flex-row justify-between">
                    <p class="font-bold text-normal">
                      {{ proj.name }}
                    </p>
                    <p class="ml-6 font-bold text-normal">
                      {{ numberWithSpaces(proj.carac.buyprice) }} €
                    </p>
                  </div>
                  <p v-if="proj.loc" class="text-normal">
                    {{ proj.loc.carac.label }}
                  </p>
                </div>

                <p class="text-normal">
                  Pour l'investisseur : {{ investByProject(proj._id) }}
                </p>
              </div>
              <div class="flex flex-col items-end justify-end">
                <!-- <p class="font-normal text-normal">Loyer mensuel : {{ proj.carac.categ.includes('immeuble') ? proj.carac.typeloc_surface.map(val => {return Number(val.loyer)})  : numberWithSpaces(proj.carac.loyermensuel)}} €</p> -->
                <!-- <p class="font-normal text-normal">Loyer mensuel : {{ proj.carac.categ.includes('immeuble') ? proj.carac.typeloc_surface.reduce((val, nextvalue) => { return  (val.loyer) + (nextvalue.loyer); })  : numberWithSpaces(proj.carac.loyermensuel)}} €</p> -->
                <p class="font-normal text-normal">
                  Loyer mensuel :
                  {{
                    numberWithSpaces(proj.carac.loyermensuel)
                  }}
                  €
                </p>
                <p class="font-normal text-normal">
                  Rentabililité nette après fiscalité :
                  <span v-if="proj.results == null">Non défini</span>
                  <span
                    v-else
                    :class="{
                      'text-red-600': Number(proj.results.resFluxAIFonc.performance.avecFisc.tir) === 0,
                      'text-black': Number(proj.results.resFluxAIFonc.performance.avecFisc.tir) !== 0,
                    }"
                  ><span>{{
                    Number(proj.results.resFluxAIFonc.performance.avecFisc.tir) === 0
                      ? 'Négatif'
                      : `${proj.results.resFluxAIFonc.performance.avecFisc.tir} %`
                  }}</span>
                  </span>
                </p>
                <p class="font-bold text-normal">
                  Cash flow mensuel (av remb) :
                  {{
                    cashFlowComputed(proj) == null ? 'Non défini' : numberWithSpaces(cashFlowComputed(proj))
                  }}
                  €
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="mt-6 text-left">
          <p>
            Aucune simulation n’a été effectuée pour l’instant. Vous pouvez créer une simulation en allant sur
            <b>« mes biens locatifs »</b> puis <b>« créer une nouvelle simulation ».</b>
          </p>
        </div>
      </div>
    </div>
    <div v-else class="relative flex flex-col items-center justify-center">
      <h2 class="mb-2 text-2xl">
        Votre liste d'investisseurs est vide !
      </h2>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="64"
        height="64"
        viewBox="0 0 172 172"
        style="fill: #000000"
      >
        <g
          fill="none"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style="mix-blend-mode: normal"
        >
          <path d="M0,172v-172h172v172z" fill="none" />
          <g fill="#14a0eb">
            <path
              d="M45.86667,17.2c-15.76389,0 -28.66667,12.90277 -28.66667,28.66667v74.53333c0.00169,0.1908 0.0129,0.38141 0.03359,0.57109c0.30996,15.50075 13.06179,28.09558 28.63307,28.09558h103.2c3.1663,-0.00032 5.73302,-2.56703 5.73333,-5.73333v-103.2c-0.00032,-3.1663 -2.56703,-5.73302 -5.73333,-5.73333h-97.46667v-11.46667c-0.00032,-3.1663 -2.56703,-5.73302 -5.73333,-5.73333zM51.6,45.86667h91.73333v91.73333h-97.46667c-9.56597,0 -17.2,-7.63403 -17.2,-17.2c0,-9.56597 7.63403,-17.2 17.2,-17.2c3.1663,-0.00032 5.73302,-2.56703 5.73333,-5.73333zM103.2,57.33333l10.27969,10.27969l-16.01302,16.01302l-13.14636,-13.14636c-2.23904,-2.23811 -5.86825,-2.23811 -8.10729,0l-11.46667,11.46667c-1.49778,1.43802 -2.10113,3.5734 -1.57735,5.5826c0.52378,2.0092 2.09284,3.57826 4.10204,4.10204c2.0092,0.52378 4.14458,-0.07957 5.5826,-1.57735l7.41302,-7.41302l13.14636,13.14636c2.23904,2.23811 5.86825,2.23811 8.10729,0l20.06667,-20.06667l10.27969,10.27969v-28.66667zM68.8,108.93333c-2.06765,-0.02924 -3.99087,1.05709 -5.03322,2.843c-1.04236,1.78592 -1.04236,3.99474 0,5.78066c1.04236,1.78592 2.96558,2.87225 5.03322,2.843h57.33333c2.06765,0.02924 3.99087,-1.05709 5.03322,-2.843c1.04236,-1.78592 1.04236,-3.99474 0,-5.78066c-1.04236,-1.78592 -2.96558,-2.87225 -5.03322,-2.843z"
            />
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PremiereUtil from '../components/popup/PremiereUtil.vue'

export default {
  name: 'Home',
  components: {
    PremiereUtil,
  },
  props: {},
  data() {
    return {
      STATIC_URL: process.env.VUE_APP_API_HOSTNAME_DEV,
      svgColor: '#cccccc',
      tuto: false,
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
      user: 'users/user',
      listProj: 'projects/projects',
      listInv: 'invests/invests',
    }),
    lastListProj() {
      const lastThreeProject = []
      for (const invest of this.listInv) {
        if (invest.archive.isArchived === false) {
          for (const project of this.listProj) {
            if (invest.projects.includes(project._id)) {
              lastThreeProject.push(project)
            }
          }
        }
      }
      return lastThreeProject.slice(Math.max(lastThreeProject.length - 3, 0))
    },
    investsList() {
      return this.listInv.filter(val => val.archive.isArchived === false)
    },
  },
  mounted() {
    if (this.user.isTour === true) this.tuto = true
  },
  metaInfo() {
    return {
      title: 'Mes dossiers en cours - Aestima',
      meta: [
        {
          name: 'description',
          content: 'Cette page décrit les dossiers en cours de l\'utilisateur.',
        },
      ],
    }
  },
  created() {
  },
  methods: {
    ...mapActions({
      signOut: 'users/logout',
      getProjects: 'projects/getProjectsSummary',
      getProject: 'projects/getProject',
      getProjectsSummary: 'projects/getProjectsSummary',
      setProjectSelected: 'projects/setProjectSelected',
      updateUserTour: 'users/updateUserTour',
      setProjectSelectedResult: 'projects/setProjectSelectedResult',
      setInvestSelected: 'invests/setInvestSelected',
    }),
    noMoreTour() {
      this.tuto = false
      this.updateUserTour({
        id: this.user._id,
        isTour: false,
      })
    },
    async selectedProject(proj) {
      await this.getProject({
        idUser: this.user._id,
        idProject: proj._id,
      })
        .then(() => {
          this.$router.replace({
            name: 'Results',
          })
        })
    },
    selectedInvest(inv, index) {
      this.setInvestSelected({
        inv,
        index,
      }).then(() => {
        this.$router.replace({
          name: 'Simulator',
        })
      })
    },
    scroll_left() {
      const content = document.querySelector('.wrapper-box')
      content.scrollLeft -= 400
    },
    scroll_right() {
      const content = document.querySelector('.wrapper-box')
      content.scrollLeft += 400
    },
    investByProject(projectId) {
      let selectedInv = null
      this.listInv.map((inv) => {
        return inv.projects.map((proj) => {
          if (proj === projectId) {
            return (selectedInv = inv.name)
          }
        })
      })

      return selectedInv
    },
    numberWithSpaces(x) {
      if (isNaN(x) || x === undefined) return 0
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    },
    sumOfArray(array) {
      let sum = 0

      for (let i = 0; i < array.length; i++) {
        sum += Number(array[i].loyer)
      }

      return sum
    },
    cashFlowComputed(proj) {
      if (proj.results == null) {
        return null
      }

      let cashFlowAvantRemb = 0
      let cashFlowAvantRembCounter = 0
      let resCashFlowAvantRemb = 0
      for (let i = 0; i <= proj.results.timelineFonc.resTimelineEmpr.length; i++) {
        if (i > 0 && proj.results.timelineFonc.resTimelineEmpr[i] === 1) {
          cashFlowAvantRemb += proj.results.resFluxAIFonc.resCashFlowActu[i]
          cashFlowAvantRembCounter += 1
        }
      }

      resCashFlowAvantRemb = isNaN(Math.round(cashFlowAvantRemb / cashFlowAvantRembCounter / 12))
        ? 0
        : Math.round(cashFlowAvantRemb / cashFlowAvantRembCounter / 12)

      return resCashFlowAvantRemb
    },
  },
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}

img {
  max-width: none;
}

img {
  margin-left: 50%;
  transform: translateX(-50%);
}

.flexx {
  display: flex !important;
}

.card-hover {
  transition: 0.3s;
}

.card-hover div:after {
  transition: 0.4s;
  content: '';
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 0.3em solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.card-hover:hover div:after {
  content: '';
  display: block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 0.4em solid #14a0eb;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.card-hover:hover p:first-of-type {
  transition: 0.4s;
  color: #14a0eb;
}

.un-img {
  margin-left: 0%;
  transform: translateX(0%);
}

.un-margin {
  margin: 0px !important;
}

.absolute-center {
  position: absolute;
  top: 0;
  bottom: 0;
}

.outline-none-c {
  outline: none !important;
}

.arrow-absolute {
  bottom: 5rem;
  right: 10rem;
}

.text-xl-c {
  font-size: 1.4rem;
  line-height: 2rem;
}
</style>
