<template>
  <div tabindex="-1" class="absolute z-50 flex justify-center pb-12 overflow-y-auto">
    <div id="fond-grise" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    <div v-if="spinner === true" id="topDiv" class="sticky flex flex-col w-64 h-64 mt-24 bg-white rounded-3xl">
      <img class="pt-10 pb-6 pr-3 place-self-center" src="../../assets/icons/blue_spinner.gif">
      <div class="">
        Téléchargement en cours...
      </div>
    </div>
    <div v-else id="main-container" class="relative flex flex-col p-12 main-container rounded-3xl gap-8">
      <div v-if="errorMessage" id="error-message" class="flex flex-col justify-center mx-auto mb-12">
        <div class="flex justify-between place-self-center text-red-900">
          <div class="text-2xl font-bold ">
            Erreur
          </div>
        </div>
        <div class="flex flex-col justify-center flex-grow">
          <div class="flex flex-col justify-center flex-grow">
            <div class="text-xl font-bold">
              Veuillez relancer votre export
            </div>
          </div>
        </div>
      </div>
      <div id="titre" class="flex mb-4 -mt-4 text-2xl font-bold place-items-center">
        Informations à saisir avant votre export !
      </div>
      <div class="absolute p-4 cursor-pointer right-2 top-2" @click="escape">
        <CloseIcon />
      </div>
      <div id="1" class="card">
        <div class="sous-titre">
          1. Présentation du projet d’investissement
        </div>
        <textarea v-model="params.CustomPresentation" class="h-20 mt-4 custom-input" maxlength="150" placeholder="À travers ce rapport financier, je souhaite vous présenter mon projet d’investissement..." />
        <label v-if="canRequestSituationFiscale" class="flex justify-between">
          <span class="text-sm place-self-center">Affichage de la situation fiscale du client</span>
          <input v-model="params.SituationFiscale" type="checkbox" class="checkbox">
        </label>
        <div class="text-xs text-right" :class="params.CustomPresentation.length === 150 ? 'text-red-400 font-bold' : ''">
          {{ params.CustomPresentation.length }} / 150
        </div>
      </div>

      <div id="2" class="card">
        <div class="sous-titre">
          2. Descriptif détaillé du bien
          <div class="text-xs font-medium">
            Informations saisies dans la partie “description détaillée du bien et de son environnement” du formulaire simulation.
          </div>
        </div>
        <div v-for="(pj, i) in params.bienPJ" :key="i" class="flex flex-col px-24 gap-2">
          <div class="flex justify-between">
            <span class="text-sm place-self-center">Pièce jointe n°{{ i }}</span>
            <div class="flex gap-2">
              <label :for="`imageBien${i}`" class="px-4 py-1 text-xs border rounded-lg cursor-pointer" :class="params.bienPJ[i] ? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110' : 'bg-white'">Parcourir</label>
              <input :id="`imageBien${i}`" hidden type="file" :name="i" accept=".jpg,.png,.pdf" @change="uploadFile($event, i, 'bien')">
              <img
                v-if="params.bienPJ[i]"
                class="place-self-center fit fill-red-500 text-red-500 cursor-pointer"
                src="../../assets/icons/CiCloseCircle.svg"
                @click="deleteFile(i, 'bien')"
              >
            </div>
          </div>
          <input v-if="params.bienPJ[i]" v-model="params.descriptionBienPJ[i]" type="text" class="text-xs border rounded-lg px-2 py-1" placeholder="Description de la photo">
        </div>
        <label class="flex justify-between">
          <span class="text-sm place-self-center">Affichage du descriptif détaillé de l'immeuble</span>
          <input v-model="params.DescriptifImmeuble" type="checkbox" class="checkbox">
        </label>
        <label class="flex justify-between">
          <span class="text-sm place-self-center">Affichage du descriptif détaillé du quartier</span>
          <input v-model="params.DescriptifQuartier" type="checkbox" class="checkbox">
        </label>
      </div>

      <div id="3" class="card">
        <div class="flex justify-between w-full sous-titre">
          <span> 3. Présentation et visuels des travaux </span>
          <input v-model="params.PresentationVisuelTravaux" type="checkbox" class="w-6 h-6 mr-4 place-self-center">
        </div>
        <div v-if="params.PresentationVisuelTravaux" class="flex flex-col gap-4">
          <span class="self-start mt-4">Objectif des travaux</span>
          <textarea
            v-model="params.CustomTravaux"
            class="h-20 custom-input"
            placeholder="Lui faire prendre en valeur et optimiser mon loyer
Limiter la vacance locative au maximum..."
          />

          <span class="self-start my-4 text-sm">Plans des travaux : 2D, 3D, photos avant/après, etc. (.jpg ou .png)</span>
          <div v-for="(pj, i) in params.TravauxPJ" :key="i" class="flex flex-col px-24 gap-2">
            <div class="flex justify-between">
              <span class="text-sm place-self-center">Pièce jointe n°{{ i }}</span>
              <div class="flex gap-2">
                <label :for="`imageTravaux${i}`" class="px-4 py-1 text-xs border rounded-lg cursor-pointer" :class="params.TravauxPJ[i] ? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110' : 'bg-white'">Parcourir</label>
                <input :id="`imageTravaux${i}`" hidden type="file" :name="i" accept=".jpg,.png,.pdf" @change="uploadFile($event, i, 'travaux')">
                <img
                  v-if="params.TravauxPJ[i]"
                  class="place-self-center fit fill-red-500 text-red-500 cursor-pointer"
                  src="../../assets/icons/CiCloseCircle.svg"
                  @click="deleteFile(i, 'travaux')"
                >
              </div>
            </div>
            <input v-if="params.TravauxPJ[i]" v-model="params.descriptionTravauxPJ[i]" type="text" class="text-xs border rounded-lg px-2 py-1" placeholder="Description de la photo">
          </div>
        </div>
      </div>

      <div id="4" class="card">
        <div class="sous-titre">
          4. Etude de marché
        </div>
        <label class="flex justify-between">
          <span class="text-sm place-self-center">Analyse de la population de la ville</span>
          <input v-model="params.AnalysePopulation" type="checkbox" class="checkbox">
        </label>
        <label class="flex justify-between">
          <span class="text-sm place-self-center">Etude des loyers et prix au m2 de la ville</span>
          <input v-model="params.EtudeLoyers" type="checkbox" class="checkbox">
        </label>
        <label class="flex justify-between">
          <span class="text-sm place-self-center">Evolution des prix immobilier de la ville</span>
          <input v-model="params.EvolutionPrix" type="checkbox" class="checkbox">
        </label>
        <span class="self-start mt-4">Commentaires libres sur l’étude de marché</span>
        <textarea v-model="params.CustomEtudeMarche" class="h-24 custom-input" placeholder="On constate sur cette étude qu’il y a 40% d’étudiants. Mon souhait d’investir dans un studio en plein coeur de cette ville prend donc tout son sens..." />
      </div>

      <div id="5" class="card">
        <div class="sous-titre">
          5. Détails financiers
        </div>
        <label class="flex justify-between">
          <span class="text-sm place-self-center">Affichage des ratios financiers avancés (TIR, VAN, etc.)</span>
          <input v-model="params.RatioFinanciers" type="checkbox" class="checkbox">
        </label>
        <label class="flex justify-between">
          <span class="text-sm place-self-center">Affichage de l’analyse fiscale du projet</span>
          <input v-model="params.AnalyseFiscale" type="checkbox" class="checkbox">
        </label>
        <label v-if="canRequestRegimeFiscal" class="flex justify-between">
          <span class="text-sm place-self-center">Affichage de la page explicative du régime fiscal</span>
          <input v-model="params.RegimeFiscal" type="checkbox" class="checkbox">
        </label>
        <label class="flex justify-between">
          <span class="text-sm place-self-center">Affichage du tableau de trésorerie détaillé</span>
          <input v-model="params.TableauTreso" type="checkbox" class="checkbox">
        </label>
        <label class="flex justify-between">
          <span class="text-sm place-self-center">Affichage du tableau d'amortissement d'emprunt</span>
          <input v-model="params.TableauAmort" type="checkbox" class="checkbox">
        </label>
        <span class="self-start mt-4">Commentaires libres sur le financement</span>
        <textarea v-model="params.CustomFinancement" class="custom-input h-28" placeholder="Dans cette simulation, n’ayant pas vos données financières, nous nous sommes basés sur un emprunt de 80% de la valeur du bien + travaux. Le reste correspond à votre apport." />
      </div>

      <div id="6" class="card">
        <div class="sous-titre">
          6. Synthèse personnalisée de la simulation
        </div>
        <textarea v-model="params.CustomSynthese" class="h-auto custom-input" placeholder="Ici viendra la synthèse automatique" />
      </div>

      <div id="7" class="card">
        <div class="sous-titre">
          7. Mise en forme
        </div>
        <span class="self-start">Donner un nom à votre dossier</span>
        <textarea v-model="params.NomDossier" class="h-12 custom-input" />
        <div v-if="user.coworkerLimit && coworkers.length > 1">
          <label class="flex justify-between">
            <span class="self-start">Afficher les coordonnées d'un conseiller</span>
            <input v-model="chooseCoworkerDropdown" type="checkbox" class="checkbox">
          </label>
          <div v-if="chooseCoworkerDropdown === true" class="flex gap-2 mt-6">
            <PDropdown v-model="params.ChosenCoworker" :options="coworkers" option-label="name" placeholder="Choisissez un collaborateur">
              <template #value="slotProps">
                <div v-if="params.ChosenCoworker" class="flex gap-2">
                  <div id="pastille" slot="trigger" :value="slotProps.value.color" readonly class="form__input__element flex items-center justify-center w-10 h-10 mr-4 rounded-full shadow-md cursor-pointer" :style="{ backgroundColor: slotProps.value.color }">
                    <div class="font-bold leading-none text-white">
                      {{ initials(slotProps.value.name) }}
                    </div>
                  </div>
                  <div class="my-auto font-semibold">
                    {{ slotProps.value.name }}
                  </div>
                </div>
                <div v-else>
                  {{ slotProps.placeholder }}
                </div>
              </template>
              <template #option="slotProps">
                <div class="flex gap-2">
                  <VSwatches v-model="slotProps.option.color">
                    <div id="pastille" slot="trigger" :value="slotProps.option.color" readonly class="form__input__element flex items-center justify-center w-10 h-10 mr-4 rounded-full shadow-md cursor-pointer" :style="{ backgroundColor: slotProps.option.color }">
                      <div class="font-bold leading-none text-white">
                        {{ initials(slotProps.option.name) }}
                      </div>
                    </div>
                  </VSwatches>
                  <span class="my-auto rounded-md px-1 py-0.5">{{ slotProps.option.name }}</span>
                </div>
              </template>
            </PDropdown>
          </div>
        </div>
      </div>
      <button
        type="submit"
        :disabled="!canDownload"
        class="relative flex self-center justify-center w-3/4 px-4 py-2 text-sm font-medium text-white rounded-lg bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out"
        :class="{ 'opacity-50 cursor-not-allowed': !canDownload }"
        @click="exportAndClose"
      >
        <span class="absolute inset-y-0 left-0 flex items-center pl-3">
          <DownloadIcon v-if="canDownload" />
          <LockIcon v-else />
        </span>
        Télécharger le rapport financier en PDF
      </button>

      <!-- <div v-if="user.sub_level === 1" class="text-sm">
        <span class="font-semibold">Avec votre abonnement actuel,</span><br>
        Il vous reste {{ 10 - user.exportThisMonth.length }} PDF à télécharger ce mois-ci
        Pour profiter d'export de PDF en illimité, <a href:'./Profil' class="underline">faites évoluer votre abonnement</a>
      </div> -->

      <div v-if="user.sub_level === 0" class="-mt-4 text-sm italic">
        <a href="../Profil" class="underline">Faites évoluer votre abonnement</a> vers le plan initié ou professionnel <br>pour bénéficier des exports PDF
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import DownloadIcon from '@/components/SVG/DownloadIcon.vue'
import LockIcon from '@/components/SVG/LockIcon.vue'
import CloseIcon from '@/components/SVG/CloseIcon.vue'

export default {
  name: 'ExportPDF',
  components: {
    DownloadIcon,
    LockIcon,
    CloseIcon,
  },
  data() {
    return {
      spinner: false,
      errorMessage: false,
      chooseCoworkerDropdown: false,
      coworkers: [],
      params: {
        CustomPresentation: '',
        SituationFiscale: false,

        DescriptifImmeuble: false,
        DescriptifQuartier: false,

        PresentationVisuelTravaux: false,
        CustomTravaux: '',

        TravauxPJ: {
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
          6: null,
        },
        descriptionTravauxPJ: {
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
        },
        bienPJ: {
          1: null,
          2: null,
          3: null,
        },
        descriptionBienPJ: {
          1: '',
          2: '',
          3: '',
        },

        AnalysePopulation: true,
        EtudeLoyers: true,
        EvolutionPrix: false,
        CustomEtudeMarche: '',

        RegimeFiscal: true,
        RatioFinanciers: false,
        AnalyseFiscale: false,
        TableauTreso: true,
        TableauAmort: true,
        CustomFinancement: '',

        CustomSynthese: '',

        NomDossier: '',
        ChosenCoworker: null,
        ExportTimestamp: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
      user: 'users/user',
      projet: 'projects/selected',
      invest: 'invests/selected',
      projectSelected: 'projects/selected',
    }),
    canDownload() {
      return (this.user.sub_level === 1 && this.user.exportThisMonth.length < 10) || this.user.sub_level === 2
    },
    canRequestRegimeFiscal() {
      const regime = this.projectSelected.carac.formejuridique.filter(item => item.selected === true)[0].code
      const type = this.projectSelected.carac.typeexploitation
      if (type === 'classique_neuf' || type === 'classique_ancien') {
        if (regime === 'sasis' || regime === 'lmnpbic' || regime === 'sciirmf' || regime === 'lnmf') {
          this.params.RegimeFiscal = false
          return false
        }
      }
      return true
    },
    canRequestSituationFiscale() {
      if (this.invest.fiscalite == null) return false
      return true
    },
  },

  mounted() {
    this.params = { ...this.params, ...this.projet.exports }
    this.coworkers = this.user.coworkers
    this.params.ChosenCoworker = this.projectSelected.owner
    delete this.params.ImmeublePJ
    delete this.params.imgPrincipale
  },

  methods: {
    ...mapActions({
      setPdfParams: 'users/setPdfParams',
    }),
    scrollTop() { },

    uploadFile(e, i, type) {
      console.log('type', type)
      if (type === 'bien')
        this.params.bienPJ[i] = e.target.files[0]
      else if (type === 'travaux')
        this.params.TravauxPJ[i] = e.target.files[0]
    },
    deleteFile(i, type) {
      if (type === 'bien') {
        this.params.bienPJ[i] = null
        this.params.descriptionBienPJ[i] = ''
      } else if (type === 'travaux') {
        this.params.TravauxPJ[i] = null
        this.params.descriptionTravauxPJ[i] = ''
      }
    },
    initials(name) {
      if (!name) return ''
      const first = name.split(' ')[0].toUpperCase()[0]
      if (!name.split(' ')[1]) return first
      const second = name.split(' ')[1].toUpperCase()[0]
      return `${first}${second}`
    },
    async exportAndClose() {
      this.params.ExportTimestamp = Date.now()
      this.projet.exports = { ...this.params }
      window.scrollTo({ top: 2, behavior: 'smooth' })
      this.spinner = true

      // Upload TravauxPJ
      const filesTravaux = new FormData()
      let urlsTravaux = []

      if (this.params.TravauxPJ) {
        for (const i in this.params.TravauxPJ) {
          if (this.params.TravauxPJ[i]) {
            filesTravaux.append('files', this.params.TravauxPJ[i])
          }
        }
      }

      if (filesTravaux) {
        urlsTravaux = await axios.post('/export/image', filesTravaux)
        urlsTravaux = urlsTravaux.data.filesName
        for (const i in urlsTravaux) {
          this.params.TravauxPJ[Number(i) + 1] = urlsTravaux[i]
        }
      }

      //Upload bienPJ
      const filesBien = new FormData()
      let urlsBien = []

      if (this.params.bienPJ) {
        for (const i in this.params.bienPJ) {
          if (this.params.bienPJ[i]) {
            filesBien.append('files', this.params.bienPJ[i])
          }
        }
      }

      if (filesBien) {
        urlsBien = await axios.post('/export/image', filesBien)
        urlsBien = urlsBien.data.filesName
        for (const i in urlsBien) {
          this.params.bienPJ[Number(i) + 1] = urlsBien[i]
        }
      }

      const res = await this.setPdfParams({
        idInvest: this.invest._id,
        idSimu: this.projet._id,
        idUser: this.user._id,
        params: this.params,
      })

      console.log('res EXPORT', res)
      if (res === 'success') {
        this.spinner = false
        this.errorMessage = false
        this.$emit('close')
      } else {
        this.errorMessage = true
        this.spinner = false
      }
    },
    escape() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.main-container {
  --bg-opacity: 1;
  background-color: #f1f1f1fb;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-width: 2px;
  width: 40rem;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: rgb(240, 247, 255);
  border-radius: 1.5rem /* 24px */;
}
.main-container {
  --bg-opacity: 1;
  background-color: #f1f1f1fb;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-width: 2px;
  width: 40rem;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: rgb(240, 247, 255);
  border-radius: 1.5rem /* 24px */;
  box-shadow: 0.1rem 0.2rem 0.4rem 0.05rem rgba(0, 0, 0, 0.1);
}
.sous-titre {
  place-self: start;
  font-size: 1.1rem;
  font-weight: 600;
  color: #233a5f;
}
.custom-input {
  border: 0.1rem solid #9fa6b2;
  border-radius: 0.5rem /* 8px */;
  font-size: 0.875rem /* 14px */;
  padding: 0.75rem;
  font-size: 0.875rem /* 14px */;
}

.checkbox {
  margin-right: 1rem /* 16px */;
  width: 1.5rem /* 24px */;
  height: 1.5rem /* 24px */;
  place-self: center;
}
</style>
