<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="content-text ml-2 w-16 md:w-56 lg:w-64 fixed top-[2px] left-0 bg-white text-black grid grid-cols-1 gap-6 pr-6 rounded-lg shadow dark:bg-gray-700"
  >
    <div>
      <a href="javascript:document.getElementById('descriptif').scrollIntoView(true);" class="prop mt-4">
        <img class="pictobg" src="../assets/picto/sidebar/DescriptifB.png" /><img
          class="picto"
          src="../assets/picto/sidebar/DescriptifN.png"
        /><a class="relative left-15">Descriptif du bien</a>
      </a>


      <!-- ces <a> sont comme ca car sinon ils ne marchemt pas sur firefox -->
      <a href="javascript:document.getElementById('acquisition').scrollIntoView(true);" class="prop">
        <img class="pictobg" src="../assets/picto/sidebar/AcquisitionB.png" /><img
          class="picto"
          src="../assets/picto/sidebar/AcquisitionN.png"
        />
        <a class="relative left-15">Acquisition du bien</a>
      </a>


      <a href="javascript:document.getElementById('travaux').scrollIntoView(true);" class="prop">
        <img class="pictobg" src="../assets/picto/sidebar/ScenarioreventeB.png" /><img
          class="picto"
          src="../assets/picto/sidebar/ScenarioreventeN.png"
        />
        <a class="relative left-15">Scénario de revente</a>
      </a>


      <a href="javascript:document.getElementById('travaux2').scrollIntoView(true);" class="prop">
        <img class="pictobg" src="../assets/picto/sidebar/TravauxB.png" /><img
          class="picto"
          src="../assets/picto/sidebar/TravauxN.png"
        />
        <a class="relative left-15">Estimation des travaux</a>
      </a>


      <a href="javascript:document.getElementById('revenus').scrollIntoView(true);" class="prop">
        <img class="pictobg" src="../assets/picto/sidebar/SolvabiliteB.png" /><img
          class="picto"
          src="../assets/picto/sidebar/SolvabiliteN.png"
        />
		  <a class="relative left-15">Revenus du bien</a>
      </a>


      <a href="javascript:document.getElementById('chargesLocatives').scrollIntoView(true);" class="prop">
        <img class="pictobg" src="../assets/picto/sidebar/ChargesLocativesB.png" /><img
          class="picto"
          src="../assets/picto/sidebar/ChargesLocativesN.png"
        />
      <a class="relative left-15">Charges locatives</a>
      </a>


      <a href="javascript:document.getElementById('financement').scrollIntoView(true);" class="prop">
        <img class="pictobg" src="../assets/picto/sidebar/FinancementB.png" /><img
          class="picto"
          src="../assets/picto/sidebar/FinancementN.png"
        />
        <a class="relative left-15">Financement</a>
      </a>


      <a href="javascript:document.getElementById('regimeFiscal').scrollIntoView(true);" class="prop">
        <img class="pictobg" src="../assets/picto/sidebar/RegimeFiscalB.png" /><img
          class="picto"
          src="../assets/picto/sidebar/RegimeFiscalN.png"
        />
        <a class="relative left-15 mb-2">Régime fiscal</a>
      </a>


      <hr />
      <div class="mb-4 mt-1 ml-2">
        <button>Enregistrer et voir les résultats</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {},
  methods: {
    close() {},
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content-text {
  font-size: 13px;
  line-height: 36px;
}

.picto {
  position: fixed;
  left: 20px;
  width: 34px;
  height: 34px;
  margin-right: 8px;
  opacity: 1;
}
.pictobg {
  position: fixed;
  left: 15px;
  width: 34px;
  height: 34px;
  margin-right: 8px;
  opacity: 1;
}

.prop {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.1rem;
}

.prop:hover {
  color: rgb(255, 255, 255);
  --bg-opacity: 1;
  background-color: #0e1e3a;
  background-color: rgba(14, 30, 58, var(--bg-opacity));
}

.prop:hover .picto {
  background-color: aqua;
  opacity: 0;
}

hr.style1 {
  border-top: 1px solid #01bb6a;
}

button {
  background-color: rgb(218, 218, 218);
  border: transparent;
  border-radius: 0.5rem;
  padding: 6%;
  margin-top: 10px;
  line-height: 1;
}
button:hover {
  background-color: #14A0EB;
  color: #fff;
}
</style>
