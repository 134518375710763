<template>
	<div>
		<Header/>
		<div class="grid grid-cols-11 gap-1 bg-darkblueaestima-500 relative flex flex-row justify-center items-start">
			<div class="relative text-center col-span-11 sm:col-start-2 sm:col-end-11 z-10">
				<h1 class="uppercase s1-title font-bold text-white text-4xl md:text-6xl mb-10 aestima-title-vw leading-normal"> UNE ANALYSE DE RENTABILITÉ IMMOBILIÈRE C’EST BIEN BEAU, 
				Mais comment ça marche ?</h1>
			</div>
			<router-link to="/tarifs" class="col-span-11 sm:col-start-5 sm:col-end-8 uppercase text-sm font-semibold text-white px-12 py-6 bg-aestiblue-light rounded-full aestima-button mb-10">COMMENCER MON ESSAI GRATUIT</router-link>	
		</div>

		<div class="grid grid-cols-6 gap-6 mt-32 mb-20 flex justify-center items-center">
			<span class="col-span-6 sm:col-start-2 sm:col-end-3 w-20 h-20 justify-self-center flex justify-center items-center bg-darkblueaestima-500 rounded-full text-white font-bold text-xl">1</span>
			<p class="col-span-6 sm:col-start-3 sm:col-end-6 text-2xl font-medium justify-self-start text-justify">Choisissez un bien locatif que vous avez en portefeuille.</p>				
		</div>
		<div class="grid grid-cols-6 gap-6 mt-20 mb-20 flex justify-center items-center">
			<span class="col-span-6 sm:col-start-2 sm:col-end-3 w-20 h-20 justify-self-center flex justify-center items-center bg-darkblueaestima-500 rounded-full text-white font-bold text-xl">2</span>
			<p class="col-span-6 sm:col-start-3 sm:col-end-6 text-2xl font-medium justify-self-start text-justify">Enregistrez toutes les données chiffrées de ce bien
 (taxe foncière, charges locatives, variations, etc.).</p>
		</div>
		<div class="grid grid-cols-6 gap-6 mt-20 mb-20 flex justify-center items-center">
			<span class="col-span-6 sm:col-start-2 sm:col-end-3 w-20 h-20 justify-self-center flex justify-center items-center bg-darkblueaestima-500 rounded-full text-white font-bold text-xl">3</span>
			<p class="col-span-6 sm:col-start-3 sm:col-end-6 text-2xl font-medium justify-self-start text-justify">Listez ensuite les investisseurs intéréssés par ce bien et saisissez leurs informations (emprunt, montage , projet, etc.)</p>				
		</div>
		<div class="grid grid-cols-6 gap-6 mt-20 mb-20 flex justify-center items-center">
			<span class="col-span-6 sm:col-start-2 sm:col-end-3 w-20 h-20 justify-self-center flex justify-center items-center bg-darkblueaestima-500 rounded-full text-white font-bold text-xl">4</span>
			<p class="col-span-6 sm:col-start-3 sm:col-end-6 text-2xl font-medium justify-self-start text-justify">Lancez l’analyse de rentabilité et consultez les résultats en ligne.</p>				
		</div>
		<div class="grid grid-cols-6 gap-6 mt-20 mb-20 flex justify-center items-center">
			<span class="col-span-6 sm:col-start-2 sm:col-end-3 w-20 h-20 justify-self-center flex justify-center items-center bg-darkblueaestima-500 rounded-full text-white font-bold text-xl">5</span>
			<p class="col-span-6 sm:col-start-3 sm:col-end-6 text-2xl font-medium justify-self-start text-justify">Lisez les explications de chaque résultat pour en comprendre le sens et exportez un rapport PDF de l’analyse à vos clients.</p>				
		</div>
		<div class="grid grid-cols-6 gap-6 mt-20 mb-20 flex justify-center items-center">
			<span class="col-span-6 sm:col-start-2 sm:col-end-3 w-20 h-20 justify-self-center flex justify-center items-center bg-darkblueaestima-500 rounded-full text-white font-bold text-xl">6</span>
			<p class="col-span-6 sm:col-start-3 sm:col-end-6 text-2xl font-medium justify-self-start text-justify">Et voilà ! Vous pouvez répéter ce processus autant de fois que vous le voudrez.</p>				
		</div>

		<div class="grid grid-cols-11 gap-1 mb-20">
			<div class="relative text-center md:text-right col-span-12 sm:col-start-3 sm:col-end-10 z-10 mt-20 md:ml-10">
				<video class="w-full" poster="../../assets/aestima_miniature.png" controls>
					<source src="../../assets/aestima.mp4" type="video/mp4" alt="vidéo de présentation du simulateur aestima">
				</video>
			</div>
		</div>

		<Footer/>
	</div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
export default {
	name: 'HIW',
	data() {
		return {
		}
	},
	components: {
		Footer,
		Header
	},
	metaInfo(){
		return {
			title: "Comment ça marche ? - Aestima",
			meta: [{
				name: 'description',
				content: "Cette page décrit le fonctionnement de l'estimateur immobilier en ligne Aestima."
			}]
			//keywords
		}
	},
}
</script>

<style scoped>
.h-header {
	height: 100%;
}
.aestima-button {
	transition: 0.5s;
	background-color: #14A0EB;
}
/* 
video {
	position: relative;
	width: 100% !important;
	height: auto;
} */

.aestima-button:hover {
	background-color: #ffffff;
	border: 2px solid #14A0EB;
	color: #14A0EB;
	transform: scale(1.1);
}

.aestima-title-vw {
}

.aestima-par-vw {
}
/* Medium (md) */
@media (min-width: 768px) {
	.h-header {
		height: 50rem;
	}

	.aestima-title-vw {
		font-size: 2.8vw !important;
	}

	.aestima-par-vw {
		font-size: 1.2vw !important;
	}
}
</style>

