<template>
  <div id="resultat_financier">
    <div class="px-5 py-5 mt-5 card">
      <div class="flex flex-row align-middle card-header">
        <img
          src="@/assets/icons/resultats_financiers/resultat_financier_1.jpg"
          class="h-20"
          alt="Résultats financier"
        />
        <div class="flex flex-col my-auto ml-5">
          <p class="text-xl font-bold">Résultats financiers de la simulation</p>
          <p class="text-sm">
            L'écrivain Bernard Werber disait "l'important n'est pas de convaincre, mais de donner à
            réflechir."
          </p>
          <p class="text-sm">
            Accompagner votre client c'est aussi lui apporter toute la visibilité financière dont il a besoin
            pour se projeter dans son projet
          </p>

          <!-- {{ showPositif }} -->
        </div>
      </div>
    </div>
    <!-- ENRICHISSEMENT LIE E L'INVESTISSEMENT -->
    <div class="px-5 py-5 mt-5 card">
      <div class="flex flex-row align-middle card-header">
        <img
          src="@/assets/icons/resultats_financiers/resultat_financier_3.jpeg"
          class="h-20"
          alt="Enrichissement lié à l'investissement"
        />
        <div class="flex flex-col my-auto ml-5">
          <p class="text-xl font-bold">Enrichissement lié à l'investissement</p>
        </div>
      </div>
      <div class="mt-5 sm:mt-0 sm:col-start-2 sm:col-end-12">
        <div class="sm:rounded-md">
          <div class="flex items-center px-4 py-5 bg-white sm:py-0 sm:px-6 grid grid-cols-6 gap-6">
            <div
              class="flex flex-col items-start justify-start text-left col-span-6 sm:col-start-1 sm:col-end-5"
            >
              <div class="flex flex-row items-center justify-between w-full my-2">
                <div class="flex flex-row items-center">
                  <h3 class="font-bold">Profit total généré par l’investissement</h3>
                  <QuestionMarkv2
                    class="float-right ml-2"
                    :text="`${infobulles.profitTotalInvestissement}`"
                  />
                  <QuestionMarkv2
                    class="float-right ml-2"
                    :text="`${infobulles.profitTotalInvestissementCalc}`"
                    :calculatrice="true"
                  />
                </div>
                <p class="text-lg font-bold text-lightblueaestima-100">
                  <span class="text-xl">{{
                    numberWithSpaces(Math.round(projectSelected.results.resFluxAIFonc.resTotCashFlowNonActu))
                  }}</span>
                  €
                </p>
              </div>
              <div class="w-full pl-10">
                <div class="flex flex-row items-center justify-between mb-1">
                  <div class="flex flex-row items-center justify-between w-3/5">
                    <p>Par les revenus locatifs</p>
                    <span class="block w-10 h-4 chart-blue rounded-md"></span>
                  </div>
                  <p class="text-lg text-black font-base">
                    <span class="text-lg">{{
                      numberWithSpaces(
                        Math.round(projectSelected.results.resFluxAIFonc.revenuLocatifTotalApresEndettement)
                      )
                    }}</span>
                    €
                  </p>
                </div>
                <div class="flex flex-row items-center justify-between">
                  <div class="flex flex-row items-center justify-between w-3/5">
                    <p>Par la plus-value de revente</p>
                    <span class="block w-10 h-4 chart-blue-light rounded-md"></span>
                  </div>
                  <p class="text-lg text-black font-base">
                    <span class="text-lg">{{
                      numberWithSpaces(Math.round(projectSelected.results.resFluxAIFonc.plusValueRevente))
                    }}</span>
                    €
                  </p>
                </div>
                <div class="flex flex-row items-center justify-between">
                  <div class="flex flex-row items-center justify-between w-3/5">
                    <div class="flex flex-row">
                      <p>Par l'économie fiscale</p>

                      <QuestionMarkv2
                        v-if="
                          projectSelected.carac.typeexploitation === 'pinel_ancien' ||
                          projectSelected.carac.typeexploitation === 'pinel_neuf'
                        "
                        class="ml-2"
                        :text="`${infobulles.economieFiscalePinel}`"
                      />
                      <QuestionMarkv2
                        v-if="projectSelected.carac.typeexploitation === 'denormandie'"
                        class="ml-2"
                        :text="`${infobulles.economieFiscaleDenormandie}`"
                      />
                    </div>
                    <span class="block w-10 h-4 chart-orange rounded-md"></span>
                  </div>
                  <p class="text-lg text-black font-base">
                    <span class="text-lg">{{ numberWithSpaces(Math.round(tfoot[9])) }}</span>
                    €
                  </p>
                </div>
              </div>
            </div>
            <div class="col-span-6 sm:col-start-5 sm:col-end-7">
              <template>
                <doughnut-chart
                  v-if="Doug1"
                  :style="'width: 150px !important; height: 150px !important'"
                  :chartId="'chart1'"
                  class=""
                  :options="doughnutPieOptions(false)"
                  :chartdata="
                    doughnutChartdata(
                      [
                        'Par revenus locatifs',
                        'Par plus-value de revente',
                        'Par économie fiscale',
                      ],
                      [
                        Math.abs(projectSelected.results.resFluxAIFonc.revenuLocatifTotalApresEndettement),
                        Math.abs(projectSelected.results.resFluxAIFonc.plusValueRevente),
                        Math.abs(tfoot[9]),
                      ],
                      ['#0C1E3B', '#14A0EB', '#eb7b14']
                    )
                  "
                />
                <img v-else src="../../assets/icons/blue_spinner.gif" />
              </template>
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="formeJuridiqueSelected(['sciis', 'sciirr', 'sciirmf', 'sasis'])"
        class="my-4 sm:col-start-2 sm:col-end-12"
      >
        <div class="sm:rounded-md sm:overflow-hidden">
          <div class="relative px-6 py-4 mx-48 my-10 border-2 rounded-lg tips col-span-3 sm:col-span-3">
            <img class="absolute z-0 w-auto max-h-10 ampoule-picto" src="@/assets/picto/light.png" alt="" />
            <h3 class="font-bold">
              Profit généré par associé de la
              {{ projectSelected.carac.formejuridique.filter((val) => val.selected == true)[0].text }}
            </h3>

            <div class="w-full pl-10 mt-4">
              <div class="flex flex-row items-center justify-between mb-6">
                <div class="flex flex-row items-center justify-between w-2/4">
                  <p class="font-semibold">Associés</p>
                  <p class="font-semibold">Capital</p>
                  <!-- <p class="font-semibold">Apport</p> -->
                </div>
                <p class="font-semibold text-right">
                  <span>Profit total réparti</span>
                </p>
              </div>
              <div
                v-for="(input, index) in projectSelected.carac.type_fisc"
                :key="`type_fisc-${index}`"
                class="flex flex-row items-center justify-between"
              >
                <div class="flex flex-row items-center justify-between w-2/4">
                  <p class="font-semibold">Associé {{ Math.abs(index + 1) }}</p>
                  <p class="font-semibold">{{ Math.abs(input.capital) }} %</p>
                  <!-- <p class="font-semibold">{{ Math.abs(input.apport) }} €</p> -->
                </div>
                <p class="text-lg font-bold text-black">
                  <span class="text-lg">{{
                    numberWithSpaces(
                      Math.round(
                        Math.round(projectSelected.results.resFluxAIFonc.resTotCashFlowNonActu) *
                          (Number(input.capital) / 100)
                      )
                    )
                  }}</span>
                  €
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 mb-4 sm:col-start-2 sm:col-end-12">
        <div class="sm:rounded-md">
          <div class="flex items-center px-4 py-5 bg-white sm:py-0 sm:px-6 grid grid-cols-6 gap-6">
            <div
              class="flex flex-col items-start justify-start text-left col-span-6 sm:col-start-1 sm:col-end-5"
            >
              <div class="flex flex-row items-center justify-between w-full">
                <div class="flex flex-row items-center">
                  <h3 class="font-bold">Imposition totale payée sur l’investissement</h3>
                  <QuestionMarkv2
                    class="ml-2"
                    :text="`Montant total d’impôt payé pour cet investissement. Il comprend l’impôt sur le revenu ou les sociétés, selon votre statut ainsi que l’impôt sur la plus-value lors de la revente. (Prise en compte de la taxation sur les dividendes via la flat tax uniquement si l’option a été choisie).`"
                  />
                </div>
                <p class="text-lg font-bold text-lightblueaestima-100">
                  <span class="text-xl">{{
                    numberWithSpaces(
                      -Math.round(projectSelected.results.resFluxAIFonc.resImposition.reduce((a, b) => a + b))
                    )
                  }}</span>
                  €
                </p>
              </div>
              <div class="w-full pl-10 my-2">
                <div class="flex flex-row items-center justify-between">
                  <div class="flex flex-row items-center justify-between w-3/5">
                    <p>Dont impôt payé sur les revenus locatifs</p>
                    <span class="block w-10 h-4 chart-blue rounded-md"></span>
                  </div>
                  <p class="text-lg text-black font-base">
                    <span class="text-lg">{{
                      numberWithSpaces(-1 * Math.round(projectSelected.results.resFluxAIFonc.resTotImpositionRevenusLocatifs))
                    }}</span>
                    €
                  </p>
                </div>
                <div class="flex flex-row items-center justify-between">
                  <div class="flex flex-row items-center justify-between w-3/5">
                    <p>Dont impôt sur la plus-value de revente</p>
                    <span class="block w-10 h-4 chart-blue-light rounded-md"></span>
                  </div>
                  <p class="text-lg text-black font-base">
                    <span class="text-lg"> {{
                      numberWithSpaces(
                        Math.abs(Math.round(projectSelected.results.resFluxAIFonc.resTotImpositionPlusValue))
                      )
                    }}</span>
                    €
                  </p>
                </div>
                <div class="flex flex-row items-center justify-between">
                  <div class="flex flex-row items-center justify-between w-3/5">
                    <p>Dont impôt sur les dividendes perçus</p>
                    <span class="block w-10 h-4 chart-orange rounded-md"></span>
                  </div>
                  <p class="text-lg text-black font-base">
                    <span>{{
                      numberWithSpaces(
                        Math.abs(Math.round(projectSelected.results.resFluxAIFonc.resTotImpositionDividende))
                      )
                    }}</span>
                    €
                  </p>
                </div>

                <!-- <div class="flex flex-row items-center justify-between">
                      <div class="flex flex-row items-center justify-between w-3/5">
                        <p class="font-semibold">Dont impôt sur les dividendes perçus</p>
                        <span class="block w-10 h-4 chart-orange rounded-md"></span>
                      </div>
                      <p class="text-lg text-black font-base"><span class="text-lg">{{Math.round(-projectSelected.results.resFluxAIFonc.resTotVersDiv)}}</span> €</p>
                    </div> -->
              </div>
            </div>
            <div class="col-span-6 sm:col-start-5 sm:col-end-7">
              <template>
                <doughnut-chart
                  v-if="Doug2"
                  :style="'width: 150px !important; height: 150px !important'"
                  :chartId="'chart3'"
                  :options="doughnutPieOptions(false)"
                  :chartdata="
                    doughnutChartdata(
                      [
                        'Sur revenus locatifs',
                        'Sur plus-value de revente',
                        'Sur dividendes perçus',
                      ],
                      [
                        projectSelected.results.resFluxAIFonc.resTotImpositionRevenusLocatifs,
                        projectSelected.results.resFluxAIFonc.resTotImpositionPlusValue,
                        projectSelected.results.resFluxAIFonc.resTotImpositionDividende,
                      ],
                      ['#0C1E3B', '#14A0EB', '#EB7B14']
                    )
                  "
                />
              </template>
            </div>
          </div>
          <div class="mt-10 text-center">
            <a
              @click="showImpositionDetaille"
              class="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-center text-white border border-transparent cursor-pointer leading-6 rounded-md bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
              >Ouvrir le tableau détaillé de l'imposition</a
            >
            <ImpositionDetaille
              v-show="impositionDetaille"
              :dataRow="dataImpositionDetaille"
              :dataTotal="totauxImpositionDetaille"
              :changementDeFiscalite="projectSelected.carac.changementDeFiscalite"
              :engagementPlusTravaux="projectSelected.carac.engagementPlusTravaux"
              @close="closeImpositionDetaille"
            />
          </div>
        </div>
      </div>
      <div
        v-show="formeJuridiqueSelected(['sciis', 'sciirr', 'sciirmf', 'sasis'])"
        class="my-4 sm:col-start-2 sm:col-end-12"
      >
        <div class="sm:rounded-md sm:overflow-hidden">
          <div class="relative px-6 py-4 mx-48 my-10 border-2 rounded-lg tips col-span-3 sm:col-span-3">
            <img class="absolute z-0 w-auto max-h-10 ampoule-picto" src="@/assets/picto/light.png" alt="" />
            <h3 class="font-bold">
              Imposition détaillée par associé de la
              {{ projectSelected.carac.formejuridique.filter((val) => val.selected == true)[0].text }}
            </h3>

            <div class="w-full pl-10 mt-4">
              <div class="flex flex-row items-center justify-between mb-6">
                <div class="flex flex-row items-center justify-between w-2/4">
                  <p class="font-semibold">Associés</p>
                  <p class="font-semibold">Capital</p>
                  <!-- <p class="font-semibold">Apport</p> -->
                </div>
                <p v-show="formeJuridiqueSelected(['sciirr', 'sciirmf'])" class="font-semibold text-right">
                  <span>Impact de l'imposition</span>
                </p>
                <p v-show="formeJuridiqueSelected(['sciirr', 'sciirmf'])" class="font-semibold text-right">
                  <span>Impact de la plus-value </span>
                </p>
                <p v-show="formeJuridiqueSelected(['sciis', 'sasis'])" class="font-semibold text-right">
                  <span>Imposition dividendes</span>
                </p>
              </div>
              <div
                v-for="(input, index) in projectSelected.carac.type_fisc"
                :key="`type_fisc-${index}`"
                class="flex flex-row items-center justify-between"
              >
                <div class="flex flex-row items-center justify-between w-2/4">
                  <p class="font-semibold">Associé {{ Math.abs(index + 1) }}</p>
                  <p class="font-semibold">{{ Math.abs(input.capital) }} %</p>
                  <!-- <p class="font-semibold">{{ Math.abs(input.apport) }} €</p> -->
                </div>
                <!-- <p v-if="formeJuridiqueSelected(['sciis', 'sasis', 'sasuis'])" class="font-bold"><span>{{numberWithSpaces(Math.round(projectSelected.results.resFluxAIFonc.resTotVersDiv) * (Number(input.capital) / 100))}}</span> €</p> -->
                <!-- <template v-else-if="formeJuridiqueSelected(['sciirr'])"> -->
                <template v-if="formeJuridiqueSelected(['sciirr', 'sciirmf'])">
                  <p v-if="index == 0" class="text-lg font-bold text-black">
                    <span
                      >{{
                        numberWithSpaces(
                          Math.abs(
                            Math.round(
                              -projectSelected.results.resFluxAIFonc.resTotImposition -
                                projectSelected.results.resFluxAIFonc.resTotImpositionDividende
                            )
                          ) *
                            (Number(input.capital) / 100)
                        )
                      }}
                    </span>
                    €
                  </p>
                  <p v-else-if="index == 1" class="text-lg font-bold text-black">
                    <span>{{
                      numberWithSpaces(
                        Math.abs(
                          Math.round(
                            -projectSelected.results.resFluxAIFonc.resTotImposition -
                              projectSelected.results.resFluxAIFonc.resTotImpositionDividende
                          ) *
                            (Number(input.capital) / 100)
                        )
                      )
                    }}</span>
                    €
                  </p>
                  <p v-else-if="index == 2" class="text-lg font-bold text-black">
                    <span>{{
                      numberWithSpaces(
                        Math.abs(
                          Math.round(
                            -projectSelected.results.resFluxAIFonc.resTotImposition -
                              projectSelected.results.resFluxAIFonc.resTotImpositionDividende
                          ) *
                            (Number(input.capital) / 100)
                        )
                      )
                    }}</span>
                    €
                  </p>
                  <p v-else-if="index == 3" class="text-lg font-bold text-black">
                    <span>{{
                      numberWithSpaces(
                        Math.abs(
                          Math.round(
                            -projectSelected.results.resFluxAIFonc.resTotImposition -
                              projectSelected.results.resFluxAIFonc.resTotImpositionDividende
                          ) *
                            (Number(input.capital) / 100)
                        )
                      )
                    }}</span>
                    €
                  </p>
                </template>
                <p
                  v-show="formeJuridiqueSelected(['sciirr', 'sciirmf'])"
                  class="text-lg font-bold text-black"
                >
                  <span>{{
                    numberWithSpaces(
                      Math.abs(
                        Math.round(
                          Math.round(projectSelected.results.resFluxAIFonc.resTotPV) *
                            (Number(input.capital) / 100)
                        )
                      )
                    )
                  }}</span>
                  €
                </p>
                <p v-show="formeJuridiqueSelected(['sciis', 'sasis'])" class="text-lg font-bold text-black">
                  <span>{{
                    numberWithSpaces(
                      Math.abs(
                        Math.round(
                          projectSelected.results.resFluxAIFonc.resTotImpositionDividende *
                            (Number(input.capital) / 100)
                        )
                      )
                    )
                  }}</span>
                  €
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN ENRICHISSEMENT LIE a L'INVESTISSEMENT -->
    <!-- ENRICHISSEMENT LIE a L'INVESTISSEMENT -->
    <div class="px-5 py-5 mt-5 card">
      <div class="flex flex-row align-middle card-header">
        <img
          src="@/assets/icons/resultats_financiers/resultat_financier_2.jpeg"
          class="h-20"
          alt="Ratios de rentabilité de l'investissement"
        />
        <div class="flex flex-col my-auto ml-5">
          <p class="text-xl font-bold">Ratios de rentabilité de l'investissement</p>
        </div>
      </div>
      <div class="mt-5 sm:mt-0 sm:col-start-2 sm:col-end-12">
        <div class="sm:rounded-md">
          <div class="flex flex-col px-20 bg-white">
            <div class="items-end justify-items-end grid">
              <div class="sm:col-start-1 sm:col-end-10">
                <div>
                  <p class="flex justify-end my-3">
                    Rendement de l'investissement fiscalité comprise (hors revente)
                    <span 
                      class="ml-10 font-bold"
                      :class="rendementInvestissementFiscaMoyenne <= 0 ? 'text-red-600' : 'text-light'"
                      >{{ rendementInvestissementFiscaMoyenne }} %</span
                    >
                  </p>
                </div>
                <div>
                  <p class="flex justify-end my-3 items-center">
                    Rentabilité nette de charges (avant fiscalité)
                    <span
                      v-if="projectSelected.results.resFluxAIFonc.performance.avecDette.tir !== 0"
                      :class="{
                        'text-red-600':
                          Number(projectSelected.results.resFluxAIFonc.performance.avecDette.tir) <= 0,
                        ' text-lightblueaestima-100 font-bold':
                          Number(projectSelected.results.resFluxAIFonc.performance.avecDette.tir) > 0,
                      }"
                      class="ml-10 font-bold"
                      >
                      <span>
                        {{ projectSelected.results.resFluxAIFonc.performance.avecDette.tir + '%' }}
                      </span>
                    </span>
                    <img
                      v-else
                      class="inline-block w-auto h-10 mx-2 ml-13"
                      alt="symbole infini"
                      src="https://api.iconify.design/ion/infinite.svg?color=%230e9f6e"
                    />
                  </p>
                </div>
                <div
                >
                  <p class="flex justify-end my-3 items-center">
                    Rentabilité nette après fiscalité
                    <QuestionMarkv2
                      class="ml-2"
                      :text="`${infobulles.RentabiliteNetteAprèsCalc}`"
                      :calculatrice="true"
                    />
                    <span
                      v-if="projectSelected.results.resFluxAIFonc.performance.avecFisc.tir !== 0"
                      :class="{
                        'text-red-600':
                          Number(projectSelected.results.resFluxAIFonc.performance.avecFisc.tir) <= 0,
                        ' text-lightblueaestima-100 font-bold':
                          Number(projectSelected.results.resFluxAIFonc.performance.avecFisc.tir) > 0,
                      }"
                      class="ml-10 font-bold"
                    >
                      <span>
                        {{ projectSelected.results.resFluxAIFonc.performance.avecFisc.tir + ' %' }}
                      </span>
                    </span >
                    <img
                      v-else
                      class="inline-block w-auto h-10 mx-2 ml-13"
                      alt="symbole infini"
                      src="https://api.iconify.design/ion/infinite.svg?color=%230e9f6e"
                    />
                    <div 
                      v-if="projectSelected.results.resFluxAIFonc.performance.avecFisc.tir === 0"
                    class="flex flex-col justify-end text-green-500 text-right">
                      <p>Taux de rentabilité infini.</p>
                      <p>Il n'a pas pu être calculé car ce projet n'enregistre aucun flux négatif (en d'autres termes il ne coûte rien à l'investisseur).</p>
                    </div>
                  </p>
                </div>
              </div>
              <div 
                v-if="projectSelected.results.resFluxAIFonc.performance.avecDette.tir !== 0"
                class="flex mb-2 ml-2 sm:col-start-10 sm:col-end-12"
              >
                <img src="@/assets/picto/arrow.png" class="h-10" />
                <p class="pl-10 text-xs">
                  Plus l'écart est grand, plus la fiscalité pèse sur les performances du projet.
                </p>
              </div>
            </div>
            <div>
              <div 
                v-if="projectSelected.results.resFluxAIFonc.performance.avecDette.tir !== 0"
                class="relative px-6 py-4 my-10 border-2 rounded-lg tips col-span-6 sm:col-span-4"
              >
                <img
                  class="absolute z-0 w-auto max-h-10 ampoule-picto"
                  src="@/assets/picto/light.png"
                  alt=""
                />
                <p>
                  Ce qui différencie un rendement d'une rentabilité est la prise en compte, dans le calcul, de
                  la revente du bien.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN ENRICHISSEMENT LIE E L'INVESTISSEMENT -->
    <!-- PLUS DE DETAILS FINANCIERS -->
    <div class="py-5 mt-5 card">
      <div class="md:grid md:grid-cols-12 md:gap-2">
        <div class="sm:col-span-12">
          <div
            @click="showDetailFinance = !showDetailFinance"
            class="relative flex flex-row items-center justify-between px-4 cursor-pointer sm:px-3 sm:mx-20"
          >
            <h2 class="ml-2 font-bold normal-case">Plus de détails financiers</h2>
            <p class="font-semibold text-lightblueaestima-500"> Cliquer pour afficher</p>
            <div>
              <span v-if="!showDetailFinance" class="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="34"
                  height="34"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g id="Layer_1" fill="#444b54">
                      <path
                        d="M123.49063,63.02188l-37.49063,37.49063l-37.49062,-37.49062c-1.6125,-1.6125 -4.16562,-1.6125 -5.64375,0c-1.6125,1.6125 -1.6125,4.16562 0,5.64375l40.3125,40.3125c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82187,-1.20938l40.3125,-40.3125c1.6125,-1.6125 1.6125,-4.16563 0,-5.64375c-1.6125,-1.6125 -4.03125,-1.6125 -5.64375,0z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              <span v-else class="cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="34"
                  height="34"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g id="Layer_1" fill="#444b54">
                      <path
                        d="M42.86563,108.97813c1.6125,1.6125 4.16562,1.6125 5.64375,0l37.49063,-37.49062l37.49063,37.49062c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82188,-1.20938c1.6125,-1.6125 1.6125,-4.16562 0,-5.64375l-40.3125,-40.3125c-1.6125,-1.6125 -4.16563,-1.6125 -5.64375,0l-40.3125,40.3125c-1.6125,1.6125 -1.6125,4.03125 0,5.64375z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div v-if="showDetailFinance == true" class="sm:my-6 sm:col-start-2 sm:col-end-12">
          <div class="sm:rounded-md">
            <div class="flex items-center px-4 py-5 bg-white sm:py-0 sm:px-6 grid grid-cols-6 gap-6">
              <div
                class="flex flex-col items-start justify-start text-left col-span-6 sm:col-start-1 sm:col-end-5"
              >
                <div class="w-full my-2">
                  <div class="flex flex-row items-center justify-between mb-2">
                    <div class="flex flex-row">
                      <div class="flex flex-row items-center">
                        <p class="font-semibold">Frais de remboursement anticipé de l’emprunt (IRA)</p>
                        <QuestionMarkv2 class="ml-2" :text="`${infobulles.fraisRemboursementAnticipe}`" />
                        <QuestionMarkv2
                          class="ml-2"
                          :text="`${infobulles.fraisRemboursementAnticipeCalc}`"
                          :calculatrice="true"
                        />
                      </div>
                    </div>
                    <p v-if="isPaid" class="text-lg text-black font-base">
                      <span class="text-lg">{{
                        numberWithSpaces(
                          Math.round(-projectSelected.results.resInvTravFonc.resTotIndemRmbDette)
                        )
                      }}</span>
                      €
                    </p>
                    <img v-else class="w-1/12" src="@/assets/blur/blur-text.png" alt="image floutée map" />
                  </div>
                  <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row">
                      <div class="flex flex-row items-center">
                        <p class="font-semibold">Perte de revenus liée à la vacance locative</p>
                        <QuestionMarkv2
                          class="ml-2"
                          :text="`Valeur des loyers perdus à cause du manque de locataires ou délai trop important entre deux locataires.`"
                        />
                      </div>
                    </div>
                    <p v-if="isPaid" class="text-lg text-black font-base">
                      <span class="text-lg">{{
                        numberWithSpaces(
                          Math.round(
                            ((this.projectSelected.carac.loyermensuel * 12) / 365) *
                              this.projectSelected.carac.rvacanceloc.val
                          )
                        )
                      }}</span>
                      €
                    </p>
                    <img v-else class="w-1/12" src="@/assets/blur/blur-text.png" alt="image floutée map" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="showDetailFinance" class="mt-2 sm:mt-0 sm:col-start-2 sm:col-end-12">
          <div class="sm:rounded-md">
            <div class="flex items-center px-4 py-5 bg-white sm:py-0 sm:px-6 grid grid-cols-6 gap-6">
              <div
                class="flex flex-col items-start justify-start text-left col-span-6 sm:col-start-1 sm:col-end-5"
              >
                <div class="flex flex-row items-center justify-between w-full">
                  <div class="flex flex-row items-center">
                    <h3 class="font-bold">Coût total de l’investissement</h3>
                    <QuestionMarkv2 class="ml-2" :text="`${infobulles.coutTotalInvestissement}`" />
                    <QuestionMarkv2
                      class="ml-2"
                      :text="`${infobulles.coutTotalInvestissementCalc}`"
                      :calculatrice="true"
                    />
                  </div>
                  <p v-if="isPaid" class="text-lg font-bold text-lightblueaestima-100">
                    <span class="text-xl">{{ numberWithSpaces(coutTotInvestCalc) }}</span>
                    €
                  </p>
                  <img v-else class="w-1/6" src="@/assets/blur/blur-title.png" alt="image floutée map" />
                </div>
                <div class="w-full my-4">
                  <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center justify-between w-3/5">
                      <p class="font-base">Prix d’achat du bien</p>
                      <span class="block w-10 h-4 chart-blue rounded-md"></span>
                    </div>
                    <p v-if="isPaid" class="text-lg text-black font-base">
                      <span class="text-lg">{{
                        numberWithSpaces(
                          Math.round(
                            projectSelected.results.resCoutTotalFonc.resPrixAchat.reduce((a, b) => a + b)
                          )
                        )
                      }}</span>
                      €
                    </p>
                    <img v-else class="w-1/12" src="@/assets/blur/blur-text.png" alt="image floutée map" />
                  </div>
                  <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center justify-between w-3/5">
                      <p class="font-base">Frais d’acquisition du bien</p>
                      <span class="block w-10 h-4 chart-red rounded-md"></span>
                    </div>
                    <p v-if="isPaid" class="text-lg text-black font-base">
                      <span class="text-lg">{{ numberWithSpaces(fraisAcquisitionBien) }}</span>
                      €
                    </p>
                    <img v-else class="w-1/12" src="@/assets/blur/blur-text.png" alt="image floutée map" />
                  </div>
                  <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center justify-between w-3/5">
                      <p class="font-base">Frais d’exploitation et travaux</p>
                      <span class="block w-10 h-4 chart-blue-light rounded-md"></span>
                    </div>
                    <p v-if="isPaid" class="text-lg text-black font-base">
                      <span class="text-lg">{{ numberWithSpaces(fraisExploitTravaux) }}</span>
                      €
                    </p>
                    <img v-else class="w-1/12" src="@/assets/blur/blur-text.png" alt="image floutée map" />
                  </div>
                  <div v-if='coutEmprunt != 0' class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center justify-between w-3/5">
                      <p class="font-base">Coût de l’emprunt</p>
                      <span class="block w-10 h-4 chart-yellow rounded-md"></span>
                    </div>
                    <p v-if="isPaid" class="text-lg text-black font-base">
                      <span class="text-lg">{{ numberWithSpaces(coutEmprunt) }}</span>
                      €
                    </p>
                    <img v-else class="w-1/12" src="@/assets/blur/blur-text.png" alt="image floutée map" />
                  </div>
                  <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center justify-between w-3/5">
                      <p class="font-base">Impôts hors année de revente</p>
                      <span class="block w-10 h-4 chart-grey rounded-md"></span>
                    </div>
                    <p v-if="isPaid" class="text-lg text-black font-base">
                      <span class="text-lg">{{ numberWithSpaces(impotHorsAnneeReventeComputed) }}</span>
                      €
                    </p>
                    <img v-else class="w-1/12" src="@/assets/blur/blur-text.png" alt="image floutée map" />
                  </div>
                </div>
              </div>

              <div v-if="isPaid" class="col-span-6 sm:col-start-5 sm:col-end-7">
                <div>
                  <doughnut-chart
                    :chartId="'chart2'"
                    class="h-48"
                    :options="doughnutPieOptions(false)"
                    :chartdata="
                      doughnutChartdata(
                        [
                          'Prix d’achat du bien: %',
                          'Frais d’acquisition du bien: %',
                          'Frais d’exploitation et travaux: %',
                          'Coût de l’emprunt: %',
                          'Impôts hors année de revente: %',
                        ],
                        [
                          projectSelected.results.resCoutTotalFonc.resPrixAchat.reduce((a, b) => a + b),
                          fraisAcquisitionBien,
                          fraisExploitTravaux,
                          coutEmprunt,
                          impotHorsAnneeReventeComputed,
                        ],
                        ['#0C1E3B', '#F91515', '#14A0EB', 'rgba(235,175,20,0.6)', 'rgba(95,95,95,0.4)']
                      )
                    "
                  />
                </div>
              </div>
              <img
                v-else
                class="col-span-6 sm:col-start-5 sm:col-end-7"
                src="@/assets/blur/blur-graph.png"
                alt="image floutée map"
              />
            </div>
          </div>
        </div>
        <!-- Sorti de la page depuis point C.1 -->
        <!-- <div v-if="showDetailFinance" class="mt-5 sm:mt-0 sm:col-start-2 sm:col-end-12">
            <div class="sm:rounded-md">
              <div class="flex items-center px-4 py-5 bg-white sm:py-0 sm:px-6 grid grid-cols-6 gap-6">
                <div class="flex flex-col items-start justify-start text-left col-span-6 sm:col-start-1 sm:col-end-5">
                  <div class="flex flex-row items-center">
                    <h3 class="font-bold">Mode de financement de l’investissement</h3>
                    <QuestionMarkv2
                      class="ml-2"
                      :text="`Permet d’analyser de quelle manière vous avez payé toutes les charges liées à votre investissement : par les loyers ou par votre épargne.`"
                    />
                    <QuestionMarkv2 class="float-right ml-2" :text="`${infobulles.profitTotalInvestissement}`" :calculatrice="true" />

                  </div>
                  <div class="w-full pl-10 my-2">
                    <div class="flex flex-row items-center justify-between">
                      <div class="flex flex-row items-center justify-between w-3/5">
                        <p class="font-semibold">Financé par les revenus locatifs</p>
                        <span class="block w-10 h-4 chart-blue rounded-md"></span>
                      </div>
                      <p v-if="isPaid" class="text-lg text-black font-base">
                        <span class="text-lg">{{
                          numberWithSpaces(Math.round(projectSelected.results.resOperationNetFonc.resTotRevLoc))
                        }}</span>
                        €
                      </p>
                      <img v-else class="w-1/12" src="@/assets/blur/blur-text.png" alt="image floutée map" />
                    </div>
                    <div class="flex flex-row items-center justify-between">
                      <div class="flex flex-row items-center justify-between w-3/5">
                        <p class="font-semibold">Financé par l’apport d’épargne</p>
                        <span class="block w-10 h-4 chart-blue-light rounded-md"></span>
                      </div>
                      <p v-if="isPaid" class="text-lg text-black font-base">
                        <span class="text-lg">{{
                          numberWithSpaces(-Math.round(projectSelected.results.resFluxAIFonc.resTotSommeInvest))
                        }}</span>
                        €
                      </p>
                      <img v-else class="w-1/12" src="@/assets/blur/blur-text.png" alt="image floutée map" />
                    </div>
                  </div>
                </div>
                <div v-if="isPaid" class="col-span-6 sm:col-start-5 sm:col-end-7">
                  <template>
                    <doughnut-chart
                      :width="150"
                      :height="150"
                      class=""
                      :options="doughnutPieOptions(false)"
                      :chartdata="
                        doughnutChartdata(
                          ['Financé par les revenus locatifs: %', 'Dont impôt sur la plus-value de revente: %'],
                          [],
                          [
                            Math.round(
                              (Math.round(-Math.round(projectSelected.results.resOperationNetFonc.resTotRevLoc)) *
                                100) /
                                Math.round(
                                  projectSelected.results.resOperationNetFonc.resTotRevLoc -
                                    projectSelected.results.resFluxAIFonc.resTotSommeInvest
                                )
                            ),
                            Math.round(
                              (-Math.round(projectSelected.results.resFluxAIFonc.resTotSommeInvest) * 100) /
                                Math.round(
                                  projectSelected.results.resOperationNetFonc.resTotRevLoc -
                                    projectSelected.results.resFluxAIFonc.resTotSommeInvest
                                )
                            ),
                          ],
                          ['#0C1E3B', '#14A0EB']
                        )
                      "
                    />
                  </template>
                </div>
                <img
                  v-else
                  class="col-span-6 sm:col-start-5 sm:col-end-7"
                  src="@/assets/blur/blur-graph.png"
                  alt="image floutée map"
                />
              </div>
            </div>
          </div> -->
        <div v-if="showDetailFinance" class="mt-5 sm:mt-0 sm:col-start-2 sm:col-end-12">
          <div class="sm:rounded-md">
            <div class="items-center px-4 py-5 bg-white sm:py-8 sm:px-6 grid grid-cols-6 gap-2">
              <div class="flex flex-col items-start justify-start text-left sm:col-start-1 sm:col-end-6">
                <h3 class="font-bold">Ratios et indicateurs financiers</h3>
              </div>
              <div class="flex flex-row items-center justify-between col-span-6 sm:col-start-1 sm:col-end-5">
                <div class="flex flex-row items-center justify-between w-full">
                  <div class="flex flex-row items-center">
                    <p class="text-sm">Taux de rendement interne de l’investissement (TIR)</p>
                    <QuestionMarkv2
                      class="ml-2"
                      :text="`Ratio financier qui permet de connaître la rentabilité finale de l’investissement en tenant compte de l’impact de l’inflation sur les cash-flows annuels (dépend donc du taux d’actualisation inscrit dans la fiche investisseur). Plus d’infos dans le <u><a target='_blank' href='/lexique'>lexique</a></u>.`"
                    />
                  </div>
                  <p
                    v-if="isPaid"
                    :class="{
                      'text-green-500': projectSelected.results.resFluxAIFonc.performance.avecFisc.tir === 0,
                      'text-red-600': Number(projectSelected.results.resFluxAIFonc.performance.tir) < 0,
                      'text-lightblueaestima-100':
                        Number(projectSelected.results.resFluxAIFonc.performance.tir) > 0,
                    }"
                    class="text-lg font-bold"
                  >
                    <span class="">{{
                      Number(projectSelected.results.resFluxAIFonc.performance.tir) == 0
                        ? (projectSelected.results.resFluxAIFonc.resCashFlowActu[0] > 0 ? 'infini' : 'Négatif')
                        : projectSelected.results.resFluxAIFonc.performance.tir + '%'
                    }}</span>
                  </p>
                  <img v-else class="w-1/12" src="@/assets/blur/blur-title.png" alt="image floutée map" />
                </div>
              </div>
              <div
                v-if="isPaid"
                class="flex flex-col items-start justify-start overflow-hidden text-left col-span-6 sm:col-start-5 sm:col-end-7"
              >
                <progress-bar
                  v-show="Number(projectSelected.results.resFluxAIFonc.performance.tir) != 0"
                  class="w-full"
                  :value="Number(projectSelected.results.resFluxAIFonc.performance.tir)"
                  bar-class="bg-info"
                />
                <span class="text-sm"
                  v-if="projectSelected.results.resFluxAIFonc.performance.avecFisc.tir > 0"
                >Indicateur sur 100%</span>
              </div>
              <img
                v-else
                class="w-2/3 col-span-6 sm:col-start-5 sm:col-end-7"
                src="@/assets/blur/blur-loadbar.png"
                alt="image floutée map"
              />
              <div class="flex flex-row items-center justify-between col-span-6 sm:col-start-1 sm:col-end-5">
                <div class="flex flex-row items-center justify-between w-full mb-3">
                  <div class="flex flex-row items-center">
                    <p class="text-sm">VAN (Valeur Actuelle Nette) ou profit total actualisé</p>
                    <QuestionMarkv2 class="ml-2" :text="`${infobulles.VAN}`" />
                    <QuestionMarkv2 class="ml-2" :text="`${infobulles.VANCalc}`" :calculatrice="true" />
                  </div>
                  <p v-if="isPaid" class="text-lg font-bold text-lightblueaestima-100">
                    <span class="">{{
                      numberWithSpaces(Math.round(projectSelected.results.resFluxAIFonc.resTotCashFlowActu))
                    }}</span>
                    €
                  </p>
                  <img v-else class="w-1/12" src="@/assets/blur/blur-title.png" alt="image floutée map" />
                </div>
              </div>
              <div class="flex flex-row items-center justify-between col-span-6 sm:col-start-1 sm:col-end-5">
                <div class="flex flex-row items-center justify-between w-full">
                  <div class="flex flex-row items-center">
                    <p class="text-sm">Indice de profitabilité de l’investissement</p>
                    <QuestionMarkv2 class="ml-2" :text="`${infobulles.indiceProfitabilite}`" />
                    <QuestionMarkv2
                      class="ml-2"
                      :text="`${infobulles.indiceProfitabiliteCalc}`"
                      :calculatrice="true"
                    />
                  </div>
                  <p v-if="isPaid" class="text-lg font-bold text-lightblueaestima-100">
                    <span :class="{'text-green-500': isInfinityOrNegativeInfinity}">{{
                      isInfinityOrNegativeInfinity ? "Infini" :
                      (
                        projectSelected.results.resFluxAIFonc.resTotCashFlowActu /
                        -projectSelected.results.resFluxAIFonc.resTotSommeInvest +
                        1
                      ).toFixed(2)
                    }}
                    </span>
                  </p>
                  <img v-else class="w-1/12" src="@/assets/blur/blur-title.png" alt="image floutée map" />
                </div>
              </div>
              <div class="flex flex-row items-center justify-between col-span-6 sm:col-start-1 sm:col-end-5">
                <div class="flex flex-row justify-between w-full">
                  <div class="flex flex-row items-center">
                    <p class="text-sm">Effet de levier de l’emprunt sur l’investissement</p>
                    <QuestionMarkv2 class="ml-2" :text="`${infobulles.effetLevier}`" />
                    <QuestionMarkv2
                      class="ml-2"
                      :text="`${infobulles.effetLevierCalc}`"
                      :calculatrice="true"
                    />
                  </div>
                  <p v-if="isPaid" class="text-lg font-bold text-lightblueaestima-100">
                    <span 
                      :class="{
                        'text-green-500': projectSelected.results.resFluxAIFonc.performance.avecDette.effetlevier && 
                                          projectSelected.results.resFluxAIFonc.performance.avecFisc.tir <= 0
                      }"
                    >
                      {{
                        projectSelected.results.resFluxAIFonc.performance.avecDette.effetlevier
                          ? (projectSelected.results.resFluxAIFonc.performance.avecFisc.tir > 0
                              ? Number(projectSelected.results.resFluxAIFonc.performance.avecDette.effetlevier.toFixed(2)) + '%'
                              : 'Infini')
                          : '0%'
                      }}
                    </span>
                  </p>
                  <img v-else class="w-1/12" src="@/assets/blur/blur-title.png" alt="image floutée map" />
                </div>
              </div>
              <div
                v-if="isPaid"
                class="flex flex-col items-start justify-start overflow-hidden text-left col-span-6 sm:col-start-5 sm:col-end-7"
              >
                <progress-bar
                  v-if="projectSelected.results.resFluxAIFonc.performance.avecFisc.tir > 0"
                  class="w-full"
                  :value="
                    projectSelected.results.resFluxAIFonc.performance.avecDette.effetlevier
                      ? Number(
                          projectSelected.results.resFluxAIFonc.performance.avecDette.effetlevier.toFixed(2)
                        )
                      : 0
                  "
                  bar-class="bg-info"
                />
                <span class="text-sm"
                  v-if="projectSelected.results.resFluxAIFonc.performance.avecFisc.tir > 0"
                >Indicateur sur 100%</span>
              </div>
              <img
                v-else
                class="w-2/3 col-span-6 sm:col-start-5 sm:col-end-7"
                src="@/assets/blur/blur-loadbar.png"
                alt="image floutée map"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN PLUS DE DETAILS FINANCIERS -->
    <div class="px-5 py-5 mt-5 card">
      <div class="flex flex-row mb-10 align-middle card-header">
        <img
          src="@/assets/icons/resultats_financiers/resultat_financier_4.jpeg"
          class="h-20"
          alt="Trésorerie de l'investissement"
        />
        <div class="flex flex-col my-auto ml-5">
          <p class="text-xl font-bold">Trésorerie de l'investissement</p>
        </div>
      </div>
      <div class="mt-5 sm:mt-0 sm:col-start-2 sm:col-end-12">
        <div class="sm:rounded-md">
          <div class="flex items-center bg-white grid grid-cols-12 gap-6">
            <div class="flex flex-col justify-center col-span-4 sm:col-start-1 sm:col-end-5">
              <div class="flex flex-col items-center w-full">
                <div class="flex flex-row">
                  <p class="text-xs text-center font-base">
                    <span class="text-base font-semibold">Apport à fournir à l’achat</span>
                    <br />avant d’encaisser des loyers et de payer des charges
                  </p>
                  <QuestionMarkv2 class="ml-2" :text="`${infobulles.apportAchat}`" />
                  <QuestionMarkv2 class="ml-2" :text="`${infobulles.apportAchatCalc}`" :calculatrice="true" />
                </div>
                <p class="text-lg font-bold text-lightblueaestima-100">
                  <span class="text-xl">{{ numberWithSpaces(Math.round(apportAFournir)) }}</span>
                  €
                </p>
              </div>
              <!-- <div class="flex line-div">
                <div class="line-up"></div>
              </div> -->
            </div>

            <div class="flex flex-col justify-center pr-10 col-span-4 sm:col-start-5 sm:col-end-9">
              <div class="flex flex-col items-center w-full">
                <div class="flex flex-row">
                  <div class="text-xs text-center font-base">
                    <div class="text-base font-semibold">Cash-flow mensuel</div>
                    <div v-if="projectSelected.carac.modefinancement != 'fond_propre'">
                      durant le remboursement de l’emprunt
                    </div>
                  </div>
                  <QuestionMarkv2
                    v-if="projectSelected.carac.modefinancement != 'fond_propre'"
                    class="ml-2"
                    :text="`${infobulles.cashflowDurant}`"
                  />
                  <QuestionMarkv2
                    v-if="projectSelected.carac.modefinancement != 'fond_propre'"
                    class="ml-2"
                    :text="`${infobulles.cashflowDurantCalc}`"
                    :calculatrice="true"
                  />
                </div>
                <div class="flex flex-row items-center">
                  <p class="text-lg font-bold text-lightblueaestima-100">
                    <span class="text-xl">{{ numberWithSpaces(cashFlowComputed[0]) }}</span>
                    €
                  </p>
                  <!-- <div class="z-40 wrapper" v-if="messageStrategy[strategyIndex].cashflowMens != ''">
                    <img class="inline-block w-auto h-6 mx-2" src="@/assets/picto/Panneau.png" alt="pictogramme panneau attention" />
                    <div class="tooltip" v-html="messageStrategy[strategyIndex].cashflowMens"></div>
                  </div> -->
                </div>
              </div>
            </div>

            <div
              class="flex flex-col justify-center pl-10 col-span-4 sm:col-start-9 sm:col-end-12"
              v-if="projectSelected.carac.dureeempr.val < projectSelected.carac.dureedetention.val"
            >
              <div class="flex flex-col items-center w-full">
                <div class="flex flex-row">
                  <p class="text-xs text-center font-base">
                    <span class="text-base font-semibold">Cash-flow mensuel</span>
                    <br />après remboursement de l’emprunt
                  </p>
                  <QuestionMarkv2
                    class="ml-2"
                    :text="`Montant que vous gagnerez (+) ou devrez rajouter de votre poche (-) chaque mois pour financer le bien et ses charges après avoir fini de  rembourser votre emprunt bancaire.`"
                    :calculatrice="true"
                  />
                </div>
                <p class="text-lg font-bold text-lightblueaestima-100">
                  <span class="text-xl">{{ numberWithSpaces(cashFlowComputed[1]) }}</span>
                  €
                </p>
              </div>
            </div>
          </div>
          <img
            src="@/assets/icons/resultats_financiers/80_emprunt.png"
            alt="CashFlow"
            v-if="
              projectSelected.carac.modefinancement != 'fond_propre' &&
              projectSelected.carac.dureeempr.val < projectSelected.carac.dureedetention.val
            "
          />
          <img
            src="@/assets/icons/resultats_financiers/100_emprunt.png"
            alt="CashFlow"
            v-if="
              projectSelected.carac.modefinancement != 'fond_propre' &&
              (projectSelected.carac.dureeempr.val > projectSelected.carac.dureedetention.val ||
                projectSelected.carac.dureeempr.val == projectSelected.carac.dureedetention.val)
            "
          />
          <img
            src="@/assets/icons/resultats_financiers/100_fonds_propre.png"
            alt="CashFlow"
            v-if="projectSelected.carac.modefinancement == 'fond_propre'"
          />

          <div
            class="flex flex-col items-start justify-start mt-10 text-left col-span-6 sm:col-start-1 sm:col-end-5"
          >
            <div class="flex flex-row items-center w-full">
              <div class="flex flex-col">
                <div class="flex">
                  <p class="text-base font-semibold">Effort d’épargne total</p>
                  <QuestionMarkv2 class="ml-2" :text="`${infobulles.effortEpargneTotal}`" />
                  <QuestionMarkv2
                    class="ml-2"
                    :text="`${infobulles.effortEpargneTotalCalc}`"
                    :calculatrice="true"
                  />
                </div>
                <p>Soit l'argent sorti de la poche de votre client durant toute la vie du projet</p>
              </div>
              <p class="ml-5 text-lg font-bold text-lightblueaestima-100">
                <span class="text-xl">{{
                  numberWithSpaces(-Math.round(projectSelected.results.resFluxAIFonc.resTotSommeInvest))
                }}</span>
                €
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionMarkv2 from '@/components/SVG/QuestionMarkv2'
import InfoLine from '@/components/result/InfoLine'
import Map from '@/components/Map/Map.vue'
import MapPolygon from '@/components/Map/MapPolygon.vue'
import BarChart from '@/components/Charts/BarChart.vue'
import PieChart from '@/components/Charts/PieChart.vue'
import { mapGetters } from 'vuex'
import populationEvolution from '@/assets/cityData/populationEvolution.json'
import DoughnutChart from '@/components/Charts/DoughnutChart.vue'
import ImpositionDetaille from '@/components/popup/ImpositionDetaille'
import ProgressBar from '@/components/Charts/ProgressBar.vue'

export default {
  name: 'ResultatFinancier',
  components: {
    QuestionMarkv2,
    InfoLine,
    Map,
    MapPolygon,
    PieChart,
    BarChart,
    DoughnutChart,
    ImpositionDetaille,
    ProgressBar,
  },
  props: {
    cashFlowComputed: {
      type: Array,
    },
    apport: {
      type: Number,
    },
    chartDatas: {
      type: Array,
    },
    salePrice: {
      type: Object,
    },
    showLinear: {
      type: Boolean,
    },
    dvf: {
      type: Array,
    },
    indexListDVF: {
      type: Number || null,
    },
    messageStrategy: {
      type: Array,
    },
    strategyIndex: {
      type: Number || null,
    },
    chartLabels: {
      type: Array,
    },
    totauxImpositionDetaille: {
      type: Array,
    },
    dataImpositionDetaille: {
      type: Array,
    },
    thead: {
      type: Array,
    },
    tfoot: {
      type: Array,
    },
    getResult: {
      type: Array,
    },
    rendementInvestissementFiscaMoyenne: {
      type: [String, Number, null],
    },
  },
  created() {
  },
  mounted() {
    this.center = [
      this.projectSelected.loc.carac.coordinates[1],
      this.projectSelected.loc.carac.coordinates[0],
    ]
    // lignes obligatoire sinon il n'a pas le temps d'aller chercher les données
    this.Doug1 = true
    this.Doug2 = true
  },
  computed: {
    ...mapGetters({
      projectSelected: 'projects/selected',
      investSelected: 'invests/selected',
      user: 'users/user',
    }),
    fraisAcquisitionBien() {
      return Math.round(
        this.projectSelected.results.resCoutTotalFonc.resFraisNotaire.reduce((a, b) => a + b) +
          -this.projectSelected.results.resCoutTotalFonc.resFraisAgenceImmo.reduce((a, b) => a + b) +
          this.projectSelected.results.resCoutTotalFonc.resTVA.reduce((a, b) => a + b)
      )
    },
    isInfinityOrNegativeInfinity() {
      const value = this.projectSelected.results.resFluxAIFonc.resTotCashFlowActu /
                    -this.projectSelected.results.resFluxAIFonc.resTotSommeInvest +
                    1;
      return value === Infinity || value === -Infinity;
    },
    fraisExploitTravaux() {
      return -Math.round(
        this.projectSelected.results.resOperationNetFonc.resTotFraisExploit +
          this.projectSelected.results.resOperationNetFonc.resTotStructureSCI +
          this.projectSelected.results.resInvTravFonc.resTotalTravauxConstruction +
          this.projectSelected.results.resInvTravFonc.resTotalTravauxRenovation
      )
    },
    coutEmprunt() {
        if (this.projectSelected.carac.modefinancement == 'fond_propre') {
          return 0
        } else {
          return Math.round(Math.abs(
        this.projectSelected.results.resInvTravFonc.resTotFraisBanc +
          this.projectSelected.results.resInvTravFonc.resTotItr +
          this.totalAssurance +
          this.projectSelected.results.resInvTravFonc.resTotIndemRmbDette
      ))
        }
    },
    totalAssurance() {
      let total = 0
      this.projectSelected.results.resInvTravFonc.resAssEmpr.forEach((d, i) => {
        if (this.projectSelected.carac.amortData[i]) {
          total +=
            this.projectSelected.carac.amortData[i].amortAssur *
            this.projectSelected.results.timelineFonc.resTimelineEmpr[i]
        }
      })
      return -total
    },
    apportAFournir() {
      return (
        Math.abs(this.projectSelected.results.resCoutTotalFonc.resPrixAchat[0]) +
        Math.abs(this.projectSelected.results.resCoutTotalFonc.resFraisNotaire[0]) +
        Math.abs(this.projectSelected.results.resCoutTotalFonc.resFraisAgenceImmo[0]) +
        Math.abs(this.projectSelected.results.resCoutTotalFonc.resTVA[0]) +
        Math.abs(Number(this.projectSelected.carac.travauxReno.year_0)) +
        Math.abs(Number(this.projectSelected.carac.travauxConstru.year_0)) +
        Math.abs(Number(this.fraisAmeublement())) +
        Math.abs(this.projectSelected.results.resInvTravFonc.resFraisBanc[0]) -
        Math.abs(this.projectSelected.results.resInvTravFonc.resEmprunt[0])
      )
    },
    impotHorsAnneeReventeComputed: {
      get: function () {
        let impot_total = this.projectSelected.results.resFluxAIFonc.resImposition.reduce((a, b) => a + b)
        let plus_value = this.projectSelected.results.resFluxAIFonc.resTotImpositionPlusValue
        if (impot_total < 0) {
          impot_total = -impot_total
        }
        if (plus_value > 0) {
          plus_value = -plus_value
        }
        return Math.round(impot_total + plus_value)
      },
      set: function (val) {},
    },
    coutTotInvestCalc: {
      get: function () {
        let ct =
          Math.round(this.projectSelected.results.resCoutTotalFonc.resPrixAchat.reduce((a, b) => a + b)) +
          this.fraisAcquisitionBien +
          this.fraisExploitTravaux +
          this.coutEmprunt +
          this.impotHorsAnneeReventeComputed
        return ct
      },
      set: function (val) {},
    },
    loyerMoyen() {
      return this.projectSelected.carac.loyermoy
    },
    getEvolutionPopulation() {
      return ((this.populations.PMUN18 - this.populations.PSDC99) / this.populations.PSDC99).toFixed(2)
    },
    getDataPopulation() {
      this.populations = Object.values(populationEvolution).find(
        (data) => data.CODGEO == this.projectSelected.loc.carac.citycode
      )
      this.chartDatasPopulation.push(
        this.populations.PSDC99,
        this.populations.PMUN06,
        this.populations.PMUN07,
        this.populations.PMUN08,
        this.populations.PMUN09,
        this.populations.PMUN10,
        this.populations.PMUN11,
        this.populations.PMUN12,
        this.populations.PMUN13,
        this.populations.PMUN14,
        this.populations.PMUN15,
        this.populations.PMUN16,
        this.populations.PMUN17,
        this.populations.PMUN18
      )
      return this.chartDatasPopulation
    },
    titleCategBien: {
      get: function () {
        let categName = ''
        if (this.projectSelected.carac.categ == 'appartement') {
          categName = 'Appartements'
        } else if (this.projectSelected.carac.categ == 'maison_individuelle') {
          categName = 'Maisons'
        } else if (this.projectSelected.carac.categ == 'immeuble_residentiel') {
          categName = 'Appartements'
        } else if (this.projectSelected.carac.categ == 'immeuble_de_rapport') {
          categName = 'Appartements'
        }

        return categName
      },
      set: function (val) {},
    },
    showPositif: {
      get: function () {
        return true
      },
      set: function (val) {},
    },
    showNegatif: {
      get: function () {
        return true
      },
      set: function (val) {},
    },
    showConseil: {
      get: function () {
        return true
      },
      set: function (val) {},
    },
    isPaid: {
      get: function () {
        return this.user.sub_level != 0 || this.projectSelected.isPaid == true ? true : false
      },
      set: function (val) {},
    },
    sameAdresse: {
      get: function () {
        let arrayAdresse = []
        arrayAdresse = this.dvf.filter((v, i, a) => {
          return (
            this.dvf.findIndex(
              (t) => t.properties.lat === v.properties.lat && t.properties.lon === v.properties.lon
            ) === i
          )
        })

        arrayAdresse.map((value, index) => {
          arrayAdresse[index].sameAdresse = this.dvf.filter((data) => {
            return value.properties.lon == data.properties.lon && value.properties.lat == data.properties.lat
          })
        })

        console.log('arrayAdresse =========>', arrayAdresse)
        return arrayAdresse
      },
      set: function (val) {},
    },
  },
  data: function () {
    return {
      Doug1: false,
      Doug2: false,
      parEcoFisc: 0,
      center: [],
      inseeYear: '2017',
      chartLabelsPopulation: [
        '1999',
        '2006',
        '2007',
        '2008',
        '2009',
        '2010',
        '2011',
        '2012',
        '2013',
        '2014',
        '2015',
        '2016',
        '2017',
        '2018',
      ],
      chartDatasPopulation: [],
      populations: {},
      impositionDetaille: false,
      showDetailFinance: false,
      infobulles: {
        profitTotalInvestissement:
          'Représente la somme gagnée au terme de votre investissement, revente comprise.',
        profitTotalInvestissementCalc:
          "Pour expliquer simplement à votre client de quoi est composé le profit, il suffit de faire :<br> Somme des loyers durant toute la durée de détention + prix de revente de l’investissement – prix d’achat frais compris - somme des charges locatives – sommes des charges d’emprunt – imposition totale du projet.<br><br>Le profit est aussi égal au prix de revente (après déduction de l'impôt sur la plus-value) auquel on soustrait l’effort d’épargne fourni tout au long du projet.",
        fraisRemboursementAnticipe:
          'IRA pour Indemnités de remboursement anticipé de l’emprunt. Ce sont des frais à payer à la banque si votre client décide de rembourser son emprunt avant la date d’échéance. Selon leurs accords, il peut ne pas y en avoir.',
        fraisRemboursementAnticipeCalc:
          'Représente 6 mois d’intérêts d’emprunt calculés sur le capital restant dû.',
        coutTotalInvestissement:
          "Calculé sur toute la durée de vie de l’investissement, ce résultat permet de connaître le poids de chaque catégorie de charges sur le coût total de l'investissement.",
        coutTotalInvestissementCalc:
          'Coût total d’acquisition + toutes les charges locatives payées durant toute la durée de détention du bien + toutes les charges liées à l’emprunt payées durant toute la durée de détention du bien + fiscalité payée durant toute la durée de détention du bien.',
        apportAchat:
          "Calculé en déduisant le montant prêté par la banque du coût total de l’investissement.<br>Selon le département dans lequel vous investissez, vous pouvez avoir droit à des aides. <br>Plus d’information sur : <u><a target='_blank' href='https://www.anil.org/aides-locales/'>www.anil.org/aides-locales/</a></u>",
        // apportAchat: "Selon le département dans lequel vous investissez, vous pouvez avoir droit à des aides. <br>Plus d’information sur : <u><a target='_blank' href='https://www.anil.org/aides-locales/'>www.anil.org/aides-locales/</a></u>",
        apportAchatCalc:
          'Coût total d’acquisition + travaux à prévoir à l’achat + frais d’obtention d’emprunt (dossier et garantie) - montant emprunté',
        modeFinancementOperation:
          '• Financé par les revenus locatifs : Somme des loyers perçus durant toute la durée de détention du bien. Dans ce calcul, on ne déduit pas les charges. \n• Financé par l’apport d’épargne : Coût total de l’investissement – financement par les revenus locatifs – financement par l’économie fiscale (dans le cas où elle est présente).',
        VAN: 'Correspond au profit total généré par l’investissement en tenant compte de l’impact de l’inflation sur les cash-flows annuels (dépend donc du taux d’actualisation inscrit dans le formulaire simulation).',
        VANCalc:
          'Pour chaque année, on dévalue chaque flux de trésorerie avec le taux d’actualisation saisi dans la simulation. Dans ce calcul, l’année est inscrite en puissance. Ainsi, plus le flux de trésorerie est perçu dans le futur et plus il est dévalué. On peut le comparer à l’effet de l’inflation sur une pièce de 1€. Plus on attend et moins cette pièce a de valeur. On fait ensuite la somme de ces flux dits « actualisés ». On obtient ainsi la Valeur Actuelle Nette (VAN).',
        economieFiscalePinel:
          'Impôts totaux que votre client aura économisé grâce au dispositif fiscal Pinel.',
        economieFiscaleDenormandie:
          'Impôts totaux que votre client aura économisé grâce au dispositif fiscal Denormandie.',
        effetLevier:
          "Ratio permettant d'analyser l'impact de l’emprunt sur notre investissement. Plus ce pourcentage est important et plus l’emprunt aura amélioré la performance de l’investissement.",
        effetLevierCalc:
          '(Taux de rentabilité après emprunt - Taux de rentabilité avant emprunt) / Taux de rentabilité avant emprunt<br><br>Plus le projet est financé via un emprunt dont le taux d’intérêt est faible et plus ce ratio sera élevé car l’investisseur engage moins de fonds personnels.',
        effortEpargneTotal:
          "Somme totale que le client aura dépensé durant la vie de son projet locatif pour financer les charges non couvertes par les loyers. Pour rappel, ce ratio n'a rien à voir avec l'enrichissement ou le profit généré par l'opération.",
        effortEpargneTotalCalc:
          'Somme de tous les flux annuels de trésorerie négatifs. On ne tient pas compte des flux positifs qui s’en suivent pour faire prendre conscience au client de l’effort à fournir nécessaire avant de récupérer le premier euro de son projet.',
        indiceProfitabilite:
          "Valeur récupérée pour 1€ investi dans le bien. Ratio permettant de mesurer la performance des fonds investis dans l’investissement. Plus d’infos dans le <u><a target='_blank' href='/lexique'>lexique</a></u>.",
        indiceProfitabiliteCalc:
          "Profit actualisé (VAN) généré par le projet / Effort d’épargne total fourni par l'investisseur.",
        cashflowDurant:
          'Argent que votre client encaissera (+) ou devra sortir de sa poche (-) chaque mois pour financer le reste à charge de son projet durant toute la période où il remboursera son emprunt bancaire.',
        cashflowDurantCalc:
          "Si l’emprunt est de 20 ans<br>Somme des flux de trésorerie annuels de l'année 1 à l'année 20<br>/ 20 (nombre d'années de remboursement d'emprunt)<br><br> Nous obtenons le cash-flow moyen annuel. On redivise cette donnée par 12 pour avoir le cash-flow mensuel.",
        RentabiliteNetteAprèsCalc:
          "Moyenne des taux de rentabilité nets de chaque année, eux-mêmes calculés comme suit : (revenus locatifs - charges locatives - frais d'emprunt - fiscalité - travaux) / (prix d'achat + frais d'achat + frais de souscription d'emprunt + frais montage juridique)",
      },
    }
  },
  methods: {
    laFormeJuridiqueSelected() {
      // check witch item in projectSelected.carac.formeJuridique.item.selected is true and return item.code
     for (let item of this.projectSelected.carac.formejuridique) {
        if (item.selected === true) {
          return item.code
        }
      }
     return null
    },
    fraisAmeublement() {
	    if(['sasuis', 'sciis', 'lmnpbic', 'lmnpr'].includes(this.laFormeJuridiqueSelected())) {
            return this.projectSelected.carac.fameublement
	    } else {
	    return 0
	    }
    },

    //Ferme la fenetre du tableau d'imposition détaillé
    closeImpositionDetaille() {
      this.impositionDetaille = false
    },
    //Ouvre la fenetre du  tableau d'imposition détaillé
    showImpositionDetaille() {
      this.impositionDetaille = true
    },
    formeJuridiqueSelected(value) {
      let bool = false
      this.projectSelected.carac.formejuridique.map((val) => {
        if (val.selected == true) {
          value.map((index) => {
            if (val.code == index) {
              return (bool = true)
            }
          })
        }
      })

      if (bool == true) return true
      else return false
    },
    doughnutPieOptions(isAxes) {
      let options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
              display: false,
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              display: false,
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        tooltips: {
          enabled: true,
        },
        aspectRatio: 1,
        cutoutPercentage: 70,
      }

      return options
    },
    doughnutChartdata(labels, data, colors) {
      let datasets = []

      let colorArray = []

      if (colors.length > 2) {
        colorArray = [colors[0], colors[1], colors[2], colors[3]]
      } else {
        colorArray = [colors[0], colors[1]]
      }

      datasets.push({
        data: data,
        label: 'doughnut',
        borderWidth: 1,
        backgroundColor: colorArray,
        borderColor: colorArray,
        pointBorderColor: '#2554FF',
      })

      let chartdata = {
        labels: labels,
        datasets: datasets,
      }

      return chartdata
    },
    numberWithSpaces(x) {
      if (isNaN(x) || x == undefined) return 0
      var parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    },
    barOptions(isAxes) {
      let options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
              },
              gridLines: {
                display: true,
              },
              display: true,
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
              },
              gridLines: {
                display: false,
              },
              display: true,
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      }

      return options
    },
    barChartdata(labels, label, data, type, isDark) {
      // let darkBlue = '#0C1E3B'
      // let lightBlue = '#14A0EB'
      // let red = '#D45B40'
      // let yellow = '#ECCA51'

      let datasets = []

      for (let i = 0; i < data.length; i++) {
        datasets.push({
          type: type[i],
          label: label[i],
          data: data[i],
          fill: true,
          fillColor: 'rgba(20, 160, 235, 1)',
          tension: 0,
          order: i + 1,
          pointRadius: 2,
          pointHitRadius: 30,
          lineTension: 0.3,
          backgroundColor: ['rgba(20, 160, 235, 0.2)'],
          borderColor: ['rgba(20, 160, 235, 1)'],
          borderWidth: 3,
        })
      }

      let chartdata = {
        labels: labels,
        datasets: datasets,
      }

      return chartdata
    },
    barPieOptions(isAxes) {
      let options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
              display: false,
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              display: false,
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        pieceLabel: {
          render: function (d) {
            return d.label + ' (' + d.percentage + '%)'
          },
          fontColor: '#000',
          position: 'outside',
          segment: true,
        },
        tooltips: {
          enabled: true,
        },
        aspectRatio: 1,
      }

      return options
    },
    pieChartdata(labels, label, data) {
      let datasets = []

      datasets.push({
        data: data,
        label: 'Bar Chart',
        borderWidth: 1,
        backgroundColor: ['rgba(12, 30, 59, 1)', 'rgba(20, 160, 235, 1)', 'rgba(235, 123, 20, 1)'],
        borderColor: ['rgba(12, 30, 59, 1)', 'rgba(20, 160, 235, 1)', 'rgba(235, 123, 20, 1)'],
        pointBorderColor: '#2554FF',
      })

      let chartdata = {
        labels: labels,
        datasets: datasets,
      }

      return chartdata
    },
    setEmitCenter(center) {
      this.center = [center[0], center[1]]
    },
    setZoomDVF(value) {
      // console.log("======> zoom value1: ", this.zoomValue)
      // this.zoomValue = this.zoomValue + value
      // console.log("======> zoom value2: ", this.zoomValue)
      // // return
      // axios
      // .get('/apidvfmap', {
      // 	params: {
      // 		dist: this.zoomValue + value,
      // 	}
      // })
      // .then(response => {
      // 	this.dvf = response.data.features
      // 	// this.center = response.data.features[0]
      // 	// console.log(this.center)
      // })
      // .catch(error => {throw error})
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 24px;
}

.card {
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;

  &-header {
    &-img {
      width: 259px;
      height: 215px;
    }
  }

  &-line {
    margin-top: 30px;
  }

  &-p {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.blue {
  color: #14a0eb;
}

.red {
  color: #eb7b14;
}

.preicon {
  max-height: 80px;
}

.describe {
  padding-left: 10px;
  font-size: 18px;
}

.li-box {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  cursor: pointer;
  background-color: #ffffff;
  padding: 10px 0px 10px 0px;
  margin-bottom: 0.1rem;
}

.li-box:hover {
  transform: scale(1.03);
}

.tips {
  background: #c7caff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 37px;
  font-size: 0.85rem /* 13px */;
  font-weight: 400;
}

.ampoule-picto {
  top: -1rem;
  left: -1rem;
}

.line-div {
  position: relative;
}

.line-up {
  position: absolute;
  border-left: 1px solid black;
  height: 155px;
  left: 5%;
  margin-left: 50px;
  top: -5px;
}
</style>
