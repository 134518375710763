import InvestorTools from './Investor'
import moment from 'moment'
/**
 * Class qui va regrouper des fonctions basées sur le calcul des projets
 * Note aux développeurs suivant :
 * Toutes les fonctions codées ici regroupent les calculs qui iront alimenter l'objet simulation, la plupart des calculs excels ont été refait ici
 * La fonction setDataProject calcul les données d'une nouvelle simulation
 * Pour une simulation déjà existance, allez dans le fichier store project.js et dans l'action updateProject
 * La plupart des timelines ont été recodées mais pas toutes. J'ai essayer de reproduire au mieux le fonctionnement de tous le excel.
 * Si jamais vous avez des questions contactez Gary (deshayesgary@hotmail.fr)
 */
export default class Project {
  /**
   * Fonction pour la création d'une simulation
   * @param {*} projet
   * @param {*} globals
   * @param {*} investisseur
   * @param {*} tmis
   * @returns
   */
  static setDataProject(projet, globals, investisseur, tmis) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue
    projet.results.timelineFonc.timelineAchevementTravauxClassiqueNeuf =
      this.getArrayTotalTimelineAchevementTravauxMiseLocationClassiqueNeuf(projet)
    projet.results.timelineFonc.timelineAchevementTravauxPinelNeuf =
      this.getArrayTotalTimelineAchevementTravauxMiseLocationPinelNeuf(projet)
    projet.results.timelineFonc.timelineAchevementTravauxPinelAncienDenormandie =
      this.getArrayTotalTimelineAchevementTravauxMiseLocationPinelAncienDenormandie(projet)
    projet.results.timelineFonc.timelineTempsLocationPinelAncienDenormandie =
      this.getArrayTotalTimelineTempsLocationPinelAncienDenormandie(projet)
    projet.results.timelineFonc.timelineTempsLocationPinelNeuf =
      this.getArrayTotalTimelineTempsLocationPinelNeuf(projet)
    projet.results.timelineFonc.timelineTempsLocationNeufClassique =
      this.getArrayTotalTimelineTempsLocationNeufClassique(projet)
    projet.results.timelineFonc.timelineReductionImpotPinelNeuf =
      this.getArrayTotalTimelineReductionImpotPinelNeuf(projet)
    projet.results.timelineFonc.timelineReductionImpotPinelAncienDenormandie =
      this.getArrayTotalTimelineReductionImpotPinelAncienDenormandie(projet)
    projet.results.timelineFonc.timelineChoixJuridiqueNuReelSCIIRReel =
      this.getArrayTimelineChoixJuridiqueNuReelSCIIRReel(projet)
    //Données diverses
    projet.results.resOperationNetFonc.resLoyerEnt = this.getArrayLoyer(projet)
    projet.results.resOperationNetFonc.resPertes = this.getArrayPertes(projet)
    projet.results.resOperationNetFonc.resRevLoc = this.getArrayRevenusLocatifs(projet)
    projet.results.resOperationNetFonc.resStructureSCI = this.getArrayFraisStructureEntreprise(projet)
    let data_globals_reduction_impots = {
      reduction_1_9: globals.reductionannee1a9,
      reduction_10_12: globals.reductionannee10a12,
    }
    projet.results.resOperationNetFonc.reductionImpots = this.getArrayReductionImpots(
      projet,
      data_globals_reduction_impots,
      investisseur
    )
    projet.results.resOperationNetFonc.resAssPNO = this.getArrayAssurancePNO(projet)
    projet.results.resOperationNetFonc.resGLI = this.getArrayGarantieLoyerImpaye(projet)
    projet.results.resOperationNetFonc.resFraisGestLoc = this.getArrayFraisGestionLocative(projet)
    projet.results.resOperationNetFonc.resFraisCopro = this.getArrayFraisCopropriete(projet)
    projet.results.resOperationNetFonc.resTaxeFonc = this.getArrayTaxeFonciere(projet)
    projet.results.resOperationNetFonc.resFraisExploit = this.getArrayFraisExploitation(projet)
    projet.results.resOperationNetFonc.resTotFraisExploit =
      projet.results.resOperationNetFonc.resFraisExploit.reduce(reducer)

    projet.results.resOperationNetFonc.resAutresFrais = this.getArrayAutresFraisDeductibles(projet)
    projet.results.resInvTravFonc.resItr = this.getArrayInteretCagnotteEtBase(projet)
    projet.results.resInvTravFonc.resTotItr = projet.results.resInvTravFonc.resItr.reduce(reducer)
    projet.results.resFluxAIFonc.resBaseImposable = this.getArrayBaseImposable(projet)

    projet.results.resInvTravFonc.resEmprunt = this.getArrayEmprunt(projet)
    projet.results.resInvTravFonc.resRmbCap = this.getArrayRemboursementCapital(projet)
    projet.results.resInvTravFonc.resRmbDette = this.getArrayRemboursementDette(projet)
    projet.results.resInvTravFonc.resInvestissement =
      this.getArrayCoutInvestissement(projet).array_investissement

    //Pour les données de résultats
    projet.results.resInvTravFonc.resIndemRmbDette = this.getArrayIRA(projet)
    projet.results.resInvTravFonc.resTotIndemRmbDette =
      projet.results.resInvTravFonc.resIndemRmbDette.reduce(reducer)
    let retourPV = this.calculTotalPlusValueRevente(projet)

    projet.results.resFluxAIFonc.plusValueRevente = retourPV.calcul
    projet.results.resCoutTotalFonc.resPrixAchat = retourPV.array_prix_achat
    projet.results.resCoutTotalFonc.resTVA = retourPV.array_tva

    //TIR

    let data_cagnotte = this.getArrayBaseImposableNetteEtCagnotte(projet, investisseur)
    projet.results.resFluxAIFonc.resBaseImposableNette = data_cagnotte.array_base_imposable_nette
    projet.results.resFluxAIFonc.resCagnotteReportable = data_cagnotte.array_cagnotte
    let calculImpotPlusValue = this.calculTotalImpositionPlusValue(projet, globals)
    projet.results.resFluxAIFonc.resPV[projet.results.resFluxAIFonc.resPV.length - 1] =
      -calculImpotPlusValue.total_impots

    projet.results.resFluxAIFonc.resTotPV = projet.results.resFluxAIFonc.resPV.reduce(reducer)
    let dataImpositionDetaille = []
    let dureeempr =
      projet.carac.dureedetention.isAuto == true
        ? Number(projet.carac.dureedetention.valAuto / 12)
        : Number(projet.carac.dureedetention.val)
    let imposition = []
    let imposition_revenus_locatifs = []
    let imposition_plus_value = []
    let imposition_dividende = []
    for (let index = 0; index <= dureeempr; index++) {
      let resultat = this.calculTableauImposition(projet, index, dataImpositionDetaille, investisseur, tmis, globals)
      imposition_revenus_locatifs.push(
        resultat.data_imposition.imposition_revenu_bien_locatif + resultat.data_imposition.results_impots_is
      )
      imposition_plus_value.push(
        resultat.data_imposition.results_impots_cession + resultat.data_imposition.plus_value
      )
      imposition_dividende.push(
        resultat.data_imposition.results_dividendes +
          resultat.data_imposition.results_prevelement_sociaux_dividende
      )
      dataImpositionDetaille.push(resultat.tableau_imposition)
      // if (dataImpositionDetaille[index][9] < 0) {
      //   imposition.push(Math.abs(dataImpositionDetaille[index][9]));
      // } else {
      // }
      imposition.push(dataImpositionDetaille[index][9])
    }

    projet.results.resFluxAIFonc.resImposition = imposition
    projet.results.resFluxAIFonc.resImpositionRevenusLocatifs = imposition_revenus_locatifs
    projet.results.resFluxAIFonc.resImpositionPlusValue = imposition_plus_value
    projet.results.resFluxAIFonc.resImpositionDividende = imposition_dividende
    projet.results.resFluxAIFonc.resTotImpositionRevenusLocatifs = imposition_revenus_locatifs.reduce(
      (a, b) => a + b
    )
    projet.results.resFluxAIFonc.resTotImpositionPlusValue = imposition_plus_value.reduce((a, b) => a + b)
    projet.results.resFluxAIFonc.resTotImpositionDividende = imposition_dividende.reduce((a, b) => a + b)
    projet.results.resFluxAIFonc.resTotImposition = imposition.reduce((a, b) => a + b)
    //Pour les diagrames résultats
    projet.results.resOperationNetFonc.resOpeNet = this.getArrayResultatOperationnelNet(projet)
    projet.results.resFluxAIFonc.resFluxAvtDette = this.getArrayFluxAvantDettes(projet)
    projet.results.resFluxAIFonc.resFluxAvtImpot = this.getArrayFluxNetsAvantImpots(projet)
    projet.results.resFluxAIFonc.resFluxApresImpots = this.getArrayFluxNetsApresImpots(projet)
    projet.results.resFluxAIFonc.resCashFlowNonActu = this.getArrayCashFlowNonActu(projet)
    projet.results.resFluxAIFonc.resTotCashFlowNonActu =
      projet.results.resFluxAIFonc.resCashFlowNonActu.reduce((a, b) => a + b)

    projet.results.resFluxAIFonc.resCashFlowActu = this.getArrayCashFlowActu(projet)
    projet.results.resFluxAIFonc.resTotCashFlowActu = projet.results.resFluxAIFonc.resCashFlowActu.reduce(
      (a, b) => a + b
    )
    projet.results.resFluxAIFonc.resCashFlowCumul = this.getArrayCashFlowCumul(projet)
    projet.results.resFluxAIFonc.resSommeInvest = this.getArraySommeInvestie(projet)
    projet.results.resFluxAIFonc.resTotSommeInvest = projet.results.resFluxAIFonc.resSommeInvest.reduce(
      (a, b) => a + b
    )

    projet.results.resFluxAIFonc.economieFiscale = this.calculTotalEconomieFiscale(projet)
    let tir = this.calculToutTIR(projet, globals.tactualisationPer)
    if (projet.results.resFluxAIFonc.performance == null) {
      projet.results.resFluxAIFonc.performance = {}
    }
    if (projet.results.resFluxAIFonc.performance.sansDette == null) {
      projet.results.resFluxAIFonc.performance.sansDette = {}
    }
    if (projet.results.resFluxAIFonc.performance.avecDette == null) {
      projet.results.resFluxAIFonc.performance.avecDette = {}
    }
    if (projet.results.resFluxAIFonc.performance.avecFisc == null) {
      projet.results.resFluxAIFonc.performance.avecFisc = {}
    }
    projet.results.resFluxAIFonc.performance.sansDette.tir = tir.sansDette
    projet.results.resFluxAIFonc.performance.avecDette.tir = tir.avecDette
    projet.results.resFluxAIFonc.performance.avecFisc.tir = tir.cashFlowNonActu
    projet.results.resFluxAIFonc.performance.tir = tir.cashFlowActu
    projet.results.resFluxAIFonc.performance.avecDette.effetlevier = Number(
      (((tir.avecDette / 100 - tir.sansDette / 100) / (tir.sansDette / 100)) * 100).toFixed(2)
    )
    projet.results.resFluxAIFonc.revenuLocatifTotalApresEndettement =
      this.calculRevenuLocatifTotalApresEndettement(projet)
    return projet
  }

  /**
   * Vérifie si il y a un changement de fiscalite pour LMNP puis que l'année est bien apres ce changement
   * @param {*} projet
   * @returns
   */
  static estApresChangementFiscalite(changementDeFiscalite, index_annee, engagementPlusTravaux) {
    return changementDeFiscalite && (index_annee >= engagementPlusTravaux)
  }

  /**
   * Vérifie si il y a un changement de fiscalite pour LMNP puis que l'année est bien celle du changement
   * @param {*} projet
   * @returns
   */
  static estAnneeChangementFiscalite(changementDeFiscalite, index_annee, engagementPlusTravaux) {
    return changementDeFiscalite && (index_annee == engagementPlusTravaux)
  }

  /**
   * Récupération de l'array des cash flow cumulés
   * @param {*} projet
   * @returns
   */
  static getArrayIRA(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculIRA(projet, i))
    })
    return array
  }

  /**
   * Calcul les sommes investies
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculIRA(projet, index_annee) {
    let calcul = 0
    let ira = projet.carac.ira.val
    let tauxinteret =
      projet.carac.tinteretempr.isAuto == true
        ? projet.carac.tinteretempr.tinteretemprPercentage.val
        : projet.carac.tinteretempr.val
    if (ira == null) {
      let balance = 0
      if (projet.carac.amortData[index_annee - 1]) {
        balance = projet.carac.amortData[index_annee - 1].amortCapEnd
      }
      //balance bancaire * 6 * (taux interet / 12)
      calcul = -projet.results.timelineFonc.resDateRevente[index_annee] * balance * 6 * (tauxinteret / 12)
    } else {
      calcul = -projet.results.timelineFonc.resDateRevente[index_annee] * ira
    }

    return calcul
  }

  /**
   * Calcul de la plus value
   * @param {*} projet
   * @param {*} globals
   * @returns
   */
  static calculTotalImpositionPlusValue(projet, globals) {
    let data = {
      frais_reel: 0,
      travaux_reel: 0,
      prix_acquisition_majore: 0,
      prix_cession: 0,
      plus_value_brute: 0,
      pourcentageIR: 0,
      pourcentagePS: 0,
      plus_value_nette_IR: 0,
      plus_value_nette_PS: 0,
      impots_IR: 0,
      impots_PS: 0,
      somme_taxe_additionnelle: 0,
      total_impots: 0,
    }
    let prix_acquisition = projet.carac.buyprice
    let somme_travaux = 0
    let sommeConstru = Object.values(projet.carac.travauxConstru).reduce((a, b) => +a + +b, 0)
    let sommeReno = Object.values(projet.carac.travauxReno).reduce((a, b) => +a + +b, 0)
    somme_travaux = sommeConstru + sommeReno
    //calcul frais reel
    if (projet.carac.fnotaire.val > projet.carac.buyprice * 0.075) {
      data.frais_reel = projet.carac.fnotaire.val
    } else {
      data.frais_reel = projet.carac.buyprice * 0.075
    }
    //calcul travaux reel
    if (somme_travaux > projet.carac.buyprice * 0.15) {
      data.travaux_reel = somme_travaux
    } else {
      if (projet.carac.dureedetention.val >= 5) {
        data.travaux_reel = projet.carac.buyprice * 0.15
      } else {
        data.travaux_reel = somme_travaux
      }
    }
    //calcul prix acquisition majoré
    data.prix_acquisition_majore = prix_acquisition + data.frais_reel + data.travaux_reel
    data.prix_cession = projet.carac.estimprixrevente
    data.plus_value_brute = data.prix_cession - data.prix_acquisition_majore
    //Récupération pourcetange
    data.pourcentageIR = globals.calPluvalIR[projet.carac.dureedetention.val - 1]
    data.pourcentagePS = globals.calPluvalPS[projet.carac.dureedetention.val - 1]
    //Calcul plus value nette
    data.plus_value_nette_IR = data.plus_value_brute - data.plus_value_brute * (data.pourcentageIR / 100)
    data.plus_value_nette_PS = data.plus_value_brute - data.plus_value_brute * (data.pourcentagePS / 100)
    //impots et PS à payer
    data.impots_IR = data.plus_value_nette_IR * 0.19
    data.impots_PS = data.plus_value_nette_PS * 0.172
    //Barème d'imposition
    let baremes = [
      [50001, 60000, 0.02],
      [60001, 100000, 0.02],
      [100001, 110000, 0.03],
      [110001, 150000, 0.03],
      [150001, 160000, 0.04],
      [160001, 200000, 0.04],
      [200001, 210000, 0.05],
      [210001, 250000, 0.05],
      [250001, 260000, 0.06],
      [260000, 10000000, 0.06],
    ]
    baremes.forEach((d, i) => {
      if (data.plus_value_nette_IR > d[0] && data.plus_value_nette_IR < d[1]) {
        baremes[i][3] = 1
      } else {
        baremes[i][3] = 0
      }
      baremes[i][4] =
        baremes[i][3] *
        (d[2] * data.plus_value_nette_IR - ((baremes[i][1] - data.plus_value_nette_IR) * 1) / 20)
      data.somme_taxe_additionnelle += baremes[i][4]
    })
    data.total_impots = data.impots_IR + data.impots_PS + data.somme_taxe_additionnelle
    return data
  }

  /**
   * Calcule tout les tirs utiles
   * @param {*} projet
   * @param {*} array_tir
   * @returns
   */
  static calculToutTIR(projet, array_tir) {
    let data = {
      sansDette: this.calculTIR(projet.results.resFluxAIFonc.resFluxAvtDette), 
      avecDette: this.calculTIR(projet.results.resFluxAIFonc.resFluxAvtImpot),
      cashFlowNonActu: this.calculTIR(projet.results.resFluxAIFonc.resCashFlowNonActu),
      cashFlowActu: this.calculTIR(projet.results.resFluxAIFonc.resCashFlowActu), //TIR
    }
    return data
  }

  /**
   * Calcul les champs C125 et C126 de l'excel
   * @param {*} projet
   * @returns
   */
  static calculMoisAnneeFinTravaux(projet) {
    let acquisition = moment(projet.carac.dateacqui + '-01')
    let achevement_travaux = moment(projet.carac.achevementtravaux + '-01')
    let annee = achevement_travaux.diff(acquisition, 'years')
    let mois = achevement_travaux.diff(acquisition.add(1, 'Y'), 'month')
    return {
      annee,
      mois,
    }
  }

  /**
   * Calcul le % de TIR en fonction du flux envoyé
   * @param {*} projet
   * @param {*} flux
   * @param {*} array_tir
   * @returns
   */
  // static calculTIR(projet, flux, array_tir) {
  //   let dureeDetent =
  //     projet.carac.dureedetention.isAuto == true
  //       ? Number(projet.carac.dureedetention.valAuto / 12)
  //       : Number(projet.carac.dureedetention.val)

  //   let Tir = 0
  //   let arrayTir = []
  //   let arrayEachTir = []
  //   let test = []
  //   const reducer = (accumulator, currentValue) => accumulator + currentValue

  //   for (let i = 0; i <= array_tir.length - 1; i++) {
  //     for (let j = 0; j <= dureeDetent; j++) {
  //       arrayEachTir.push(flux[j] / (1 + array_tir[i] / 100) ** j)
  //     }
  //     arrayTir.push(arrayEachTir)
  //     Tir = arrayEachTir.reduce(reducer)
  //     test.push(Tir)
  //     arrayEachTir = []

  //     if (Tir <= 0) {
  //       return array_tir[i]
  //     }
  //   }
  // }

  static calculTIR(cashFlows) {
    // const initialGuess = 0.1
    const convergence = 0.0001
    const maxIteration =  1000
    const stepSize = 0.01

    for (let initialGuess = 0; initialGuess < 1; initialGuess += 0.1) {
      let tir = this.NewtonRaphson(cashFlows, initialGuess, convergence, maxIteration, stepSize)
      if (tir) return tir
      tir = this.secant(cashFlows, initialGuess, convergence, maxIteration, stepSize)
      if (tir) return tir
    }

    return 0
  }

  static secant(cashFlows, initialGuess, convergence, maxIteration, stepSize) {
    const initialGuess1 = initialGuess;
    const initialGuess2 = initialGuess + stepSize
    let tir1 = initialGuess1;
    let tir2 = initialGuess2;

    for (let i = 0; i < maxIteration; i++) {
      const npv1 = this.NPV(cashFlows, tir1);
      const npv2 = this.NPV(cashFlows, tir2);

      if (npv2 - npv1 === 0) {
        // Avoid division by zero
        break;
      }

      const tirNew = tir2 - npv2 * (tir2 - tir1) / (npv2 - npv1);

      if (Math.abs(tirNew - tir2) < convergence) {
        return tirNew;
      }

      tir1 = tir2;
      tir2 = tirNew;
    }

    return null; 
  }



  static NewtonRaphson(cashFlows, initialGuess, convergence, maxIteration, stepSize) {
    let tir = initialGuess
    for (let i = 0; i < maxIteration; i++) {
      const npv = this.NPV(cashFlows, tir)
      const derivative = this.derivativeNPV(cashFlows, tir)
      if (Math.abs(derivative) < 1e-6) {
        tir += 0.001;
        continue;
      }

      const newTir = tir - npv / derivative * stepSize

      if (Math.abs((newTir - tir) / tir) < convergence) {
        return Math.round(newTir * 10000) / 100
      }

      tir = newTir
    }
    return null
  }

  static NPV(cashFlows, discountRate) {
    let npv = 0;
    for (let i = 0; i < cashFlows.length; i++) {
      if (i === 0) {
        npv += cashFlows[i]; // The initial cash flow is not discounted
      } else {
        npv += cashFlows[i] / Math.pow((1 + discountRate), i);
      }
    }
    return npv;
  }

  static derivativeNPV(cashFlows, discountRate) {
    let derivative = 0
    for (let i = 0; i < cashFlows.length; i++) {
      derivative -= i * cashFlows[i] / ((1 + discountRate) ** (i + 1));
    }
    return derivative
  }

  static calculTauxRentabiliteNetteDeCharges(projet) {
    let calcul = 0
    let somme_flux_avant_impots = projet.results.resFluxAvtImpot.reduce((a, b) => a + b)
    if (somme_flux_avant_impots > 0) {
    } else {
      calcul = 'NEGATIF'
    }
    return calcul
  }
  /**
   * Calcul le total des revenus locatifs apres endettement
   * @param {*} projet
   * @returns
   */
  static calculRevenuLocatifTotalApresEndettement(projet) {
    let calcul = 0
    calcul =
      projet.results.resFluxAIFonc.resTotCashFlowNonActu -
      projet.results.resFluxAIFonc.economieFiscale -
      projet.results.resFluxAIFonc.plusValueRevente
    return calcul
  }

  /**
   * Calcul le total de plus value à la revente a partir des couts totaux d'acquisition
   * @param {*} projet
   * @returns
   */
  static calculTotalPlusValueRevente(projet) {
    let calcul = 0
    let data = {
      calcul: 0,
      array_investissement: [],
      array_tva: [],
      array_prix_achat: [],
    }
    let retour = this.getArrayCoutInvestissement(projet, false)
    data.array_investissement = retour.array_investissement
    data.array_tva = retour.array_tva
    data.array_prix_achat = retour.array_prix_achat
    let investissement_sans_travaux = retour.array_investissement
    investissement_sans_travaux.forEach((data, index) => {
      if (projet.results.timelineFonc.resDateRevente[index]) {
        calcul += data
      } else {
        calcul += -data
      }
    })
    const travauxTroisPremieresAnnees = (Number(projet.carac.travauxReno.year_0)
      + Number(projet.carac.travauxReno.year_1)
      + Number(projet.carac.travauxReno.year_2)
      + Number(projet.carac.travauxConstru.year_0)
      + Number(projet.carac.travauxConstru.year_1)
      + Number(projet.carac.travauxConstru.year_2))
    
    data.calcul = calcul + projet.results.resFluxAIFonc.resTotPV - travauxTroisPremieresAnnees
    return data
  }

  /**
   * Calcul le total d'economie fiscale à partir des impots
   * @param {*} projet
   * @returns
   */
  static calculTotalEconomieFiscale(projet) {
    let calcul = 0
    calcul = projet.results.resOperationNetFonc.reductionImpots.reduce((a, b) => a + b)
    return calcul
  }

  /**
   * Récupération de l'array des cash flow cumulés
   * @param {*} projet
   * @returns
   */
  static getArraySommeInvestie(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculSommeInvestie(projet, i))
    })
    return array
  }

  /**
   * Calcul les sommes investies
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculSommeInvestie(projet, index_annee) {
    let calcul = 0
    calcul =
      projet.results.timelineFonc.resTmpLoc[index_annee] *
      (projet.results.resFluxAIFonc.resCashFlowActu[index_annee] <= 0
        ? projet.results.resFluxAIFonc.resCashFlowActu[index_annee]
        : 0)
    return calcul
  }

  /**
   * Récupération de l'array des sommes investies
   * @param {*} projet
   * @returns
   */
  static getArrayCashFlowCumul(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculCashFlowCumul(projet, i))
    })
    return array
  }

  /**
   * Calcul les cash flow cumulés
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculCashFlowCumul(projet, index_annee) {
    let calcul = 0
    if (index_annee == 0) {
      calcul =
        (projet.results.timelineFonc.resTmpLoc[index_annee] +
          projet.results.timelineFonc.resDateRevente[index_annee]) *
        projet.results.resFluxAIFonc.resCashFlowNonActu[index_annee]
    } else {
      calcul =
        (projet.results.timelineFonc.resTmpLoc[index_annee] +
          projet.results.timelineFonc.resDateRevente[index_annee]) *
        (projet.results.resFluxAIFonc.resCashFlowNonActu[index_annee] +
          projet.results.resFluxAIFonc.resCashFlowActu[index_annee - 1])
    }
    return calcul
  }

  /**
   * Récupération de l'array des cash flow actualisés
   * @param {*} projet
   * @returns
   */
  static getArrayCashFlowActu(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculCashFlowActu(projet, i))
    })
    return array
  }

  /**
   * Calcul les cash flow non actualisés
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculCashFlowActu(projet, index_annee) {
    let calcul = 0
    if (projet.results.resFluxAIFonc.resCashFlowNonActu[index_annee] > 0) {
      //(E103/((1+'FORM SIMU'!$C$135)^ALGO!E1))
      calcul =
        projet.results.resFluxAIFonc.resCashFlowNonActu[index_annee] /
        (1 + +projet.carac.tactualisation.per / 100) ** index_annee
    } else {
      calcul = projet.results.resFluxAIFonc.resCashFlowNonActu[index_annee]
    }
    return calcul
  }

  /**
   * Récupération de l'array des cash flow non actualisés
   * @param {*} projet
   * @returns
   */
  static getArrayCashFlowNonActu(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculCashFlowNonActu(projet, i))
    })
    projet.results.resFluxAIFonc.resTotCashFlowActu = array.reduce((a, b) => a + b, 0)
    return array
  }

  /**
   * Calcul les cash flow non actualisés
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculCashFlowNonActu(projet, index_annee) {
    let calcul = 0
    calcul =
      projet.results.resFluxAIFonc.resFluxApresImpots[index_annee] +
      projet.results.resOperationNetFonc.reductionImpots[index_annee]
    return calcul
  }

  /**
   * Récupération de l'array des flux après impots
   * @param {*} projet
   * @returns
   */
  static getArrayFluxNetsApresImpots(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculFluxApresImpots(projet, i))
    })
    return array
  }

  /**
   * Calcul les flux après impots
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculFluxApresImpots(projet, index_annee) {
    let calcul = 0

    calcul =
      projet.results.resFluxAIFonc.resFluxAvtImpot[index_annee] +
      projet.results.resFluxAIFonc.resImposition[index_annee]
    return calcul
  }

  /**
   * Récupération de l'array des flux avant impots
   * @param {*} projet
   * @returns
   */
  static getArrayFluxNetsAvantImpots(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculFluxAvantImpots(projet, i))
    })
    return array
  }

  /**
   * Calcul les flux avant impots
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculFluxAvantImpots(projet, index_annee) {
    let calcul = 0
    let annuite = 0
    if (projet.carac.amortData[index_annee] && projet.carac.modefinancement != 'fond_propre') {
      annuite =
        projet.carac.amortData[index_annee].amortAnnuit + projet.carac.amortData[index_annee].amortAssur
    }
    calcul =
      projet.results.resFluxAIFonc.resFluxAvtDette[index_annee] +
      projet.results.resInvTravFonc.resEmprunt[index_annee] +
      -annuite * projet.results.timelineFonc.resTimelineEmpr[index_annee] +
      -projet.results.resInvTravFonc.resRmbDette[index_annee] +
      projet.results.resInvTravFonc.resIndemRmbDette[index_annee] +
      projet.results.resInvTravFonc.resFraisBanc[index_annee]
    return calcul
  }

  /**
   * Récupération de l'array des flux avant dettes
   * @param {*} projet
   * @returns
   */
  static getArrayFluxAvantDettes(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculFluxAvantDettes(projet, i))
    })
    return array
  }

  /**
   * Calcul les flux avant dettes
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculFluxAvantDettes(projet, index_annee) {
    let calcul = 0
    calcul =
      projet.results.resInvTravFonc.resInvestissement[index_annee] +
      projet.results.resOperationNetFonc.resOpeNet[index_annee]
    return calcul
  }

  /**
   * Récupération de l'array des résultats opérationnel net
   * @param {*} projet
   * @returns
   */
  static getArrayResultatOperationnelNet(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculResultatOperationnelNet(projet, i))
    })
    return array
  }

  /**
   * Calcul les résultats opérationnel net
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculResultatOperationnelNet(projet, index_annee) {
    let calcul = 0
    if (!projet.results.timelineFonc.resDateRevente[index_annee]) {
      calcul =
        projet.results.resOperationNetFonc.resRevLoc[index_annee] +
        projet.results.resOperationNetFonc.resFraisExploit[index_annee] +
        projet.results.resOperationNetFonc.resStructureSCI[index_annee]
    }
    return calcul
  }

  /**
   * Récupération de l'array des pertes
   * @param {*} projet
   * @returns
   */
  static getArrayInteretCagnotteEtBase(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculInteretCagnotteEtBase(projet, i))
    })
    return array
  }

  /**
   * Calcul les interets de la feuille cagnotte et base (Ligne 18)
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculInteretCagnotteEtBase(projet, index_annee) {
    let calcul = 0
    let remboursementemprunt = projet.carac.remboursementemprunt
    let dureediffere = projet.carac.dureediffere
    let interets = 0
    if (projet.carac.amortData[index_annee]) {
      interets =
        projet.carac.amortData[index_annee].amortPartInt *
        projet.results.timelineFonc.resTimelineEmpr[index_annee]
    }
    if (index_annee == 0) {
      if (remboursementemprunt == 'total' && dureediffere >= 12) {
        calcul = 0
      } else if (remboursementemprunt == 'total' && dureediffere < 12) {
        calcul = -interets * ((12 - dureediffere) / 12)
      } else {
        calcul = -interets
      }
    }
    if (index_annee == 1) {
      if (remboursementemprunt == 'total' && dureediffere >= 24) {
        calcul = 0
      } else if (remboursementemprunt == 'total' && dureediffere > 12 && dureediffere < 24) {
        calcul = -interets * ((12 - dureediffere) / 12)
      } else {
        calcul = -interets
      }
    }
    if (index_annee == 2) {
      if (remboursementemprunt == 'total' && dureediffere > 24 && dureediffere < 36) {
        calcul = -interets * ((24 - dureediffere) / 12)
      } else {
        calcul = -interets
      }
    }
    if (index_annee > 2) {
      calcul = -interets
    }
    return calcul
  }

  /**
   * Calcul les frais de notaires
   * @param {*} prix_achat Nombre
   * @param {*} neuf True ou false
   * @param {*} departement
   * @param {*} pourcentage True ou false
   * @returns
   */
  static calculFraisNotaire(prix_achat, neuf, departement, pourcentage, data_globals) {
    let calcul = 0
    let taxe_departementale = 0
    let taxe_communale = 0
    let frais_assiette_recouvrement = 0
    let contribution_securite_immobiliere = prix_achat * 0.001
    let emolument_formalite_debours = data_globals.emolument_formalite_debours
    let honoraires_notaire = 0
    let tva_honoraire = 0

    if (neuf) {
      taxe_departementale = Math.round(prix_achat * 0.007)
      taxe_communale = Math.round(prix_achat * 0)
      frais_assiette_recouvrement = Math.round(taxe_departementale * 0.0214)
    } else {
      taxe_communale = prix_achat * 0.012
      if (['36', '56', '38', '974'].includes(departement)) {
        taxe_departementale = Math.round(prix_achat * 0.038)
      } else {
        taxe_departementale = Math.round(prix_achat * 0.045)
      }
      frais_assiette_recouvrement = Math.round(taxe_departementale * 0.0237)
    }
    //honoraire notaire
    if (prix_achat <= 6500) {
      honoraires_notaire = Math.round(prix_achat * data_globals.notaire0a6500.val)
    } else if (prix_achat >= 6501 && prix_achat <= 17000) {
      honoraires_notaire = Math.round(
        data_globals.notaire0a6500.val * (prix_achat - 6500) + 6500 * data_globals.notaire0a6500.val
      )
    } else if (prix_achat >= 17001 && prix_achat <= 60000) {
      honoraires_notaire = Math.round(
        data_globals.notaire17001a60000.val * (prix_achat - 17000) +
          6500 * data_globals.notaire0a6500.val +
          (17000 - 6501) * data_globals.notaire6501a17000.val
      )
    } else if (prix_achat >= 60001) {
      honoraires_notaire = Math.round(
        data_globals.notaire60001etplus.val * (prix_achat - 60001) +
          6500 * data_globals.notaire0a6500.val +
          (17000 - 6501) * data_globals.notaire6501a17000.val +
          (60000 - 17001) * data_globals.notaire17001a60000.val
      )
    }
    tva_honoraire = Math.round(honoraires_notaire * 0.2)
    calcul =
      taxe_departementale +
      taxe_communale +
      frais_assiette_recouvrement +
      contribution_securite_immobiliere +
      emolument_formalite_debours +
      honoraires_notaire +
      tva_honoraire
    let total = calcul
    if (pourcentage) {
      calcul = (calcul / prix_achat).toFixed(10)
    }
    return {
      calcul,
      data: {
        honoraire_notaire_ttc: honoraires_notaire + tva_honoraire,
        droit_enregistrement: taxe_departementale + taxe_communale + frais_assiette_recouvrement,
        emolument_formalite_debours,
        contribution_securite_immobiliere,
        total,
      },
    }
  }

  /**
   * Récupération de l'array des pertes
   * @param {*} projet
   * @returns
   */
  static getArrayPertes(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculPertes(projet, i))
    })
    return array
  }

  /**
   * Calcul des pertes
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculPertes(projet, index_annee) {
    let calcul = 0
    let pertes = 0
    if (index_annee == 0) {
      pertes =
        -projet.results.resOperationNetFonc.resLoyerEnt[index_annee] * (projet.carac.rvacanceloc.val / 365) -
        projet.carac.loyermensuel * 12 * (+projet.carac.differeDeMiseEnLocation / 12)
    } else {
      pertes =
        -projet.results.resOperationNetFonc.resLoyerEnt[index_annee] * (projet.carac.rvacanceloc.val / 365)
    }
    calcul = Math.round(calcul + pertes)
    return calcul
  }

  /**
   * Calcul le type de différé dans la feuille Emprunt de l'excel (Colonne B)
   * @param {*} mois
   * @param {*} differe
   */
  static calculTypeDiffere(mois, type, duree) {
    let retour = ''
    if (type == 'partiel' && mois <= duree) {
      retour = 'D1'
    } else {
      if (type == 'total' && mois <= duree) {
        retour = 'D2'
      } else {
        retour = mois
      }
    }
    return retour
  }

  /**
   * Calcul des intérets par mois (feuille Emprunt)
   * @param {*} projet
   * @returns
   */
  static calculInteretMois(projet) {
    let array_interets = []
    let array_PRN_debut = []
    let array_PRN_fin = []
    if (!['pinel_neuf', 'classique_neuf'].includes(projet.carac.typeexploitation)) {
      array_PRN_debut.push(projet.carac.montantemprunte.val)
    } else {
      array_PRN_debut.push((+projet.carac.appelDeFond.reservation / 100) * projet.carac.montantemprunte.val)
    }
    for (let index = 0; index < 360; index++) {
      array_interets.push((array_PRN_debut[index] * projet.carac.tinteretempr.val) / 12)
      if (!['pinel_neuf', 'classique_neuf'].includes(projet.carac.typeexploitation)) {
        array_PRN_debut.push(projet.carac.montantemprunte.val)
      } else {
        array_PRN_debut.push((+projet.carac.appelDeFond.reservation / 100) * projet.carac.montantemprunte.val)
      }
    }
    return array_interets
  }

  /**
   * Retourne le tableau de frais de gestion locatives
   * @param {*} projet
   * @returns
   */
  static getArrayFraisGestionLocative(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculFraisGestionLocative(projet, i, array))
    })
    return array
  }

  /**
   * Calcul les frais de gestion locative pour une année
   * @param {*} projet
   * @param {*} index_annee
   * @param {*} array_frais_gestion
   * @returns
   */
  static calculFraisGestionLocative(projet, index_annee, array_frais_gestion) {
    let calcul = 0
    if (index_annee == 0) {
      calcul =
        -projet.results.timelineFonc.resTmpLoc[index_annee] *
        (projet.results.resOperationNetFonc.resRevLoc[index_annee] * (projet.carac.hgestionloc.per / 100) +
          0 * (Number(projet.carac.trevalorisationOPEX.val) / 100))
    } else {
      calcul =
        -projet.results.timelineFonc.resTmpLoc[index_annee] *
        (projet.results.resOperationNetFonc.resRevLoc[index_annee] * (projet.carac.hgestionloc.per / 100) +
          array_frais_gestion[index_annee - 1] * (Number(projet.carac.trevalorisationOPEX.val) / 100))
    }
    return calcul
  }

  /**
   * Retourne le tableau de reduction des garantie de loyer impayés
   * @param {*} projet
   * @returns
   */
  static getArrayGarantieLoyerImpaye(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculGarantieLoyerImpaye(projet, i, array))
    })
    return array
  }
  /**
   * Calcul les garantie loyer impayé d'une année
   * @param {*} projet
   * @param {*} index_annee
   * @param {*} array_garantie
   * @returns
   */
  static calculGarantieLoyerImpaye(projet, index_annee, array_garantie) {
    let calcul = 0
    if (index_annee == 0) {
      calcul =
        -projet.results.timelineFonc.resTmpLoc[index_annee] *
        (projet.results.resOperationNetFonc.resRevLoc[index_annee] * (projet.carac.gli.per / 100) +
          0 * (Number(projet.carac.trevalorisationOPEX.val) / 100))
    } else {
      calcul =
        -projet.results.timelineFonc.resTmpLoc[index_annee] *
        (projet.results.resOperationNetFonc.resRevLoc[index_annee] * (projet.carac.gli.per / 100) +
          array_garantie[index_annee - 1] * (Number(projet.carac.trevalorisationOPEX.val) / 100))
    }
    return calcul
  }

  /**
   * Retourne le tableau de reduction des taxe foncières
   * @param {*} projet
   * @returns
   */
  static getArrayTaxeFonciere(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculTaxeFonciere(projet, i, array))
    })
    return array
  }

  /**
   * Calcul les taxes foncières d'une année
   * @param {*} projet
   * @param {*} index_annee
   * @param {*} array_taxes
   * @returns
   */
  static calculTaxeFonciere(projet, index_annee, array_taxes) {
    let calcul = 0
    if (index_annee == 0) {
      if (projet.carac.typeexploitation == 'pinel_neuf') {
        calcul =
          -projet.results.timelineFonc.timelineAchevementTravauxPinelNeuf[index_annee] *
          projet.carac.tfonciere.val *
          projet.results.timelineFonc.resTmpLoc[index_annee]
      } else {
        if (projet.carac.typeexploitation == 'classique_neuf') {
          calcul =
            -projet.results.timelineFonc.timelineAchevementTravauxClassiqueNeuf[index_annee] *
            projet.carac.tfonciere.val *
            projet.results.timelineFonc.resTmpLoc[index_annee]
        } else {
          calcul =
            -projet.results.timelineFonc.resDateAcqui[index_annee] *
            projet.carac.tfonciere.val *
            projet.results.timelineFonc.resTmpLoc[index_annee]
        }
      }
    } else {
      if (projet.carac.typeexploitation == 'pinel_neuf') {
        //alors -date achevement travaux timeline * taxe fonciere + taxe fonciere - 1 * (1 + taux revalorisatio charges) * timeline location
        calcul =
          -projet.results.timelineFonc.timelineAchevementTravauxPinelNeuf[index_annee] *
            projet.carac.tfonciere.val +
          array_taxes[index_annee - 1] *
            (1 + Number(projet.carac.trevalorisationOPEX.val) / 100) *
            projet.results.timelineFonc.resTmpLoc[index_annee]
      } else {
        if (projet.carac.typeexploitation == 'classique_neuf') {
          calcul =
            -projet.results.timelineFonc.timelineAchevementTravauxClassiqueNeuf[index_annee] *
              projet.carac.tfonciere.val +
            array_taxes[index_annee - 1] *
              (1 + Number(projet.carac.trevalorisationOPEX.val) / 100) *
              projet.results.timelineFonc.resTmpLoc[index_annee]
        } else {
          calcul =
            -projet.results.timelineFonc.resDateAcqui[index_annee] * projet.carac.tfonciere.val +
            array_taxes[index_annee - 1] *
              (1 + Number(projet.carac.trevalorisationOPEX.val) / 100) *
              projet.results.timelineFonc.resTmpLoc[index_annee]
        }
      }
    }
    return calcul
  }

  /**
   * Retourne le tableau des balances bancaires
   * @param {*} projet
   * @returns
   */
  static getArrayBalanceBancaire(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculBalanceBancaire(projet, i, array))
    })
    return array
  }

  /**
   * Calcul la balance bancaire d'une année
   * @param {*} projet
   * @param {*} index_annee
   * @param {*} reduction_impots
   * @param {*} array_reductions
   * @param {*} total_reduction
   * @returns
   */
  static calculBalanceBancaire(projet, index_annee) {
    let calcul = 0
    if (index_annee > 0) {
      if (projet.carac.amortData[index_annee]) {
        calcul =
          projet.results.timelineFonc.resTimelineEmpr[index_annee - 1] *
          projet.carac.amortData[index_annee].amortCapEnd
      }
    }
    return calcul
  }

  /**
   * Retourne le tableau des remboursements de dette
   * @param {*} projet
   * @returns
   */
  static getArrayRemboursementDette(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculRemboursementDette(projet, i, array))
    })
    return array
  }

  /**
   * Calcul le remboursement de dette d'une année
   * @param {*} projet
   * @param {*} index_annee
   * @param {*} reduction_impots
   * @param {*} array_reductions
   * @param {*} total_reduction
   * @returns
   */
  static calculRemboursementDette(projet, index_annee) {
    let calcul = 0
    let balance = this.calculBalanceBancaire(projet, index_annee - 1)
    calcul = projet.results.timelineFonc.resDateRevente[index_annee] * balance
    if (projet.carac.modefinancement == 'fond_propre') {
      calcul = 0
    }
    return calcul
  }

  /**
   * Retourne le tableau des remboursements de capital
   * @param {*} projet
   * @returns
   */
  static getArrayRemboursementCapital(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculRemboursementCapital(projet, i, array))
    })
    return array
  }

  /**
   * Calcul le remboursement capital d'une année
   * @param {*} projet
   * @param {*} index_annee
   * @param {*} reduction_impots
   * @param {*} array_reductions
   * @param {*} total_reduction
   * @returns
   */
  static calculRemboursementCapital(projet, index_annee) {
    let calcul = 0
    if (projet.carac.amortData[index_annee]) {
      calcul =
        projet.results.timelineFonc.resTimelineEmpr[index_annee] *
        projet.carac.amortData[index_annee].amortCapRemb
    }
    if (projet.carac.modefinancement == 'fond_propre') {
      calcul = 0
    }
    return calcul
  }

  /**
   * Retourne le tableau des emprunts
   * @param {*} projet
   * @returns
   */
  static getArrayEmprunt(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculEmprunt(projet, i, array))
    })
    return array
  }

  /**
   * Calcul l'emprunt pour une année
   * @param {*} projet
   * @param {*} index_annee
   * @param {*} reduction_impots
   * @param {*} array_reductions
   * @param {*} total_reduction
   * @returns
   */
  static calculEmprunt(projet, index_annee) {
    let calcul = 0
    let montant_emprunt = projet.carac.montantemprunte.val

    if (index_annee == 0) {
      if (['pinel_neuf', 'classique_neuf'].includes(projet.carac.typeexploitation)) {
        //alors prn fin premiere cellule
        calcul = projet.carac.amortData[0].amortCapEnd
      } else if (['pinel_ancien', 'denormandie', 'malraux'].includes(projet.carac.typeexploitation)) {
        let sommeReno = 0
        let sommeConstru = 0
        let reno_annee = 0
        let constru_annee = 0
        if (projet.carac.travauxConstru['year_' + index_annee]) {
          constru_annee = +projet.carac.travauxConstru['year_' + index_annee]
        }
        if (projet.carac.travauxReno['year_' + index_annee]) {
          reno_annee = +projet.carac.travauxReno['year_' + index_annee]
        }
        sommeConstru = Object.values(projet.carac.travauxConstru).reduce((a, b) => +a + +b, 0)
        sommeReno = Object.values(projet.carac.travauxReno).reduce((a, b) => +a + +b, 0)
        //montant emprunté - somme travaux lourd - somme travaux reno + travaux reno de l'index + travaux lourd de l'index
        calcul =
          projet.results.timelineFonc.resDateAcqui[index_annee] *
          (montant_emprunt - sommeConstru - sommeReno + reno_annee + constru_annee)
      } else {
        calcul = projet.results.timelineFonc.resDateAcqui[index_annee] * montant_emprunt
      }
    } else if (index_annee >= 1 && index_annee <= 3) {
      if (['pinel_neuf', 'classique_neuf'].includes(projet.carac.typeexploitation)) {
        let pourcent = 0
        if (projet.carac.cumulADF[index_annee]) {
          pourcent =
            projet.carac.cumulADF[index_annee].number / (projet.carac.buyprice + projet.carac.tva.val)
        }
        let prix_achat_emprunt = projet.carac.empruntOption.bien == 'yes' ? projet.carac.buyprice : 0
        let frais_agence_emprunt = projet.carac.empruntOption.agence == 'yes' ? projet.carac.fagence.val : 0
        let travaux_emprunt = projet.carac.empruntOption.travaux == 'yes' ? projet.carac.trvame : 0
        let tva_emprunt = projet.carac.empruntOption.tva == 'yes' ? projet.carac.tva.val : 0
        //prix achat seul + frais agence + travaux et ameublement + tva * cumul ADF %
        calcul = (prix_achat_emprunt + frais_agence_emprunt + travaux_emprunt + tva_emprunt) * pourcent
      } else if (['pinel_ancien', 'denormandie', 'malraux'].includes(projet.carac.typeexploitation)) {
        let reno_annee = 0
        let constru_annee = 0
        if (projet.carac.travauxConstru['year_' + index_annee]) {
          constru_annee = +projet.carac.travauxConstru['year_' + index_annee]
        }
        if (projet.carac.travauxReno['year_' + index_annee]) {
          reno_annee = +projet.carac.travauxReno['year_' + index_annee]
        }
        //travaux année index
        calcul = reno_annee + constru_annee
      } else {
        calcul = projet.results.timelineFonc.resDateAcqui[index_annee] * montant_emprunt
      }
    } else {
      calcul = projet.results.timelineFonc.resDateAcqui[index_annee] * montant_emprunt
    }
    //en cas de fond propre pas d'emprunt
    if (projet.carac.modefinancement == 'fond_propre') {
      calcul = 0
    }
    return calcul
  }

  /**
   * Retourne le tableau de reduction d'impots complet
   * @param {*} projet
   * @returns
   */
  static getArrayReductionImpots(projet, data_globals, investisseur) {
    console.log('getArrayReductionImpots', investisseur)
    let array = []
    let reduction_impots = this.calculReductionImpotAnnuelle(projet, data_globals)
    let total_reduction = this.calculReductionFiscaleTotale(projet, data_globals)
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculReductionImpotAnnee(projet, i, reduction_impots, array, total_reduction, investisseur))
    })
    return array
  }

  /**
   * Calcul la réduction d'impots pour une année
   * @param {*} projet
   * @param {*} index_annee
   * @param {*} reduction_impots
   * @param {*} array_reductions
   * @param {*} total_reduction
   * @returns
   */
  static calculReductionImpotAnnee(projet, index_annee, reduction_impots, array_reductions, total_reduction, investisseur) {
    let calcul = 0
    let somme_reduction = array_reductions.reduce((sum, val) => sum + (val ?? 0), 0)
    
    const calculateReduction = (engagement, reductions) => {
      const reduction6ans = reductions[0] 
      const reduction9ans = reductions[1]
      const reduction12ans = reductions[2]

      const timeline = projet.results.timelineFonc[`timelineReductionImpot${projet.carac.typeexploitation === 'pinel_neuf' ? 'PinelNeuf' : 'PinelAncienDenormandie'}`][index_annee]
      // Calculate the number of years that have received reduction so far
      const yearsWithReduction = array_reductions.filter(r => r > 0).length
      
      if (Number(engagement) === 6 && timeline === 1 && yearsWithReduction < 6) {
        return Math.ceil(reduction6ans)
      } else if (Number(engagement) === 9 && timeline === 1 && yearsWithReduction < 9) {
        return Math.ceil(reduction9ans)
      } else if (Number(engagement) === 12 && timeline === 1) {
        if (yearsWithReduction < 9) {
          return Math.ceil(reduction9ans)
        } else if (yearsWithReduction < 12) {
          return Math.ceil(reduction12ans)
        }
      }
      return 0
    }

    if (['pinel_neuf', 'pinel_ancien'].includes(projet.carac.typeexploitation)) {
      calcul = calculateReduction(projet.carac.engagement, reduction_impots)
    }

    // Loi Malraux
    if (projet.carac.typeexploitation == 'malraux') {
      // pourcentage de réduction d'impot selon la zone
      const taux_reduction_malraux = projet.carac.zonageMalraux === "PSMV_QAD" ? 0.22 : 0.3
      let seuil_travaux_couverts_malraux = 400000

      //si les tableaux n'existent pas il faut les créer:
      // ce tableau est utilisé pour stocker les réductions d'impots généré par l'année, sans les résidu des précédentes années
      if (!projet.results.resFluxAIFonc.resReductionMalrauxDeLAnnee == 'undefined' || index_annee === 0) projet.results.resFluxAIFonc.resReductionMalrauxDeLAnnee = []
      let reduction_malraux_de_l_annee = 0
      
      // ce tableau indique si il y a eu de la cagnotte perdue cette année
      if (!projet.results.resFluxAIFonc.resCagnotteMalrauxPerdue == 'undefined' || index_annee === 0) projet.results.resFluxAIFonc.resCagnotteMalrauxPerdue = []
      let cagnotte_malraux_perdue = 0

      if (!projet.results.resFluxAIFonc.resReductionMalrauxCumulee == 'undefined' || index_annee === 0) projet.results.resFluxAIFonc.resReductionMalrauxCumulee = []
      let reduction_malraux_cumulee = 0

      if (!projet.results.resFluxAIFonc.reductionMalrauxEffective == 'undefined' || index_annee === 0) projet.results.resFluxAIFonc.reductionMalrauxEffective = []
      let reduction_malraux_effective = 0

      // initialize les reductions possible si la valeur n'éxiste pas ou année 0
      // Les reductions totales ne pourront pas depasser la valeur de travaux totale couverte multiplié par le taux
      if (projet.results.resFluxAIFonc.reductionPossibleRestante == 'undefined' || index_annee === 0) {
        projet.results.resFluxAIFonc.reductionPossibleRestante = seuil_travaux_couverts_malraux * taux_reduction_malraux
      }
      let reduction_possible_restante = projet.results.resFluxAIFonc.reductionPossibleRestante

      const duree_travaux_en_annee = projet.carac.annee_fin_travaux

      calcul_des_reductions : if (index_annee <= duree_travaux_en_annee && reduction_possible_restante > 0) {

        // on calcul la reduction par rappport aux travaux
        const travaux_lourds_de_l_annee = projet.carac.travauxConstru['year_' + index_annee] || 0
        const reduction_malraux_de_l_annee_sans_seuil = travaux_lourds_de_l_annee * taux_reduction_malraux

        // on deduit la reduction de l'année de la reduction possible restante
        reduction_possible_restante -= reduction_malraux_de_l_annee_sans_seuil

        // si il ne reste plus de reduction possible, on va reduire la différence
        if (reduction_possible_restante > 0) {
          reduction_malraux_de_l_annee = reduction_malraux_de_l_annee_sans_seuil
        } else {
          reduction_malraux_de_l_annee = reduction_malraux_de_l_annee_sans_seuil + reduction_possible_restante
        }
      }

      calcul_des_cumuls: if (
        index_annee != 0
        && investisseur.impotsIndicatifs
        && investisseur.impotsIndicatifs.length > 0
        && !investisseur.tmi_fixe
        && projet.results.resFluxAIFonc.resReductionMalrauxCumulee[index_annee - 1]
      ) {
        // on va déduire de l'annéé précédente les impots de l'investisseur
        const reduction_malraux_cumulee_de_l_annee_precedente = projet.results.resFluxAIFonc.resReductionMalrauxCumulee[index_annee - 1] || 0

        const impots_de_l_investisseur_de_l_annee_precedente = investisseur.impotsIndicatifs[index_annee - 1] || 0
        const impots_de_l_investisseur_de_l_annee = investisseur.impotsIndicatifs[index_annee] || 0

        const cumul_malraux_de_l_annee = Math.max(0, reduction_malraux_cumulee_de_l_annee_precedente - impots_de_l_investisseur_de_l_annee_precedente)
        if (cumul_malraux_de_l_annee > 0) projet.results.resFluxAIFonc.cumulMalrauxReporte = true
        reduction_malraux_cumulee = reduction_malraux_de_l_annee + cumul_malraux_de_l_annee
        
        if (index_annee >= 4 ) {
          const reduction_malraux_annee_moins_deux = projet.results.resFluxAIFonc.resReductionMalrauxDeLAnnee[index_annee - 2] || 0
          const reduction_malraux_annee_moins_un = projet.results.resFluxAIFonc.resReductionMalrauxDeLAnnee[index_annee - 1] || 0
          const reduction_malraux_annee_moins_trois = projet.results.resFluxAIFonc.resReductionMalrauxDeLAnnee[index_annee - 3 ] || 0
          const reduction_malraux_des_trois_dernieres_annees = reduction_malraux_annee_moins_trois + reduction_malraux_annee_moins_deux + reduction_malraux_annee_moins_un + reduction_malraux_de_l_annee

          if (reduction_malraux_cumulee > reduction_malraux_des_trois_dernieres_annees) {
            cagnotte_malraux_perdue = reduction_malraux_cumulee - reduction_malraux_des_trois_dernieres_annees
            reduction_malraux_cumulee = reduction_malraux_des_trois_dernieres_annees
          }
        }

        reduction_malraux_effective = Math.min(reduction_malraux_cumulee, impots_de_l_investisseur_de_l_annee)

      } else if (index_annee == 0 && investisseur.tmi_fix === false) {
        const impots_de_l_investisseur_de_l_annee = investisseur.impotsIndicatifs[index_annee] || 0
        reduction_malraux_cumulee = reduction_malraux_de_l_annee
        reduction_malraux_effective = Math.min(reduction_malraux_cumulee, impots_de_l_investisseur_de_l_annee)
      } else {
        reduction_malraux_cumulee = reduction_malraux_de_l_annee
        reduction_malraux_effective = reduction_malraux_de_l_annee
      }

      // console.log('annee', index_annee)
      // console.log('reduction_malraux_de_l_annee', reduction_malraux_de_l_annee)
      // console.log('reduction_malraux_cumulee', reduction_malraux_cumulee)
      // console.log('cagnotte_malraux_perdue', cagnotte_malraux_perdue)
      // console.log('reduction_malraux_effective', reduction_malraux_effective)

      // console.log('reduction_possible_restante', reduction_possible_restante)

      // on push les valeurs dans les tableaux
      projet.results.resFluxAIFonc.resReductionMalrauxDeLAnnee.push(reduction_malraux_de_l_annee)
      projet.results.resFluxAIFonc.resReductionMalrauxCumulee.push(reduction_malraux_cumulee)
      projet.results.resFluxAIFonc.resCagnotteMalrauxPerdue.push(cagnotte_malraux_perdue)
      projet.results.resFluxAIFonc.reductionMalrauxEffective.push(reduction_malraux_effective)

      // on update la reduction restante
      projet.results.resFluxAIFonc.reductionPossibleRestante = reduction_possible_restante 

      // et on return la value qui nous interesse
      calcul = reduction_malraux_effective
    }

    if (['denormandie'].includes(projet.carac.typeexploitation)) {
      if (somme_reduction < 9 * reduction_impots[0]) {
        calcul = Math.ceil(
          projet.results.timelineFonc.timelineReductionImpotPinelAncienDenormandie[index_annee] *
            reduction_impots[0]
        )
      } else {
        if (projet.carac.engagement == '12' && somme_reduction < total_reduction) {
          calcul = Math.ceil(reduction_impots[1])
        }
      }
    }
    return calcul
  }

  /**
   * Retourne le tableau des exoneration d'impots
   * @param {*} projet
   * @returns
   */
  static getArrayExonerationTaxeFonciere(projet, array_taxes) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculExonerationTaxeFonciere(projet, i, array_taxes))
    })
    return array
  }

  /**
   * Calcul l'exonération de taxe foncière pour une année
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculExonerationTaxeFonciere(projet, index_annee, array_taxes) {
    let calcul = 0
    if (index_annee >= 0 && index_annee <= 2) {
      if (
        ['pinel_neuf', 'classique_neuf'].includes(projet.carac.typeexploitation) &&
        projet.carac.exonerationTaxeFonciere
      ) {
        calcul = Math.abs(array_taxes[index_annee])
      }
    }
    return calcul
  }

  /**
   * Calcul le cumul ADF
   * @param {*} adf
   * @param {*} cout_acqui
   * @param {*} tva
   * @returns
   */
  static cumulADF(adf, cout_acqui, tva) {
    let annee_0 = {
      number: 0,
      percent: 0,
    }
    let annee_1 = {
      number: 0,
      percent: 0,
    }
    let annee_2 = {
      number: 0,
      percent: 0,
    }
    //année 0
    if (+adf.reservationMois <= 12) {
      annee_0.number += (+adf.reservation / 100) * (cout_acqui + tva)
    }
    if (+adf.achevementFondationMois <= 12) {
      annee_0.number += (+adf.achevementFondation / 100) * (cout_acqui + tva)
    }
    if (+adf.miseEauAirMois <= 12) {
      annee_0.number += (+adf.miseEauAir / 100) * (cout_acqui + tva)
    }
    if (+adf.achevementTravauxMois <= 12) {
      annee_0.number += (+adf.achevementTravaux / 100) * (cout_acqui + tva)
    }
    if (+adf.livraisonRemiseClefMois <= 12) {
      annee_0.number += (+adf.livraisonRemiseClef / 100) * (cout_acqui + tva)
    }
    annee_0.percent = (annee_0.number / (cout_acqui + tva)) * 100
    //année 1
    if (+adf.reservationMois > 12 && +adf.reservationMois <= 24) {
      annee_1.number += (+adf.reservation / 100) * (cout_acqui + tva)
    }
    if (+adf.achevementFondationMois > 12 && +adf.achevementFondationMois <= 24) {
      annee_1.number += (+adf.achevementFondation / 100) * (cout_acqui + tva)
    }
    if (+adf.miseEauAirMois > 12 && +adf.miseEauAirMois <= 24) {
      annee_1.number += (+adf.miseEauAir / 100) * (cout_acqui + tva)
    }
    if (+adf.achevementTravauxMois > 12 && +adf.achevementTravauxMois <= 24) {
      annee_1.number += (+adf.achevementTravaux / 100) * (cout_acqui + tva)
    }
    if (+adf.livraisonRemiseClefMois > 12 && +adf.livraisonRemiseClefMois <= 24) {
      annee_1.number += (+adf.livraisonRemiseClef / 100) * (cout_acqui + tva)
    }
    annee_1.percent = annee_0.percent + (annee_1.number / (cout_acqui + tva)) * 100
    //année 2
    if (+adf.reservationMois > 24 && +adf.reservationMois <= 36) {
      annee_2.number += (+adf.reservation / 100) * (cout_acqui + tva)
    }
    if (+adf.achevementFondationMois > 24 && +adf.achevementFondationMois <= 36) {
      annee_2.number += (+adf.achevementFondation / 100) * (cout_acqui + tva)
    }
    if (+adf.miseEauAirMois > 24 && +adf.miseEauAirMois <= 36) {
      annee_2.number += (+adf.miseEauAir / 100) * (cout_acqui + tva)
    }
    if (+adf.achevementTravauxMois > 24 && +adf.achevementTravauxMois <= 36) {
      annee_2.number += (+adf.achevementTravaux / 100) * (cout_acqui + tva)
    }
    if (+adf.livraisonRemiseClefMois > 24 && +adf.livraisonRemiseClefMois <= 36) {
      annee_2.number += (+adf.livraisonRemiseClef / 100) * (cout_acqui + tva)
    }
    annee_2.percent = annee_1.percent + (annee_2.number / (cout_acqui + tva)) * 100
    return [annee_0, annee_1, annee_2]
  }

  /**
   * Retourne le tableau de reduction des assurances PNO
   * @param {*} projet
   * @returns
   */
  static getArrayFraisExploitation(projet) {
    let array_final = []
    let array_assurance = this.getArrayAssurancePNO(projet)
    let array_copropriete = this.getArrayFraisCopropriete(projet)
    let array_taxe = this.getArrayTaxeFonciere(projet)
    let array_exoneration = this.getArrayExonerationTaxeFonciere(projet, array_taxe)
    let array_gli = this.getArrayGarantieLoyerImpaye(projet)
    let array_frais_gestion = this.getArrayFraisGestionLocative(projet)
    projet.results.timelineFonc.period.forEach((data, i) => {
      array_final.push(
        Math.ceil(
          array_gli[i] +
            array_frais_gestion[i] +
            array_assurance[i] +
            array_copropriete[i] +
            array_taxe[i] +
            array_exoneration[i] +
            projet.results.resOperationNetFonc.resProvPTE[i]
        )
      )
    })
    return array_final
  }

  /**
   * Retourne le tableau de reduction des assurances PNO
   * @param {*} projet
   * @returns
   */
  static getArrayAssurancePNO(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculAssurancePNO(projet, i, array))
    })
    return array
  }

  /**
   * Calcul les frais de copropriete par année
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculAssurancePNO(projet, index_annee, array_assurance) {
    let calcul = 0
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    if (index_annee == 0) {
      if (projet.carac.typeexploitation == 'pinel_neuf') {
        //alors -date achevement travaux timeline * charge copro + charge copro - 1 * (1 + taux revalorisatio charges) * timeline location
        calcul =
          -projet.results.timelineFonc.timelineAchevementTravauxPinelNeuf[index_annee] *
          projet.carac.apno.val *
          projet.results.timelineFonc.resTmpLoc[index_annee]
      } else {
        if (projet.carac.typeexploitation == 'classique_neuf') {
          calcul =
            -projet.results.timelineFonc.timelineAchevementTravauxClassiqueNeuf[index_annee] *
            projet.carac.apno.val *
            projet.results.timelineFonc.resTmpLoc[index_annee]
        } else {
          calcul =
            -projet.results.timelineFonc.resDateAcqui[index_annee] *
            projet.carac.apno.val *
            projet.results.timelineFonc.resTmpLoc[index_annee]
        }
      }
    } else {
        if (this.estAnneeChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)) { 
          calcul =
            -projet.results.timelineFonc.resDateAcqui[index_annee] * projet.carac.nouveauPNO +
            array_assurance[index_annee - 1] *
              (1 + Number(projet.carac.trevalorisationOPEX.val) / 100) *
              projet.results.timelineFonc.resTmpLoc[index_annee]
        } else if (projet.carac.typeexploitation == 'pinel_neuf') {
        //alors -date achevement travaux timeline * charge copro + charge copro - 1 * (1 + taux revalorisatio charges) * timeline location
        calcul =
          -projet.results.timelineFonc.timelineAchevementTravauxPinelNeuf[index_annee] *
            projet.carac.apno.val +
          array_assurance[index_annee - 1] *
            (1 + Number(projet.carac.trevalorisationOPEX.val) / 100) *
            projet.results.timelineFonc.resTmpLoc[index_annee]
        } else if (projet.carac.typeexploitation == 'classique_neuf') {
          calcul =
            -projet.results.timelineFonc.timelineAchevementTravauxClassiqueNeuf[index_annee] *
              projet.carac.apno.val +
            array_assurance[index_annee - 1] *
              (1 + Number(projet.carac.trevalorisationOPEX.val) / 100) *
              projet.results.timelineFonc.resTmpLoc[index_annee]
        } else {
          calcul =
            -projet.results.timelineFonc.resDateAcqui[index_annee] * projet.carac.apno.val +
            array_assurance[index_annee - 1] *
              (1 + Number(projet.carac.trevalorisationOPEX.val) / 100) *
              projet.results.timelineFonc.resTmpLoc[index_annee]
        }
      }
    return calcul
  }

  /**
   * Retourne le tableau de reduction des taxe foncières
   * @param {*} projet
   * @returns
   */
  static getArrayFraisCopropriete(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculFraisCopropriete(projet, i, array))
    })
    return array
  }

  /**
   * Calcul les frais de copropriete par année
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculFraisCopropriete(projet, index_annee, array_frais) {
    let calcul = 0
    if (typeof projet.results.fraisCopropriete == 'undefined') {
      projet.results.fraisCopropriete = []
    }
    if (index_annee == 0) {
      if (projet.carac.typeexploitation == 'pinel_neuf') {
        //alors -date achevement travaux timeline * charge copro + charge copro - 1 * (1 + taux revalorisatio charges) * timeline location
        calcul =
          -projet.results.timelineFonc.timelineAchevementTravauxPinelNeuf[index_annee] *
          projet.carac.chargecopro.val *
          projet.results.timelineFonc.resTmpLoc[index_annee]
      } else {
        if (projet.carac.typeexploitation == 'classique_neuf') {
          calcul =
            -projet.results.timelineFonc.timelineAchevementTravauxClassiqueNeuf[index_annee] *
            projet.carac.chargecopro.val *
            projet.results.timelineFonc.resTmpLoc[index_annee]
        } else {
          calcul =
            -projet.results.timelineFonc.resDateAcqui[index_annee] *
            projet.carac.chargecopro.val *
            projet.results.timelineFonc.resTmpLoc[index_annee]
        }
      }
    } else {
      if (projet.carac.typeexploitation == 'pinel_neuf') {
        //alors -date achevement travaux timeline * charge copro + charge copro - 1 * (1 + taux revalorisatio charges) * timeline location
        calcul =
          -projet.results.timelineFonc.timelineAchevementTravauxPinelNeuf[index_annee] *
            projet.carac.chargecopro.val +
          array_frais[index_annee - 1] *
            (1 + Number(projet.carac.trevalorisationOPEX.val) / 100) *
            projet.results.timelineFonc.resTmpLoc[index_annee]
      } else {
        if (projet.carac.typeexploitation == 'classique_neuf') {
          calcul =
            -projet.results.timelineFonc.timelineAchevementTravauxClassiqueNeuf[index_annee] *
              projet.carac.chargecopro.val +
            array_frais[index_annee - 1] *
              (1 + Number(projet.carac.trevalorisationOPEX.val) / 100) *
              projet.results.timelineFonc.resTmpLoc[index_annee]
        } else {
          calcul =
            -projet.results.timelineFonc.resDateAcqui[index_annee] * projet.carac.chargecopro.val +
            array_frais[index_annee - 1] *
              (1 + Number(projet.carac.trevalorisationOPEX.val) / 100) *
              projet.results.timelineFonc.resTmpLoc[index_annee]
        }
      }
    }
    projet.results.fraisCopropriete.push(calcul)
    return calcul
  }

  /**
   * Calcul la TVA a Payer sur un logement neuf
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculTVAAPayerLogementNeuf(projet, index_annee) {
    let calcul = 0
    if (['pinel_neuf', 'classique_neuf'].includes(projet.carac.typeexploitation)) {
      if (index_annee >= 0 && index_annee <= 2) {
        calcul =
          projet.carac.tva.val *
          (projet.carac.cumulADF[index_annee].number / (projet.carac.tva.val + projet.carac.buyprice))
      } else {
        if (projet.results.timelineFonc.resDateAcqui[index_annee]) {
          calcul = projet.carac.tva.val
        }
      }
    }
    return calcul
  }

  static getArrayCoutInvestissement(projet, avec_travaux = true) {
    let array_investissement = []
    let array_tva = []
    let array_prix_achat = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      let retour = this.coutTotalInvestissement(projet, i, avec_travaux)
      array_investissement.push(retour.calcul)
      array_tva.push(retour.tva)
      array_prix_achat.push(retour.prix_achat)
    })
    let data = {
      array_investissement,
      array_tva,
      array_prix_achat,
    }
    return data
  }

  /**
   * Calcul le cout total d'investissement
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static coutTotalInvestissement(projet, index_annee, avec_travaux = true) {
    let calcul = 0

    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    let data = {
      calcul: 0,
      tva: 0,
      prix_achat: 0,
    }
    let forme_juridique = projet.carac.formejuridique.find((f) => f.selected)
    let prix_achat = this.calculPrixAchatDuBien(projet, index_annee)
    let frais_notaire = projet.results.resCoutTotalFonc.resFraisNotaire[index_annee]
    let frais_immo = projet.results.resCoutTotalFonc.resFraisAgenceImmo[index_annee]
    let prix_revente = projet.results.timelineFonc.resDateRevente[index_annee] * projet.carac.estimprixrevente
    let tva = this.calculTVAAPayerLogementNeuf(projet, index_annee)
    calcul = +prix_achat + +frais_notaire + -frais_immo + +tva + +prix_revente
    if (avec_travaux) {
      if ((index_annee == 0 && ['sasuis', 'sciis', 'lmnpbic', 'lmnpr'].includes(forme_juridique.code))
        ||   this.estAnneeChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)) {
        calcul += Number(projet.carac.fameublement)
      }
      //calcul des travaux
      if (projet.carac.travauxReno['year_' + index_annee]) {
        calcul += +projet.carac.travauxReno['year_' + index_annee]
      }
      if (projet.carac.travauxConstru['year_' + index_annee]) {
        calcul += +projet.carac.travauxConstru['year_' + index_annee]
      }
      if (!projet.results.timelineFonc.resDateRevente[index_annee]) {
        calcul = -calcul
      }
    }
    data.calcul = calcul
    data.prix_achat = prix_achat
    data.tva = tva
    return data
  }

  /**
   * Calcul le tableau des revenus locatifs
   * @param {*} projet
   * @returns
   */
  static getArrayRevenusLocatifs(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(
        projet.results.resOperationNetFonc.resLoyerEnt[i] + projet.results.resOperationNetFonc.resPertes[i]
      )
    })
    return array
  }

  /**
   * Calcul les données pour le tableau de loyer
   * @param {*} projet
   * @returns
   */
  static getArrayLoyer(projet) {
    let array = []

    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux

    projet.results.timelineFonc.period.forEach((data, i) => {
      if (this.estApresChangementFiscalite(changementVersLMNP, i, engagementPlusTravaux)) {
        array[i] = this.calculLoyerClassiqueAncien(projet, i)
      } else {
        if (['pinel_ancien', 'denormandie', 'malraux'].includes(projet.carac.typeexploitation)) {
          array[i] = this.calculLoyerPinelAncienDenormandie(projet, i)
        }
        if (['pinel_neuf'].includes(projet.carac.typeexploitation)) {
          array[i] = this.calculLoyerPinelNeuf(projet, i)
        }
        if (['classique_ancien'].includes(projet.carac.typeexploitation)) {
          array[i] = this.calculLoyerClassiqueAncien(projet, i)
        }
        if (['classique_neuf'].includes(projet.carac.typeexploitation)) {
          array[i] = this.calculLoyerClassiqueNeuf(projet, i)
        }
      } 
    })
    return array
  }

  /**
   * Calcul les loyer pinel ancien et denormandie d'un année
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculLoyerPinelAncienDenormandie(projet, index_annee) {
    let calcul = 0
    if (typeof projet.results.loyerPinelAncienDenormandie == 'undefined') {
      projet.results.loyerPinelAncienDenormandie = []
    }
    let loyermensuel = projet.carac.loyermensuel
    let moisMiseLoc = projet.carac.mois_fin_travaux
    // let pertes = 0
    if (index_annee == 0) {
      //Si on est une année de mise en location
      if (projet.results.timelineFonc.timelineTempsLocationPinelAncienDenormandie[index_annee]) {
        // alors loyer plaffoné * (12 - mois de mise en location)
        calcul = loyermensuel * Math.abs(12 - moisMiseLoc)
      } else {
        //année achevement des travaux (true ou false) * revenus locatifs annuels + année de mise en location (true ou false) * (1+ taux revalorisation irl annuel) * année loyer - 1
        if (projet.results.timelineFonc.timelineAchevementTravauxPinelAncienDenormandie[index_annee]) {
          calcul += loyermensuel * 12
        }
      }
      //Calcul pertes
      // pertes = (-calcul) * projet.carac.rvacanceloc.val - (loyermensuel * 12) * (+projet.carac.differeDeMiseEnLocation /12)
      // calcul = calcul + pertes
    }
    if (index_annee > 0) {
      let calcul_somme_temps_location = 0
      for (let index = 0; index <= index_annee; index++) {
        calcul_somme_temps_location +=
          projet.results.timelineFonc.timelineTempsLocationPinelAncienDenormandie[index]
      }
      //Si on est une année de mise en location
      if (calcul_somme_temps_location == 1) {
        // alors loyer plaffoné * (12 - mois de mise en location)
        calcul =
          projet.results.timelineFonc.timelineTempsLocationPinelAncienDenormandie[index_annee] *
          (loyermensuel * Math.abs(12 - moisMiseLoc))
      } else {
        //si année - 1 loyer >= revenus locatifs annuels
        if (projet.results.loyerPinelAncienDenormandie[index_annee - 1] >= loyermensuel * 12) {
          //année achevement des travaux (true ou false) * revenus locatifs annuels + année de mise en location (true ou false) * (1+ taux revalorisation irl annuel) * année loyer - 1
          calcul +=
            projet.results.timelineFonc.timelineAchevementTravauxPinelAncienDenormandie[index_annee] *
              (loyermensuel * 12) +
            projet.results.timelineFonc.timelineTempsLocationPinelAncienDenormandie[index_annee] *
              (1 + projet.carac.trevalorisation.per / 100) *
              projet.results.loyerPinelAncienDenormandie[index_annee - 1]
        } else {
          calcul =
            projet.results.timelineFonc.timelineTempsLocationPinelAncienDenormandie[index_annee] *
            (loyermensuel * 12)
        }
      }
      //Calcul pertes
      // pertes = (-calcul) * projet.carac.rvacanceloc.val
      // calcul = calcul + pertes
    }
    calcul = Math.round(calcul)
    projet.results.loyerPinelAncienDenormandie[index_annee] = calcul
    return calcul
  }

  /**
   * Calcul le loyer en pinel neuf d'une année
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculLoyerPinelNeuf(projet, index_annee) {
    let calcul = 0
    if (typeof projet.results.loyerPinelNeuf == 'undefined') {
      projet.results.loyerPinelNeuf = []
    }
    let moisMiseLoc = projet.carac.mois_fin_travaux
    let loyermensuel = projet.carac.loyermensuel
    // let pertes = 0
    if (index_annee == 0) {
      //Si on est une année de mise en location
      if (projet.results.timelineFonc.timelineTempsLocationPinelNeuf[index_annee]) {
        // alors loyer plaffoné * (12 - mois de mise en location)
        calcul = loyermensuel * Math.abs(12 - moisMiseLoc)
      } else {
        //année achevement des travaux (true ou false) * revenus locatifs annuels + année de mise en location (true ou false) * (1+ taux revalorisation irl annuel) * année loyer - 1
        if (projet.results.timelineFonc.timelineAchevementTravauxPinelNeuf[index_annee]) {
          calcul +=
            projet.results.timelineFonc.timelineTempsLocationPinelNeuf[index_annee] * (loyermensuel * 12)
        }
      }
      //Calcul pertes
      // pertes = (-calcul) * projet.carac.rvacanceloc.val - (loyermensuel * 12) * (+projet.carac.differeDeMiseEnLocation /12)
      // calcul = calcul + pertes
    }
    if (index_annee > 0) {
      let calcul_somme_temps_location = 0
      for (let index = 0; index <= index_annee; index++) {
        calcul_somme_temps_location += projet.results.timelineFonc.timelineTempsLocationPinelNeuf[index]
      }
      //Si on est une année de mise en location
      if (calcul_somme_temps_location == 1) {
        // alors loyer plaffoné * (12 - mois de mise en location)
        calcul = loyermensuel * Math.abs(12 - moisMiseLoc)
      } else {
        //si année - 1 loyer >= revenus locatifs annuels
        if (projet.results.loyerPinelNeuf[index_annee - 1] >= loyermensuel * 12) {
          //année achevement des travaux (true ou false) * revenus locatifs annuels + année de mise en location (true ou false) * (1+ taux revalorisation irl annuel) * année loyer - 1
          if (projet.results.timelineFonc.timelineAchevementTravauxPinelNeuf[index_annee]) {
            calcul += loyermensuel * 12
          }
          if (projet.results.timelineFonc.timelineTempsLocationPinelNeuf[index_annee]) {
            calcul +=
              (1 + projet.carac.trevalorisation.per / 100) * projet.results.loyerPinelNeuf[index_annee - 1]
          }
        } else {
          calcul = loyermensuel * 12
        }
      }
      //Calcul pertes
      // pertes = (-calcul) * projet.carac.rvacanceloc.val
      // calcul = calcul + pertes
    }
    calcul = projet.results.timelineFonc.timelineTempsLocationPinelNeuf[index_annee] * calcul
    calcul = Math.round(calcul)
    projet.results.loyerPinelNeuf[index_annee] = calcul
    return calcul
  }

  /**
   * Calcul le loyer en neuf classique d'une année
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculLoyerClassiqueNeuf(projet, index_annee) {
    let calcul = 0
    if (typeof projet.results.loyerClassiqueNeuf == 'undefined') {
      projet.results.loyerClassiqueNeuf = []
    }
    let moisMiseLoc = projet.carac.mois_fin_travaux
    let loyermensuel = projet.carac.loyermensuel
    // let pertes = 0
    if (index_annee == 0) {
      //Si on est une année de mise en location
      if (projet.results.timelineFonc.timelineTempsLocationNeufClassique[index_annee]) {
        // alors loyer plaffoné * (12 - mois de mise en location)
        calcul = loyermensuel * Math.abs(12 - moisMiseLoc)
      } else {
        //année achevement des travaux (true ou false) * revenus locatifs annuels + année de mise en location (true ou false) * (1+ taux revalorisation irl annuel) * année loyer - 1
        if (projet.results.timelineFonc.timelineAchevementTravauxClassiqueNeuf[index_annee]) {
          calcul +=
            projet.results.timelineFonc.timelineTempsLocationNeufClassique[index_annee] * (loyermensuel * 12)
        }
      }
      //Calcul pertes
      // pertes = (-calcul) * projet.carac.rvacanceloc.val - (loyermensuel * 12) * (+projet.carac.differeDeMiseEnLocation /12)
      // calcul = calcul + pertes
    }
    if (index_annee > 0) {
      let calcul_somme_temps_location = 0
      for (let index = 0; index <= index_annee; index++) {
        calcul_somme_temps_location += projet.results.timelineFonc.timelineTempsLocationNeufClassique[index]
      }
      //Si on est une année de mise en location
      if (calcul_somme_temps_location == 1) {
        // alors loyer plaffoné * (12 - mois de mise en location)
        calcul = loyermensuel * Math.abs(12 - moisMiseLoc)
      } else {
        //si année - 1 loyer >= revenus locatifs annuels
        if (projet.results.loyerClassiqueNeuf[index_annee - 1] >= loyermensuel * 12) {
          //année achevement des travaux (true ou false) * revenus locatifs annuels + année de mise en location (true ou false) * (1+ taux revalorisation irl annuel) * année loyer - 1
          if (projet.results.timelineFonc.timelineAchevementTravauxClassiqueNeuf[index_annee]) {
            calcul = loyermensuel * 12
          }
          if (projet.results.timelineFonc.timelineTempsLocationNeufClassique[index_annee]) {
            calcul =
              (1 + projet.carac.trevalorisation.per / 100) *
              projet.results.loyerClassiqueNeuf[index_annee - 1]
          }
        } else {
          calcul = loyermensuel * 12
        }
      }
      //Calcul pertes
      // pertes = (-calcul) * projet.carac.rvacanceloc.val
      // calcul = calcul + pertes
    }
    calcul = projet.results.timelineFonc.timelineTempsLocationNeufClassique[index_annee] * calcul
    calcul = Math.round(calcul)
    projet.results.loyerClassiqueNeuf[index_annee] = calcul
    return calcul
  }

  static calculLoyerClassiqueAncien(projet, index_annee) {
    let calcul = 0
    if (typeof projet.results.loyerClassiqueAncien == 'undefined') {
      projet.results.loyerClassiqueAncien = []
    }
    let loyermensuel = 0 
    let previousYear = 0
    // let pertes = 0

    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    const anneeChangement = this.estAnneeChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)

    if (apresChangement) {
      loyermensuel = Number(projet.carac.nouveauLoyerMensuel)
    } else {
      loyermensuel = projet.carac.loyermensuel
    }

    
    if (index_annee == 0) {
      calcul = projet.results.timelineFonc.resDateAcqui[index_annee] * (loyermensuel * 12)
    } else if (anneeChangement) {
      calcul = loyermensuel * 12
    } else {
      calcul =
        projet.results.timelineFonc.resDateAcqui[index_annee] * (loyermensuel * 12) +
        projet.results.timelineFonc.resTmpLoc[index_annee] *
          (1 + projet.carac.trevalorisation.per / 100) *
          (projet.results.loyerClassiqueAncien[index_annee - 1] ? projet.results.loyerClassiqueAncien[index_annee - 1] : loyermensuel * 12)
      //Calcul pertes
      // pertes = (-calcul) * projet.carac.rvacanceloc.val
      // calcul = calcul + pertes
    }
    calcul = Math.round(calcul)
    projet.results.loyerClassiqueAncien[index_annee] = calcul
    return calcul
  }

  /**
   * Retourne le tableau de la timeline achevement travaux mise en location pour pinel neuf
   * @param {*} projet
   * @returns
   */
  static getArrayTimelineChoixJuridiqueNuReelSCIIRReel(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.getTimelineChoixJuridiqueNuReelSCIIRReel(projet, i))
    })
    return array
  }

  /**
   * Retourne l'année de la timeline achevement travaux mise en location pour pinel neuf
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static getTimelineChoixJuridiqueNuReelSCIIRReel(projet, index_annee) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    let retour = 0
    let forme_juridique = projet.carac.formejuridique.find((f) => f.selected)
    let timeline_duree_totale =
      projet.results.timelineFonc.resDateAcqui[index_annee] +
      projet.results.timelineFonc.resTmpLoc[index_annee]
    if (timeline_duree_totale == 2) {
      timeline_duree_totale = 1
    }
    if ((['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) && !projet.carac.change_regime) {
      retour = timeline_duree_totale * 1
    }
    return retour
  }

  /**
   * Retourne le tableau de la timeline achevement travaux mise en location pour pinel neuf
   * @param {*} projet
   * @returns
   */
  static getArrayTotalTimelineAchevementTravauxMiseLocationPinelAncienDenormandie(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.getTimelineAchevementTravauxMiseLocationPinelAncienDenormandie(projet, i))
    })
    return array
  }

  /**
   * Retourne l'année de la timeline achevement travaux mise en location pour pinel neuf
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static getTimelineAchevementTravauxMiseLocationPinelAncienDenormandie(projet, index_annee) {
    let retour = 0
    if (projet.carac.annee_fin_travaux == index_annee) {
      retour = 1
    }
    return retour
  }

  /**
   * Retourne le tableau de la timeline achevement travaux mise en location pour pinel neuf
   * @param {*} projet
   * @returns
   */
  static getArrayTotalTimelineAchevementTravauxMiseLocationPinelNeuf(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.getTimelineAchevementTravauxMiseLocationPinelNeuf(projet, i))
    })
    return array
  }

  /**
   * Retourne l'année de la timeline achevement travaux mise en location pour pinel neuf
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static getTimelineAchevementTravauxMiseLocationPinelNeuf(projet, index_annee) {
    let retour = 0
    let pourcent_1,
      pourcent_2,
      pourcent_3 = 0
    if (projet.carac.cumulADF[0]) {
      pourcent_1 = projet.carac.cumulADF[0].percent
    }
    if (projet.carac.cumulADF[1]) {
      pourcent_2 = projet.carac.cumulADF[1].percent
    }
    if (projet.carac.cumulADF[2]) {
      pourcent_3 = projet.carac.cumulADF[2].percent
    }
    if (projet.carac.typeexploitation == 'pinel_neuf') {
      if (index_annee == 0) {
        if (pourcent_1 == 100) retour = 1
      }
      if (index_annee == 1 && pourcent_1 != 100) {
        if (pourcent_2 == 100) retour = 1
      }
      if (index_annee == 2 && pourcent_1 != 100 && pourcent_2 != 100) {
        if (pourcent_3 == 100) retour = 1
      }
    }
    return retour
  }
  /**
   * Retourne le tableau de la timeline achevement travaux mise en location pour classique neuf
   * @param {*} projet
   * @returns
   */
  static getArrayTotalTimelineAchevementTravauxMiseLocationClassiqueNeuf(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.getTimelineAchevementTravauxMiseLocationClassiqueNeuf(projet, i))
    })
    return array
  }

  /**
   * Retourne l'année de la timeline achevement travaux mise en location pour classique neuf
   * @param {*} projet
   * @returns
   */
  static getTimelineAchevementTravauxMiseLocationClassiqueNeuf(projet, index_annee) {
    let retour = 0
    let pourcent_1,
      pourcent_2,
      pourcent_3 = 0
    if (projet.carac.cumulADF[0]) {
      pourcent_1 = projet.carac.cumulADF[0].percent
    }
    if (projet.carac.cumulADF[1]) {
      pourcent_2 = projet.carac.cumulADF[1].percent
    }
    if (projet.carac.cumulADF[2]) {
      pourcent_3 = projet.carac.cumulADF[2].percent
    }
    if (projet.carac.typeexploitation == 'classique_neuf') {
      if (index_annee == 0) {
        if (pourcent_1 == 100) retour = 1
      }
      if (index_annee == 1 && pourcent_1 != 100) {
        if (pourcent_2 == 100) retour = 1
      }
      if (index_annee == 2 && pourcent_1 != 100 && pourcent_2 != 100) {
        if (pourcent_3 == 100) retour = 1
      }
    }
    return retour
  }

  /**
   * Permet d'avoir un tableau complet des année de mise en location pinel neuf
   * @param {*} projet
   * @returns
   */
  static getArrayTotalTimelineTempsLocationPinelAncienDenormandie(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.getTimelineTempsLocationPinelAncienDenormandie(projet, i))
    })
    return array
  }

  /**
   * Permet de savoir si l'année demandée est celle de la mise en location pinel neuf
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static getTimelineTempsLocationPinelAncienDenormandie(projet, index_annee) {
    let retour = 0
    let momentTravaux = moment(
      moment(projet.carac.dateacqui + '-01')
        .add(projet.carac.annee_fin_travaux, 'years')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    let momentRevente = moment(
      moment(projet.carac.daterevente + '-01')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    let momentPeriod = moment(
      moment(projet.results.timelineFonc.totalperiod[index_annee].slice(0, -3) + '-01')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    if (index_annee >= 0 && index_annee <= 3) {
      if (
        ['pinel_ancien', 'denormandie', 'malraux'].includes(projet.carac.typeexploitation) &&
        momentPeriod.unix() >= momentTravaux.unix()
      ) {
        retour = 1
      }
    } else {
      if (
        ['pinel_ancien', 'denormandie', 'malraux'].includes(projet.carac.typeexploitation) &&
        momentPeriod.unix() >= momentTravaux.unix()
      ) {
        //TODO calcul avec régime fiscal
        if (projet.carac.change_regime) {
          retour = 0
        } else {
          if (momentPeriod.unix() < momentRevente.unix()) {
            retour = 1
          } else {
            retour = 0
          }
        }
      } else {
        retour = 0
      }
    }
    return retour
  }

  /**
   * Permet d'avoir un tableau complet des année de mise en location neuf classique
   * @param {*} projet
   * @returns
   */
  static getArrayTotalTimelineTempsLocationNeufClassique(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.getTimelineTempsLocationNeufClassique(projet, i))
    })
    return array
  }

  /**
   * Permet de savoir si l'année demandée est celle de la mise en location neuf classique
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static getTimelineTempsLocationNeufClassique(projet, index_annee) {
    let retour = 0
    let momentTravaux = moment(
      moment(projet.carac.dateacqui + '-01')
        .add(projet.carac.annee_fin_travaux, 'years')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    let momentRevente = moment(
      moment(projet.carac.daterevente + '-01')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    let momentPeriod = moment(
      moment(projet.results.timelineFonc.totalperiod[index_annee].slice(0, -3) + '-01')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    let pourcent_1,
      pourcent_2,
      pourcent_3 = 0
    if (projet.carac.cumulADF[0]) {
      pourcent_1 = projet.carac.cumulADF[0].percent
    }
    if (projet.carac.cumulADF[1]) {
      pourcent_2 = projet.carac.cumulADF[1].percent
    }
    if (projet.carac.cumulADF[2]) {
      pourcent_3 = projet.carac.cumulADF[2].percent
    }
    if (projet.carac.typeexploitation == 'classique_neuf') {
      if (index_annee == 0) {
        if (pourcent_1 == 100) retour = 1
      }
      if (index_annee == 1) {
        if (pourcent_2 == 100) retour = 1
      }
      if (index_annee == 2) {
        if (pourcent_3 == 100) retour = 1
      }
      //TODO calcul changement fiscalité après
      if (index_annee > 2) {
        if (
          ['classique_neuf'].includes(projet.carac.typeexploitation) &&
          momentPeriod.unix() >= momentTravaux.unix()
        ) {
          //TODO calcul avec régime fiscal
          if (projet.carac.change_regime) {
            retour = 0
          } else {
            if (momentPeriod.unix() < momentRevente.unix()) {
              retour = 1
            } else {
              retour = 0
            }
          }
        } else {
          retour = 0
        }
      }
    }
    return retour
  }

  /**
   * Permet d'avoir un tableau complet des année de mise en location pinel neuf
   * @param {*} projet
   * @returns
   */
  static getArrayTotalTimelineTempsLocationPinelNeuf(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.getTimelineTempsLocationPinelNeuf(projet, i))
    })
    return array
  }

  /**
   * Permet de savoir si l'année demandée est celle de la mise en location pinel neuf
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static getTimelineTempsLocationPinelNeuf(projet, index_annee) {
    let retour = 0
    let momentTravaux = moment(
      moment(projet.carac.dateacqui + '-01')
        .add(projet.carac.annee_fin_travaux, 'years')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    let momentRevente = moment(
      moment(projet.carac.daterevente + '-01')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    let momentPeriod = moment(
      moment(projet.results.timelineFonc.totalperiod[index_annee].slice(0, -3) + '-01')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    let pourcent_1,
      pourcent_2,
      pourcent_3 = 0
    if (projet.carac.cumulADF[0]) {
      pourcent_1 = projet.carac.cumulADF[0].percent
    }
    if (projet.carac.cumulADF[1]) {
      pourcent_2 = projet.carac.cumulADF[1].percent
    }
    if (projet.carac.cumulADF[2]) {
      pourcent_3 = projet.carac.cumulADF[2].percent
    }
    if (projet.carac.typeexploitation == 'pinel_neuf') {
      if (index_annee == 0) {
        if (pourcent_1 == 100) retour = 1
      }
      if (index_annee == 1) {
        if (pourcent_2 == 100) retour = 1
      }
      if (index_annee == 2) {
        if (pourcent_3 == 100) retour = 1
      }
      //TODO calcul changement fiscalité après
      if (index_annee > 2) {
        if (
          ['pinel_neuf'].includes(projet.carac.typeexploitation) &&
          momentPeriod.unix() >= momentTravaux.unix()
        ) {
          //TODO calcul avec régime fiscal
          if (projet.carac.change_regime) {
            retour = 0
          } else {
            if (momentPeriod.unix() < momentRevente.unix()) {
              retour = 1
            } else {
              retour = 0
            }
          }
        } else {
          retour = 0
        }
      }
    }
    return retour
  }

  /**
   * Permet d'avoir un tableau complet des année de réduction d'impots en location pinel neuf
   * @param {*} projet
   * @returns
   */
  static getArrayTotalTimelineReductionImpotPinelAncienDenormandie(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.getTimelineReductionImpotPinelAncienDenormandie(projet, i, array))
    })
    return array
  }

  /**
   * Permet de savoir si l'année demandée est soumise à réduction d'impots
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static getTimelineReductionImpotPinelAncienDenormandie(projet, index_annee, array_timeline) {
    let retour = 0
    let momentTravaux = moment(
      moment(projet.carac.dateacqui + '-01')
        .add(projet.carac.annee_fin_travaux, 'years')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    let momentPeriod = moment(
      moment(projet.results.timelineFonc.totalperiod[index_annee].slice(0, -3) + '-01')
        .endOf('month')
        .format('YYYY-MM-DD')
    )
    let somme_timeline = 0
    for (let index = 0; index < array_timeline.length; index++) {
      somme_timeline += array_timeline[index] ?? 0
    }
    if (index_annee >= 0 && index_annee <= 5) {
      if (
        ['pinel_ancien', 'denormandie'].includes(projet.carac.typeexploitation) &&
        momentPeriod.diff(momentTravaux, 'days') >= 0
      ) {
        retour = 1
      }
    } else {
      if (['pinel_ancien', 'denormandie'].includes(projet.carac.typeexploitation)) {
        if (
          (projet.carac.engagement == '6' && somme_timeline < 6) ||
          (projet.carac.engagement == '9' && somme_timeline < 9) ||
          (projet.carac.engagement == '12' && somme_timeline < 12)
        ) {
          retour = 1
        }
      }

      //TODO calcul avec régime fiscal
      retour = 1
    }
    return retour
  }
  /**
   * Permet d'avoir un tableau complet des année de réduction d'impots en location pinel neuf
   * @param {*} projet
   * @returns
   */
  static getArrayTotalTimelineReductionImpotPinelNeuf(projet) {
    let array = []
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.getTimelineReductionImpotPinelNeuf(projet, i))
    })
    return array
  }

  /**
   * Permet de savoir si l'année demandée est soumise à réduction d'impots
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static getTimelineReductionImpotPinelNeuf(projet, index_annee) {
    let retour = 0
    let pourcent_1,
      pourcent_2,
      pourcent_3 = 0
    if (projet.carac.cumulADF[0]) {
      pourcent_1 = projet.carac.cumulADF[0].percent
    }
    if (projet.carac.cumulADF[1]) {
      pourcent_2 = projet.carac.cumulADF[1].percent
    }
    if (projet.carac.cumulADF[2]) {
      pourcent_3 = projet.carac.cumulADF[2].percent
    }
    if (projet.carac.typeexploitation == 'pinel_neuf') {
      if (index_annee == 0) {
        if (pourcent_1 == 100) retour = 1
      }
      if (index_annee == 1) {
        if (pourcent_2 == 100) retour = 1
      }
      if (index_annee == 2) {
        if (pourcent_3 == 100) retour = 1
      }
      if (index_annee > 2) {
        retour = 1
      }
    }
    return retour
  }

  /**
   * Vérifie si c'est l'année d'achevement des travaux
   * @param {*} projet
   * @returns
   */
  static estAnneeAchevementTravaux(projet) {
    let retour = false
    if (projet.results.timelineFonc.period[index_annee] == projet.carac.achevementtravaux.substr(0, 4)) {
      retour = true
    }
    return retour
  }

  /**
   * Calcul le prix d'achat du bien en fonction des type d'exploitation
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculPrixAchatDuBien(projet, index_annee) {
    let calcul = 0
    if (
      ['pinel_neuf', 'classique_neuf'].includes(projet.carac.typeexploitation) &&
      index_annee >= 0 &&
      index_annee <= 2
    ) {
      calcul =
        projet.carac.buyprice *
        (projet.carac.cumulADF[index_annee].number / (projet.carac.tva.val + projet.carac.buyprice))
    } else {
      if (projet.results.timelineFonc.resDateAcqui[index_annee]) {
        calcul = projet.carac.buyprice
      }
    }
    return calcul
  }

  /**
   * Calcule le cout de revient total pour pinel et denormandie
   * @param {*} projet Le projet
   * @returns
   */
  static calculCoutRevientTotal(projet) {
    let calcul = 0
    if (projet.carac.typeexploitation == 'pinel_neuf') {
      calcul = projet.carac.coutacquisition + projet.carac.fgarantie.val
    }
    if (projet.carac.typeexploitation == 'denormandie') {
      let somme_travaux =
        +projet.carac.travauxReno.year_0 +
        +projet.carac.travauxReno.year_1 +
        +projet.carac.travauxReno.year_2 +
        +projet.carac.travauxReno.year_3
      calcul = projet.carac.coutacquisition + projet.carac.fgarantie.val + somme_travaux
    }
    if (['pinel_ancien', 'malraux'].includes(projet.carac.typeexploitation)) {
      let somme_travaux =
        +projet.carac.travauxConstru.year_0 +
        +projet.carac.travauxConstru.year_1 +
        +projet.carac.travauxConstru.year_2 +
        +projet.carac.travauxConstru.year_3
      calcul = projet.carac.coutacquisition + projet.carac.fgarantie.val + somme_travaux
    }
    return calcul
  }

  /**
   * Calcul le cout de revient après plafond pour pinel et denormandie
   * @param {*} projet
   * @returns
   */
  static calculCoutRevientApresPlafond(projet) {
    let calcul = 0
    let cout_revient_total = this.calculCoutRevientTotal(projet)
    let surface_fiscale = +projet.carac.surface + +projet.carac.surfaceannexe

    if (projet.carac.typeexploitation == 'pinel_neuf') {
      //si cout de revient total pinel neuf <= 300 000 ET cout de revient total pinel neuf / surface fiscal <= 5500
      if (cout_revient_total <= 300000 && cout_revient_total / surface_fiscale <= 5500) {
        // alors cout de revien total pinel neuf
        calcul = cout_revient_total
      } else {
        //si cout de revient total > 300 000
        if (cout_revient_total > 300000) {
          calcul = 300000
        } else {
          //si cout de revient total / surface fiscale > 5500
          if (cout_revient_total / surface_fiscale > 5500) {
            calcul = surface_fiscale * 5500
          }
        }
      }
    }
    if (projet.carac.typeexploitation == 'denormandie') {
      let somme_travaux = 0
      if (projet.carac.typeexploitation == 'denormandie') {
        somme_travaux =
          +projet.carac.travauxReno.year_0 +
          +projet.carac.travauxReno.year_1 +
          +projet.carac.travauxReno.year_2 +
          +projet.carac.travauxReno.year_3
      }
      if (somme_travaux / cout_revient_total >= 0.25) {
        //si cout de revient total pinel neuf <= 300 000 ET cout de revient total pinel neuf / surface fiscal <= 5500
        if (cout_revient_total <= 300000 && cout_revient_total / surface_fiscale <= 5500) {
          // alors cout de revien total pinel neuf
          calcul = cout_revient_total
        } else {
          //si cout de revient total > 300 000
          if (cout_revient_total > 300000) {
            calcul = 300000
          } else {
            //si cout de revient total / surface fiscale > 5500
            if (cout_revient_total / surface_fiscale > 5500) {
              calcul = surface_fiscale * 5500
            }
          }
        }
      }
    }
    if (projet.carac.typeexploitation == 'pinel_ancien') {
      //si cout de revient total pinel neuf <= 300 000 ET cout de revient total pinel neuf / surface fiscal <= 5500
      if (cout_revient_total <= 300000 && cout_revient_total / surface_fiscale <= 5500) {
        // alors cout de revien total pinel neuf
        calcul = cout_revient_total
      } else {
        //si cout de revient total > 300 000
        if (cout_revient_total > 300000) {
          calcul = 300000
        } else {
          //si cout de revient total / surface fiscale > 5500
          if (cout_revient_total / surface_fiscale > 5500) {
            calcul = surface_fiscale * 5500
          }
        }
      }
    }

    return calcul
  }

  /**
   * Calcul les réductions d'impots annuelle pour pinel et denormandie
   * @param {*} projet L'objet projet
   * @returns Tableau avec deux index, le premier c'est la valeur de l'année 1 à 9, ensuite deuxieme index année 10 à 12
   */
  static calculReductionImpotAnnuelle(projet, data_globals) {
    let calculs = []
    let cout_revient_apres_plafond = this.calculCoutRevientApresPlafond(projet)
    if (['denormandie'].includes(projet.carac.typeexploitation)) {
      //2% les 9 première année
      calculs.push(cout_revient_apres_plafond * data_globals.reduction_1_9.val)
      //1% de 10 à 12 ans
      calculs.push(cout_revient_apres_plafond * data_globals.reduction_10_12.val)
    } else if (['pinel_neuf', 'pinel_ancien'].includes(projet.carac.typeexploitation)) {
      // taux pinel
      if (projet.carac.pinelplus) {
        calculs.push(cout_revient_apres_plafond * 0.02)
        calculs.push(cout_revient_apres_plafond * 0.02)
        calculs.push(cout_revient_apres_plafond * 0.01)
      } else {
        calculs.push(cout_revient_apres_plafond * 0.015)
        calculs.push(cout_revient_apres_plafond * 0.0133)
        calculs.push(cout_revient_apres_plafond * 0.0067)
      }
    }
    return calculs
  }

  /**
   * Calcul la réduction fiscale totale en denormandie et pinel
   * @param {*} projet
   * @returns
   */
  static calculReductionFiscaleTotale(projet, data_globals) {
    let calcul = 0
    let calcul_reduction_annuelle = this.calculReductionImpotAnnuelle(projet, data_globals)
    if (projet.carac.engagement == '6') {
      calcul = calcul_reduction_annuelle[0] * 6
    }
    if (projet.carac.engagement == '9') {
      calcul = calcul_reduction_annuelle[0] * 9
    }
    if (projet.carac.engagement == '12') {
      calcul = calcul_reduction_annuelle[0] * 9 + calcul_reduction_annuelle[1] * 3
    }
    return calcul
  }

  /**
   * Calcul la valeur de bien taxable lors de la revente
   * @param {*} projet Le projet
   * @param {*} forme_juridique La forme juridique du projet
   * @param {*} index_annee L'année voulue
   * @returns
   */
  static calculValeurBienTaxable(projet, forme_juridique, index_annee) {
    let calcul = 0
    if (['sciis', 'sasuis', 'sasis'].includes(forme_juridique.code)) {
      calcul =
        projet.results.timelineFonc.resDateRevente[index_annee] *
      (
        -projet.carac.estimprixrevente -
        (-projet.results.resFluxAIFonc.resAmorActif.reduce((a, b) => a + b, 0)
        - projet.carac.buyprice)
        + (projet.results.resFluxAIFonc.resBaseImposableNette[index_annee -1] > 0 ? 0 : -projet.results.resFluxAIFonc.resBaseImposableNette[index_annee -1])
      )
    }
    return calcul
  }

  /**
   * Retourne les dividendes brutes constatées sur l'année en cours (Méthode RCM)
   * @param {*} projet L'object du projet en cours
   * @param {*} forme_juridique Le code de la forme juridique
   * @param {*} impot La valeur de l'impot
   * @param {*} base_imposable La base imposable
   * @param {*} index_annee L'année voulue
   * @returns
   */
  static dividendesBrutAnneeEnCours(projet, forme_juridique, base_imposable, impots_societe, index_annee) {
    let valeur_bien_taxable = 0
    //Si nous avons un impot
    //Et que la forme juridique est sci à is OU sasu
    //Et que l'on a choisi les dividendes
    //Et que le mode d'imposition est RCM
    if (
      (projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] > 0 ||
        projet.results.timelineFonc.resDateRevente[index_annee]) &&
      ['sciis', 'sasuis'].includes(forme_juridique.code) &&
      projet.carac.dividendeSCI &&
      projet.carac.modeimposition == 'ir'
    ) {
      if (projet.results.timelineFonc.resDateRevente[index_annee]) {
        valeur_bien_taxable = this.calculValeurBienTaxable(projet, forme_juridique, index_annee)
      }
      //Alors le résultat est base imposable + valeur du bien taxable en société (partie non amortie) + impots sur les société (sas, sci is, sasu)
      return (
        +projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] +
        valeur_bien_taxable +
        impots_societe
      )
    }
    return 0
  }

  /**
   * Retourne la part fiscale d'un investisseur sans enfant
   * @param {*} projet L'object du projet en cours
   * @param {*} forme_juridique Le code de la forme juridique
   * @param {*} base_imposable La valeur de l'impot
   * @param {*} dividende_brut Les dividendes brutes
   * @param {*} index_annee L'année voulue
   * @returns
   */
  static dividendesNetsImputeeAbattement(
    projet,
    forme_juridique,
    base_imposable,
    impots_societe,
    index_annee
  ) {
    if (
      (base_imposable > 0 || projet.results.timelineFonc.resDateRevente[index_annee]) &&
      ['sciis', 'sasuis'].includes(forme_juridique.code) &&
      projet.carac.dividendeSCI &&
      projet.carac.modeimposition == 'ir'
    ) {
      //Alors le résultat est base imposable + valeur du bien taxable en société (partie non amortie) + impots sur les société (sas, sci is, sasu)
      return (
        this.dividendesBrutAnneeEnCours(
          projet,
          forme_juridique,
          base_imposable,
          impots_societe,
          index_annee
        ) *
        (1 - 40 / 100 - 6.8 / 100)
      )
    }
    return 0
  }

  /**
   * Retourne les impots dividendes associé de l'année
   * @param {*} base_imposable La valeur de l'impot
   * @param {*} forme_juridique Le code de la forme juridique
   * @param {*} projet L'object du projet en cours
   * @param {*} index_annee L'année voulue
   * @param {*} base_imposable La base imposable pour calcul de la flat tax
   * @returns
   */
  static impotDividendeAssocie(
    forme_juridique,
    projet,
    dividende_nette,
    imposition_revenu_bien_locatif,
    index_annee,
    base_imposable,
    impots_is,
    valeur_bien_taxable,
    results_impots_cession
  ) {
    let arrayReturn = []
    //Calcul méthode RCM
    if (
      (dividende_nette > 0 || projet.results.timelineFonc.resDateRevente[index_annee]) &&
      ['sciis', 'sasis'].includes(forme_juridique.code) &&
      projet.carac.modeimposition == 'ir' &&
      projet.carac.dividendeSCI
    ) {
      arrayReturn.push(-imposition_revenu_bien_locatif)
      //dividendesNetsImputeeAbattement * tmi associé 2 * % société (122 * 0.41 * 25) par exemple = 12.55 (13)
      if (projet.carac.type_fisc.length >= 2) {
        for (let index = 1; index < projet.carac.type_fisc.length; index++) {
          arrayReturn.push(
            -(
              dividende_nette *
              (+projet.carac.type_fisc[index].tmi / 100) *
              (projet.carac.type_fisc[index].capital / 100)
            )
          )
        }
      }
    }
    //Calcul méthode flat tax
    //todo à l'avenir corriger l'erreur FLAX_TAX EN FLAT_TAX qui est le bon terme
    if (
      ['sciis', 'sasis'].includes(forme_juridique.code) &&
      projet.carac.modeimposition == 'flax_tax' &&
      projet.carac.dividendeSCI
    ) {
      for (let index = 0; index < 1; index++) {
        if (projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] + valeur_bien_taxable > 0) {
          const dividendes = -(
              projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] *
                projet.results.timelineFonc.resTmpLoc[index_annee] +
              impots_is +
              valeur_bien_taxable +
              results_impots_cession
            ) * 0.3
          projet.results.resFluxAIFonc.resDividendes[index_annee] = dividendes
          arrayReturn.push(
            dividendes
          )
        } else {
          projet.results.resFluxAIFonc.resDividendes[index_annee] = 0
          arrayReturn.push(0)
        }
      }
    }

    if (['sasuis'].includes(forme_juridique.code) && projet.carac.dividendeSCI) {
      if (projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] + valeur_bien_taxable > 0) {
        arrayReturn.push(-imposition_revenu_bien_locatif)
      }
    }

    if (['sasis'].includes(forme_juridique.code)) {
      arrayReturn[1] = 0
      arrayReturn[2] = 0
    }
    return arrayReturn
  }

  /**
   * Retourne les prélévement sociaux des dividendes
   * @param {*} dividende_brut Les dividendes brutes
   * @param {*} taux_prevelement Le pourcentage de prélévement (0.172 actuellement)
   * @return {Number}
   */
  static prelevementSociauxDividendes(dividende_brut, taux_prevelement) {
    return -(dividende_brut * taux_prevelement)
  }

  static getArrayAutresFraisDeductibles(projet) {
    let array = []
    let forme_juridique = projet.carac.formejuridique.find((f) => f.selected)

    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(this.calculAutresFrais(projet, i, forme_juridique))
    })
    return array
  }

  static calculAutresFrais(projet, index_annee, forme_juridique) {
    let calcul = 0

    
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    const anneeChangement = this.estAnneeChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)

    /**
     * Location nue réel OU SCI IR réel
      Autres frais déductibles en régime fiscal "Location nue réel" OU "SCI IR réel" = Travaux de rénovation uniquement
      Si type d'exploitation = "DENORMANDIE" et régime fiscal = "location nue réel" ou "SCI IR réel" ALORS on ne prend en compte en "autres frais déductibles" que les travaux de rénovation excédentaire à 25% du prix de revient
     */
    if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
      if (projet.carac.typeexploitation == 'denormandie') {
        calcul = this.calculDenormandieTravaux(projet, calcul, index_annee)
      } else {
        if (typeof projet.carac.travauxReno['year_' + index_annee] != 'undefined') {
          calcul += +projet.carac.travauxReno['year_' + index_annee]
        }
      }
      if (index_annee == 0 && ['sciirr'].includes(forme_juridique.code)) {
        calcul += projet.carac.fsociete
      }
    }
    /**
     * Autres frais déductibles en régime fiscal "LMNP réel" OU "SCI IS" OU "SAS" OU "SASU" = Frais de notaire + frais d'agence + frais de structure société + travaux rénovation
     */
    if (['lmnpr', 'sciis', 'sasis', 'sasuis'].includes(forme_juridique.code) || apresChangement) {
      calcul = Math.abs(
        Math.abs(projet.results.resOperationNetFonc.resStructureSCI[index_annee]) +
          projet.results.resCoutTotalFonc.resFraisNotaire[index_annee] -
          projet.results.resCoutTotalFonc.resFraisAgenceImmo[index_annee]
      )
      if (typeof projet.carac.travauxReno['year_' + index_annee] != 'undefined') {
        calcul += +projet.carac.travauxReno['year_' + index_annee]
      }
    }

    /**
     * TRAVAUX LOURDS :
    On les rajoute à la valeur amortissable quand :
    LMNP REEL
    SCI IS
    SASU/SAS
    Sinon leur valeur est juste ajouté à la trésorerie
     */

    /**
     On ajoute la premiere annee la deduction sur les frais d'ammeublement 
    */
    

    if ((index_annee == 0 && ['sasuis', 'sciis', 'lmnpbic', 'lmnpr'].includes(forme_juridique.code))
      || anneeChangement) {
       calcul += projet.carac.fameublementDeduction
    }
    return calcul
  }

  /**
   * Calcul les autres frais déductibles en fonction de plusieurs paramètres
   * @param {*} projet Le projet
   * @param {*} structSCI Les frais de structure de société
   * @param {*} fraisNotaire Les frais de notaire
   * @param {*} fraisAgenceImmo Les frais d'agence immobilière
   * @param {*} forme_juridique La forme juridique du projet
   * @param {*} index_annee L'année voulue
   * @returns
   */
  static calculAutresFraisDeductibles(
    projet,
    structSCI,
    fraisNotaire,
    fraisAgenceImmo,
    forme_juridique,
    index_annee
  ) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    let calcul = 0

    /**
     * Location nue réel OU SCI IR réel
      Autres frais déductibles en régime fiscal "Location nue réel" OU "SCI IR réel" = Travaux de rénovation uniquement
      Si type d'exploitation = "DENORMANDIE" et régime fiscal = "location nue réel" ou "SCI IR réel" ALORS on ne prend en compte en "autres frais déductibles" que les travaux de rénovation excédentaire à 25% du prix de revient
     */
    if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
      if (projet.carac.typeexploitation == 'denormandie') {
        calcul = this.calculDenormandieTravaux(projet, calcul, index_annee)
      } else {
        if (typeof projet.carac.travauxReno['year_' + index_annee] != 'undefined') {
          calcul += +projet.carac.travauxReno['year_' + index_annee]
        }
      }
    }
    /**
     * Autres frais déductibles en régime fiscal "LMNP réel" OU "SCI IS" OU "SAS" OU "SASU" = Frais de notaire + frais d'agence + frais de structure société + travaux rénovation
     */
    if (['lmnpr', 'sciis', 'sasis', 'sasuis'].includes(forme_juridique.code) || apresChangement) {
      calcul = Math.abs(Math.abs(structSCI) + fraisNotaire + fraisAgenceImmo)
      if (typeof projet.carac.travauxReno['year_' + index_annee] != 'undefined') {
        calcul += +projet.carac.travauxReno['year_' + index_annee]
      }
    }

    /**
     * TRAVAUX LOURDS :
  On les rajoute à la valeur amortissable quand :
  LMNP REEL
  SCI IS
  SASU/SAS
  Sinon leur valeur est juste ajouté à la trésorerie
     */
    return calcul
  }
  /**
   * Impôt sur les revenus locatifs de sociétés selon l'impôt sur les sociétés (SAS, SCI IS, SASU)
   * @param {*} base_imposable
   * @param {*} forme_juridique
   * @returns
   */
  static calculImpotSasSciisSasu(valeur_bien_taxable, forme_juridique, projet, index, base_imposable_nette, globals) {
    const seuil_impots = globals.plafondImpositionIS
    const taux_bas = globals.timpositionIS.val
    const taux_haut =globals.impositionIS.val

    let calcul = 0
    let data = valeur_bien_taxable > 0 ? valeur_bien_taxable : base_imposable_nette
    let timeline = data > 0 ? 1 : 0

    if (data > 0 && ['sciis', 'sasuis', 'sasis'].includes(forme_juridique.code)) {
      if (data < seuil_impots) {
        calcul = timeline * -(data * taux_bas)
        projet.results.resFluxAIFonc.resRateIS[index] = taux_bas
      } else {
        calcul = timeline * -(seuil_impots * taux_bas + (data - seuil_impots) * taux_haut)
        projet.results.resFluxAIFonc.resRateIS[index] = taux_haut
      }
    } else {
      projet.results.resFluxAIFonc.resRateIS[index] = 0
    }
    projet.results.resFluxAIFonc.resImpotIS[index] = calcul
    return calcul
  }

  /**
   *
   * @param {*} forme_juridique La forme juridique
   * @param {*} base_imposable_brute La base imposable brute
   * @param {*} invest L'objet de l'investisseur
   * @param {*} interets Les intérets
   * @param {*} revenusLocatifs Le revenus locatifs
   * @param {*} index_annee L'index de l'année (0, 1 ...)
   * @param {*} dataImpositionDetaille Le tableau complet des impositions détaillés
   * @returns
   */
  static calculDeficitFoncierReel(
    projet,
    forme_juridique,
    base_imposable_brute,
    invest,
    interets,
    revenusLocatifs,
    index_annee,
    dataImpositionDetaille
  ) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    let base_imposable = base_imposable_brute
    let base_imposable_nette = 0
    let cagnotte = 0
    let fonciers = 0

    let doublement_deficit_foncier = 10700

    // anne acquisition + index_annee
    const yearAcqui = new Date(projet.carac.dateacqui).getFullYear()
    let year = Number(yearAcqui) + Number(index_annee)

    if (year < 2026 && projet.carac.doubleDFTravauxEnergie) {
      doublement_deficit_foncier = 21400
    }
    if (invest.fiscalite) {
      fonciers = +invest.fiscalite[index_annee].fonciers
    }
    //Déficit foncier et cagnotte pour location nue réel et SCI IR au réel
    if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
      //Si base imposable brute supérieure à 0 alors on l'affiche
      if (base_imposable > 0) {
        if (index_annee > 0) {
          base_imposable_nette = Math.max(0, base_imposable - dataImpositionDetaille[index_annee - 1][6])
        } else {
          base_imposable_nette = base_imposable
        }
      } else {
        //Si nous n'avons pas d'autres revenus foncier cette année
        if (fonciers == 0) {
          if (
            (revenusLocatifs != 0 && base_imposable > 0) ||
            (base_imposable < 0 && base_imposable > -doublement_deficit_foncier)
          ) {
            base_imposable_nette = base_imposable
          } else {
            base_imposable_nette = -doublement_deficit_foncier
          }
          if (index_annee > 0) {
            base_imposable_nette = Math.max(0, base_imposable - dataImpositionDetaille[index_annee - 1][6])
          }
        } else {
          if (base_imposable < 0 && base_imposable + fonciers > -doublement_deficit_foncier) {
            base_imposable_nette = base_imposable
            //Retrait de la cagnotte de l'an passé
            if (index_annee > 0) {
              base_imposable -= dataImpositionDetaille[index_annee - 1][6]
            }
          } else {
            base_imposable_nette = -doublement_deficit_foncier - fonciers
          }
        }
      }
      cagnotte = Math.abs(base_imposable - base_imposable_nette)
      if (interets > revenusLocatifs) {
        cagnotte = revenusLocatifs - interets
      }
      if (index_annee > 0) {
        cagnotte += dataImpositionDetaille[index_annee - 1][6]
        if (base_imposable > 0) {
          cagnotte = Math.max(0, cagnotte - base_imposable * 2)
        }
      }
    }
    if (['sciis'].includes(forme_juridique.code)) {
      if (index_annee > 0) {
        cagnotte = dataImpositionDetaille[index_annee - 1][6] + base_imposable
        base_imposable_nette = cagnotte
        if (cagnotte > 0) cagnotte = 0
      } else {
        if (base_imposable < 0) {
          cagnotte = base_imposable
        }
        base_imposable_nette = base_imposable
      }
    }
    return {
      base_imposable,
      base_imposable_nette,
      cagnotte,
    }
  }

  /**
   * Calcul la réduction des frais déductibles en denormandie si besoin
   * @param {*} projet Le projet
   * @param {*} autresFraisDeductibles Les autresFraisDéductibles
   * @param {*} index_annee L'année voulue
   * @returns
   */
  static calculDenormandieTravaux(projet, autresFraisDeductibles, index_annee) {
    if (projet.results.timelineFonc.timelineAchevementTravauxPinelAncienDenormandie[index_annee]) {
      //Si c'est l'année de fin de travaux et Si projet de type Denormandie alors modification par rapport aux travaux
      //Calculer les 4 première année de travaux
      let cout_travaux_quatre_ans =
        +projet.carac.travauxReno.year_0 +
        +projet.carac.travauxReno.year_1 +
        +projet.carac.travauxReno.year_2 +
        +projet.carac.travauxReno.year_3
      //Si montant des travaux des 4 première année > 25% des (cout d'acquisition + cout travaux 4 ans + frais garantie) de DENORMANDIE (VOIR EXCEL)
      let total_denormandie = this.calculCoutRevientApresPlafond(projet)
      let vingt_cinq_pourcent_denormandie = (cout_travaux_quatre_ans / total_denormandie) * 100

      //Ce qui est au dessus de 25% peut etre retiré des autres frais déductibles
      if (vingt_cinq_pourcent_denormandie > 25) {
        let retrait_impots_denormandie = cout_travaux_quatre_ans - total_denormandie * 0.25
        autresFraisDeductibles += retrait_impots_denormandie
      }
      // [x] Faire attention plafond à l'avenir
    }
    if (index_annee > 3) {
      if (typeof projet.carac.travauxReno['year_' + index_annee] != 'undefined') {
        autresFraisDeductibles += +projet.carac.travauxReno['year_' + index_annee]
      }
    }
    return autresFraisDeductibles
  }

  /**
   * Calcul de la cagnotte globale sur 10 ans
   * @param {*} forme_juridique
   * @param {*} index_annee
   * @param {*} base_imposable_brute
   * @param {*} invest
   * @param {*} dataImpositionDetaille
   * @returns
   */
  static calculCagnotteGlobale(
    projet,
    forme_juridique,
    index_annee,
    base_imposable_brute,
    invest,
    dataImpositionDetaille,
    revenus_locatifs
  ) {
    let calcul = 0
    let fonciers = 0
    if (invest.fiscalite) {
      fonciers = +invest.fiscalite[index_annee].fonciers
    }
    let base_imposable_fonciers = this.calculBaseImposableBruteAutresRF(
      projet,
      forme_juridique,
      invest,
      index_annee,
      base_imposable_brute,
      dataImpositionDetaille
    )
    let base_imposable_reel = this.calculBaseImposableReel(
      projet,
      forme_juridique,
      invest,
      index_annee,
      base_imposable_brute,
      revenus_locatifs,
      dataImpositionDetaille
    )
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    //Première colonne différente des autres
    if (index_annee == 0) {
      //si base imposable avec autres revenus fonciers = 0
      if (base_imposable_fonciers == 0) {
        if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
          // OUI si location nue réel et sci irr alors base imposable - base imposable nu réel - base imposable avec revenus foncier
          calcul = base_imposable_brute - base_imposable_reel - base_imposable_fonciers
        }
      } else {
        if (base_imposable_fonciers - fonciers === base_imposable_brute) {
          calcul = 0
        } else {
          if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
            // OUI si location nue réel et sci irr alors base imposable - base imposable nu réel - base imposable avec revenus foncier
            calcul = base_imposable_brute - base_imposable_reel - base_imposable_fonciers
          }
        }
      }
    }

    if (index_annee > 0) {
      //si base imposable avec autres revenus fonciers = 0
      if (base_imposable_fonciers == 0) {
        if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
          // OUI si location nue réel et sci irr alors cagnotte déductible 10 ans + base imposable - base imposable nu réel - base imposable avec revenus foncier
          calcul =
            dataImpositionDetaille[index_annee - 1][6] +
            base_imposable_brute -
            base_imposable_reel -
            base_imposable_fonciers
        }
      } else {
        if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
          // OUI si location nue réel et sci irr alors cagnotte déductible 10 ans + base imposable - base imposable nu réel - base imposable avec revenus foncier
          calcul = dataImpositionDetaille[index_annee - 1][6] + base_imposable_brute - base_imposable_fonciers
        }
      }
      //si loyer entrant -1 == 0 et base imposable avec revenus foncier == 0
      if (dataImpositionDetaille[index_annee - 1][0] == 0 && base_imposable_fonciers == 0) {
        calcul += dataImpositionDetaille[index_annee - 1][7]
      }
    }
    return calcul
  }

  /**
   * Calcul de la cagnotte reportable à afficher dans le tableau d'imposition
   * @param {*} forme_juridique
   * @param {*} index_annee
   * @param {*} base_imposable_brute
   * @param {*} invest
   * @param {*} dataImpositionDetaille
   * @returns
   */
  static calculCagnotteReportable(
    forme_juridique,
    index_annee,
    base_imposable_brute,
    invest,
    dataImpositionDetaille,
    revenus_locatifs,
    base_imposable_nette,
    projet
  ) {
    let calcul = 0
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    // let cagnotteLNR = this.calculCagnotteGlobaleLNR();
    //Si base imposable LMNP reel est < 0 alors on affiche la base imposable, sinon 0
    if (['lmnpr'].includes(forme_juridique.code) && base_imposable_nette <= 0 || apresChangement) {
      calcul += base_imposable_nette
    }
    let cagnotte_globale = this.calculCagnotteGlobale(
      projet,
      forme_juridique,
      index_annee,
      base_imposable_brute,
      invest,
      dataImpositionDetaille,
      revenus_locatifs
    )
    let cagnotte_reserve = this.calculCagnotteReserveeEmprunt(projet, index_annee)
    // Cagnotte totale déductible sur 10 ans (somme cagnotte globale et cagnote réservée emprunt)
    if (index_annee < 10) {
      calcul += cagnotte_globale + -cagnotte_reserve
    } else if (index_annee == 10) {
      //base imposable brute - 10 < 0
      if (dataImpositionDetaille[0][5] < 0) {
        //si cagnotte total - 1 < (cagnotte total - 10 - cagnotte total - 11)
        if (dataImpositionDetaille[index_annee - 1][6] < dataImpositionDetaille[0][6] - 0) {
          // alors somme de cagnotte réservée et cagnotte globale l'index -  (cagnotte total - 10 - cagnotte total - 11)
          calcul = cagnotte_globale + cagnotte_reserve - (dataImpositionDetaille[0][6] - 0)
        } else {
          //sinon si cagnotte total - 1 > (cagnotte total - 10 - cagnotte total - 11)
          if (dataImpositionDetaille[index_annee - 1][6] > dataImpositionDetaille[0][6] - 0) {
            // alors somme de cagnotte réservée et cagnotte globale l'index -  cagnotte réservée
            calcul = cagnotte_globale + cagnotte_reserve - cagnotte_globale
          } else {
            //sinon base imposable - 1 + base imposable
            calcul = 0
          }
        }
      } else {
        calcul = cagnotte_globale + cagnotte_reserve
      }
    } else {
      //base imposable brute - 10 < 0
      if (dataImpositionDetaille[index_annee - 10][5] < 0) {
        //si cagnotte total - 1 < (cagnotte total - 10 - cagnotte total - 11)
        if (
          dataImpositionDetaille[index_annee - 1][6] <
          dataImpositionDetaille[index_annee - 10][6] - dataImpositionDetaille[index_annee - 11][6]
        ) {
          // alors somme de cagnotte réservée et cagnotte globale l'index -  (cagnotte total - 10 - cagnotte total - 11)
          calcul =
            cagnotte_globale +
            cagnotte_reserve -
            (dataImpositionDetaille[index_annee - 10][6] - dataImpositionDetaille[index_annee - 11][6])
        } else {
          //sinon si cagnotte total - 1 > (cagnotte total - 10 - cagnotte total - 11)
          if (
            dataImpositionDetaille[index_annee - 1][6] >
            dataImpositionDetaille[index_annee - 10][6] - dataImpositionDetaille[index_annee - 11][6]
          ) {
            // alors somme de cagnotte réservée et cagnotte globale l'index -  cagnotte réservée
            calcul = cagnotte_globale + cagnotte_reserve - cagnotte_globale
          } else {
            //sinon base imposable - 1 + base imposable
            calcul = 0
          }
        }
      } else {
        calcul = cagnotte_globale + cagnotte_reserve
      }
    }
    // si base imposable sci is / sas /sasu <= 0 alors on mets sinon 0

    if (['sciis', 'sasuis', 'sasis'].includes(forme_juridique.code) && base_imposable_nette <= 0) {
      calcul += base_imposable_nette
    }
    return calcul
  }

  /**
   * Calcul la cagnotte réservée à l'emprunt d'un année
   * @param {*} projet
   * @param {*} index_annee
   * @returns
   */
  static calculCagnotteReserveeEmprunt(projet, index_annee) {
    let calcul = 0
    let revenus = projet.results.resOperationNetFonc.resRevLoc[index_annee]
    let interets = Math.abs(projet.results.resInvTravFonc.resItr[index_annee])
    if (projet.results.timelineFonc.timelineChoixJuridiqueNuReelSCIIRReel[index_annee]) {
      if (interets > revenus) {
        calcul = interets - revenus
      }
    }
    return calcul
  }

  /**
   * Calcule l'imposition du projet
   * @param {*} investisseur L'object investisseur concerné
   * @param {*} index_annee L'année voulue
   * @param {*} tmis Le tableau de TMIS en base de données
   * @param {*} dividende_nette Les dividendes nettes
   * @param {*} projet L'objet du projet
   * @param {*} forme_juridique La forme juridique
   * @returns
   */
  static calculImposition(
    investisseur,
    index_annee,
    tmis,
    dividende_nette,
    projet,
    forme_juridique,
    base_imposable_nette
  ) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    //Calcul de la situation matrimoniale
    let situation_matrimoniale = 1
    if (investisseur.fiscalite) {
      switch (investisseur.fiscalite[0].situation) {
        case 'celibataire':
          situation_matrimoniale = 1
          break
        case 'marie':
          situation_matrimoniale = 2
          break
        case 'pacse':
          situation_matrimoniale = 2
          break
        case 'divorce':
          situation_matrimoniale = 1
          break
        case 'veuf':
          situation_matrimoniale = 2
          break
        case 'separe':
          situation_matrimoniale = 1
          break

        default:
          break
      }
    }
    let tmi_fix = investisseur.tmi_fix
    let tmi = !tmi_fix
      ? InvestorTools.getTMI(investisseur.fiscalite, index_annee, tmis)
      : investisseur.tmi

    let traitement_salaire_nets = investisseur.emprunt.emprunt_rev_tot
    //Calcul de l'imposition globale
    if (!tmi_fix) {
      if (+investisseur.fiscalite[index_annee].salaire * 0.1 <= 12829) {
        traitement_salaire_nets =
          +investisseur.fiscalite[index_annee].salaire - +investisseur.fiscalite[index_annee].salaire * 0.1
      } else {
        traitement_salaire_nets = +investisseur.fiscalite[index_annee].salaire - 12829
      }
    } else {
      if (investisseur.emprunt.emprunt_rev_tot * 0.1 <= 12829) {
        traitement_salaire_nets = traitement_salaire_nets - investisseur.emprunt.emprunt_rev_tot * 0.1
      } else {
        traitement_salaire_nets = traitement_salaire_nets - 12829
      }
    }
    let revenus_net_imposables_avant_projet = !tmi_fix
      ? traitement_salaire_nets +
        Number(investisseur.fiscalite[index_annee].bic) +
        Number(investisseur.fiscalite[index_annee].fonciers) +
        Number(investisseur.fiscalite[index_annee].rcm) +
        Number(+investisseur.fiscalite[index_annee].autrebic)
      : traitement_salaire_nets
    //Indication de l'impot avant projet
    let indication_impot_avant_projet = 0
    let part_fiscale_sans_enfant = !tmi_fix
      ? InvestorTools.partsFiscaleSansEnfant(investisseur.fiscalite[0].situation)
      : 1
    let part_fiscale_avec_enfant = !tmi_fix
      ? InvestorTools.partsFiscaleEnfant(
          investisseur.fiscalite[0].situation,
          investisseur.fiscalite[0].enfants
        )
      : 1
    let quotient_familial_sans_enfant_avant_projet =
      revenus_net_imposables_avant_projet / part_fiscale_sans_enfant
    let quotient_familial_avec_enfant_avant_projet =
      revenus_net_imposables_avant_projet / part_fiscale_avec_enfant
    let tmi_sans_enfants_avant_projet = InvestorTools.getTMINombre(
      quotient_familial_sans_enfant_avant_projet,
      tmis
    )
    let tmi_avec_enfants_avant_projet = InvestorTools.getTMINombre(
      quotient_familial_avec_enfant_avant_projet,
      tmis
    )
    if (tmi_fix) {
      tmi_sans_enfants_avant_projet = tmi
      tmi_avec_enfants_avant_projet = tmi
    }
    let imposition_revenus_global_sans_enfant_avant_projet = InvestorTools.imposition_revenu_global(
      quotient_familial_sans_enfant_avant_projet,
      tmi_sans_enfants_avant_projet,
      tmis,
      situation_matrimoniale
    )
    let imposition_revenus_global_avec_enfant_avant_projet = InvestorTools.imposition_revenu_global(
      quotient_familial_avec_enfant_avant_projet,
      tmi_avec_enfants_avant_projet,
      tmis,
      part_fiscale_avec_enfant
    )
    let nb_enfants = investisseur.fiscalite ? investisseur.fiscalite[index_annee].enfants : 0
    //calcul de l'indication avant projet

    if (
      imposition_revenus_global_sans_enfant_avant_projet -
        imposition_revenus_global_avec_enfant_avant_projet <=
      3184 * (part_fiscale_avec_enfant - part_fiscale_sans_enfant)
    ) {
      indication_impot_avant_projet = imposition_revenus_global_avec_enfant_avant_projet
    } else {
      indication_impot_avant_projet =
        imposition_revenus_global_sans_enfant_avant_projet -
        3184 * (part_fiscale_avec_enfant - part_fiscale_sans_enfant)
    }
    //**indication de l'impot après projet**
    let indication_impot_apres_projet = 0
    let revenus_fonciers_nets = revenus_net_imposables_avant_projet
    let revenus_net_imposables_apres_projet = revenus_fonciers_nets

    if (dividende_nette > 0 && projet.carac.modeimposition == 'ir') {
      let capital = 0
      if (['sasuis'].includes(forme_juridique.code)) {
        capital = 100
      } else {
        capital = projet.carac.type_fisc[0].capital
      }
      revenus_net_imposables_apres_projet += dividende_nette * (capital / 100)
    }
    let revenus = 0
    if (['lnr', 'lnmf', 'sciirr', 'sciirmf', 'lmnpbic'].includes(forme_juridique.code) && !apresChangement) {
      if (projet.carac.type_fisc.length > 1) {
        revenus =
          projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] *
          (projet.carac.type_fisc[0].capital / 100)
      } else {
        revenus = projet.results.resFluxAIFonc.resBaseImposableNette[index_annee]
      }
      revenus_net_imposables_apres_projet += revenus
    }
    if (
      (['lmnpr'].includes(forme_juridique.code) || apresChangement) &&
      projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] > 0
      
    ) {
      revenus_net_imposables_apres_projet += projet.results.resFluxAIFonc.resBaseImposableNette[index_annee]
    }

    let quotient_familial_sans_enfant_apres_projet =
      revenus_net_imposables_apres_projet / part_fiscale_sans_enfant
    let quotient_familial_avec_enfant_apres_projet =
      revenus_net_imposables_apres_projet / part_fiscale_avec_enfant
    let tmi_sans_enfants_apres_projet = InvestorTools.getTMINombre(
      quotient_familial_sans_enfant_apres_projet,
      tmis
    )
    let tmi_avec_enfants_apres_projet = InvestorTools.getTMINombre(
      quotient_familial_avec_enfant_apres_projet,
      tmis
    )

    if (tmi_fix) {
      tmi_sans_enfants_apres_projet = tmi
      tmi_avec_enfants_apres_projet = tmi
    }
    let imposition_revenus_global_sans_enfant_apres_projet = InvestorTools.imposition_revenu_global(
      quotient_familial_sans_enfant_apres_projet,
      tmi_sans_enfants_apres_projet,
      tmis,
      situation_matrimoniale
    )
    let imposition_revenus_global_avec_enfant_apres_projet = InvestorTools.imposition_revenu_global(
      quotient_familial_avec_enfant_apres_projet,
      tmi_avec_enfants_apres_projet,
      tmis,
      part_fiscale_avec_enfant
    )

    if (
      imposition_revenus_global_sans_enfant_apres_projet -
        imposition_revenus_global_avec_enfant_apres_projet <=
      3184 * (part_fiscale_avec_enfant - part_fiscale_sans_enfant)
    ) {
      indication_impot_apres_projet = imposition_revenus_global_avec_enfant_apres_projet
    } else {
      indication_impot_apres_projet =
        imposition_revenus_global_sans_enfant_apres_projet -
        3184 * (part_fiscale_avec_enfant - part_fiscale_sans_enfant)
    }

    // tmi = tmi_sans_enfants_apres_projet ? tmi_sans_enfants_apres_projet : tmi ;
    let imposition_revenu_bien_locatif = indication_impot_avant_projet - indication_impot_apres_projet
    if (imposition_revenu_bien_locatif > 0) {
      imposition_revenu_bien_locatif = -Math.abs(imposition_revenu_bien_locatif)
    } else {
      imposition_revenu_bien_locatif = Math.abs(imposition_revenu_bien_locatif)
    }

    let prelevement_sociaux = 0
    if (projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] > 0) {
      if (projet.carac.type_fisc.length > 1) {
        prelevement_sociaux =
          projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] *
          (projet.carac.type_fisc[0].capital / 100) *
          0.172
      } else {
        prelevement_sociaux = projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] * 0.172
      }
    }
    if (tmi_fix) {
      imposition_revenu_bien_locatif = projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] * (tmi / 100)
    }
    // imposition_revenu_bien_locatif = revenus * (tmi / 100)
    let imposition_revenu_genere = imposition_revenu_bien_locatif
    let prelevement_sociaux_genere = prelevement_sociaux
    let impot_associe_1 = 0
    let impot_associe_2 = 0
    let impot_associe_3 = 0
    let prelevement_sociaux_associe_1 = 0
    let prelevement_sociaux_associe_2 = 0
    let prelevement_sociaux_associe_3 = 0
    let plus_value_immobiliere = 0
    if (projet.carac.type_fisc.length > 1) {
      impot_associe_1 =
        projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] *
        (projet.carac.type_fisc[0].tmi / 100) *
        (projet.carac.type_fisc[0].capital / 100)
      prelevement_sociaux_associe_1 =
        projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] *
        0.172 *
        (projet.carac.type_fisc[0].capital / 100)

      impot_associe_2 =
        projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] *
        (projet.carac.type_fisc[1].tmi / 100) *
        (projet.carac.type_fisc[1].capital / 100)
      prelevement_sociaux_associe_2 =
        projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] *
        0.172 *
        (projet.carac.type_fisc[1].capital / 100)
      if (projet.carac.type_fisc.length > 2) {
        impot_associe_3 =
          projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] *
          (projet.carac.type_fisc[2].tmi / 100) *
          (projet.carac.type_fisc[2].capital / 100)
        prelevement_sociaux_associe_3 =
          projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] *
          0.172 *
          (projet.carac.type_fisc[2].capital / 100)
      }
      if (prelevement_sociaux_associe_1 < 0) {
        prelevement_sociaux_associe_1 = 0
      }
      if (prelevement_sociaux_associe_2 < 0) {
        prelevement_sociaux_associe_2 = 0
      }
      if (prelevement_sociaux_associe_3 < 0) {
        prelevement_sociaux_associe_3 = 0
      }
    }
    if (['lnr', 'lnmf', 'lmnpbic', 'lmnpr'].includes(forme_juridique.code) || apresChangement) {
      impot_associe_1 = 0
      impot_associe_2 = 0
      impot_associe_3 = 0
      prelevement_sociaux_associe_1 = 0
      prelevement_sociaux_associe_2 = 0
      prelevement_sociaux_associe_3 = 0
    }
    if (['sciirmf', 'sciirr'].includes(forme_juridique.code)) {
      imposition_revenu_bien_locatif = 0
      prelevement_sociaux = 0
    }
    plus_value_immobiliere = -projet.results.resFluxAIFonc.resPV[index_annee]
    if (['sciis', 'sasuis', 'sasis'].includes(forme_juridique.code)) {
      imposition_revenu_bien_locatif = 0
      prelevement_sociaux = 0
      impot_associe_1 = 0
      impot_associe_2 = 0
      impot_associe_3 = 0
      prelevement_sociaux_associe_1 = 0
      prelevement_sociaux_associe_2 = 0
      prelevement_sociaux_associe_3 = 0
      plus_value_immobiliere = 0
    }
    if (plus_value_immobiliere > 0) {
      if (['lnr', 'lnmf', 'lmnpbic', 'lmnpr', 'sciirr', 'sciirmf'].includes(forme_juridique.code) || apresChangement) {
        plus_value_immobiliere =
          projet.results.timelineFonc.resDateRevente[index_annee] * plus_value_immobiliere
      } else {
        plus_value_immobiliere = 0
      }
    } else {
      plus_value_immobiliere = 0
    }
    let impot = -Math.round(
      imposition_revenu_bien_locatif + impot_associe_1 + impot_associe_2 + impot_associe_3
    )
    let prelevement = -Math.round(
      prelevement_sociaux +
        prelevement_sociaux_associe_1 +
        prelevement_sociaux_associe_2 +
        prelevement_sociaux_associe_3
    )
    //timeline impot positif
    // if(projet.results.resFluxAIFonc.resBaseImposableNette[index_annee] < 0){
    //   impot = 0
    //   prelevement = 0
    // }
    //tmi après projet
    if (
      imposition_revenus_global_avec_enfant_apres_projet -
        imposition_revenus_global_sans_enfant_apres_projet <=
      3184 * (part_fiscale_avec_enfant - part_fiscale_sans_enfant)
        ? part_fiscale_avec_enfant
        : 0
    ) {
      tmi = tmi_sans_enfants_apres_projet
    } else {
      tmi = tmi_avec_enfants_apres_projet
    }
    return {
      tmi,
      imposition_revenu_bien_locatif: impot,
      prelevement_sociaux: prelevement,
      impot_associe_1,
      impot_associe_2,
      impot_associe_3,
      prelevement_sociaux_associe_1,
      prelevement_sociaux_associe_2,
      prelevement_sociaux_associe_3,
      plus_value_immobiliere,
      imposition_revenu_genere,
      prelevement_sociaux_genere,
    }
  }

  /**
   * Calcul les charges locatives d'une année
   * @param {*} projet
   * @param {*} forme_juridique
   * @param {*} index_annee
   * @returns
   */
  static calculChargesLocatives(projet, forme_juridique, index_annee) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    let calcul = 0
    // Pas de calcul des frais dans ces régimes
    if (['lnmf', 'lmnpbic', 'sciirmf'].includes(forme_juridique.code) && !apresChangement) {
      calcul = 0
    } else {
      calcul = projet.results.resOperationNetFonc.resFraisExploit[index_annee]
    }
    return calcul
  }

  /**
   * Calcul les charges financières d'une année
   * @param {*} projet
   * @param {*} forme_juridique
   * @param {*} index_annee
   * @returns
   */
  static calculChargesFinancieres(projet, forme_juridique, index_annee) {
    let calcul = 0
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    // Pas de calcul des frais dans ces régimes
    if (['lnmf', 'lmnpbic', 'sciirmf'].includes(forme_juridique.code) && !apresChangement) {
      calcul = 0
    } else {
      let assurance = 0
      let partInteret = projet.results.resInvTravFonc.resItr[index_annee]
      if (typeof projet.carac.amortData != 'undefined') {
        assurance = projet.carac.amortData[index_annee]?.amortAssur
          ? projet.carac.amortData[index_annee].amortAssur
          : 0
      }
      let fraisBancaires = projet.results.resInvTravFonc.resFraisBanc[index_annee]
      let indemRembDette = projet.results.resInvTravFonc.resIndemRmbDette[index_annee - 1]
        ? projet.results.resInvTravFonc.resIndemRmbDette[index_annee - 1]
        : 0
      calcul = Math.abs(fraisBancaires - Math.abs(partInteret) - Math.abs(assurance) - indemRembDette)
    }

    if (projet.carac.modefinancement == 'fond_propre') {
      calcul = 0
    }

    if (projet.results.resFluxAIFonc) {
      projet.results.resFluxAIFonc.resChargesFinancieres = projet.results.resFluxAIFonc.resChargesFinancieres || []
      projet.results.resFluxAIFonc.resChargesFinancieres[index_annee] = calcul
    }
    return calcul
  }

  static getArrayBaseImposableNetteEtCagnotte(projet, investisseur) {
    let dataReturn = {
      array_base_imposable_nette: [],
      array_cagnotte: [],
    }
    let tableaux_cagnotte = {
      cagnotte_nu_reel_debut: [],
      cagnotte_nu_reel_fin: [],
      cagnotte_nu_reel_reservee: [],
      cagnotte_nu_reel_totale: [],
      timeline_nu_reel: [],
      array_base_imposable_nette: [],
      cagnotte_lmnp_debut: [],
      cagnotte_lmnp_amortissement: [],
      cagnotte_lmnp_deficit: [],
      cagnotte_lmnp_fin: [],
      timeline_lmnp: [],

      cagnotte_lmp_pro_reservee: [],
      cagnotte_lmp_pro_cumul: [],
    }
    let forme_juridique = projet.carac.formejuridique.find((f) => f.selected)
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    projet.results.timelineFonc.period.forEach((data, i) => {
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, i, engagementPlusTravaux)
      if ((forme_juridique.code == 'lnr' || forme_juridique.code == 'sciirr') && !apresChangement) {
        dataReturn.array_cagnotte.push(
          Math.round(
            this.calculCagnotteReport(
              projet,
              forme_juridique,
              i,
              dataReturn.array_base_imposable_nette,
              dataReturn.array_cagnotte,
              tableaux_cagnotte,
              investisseur
            )
          )
        )
        dataReturn.array_base_imposable_nette = tableaux_cagnotte.array_base_imposable_nette
      } else if (!['sasuis', 'sasis', 'sciis'].includes(forme_juridique.code)) {
        dataReturn.array_cagnotte.push(
          Math.round(
            this.calculCagnotteReport(
              projet,
              forme_juridique,
              i,
              dataReturn.array_base_imposable_nette,
              dataReturn.array_cagnotte,
              tableaux_cagnotte,
              investisseur
            )
          )
        )
        dataReturn.array_base_imposable_nette.push(
          this.calculBaseImposableNet(
            projet,
            i,
            forme_juridique,
            investisseur,
            dataReturn.array_base_imposable_nette,
            dataReturn.array_cagnotte,
            tableaux_cagnotte
          )
        )
      } else {
        dataReturn.array_base_imposable_nette.push(
          this.calculBaseImposableNet(
            projet,
            i,
            forme_juridique,
            investisseur,
            dataReturn.array_base_imposable_nette,
            dataReturn.array_cagnotte,
            tableaux_cagnotte
          )
        )
        dataReturn.array_cagnotte.push(
          Math.round(
            this.calculCagnotteReport(
              projet,
              forme_juridique,
              i,
              dataReturn.array_base_imposable_nette,
              dataReturn.array_cagnotte,
              tableaux_cagnotte,
              investisseur
            )
          )
        )
      }
    })
    return dataReturn
  }

  static calculBaseImposableNuReel(
    projet,
    forme_juridique,
    invest,
    index_annee,
    array_base_imposable_nette,
    array_cagnotte,
    tableaux_cagnotte
  ) {
    let calcul = 0
    let fonciers = 0
    let base_imposable_brute = projet.results.resFluxAIFonc.resBaseImposable[index_annee]
    let revenus_locatifs = projet.results.resOperationNetFonc.resRevLoc[index_annee]

    let doublement_deficit_foncier = 10700

    // anne acquisition + index_annee
    const yearAcqui = new Date(projet.carac.dateacqui).getFullYear()
    let year = Number(yearAcqui) + Number(index_annee)

    if (year < 2026 && projet.carac.doubleDFTravauxEnergie) {
      doublement_deficit_foncier = 21400
    }

    if (invest.fiscalite) {
      fonciers = +invest.fiscalite[index_annee].fonciers
    }
    if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
      if (index_annee == 0) {
        //si fonciers == 0

        if (fonciers == 0) {
          //si loyer entrant == 0

          if (revenus_locatifs == 0) {
            calcul = 0
          } else {
            if (
              base_imposable_brute > 0 ||
              (base_imposable_brute < 0 && base_imposable_brute > -doublement_deficit_foncier)
            ) {
              calcul = base_imposable_brute
            } else {
              calcul = -doublement_deficit_foncier
            }
          }
        }
      }
      if (index_annee > 0) {
        if (fonciers == 0) {
          if (revenus_locatifs == 0) {
            calcul = 0
          } else {
            //cagnotte globale - 1 == 0 && base imposable >= -doublement_deficit_foncier
            if (
              tableaux_cagnotte.cagnotte_nu_reel_debut[index_annee] == 0 &&
              base_imposable_brute >= -doublement_deficit_foncier
            ) {
              calcul =
                projet.results.timelineFonc.timelineChoixJuridiqueNuReelSCIIRReel[index_annee] *
                base_imposable_brute
            } else {
              // si base imposable  >= 0 && base imposable + cagnotte >= 0
              if (
                base_imposable_brute >= 0 &&
                base_imposable_brute + tableaux_cagnotte.cagnotte_nu_reel_debut[index_annee] >= 0
              ) {
                calcul =
                  projet.results.timelineFonc.timelineChoixJuridiqueNuReelSCIIRReel[index_annee] *
                    base_imposable_brute +
                  tableaux_cagnotte.cagnotte_nu_reel_debut[index_annee]
              } else {
                //sinon si base imposable  >= 0 && base imposable + cagnotte < 0
                if (
                  base_imposable_brute >= 0 &&
                  base_imposable_brute + tableaux_cagnotte.cagnotte_nu_reel_debut[index_annee] < 0
                ) {
                  calcul = 0
                } else {
                  //sinon si base imposable < 0 && base imposable >= -doublement_deficit_foncier
                  if (base_imposable_brute < 0 && base_imposable_brute >= -doublement_deficit_foncier) {
                    calcul =
                      projet.results.timelineFonc.timelineChoixJuridiqueNuReelSCIIRReel[index_annee] *
                      base_imposable_brute
                  } else {
                    calcul =
                      projet.results.timelineFonc.timelineChoixJuridiqueNuReelSCIIRReel[index_annee] *
                      -doublement_deficit_foncier
                  }
                }
              }
            }
          }
        }
      }
    }
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    const anneeChangement = this.estAnneeChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)

    const amortissement_comptable = projet.results.resFluxAIFonc.resAmorActif

    if (['lmnpr'].includes(forme_juridique.code) || apresChangement) {
      if (index_annee == 0 || anneeChangement) {
        if (array_cagnotte[index_annee] < 0) {
          calcul = 0
        } else {
          calcul = projet.results.resFluxAIFonc.resBaseImposable[index_annee]
        }
      } else if (index_annee > 0) {
        if (array_cagnotte[index_annee] < 0) {
          calcul = 0
        } else {
          if (array_cagnotte[index_annee - 1] < (projet.results.resFluxAIFonc.resBaseImposable[index_annee -10] - amortissement_comptable[index_annee - 10])) {
            calcul =
              projet.results.resFluxAIFonc.resBaseImposable[index_annee] + array_cagnotte[index_annee - 1]
          } else {
            calcul = (
              projet.results.resFluxAIFonc.resBaseImposable[index_annee]
            )
          }
        }
      }
    }
    return calcul
  }

  static calculBaseImposableBruteAutresRevenusFoncier(
    projet,
    forme_juridique,
    invest,
    index_annee,
    array_cagnotte
  ) {
    let calcul = 0
    let fonciers = 0

    let doublement_deficit_foncier = 10700

    // anne acquisition + index_annee
    const yearAcqui = new Date(projet.carac.dateacqui).getFullYear()
    let year = Number(yearAcqui) + Number(index_annee)

    if (year < 2026 && projet.carac.doubleDFTravauxEnergie) {
      doublement_deficit_foncier = 21400
    }

    let base_imposable_brute = projet.results.resFluxAIFonc.resBaseImposable[index_annee]
    if (invest.fiscalite) {
      fonciers = Number(invest.fiscalite[index_annee].fonciers)
    }
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    //calcul année 0
    if (fonciers != 0 && index_annee == 0) {
      if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement ) {
        //base imposable > 0 OU base imposable < 0 && base imposable + fonciers > -doublement_deficit_foncier
        if (
          base_imposable_brute > 0 ||
          (base_imposable_brute < 0 && base_imposable_brute + fonciers > -doublement_deficit_foncier)
        ) {
          calcul = base_imposable_brute
        } else {
          calcul = -doublement_deficit_foncier - fonciers
        }
      }
    }
    //calcul autres années
    if (fonciers != 0 && index_annee > 0) {
      if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
        //si la cagnotte globale - 1 = 0 et que la base imposable >= -doublement_deficit_foncier
        if (array_cagnotte[index_annee - 1] == 0 && base_imposable_brute >= -doublement_deficit_foncier) {
          //alors base imposable
          calcul = base_imposable_brute
        } else {
          //sinon si la base imposable >= 0 et que base imposable + cagnotte globale >= 0
          if (base_imposable_brute >= 0 && base_imposable_brute + array_cagnotte[index_annee - 1] >= 0) {
            //alors base imposable + cagnotte
            calcul = base_imposable_brute + array_cagnotte[index_annee - 1]
          } else {
            //sinon si la base imposable >= 0 ET base imposable + cagnotte < 0
            if (base_imposable_brute >= 0 && base_imposable_brute + array_cagnotte[index_annee - 1] < 0) {
              calcul = 0
            } else {
              //sinon si base imposable < 0 ET base imposable + revenus fonciers <= -doublement_deficit_foncier
              if (
                base_imposable_brute < 0 &&
                base_imposable_brute + fonciers <= -doublement_deficit_foncier
              ) {
                // alors -doublement_deficit_foncier - revenus fonciers
                calcul = -doublement_deficit_foncier - fonciers
              } else {
                calcul = base_imposable_brute
              }
            }
          }
        }
      }
    }
    return calcul
  }

  static calculBaseImposableNet(
    projet,
    index_annee,
    forme_juridique,
    investisseur,
    array_base_imposable_nette,
    array_cagnotte,
    tableaux_cagnotte
  ) {
    let calcul = 0
    let revenusLocatifs = projet.results.resOperationNetFonc.resRevLoc[index_annee]

    let baseImposableBrute = projet.results.resFluxAIFonc.resBaseImposable[index_annee]
    if (['lmnpbic'].includes(forme_juridique.code)) {
      calcul = revenusLocatifs * 0.5
    }
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    if (['lmnpr'].includes(forme_juridique.code) || apresChangement) {
      calcul = this.calculBaseImposableNuReel(
        projet,
        forme_juridique,
        investisseur,
        index_annee,
        array_base_imposable_nette,
        array_cagnotte,
        tableaux_cagnotte
      )
    }
    
    if (['lnmf', 'sciirmf'].includes(forme_juridique.code) && !apresChangement  ) {
      calcul = revenusLocatifs * 0.7
    }
    if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
      calcul =
        this.calculBaseImposableBruteAutresRevenusFoncier(
          projet,
          forme_juridique,
          investisseur,
          index_annee,
          array_cagnotte
        ) +
        this.calculBaseImposableNuReel(
          projet,
          forme_juridique,
          investisseur,
          index_annee,
          array_base_imposable_nette,
          array_cagnotte,
          tableaux_cagnotte
        )
    }
    if (['sciis', 'sasis', 'sasuis'].includes(forme_juridique.code)) {
      if (index_annee == 0) {
        calcul = projet.results.resFluxAIFonc.resBaseImposable[index_annee]
      } else {
        if (array_base_imposable_nette[index_annee - 1] < 0) {
          calcul =
            projet.results.resFluxAIFonc.resBaseImposable[index_annee] +
            array_base_imposable_nette[index_annee - 1]
        } else {
          calcul = projet.results.resFluxAIFonc.resBaseImposable[index_annee]
        }
      }
    }
    return calcul
  }

  static calculCagnotteReport(
    projet,
    forme_juridique,
    index_annee,
    array_base_imposable_nette,
    array_cagnotte,
    tableaux_cagnotte,
    investisseur
  ) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    let calcul = 0

    //cagnotte totale (location nue réel)
    if ((forme_juridique.code == 'lnr' || forme_juridique.code == 'sciirr') && !apresChangement) {
      this.calculCagnotteLocationNueReel(
        projet,
        index_annee,
        array_cagnotte,
        tableaux_cagnotte,
        forme_juridique,
        investisseur
      )
      calcul += tableaux_cagnotte.cagnotte_nu_reel_totale[index_annee]
    }
    //cagnotte fin période (LMNP au réel)
    if (forme_juridique.code == 'lmnpr' || apresChangement) {
      this.calculCagnotteLMNPReel(
        projet,
        index_annee,
        forme_juridique,
        array_base_imposable_nette,
        array_cagnotte,
        tableaux_cagnotte
      )
      calcul += tableaux_cagnotte.cagnotte_lmnp_fin[index_annee]
    }

    //si type exploitation == Location meublée professionnelle

    //si base imposable nette SCI IS / SAS / SASU <= 0
    if (
      ['sciis', 'sasis', 'sasuis'].includes(forme_juridique.code) &&
      array_base_imposable_nette[index_annee] <= 0
    ) {
      calcul += array_base_imposable_nette[index_annee]
      // console.log('Calcul cagnotte report SCI IS / SAS / SASU', calcul)
    }

    return calcul
  }

  static calculCagnotteLocationNueReel(
    projet,
    i,
    array_cagnotte,
    tableaux_cagnotte,
    forme_juridique,
    investisseur
  ) {
    let somme_timeline = 0
    for (let index = 0; index < tableaux_cagnotte.timeline_nu_reel.length; index++) {
      somme_timeline += tableaux_cagnotte.timeline_nu_reel[index] ?? 0
    }
    //**calcul début période **
    if (i == 0) {
      tableaux_cagnotte.cagnotte_nu_reel_debut.push(0)
    }
    if (i > 0 && i < 10) {
      tableaux_cagnotte.cagnotte_nu_reel_debut.push(tableaux_cagnotte.cagnotte_nu_reel_totale[i - 1])
    }
    if (i >= 10) {
      //si la somme de timeline déficit repotable nu réel de D63 à M63 > 0
      if (somme_timeline > 0) {
        //alors cagnotte totale - 1
        tableaux_cagnotte.cagnotte_nu_reel_debut.push(tableaux_cagnotte.cagnotte_nu_reel_totale[i - 1])
      } else {
        //sinon si cagnotte totale - 1 < cagnotte fin période - 10
        if (
          tableaux_cagnotte.cagnotte_nu_reel_totale[i - 1] < tableaux_cagnotte.cagnotte_nu_reel_fin[i - 10]
        ) {
          tableaux_cagnotte.cagnotte_nu_reel_debut.push(
            tableaux_cagnotte.cagnotte_nu_reel_totale[i - 1] - tableaux_cagnotte.cagnotte_nu_reel_fin[i - 10]
          )
        } else {
          //sinon si cagnotte totale - 1 > cagnotte fin période - 10
          if (
            tableaux_cagnotte.cagnotte_nu_reel_totale[i - 1] > tableaux_cagnotte.cagnotte_nu_reel_fin[i - 10]
          ) {
            tableaux_cagnotte.cagnotte_nu_reel_debut.push(0)
          } else {
            tableaux_cagnotte.cagnotte_nu_reel_debut.push(tableaux_cagnotte.cagnotte_nu_reel_totale[i - 1])
          }
        }
      }
    }
    /** fin calcul début période **/

    /** CALCUL BASE IMPOSABLE NETTE **/
    let calcul_bin = 0

    let revenusLocatifs = projet.results.resOperationNetFonc.resRevLoc[i]

    let baseImposableBrute = projet.results.resFluxAIFonc.resBaseImposable[i]
    if (['lmnpbic'].includes(forme_juridique.code)) {
      calcul_bin = revenusLocatifs * 0.5
    }
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, i, engagementPlusTravaux)
    if (['lmnpr'].includes(forme_juridique.code) || apresChangement) {
      calcul_bin = this.calculBaseImposableNuReel(
        projet,
        forme_juridique,
        investisseur,
        i,
        tableaux_cagnotte.array_base_imposable_nette,
        array_cagnotte,
        tableaux_cagnotte
      )
    }
    if (['lnmf', 'sciirmf'].includes(forme_juridique.code) && !apresChangement) {
      calcul_bin = revenusLocatifs * 0.7
    }
    if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
      calcul_bin =
        this.calculBaseImposableBruteAutresRevenusFoncier(
          projet,
          forme_juridique,
          investisseur,
          i,
          array_cagnotte
        ) +
        this.calculBaseImposableNuReel(
          projet,
          forme_juridique,
          investisseur,
          i,
          tableaux_cagnotte.array_base_imposable_nette,
          array_cagnotte,
          tableaux_cagnotte
        )
    }
    if (['sciis', 'sasis', 'sasuis'].includes(forme_juridique.code)) {
      if (i == 0) {
        calcul_bin = baseImposableBrute
      } else {
        calcul_bin = baseImposableBrute + tableaux_cagnotte.array_base_imposable_nette[i - 1]
      }
    }
    tableaux_cagnotte.array_base_imposable_nette.push(calcul_bin)

    /** FIN CALCUL BASE IMPOSABLE NETTE **/

    /** calcul fin période **/
    //si base imposable nette nu réel == 0
    if (tableaux_cagnotte.array_base_imposable_nette[i] == 0) {
      //timeline nu réel * (base imposable brute + cagnotte début - base imposable nette)

      tableaux_cagnotte.cagnotte_nu_reel_fin.push(
        projet.results.resFluxAIFonc.resBaseImposable[i] +
          tableaux_cagnotte.cagnotte_nu_reel_debut[i] -
          tableaux_cagnotte.array_base_imposable_nette[i]
      )
    } else if (tableaux_cagnotte.array_base_imposable_nette[i] != 0) {
      //timeline nu réel * (base imposable brute + cagnotte début - base imposable nette revenus foncier)
      tableaux_cagnotte.cagnotte_nu_reel_fin.push(
        projet.results.resFluxAIFonc.resBaseImposable[i] +
          tableaux_cagnotte.cagnotte_nu_reel_debut[i] -
          tableaux_cagnotte.array_base_imposable_nette[i]
      )
    }
    /** fin calcul fin période **/
    /** debut calcul reservée **/
    if (Math.abs(projet.results.resInvTravFonc.resItr[i]) > projet.results.resOperationNetFonc.resRevLoc[i]) {
      tableaux_cagnotte.cagnotte_nu_reel_reservee.push(
        -(Math.abs(projet.results.resInvTravFonc.resItr[i]) - projet.results.resOperationNetFonc.resRevLoc[i])
      )
    } else {
      tableaux_cagnotte.cagnotte_nu_reel_reservee.push(0)
    }
    /** fin calcul reservée **/

    //timeline
    if (tableaux_cagnotte.cagnotte_nu_reel_fin[i] != 0) {
      tableaux_cagnotte.timeline_nu_reel.push(0)
    } else {
      tableaux_cagnotte.timeline_nu_reel.push(1)
    }
    tableaux_cagnotte.cagnotte_nu_reel_totale.push(
      tableaux_cagnotte.cagnotte_nu_reel_fin[i] + tableaux_cagnotte.cagnotte_nu_reel_reservee[i]
    )
    return tableaux_cagnotte
  }

  static calculCagnotteLMNPReel(
    projet,
    i,
    forme_juridique,
    array_base_imposable_nette,
    array_cagnotte,
    tableaux_cagnotte
  ) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const anneeChangement = this.estAnneeChangementFiscalite(changementVersLMNP, i, engagementPlusTravaux)
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, i, engagementPlusTravaux)
    const apres10ansChangement = this.estApresChangementFiscalite(changementVersLMNP, i, engagementPlusTravaux + 10)
    const t = tableaux_cagnotte

    if(anneeChangement){
      for (let index = 0; index < engagementPlusTravaux; index++) {
        t.cagnotte_lmnp_debut.push(0)
        t.cagnotte_lmnp_fin.push(0)
        t.cagnotte_lmnp_amortissement.push(0)
        t.cagnotte_lmnp_deficit.push(0)
      }
    }
    
    let somme_timeline = 0
    for (let index = 0; index < t.timeline_lmnp.length; index++) {
      somme_timeline += t.timeline_lmnp[index] ?? 0
    }

    if (i == 0) {
      t.cagnotte_lmnp_debut.push(0)
    }
    else if ((i > 0 && i < 10) || (changementVersLMNP && (i > Number(engagementPlusTravaux) && i < (Number(engagementPlusTravaux) +10)))) {
      t.cagnotte_lmnp_debut.push(t.cagnotte_lmnp_fin[i - 1])
    }
    else if ((i >= 10 && !changementVersLMNP)|| apres10ansChangement) {
      if (somme_timeline > 0) {
        t.cagnotte_lmnp_debut.push(t.cagnotte_lmnp_fin[i - 1])
      } else {
        if (t.cagnotte_lmnp_fin[i - 1] < t.cagnotte_lmnp_deficit[i - 10]) {
          t.cagnotte_lmnp_debut.push(t.cagnotte_lmnp_fin[i - 1] - t.cagnotte_lmnp_deficit[i - 10])
        } else {
            t.cagnotte_lmnp_debut.push(0)
        }
      } 
    }
    // ligne 37 de l'onglet "cagnotte et base" de l'excel : Cagnotte réservée au déficit de l'exploitation (travaux, charges locatives). Report sur 10ans 
    t.cagnotte_lmnp_amortissement.push(projet.results.resFluxAIFonc.resAmorActif[i])
    if (projet.results.resFluxAIFonc.resBaseImposable[i] - t.cagnotte_lmnp_amortissement[i] <= 0) {
      t.cagnotte_lmnp_deficit.push(projet.results.resFluxAIFonc.resBaseImposable[i] - t.cagnotte_lmnp_amortissement[i])
    } else {
      t.cagnotte_lmnp_deficit.push(0)
    }

    if ((i == 0 || anneeChangement) && projet.results.resFluxAIFonc.resBaseImposable[i] < 0) {
      t.cagnotte_lmnp_fin.push(projet.results.resFluxAIFonc.resBaseImposable[i]) 
    }
    else if (i > 0 || (!anneeChangement && apres10ansChangement)) {
      if (changementVersLMNP) {
        if (t.cagnotte_lmnp_fin[i-1] + projet.results.resFluxAIFonc.resBaseImposable[i] < 0) {
        t.cagnotte_lmnp_fin.push(t.cagnotte_lmnp_fin[i-1] + projet.results.resFluxAIFonc.resBaseImposable[i])
      } else {
        t.cagnotte_lmnp_fin.push(0)
      }
      }else if (t.cagnotte_lmnp_debut[i] + projet.results.resFluxAIFonc.resBaseImposable[i] < 0) {
        t.cagnotte_lmnp_fin.push(t.cagnotte_lmnp_debut[i] + projet.results.resFluxAIFonc.resBaseImposable[i])
      } else {
        t.cagnotte_lmnp_fin.push(0)
      }
    }

    if (t.cagnotte_lmnp_fin[i] != 0) {
      t.timeline_lmnp.push(0)
    } else {
      t.timeline_lmnp.push(1)
    }

    return t
  }

  static getArrayBaseImposable(projet) {
    let array = []
    let forme_juridique = projet.carac.formejuridique.find((f) => f.selected)
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(Math.round(this.calculBaseImposable(projet, i, forme_juridique)))
    })
    return array
  }

  static calculBaseImposable(projet, index_annee, forme_juridique) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    let calcul = 0
    let interets = 0
    let assurance = 0

    if (projet.carac.amortData[index_annee]) {
      interets = projet.results.resInvTravFonc.resItr[index_annee]
      assurance =
        projet.carac.amortData[index_annee].amortAssur *
        projet.results.timelineFonc.resTimelineEmpr[index_annee]
    }
    //fond propre pas d'emprunt
    if (projet.carac.modefinancement == 'fond_propre') {
      interets = 0
      assurance = 0
    }
    // Pas de calcul des frais dans ces régimes
    if (['lnmf', 'lmnpbic', 'sciirmf'].includes(forme_juridique.code) && !apresChangement) {
      calcul = 0
    } else {
      calcul +=
        projet.results.resOperationNetFonc.resRevLoc[index_annee] -
        -projet.results.resOperationNetFonc.resFraisExploit[index_annee]
      if (Math.abs(interets) < Math.abs(projet.results.resOperationNetFonc.resRevLoc[index_annee])) {
        calcul -= -interets
      } else {
        calcul -= projet.results.resOperationNetFonc.resRevLoc[index_annee]
      }
      calcul +=
        projet.results.resInvTravFonc.resFraisBanc[index_annee] +
        -assurance +
        -projet.results.resOperationNetFonc.resAutresFrais[index_annee] +
        projet.results.resFluxAIFonc.resAmorActif[index_annee]
    }
    return calcul
  }

  /**
   * Calcul la base imposable brute
   * @param {*} forme_juridique
   * @param {*} revenusLocatifs
   * @param {*} chargesLocativesDeductibles
   * @param {*} chargesFinanciereDeductibles
   * @param {*} autresFraisDeductibles
   * @param {*} amortissementActif
   * @returns
   */
  static calculBaseImposableBrute(
    forme_juridique,
    projet,
    revenusLocatifs,
    chargesLocativesDeductibles,
    autresFraisDeductibles,
    amortissementActif,
    index_annee
  ) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    let calcul = 0
    let interets = 0
    let assurance = 0
    if (projet.carac.amortData[index_annee]) {
      interets = projet.carac.amortData[index_annee].amortPartInt
      assurance = projet.carac.amortData[index_annee].amortAssur
    }
    // Pas de calcul des frais dans ces régimes
    if (['lnmf', 'lmnpbic', 'sciirmf'].includes(forme_juridique.code) && !apresChangement) {
      calcul = 0
    } else {
      calcul +=
        projet.results.resOperationNetFonc.resRevLoc[index_annee] +
        projet.results.resOperationNetFonc.resFraisExploit[index_annee]
      if (Math.abs(interets) < Math.abs(projet.results.resOperationNetFonc.resRevLoc[index_annee])) {
        calcul += -interets
      } else {
        calcul += -projet.results.resOperationNetFonc.resRevLoc[index_annee]
      }
      calcul +=
        projet.results.resInvTravFonc.resFraisBanc[index_annee] +
        -assurance +
        -autresFraisDeductibles +
        -projet.results.resFluxAIFonc.resAmorActif[index_annee]
    }
    return calcul
  }

  /**
   * Calcul la base imposable des régimes fiscaux location nu réel ou sci ir réel
   * @param {*} forme_juridique
   * @param {*} invest
   * @param {*} index_annee
   * @param {*} base_imposable_brute
   * @param {*} revenus_locatifs
   * @param {*} dataImpositionDetaille
   * @returns
   */
  static calculBaseImposableReel(
    projet,
    forme_juridique,
    invest,
    index_annee,
    base_imposable_brute,
    revenus_locatifs,
    dataImpositionDetaille
  ) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    let calcul = 0
    let fonciers = 0

    let doublement_deficit_foncier = 10700

    // anne acquisition + index_annee
    const yearAcqui = new Date(projet.carac.dateacqui).getFullYear()
    let year = Number(yearAcqui) + Number(index_annee)

    if (year < 2026 && projet.carac.doubleDFTravauxEnergie) {
      doublement_deficit_foncier = 21400
    }

    if (invest.fiscalite) {
      fonciers = +invest.fiscalite[index_annee].fonciers
    }
    if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
      if (index_annee == 0) {
        //si fonciers == 0

        if (fonciers == 0) {
          //si loyer entrant == 0

          if (revenus_locatifs == 0) {
            calcul = 0
          } else {
            if (
              base_imposable_brute > 0 ||
              (base_imposable_brute < 0 && base_imposable_brute > -doublement_deficit_foncier)
            ) {
              calcul = base_imposable_brute
            } else {
              calcul = -doublement_deficit_foncier
            }
          }
        }
      }
      if (index_annee > 0) {
        if (fonciers == 0) {
          if (revenus_locatifs == 0) {
            calcul = 0
          } else {
            //cagnotte globale - 1 == 0 && base imposable >= -doublement_deficit_foncier
            if (
              dataImpositionDetaille[index_annee - 1][6] == 0 &&
              base_imposable_brute >= -doublement_deficit_foncier
            ) {
              calcul = base_imposable_brute
            } else {
              // si base imposable  >= 0 && base imposable + cagnotte >= 0
              if (
                base_imposable_brute >= 0 &&
                base_imposable_brute + dataImpositionDetaille[index_annee - 1][6] >= 0
              ) {
                calcul = base_imposable_brute + dataImpositionDetaille[index_annee - 1][6]
              } else {
                //sinon si base imposable  >= 0 && base imposable + cagnotte < 0
                if (
                  base_imposable_brute >= 0 &&
                  base_imposable_brute + dataImpositionDetaille[index_annee - 1][6] < 0
                ) {
                  calcul = 0
                } else {
                  //sinon si base imposable < 0 && base imposable >= -doublement_deficit_foncier
                  if (base_imposable_brute < 0 && base_imposable_brute >= -doublement_deficit_foncier) {
                    calcul = base_imposable_brute
                  } else {
                    calcul = -doublement_deficit_foncier
                  }
                }
              }
            }
          }
        }
      }
    }

    const anneeChangement = this.estAnneeChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    const annee10ApresChangement = this.estAnneeChangementFiscalite(changementVersLMNP, index_annee, (engagementPlusTravaux +10))
    if (['lmnpr'].includes(forme_juridique.code) || apresChangement) {
      if (index_annee == 0 || anneeChangement) {
        calcul = base_imposable_brute
      } else if ((index_annee > 0 && index_annee <= 9)
      || (changementVersLMNP && index_annee <= 9 + engagementPlusTravaux)) {
        if (dataImpositionDetaille[index_annee - 1][6] < 0) {
          calcul = dataImpositionDetaille[index_annee - 1][6] + base_imposable_brute
        } else {
          calcul = base_imposable_brute
        }
      } else if (index_annee == 10 || annee10ApresChangement) {
        if (dataImpositionDetaille[0][5] < 0) {
          //si base imposable - 1 < première base imposable
          if (dataImpositionDetaille[index_annee - 1][6] < dataImpositionDetaille[0][6]) {
            //alors base imposable - 1 + -premiere imposable
            calcul =
              dataImpositionDetaille[index_annee - 1][6] + base_imposable_brute - dataImpositionDetaille[0][6]
          } else {
            //sinon si base imposable - 1 > première base imposable
            if (dataImpositionDetaille[index_annee - 1][6] > dataImpositionDetaille[0][6]) {
              //alors base imposable
              calcul = base_imposable_brute
            } else {
              //sinon base imposable - 1 + base imposable
              calcul = dataImpositionDetaille[index_annee - 1][6] + base_imposable_brute
            }
          }
        } else {
          if (dataImpositionDetaille[index_annee - 1][6] <= 0) {
            calcul = dataImpositionDetaille[index_annee - 1][6] + base_imposable_brute
          } else {
            calcul = base_imposable_brute
          }
        }
      } else {
        if (dataImpositionDetaille[index_annee - 10][5] < 0) {
          //si base imposable - 1 < première base imposable
          if (
            dataImpositionDetaille[index_annee - 1][6] <
            dataImpositionDetaille[index_annee - 10][6] - dataImpositionDetaille[index_annee - 11][6]
          ) {
            //alors base imposable - 1 + -premiere imposable
            calcul =
              dataImpositionDetaille[index_annee - 1][6] +
              base_imposable_brute -
              (dataImpositionDetaille[index_annee - 10][6] - dataImpositionDetaille[index_annee - 11][6])
          } else {
            //sinon si base imposable - 1 > première base imposable
            if (
              dataImpositionDetaille[index_annee - 1][6] >
              dataImpositionDetaille[index_annee - 10][6] - dataImpositionDetaille[index_annee - 11][6]
            ) {
              //alors base imposable
              calcul = base_imposable_brute
            } else {
              //sinon base imposable - 1 + base imposable
              calcul = dataImpositionDetaille[index_annee - 1][6] + base_imposable_brute
            }
          }
        } else {
          //sinon si base imposable - 1 <= 0
          if (dataImpositionDetaille[index_annee - 1][6] <= 0) {
            //base imposable - 1 + base imposable
            calcul = dataImpositionDetaille[index_annee - 1][6] + base_imposable_brute
          } else {
            // sinon base imposable
            calcul = base_imposable_brute
          }
        }
      }
    }
    return calcul
  }

  /**
   * Calcul la base imposable brutes autres revenus fonciers
   * @param {*} forme_juridique
   * @param {*} invest
   * @param {*} index_annee
   * @param {*} base_imposable_brute
   * @returns
   */
  static calculBaseImposableBruteAutresRF(
    projet,
    forme_juridique,
    invest,
    index_annee,
    base_imposable_brute,
    dataImpositionDetaille
  ) {
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    let calcul = 0
    let fonciers = 0

    let doublement_deficit_foncier = 10700

    // anne acquisition + index_annee
    const yearAcqui = new Date(projet.carac.dateacqui).getFullYear()
    let year = Number(yearAcqui) + Number(index_annee)

    if (year < 2026 && projet.carac.doubleDFTravauxEnergie) {
      doublement_deficit_foncier = 21400
    }

    if (invest.fiscalite) {
      fonciers = +invest.fiscalite[index_annee].fonciers
    }
    //calcul année 0
    if (fonciers != 0 && index_annee == 0) {
      if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
        //base imposable > 0 OU base imposable < 0 && base imposable + fonciers > -doublement_deficit_foncier
        if (
          base_imposable_brute > 0 ||
          (base_imposable_brute < 0 && base_imposable_brute + fonciers > -doublement_deficit_foncier)
        ) {
          calcul = base_imposable_brute
        } else {
          calcul = -doublement_deficit_foncier - fonciers
        }
      }
    }
    //calcul autres années
    if (fonciers != 0 && index_annee > 0) {
      if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
        //si la cagnotte globale - 1 = 0 et que la base imposable >= -doublement_deficit_foncier
        if (
          dataImpositionDetaille[index_annee - 1][6] == 0 &&
          base_imposable_brute >= -doublement_deficit_foncier
        ) {
          //alors base imposable
          calcul = base_imposable_brute
        } else {
          //sinon si la base imposable >= 0 et que base imposable + cagnotte globale >= 0
          if (
            base_imposable_brute >= 0 &&
            base_imposable_brute + dataImpositionDetaille[index_annee - 1][6] >= 0
          ) {
            //alors base imposable + cagnotte

            calcul = base_imposable_brute + dataImpositionDetaille[index_annee - 1][6]
          } else {
            //sinon si la base imposable >= 0 ET base imposable + cagnotte < 0
            if (
              base_imposable_brute >= 0 &&
              base_imposable_brute + dataImpositionDetaille[index_annee - 1][6] < 0
            ) {
              calcul = 0
            } else {
              //sinon si base imposable < 0 ET base imposable + revenus fonciers <= -doublement_deficit_foncier
              if (
                base_imposable_brute < 0 &&
                base_imposable_brute + dataImpositionDetaille[index_annee - 1][6] <=
                  -doublement_deficit_foncier
              ) {
                // alors -doublement_deficit_foncier - revenus fonciers

                calcul = -doublement_deficit_foncier - fonciers
              } else {
                calcul = base_imposable_brute
              }
            }
          }
        }
      }
    }
    return calcul
  }

  /**
   * Calcul la base imposable nette d'une année
   * @param {*} forme_juridique
   * @param {*} baseImposableBrute
   * @param {*} investisseur
   * @param {*} partInteret
   * @param {*} revenusLocatifs
   * @param {*} index_annee
   * @param {*} dataImpositionDetaille
   * @returns
   */
  static calculBaseImposableNette(
    projet,
    forme_juridique,
    baseImposableBrute,
    investisseur,
    partInteret,
    revenusLocatifs,
    index_annee,
    dataImpositionDetaille
  ) {
    let calcul = 0
    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    const apresChangement = this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux)
    if (['lmnpbic'].includes(forme_juridique.code)) {
      calcul = revenusLocatifs * 0.5
    }
    if (['lmnpr'].includes(forme_juridique.code) || apresChangement ) {
      calcul = this.calculBaseImposableReel(
        projet,
        forme_juridique,
        investisseur,
        index_annee,
        baseImposableBrute,
        revenusLocatifs,
        dataImpositionDetaille
      )
    }
    if (['lnmf', 'sciirmf'].includes(forme_juridique.code) && !apresChangement) {
      calcul = revenusLocatifs * 0.7
    }
    if (['lnr', 'sciirr'].includes(forme_juridique.code) && !apresChangement) {
      calcul =
        this.calculBaseImposableBruteAutresRF(
          projet,
          forme_juridique,
          investisseur,
          index_annee,
          baseImposableBrute,
          dataImpositionDetaille
        ) +
        this.calculBaseImposableReel(
          projet,
          forme_juridique,
          investisseur,
          index_annee,
          baseImposableBrute,
          revenusLocatifs,
          dataImpositionDetaille
        )
    }
    if (['sciis', 'sasis', 'sasuis'].includes(forme_juridique.code)) {
      if (index_annee == 0) {
        calcul = baseImposableBrute
      } else {
        calcul = baseImposableBrute + dataImpositionDetaille[index_annee - 1][7]
      }
    }
    return calcul
  }

  /**
   * Calcul la valeur d'amortissement du projet
   * @param {*} projet Objet projet
   * @returns
   */
  static calculValeurBrutAmortissement(projet) {
    let calcul = 0
    calcul = projet.carac.buyprice + projet.carac.travauxConstru.reduce((a, b) => a + b, 0)
    return calcul
  }

  static getArrayFraisStructureEntreprise(projet) {
    let array = []
    let forme_juridique = projet.carac.formejuridique.find((f) => f.selected)
    projet.results.timelineFonc.period.forEach((data, i) => {
      array.push(Math.round(this.calculFraisStructureEntreprise(projet, i, forme_juridique)))
    })
    return array
  }

  /**
   * Frais de structure entreprise (SCI, LMNP, LMP, SAS, SASU)
   * @param {*} projet Le projet (Object)
   * @param {*} index_annee L'index de l'année
   */
  static calculFraisStructureEntreprise(projet, index_annee, forme_juridique) {
    let calcul = 0

    const changementVersLMNP = projet.carac.changementDeFiscalite
    const engagementPlusTravaux = projet.carac.engagementPlusTravaux
    //Première année on elimine les frais de société
    if (
      index_annee == 0 &&
      ['sciis', 'sasis', 'sasuis', 'sciirr', 'sciirmf'].includes(forme_juridique.code)
    ) {
      calcul -= projet.carac.fsociete
    }
    //CFE + Frais comptabilité CGA
    if (['lmnpr', 'sciis', 'sasis', 'sasuis'].includes(forme_juridique.code) ||
      (this.estApresChangementFiscalite(changementVersLMNP, index_annee, engagementPlusTravaux))) {
      calcul -= projet.results.timelineFonc.resTmpLoc[index_annee] * +projet.carac.fcomptable.val
      calcul -= projet.results.timelineFonc.resTmpLoc[index_annee] * +projet.carac.fcotisation.val
    }
    //CRL
    // if(['sciis', 'sasis', 'sasuis'].includes(forme_juridique.code) && projet.carac.fcrl.val != 0){
    //   calcul -= projet.results.timelineFonc.resTmpLoc[index_annee] * -((parseFloat(projet.carac.fcrl.val) / 100) * projet.carac.revenulocatif);
    // }

    return calcul
  }

  /**
   * Ressort les calculs du tableau d'imposition pour une année
   * @param {*} projet Le projet en question
   * @param {*} index_annee Index de l'année (0 , 1 ...)
   * @param {*} dataImpositionDetaille Le tableau complet de l'imposition détaillée
   * @param {*} investisseur L'objet de l'investisseur
   * @param {*} tmis Les tmis de la BDD
   */
  static calculTableauImposition(projet, index_annee, dataImpositionDetaille, investisseur, tmis, globals) {
    try {
      let valeur_bien_taxable = 0
      let forme_juridique = projet.carac.formejuridique.filter((d) => d.selected)[0]
      let revenusLocatifs = projet.results.resOperationNetFonc.resRevLoc[index_annee]

      //Calcul frais de structure d'entreprise
      let fraisStructureEntreprise = this.calculFraisStructureEntreprise(projet, index_annee, forme_juridique)

      let CapFinPeriode = 0
      let assurance = 0
      let partInteret = 0
      if (typeof projet.carac.amortData != 'undefined') {
        CapFinPeriode = projet.carac.amortData[index_annee]?.amortCapRemb
          ? projet.carac.amortData[index_annee].amortCapRemb
          : 0
        assurance = projet.carac.amortData[index_annee]?.amortAssur
          ? projet.carac.amortData[index_annee].amortAssur
          : 0
        partInteret = projet.carac.amortData[index_annee]?.amortPartInt
          ? projet.carac.amortData[index_annee].amortPartInt
          : 0
      }
      let fraisNotaire = projet.results.resCoutTotalFonc.resFraisNotaire[index_annee]
      let fraisAgenceImmo = projet.results.resCoutTotalFonc.resFraisAgenceImmo[index_annee]
      let amortissementActif = Math.abs(projet.results.resFluxAIFonc.resAmorActif[index_annee])

      let chargesLocativesDeductibles = this.calculChargesLocatives(projet, forme_juridique, index_annee)
      let chargesFinanciereDeductibles = this.calculChargesFinancieres(projet, forme_juridique, index_annee)
      let cagnotteReportable = 0
      let autresFraisDeductibles = this.calculAutresFraisDeductibles(
        projet,
        fraisStructureEntreprise,
        fraisNotaire,
        fraisAgenceImmo,
        forme_juridique,
        index_annee
      )
      let baseImposableBrute = this.calculBaseImposableBrute(
        forme_juridique,
        projet,
        revenusLocatifs,
        chargesLocativesDeductibles,
        autresFraisDeductibles,
        amortissementActif,
        index_annee
      )
      // if (revenusLocatifs > partInteret) {
      //   baseImposableBrute -= partInteret
      // } else {
      //   baseImposableBrute -= revenusLocatifs
      // }
      let baseImposableNette = 0
      let results_deficit = this.calculDeficitFoncierReel(
        projet,
        forme_juridique,
        baseImposableBrute,
        investisseur,
        partInteret,
        revenusLocatifs,
        index_annee,
        dataImpositionDetaille
      )
      // baseImposableBrute = results_deficit.base_imposable
      cagnotteReportable = results_deficit.cagnotte
      baseImposableNette = this.calculBaseImposableNette(
        projet,
        forme_juridique,
        baseImposableBrute,
        investisseur,
        partInteret,
        revenusLocatifs,
        index_annee,
        dataImpositionDetaille
      )
      cagnotteReportable = this.calculCagnotteReportable(
        forme_juridique,
        index_annee,
        baseImposableBrute,
        investisseur,
        dataImpositionDetaille,
        revenusLocatifs,
        baseImposableNette,
        projet
      )
      let results_impots_is = this.calculImpotSasSciisSasu(
        0,
        forme_juridique,
        projet,
        index_annee,
        projet.results.resFluxAIFonc.resBaseImposableNette[index_annee],
        globals
      )
      let dividende_nette = Math.abs(
        this.dividendesNetsImputeeAbattement(
          projet,
          forme_juridique,
          baseImposableNette,
          results_impots_is,
          index_annee
        )
      )

      let results_imposition = this.calculImposition(
        investisseur,
        index_annee,
        tmis,
        dividende_nette,
        projet,
        forme_juridique,
        baseImposableNette
      )
      let prelevement_sociaux = results_imposition.prelevement_sociaux
      let tmi = results_imposition.tmi
      let plus_value = results_imposition.plus_value_immobiliere
      let imposition_revenu_bien_locatif = results_imposition.imposition_revenu_bien_locatif
      valeur_bien_taxable = Math.abs(this.calculValeurBienTaxable(projet, forme_juridique, index_annee))
      let results_impots_cession = 0
      if (valeur_bien_taxable > 0) {
        results_impots_cession = this.calculImpotSasSciisSasu(
          valeur_bien_taxable,
          forme_juridique,
          projet,
          index_annee,
          projet.results.resFluxAIFonc.resBaseImposableNette[index_annee],
          globals
        )
      }
      let results_dividendes = this.impotDividendeAssocie(
        forme_juridique,
        projet,
        dividende_nette,
        results_imposition.imposition_revenu_genere,
        index_annee,
        baseImposableNette,
        results_impots_is,
        valeur_bien_taxable,
        results_impots_cession
      )
      let results_prevelement_sociaux_dividende = this.prelevementSociauxDividendes(
        this.dividendesBrutAnneeEnCours(
          projet,
          forme_juridique,
          baseImposableNette,
          results_impots_is,
          index_annee
        ),
        0.172
      )
      if (results_prevelement_sociaux_dividende > 0) {
        results_prevelement_sociaux_dividende = -results_prevelement_sociaux_dividende
      }

      // PS sur bénéfice

      let csg_recuperable = 0
      //Calcul de la CSG par rapport à l'année précédente
      if (index_annee > 0) {
        csg_recuperable = Math.round(dataImpositionDetaille[index_annee - 1][10] * 0.068)
      }
      //Si c'est la date de revente on enlève les impots et prelevement sociaux
      imposition_revenu_bien_locatif = Math.round(
        imposition_revenu_bien_locatif + prelevement_sociaux - csg_recuperable
      )

      let imposition_globale_projet = Math.ceil(
        imposition_revenu_bien_locatif +
          results_prevelement_sociaux_dividende +
          results_impots_is +
          results_dividendes.reduce((a, b) => a + b, 0) +
          results_impots_cession +
          -plus_value
      )
      let dataReturn = {
        tableau_imposition: null,
        data_imposition: {
          imposition_revenu_bien_locatif,
          results_prevelement_sociaux_dividende,
          results_impots_is,
          results_dividendes: results_dividendes.reduce((a, b) => a + b, 0),
          results_impots_cession,
          prelevement_sociaux,
          csg_recuperable,
          plus_value,
        },
      }
      if (projet.results.timelineFonc.resDateRevente[index_annee]) {
        dataReturn.tableau_imposition = [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          tmi,
          imposition_globale_projet,
          prelevement_sociaux,
        ]
      } else {
        dataReturn.tableau_imposition = [
          revenusLocatifs,
          chargesLocativesDeductibles,
          chargesFinanciereDeductibles,
          autresFraisDeductibles,
          amortissementActif,
          baseImposableBrute,
          cagnotteReportable,
          baseImposableNette,
          tmi,
          imposition_globale_projet,
          prelevement_sociaux,
        ]
      }
      return dataReturn
    } catch (error) {
      console.log(error)
    }
  }
}
