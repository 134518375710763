<template>
	<div>
		<Header/>
		<div class="grid grid-cols-11 gap-1 bg-darkblueaestima-500 relative flex flex-row justify-center items-start">
			<div class="relative text-center col-span-11 sm:col-start-2 sm:col-end-11 z-10">
				<h1 class="uppercase s1-title font-bold text-white text-4xl md:text-6xl mb-4 aestima-title-vw leading-normal">MENTIONS LEGALES</h1>
			</div>
		</div>

		<div class="mx-28 text-justify mb-20">
			
			<h2>Identification de la personne physique :</h2>

			<p>Société EVENY</p>
			<p>SAS au capital de 500€</p>
			<p>13 Rue Haxo, 13001 MARSEILLE</p>
			<p><a href="mailto:contact@aestima-immo.com">contact@aestima-immo.com</a></p>
			<p>Directeur de la publication et responsable de la rédaction : Julien Videau</p>
			
			<h2>Activité de l’entreprise Aestima :</h2>

			<p>N° SIREN : 918 402 322 R.C.S MARSEILLE</p>
			<p>Voir les <router-link to="/CGV"> conditions générales de vente </router-link> (CGV).</p>
			<p>Hébergeur du site : OVH Cloud, Rue Kellermann - 59100 Roubaix – France, n° de téléphone 1007</p>
		
			<h2>Mentions relatives à l'utilisation de données personnelles :</h2>
			<p>Délégué à la protection des données (DPO ou DPD) : Julien Videau</p>
			<p>Questions sur la protection de vos données personnelles : <a href="mailto:contact@aestima-immo.com">contact@aestima-immo.com</a></p>
			<p><u>Finalité poursuivie par le traitement auquel les données sont destinées :</u> relations commerciales, facturation mensuelle des clients, assistance et informations pour les utilisateurs.</p>
			<p><u>Destinataires ou catégories de destinataires des données :</u> Service après ventes, service commerciale, service maintenance, service facturation.</p>
			<p>L’utilisateur a le droit d'opposition, d'interrogation, d'accès, de rectification et d’introduire une réclamation (plainte) auprès de la Cnil.</p>
			<p>Traitement de données permis par le consentement des personnes concernées et l'exécution du contrat d’abonnement.</p>
		</div>

	<Footer/>
</div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
import { mapGetters, mapActions } from "vuex"

export default {
	name: 'CGV',
	data() {
		return {
			firstname: '',
			lastname: '',
			phone: '',
			email: '',
			work: 'testeur',
			message: '',
			worklist: [
				{
					text: 'testeur'
				},
				{
					text: 'doudou'
				},
			],
			isSend: false,
		}
	},
	components: {
		Footer,
		Header
	},
	metaInfo(){
		return {
			title: "Conditions générales de vente - Aestima",
			meta: [{
				name: 'description',
				content: "Cette page décrit les conditions générales de vente de l'estimateur immobilier Aestima."
			}]
			//keywords
		}
	},
	computed: {
		...mapGetters({
		}),
	},
	methods: {
		...mapActions({
			contact: 'users/contact',
		}),
		setSelectedWork(event) {
			this.work = event.target.value
		},
		async submit(){
			await this.contact({
				firstname: this.firstname,
				lastname: this.lastname,
				phone: this.phone,
				email: this.email,
				work: this.work,
				message: this.message
			})
			.then(() => {
				this.isSend = true
				setTimeout(() => {
					this.isSend = false
				}, 8000);
			})
		}
	},
}
</script>

<style scoped>
h2 {
	font-size: 1.4rem;
	font-weight: 900;
	margin-bottom: 1rem;
	margin-top: 3rem;
}

p {
	font-size: 1.2rem;
	font-weight: 500;
	margin-bottom: 1rem;
}

ul {
	margin-bottom: 1rem;
}

li {
	font-size: 1.2rem;
	font-weight: 500;
}
/* Medium (md) */
@media (min-width: 768px) {
	.h-header {
		height: 50rem;
	}

	.aestima-title-vw {
		font-size: 2.8vw !important;
	}

	.aestima-par-vw {
		font-size: 1.2vw !important;
	}
}

u {
	text-decoration: underline;
}

.shadow-inner {
	box-shadow: 0 15px 35px 0 rgba(60,66,87,.08), 0 5px 15px 0 rgba(0,0,0,.12);
}
</style>

