<template>
  <div>
    <Header/>
    <div class="bg-darkblueaestima-500">
      <div class="relative items-start justify-center xl:container xl:mx-auto grid grid-cols-11 gap-1">
        <div class="relative z-10 text-center col-span-11 col-start-2 col-end-11">
          <h1 class="mb-6 text-4xl font-bold leading-normal text-white s1-title md:text-6xl aestima-title-vw">Tarifs</h1>
        </div>
      </div>
    </div>

    <div class="mx-4 sm:mx-1/12">
      <div class="flex flex-col">


        <!-- HEADER TABLEAU -->
        <div class="relative items-start justify-center hidden mt-6 sm:grid grid-cols-11">
          <div class="mt-auto col-start-1 col-end-6">
            <p class="text-xl font-bold md:text-normal">
              Durée <br />
              d'engagement
            </p>
          </div>
          <div class="col-start-6 col-end-9">
            <p class="text-xl font-normal subscribe-title md:text-normal">Initié</p>
            <p class="mx-auto font-normal text-center subscribe-desc">Parfait pour développer vos conseils en investissement.</p>
            <div class="flex flex-col items-center justify-center">
              <p v-if="!month" class="text-xl font-bold text-darkblueaestima-500">29 €<span class="text-xs font-normal text-darkblueaestima-500">  HT</span></p>
              <p v-else class="text-xl font-bold text-darkblueaestima-500">39 €<span class="text-xs font-normal text-darkblueaestima-500">  HT</span></p>
              <p class="text-xs font-normal text-darkblueaestima-500">par mois</p>
            </div>
          </div>
          <div class="col-start-9 col-end-12">
            <p class="text-xl font-normal subscribe-title md:text-normal">Professionnel</p>
            <p class="mx-auto font-normal text-center subscribe-desc">Optimal pour conseiller chacun de vos clients sur leurs projets.</p>
            <div class="flex flex-col items-center justify-center">
              <p v-if="!month" class="text-xl font-bold text-darkblueaestima-500">44 €<span class="text-xs font-normal text-darkblueaestima-500">  HT</span></p>
              <p v-else class="text-xl font-bold text-darkblueaestima-500">59 €<span class="text-xs font-normal text-darkblueaestima-500">  HT</span></p>
              <p class="text-xs font-normal text-darkblueaestima-500">par mois</p>
            </div>
          </div>
        </div>
        <div class="relative items-start justify-center hidden mt-4 mb-6 sm:grid grid-cols-11 gap-2">
          <div class="col-start-1 col-end-6">
            <label class="switch">
              <input type="checkbox" @click="month = !month" />
              <span v-if="month" class="flex items-center justify-center font-semibold text-white uppercase slider">Mensuel</span>
              <span v-else class="flex items-center justify-center font-semibold text-white uppercase slider">Annuel</span>
            </label>
          </div>
          <div class="col-start-6 col-end-9">
            <div class="flex flex-col">
                <button class="px-3 py-2 text-xs font-normal text-white normal-case rounded-lg bg-aestiblue-light aestima-button" @click="selectPriceAndRoute('ini', false)">
                  <p>Souscrire maintenant</p>
                </button>
                <button class="mt-2 text-xs font-normal normal-case text-darkblueaestima-500 subscribe-essaie" @click="selectPriceAndRoute('ini', true)">
                  <p>30 jours gratuits sans engagement</p>
                </button>
            </div>
          </div>
          <div class="col-start-9 col-end-12">
            <div class="flex flex-col">
                <button class="px-3 py-2 text-xs font-normal text-white normal-case rounded-lg bg-aestiblue-light aestima-button" @click="selectPriceAndRoute('pro', false)">
                  <p>Souscrire maintenant</p>
                </button>
                <button class="mt-2 text-xs font-normal normal-case text-darkblueaestima-500 subscribe-essaie" @click="selectPriceAndRoute('pro', true)">
                  <p>30 jours gratuits sans engagement</p>
                </button>
            </div>
          </div>
        </div>
        <!-- FIN HEADER -->

        <!-- TARIFS EN FLEX -->
        <div id="tarifs" class="flex flex-col -mr-2 sm:hidden">
          <div id="les-dits-tarifs" class="flex flex-row my-8 justify-evenly">
            <div id="newSwitch" class="self-end w-1/4 -mr-4">
              <div class="mb-2 -ml-4 text-sm font-bold">Engagement</div>
              <div class="toggle-switch-container">
                <div class="toggle-switch switch-vertical">
                                <!-- <input type="checkbox" @click="month = !month" /> -->
                  <input id="toggle-a" type="radio" v-model="month" :value="true" checked="checked" />
                  <label for="toggle-a">Mensuel</label>
                  <input id="toggle-b" type="radio" v-model="month" :value="false" />
                  <label for="toggle-b">Annuel</label>
                  <span class="toggle-outside">
                    <span class="toggle-inside"></span>
                  </span>
                </div>
              </div>
            </div>
            <div id="Initié" class="flex flex-col justify-end w-1/4">
              <div id="nom" class="text-sm font-bold">Initié</div>
              <div v-if="!month" id="prix" class="text-sm font-medium">29 €<span class="text-xs font-normal"> par mois</span></div>
              <div v-else id="prix" class="text-sm font-medium">39 €<span class="text-xs font-normal"> par mois</span></div>
              <div id="bouton">
                  <button class="px-3 py-2 mt-2 text-xs font-normal text-white normal-case rounded-lg bg-aestiblue-light aestima-button" @click="selectPriceAndRoute('ini', false)">
                    <p>Essayer</p>
                  </button>
              </div>
            </div>
            <div id="Professionnel" class="flex flex-col justify-end w-1/4">
              <div id="nom" class="text-sm font-bold">Pro</div>
              <div v-if="!month" id="prix" class="text-sm font-medium">44 €<span class="text-xs font-normal">par mois</span></div>
              <div v-else id="prix" class="text-sm font-medium">59 €<span class="text-xs font-normal"> par mois</span></div>
              <div id="bouton">
                  <button class="px-3 py-2 mt-2 text-xs font-normal text-white normal-case rounded-lg bg-aestiblue-light aestima-button" @click="selectPriceAndRoute('pro', false)">
                    <p>Essayer</p>
                  </button>
              </div>
            </div>
          </div>
        </div>
        <!-- FIN TARIFS EN FLEX-->

        <!-- BODY TABLEAU -->
        <div class="relative grid grid-cols-11">
          <!-- CRM -->
          <div class="subscribe-table col-span-12">
            <div class="subscribe-table-header">
              <h2 class="py-1 ml-3 text-left">Le CRM</h2>
            </div>
            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Enregistrer des clients investisseurs</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">avec leurs critères et objectifs</p>
                  </div>
                  <QuestionMark class="hidden mt-1 mb-auto ml-2 sm:block" :text="` Coordonnées, calcul de leur solvabilité, stratégie, tolérance aux risques et critères de recherches.`" />
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <p class="text-sm subscribe-item sm:text-xl">4</p>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <p class="text-sm subscribe-item sm:text-xl">Illimité</p>
                </div>
              </div>
            </div>
            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Utilisation de la solution en équipe</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">avec personnalisation des membres</p>
                  </div>
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <No/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                  <p class="text-sm sm:text-xl-desc">Tarifs sur mesure</p>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN CRM -->

          <!-- SIMULATIONS ET ANALYSE -->
          <div class="subscribe-table col-span-12">
            <div class="subscribe-table-header">
              <h2 class="py-1 ml-3 text-left">Les simulations et leur analyse</h2>
            </div>
            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Enregistrer des simulations,</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">les consulter et les modifier</p>
                  </div>
                  <QuestionMark class="hidden ml-4 sm:block" :text="`En fonction de l’abonnement choisi, vous pourrez en sauvegarder un certain nombre pour chaque client investisseur créé sur l’interface.`"/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-6 col-end-9">
                  <p class="text-sm subscribe-item sm:text-xl">3</p>
                  <p class="text-sm sm:text-xl-desc">par profil</p>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <p class="text-sm subscribe-item sm:text-xl">Illimité</p>
                  <p class="text-sm sm:text-xl-desc">par profil</p>
                </div>
              </div>
            </div>
            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Analyse simplifiée des performances</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">financières et du marché locatif</p>
                  </div>
                  <QuestionMark class="hidden ml-4 sm:block" :text="`Accès aux flux de trésorerie moyens (cash-flows), rentabilité nette, effort d’épargne, fiscalité des projets. Pour l’analyse de la ville, démographie, liquidité du marché, loyers et prix au m2.`"/>
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <Yes/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                </div>
              </div>
            </div>

            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Analyse avancée des performances</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">financières et du marché locatif</p>
                  </div>
                  <QuestionMark class="hidden ml-4 sm:block" :text="`Accès aux ratios financiers TIR, VAN, effet de levier du crédit, tableau de trésorerie complet par année, coût total de l’opération. Pour l’analyse de la ville, démographie, liquidité du marché, loyers et prix au m2.`"/>
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <Yes/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                </div>
              </div>
            </div>
	    
            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Comparer les simulations,</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">leurs performances, leur marché</p>
                  </div>
                  <QuestionMark class="hidden ml-4 sm:block" :text="`Comparez simultanément jusqu’à 4 simulations. Potentiel locatif de la ville, performances financières, fiscalité, effort d’épargne. Aidez votre client à gagner du temps dans le choix de son futur projet locatif.`"/>
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <Yes/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                </div>
              </div>
            </div>
	   
            <!-- <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Optimiser les performances</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">financières de chaque simulation</p>
                  </div>
                  <QuestionMark class="hidden ml-4 sm:block" :text="`Accédez à la tour de contrôle de vos simulations pour ajuster chacun de ses paramètres et améliorer leur rentabilité et leur cash-flow en quelques mouvements de souris (durée d’emprunt optimale, durée de détention optimale, loyer optimale, etc.).`"/>
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-8">
                  <Yes/>
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <Yes/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                </div>
              </div>
            </div> -->
          </div>
          <!-- FIN SIMULATIONS ET ANALYSE -->

          <!-- La fiscalité -->
          <div class="subscribe-table col-span-12">
            <div class="subscribe-table-header">
              <h2 class="py-1 ml-3 text-left">La fiscalité</h2>
            </div>
            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Fiscalité évolutive du client et du projet</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">en fonction du patrimoine existant</p>
                  </div>
                  <QuestionMark class="hidden ml-4 sm:block" :text="`Selon votre mode opératoire, vous pouvez soit affecter une tranche marginale d'imposition fixe sur toute la durée du projet de votre client, soit prendre en compte sa fiscalité et son patrimoine existant pour calculer l'imposition de son projet (possible uniquement avec l'abonnement initié et professionnel).`"/>
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <Yes/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                </div>
              </div>
            </div>
            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Simulations en nom propre</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">dans l’ancien et le neuf (en nu et LMNP)</p>
                  </div>
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <Yes/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                </div>
              </div>
            </div>

            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Simulations en société</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">dans l’ancien et le neuf (SCI IR/IS, SAS, SASU)</p>
                  </div>
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <Yes/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                </div>
              </div>
            </div>

            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Simulations avec dispositifs fiscaux</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">Pinel (neuf et ancien), Denormandie, et Malraux</p>
                  </div>
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <Yes/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                </div>
              </div>
            </div>

            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Changement de régime fiscal</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">durant la détention du bien locatif</p>
                  </div>
                  <QuestionMark
                    class="hidden ml-4 sm:block"
                    :text="`Pour une même simulation, opérez un changement de fiscalité au cours du projet pour optimiser ses performances. Passez par exemple d'un dispositif PINEL à une location meublée au terme de l'engagement fiscal.`"
                  />
                </div>
                <div class="flex items-center justify-center border-t col-start-6 col-end-9">
                  <No/>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN La fiscalité -->

          <!-- PRESENTATION -->
          <div class="subscribe-table col-span-12">
            <div class="subscribe-table-header">
              <h2 class="py-1 ml-3 text-left">
                Présentation de votre travail<!-- et interacti  width: 100%;
n avec vos équipes -->
              </h2>
            </div>
            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <div class="flex flex-row">
                      <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Personnalisation de vos dossiers</h3>
                      <QuestionMark
                    class="hidden ml-4 sm:block"
                    :text="`Pour chacun de vos clients et chacune de vos simulations, vous avez la possibilité de télécharger au format PDF une synthèse financière de votre travail reprenant : la situation de votre client et ses objectifs, la description du projet locatif, l’étude de son marché locatif, ses performances financières et, bien sûr, vos annotations et vos recommandations d’expert.`"
                  />
                    </div>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">sur le bien, les détails financiers et commentaires</p>
                  </div>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-6 col-end-9">
                  <Yes/>
		  <p class="mt-2 text-xs sm:text-sm leading-4 sm:text-xl-italic">avec votre logo</br>et nom de marque</p>
                </div>
                <div class="flex flex-col items-center justify-center border-t col-start-9 col-end-12">
                  <Yes/>
                  <p class="mt-2 text-xs sm:text-sm leading-4 sm:text-xl-italic">avec votre logo</br>et nom de marque</p>
                </div>
              </div>
            </div>
            <div class="subscribe-table-body">
              <div class="relative grid grid-cols-11">
                <div class="flex items-center justify-start px-3 py-4 text-left border-t col-start-1 col-end-6 wrapper">
                  <div>
                    <h3 class="text-sm font-bold leading-5 sm:subscribe-table-body-title">Export de vos dossiers</h3>
                    <p class="text-sm leading-5 sm:subscribe-table-body-desc">de présentation au format PDF</p>
                  </div>
                </div>
                <div class="flex items-center justify-center align-middle border-t col-start-6 col-end-9">
                  <div class="flex flex-col">
                  <p class="mr-2 text-sm subscribe-item sm:text-xl">Illimité</p>
                  </div>
                </div>
                <div class="flex items-center justify-center align-middle border-t col-start-9 col-end-12">
                  <p class="mr-2 text-sm subscribe-item sm:text-xl">Illimité</p>
                  <!-- <p class="text-xs font-normal subscribe-item sm:text-xl-desc">par mois</p> -->
                </div>
              </div>
            </div>
          </div>
          <!-- FIN PRESENTATION -->
        </div>

        <!-- ANCIENNE VERSION DE JEAN-LOUIS -->
        <!-- <div class="relative items-start justify-center hidden mt-6 sm:grid grid-cols-11 gap-2">
          <div class="col-start-1 col-end-6"></div>
          <div class="col-start-6 col-end-8">
            <p class="font-medium sm:text-xl text-normal">Découverte</p>
            <p class="text-3xl font-bold md:text-normal text-darkblueaestima-500">Gratuit</p>
          </div>
          <div class="col-start-6 col-end-9">
            <p class="font-medium sm:text-xl text-normal">Initié</p>
            <div class="flex flex-col items-center justify-center">
              <p v-if="!month" class="text-2xl font-bold uppercase text-darkblueaestima-500">19 €</p>
              <p v-else class="text-2xl font-bold uppercase text-darkblueaestima-500">29 €</p>
              <p class="text-xs font-medium text-darkblueaestima-500">par mois</p>
            </div>
          </div>
          <div class="col-start-9 col-end-12">
            <p class="font-medium sm:text-xl text-normal">Professionnel</p>
            <div class="flex flex-col items-center justify-center">
              <p v-if="!month" class="text-2xl font-bold uppercase text-darkblueaestima-500">29 €</p>
              <p v-else class="text-2xl font-bold uppercase text-darkblueaestima-500">49 €</p>
              <p class="text-xs font-medium text-darkblueaestima-500">par mois</p>
            </div>
          </div>
        </div>

        <div class="relative items-start justify-center hidden mt-4 mb-6 sm:grid grid-cols-11 gap-2">
          <div class="col-start-6 col-end-8">
            <router-link to="/register" @click.native="selectedStripePrice({ id: 0, isTrial: false })"
              ><button class="px-3 py-2 font-semibold text-white normal-case rounded-lg text-md bg-aestiblue-light aestima-button">
                <p>Souscrire maintenant</p>
              </button></router-link
            >
          </div>
          <div class="col-start-6 col-end-9">
            <router-link to="/register" @click.native="setSelectedStripePrice('ini', false)">
              <button class="px-3 py-2 font-semibold text-white normal-case rounded-lg text-md bg-aestiblue-light aestima-button">
                <p>Souscrire maintenant</p>
              </button>
            </router-link>
            <router-link to="/acces-simulateur" @click.native="setSelectedStripePrice('ini', true)">
              <button class="mt-2 text-sm font-normal normal-case text-darkblueaestima-500 subscribe-essaie">
                <p>30 jours gratuits sans engagement</p>
              </button>
            </router-link>
          </div>
          <div class="col-start-9 col-end-12">
            <router-link to="/register" @click.native="setSelectedStripePrice('pro', false)">
              <button class="px-3 py-2 font-semibold text-white normal-case rounded-lg text-md bg-aestiblue-light aestima-button">
                <p>Souscrire maintenant</p>
              </button>
            </router-link>
            <router-link to="/register" @click.native="setSelectedStripePrice('pro', true)">
              <button class="mt-2 text-sm font-normal normal-case text-darkblueaestima-500 subscribe-essaie">
                <p>30 jours gratuits sans engagement</p>
              </button>
            </router-link>
          </div>
        </div> -->


        <!-- HEADER TABLEAU -->
        <div class="relative items-start justify-center hidden mt-6 sm:grid grid-cols-11">
          <div class="mt-auto col-start-1 col-end-6">
            <p class="text-xl font-bold md:text-normal">
              Durée <br />
              d'engagement
            </p>
          </div>
          <div class="col-start-6 col-end-9">
            <p class="text-xl font-normal subscribe-title md:text-normal">Initié</p>
            <p class="mx-auto font-normal text-center subscribe-desc">Parfait pour développer vos conseils en investissement.</p>
            <div class="flex flex-col items-center justify-center">
              <p v-if="!month" class="text-xl font-bold text-darkblueaestima-500">29 €<span class="text-xs font-normal text-darkblueaestima-500">  HT</span></p>
              <p v-else class="text-xl font-bold text-darkblueaestima-500">39 €<span class="text-xs font-normal text-darkblueaestima-500">  HT</span></p>
              <p class="text-xs font-normal text-darkblueaestima-500">par mois</p>
            </div>
          </div>
          <div class="col-start-9 col-end-12">
            <p class="text-xl font-normal subscribe-title md:text-normal">Professionnel</p>
            <p class="mx-auto font-normal text-center subscribe-desc">Optimal pour conseiller chacun de vos clients sur leurs projets.</p>
            <div class="flex flex-col items-center justify-center">
              <p v-if="!month" class="text-xl font-bold text-darkblueaestima-500">44 €<span class="text-xs font-normal text-darkblueaestima-500">  HT</span></p>
              <p v-else class="text-xl font-bold text-darkblueaestima-500">59 €<span class="text-xs font-normal text-darkblueaestima-500">  HT</span></p>
              <p class="text-xs font-normal text-darkblueaestima-500">par mois</p>
            </div>
          </div>
        </div>
        <div class="relative items-start justify-center hidden mt-4 mb-6 sm:grid grid-cols-11 gap-2">
          <div class="col-start-1 col-end-6">
            <label class="switch">
              <input type="checkbox" @click="month = !month" />
              <span v-if="month" class="flex items-center justify-center font-semibold text-white uppercase slider">Mensuel</span>
              <span v-else class="flex items-center justify-center font-semibold text-white uppercase slider">Annuel</span>
            </label>
          </div>
          <div class="col-start-6 col-end-9">
            <div class="flex flex-col">
                <button class="px-3 py-2 text-xs font-normal text-white normal-case rounded-lg bg-aestiblue-light aestima-button" @click="selectPriceAndRoute('ini', false)">
                  <p>Souscrire maintenant</p>
                </button>
                <button class="mt-2 text-xs font-normal normal-case text-darkblueaestima-500 subscribe-essaie" @click="selectPriceAndRoute('ini', true)">
                  <p>30 jours gratuits sans engagement</p>
                </button>
            </div>
              </div>
              <div class="col-start-9 col-end-12">
                <div class="flex flex-col">
                <button class="px-3 py-2 text-xs font-normal text-white normal-case rounded-lg bg-aestiblue-light aestima-button" @click="selectPriceAndRoute('pro', false)">
                  <p>Souscrire maintenant</p>
                </button>
                <button class="mt-2 text-xs font-normal normal-case text-darkblueaestima-500 subscribe-essaie" @click="selectPriceAndRoute('pro', true)">
                  <p>30 jours gratuits sans engagement</p>
                </button>
            </div>
          </div>
        </div>
        <!-- FIN HEADER -->

        <!-- TARIFS EN FLEX -->
        <div id="tarifs" class="flex flex-col -mr-2 sm:hidden">
          <div id="les-dits-tarifs" class="flex flex-row my-8 justify-evenly">
            <div id="newSwitch" class="self-end w-1/4 -mr-4">
              <div class="mb-2 -ml-4 text-sm font-bold">Engagement</div>
              <div class="toggle-switch-container">
                <div class="toggle-switch switch-vertical">
                  <input id="toggle-a" type="radio" v-model="month" :value="true" checked="checked" />
                  <label for="toggle-a">Mensuel</label>
                  <input id="toggle-b" type="radio" v-model="month" :value="false" />
                  <label for="toggle-b">Annuel</label>
                  <span class="toggle-outside">
                    <span class="toggle-inside"></span>
                  </span>
                </div>
              </div>
            </div>
            <div id="Initié" class="flex flex-col justify-end w-1/4">
              <div id="nom" class="text-sm font-bold">Initié</div>
              <div v-if="!month" id="prix" class="text-sm font-medium">26 €<span class="text-xs font-normal"> HT par mois</span></div>
              <div v-else id="prix" class="text-sm font-medium">39 €<span class="text-xs font-normal"> HT par mois</span></div>
              <div id="bouton">
                  <button class="px-3 py-2 mt-2 text-xs font-normal text-white normal-case rounded-lg bg-aestiblue-light aestima-button" @click="selectPriceAndRoute('ini', false)">
                    <p>Essayer</p>
                  </button>
              </div>
            </div>
            <div id="Professionnel" class="flex flex-col justify-end w-1/4">
              <div id="nom" class="text-sm font-bold">Pro</div>
              <div v-if="!month" id="prix" class="text-sm font-medium">44 €<span class="text-xs font-normal"> HT par mois</span></div>
              <div v-else id="prix" class="text-sm font-medium">59 €<span class="text-xs font-normal"> HT par mois</span></div>
              <div id="bouton">
                  <button class="px-3 py-2 mt-2 text-xs font-normal text-white normal-case rounded-lg bg-aestiblue-light aestima-button" @click="selectPriceAndRoute('pro', false)">
                    <p>Essayer</p>
                  </button>
              </div>
            </div>
          </div>
        </div>
        <!-- FIN TARIFS EN FLEX-->


        <div class="relative items-start justify-center hidden mt-4 mb-6 sm:grid grid-cols-11">
          <div class="col-start-1 col-end-12">
            <p class="text-2xl font-bold text-left">Retrouvez aussi nos offres complémentaires</p>
          </div>
        </div>

        <!-- CREDIT UNIQUE -->
        <!-- <div class="hidden rounded-lg sm:grid subscribe-table col-span-12">
          <div class="rounded-lg subscribe-table-body">
            <div class="relative grid grid-cols-11">
              <div class="mx-5 my-auto col-start-1 col-end-4">
                <p class="option-title">Crédit unique Aestima</p>
              </div>

              <div class="px-2 py-4 col-start-4 col-end-8">
                <p class="text-right option-desc leading-5">Valable pour une simulation, ce crédit vous permettra d’accéder aux modules d’analyse avancée, d’optimisation et d’exports de dossiers.</p>
              </div>

              <div class="flex flex-col items-center justify-center py-4 col-start-6 col-end-9">
                <p class="text-2xl font-bold text-darkblueaestima-500">25 €</p>
                <p class="option-price-desc">HT par simulation</p>
              </div>

              <div class="py-4 col-start-9 col-end-12 place-self-center">
                <router-link @click.native="selectedStripePrice({ id: 0, isTrial: false })" to="/acces-simulateur"
                  ><button class="px-3 py-2 text-xs font-normal text-white normal-case rounded-lg bg-aestiblue-light aestima-button w-100">
                    <p>Acheter un crédit</p>
                  </button></router-link
                >
              </div>
            </div>
          </div>
        </div> -->
        <!-- FIN CREDIT UNIQUE -->

        <!-- Formation personnalisée -->
        <div class="hidden rounded-lg sm:grid subscribe-table col-span-12">
          <div class="rounded-lg subscribe-table-body">
            <div class="relative grid grid-cols-11">
              <div class="mx-5 my-auto col-start-1 col-end-4">
                <p class="option-title">Formation personnalisée</p>
              </div>

              <div class="px-2 py-4 col-start-4 col-end-8">
                <p class="text-right option-desc leading-5">
                  Vous développez l’activité de conseil en investissement au sein de votre agence ? Nos experts peuvent former votre équipe pour calculer, analyser et optimiser toutes les performances
                  d’un bien locatif et accompagner au mieux vos clients investisseurs.
                </p>
              </div>

              <div class="flex flex-col items-center justify-center py-4 col-start-8 col-end-10">
                <p class="text-xl font-bold text-darkblueaestima-500">tarif horaire</p>
                <p class="option-price-desc">sur devis</p>
              </div>

              <div class="py-4 col-start-10 col-end-12 place-self-center">
                <a href="https://calendly.com/contact-aestima/45min?" target="_blank">
                  <button class="px-3 py-2 text-xs font-normal text-white normal-case rounded-lg bg-aestiblue-light aestima-button w-100">
                    <p>Programmer un RDV</p>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- FIN Formation personnalisée -->

        <div class="flex flex-col items-center mb-4 sm:flex-row justify-evenly">
          <p class="my-auto">
            L’équipe fondatrice de Aestima est
            <b>diplômée de Master de finance et d’immobilier</b> et est certifiée par l’<b>Autorité des Marchés financiers.</b>
          </p>
          <img class="w-1/2 h-auto mt-5 sm:w-48 sm:mt-0" src="../../assets/icons/home/aestima_amf.png" alt="logo amf" />
        </div>

        <!-- TVA -->
        <!-- <p class="text-left tva">* TVA non applicable, article 293 B du Code général des impôts</p> -->
        <!-- FIN TVA -->
      </div>
    </div>
    <Footer/>
  </div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
import No from '@/components/Pricing/No.vue'
import Yes from '@/components/Pricing/Yes.vue'
import QuestionMark from '@/components/SVG/QuestionMark.vue'
import { mapGetters, mapActions } from "vuex"

export default {
  name: 'Pricing',
  data() {
    return {
      month: true,
    }
  },
  components: {
    Footer,
    Header,
    No,
    Yes,
    QuestionMark
  },
  metaInfo(){
    return {
      title: "Tarifs Aestima : logiciel CGP vraiment intuitif",
      meta: [{
        name: 'description',
        content: "Simulateur d’investissement immobilier - Simulateur de défiscalisation - Pédagogique pour vos équipes et vos clients."
      }]
      //keywords
    }
  },
  methods: {
    ...mapActions({
      selectedStripePrice: 'users/setStripePriceSelected',
    }),

    async setSelectedStripePrice(typePrice, isTrial) {
      if(typePrice == 'ini') {
        if(this.month == true) {
          await this.selectedStripePrice({id: process.env.VUE_APP_STRIPE_PRICE_ID_INI_MONTH, isTrial: isTrial});
        } else {
          await this.selectedStripePrice({id: process.env.VUE_APP_STRIPE_PRICE_ID_INI_YEAR, isTrial: isTrial});
        }
      } else if(typePrice == 'pro') {
        if(this.month == true) {
          await this.selectedStripePrice({id: process.env.VUE_APP_STRIPE_PRICE_ID_PRO_MONTH, isTrial: isTrial});
        } else {
          await this.selectedStripePrice({id: process.env.VUE_APP_STRIPE_PRICE_ID_PRO_YEAR, isTrial: isTrial});
        }
      }
    },
    async selectPriceAndRoute(typePrice, isTrial) {
      console.log('typePrice', typePrice);
      await this.setSelectedStripePrice(typePrice, isTrial);
      this.$router.push('/register');
    }
  }
}
</script>

<style lang="scss" scoped>

.subscribe{
  &-desc{
    font-size: 12px;
    margin: 0 10px;
    height: 40px;
    margin-top: 8px;
    margin-bottom: 4px;
  }
  &-essaie{
    font-size: 11px;
  }
  &-title{
    font-weight: bold;
    font-size: 23px;
    color: #0C1E3B;
  }
  &-table{
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;
    &-header{
      background: rgba(20, 160, 235, 0.19);
      color: #14A0EB;
      font-weight: bold;
      font-size: 16px;
    }
    &-body{
      background-color: #f3fafe;
      line-height: 30px;
      &-title{
        color: #0C1E3B;
        font-weight: bold;
        font-size: 16px;
      }
      &-desc{
        color: #0C1E3B;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
  &-item{
    color: #0C1E3B;
    font-weight: bold;
    &-italic{
      font-style: italic;
      font-size: 12px;
      color: #0C1E3B;
    }
    &-desc{
      font-size: 16px;
    }
  }
}

.option{
  &-title{
    color: #0C1E3B;
    font-size: 20px;
    font-weight: bold;
  }
  &-desc{
    color: #0C1E3B;
    font-weight: 400;
    font-size: 12px;
  }
  &-price{
    color: black;
    font-weight: bold;
    font-size: 30px;
    &-desc{
      color: #0C1E3B;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.switch-button {
  background: #99ddff;
  border-radius: 30px;
  overflow: hidden;
  width: 240px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #ffffff;
  position: relative;
  padding-right: 120px;

  &:before {
    content: 'Annuel';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(120px);
      transition: transform 300ms linear;
    }

    & + .switch-button-label {
      position: relative;
      padding: 15px 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: '';
        background: #14a0eb;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 30px;
        border: 3px solid #0e1e3a;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}

.tva {
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
  margin-top: 59px;
}

.h-header {
  height: 100%;
}
.aestima-button {
  transition: 0.5s;
  background-color: #14a0eb;
}

.aestima-button:hover {
  background-color: #ffffff;
  border: 2px solid #14a0eb;
  color: #14a0eb;
  transform: scale(1);
}

/* Medium (md) */
@media (min-width: 768px) {
  .aestima-title-vw {
    font-size: 2.8vw !important;
  }

  .aestima-par-vw {
    font-size: 1.2vw !important;
  }
}

.shadow-inner {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/** SWITCH */

.switch {
  position: relative;
  display: inline-block;
  width: 9rem;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #14a0eb;
  border: #0c1e3b solid 1px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0.2rem;
}

.slider:before {
  position: absolute;
  content: '';
  height: 32px;
  width: 26px;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 0.2rem;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(-7.25rem);
  -ms-transform: translateX(-7.25rem);
  transform: translateX(-7.25rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.wrapper {
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.wrapper .tooltip {
  background: #14a0eb;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -20px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
  bottom: -20px;
  content: ' ';
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #1496bb 10px;
  bottom: -10px;
  content: ' ';
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
  display: none;
}

.lte8 .wrapper:hover .tooltip {
  display: block;
}

@import url('https://fonts.googleapis.com/css?family=Roboto');
*,
*:before,
*:after {
  box-sizing: border-box;
}

.font-xxs {
  font-size: 0.8rem;
  line-height: 0.9rem /* 16px */;
}

.toggle-switch-container {
  align-content: center;
  padding: 2px;
}

.toggle-switch {
  margin: 0;
}

.toggle-switch {
  width: 10px;
  position: relative;
}

.toggle-switch input {
  position: absolute;
  top: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.toggle-switch input:checked {
  z-index: 1;
}

.toggle-switch input:checked + label {
  opacity: 1;
  cursor: default;
}

.toggle-switch input:not(:checked) + label:hover {
  opacity: 0.5;
}

.toggle-switch label {
  color: rgb(0, 0, 0);
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.toggle-switch .toggle-outside {
  height: 100%;
  border-radius: 17px;
  padding: 11px;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}

.toggle-switch .toggle-inside {
  border-radius: 50%;
  background: #424242;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.switch-vertical {
  background-color: #00f;
  width: 0px;
  height: 60px;
  margin-left: 14px;
}

.switch-vertical input {
  height: 100%;
  width: auto;
  right: 0;
  margin: 0;
}

.switch-vertical label {
  font-size: 11px;
  line-height: 30px;
  display: block;
  width: 80px;
  height: 50%;
  margin: 0;
  text-align: left;
  padding-left: 6px;
}

.switch-vertical .toggle-outside {
  background: #14a0eb;
  position: absolute;
  width: 23px;
  height: 100%;
  right: 0;
  top: 0;
}

.switch-vertical .toggle-inside {
  height: 17px;
  left: 3px;
  top: 3px;
  width: 17px;
}

.switch-vertical input:checked ~ .toggle-outside .toggle-inside {
  top: 3px;
}

.switch-vertical input ~ input:checked ~ .toggle-outside .toggle-inside {
  top: 40px;
}
</style>
