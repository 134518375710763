var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('l-map',{ref:"map",staticClass:"map",attrs:{"center":[_vm.polygon.centre.coordinates[1], _vm.polygon.centre.coordinates[0]],"zoom":_vm.zoom,"minZoom":_vm.minZoom,"maxZoom":_vm.maxZoom,"options":{
			zoomControl: false,
			zoomDelta: _vm.zoomDelta,
			zoomSnap: _vm.zoomDelta,
			dragging: true,
		}}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"options":{
				maxZoom: 25
			}}}),_c('l-geo-json',{attrs:{"geojson":_vm.polygon.contour}}),_c('l-control-zoom',{attrs:{"position":"topright"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }