<template>
	<div>
		<Header/>
		<div class="grid grid-cols-11 gap-1 bg-darkblueaestima-500 relative flex flex-row justify-center items-start">
			<div class="relative text-center col-span-11 sm:col-start-3 sm:col-end-9 z-10 flex justify-center">
				<h1 class="1-title font-normal text-white text-4xl md:text-6xl mb-4 aestima-title-vw leading-none text-center sm:text-left"><b>Investir en SCI,</b> </br>Une bonne solution ? </h1>
				<!-- <p class="text-gray-300 font-medium text-2xl">Essayez la version gratuite</p>
				<p class="text-white font-medium text-2xl">durant 1 mois</p> -->
			</div>
			<!-- <a href="/presentation" class="col-span-11 sm:col-start-5 sm:col-end-8 uppercase text-sm font-semibold text-white px-12 py-6 bg-aestiblue-light rounded-full aestima-button mb-10">DEMARRER MON ESSAI GRATUIT</a>	 -->
		</div>
		
		<div class="mx-10 sm:mx-20 text-left mt-10 smooth">
			<div class="grid grid-cols-12 gap-1 relative flex flex-row justify-center items-start mb-6">
				<div class="hidden sticky top-16 sm:block sm:col-start-1 sm:col-end-3">
					<div class=" flex flex-col font-semibold">
						<a href="#introduction" class="my-2">Introduction</a>
						<a href="#creer_sci" class="my-2">Créer une SCI, comment ça marche ?</a>
						<a href="#difference" class="my-2">SCI à l’IR ou à l’IS, quelles différences ?</a>
						<a href="#avantage" class="my-2">Quels avantages pour l’investissement locatif ?</a>
						<a href="#pret" class="my-2">Prêt immobilier en SCI</a>
						<a href="#difference_sci" class="my-2">SCI familiale, différence avec une SCI classique ?</a>
						<a href="#conclusion" class="my-2">Conclusion</a>
					</div>
				</div>
				<div class="col-span-12 sm:col-start-4 sm:col-end-12 text-justify">
					<div class="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center mb-10">
						<div class="flex flex-row">
							<div class="rounded-full w-16 h-16 overflow-hidden flex justify-center items-center">
								<img class="w-20" src="../../../assets/julien_profil.png" alt="Profil Julien">
							</div>
							<div class="flex flex-col justify-center ml-6">
								<address class="not-italic font-bold text-lg">Julien</address> 
								<span class="text-sm">Mis à jour le <time pubdate datetime="2021-04-02" title="2 avril 2021">02 avril 2021</time></span>
							</div>
						</div>
						<div class="p-2 mt-6 sm:mt-0 bg-gray-100 rounded-full relative clipboard cursor-pointer" @click="clipboard()">
							<div>{{linkCopied}}</div>
							<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
							width="22" height="22"
							viewBox="0 0 172 172"
							style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#000000"><path d="M43,50.16667c-20.94817,0 -37.7007,17.97568 -35.66536,39.34668c1.77733,18.63333 18.48575,32.31999 37.20508,32.31999h19.96029c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667h-20.28223c-11.63867,0 -22.07378,-8.72072 -22.67578,-20.35222c-0.65217,-12.39833 9.20301,-22.64778 21.45801,-22.64778h21.5c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667zM107.5,50.16667c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h20.28223c11.63867,0 22.07378,8.72072 22.67578,20.35222c0.65217,12.39833 -9.20301,22.64778 -21.45801,22.64778h-21.5c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h21.5c20.94817,0 37.7007,-17.97568 35.66536,-39.34668c-1.77733,-18.63334 -18.48574,-32.31999 -37.20508,-32.31999zM57.33333,78.83333c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h57.33333c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667z"></path></g></g></svg>
						</div>
					</div>
					<p id="introduction">Lorsqu’il s’agit d’investissement locatif, nous entendons souvent parler du montage en SCI (Société Civile Immobilière). Son utilisation et son fonctionnement restent néanmoins opaques pour bon nombre d’investisseurs immobiliers.
Avant d’aller plus loin, notez que l’acronyme IR signifie “impôt sur le revenu” et IS “impôt sur les sociétés”.</p>
					</br>
					<p>
						Qu’est-ce qu’une SCI ? Comment fonctionne une SCI ? Quels sont les <b>avantages d’une SCI pour un investissement locatif</b> ? <b>SCI à l’IR ou à l’IS</b> ? Est-ce une bonne chose de <b>créer une SCI pour acheter un appartement</b> ? Pourquoi <b>investir en SCI</b> finalement ? 
					</p>
					</br>
					<p>
						Et qu’est-ce que cela change pour moi si je veux <b>créer une SCI familiale pour acheter un bien immobilier</b> ?
					</p>
					</br>
					<p>
						Nous avons rédigé cet article justement pour répondre à ces questions et faire un tour d’horizon complet du montage en SCI. 
					</p>
					</br>
					<p>
						Après cette lecture vous pourrez faire le choix de la SCI en pleine conscience et investir sereinement. Bonne lecture !
					</p>
					
					<div class="flex flex-col items-center relative my-6 mb-16">
						<img class="w-3/3 flex justify-center items-center self-center" src="@/assets/article/investir-SCI.png" title="Avantages SCI pour investissement locatif" alt="SCI IR ou IS">
						<div class="relative bottom-0 w-full h-10 bg-img-desc flex justify-start items-center pl-4 -m-10">
							<p class="text-xs sm:text-base"><b>Investir en SCI, </b>une bonne solution pour votre <b>projet immobilier</b> locatif ?</p>
						</div>
					</div>
					
					<div class="mt-10" id="difference" ref="difference">
						<h2 class="text-2xl mb-6">
							<b>Créer une SCI pour acheter un appartement,</b></br>
							comment ça marche ?
						</h2>
						
						<p>
							Que cela soit pour acquérir un appartement, un immeuble, une maison, un local commercial ou tout autre bien immobilier, la SCI fonctionne de la même manière. 
						</p>
						</br>
						<p>
							Pour commencer, il faut savoir que la SCI est un montage juridique spécifique permettant d’investir à plusieurs dans un projet immobilier. L’objet social de la SCI, c’est-à-dire l’objectif de son existence, peut être varié.
En effet, vous pouvez la créer pour gérer des immeubles, acheter et revendre des biens immobiliers ou encore mettre en location un patrimoine immobilier. Nous parlerons ici de cette dernière appelée la SCI de location, modèle que l’on retrouve le plus souvent en investissement immobilier.
						</p>
						</br>
						<p>
							Vous l’aurez compris, il faut donc être deux personnes au minimum pour constituer une SCI. Il faut ensuite répartir les parts sociales de cette société, c’est-à-dire, définir le pourcentage de détention de chacun des associés (par exemple si vous êtes 2, on fera généralement 50%/50%, si vous êtes 4, cela sera 25% chacun, etc.). Cette répartition est librement décidée par les associés. L’affectation des résultats et de l’impôt à payer (dans le cadre de la SCI à l’impôt sur le revenu, on y reviendra) dépendront de cette répartition.
						</p>
						</br>
						<p>
							Si plusieurs personnes peuvent <b>investir dans une SCI</b>, qu’elle est la <a target="blank" href="https://www.pretto.fr/acheteur-immobilier/sci/indivision-ou-sci/"><u>différence entre une SCI et une indivision</u></a> ? C’est principalement le cadre juridique et la planification long terme de votre investissement immobilier. 
						</p>
					</div>
					
					<div class="mt-10" id="difference" ref="difference">
						<h2 class="text-2xl mb-6">
							<b>SCI à l’IR ou à l’IS,</b></br>
							quelles différences ?
						</h2>
						<p>
							Nous retrouvons deux régimes fiscaux différents pour <b>investir en SCI</b>.
						</p>
						</br>
						<p class="mb-6">
							Il n’y a pas un régime fiscal meilleur qu’un autre, cela dépend bien entendu de votre situation financière, fiscale et de votre projet d’investissement immobilier. On fait le point ensemble à travers le tableau suivant.
						</p>
						
						<div class="overflow-x-scroll">
							<div class="w-80-c">
							<div class="grid grid-cols-12 gap-0">
								<div class="col-start-1 col-end-5 px-6 py-2 flex justify-center items-center border-black border-l border-t">
								</div>
								<div class="col-start-5 col-end-9 px-6 py-2 flex justify-center items-center border-black border-l border-t">
									<p><b>SCI à l’IR</b></p>
								</div>
								<div class="col-start-9 col-end-13 px-6 py-2 flex justify-center items-center border-black border-l border-r border-t">
									<p><b>SCI à l’IS</b></p>
								</div>
							</div>
							
							<div class="grid grid-cols-12 gap-0">
								<div class="font-bold col-start-1 col-end-5 p-2 sm:p-6 flex justify-left items-center border-black border-l border-t">
									<p>Création de société</p>
								</div>
								<div class="col-start-5 col-end-9 p-2 sm:p-6 flex justify-center items-center border-black border-l border-t">
									<p>Rédaction des statuts, Constitution d’un capital social, frais d’enregistrement.</p>
								</div>
								<div class="col-start-9 col-end-13 p-2 sm:p-6 flex justify-center items-center border-black border-l border-r border-t">
									<p>Formalités identiques à la SCI à l’IR.</p>
								</div>
							</div>
							
							<div class="grid grid-cols-12 gap-0">
								<div class="font-bold col-start-1 col-end-5 p-2 sm:p-6 flex justify-left items-center border-black border-l border-t">
									<p>Forme juridique</p>
								</div>
								<div class="col-start-5 col-end-9 p-2 sm:p-6 flex justify-center items-center border-black border-l border-t">
									<p>Pas d’identité morale,il y a autant de personnes physiques qu’il y a d’associés.</p>
								</div>
								<div class="col-start-9 col-end-13 p-2 sm:p-6 flex justify-center items-center border-black border-l border-r border-t">
									<p>Associés sous une seule et même personne morale.</p>
								</div>
							</div>
							
							<div class="grid grid-cols-12 gap-0">
								<div class="font-bold col-start-1 col-end-5 p-2 sm:p-6 flex justify-left items-center border-black border-l border-t">
									<p>Tenue d’une comptabilité.</p>
								</div>
								<div class="col-start-5 col-end-9 p-2 sm:p-6 flex justify-center items-center border-black border-l border-t">
									<p>Non obligatoire.</p>
								</div>
								<div class="col-start-9 col-end-13 p-2 sm:p-6 flex justify-center items-center border-black border-l border-r border-t">
									<p>Obligatoire.</p>
								</div>
							</div>
							
							<div class="grid grid-cols-12 gap-0">
								<div class="font-bold col-start-1 col-end-5 p-2 sm:p-6 flex justify-left items-center border-black border-l border-t">
									<p>Charges déductibles.</p>
								</div>
								<div class="col-start-5 col-end-9 p-2 sm:p-6 flex justify-center items-center border-black border-l border-t">
									<p>Charges liées à l’exploitation du bien (taxe foncière, chargesde copropriété, gestion locative, etc.)</p>
								</div>
								<div class="col-start-9 col-end-13 p-2 sm:p-6 flex justify-center items-center border-black border-l border-r border-t">
									<p>Toutes les charges relatives au bien (frais d’acquisition, frais d’exploitation, frais annexes).</p>
								</div>
							</div>
							
							<div class="grid grid-cols-12 gap-0">
								<div class="font-bold col-start-1 col-end-5 p-2 sm:p-6 flex justify-left items-center border-black border-l border-t">
									<p>Amortissement comptable.</p>
								</div>
								<div class="col-start-5 col-end-9 p-2 sm:p-6 flex justify-center items-center border-black border-l border-t">
									<p>Non pris en compte.</p>
								</div>
								<div class="col-start-9 col-end-13 p-2 sm:p-6 flex justify-center items-center border-black border-l border-r border-t">
									<p>Pris en compte.</p>
								</div>
							</div>
							
							<div class="grid grid-cols-12 gap-0">
								<div class="font-bold col-start-1 col-end-5 p-2 sm:p-6 flex justify-left items-center border-black border-l border-t">
									<p>Imposition sur les résultats.</p>
								</div>
								<div class="col-start-5 col-end-9 p-2 sm:p-6 flex justify-center items-center border-black border-l border-t">
									<p>Imposition à l’IR propre à chaque associé selon leur TMI respective.</p>
								</div>
								<div class="col-start-9 col-end-13 p-2 sm:p-6 flex justify-center items-center border-black border-l border-r border-t">
									<p>Imposition à l’IS au nom de la SCI et non à celui des associés personnellement.</p>
								</div>
							</div>
							
							<div class="grid grid-cols-12 gap-0">
								<div class="font-bold col-start-1 col-end-5 p-2 sm:p-6 flex justify-left items-center border-black border-l border-t">
									<p>Impôt sur la plus-value de revente.</p>
								</div>
								<div class="col-start-5 col-end-9 p-2 sm:p-6 flex justify-center items-center border-black border-l border-t">
									<p>Régime des plus-values immobilières imputable à chaque associé au prorata de leurs parts sociales.</p>
								</div>
								<div class="col-start-9 col-end-13 p-2 sm:p-6 flex justify-center items-center border-black border-l border-r border-t">
									<p>Soumis à l’imposition à l’IS classique avec la cession d’un actif immobilier inscrit en résultat exceptionnel.</p>
								</div>
							</div>
							
							<div class="grid grid-cols-12 gap-0">
								<div class="font-bold col-start-1 col-end-5 p-2 sm:p-6 flex justify-left items-center border-black border-l border-t border-b">
									<p>Impôt sur les dividendes.</p>
								</div>
								<div class="col-start-5 col-end-9 p-2 sm:p-6 flex justify-center items-center border-black border-l border-t border-b">
									<p>Non imputable.</p>
								</div>
								<div class="col-start-9 col-end-13 p-2 sm:p-6 flex justify-center items-center border-black border-l border-r border-t border-b">
									<p>Si les associés décident de se verser des dividendes, ils seront imposés à titre personnel (flat tax ou RCM).</p>
								</div>
							</div>
							</div>
						</div>
						
						</br>
						<p>
							L’avantage d’un mode d’imposition ou d’un autre reste très subjectif à votre profil et votre projet immobilier. Le meilleur moyen de connaître la meilleure option consiste à comparer ces deux régimes fiscaux entre eux. L’outil Aestima permet justement cette comparaison. 
						</p>
						
						<div class="flex justify-center my-10">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg text-center font-semibold text-white px-6 py-3 bg-aestiblue-light rounded-lg aestima-button mt-20 sm:mt-0"><h3>Comparer la meilleure option entre SCI à l’IR ou à l’IS</h3></router-link>
						</div>
					
					</div>
					<div class="mt-10" id="avantage" ref="avantage">
						<h2 class="text-2xl mb-6">
							Quels sont les </br>
							<b>avantages d’une SCI pour l’investissement locatif ?</b>
						</h2>
						
						<p>
							Avec le tableau descriptif ci-dessus, nous avons vu les principales différences entre les deux régimes fiscaux possibles pour <b>investir en SCI</b>.
							Voyons maintenant quels peuvent être leurs avantages respectifs.
						</p>
						</br>
						</br>
						</br>
						<h3 class="font-bold text-6xl flex flex-row items-center">
							<img class="w-aauto h-14 mb-10 sm:m-0" src="@/assets/article-number-1-black.png" title="Moins de frais" alt="Moins de frais">
							<div class='text-lg ml-2'>
								<div class='font-normal'>
									Avantages d’une SCI à l’IR pour l’investissement locatif.
								</div>
								<div>
									Moins de frais.
								</div>
							</div>
						</h3>
						</br>
						<p class="sm:pl-12">
							Premièrement, une <b>SCI à l’IR</b> coûte moins cher annuellement qu’une <b>SCI à l’IS</b> notamment grâce au fait qu’il n’est pas obligatoire de tenir une comptabilité (pour une SCI à l’IS, comptez facilement 1500€ / an pour ce poste de dépenses). 
						</p>
						</br>
						</br>
						<h3 class="font-bold text-6xl flex flex-row items-center">
							<img class="w-aauto h-14 mb-10 sm:m-0" src="@/assets/article-number-2-black.png" title="Exonération d’impôt sur la plus-value" alt="Exonération d’impôt sur la plus-value">
							<div class='text-lg ml-2'>
								<div class='font-normal'>
									Avantages d’une SCI à l’IR pour l’investissement locatif.
								</div>
								<div>
									Exonération d’impôt sur la plus-value.
								</div>
							</div>
						</h3>
						</br>
						<p class="sm:pl-12">
							Aussi, si votre objectif est de constituer un patrimoine immobilier à long terme avec une revente à plus de 30 ans, sachez que vous serez exonéré d’impôt sur la plus-value immobilière. Ce n’est pas le cas dans une SCI à l’IS qui, elle, sera imposée à 28% sur la totalité de la vente (Prix de cession - valeur nette comptable de l’actif immobilier). Cela fait une sacrée différence.
						</p>
						</br>
						</br>
						<h3 class="font-bold text-6xl flex flex-row items-center">
							<img class="w-aauto h-14 mb-10 sm:m-0" src="@/assets/article-number-3-black.png" title="Pas d’impôt sur les dividendes" alt="Pas d’impôt sur les dividendes">
							<div class='text-lg ml-2'>
								<div class='font-normal'>
									Avantages d’une SCI à l’IR pour l’investissement locatif.
								</div>
								<div>
									Pas d’impôt sur les dividendes.
								</div>
							</div>
						</h3>
						</br>
						<p class="sm:pl-12">
							Enfin, comme ce régime fiscal est transparent fiscalement, chaque associé paie des impôts sur les revenus fonciers générés par la SCI au prorata de ses parts sociales. Ayant payé des impôts sur ses gains, il est donc libre de se les reverser sous forme de dividendes au lieu de les laisser sous forme de trésorerie dans sa SCI. 
						</p>
						</br>
						<div class="my-10 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative">
							<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../../../assets/picto/ampoule.png" alt="">
							<p class="text-lightblueaestima-100 relative z-10">
								<b>Bon à savoir :</b>  Les associés de la SCI à l’IS auront, quant à eux, une double imposition. En effet, les revenus de la SCI seront imposés à l’IS et s’ils font le choix de se reverser les dividendes de la SCI, ils seront également imposés à titre personnel (RCM ou flat tax).
							</p>
						</div>
						</br>
						<p>
							Vous l’avez compris, <b>créer une SCI pour acheter un appartement</b> n’est pas anodin, et nécessite plusieurs démarches pour le choix de votre régime fiscal qui vous correspondra le mieux. Maintenant, voici trois principaux avantages du montage en SCI avec le régime fiscal à l’IS pour votre investissement locatif.
						</p>
						</br>
						</br>
						<h3 class="font-bold text-6xl flex flex-row items-center">
							<img class="w-aauto h-14 mb-10 sm:m-0" src="@/assets/article-number-1-black.png" title="Amortir le bien immobilier" alt="Amortir le bien immobilier">
							<div class='text-lg ml-2'>
								<div class='font-normal'>
									Avantages d’une SCI à l’IS pour l’investissement locatif.
								</div>
								<div>
									Amortir le bien immobilier.
								</div>
							</div>
						</h3>
						</br>
						<p class="sm:pl-12">
							L’avantage le plus important, et que l’on ne retrouve pas en SCI à l’IR, concerne l’amortissement du bien immobilier. Dans le cadre de l’imposition sur les sociétés, l’investissement immobilier est comptablement pris en compte comme une activité commerciale. Ainsi, une des règles comptables consiste à ventiler le coût d’acquisition d’un bien immobilier sur plusieurs années. A titre d’exemple, pour un bien immobilier acheté 200 000€, on déduira en moyenne 6 600€ chaque année pendant 30 ans sur le résultat imposable. Cette charge est comptablement enregistrée comme une dépense mais vous ne payez rien, on peut donc dire qu’elle est “fictive”. Ce mécanisme fera considérablement chuter le revenu imposable de la SCI.
						</p>
						</br>
						</br>
						<h3 class="font-bold text-6xl flex flex-row items-center">
							<img class="w-aauto h-14 mb-10 sm:m-0" src="@/assets/article-number-2-black.png" title="Réduire l’impôt sur mes gains" alt="Réduire l’impôt sur mes gains">
							<div class='text-lg ml-2'>
								<div class='font-normal'>
									Avantages d’une SCI à l’IS pour l’investissement locatif.
								</div>
								<div>
									Réduire l’impôt sur mes gains.
								</div>
							</div>
						</h3>
						</br>
						<p class="sm:pl-12">
							De plus, si votre tranche marginale d’imposition est supérieure ou égale à 41%, le modèle de SCI à l’IS vous permet de vous verser des dividendes moins taxés que si vous étiez en SCI à l’IR notamment grâce à la flat tax (forfait de 30%). 
						</p>
						</br>
						</br>
						<h3 class="font-bold text-6xl flex flex-row items-center">
							<img class="w-aauto h-14 mb-10 sm:m-0" src="@/assets/article-number-3-black.png" title="Déduire un maximum de frais" alt="Déduire un maximum de frais">
							<div class='text-lg ml-2'>
								<div class='font-normal'>
									Avantages d’une SCI à l’IS pour l’investissement locatif.
								</div>
								<div>
									Déduire un maximum de frais.
								</div>
							</div>
						</h3>
						</br>
						<p class="sm:pl-12">
							Le dernier point, qui rejoint le premier, est relatif au volume de charges déductibles bien supérieur à ce que propose la SCI à l’IR. En effet, dès l’achat vous pourrez déduire les frais d’acquisition (frais de notaires, frais d’agences, frais bancaires), ainsi que tous les frais d’exploitation et de gestion de la SCI. Grâce à cela, même si l’amortissement comptable ne permet pas de ramener votre bénéfice imposable à 0€, vous pourrez minimiser votre fiscalité.
						</p>
						</br>
						<p class="sm:pl-12">
							Bien entendu, ces différents avantages représentent une tendance moyenne. La meilleure façon de voir quel est le montage fiscal le plus adapté, en fonction de votre situation, est de réaliser deux simulations sur Aestima, une en SCI à l’IR et l’autre à l’IS et de les comparer entre elles pour analyser leurs performances respectives.
						</p>
						
						<div class="flex justify-center my-10">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg text-center font-semibold text-white px-6 py-3 bg-aestiblue-light rounded-lg aestima-button mt-20 sm:mt-0"><h4>Comparer mes simulations en SCI à l’IR et SCI à l’IS</h4></router-link>
						</div>
					</div>
					
					
					
					<div class="mt-10" id="pret" ref="pret">
						<h2 class="text-2xl mb-6">
							<b>Prêt immobilier en SCI</b></br>
							Qu’est-ce que ça change ?
						</h2>
						
						<p>
							<b>Investir en SCI</b> vous permettra une meilleure souplesse lors de votre demande de financement. En effet, il y a deux moyens d’obtenir un <b>prêt immobilier pour une SCI</b>. Soit la banque va analyser la situation financière de chaque associé et leur faire un prêt immobilier personnel qu’ils apporteront ensuite en compte courant de la SCI ; soit la banque prête à l’entité de la SCI sans tenir compte des situations personnelles des associés.
						</p>
						</br>
						<p>
							Attention, avec cette deuxième méthode, si votre situation professionnelle est fragile, la banque demandera à un des associés, dont la situation est la plus stable, de se porter caution pour la SCI (à la hauteur de ses parts sociales ou de la totalité des parts).
						</p>
						<p>
							Ce cas de figure est fréquent lors d’un achat en SCI avec des parents et leurs enfants en tant qu’associés (dans l’hypothèse où ces derniers sont jeunes et insolvables).
						</p>
						</br>
						<p>
							Concernant les garanties prises par la banque, elles sont sensiblement les mêmes qu’un prêt immobilier classique. Une garantie bancaire sera alors demandée (hypothèque, cautionnement, etc.), et les associés devront personnellement souscrire à une assurance emprunteur pour couvrir la banque des risques d’invalidité et de décès des associés
						</p>
						</br>
						<p>
							Concernant le projet immobilier à proprement parler il sera donc étudié de la même manière que si vous investissiez seul. Soignez donc la présentation et le <router-link target="_blank" to="/monter-dossier-bancaire"><u>montage de votre dossier bancaire</u></router-link>.
						</p>
						
						<div class="flex justify-center my-10">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg text-center font-semibold text-white px-6 py-3 bg-aestiblue-light rounded-lg aestima-button mt-20 sm:mt-0"><h3>Comparer mes simulations en SCI à l’IR et SCI à l’IS</h3></router-link>
						</div>
					</div>
					
					<div class="mt-10" id="difference_sci" ref="difference_sci">
						<h2 class="text-2xl mb-6">
							<b>Créer une SCI familiale pour acheter un bien immobilier,</b></br>
							quelle différence avec une SCI classique ?
						</h2>
						<p>
							Il est fréquent d’entendre des investisseurs immobiliers voulant <b>investir en SCI</b> dite familiale.
						</p>
						</br>
						<p>
							Si vous deviez retenir qu’une seule chose de cette partie, gardez en tête que la SCI familiale est un montage réservé aux membres d’une même famille qui confère des avantages fiscaux pour la transmission du patrimoine entre parents et enfants.
						</p>
						</br>
						<p>
							Son fonctionnement est le même qu’une SCI classique et le choix du régime fiscal entre imposition à l’IR ou imposition à l’IS est également libre.
						</p>
						</br>
						<p>
							Ce type de SCI est souvent privilégié lors d’une succession pour éviter l’indivision et ses nombreux inconvénients.
						</p>
						</br>
						<p>
							Autre particularité qui lui est propre, la SCI familiale permet de démembrer les parts sociales entre les associés. Dans le cadre d’une anticipation de transmission d’un patrimoine immobilier, les parents peuvent, par exemple, garder l’usufruit de leurs biens et laisser la nue-propriété à leurs enfants. Ainsi lors de la transmission, les enfants ne devront payer des droits de successions que sur la valeur de leur nue-propriété (généralement 50% de la valeur du bien uniquement si les parents ont démembré le bien entre leur 51 et 60 ans par exemple)
						</p>
						
						<div class="my-10 border-2 px-6 py-4 rounded-lg border-lightblueaestima-100 relative">
							<img class="max-h-10 w-auto absolute ampoule-picto z-0" src="../../../assets/picto/ampoule.png" alt="">
							<p class="text-lightblueaestima-100 relative z-10">
								<b>Bon à savoir :</b>  Pour plus de précisions sur le calcul de votre abattement, consultez <a target="_blank" href="https://www.notaires.fr/fr/donation-succession/donation/lusufruit"><u>le barème des abattements liés au démembrement</u></a>.
							</p>
						</div>
					</div>
					
					<div class="mt-10" id="conclusion" ref="conclusion">
						<h2 class="text-2xl mb-6">
							Alors,</br>
							<b>Investir en SCI, bonne ou mauvaise idée?</b>
						</h2>
						<p>
							Pour résumer, <b>investir en SCI</b> peut procurer de nombreux avantages. Cela dépend néanmoins de votre projet immobilier et de votre situation qu’il faudra analyser au cas par cas. Quant au choix d’une <b>SCI à l’IR ou à l’IS</b>, il dépend lui aussi de votre exposition fiscale personnelle et de la politique de détention du bien et de dividendes que vous choissirez. 
						</p>
						</br>
						<p>
							<router-link to="/acces-simulateur"><u>L’outil Aestima</u></router-link> a été conçu pour ce type de problématiques et vous permettra de simuler votre investissement immobilier selon différents régimes fiscaux (LMNP, SCI, etc.), de  comparer vos simulations immobilières entre elles et ainsi de choisir la meilleure option pour atteindre vos objectifs et investir en pleine conscience.
						</p>
						
						<div class="flex justify-center my-10">
							<router-link target="_blank" to="/acces-simulateur" class="text-lg text-center font-semibold text-white px-6 py-3 bg-aestiblue-light rounded-lg aestima-button mt-20 sm:mt-0"><h3>Simuler mon investissement immobilier en SCI</h3></router-link>
						</div>
					</div>
					
				</div>
			</div>
				


		</div>

	<Footer/>
</div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
export default {
	name: 'Strategy',
	data() {
		return {
			posBoxB: 0,
			posBox: 0,
			linkCopied: 'copier le lien',
		}
	},
	components: {
		Footer,
		Header
	},
	created() {
		window.addEventListener('scroll', this.calcPosOfBox());
    	
  	},
	destroy() {
		window.addEventListener('scroll', this.calcPosOfBox());
	},
	computed: {

	},
	methods: {
		calcPosOfBox() {
			const rendement_brut = document.getElementById("rendement_brut");
			// const rendement_b = this.$refs["rendement_b"].getBoundingClientRect();
			// this.posBoxB = rendement_brut.y;
			// this.posBox = rendement_b.y;
			// console.log(this.posBoxB)
			// console.log(this.posBox)
			// console.log("window.scrollY", window.scrollY)

		},
		clipboard() {
			// var copyText = window.location.href
			const copyText = document.createElement('textarea');
  			copyText.value = window.location.href;
			document.body.appendChild(copyText);
			copyText.select();
  			copyText.setSelectionRange(0, 99999);
			document.execCommand("copy");
			document.body.removeChild(copyText);
			this.linkCopied = "Lien copié"
		},
	},
	metaInfo(){
		return {
			title: "Investir en SCI, 5 conseils pour optimiser son projet immobilier - Aestima.",
			meta: [{
				name: 'description',
				content: "Investir en SCI, une solution pour un projet immobilier à plusieurs. Comment fonctionne une SCI ? SCI à l’IR ou à l’IS ? On vous explique tout."
			}]
			//keywords
		}
	},
}
</script>

<style scoped>

.clipboard div{
	padding: 0.3rem 0.6rem 0.3rem 0.6rem;
	background: #0C1E3B;
	border-radius: 5px;
	color: white;
	font-size: 0.8rem;
	position: absolute;
	white-space: nowrap;
	top: 0;
	transition: 0.2s;
	transform: translateX(0);
	opacity: 0;
}

.clipboard:hover div {
	opacity: 1;
	transform: translateX(3rem);
}

.h-header {
	height: 100%;
}
.aestima-button {
	transition: 0.5s;
	background-color: #14A0EB;
}
/* 
video {
	position: relative;
	width: 100% !important;
	height: auto;
} */

.aestima-button:hover {
	background-color: #ffffff;
	border: 2px solid #14A0EB;
	color: #14A0EB;
	transform: scale(1.1);
}

.aestima-title-vw {
}

.aestima-par-vw {
}

@media (max-width: 768px) {
	.w-80-c {
		width: 80rem;
	}
}

/* Medium (md) */
@media (min-width: 768px) {
	.h-header {
		height: 50rem;
	}

	.aestima-title-vw {
		font-size: 2.8vw !important;
	}

	.aestima-par-vw {
		font-size: 1.2vw !important;
	}
}

.shadow-inner {
	box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);
}

/** SWITCH */

.switch {
  position: relative;
  display: inline-block;
  width: 10rem;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0C1E3B;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 0.2rem;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 0.2rem;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(7.8rem);
  -ms-transform: translateX(7.8rem);
  transform: translateX(7.8rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.smooth {
	scroll-behavior: smooth;
}

.ampoule-picto {
	top: -1rem;
	left: -1rem;
}

.bg-img-desc {
	background-color: rgba(20, 160, 235,0.6);
}

.round-tiret li {
	list-style: inside;
}

.round-tiret-check li {
	list-style: url('../../../assets/picto/aestima-tiret.png');
}

</style>

