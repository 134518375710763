<template>
	<div>
		<Header/>
		<div class="grid grid-cols-11 gap-1 bg-darkblueaestima-500 relative flex flex-row justify-center items-start">
			<div class="relative text-center col-span-11 sm:col-start-2 sm:col-end-11 z-10">
				<h1 class="uppercase s1-title font-bold text-white text-4xl md:text-6xl mb-4 aestima-title-vw leading-normal">CONDITIONS GÉNÉRALES DE VENTE</h1>
			</div>
		</div>
		
		<div class="flex flex-col items-start mx-6 sm:mx-40 my-10">
			<!-- <span class="uppercase font-bold text-lg">VERSION 1</span> -->
			<span class="uppercase font-bold text-lg">En vigueur au 22/08/2022</span>
		</div>
		
		<div class="mx-6 sm:mx-40 text-justify mb-20">
			<!-- <p>Entre l’entreprise Aestima Immobilier, </p>
			<p>252 Lieu-dit Haut Bré, 33990 Hourtin, </p>
			<p>immatriculée au répertoire SIRENE sous le SIRET 81982742900021, </p>
			<p>représentée par M. Julien VIDEAU en qualité de gérant, </p>
			<p>dûment habilité aux fins des présentes. </p>
			<p>La société peut être jointe par email en cliquant sur le formulaire de contact accessible via la page d’accueil du site.</p>
			<p>Ci-après     le     «     Vendeur     »     ou     la     «     Société     ». </br>D’une part, la personne physique ou morale procédant à l’achat de produits ou services de   la   société, </br>  Ci-après,   «   l’Acheteur   »,   ou   «   le   Client   » </br>D’autre part, il a   été exposé et convenu ce qui suit :</p>
			
			<h2>PREAMBULE</h2>
			
			<p>Le   Vendeur   est  éditeur   de   Produits   et   Services   de  Aestima   Immobilier  à destination   des   professionnels   de   l’immobilier,   commercialisés   par l’intermédiaire de ses sites Internet (<a href="https://aestima-immo.com">https://aestima-immo.com</a>). La liste et le descriptif des biens et services proposés par la Société peuvent être consultés sur les sites susmentionnés.</p>
			 -->
			<h2>ARTICLE 1 – CHAMP D’APPLICATION</h2>
			
			<p>Les présentes Conditions Générales de Vente (dites « CGV ») s’appliquent, sans restriction ni réserve à l’ensemble des ventes conclues par le Vendeur auprès d’acheteurs professionnels et non professionnels (« Les Clients ou le Client »), désirant acquérir les produits ou outils digitaux proposés à la vente (« Les Produits ») par le Vendeur sur le site aestima-immo.com. Les Produits proposés à la vente sur le site sont les suivants :</p>
			
			<ul class="sm:ml-10 mb-6">
				<li>- Abonnement « Initié »</li>
				<li>- Abonnement « Professionnel »</li>
			</ul>
			
			<p>
				Les caractéristiques principales des Produits et notamment les spécifications, illustrations et indications de dimensions ou de capacité des Produits, sont présentées sur le site aestima-immo.com ce dont le client est tenu de prendre connaissance avant de commander.
			</p>
			
			<p>
				Sera considéré dans les présentes CGV comme Client, tout acheteur d’un des Produits décrits ci-dessus et plus largement, toute personne s’inscrivant avec ses données personnelles sur le site aestima-immo.com afin d’utiliser l’outil digital mis gratuitement à disposition. 
			</p>
			
			<p>
				Le choix et l’achat d’un Produit sont de la seule responsabilité du Client.
			</p>
			<p>
				Ces CGV sont accessibles à tout moment sur le site aestima-immo.com et prévaudront sur toute autre document.
			</p>
			<p>
				Le Client déclare avoir pris connaissance des présentes CGV et les avoir acceptées en cochant la case prévue à cet effet avant la mise en œuvre de la procédure de commande en ligne du site aestima-immo.com.
			</p>
			<p>
				Le Vendeur se réserve la possibilité de modifier les présentes, à tout moment par la publication d’une nouvelle version sur son site Internet. Les CGV applicables alors sont celles étant en vigueur à la date du paiement (ou du premier paiement en cas de paiements multiples) de la commande.
			</p>
			<p>
				Sauf preuve contraire, les données enregistrées dans le système informatique du Vendeur constituent la preuve de l’ensemble des transactions conclues avec le Client.
			</p>
			<p>
				Les coordonnées du Vendeur sont les suivantes :
			</p>
			<p>
				Société EVENY
			</p>
			<p>
				SAS au capital social de 500€
			</p>
			<p>
				13 Rue Haxo, 13001 Marseille
			</p>
			<p>
				Numéro d’immatriculation : 918 402 322 RCS MARSEILLE
			</p>
			<p>
				Email : <a href="mailto:contact@aestima-immo.com">contact@aestima-immo.com</a>
			</p>
			
			<h2>ARTICLE 2 – PRIX</h2> 
			
			<p>
				Les Produits sont fournis aux tarifs en vigueur figurant sur le site aestima-immo.com, lors de l’enregistrement de la commande par le Vendeur.
			</p>
			<p>
				Les prix sont exprimés en Euros et hors taxes. Au moment de la facturation, le Client devra s’acquitter d’une TVA de 20%. Le Client paiera donc les Produits toutes taxes comprises (TTC).
			</p>
			<p>
				Les tarifs tiennent compte d’éventuelles réductions qui seraient consenties par le Vendeur sur le site aestima-immo.com.
			</p>
			<p>
				Ces tarifs sont fermes et non révisables pendant leur période de validité mais le Vendeur se réserve le droit, hors période de validité, d’en modifier les prix à tout moment.
			</p>
			<p>
				Le paiement demandé au Client correspond au montant total de l’achat, y compris des frais.
			</p>
			<p>
				Une facture est établie par le Vendeur et remise au Client lors de la livraison des Produits commandés.
			</p>

			<h2>ARTICLE 3 – COMMANDES</h2> 
			
			<p>
				Il appartient au Client de sélectionner sur le site aestima-immo.com les Produits qu’il désire commander selon les modalités suivantes :
			</p>
			<p>
				Le Client pourra se connecter à son espace en ligne après avoir choisi son abonnement payant qui ouvrira un accès à des fonctionnalités différentes selon l’abonnement choisi. Pour cela, il sera redirigé vers la page "tarifs" du site web et pourra cliquer sur l'offre qui lui correspond. Chacune de ces offres fonctionne par abonnement mensuel débité à chaque début de mois. Il sera ensuite redirigé vers notre module de paiement STRIPE pour passer la commande et payer le premier mois d’utilisation de l’outil. Les futurs débits concernant son abonnement seront effectués au début de chaque mois.
			</p>
		<p>Il y a deux types d’engagement pour les offres payantes :</p>
			<ul class="sm:ml-10 mb-6">
				<li>- L’engagement mensuel qui se poursuit par tacite reconduction chaque mois. Le Client sera débité à chaque début de mois. Tous mois commencé sera dû.</li>
				<li>- L’engagement annuel a une période d’engagement de 12 mois. Le Client sera débité du total de son engagement (soit 12 mois) dès la commande. Cette offre se poursuit par tacite reconduction au terme des 12 mois d’engagement.</li>
			</ul>
			<p>Les offres de Produits sont valables tant qu’elles sont visibles sur le site.</p>
			<p>La vente ne sera considérée comme valable qu’après paiement intégral du prix. Il appartient au Client de vérifier l’exactitude de la commande et de signaler immédiatement toute erreur.</p>
			<p>Toute commande passée sur le site aestima-immo.com constitue la formation d’un contrat conclu à distance entre le Client et le Vendeur.</p>
			<p>Le Vendeur se réserve le droit d’annuler ou de refuser toute commande d’un Client avec lequel il existerait un litige relatif au paiement d’une commande antérieure. </p>
			<p>Le Client pourra suivre l’évolution de sa commande sur le site aestima-immo.com.</p>
			
			<h2>ARTICLE 4 – ESPACE CLIENT – COMPTE</h2> 
			
			<p>Afin de passer commande, le Client est invité à créer un compte (espace personnel).</p>
			<p>Pour ce faire, il doit s’inscrire en remplissant le formulaire qui lui sera proposé au moment de sa commande et s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email.</p>
			<p>Le Client est responsable de la mise à jour des informations fournies. Il lui est précisé qu’il peut les modifier en se connectant à son compte.</p>
			<p>Pour accéder à son espace personnel, le Client devra s’identifier à l’aide de son nom d’utilisateur et de son mot de passe qui seront renseignés par ses soins au moment de son inscription et qui sont strictement personnels. A ce titre, le Client s’en interdit toute divulgation. Dans le cas contraire, il restera seul responsable de l’usage qui en sera fait.</p>
			<p>Le Client pourra également solliciter sa désinscription en se rendant dans l’onglet « paramètres » puis la page « mon compte » ou en envoyant un email à contact@aestima-immo.com. Celle-ci sera effective dans un délai raisonnable.</p>
			<p>En cas de non-respect des CGV, le site aestima-immo.com aura la possibilité de suspendre voire de fermer le compte d’un client après mise en demeure adressée par voie électronique et restée sans effet.</p>
			<p>Toute suppression de compte, quel qu’en soit le motif, engendre la suppression pure et simple de toutes informations personnelles du Client.</p>
			<p>Tout évènement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou du serveur et sous réserve de toute interruption ou modification en cas de maintenance, n’engage pas la responsabilité du Vendeur.</p>
			<p>La création du compte entraine l’acceptation des présentes conditions générales de vente.</p>
			
			<h2>ARTICLE 5 – CONDITIONS DE PAIEMENT</h2> 
			
			<p>Le prix est payé par voie de paiement sécurisé par carte bancaire.</p>
			<p>Le prix est payable comptant par le Client, en totalité au jour de la passation de la commande.</p>
			<p>Les données de paiement sont échangées en mode crypté grâce au protocole défini par le prestataire de paiement agréé intervenant pour les transactions bancaires réalisées sur le site aestima-immo.com.</p>
			<p>Les paiements effectués par le Client ne seront considérés comme définitifs qu’après encaissement effectif par le Vendeur des sommes dues.</p>
			<p>Le Vendeur ne sera pas tenu de procéder à la délivrance des Produits commandés par le Client si celui-ci ne lui en paye pas le prix en totalité dans les conditions ci-dessus indiquées.</p>
			<p>Pour le mode de paiement par abonnement, le prix payé en totalité correspond au prix mensuel de l’offre si l’engagement est mensuel et au prix annuel de l’offre si l’engagement est annuel.</p>
			<p>Une fois le paiement lancé par le Client, la transaction est immédiatement débitée après vérification des informations. Conformément aux dispositions du Code monétaire et financier, l’engagement de payer donné par carte est irrévocable. En communiquant ses informations bancaires lors de la vente, le Client autorise le Vendeur à débiter sa carte du montant relatif au prix indiqué, et ce au commencement de chaque mensualité. Le Client confirme qu’il est bien le titulaire légal de la carte à débiter et qu’il est légalement en droit d’en faire usage. En cas d’erreur, ou d’impossibilité de débiter la carte, la Vente est immédiatement résolue de plein droit et la commande annulée.</p>
						
			<h2>ARTICLE 6 – LIVRAISONS</h2> 
			
			<p>L’offre des Produits étant exclusivement disponible sur le site aestima-immo.com et les Produits étant exclusivement digitaux, les Produits commandées par le Client seront instantanément disponibles (« livrés ») dès paiement du prix. </p>
			<p>Les Produits seront livrés, en une seule fois, sur l’espace personnel du Client sur le site aestima-immo.com.</p>
			<p>Si les Produits commandés n’ont pas été livrés dans un délai de 96 heures après la commande, pour toute autre cause que la force majeure ou le fait du Client, la vente pourra être résolue à la demande écrite du Client dans les conditions prévues aux articles L 216-2, L 216-3 et L241-4 du Code de la consommation. Les sommes versées par le Client lui seront alors restituées au plus tard dans les quatorze jours qui suivent la date de dénonciation du contrat, à l’exclusion de toute indemnisation ou retenue.</p>
			<p>Le Client est tenu de vérifier la bonne conformité des Produits livrés. Il dispose d’un délai de 96 heures à compter de la livraison pour formuler des réclamations au Vendeur par voie électronique en envoyant un email à contact@aestima-immo.com, accompagné de tous les justificatifs y afférents (photos ou vidéos des anomalies et dysfonctionnement). Passé ce délai et à défaut d’avoir respecté ces formalités, les Produits seront réputés conformes et exempts de tout vice apparent et aucune réclamation ne pourra être valablement acceptée par le Vendeur.</p>
			<p>Le Vendeur remboursera ou résoudra les dysfonctionnements dans les plus brefs délais et à ses frais, les Produits livrés dont les défauts auront été dûment prouvés par le Client.</p>
			
			<h2>ARTICLE 7 – DROIT DE RÉTRACTATION</h2> 
			
			<p>Compte tenu de la nature des Produits vendus, les commandes passées par le Client ne bénéficient pas du droit de rétractation.</p>
			<p>D’après les conditions prévues à l’article L 121-21-8 du Code de la consommation, le droit de rétraction du Client ne peut être exercé car les Produits vendus sur le site aestima-immo.com constituent une « fourniture d'un contenu numérique non fourni sur un support matériel dont l'exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation ».</p>
			<p>Le contrat est donc conclu de façon définitive dès la passation de la commande par le Client selon les modalités précisées aux présentes CGV.</p>
			
			<h2>ARTICLE 8 – RESPONSABILITÉ DU VENDEUR – GARANTIES</h2> 
			
			<p>Les Produits fournis par le Vendeur bénéficient :</p>
			<p>De la garantie légale de conformité pour les Produits défectueux ou ne correspondant pas à la commande.</p>
			<p>De la garantie légale contre les vices cachés provenant d’un défaut de conception affectant les Produits et les rendant impropres à l’utilisation.</p>
			<p>Comme décrit explicitement sur le site aestima-immo.com ainsi que sur les rapports PDF fournis par le Vendeur, les résultats des simulations réalisées par le Client ont une vocation purement informative et ne revêtent aucun caractère contractuel. Les projections de performances financières n’ont aucune valeur scientifique et économique. Les simulations ont été élaborées à partir des paramètres fournis par le client à sa demande, conformément à la règlementation en vigueur. </p>

			<p>C'est bien d'ailleurs parce qu'il s'agit d'un document important que la simulation doit être faite avec un minimum de rigueur et de bonne foi. Il faut prendre des hypothèses raisonnables et ne pas faire miroiter des gains trop incertains. Si le Client réalise une simulation pour le compte de son propre client, il faudra le prévenir que certaines hypothèses de départ de la simulation peuvent donner lieu à des variations défavorables. Certes, une simulation présente nécessairement un caractère aléatoire mais elle ne doit pas présenter un caractère mensonger, ou même exagérément optimiste. La société EVENY ne pourra pas être tenu responsable de la mauvaise utilisation de son outil.</p>
			<p>Le Vendeur met à la disposition de ses Clients un outil digital d’aide à la décision mais n’a aucune obligation de résultats (comptable, fiscal, financière) envers eux. Chaque information fournie sur l’outil digital ou sur le site internet a pour seul but d’informer l’utilisateur et ne pourra, en aucun cas, être considérée comme un conseil professionnel d’ordre fiscal, comptable, financier ou autre.</p>
			<p>La garantie du Vendeur est, en tout état de cause, limitée à la réparation ou au remboursement des Produits non conformes ou affectés d’un vice.</p>
			
			<h2>ARTICLE 9 – DONNÉES PERSONNELLES</h2> 
			
			<p>Le Client est informé que la collecte de ses données à caractère personnel est nécessaire à la vente des Produits et à leur délivrance/ livraison, confiées au Vendeur. Ces données à caractère personnel sont récoltées pour l’exécution du contrat de vente.</p>
			<p>Le Client est par ailleurs informé que la collecte de ses données à caractère personnel est également nécessaire pour le suivi et la relation commercial qui peut en découler à l’exception des données saisies dans le cadre du « Module d’export de PDF » (voir détails ci-dessous) qui seront strictement réservées à la délivrance totale des Produits.</p>
			<h3>9.1 Collecte des données à caractère personnel</h3>
			<p>Les données à caractère personnel qui sont collectées sur le site aestima-immo.com sont les suivantes :</p>
			<ul class="sm:ml-10 mb-6">
				<li class="mb-2">
				<h4>- Ouverture de compte</h4>
				Lors de la création du compte Client / utilisateur :<br>
				Nom, prénom, adresse postale, numéro de téléphone et adresse email.</li>
				<li class="mb-2">
				<h4>- Paiement</h4>
				Dans le cadre du paiement des Produits proposés sur le site aestima-immo.com, celui-ci enregistre des données financières relatives à la carte bancaire du Client.</li>
				<li>
				<h4>- Module d’export de PDF</h4>
				Dans le cadre de la délivrance totale des Produits proposés sur le site aestima-immo.com (export d’un PDF personnalisé), celui-ci enregistre des données personnelles relatives aux clients du Client / utilisateur. Pour rappel, ces données ne sont pas comprises dans les conditions suivantes et restent strictement réservées à la délivrance totale des Produits.</li>
			</ul>
			<h3>9.2 Destinataires des données à caractère personnel</h3>
			<p>Les données à caractère personnel sont réservées à l’usage unique du Vendeur et de ses salariés.</p>
			<h3>9.3 Responsable de traitement</h3>
			<p>Le responsable de traitement des données est le Vendeur, au sens de la loi informatique et libertés et à compter du 25 mai 2018 du Règlement 2016/679 sur la protection des données à caractère personnel. Il peut être contacté via l’adresse email : contact@aestima-immo.com.</p>
			<h3>9.4 Limitation du traitement</h3>
			<p>Sauf si le Client exprime son accord exprès, ses données à caractère personnel ne sont pas utilisées à des fins publicitaires ou marketing.</p>
			<h3>9.5 Durée de conservation des données</h3>
			<p>Le Vendeur conservera les données ainsi recueillies pendant un délai de 5 ans, couvrant le temps de la prescription de la responsabilité civile contractuelle applicable.</p>
			<h3>9.6 Sécurité et confidentialité</h3>
			<p>Le Vendeur met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. Toutefois il est à signaler qu’internet n’est pas un environnement complètement sécurisé et le Vendeur ne peut garantir la sécurité de la transmission ou du stockage des informations sur Internet.</p>
			<h3>9.7 Mise en œuvre des droits des Clients et utilisateurs</h3>
			<p>En application de la règlementation applicable aux données à caractère personnel, les Clients et utilisateurs du site aestima-immo.com disposent des droits suivants :</p>
			<ul class="sm:ml-10 mb-6">
				<li>- Ils peuvent mettre à jour ou supprimer les données qui les concernent de la manière suivante :
					<ul class="sm:ml-10 mb-6">
						<li>○ En se connectant à « mon compte » dans l’onglet « paramètres » puis sur la page « mon profil », en cliquant sur les données à modifier ou en cliquant sur le bouton « supprimer définitivement mon compte » pour supprimer leur compte et leurs données personnelles.</li>
						<li>○ En écrivant directement à l’adresse électronique indiqué à l’article 8.3 « responsable de traitement ».</li>
					</ul>
				</li>
				<li>- Ils peuvent également solliciter la portabilité des données détenues par le Vendeur vers un autre prestataire.</li>
				<li>- Enfin, ils peuvent s’opposer au traitement de leurs données par le Vendeur.</li>
			</ul>
			<p>Le responsable de traitement doit apporter une réponse dans un délai maximum d’un mois. En cas de refus de faire droit à la demande du Client, celui-ci doit être motivé.</p>
			<p>Le Client est informé qu’en cas de refus, il peut introduire une réclamation auprès de la CNIL ou saisir une autorité judiciaire.</p>
					
			<h2>ARTICLE 10 – PROPRIÉTÉ INTELLECTUELLE</h2> 
			
			<p>Le contenu du site aestima-immo.com est la propriété du Vendeur et de ses partenaires et est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.</p>
			<p>Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefaçon.</p>

			<h2>ARTICLE 11 – DROIT APPLICABLE – LANGUE</h2> 
			
			<p>Les présentes CGV et les opérations qui en découlent sont régies et soumises au droit français.</p>
			<p>Les présentes CGV sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.</p>
			
			<h2>ARTICLE 12 – LITIGES</h2> 
			
			<p>Pour toute réclamation, Le Client peut contacter le service clientèle à l’adresse postale ou mail du Vendeur indiqués à l’ARTICLE 1 des présentes CGV.</p>
			<p>En cas d'échec de la demande de réclamation auprès du service client du Vendeur, ou en l'absence de réponse dans un délai de deux mois, le Client peut soumettre le différent à un médiateur qui tentera en toute indépendance de rapprocher les parties en vue d'obtenir une solution amiable. </p>
			<p>En l’espèce, le médiateur désigné est :</p>
			<p>Médiateur FEVAD</p>
			<p>Service du Médiateur du e-commerce de la FEVAD,</p>
			<p>60 Rue la Boétie,</p>
			<p>75008 PARIS</p>
			<p>www.mediateurfevad.fr </p>
			<p>E-mail : mediateurduecommerce@fevad.com.</p>
			<p>Tous les litiges auxquels les opérations d’achat et de vente conclues en application des présentes CGV et qui n’auraient pas fait l’objet d’un règlement amiable entre le vendeur ou par médiation, seront soumis aux tribunaux compétents dans les conditions de droit commun. </p>
		</div>

	<Footer/>
</div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
import { mapGetters, mapActions } from "vuex"

export default {
	name: 'CGV',
	data() {
		return {
			firstname: '',
			lastname: '',
			phone: '',
			email: '',
			work: 'testeur',
			message: '',
			isSend: false,
		}
	},
	components: {
		Footer,
		Header
	},
	metaInfo(){
		return {
			title: "Conditions générales de vente - Aestima",
			meta: [{
				name: 'description',
				content: "Cette page décrit les conditions générales de vente de l'estimateur immobilier Aestima."
			}]
			//keywords
		}
	},
	computed: {
		...mapGetters({
		}),
	},
	methods: {
		...mapActions({
			contact: 'users/contact',
		}),
		setSelectedWork(event) {
			this.work = event.target.value
		},
		async submit(){
			await this.contact({
				firstname: this.firstname,
				lastname: this.lastname,
				phone: this.phone,
				email: this.email,
				work: this.work,
				message: this.message
			})
			.then(() => {
				this.isSend = true
				setTimeout(() => {
					this.isSend = false
				}, 8000);
			})
		}
	},
}
</script>

<style scoped>
h2 {
	font-size: 1.4rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

h3 {
	font-size: 1.1rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

h4 {
	font-size: 1rem;
	font-weight: bold;
}

p {
	font-size: 1.2rem;
	font-weight: normal;
	margin-bottom: 1rem;
}

ul {
	margin-bottom: 1rem;
}

li {
	font-size: 1.1rem;
}
/* Medium (md) */
@media (min-width: 768px) {
	.h-header {
		height: 50rem;
	}

	.aestima-title-vw {
		font-size: 2.8vw !important;
	}

	.aestima-par-vw {
		font-size: 1.2vw !important;
	}
}

.shadow-inner {
	box-shadow: 0 15px 35px 0 rgba(60,66,87,.08), 0 5px 15px 0 rgba(0,0,0,.12);
}
</style>

