<template>
	<div>
		<Header/>
		<div class="grid grid-cols-11 gap-1 bg-darkblueaestima-500 relative flex flex-row justify-center items-start">
			<div class="relative text-center col-span-11 sm:col-start-4 sm:col-end-9 z-10 flex justify-center">
				<h1 class="s1-title font-normal text-white text-4xl md:text-6xl mb-4 aestima-title-vw leading-none text-center sm:text-left"> Comment calculer </br><b class="font-bold">la rentabilité locative nette </br>d’un bien locatif ?</b> </h1>			
				<!-- <p class="text-gray-300 font-medium text-2xl">Essayez la version gratuite</p>
				<p class="text-white font-medium text-2xl">durant 1 mois</p> -->
			</div>
			<!-- <a href="/presentation" class="col-span-11 sm:col-start-5 sm:col-end-8 uppercase text-sm font-semibold text-white px-12 py-6 bg-aestiblue-light rounded-full aestima-button mb-10">DEMARRER MON ESSAI GRATUIT</a>	 -->
		</div>
		
		<div class="mx-10 sm:mx-20 text-left mt-10 smooth">
			<div class="grid grid-cols-12 gap-1 relative flex flex-row justify-center items-start mb-6">
				<div class="hidden sticky top-16 sm:block sm:col-start-1 sm:col-end-3">
					<div class=" flex flex-col font-semibold">
						<a href="#introduction" class="my-2">Introduction</a>
						<a href="#rendement_brut" class="my-2">Rendement brut</a>
						<a href="#rendement_net_charge" class="my-2">Rendement net de charges</a>
						<a href="#rendement_net_emprunt" class="my-2">Rendement net après emprunt</a>
						<a href="#rendement_net_fisca" class="my-2">Rendement net après fiscalité</a>
						<a href="#conclusion" class="my-2">Conclusion</a>
					</div>
				</div>
				<div class="col-span-12 sm:col-start-4 sm:col-end-12 text-justify">
					<div class="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center mb-10">
						<div class="flex flex-row">
							<div class="rounded-full w-16 h-16 overflow-hidden flex justify-center items-center">
								<img class="w-20" src="../../../assets/julien_profil.png" alt="Profil Julien">
							</div>
							<div class="flex flex-col justify-center ml-6">
								<address class="not-italic font-bold text-lg">Julien</address> 
								<span class="text-sm">Mis à jour le <time pubdate datetime="2021-04-02" title="2 avril 2021">02 avril 2021</time></span>
							</div>
						</div>
						<div class="p-2 mt-6 sm:mt-0 bg-gray-100 rounded-full relative clipboard cursor-pointer" @click="clipboard()">
							<div>{{linkCopied}}</div>
							<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
							width="22" height="22"
							viewBox="0 0 172 172"
							style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#000000"><path d="M43,50.16667c-20.94817,0 -37.7007,17.97568 -35.66536,39.34668c1.77733,18.63333 18.48575,32.31999 37.20508,32.31999h19.96029c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667h-20.28223c-11.63867,0 -22.07378,-8.72072 -22.67578,-20.35222c-0.65217,-12.39833 9.20301,-22.64778 21.45801,-22.64778h21.5c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667zM107.5,50.16667c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h20.28223c11.63867,0 22.07378,8.72072 22.67578,20.35222c0.65217,12.39833 -9.20301,22.64778 -21.45801,22.64778h-21.5c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h21.5c20.94817,0 37.7007,-17.97568 35.66536,-39.34668c-1.77733,-18.63334 -18.48574,-32.31999 -37.20508,-32.31999zM57.33333,78.83333c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h57.33333c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667z"></path></g></g></svg>
						</div>
					</div>
					<p id="introduction">
						Avant toute chose, arrêtons-nous sur la distinction entre <b>rentabilité locative nette</b> et <b>rendement immobilier</b>. Le calcul du <b>rendement immobilier</b> se limite à une division plus ou moins complexe. En revanche, la <b>rentabilité locative nette</b> tient compte, en plus du poids des charges sur les revenus locatifs, des plus ou moins-values liées à la revente du <b>bien locatif</b>. Il nous semblait important de commencer par cette distinction que trop de personnes confondent encore.
					</p>
					</br>
					<p>
						Calculer un <b>rendement immobilier</b> net est complexe et prend en compte beaucoup de variables. Les résultats sont souvent peu précis et loin de la réalité. C’est justement ce que résout Aestima !
					</p>
					</br>
					<p>
						Mais voyons en détails comment calculer un <b>rendement immobilier</b> VRAIMENT net !
					</p>
					<p>
						On peut diviser le calcul en 4 grandes étapes, c’est parti !
					</p>
					<div class="flex flex-col items-center relative my-6 mb-16">
						<img class="w-2/3 flex justify-center items-center self-center" src="@/assets/article/rentabilite-locative-nette.png" title="calcul rentabilité locative nette" alt="calcul rentabilité locative nette">
						<div class="relative bottom-0 w-full h-10 bg-img-desc flex justify-start items-center pl-4 -m-10">
							<p class="text-xs sm:text-base">Le <b> calcul de la rentabilité locative nette </b> est-elle simple ou complexe ?</p>
						</div>
					</div>
					
					<div class="mt-20" id="rendement_brut" ref="rendement_brut">
						<div class="flex flex-col">
							<p class="text-2xl">Etape 1</p>
							<h2 class="font-bold text-2xl mb-6">Rendement immobilier brut</h2>
						</div>
						
						<p>
							Il s’agit ici de définir en quelques secondes un ratio qui vous permettra de comparer plusieurs <b>biens locatifs</b>, toutes les autres charges étant égales par ailleurs (<b>charges locatives</b>, fiscalité, etc.). Le calcul est donc très simple :
						</p>

						<div class="flex flex-row justify-center items-center my-10">
							<div class="flex flex-col justify-center items-center">
								<p class="text-sm sm:text-lg font-semibold italic">Loyers annuels</p>
								<span class="w-full h-1 bg-black"></span>
								<p class="text-sm sm:text-lg font-semibold italic">Prix d'achat du bien</p>
							</div>
							<span class="text-sm sm:text-lg font-semibold italic ml-3">x 100</span>
						</div>

						<div class="border p-4 my-8">
							<h3 class="text-xl font-bold mb-8">Exemple</h3>
							<p>Pour un appartement T2 à 150 000€ et dont le loyer mensuel est de 700€ :</p>
							<div class="flex flex-row justify-center items-center my-10">
								<div class="flex flex-col justify-center items-center">
									<p class="text-sm sm:text-lg font-semibold italic">8400€</p>
									<span class="w-full h-1 bg-black"></span>
									<p class="text-sm sm:text-lg font-semibold italic">150 000€</p>
								</div>
								<span class="text-sm sm:text-lg font-semibold italic ml-3">x 100 = 5,6%</span>
							</div>
						</div>
						
						<p>
							Cependant, il ne faut pas s’arrêter là pour connaître la réelle performance d’un <b>bien locatif</b>. 
						</p>
						</br>
						<p>
							On voit trop souvent des agents et promoteurs immobiliers communiquer sur ce <b>rendement immobilier</b>, à première vue, alléchant mais qui n’est pas objectif par rapport à la réalité. Vous le comprendrez en poursuivant votre lecture.
						</p>
						</br>
						<h3 class="text-xl font-bold text-lightblueaestima-100">Et mon outil Aestima, il m’apporte quoi là-dessus ? 🚀</h3>
						</br>
						<p class="text-lightblueaestima-100">Pour faire cette simple division, pas besoin de nous ! On commence à vous être utile dès la prochaine étape 😉</p>
					</div>
					
					<div class="mt-10" id="rendement_net_charge" ref="rendement_brut">
						<div class="flex flex-col">
							<p class="text-2xl">Etape 2</p>
							<h2 class="font-bold text-2xl mb-6">Rendement immobilier net de charges</h2>
						</div>
						
						<p>
							Pour avoir plus de précisions quant au réel <b>rendement immobilier</b> d’un <b>bien locatif</b> il faut lister et se procurer l’ensemble des <b>charges locatives</b> liées au bien immobilier ainsi que celles liées à l’acquisition.
						</p>
						</br>
						<p>
							Voyons à présent une liste (non exhaustive) de <b>charges locatives</b> à prendre en compte dans ce calcul. Pas de panique si vous n’avez pas les montants précis, on tiendra compte de prix moyens.
						</p>
						</br>
						<p>
							<u>Charges liées à l’acquisition du bien</u> : frais de notaires, honoraires d’agence immobilière ou de chasseur immobilier, etc.
						</p>
						</br>
						<p>
							<b><u>Charges locatives</u></b> : taxe foncière, assurance PNO (propriétaire non occupant), charges de copropriété, honoraires de gestion locative, etc.
						</p>
						</br>
						<p>
							On tiendra également compte des variations qui peuvent impacter les revenus locatifs (loyers) comme l’IRL (indice de revalorisation des loyers) ou encore la vacance locative (nombre de jours par an d’inoccupation d’un bien).
						</p>

						<div class="flex flex-row justify-center items-center my-10">
							<div class="flex flex-col justify-center items-center">
								<p class="text-sm sm:text-babase font-semibold italic">Loyers annuels - Taxe foncière - Charges de copropriété - Assurance PNO - Gestion locative</p>
								<span class="w-full h-1 bg-black"></span>
								<p class="text-sm sm:text-babase font-semibold italic">Prix d'achat du bien + frais de notaires + frais d'agences</p>
							</div>
							<span class="text-sm sm:text-babase font-semibold italic ml-3">x 100</span>
						</div>
						
						<div class="border p-4 my-8">
							<h3 class="text-xl font-bold mb-8">Exemple</h3>
							<p>On reprendra l’appartement T2 à 150 000€.</p>
							<p>Le coût d’acquisition de ce <b>bien locatif</b> comprend : </p>
							<p>7,5% de frais de notaire</p>
							<p>6% de frais d’agence (tous deux calculés sur le prix d’achat du <b>bien locatif</b>).</p>
							</br>
							<p>Pour les charges annuelles du <b>bien locatif</b> on tiendra compte de :</p>
							</br>
							<p>Taxe foncière = 700€</p>
							<p>10% des loyers annuels pour les charges de copropriété = 840€</p>
							<p>Pas de gestion locative</p>
							<p>Assurance PNO = 210€</p>
							<div class="flex flex-col sm:flex-row justify-center items-center">
								<div class="flex flex-row justify-center items-center my-10">
									<div class="flex flex-col justify-center items-center">
										<p class="text-sm sm:text-lg font-semibold italic">8400€ - 700€ - 840€ - 210€</p>
										<span class="w-full h-1 bg-black"></span>
										<p class="text-sm sm:text-lg font-semibold italic">150 000€ + 11 250€ + 9 000€</p>
									</div>
									<span class="text-sm sm:text-lg font-semibold italic ml-3">x 100</span>
								</div>
								<b class="mx-10"> Soit </b>
								<div class="flex flex-row justify-center items-center my-10">
									<div class="flex flex-col justify-center items-center">
										<p class="text-sm sm:text-lg font-semibold italic">6650€</p>
										<span class="w-full h-1 bg-black"></span>
										<p class="text-sm sm:text-lg font-semibold italic">170 250€</p>
									</div>
									<span class="text-sm sm:text-lg font-semibold italic ml-3">x 100 = 3,90%</span>
								</div>
							</div>
							
						</div>
						
						<p>On s’aperçoit rapidement de la différence entre ces deux premiers calculs. On passe de 5,6% de rendement à 3,9%, et ce n’est pas fini…</p>
						</br>
						<h3 class="text-xl font-bold text-lightblueaestima-100">Et mon outil Aestima, il m’apporte quoi là-dessus ? 🚀</h3>
						</br>
						<p class="text-lightblueaestima-100">
							Arrivé à cette étape, on tient compte de toutes les informations que vous aurez saisies et on calculera automatiquement, non pas le simple taux de rendement mais, le taux de <b>rentabilité locative nette</b> de charges, qui tiendra compte, vous l’aurez compris, de ce que vous aurez saisi pour la revente du <b>bien locatif</b>.
						</p>
					</div>
					
					<div class="flex justify-center my-20">
						<router-link target="_blank" to="/acces-simulateur" class="text-lg font-semibold text-white px-2 py-3 bg-aestiblue-light rounded-lg aestima-button sm:mt-0"><h3>Simuler gratuitement mon investissement</h3></router-link>
					</div>
					
					<div class="mt-10" id="rendement_net_emprunt" ref="rendement_net_emprunt">
						<div class="flex flex-col">
							<p class="text-2xl">Etape 3</p>
							<h2 class="font-bold text-2xl mb-6">Rendement net après emprunt</h2>
						</div>
						<p>
							Si vous arrivez jusqu’à cette précision de calcul, vous pouvez être fier de vous ! Selon notre sondage mené auprès de nos prospects, 83% des professionnels de l’immobilier s’arrêtent au calcul du <b>rendement immobilier</b> net de charges.
						</p>
						<p>
							Or, c’est bien lors du calcul du <b>rendement immobilier</b> net d’emprunt et de fiscalité que vous adapterez l’étude financière du bien à votre profil d’investisseur immobilier. 
						</p>
						</br>
						<p>
							Pour cela, pas besoin de tout recommencer. Vous pouvez partir de la base de votre précédent calcul. 
						</p>
						</br>
						<p>
							Vous aurez besoin de connaître, à présent, vos intérêts d’emprunt et le montant de votre assurance emprunteur.
						</p>
						
						<div class="flex flex-row justify-center items-center my-10">
							<div class="flex flex-col justify-center items-center">
								<p class="text-sm sm:text-base font-semibold italic">Loyers annuels net de charges - intérêts d'emprunt moyens - montant d'assurance emprunteur</p>
								<span class="w-full h-1 bg-black"></span>
								<p class="text-sm sm:text-base font-semibold italic">Prix d'achat du bien + frais de notaires + frais d'agences</p>
							</div>
							<span class="text-sm sm:text-base font-semibold italic ml-3">x 100</span>
						</div>
						
						<div class="border p-4 my-8">
							<h3 class="text-xl font-bold mb-8">Exemple</h3>
							<p>Sur la base du précédent exemple, on rajoutera 1065€ d’intérêts moyens annuels (et oui, les intérêts diminuent au fur et à mesure du remboursement de l’emprunt, il faut donc prendre une moyenne pour ce calcul) et 375€ / an de frais d’assurance emprunteur.</p>
							
							<div class="flex flex-row justify-center items-center my-10">
								<div class="flex flex-col justify-center items-center">
									<p class="text-sm sm:text-lg font-semibold italic">6650€ - 1065€ - 375€</p>
									<span class="w-full h-1 bg-black"></span>
									<p class="text-sm sm:text-lg font-semibold italic">170 250€</p>
								</div>
								<span class="text-sm sm:text-lg font-semibold italic ml-3">x 100 = 3,06%</span>
							</div>
							
						</div>
						
						<p>Pour une étude personnalisée des rendements, vous pouvez vous arrêter à cette étape, vous aurez déjà un ratio qui se rapproche de la réalité et tient compte de beaucoup d’éléments. Mais si vous êtes perfectionniste et voulez pousser la précision jusqu’à la fiscalité, rendez-vous à l’étape suivante.</p>
						</br>
						<h3 class="text-xl font-bold text-lightblueaestima-100">Et mon outil Aestima, il m’apporte quoi là-dessus ? 🚀</h3>
						</br>
						<p class="text-lightblueaestima-100">Toujours grâce aux informations saisies et en fonction du mode de financement de votre client investisseur, <b> Aestima calculera automatiquement le taux de rentabilité après emprunt. </b> Ainsi, pas de calculs interminables pour trouver les intérêts moyens payés, le montant d’assurance, etc. <b> Vous pourrez ainsi constater si, in fine, l’emprunt améliore ou non la performance de votre investissement.</b></p>
						
					</div>
					
					<div class="flex justify-center my-20">
						<router-link target="_blank" to="/acces-simulateur" class="text-lg font-semibold text-white px-2 py-3 bg-aestiblue-light rounded-lg aestima-button sm:mt-0"><h3>Simuler gratuitement mon investissement</h3></router-link>
					</div>
					
					<div class="mt-10" id="rendement_net_fisca" ref="rendement_net_fisca">
						<div class="flex flex-col">
							<p class="text-2xl">Etape 4</p>
							<h2 class="font-bold text-2xl mb-6">Rendement net après fiscalité</h2>
						</div>
						<p>
							Nous ne détaillerons pas le <b>rendement immobilier</b> net de fiscalité dans ce tutoriel car il tient compte de trop de paramètres.
						</p>
						</br>
						<p>
							Sachez juste que pour le calculer, vous devrez rajouter les impôts payés exclusivement sur cet investissement. 
						</p>
						</br>
						<p>
							Cela dépend du montage juridique (LMNP, NU, SCI, etc.), du taux marginal d’imposition, et par exemple de la date de revente du <b>bien locatif</b> (vente soumise à plus-value ou non).
						</p>
						
						<div class="border p-4 my-8">
							<h3 class="text-xl font-bold mb-8">Exemple</h3>
							<p>Pour simuler rapidement l’impact de la fiscalité sur notre exemple d’investissement, disons que vous louez votre bien en nu au réel.</p>
							</br>
							<p>Votre imposition sur ces revenus locatifs, tenant compte de l’impôt sur le revenu et des prélèvement sociaux, sera par exemple de 2000€ / an. </p>
							</br>
							<p>Ainsi le <b>rendement immobilier</b> net de fiscalité sur cet investissement sera donc de </p>
							
							<div class="flex flex-row justify-center items-center my-10">
								<div class="flex flex-col justify-center items-center">
									<p class="text-sm sm:text-lg font-semibold italic">5210€ - 2000€</p>
									<span class="w-full h-1 bg-black"></span>
									<p class="text-sm sm:text-lg font-semibold italic">170 250€</p>
								</div>
								<span class="text-sm sm:text-lg font-semibold italic ml-3">x 100 = 1,88%</span>
							</div>
							
						</div>
						
						<p>
							On part donc d’un <b>rendement immobilier</b> brut à 5,6% pour arriver, une fois tous les frais pris en compte, à un <b>rendement immobilier</b> net de fiscalité de 1,88%. Et cette précision change tout. Admettons que vous compariez votre investissement dans la pierre à un placement sur une assurance-vie à 2,5%. La plupart des gens investissent en connaissant uniquement le rendement brut, plutôt alléchant, de leur investissement. Ce genre de situation peut provoquer des scénarios catastrophiques où ces personnes se voient obliger de revendre leur bien en urgence car leur effort d’épargne mensuel, non anticipé, est insoutenable.
						</p>
						</br>
						<h3 class="text-xl font-bold text-lightblueaestima-100">Et mon outil Aestima, il m’apporte quoi là-dessus ? 🚀</h3>
						</br>
						<p class="text-lightblueaestima-100">Bien entendu, l’indicateur le plus détaillé est celui-ci et chez Aestima, nous voulons le rendre ULTRA précis. À cette étape, nous vous fournissons 3 informations distinctes :</p>
						<ul class="text-lightblueaestima-100 round-tiret mb-10 pl-10 mt-4">
							<li>
								<b> Le taux de rendement net de fiscalité année après année  </b> précis (ce taux évolue selon les variations de loyers, de charges et d’imposition).
							</li>
							<li class="my-2">
								<b> Le taux de rentabilité net de fiscalité total </b> de l’investissement.
							</li>
							<li>
								<b> <router-link to="/lexique"><u>Le taux de rendement interne</u></router-link> (TRI) de l’investissement </b> qui tient compte de l’actualisation des flux de trésorerie (indicateur financier).</b>
							</li>
						</ul>
						
						<p id='conclusion'> Mais avec Aestima on ne s’arrête pas au calcul du <b>rendement immobilier</b> et de la rentabilité nette !
						</br> On vous permet également, entre autres, de :
						</p>
						<ul class="round-tiret-check mb-10 pl-16 mt-4">
							<li> Définir votre effort d’épargne année après année avec un tableau de trésorerie complet. </li>
							<li class="my-2"> Établir votre tableau d’amortissement d’emprunt. </li>
							<li> Déterminer le profit total généré par l’investissement. </li>
							<li class="my-2"> Calculer l’impôt sur la plus-value de revente. </li>
							<li> Comparer les performances entre différents montages juridiques. </li>
						</ul>
						</br></br>
						<p> Si vous voulez être épaulé sur ces étapes ou tout simplement ne pas vous prendre la tête dans toute cette mécanique financière, utilisez dès maintenant Aestima !
						</p>
						
					</div>
					
					<div class="flex justify-center my-20">
						<router-link target="_blank" to="/acces-simulateur" class="text-lg font-semibold text-white px-2 py-3 bg-aestiblue-light rounded-lg aestima-button sm:mt-0"><h3>Créer gratuitement mon compte Aestima</h3></router-link>
					</div>
					
				</div>
			</div>
				


		</div>

	<Footer/>
</div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
export default {
	name: 'Rent',
	data() {
		return {
			posBoxB: 0,
			posBox: 0,
			linkCopied: 'copier le lien',
		}
	},
	components: {
		Footer,
		Header
	},
	created() {
		window.addEventListener('scroll', this.calcPosOfBox());
    	
  	},
	destroy() {
		window.addEventListener('scroll', this.calcPosOfBox());
	},
	computed: {

	},
	methods: {
		calcPosOfBox() {
			const rendement_brut = document.getElementById("rendement_brut");
			// const rendement_b = this.$refs["rendement_b"].getBoundingClientRect();
			// this.posBoxB = rendement_brut.y;
			// this.posBox = rendement_b.y;
			// console.log(this.posBoxB)
			// console.log(this.posBox)
			// console.log("window.scrollY", window.scrollY)

		},
		clipboard() {
			// var copyText = window.location.href
			const copyText = document.createElement('textarea');
  			copyText.value = window.location.href;
			document.body.appendChild(copyText);
			copyText.select();
  			copyText.setSelectionRange(0, 99999);
			document.execCommand("copy");
			document.body.removeChild(copyText);
			this.linkCopied = "Lien copié"
		},
	},
	metaInfo(){
		return {
			title: "Calculer la rentabilité locative nette en 4 étapes - Aestima.",
			meta: [{
				name: 'description',
				content: "Découvrez comment réaliser le calcul de la rentabilité locative nette avec précision et soyez averti face aux études de rentabilité incomplètes."
			}]
			//keywords
		}
	},
}
</script>

<style scoped>

.clipboard div{
	padding: 0.3rem 0.6rem 0.3rem 0.6rem;
	background: #0C1E3B;
	border-radius: 5px;
	color: white;
	font-size: 0.8rem;
	position: absolute;
	white-space: nowrap;
	top: 0;
	transition: 0.2s;
	transform: translateX(0);
	opacity: 0;
}

.clipboard:hover div {
	opacity: 1;
	transform: translateX(3rem);
}

.h-header {
	height: 100%;
}
.aestima-button {
	transition: 0.5s;
	background-color: #14A0EB;
}
/* 
video {
	position: relative;
	width: 100% !important;
	height: auto;
} */

.aestima-button:hover {
	background-color: #ffffff;
	border: 2px solid #14A0EB;
	color: #14A0EB;
	transform: scale(1.1);
}

.aestima-title-vw {
}

.aestima-par-vw {
}
/* Medium (md) */
@media (min-width: 768px) {
	.h-header {
		height: 50rem;
	}

	.aestima-title-vw {
		font-size: 2.8vw !important;
	}

	.aestima-par-vw {
		font-size: 1.2vw !important;
	}
}

.shadow-inner {
	box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1),0 10px 10px -5px rgba(0,0,0,0.04);
}

/** SWITCH */

.switch {
  position: relative;
  display: inline-block;
  width: 10rem;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0C1E3B;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 0.2rem;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 0.2rem;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(7.8rem);
  -ms-transform: translateX(7.8rem);
  transform: translateX(7.8rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.smooth {
	scroll-behavior: smooth;
}

.bg-img-desc {
	background-color: rgba(20, 160, 235,0.6);
}

.round-tiret li {
	list-style: inside;
}

.round-tiret-check li {
	list-style: url('../../../assets/picto/aestima-tiret.png');
}

</style>

