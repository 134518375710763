import axios from 'axios'
import router from '../router/index.js'

export default {
  namespaced: true,
  state: {
    invests_list: [],
    invests_globals: [],
    invest_modal: false,
    invest_modalPDF: false,
    invest_modalPDF_loader: false,
    invest_message: '',
    invest_selected: null,
  },
  getters: {
    invests(state) {
      return state.invests_list
    },
    investsList(state) {
      return state.invests_globals
    },
    selected(state) {
      return state.invest_selected
    },
    modalPDF(state) {
      return state.invest_modalPDF
    },
    modalPDFLoader(state) {
      return state.invest_modalPDF_loader
    },
  },
  mutations: {
    SET_INVESTS_LIST(state, inv) {
      state.invests_list = inv
    },
    SET_INVESTS_GLOBALS(state, inv) {
      state.invests_globals = inv
    },
    SET_INVEST_SELECTED(state, invest) {
      state.invest_selected = invest
    },
    SET_INVEST_SELECTED_BY_INDEX(state, index) {
      state.invest_selected = state.invests_list[index]
    },
    SET_MODAL_PDF(state, boolean) {
      state.invest_modalPDF = boolean
    },
    SET_MODAL_PDF_LOADER(state, boolean) {
      state.invest_modalPDF_loader = boolean
    },
    UNSET_STATE(state) {
      state.invests_list = []
      state.invest_modal = false
      state.invest_modalPDF = false
      state.invest_modalPDF_loader = false
      state.invest_message = ''
      state.invest_selected = {}
    },
  },
  actions: {
    async getInvestsList({ commit, getters }, id) {
      await axios
        .get('/invests', {
          params: {
            idUser: id,
          },
        })
        .then((response) => {
          commit('SET_INVESTS_GLOBALS', response.data.inv)
          commit('SET_INVESTS_LIST', response.data.inv)
          if (response.data.inv.length > 0) {
            if (getters.selected !== null && Object.keys(getters.selected).length === 0) {
              response.data.inv.map((val, index) => {
                if (val.archive.isArchived === false) {
                  return commit('SET_INVEST_SELECTED', response.data.inv[index])
                }
              })
            }
          }
        })
        .catch((e) => {
          throw e
        })
    },

    async getInvests({ commit }, id) {
      await axios
        .get(`/invest/${id}`)
        .then((response) => {
          commit('SET_INVESTS_LIST', response.data.list)
        })
        .catch((e) => {
          throw e
        })
    },

    async updateOwner({ state, commit, dispatch }, { investId, userId, owner }) {
      if (!investId || !userId || !owner) return
      await axios
        .patch(`/invest/${investId}/owner`, owner)
        .then(async () => {
          // await dispatch('getInvests', investId)
          // await dispatch('getInvestsList', userId)
          const updatedInvestor = state.invests_list.find(invest => invest.id === investId)
          if (!updatedInvestor) return
          commit('SET_INVEST_SELECTED', updatedInvestor)
        })
        .catch((e) => {
          throw e
        })
    },

    setInvestSelected({ dispatch, commit }, { inv, index }) {
      if (inv != null) inv.index = index
      commit('SET_INVEST_SELECTED', inv)
    },

    async setInvest({ dispatch, commit, rootGetters }, { idUser, body, formData, coFormData }) {
      let idInvest
      await axios
        .post(`/invest/${idUser}`, body)
        .then((response) => {
          idInvest = response.data.id
          commit('projects/SET_MODAL', response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 3000)

          dispatch('getInvestsList', rootGetters['users/user']._id)

          commit('SET_INVEST_SELECTED', response.data.inv)
          router.push('/MesDossiers')
        })
        .catch((e) => {
          throw e
        })
      if (formData) await axios.post(`/invest/image/${idInvest}`, formData)
      if (coFormData) await axios.post(`/invest/coimage/${idInvest}`, coFormData)
    },

    async setInvestNote({ dispatch, commit, rootGetters }, { idInvest, note }) {
      let notes = []

      await axios
        .post(`/invest/note/${idInvest}`, note)
        .then((response) => {
          notes = response.data.notes
        })
        .catch((e) => {
          throw e
        })
      return notes
    },

    async removeInvestNote({ dispatch, commit, rootGetters }, { idInvest, note }) {
      let notes = []
      await axios
        .post(`/invest/note_remove/${idInvest}`, note)
        .then((response) => {
          notes = response.data.notes
        })
        .catch((e) => {
          throw e
        })
      return notes
    },

    async setInvestImg({ dispatch, commit, rootGetters }, { id, formData }) {
      // ////console.log("=======>",rootGetters['users/user']._id)
      await axios
        .post(`/invest/image/${id}`, formData)
        .then((response) => {
          commit('projects/SET_MODAL', response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 3000)

          dispatch('getInvestsList', rootGetters['users/user']._id)
          router.push('/MesDossiers')

          // router.push("/Home")
        })
        .catch((e) => {
          throw e
        })
    },

    async archiveInvest({ dispatch, commit, rootGetters }, { idInvest, boolean }) {
      const body = {
        boolean,
      }
      // console.log(idInvest)
      await axios
        .patch(`/invest/${idInvest}/archive`, body)
        .then((response) => {
          commit('projects/SET_MODAL', response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 3000)

          dispatch('getInvestsList', rootGetters['users/user']._id)
          commit('SET_INVEST_SELECTED', [])
        })
        .catch((e) => {
          throw e
          /// /console.log(e.response.data)
        })
    },

    async updateArchive({ dispatch, commit }, { id, boolean }) {
      const body = {
        boolean,
      }
      await axios
        .patch(`/invest/${id}/archive`, body)
        .then((response) => {
          // console.log("/invest/archive response", response)
          // commit('projects/SET_MODAL', response.data.message, { root: true })
          // setTimeout(() => {
          // 	commit('projects/UNSET_MODAL', null, { root: true })
          // }, 3000);
          // // commit('SET_INVEST_SELECTED', response.data.inv)
          // dispatch('projects/getProjects', idProj, { root: true })
          // //Après avoir enregistré les neo résultats dans l'investisseur
          // //On refresh la list d'investisseur
          // dispatch('getInvests', id)
          // //Puis, on remplit la liste de l'investisseur selectionné avec l'object créé
          // commit('SET_INVEST_SELECTED', response.data.inv)
          // //On fait une redirection vers la page des résultats
          // router.push("/Investisseur/Resultats")
        })
        .catch((e) => {
          throw e
        })
    },

    async updateInvest({ dispatch, commit, rootGetters, getters }, { id, formData, coFormData, body, push }) {
      await axios
        .patch(`/invest/${id}`, body)
        .then((response) => {
          commit('projects/SET_MODAL', response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 3000)

          dispatch('getInvestsList', rootGetters['users/user']._id)
          commit('SET_INVEST_SELECTED', response.data.inv)
        })
        .then(() => {
          if (formData) axios.post(`/invest/image/${id}`, formData)
          if (coFormData) axios.post(`/invest/coimage/${id}`, coFormData)
        })
        .then(() => {
          if (push) router.push('/MesDossiers')
        })
        .catch((e) => {
          throw e
        })
    },

    setModalPDF({ commit }, boolean) {
      commit('SET_MODAL_PDF', boolean)
    },

    setModalPDFLoader({ commit }, boolean) {
      commit('SET_MODAL_PDF_LOADER', boolean)
    },

    async deleteInvest({ dispatch, commit, rootGetters }, { idInvest }) {
      await axios
        .delete(`/invest/delete/${idInvest}`)
        .then((response) => {
          dispatch('getInvestsList', rootGetters['users/user']._id)
          dispatch('projects/getProjectsSummary', rootGetters['users/user']._id, { root: true })

          commit('projects/SET_MODAL', response.data.message, { root: true })
          setTimeout(() => {
            commit('projects/UNSET_MODAL', null, { root: true })
          }, 3000)

          commit('SET_INVEST_SELECTED', [])
          // commit("SET_INVESTS_LIST", response.data.list)
        })
        .catch((e) => {
          throw e
        })
    },

    async exportPDF({ commit }, data) {
      const params = {
        datas: data,
      }
      return axios.get('/export/pdf', {
        params,
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf',
          'Access-Control-Allow-Origin': '*',
        },
      })
    },
  },
}
