<template>
  <div class="w-full overflow-hidden relative bg-white shadow-inner">
    <div class=" py-5 px-10 gap-10 flex flex-col">
      <div class="flex flex-col gap-5">
        <!-- bandeau -->
        <div class="flex titles">
          Dossier du client :
        </div>
        <div class="flex justify-between gap-5">
          <PDropdown
            v-model="selectedInvestor" :options="investsList" option-label="name"
            placeholder="Choisissez l'investisseur" class="group flex place-items-center h-14"
          >
            <template #value="slotProps">
              <div v-if="slotProps" class="text-white font-bold text-sm group-hover:text-lightblueaestima-100">
                <SelectInvestor :investor="slotProps.value" class="text-base font-bold" />
              </div>
              <div v-else class="text-white font-bold text-sm group-hover:text-lightblueaestima-100">
                {{ slotProps.placeholder }}
              </div>
            </template>
            <template #option="slotProps">
              <div class="flex">
                <SelectInvestor :investor="slotProps.option" class="w-full" @click="changeSelectedInvestor(slotProps.option, 1)" />
              </div>
            </template>
          </PDropdown>

          <div class="flex gap-3">
            <button class="button-blue text-xs  w-40 flex justify-between items-center" @click="displayModaleNote = true">
              <img src="https://api.iconify.design/material-symbols:note-add.svg" class="h-6 w-6">
              <div>
                <div v-if="selectedInvestor.notes.length !== 0">
                  Consulter ou créer
                </div>
                <div v-else>
                  Créer
                </div>
                <b>une note</b>
              </div>
              <div
                v-if="selectedInvestor.notes.length !== 0"
                class="bg-white shadow-inner p-1 rounded-full text-black font-bold"
              >
                {{ selectedInvestor.notes.length }}
              </div>
            </button>
            <button class="button-blue text-xs " @click="updateInvest">
              Modifier les informations du client
            </button>
            <button
              class="border-2 border-red-400 rounded-xl text-xs font-bold text-red-400 hover:bg-red-400 hover:text-white whitespace-no-wrap px-2 py-1"
              @click="engageDeleteInvestor"
            >
              Supprimer le client
            </button>
          </div>
        </div>
      </div>
      <!-- Informations -->
      <div class="flex justify-between gap-5">
        <div class="encart">
          <img
            v-if="selectedInvestor.img" :src="selectedInvestor.img.data"
            class="h-20 w-20 object-cover place-self-center rounded-full" alt="img_utilisateur"
          >
          <img
            v-else-if="selectedInvestor.gender === 'homme'" src="../assets/picto/homme.png"
            class="h-20 w-20 object-cover place-self-center rounded-full" alt="img_homme"
          >
          <img
            v-else-if="selectedInvestor.gender === 'femme'" src="../assets/picto/femme.png"
            class="h-20 w-20 object-cover place-self-center rounded-full" alt="img_femme"
          >
          <img
            v-else src="../assets/picto/societe.png" class="h-20 w-20 object-cover place-self-center rounded-full"
            alt="img_societe"
          >
          <div class="subEncarts">
            <div class="subtitles">
              Coordonnées du client
            </div>
            <div>
              <div class="flex place-items-center gap-5">
                <img src="https://api.iconify.design/bi:person-fill.svg" class="h-4">
                <div>{{ selectedInvestor.name }} {{ selectedInvestor.firstname }}</div>
              </div>
              <div v-if="selectedInvestor.phone" class="flex place-items-center gap-5">
                <img src="https://api.iconify.design/mingcute:phone-fill.svg" class="h-4">
                <div>{{ selectedInvestor.phone }}</div>
              </div>
              <div v-if="selectedInvestor.mail" class="flex place-items-center gap-5">
                <img src="https://api.iconify.design/mingcute:mail-line.svg" class="h-4">
                <div>{{ selectedInvestor.mail }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="encart">
          <div class="subEncarts">
            <div class="subtitles">
              Situation fiscale
            </div>
            <div>
              <div v-if="selectedInvestor.fiscalite" class="flex w-full justify-between gap-5">
                <div class="text-left">
                  Situation maritale
                </div>
                <div class="font-bold">
                  {{ selectedInvestor.fiscalite[0].situation.substring(0, 1).toUpperCase()
                    + selectedInvestor.fiscalite[0].situation.substring(1) }}
                </div>
              </div>
              <div class="flex w-full justify-between gap-5">
                <div class="text-left">
                  Nombre de parts fiscales
                </div>
                <div class="font-bold">
                  {{ selectedInvestor.advanced_info.personals.partsFiscalesTot }}
                </div>
              </div>
              <div v-if="selectedInvestor.emprunt.emprunt_rev_tot" class="flex w-full justify-between gap-5">
                <div class="text-left">
                  Revenu net imposable
                </div>
                <div class="font-bold">
                  {{ selectedInvestor.emprunt.emprunt_rev_tot }} €
                </div>
              </div>
              <div class="flex w-full justify-between gap-5">
                <div class="text-left">
                  Tranche marginale d'imposition
                </div>
                <div class="font-bold">
                  {{ selectedInvestor.tmi }} %
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="encart">
          <div class="subEncarts">
            <div class="subtitles">
              Critères de recherches
            </div>
            <div class="flex flex-col gap-1">
              <div class="flex w-full justify-between gap-5">
                <div class="text-left">
                  Objectifs d'investissement
                </div>
                <div class="font-bold">
                  {{ selectedInvestor.strategy.typestrategy.substring(0, 1).toUpperCase()
                    + selectedInvestor.strategy.typestrategy.substring(1) }}
                </div>
              </div>
              <div v-if="selectedInvestor.search.search_budget" class="flex w-full justify-between gap-5">
                <div class="text-left">
                  Budget maximum
                </div>
                <div class="font-bold">
                  {{ selectedInvestor.search.search_budget }} €
                </div>
              </div>
              <div v-if="selectedInvestor.search.search_pref" class="flex w-full justify-between gap-5">
                <div class="text-left">
                  Zone de recherche
                </div>
                <div class="font-bold">
                  {{ selectedInvestor.search.search_pref }}
                </div>
              </div>
              <div v-if="selectedInvestor.search.search_type" class="flex w-full justify-between gap-5">
                <div class="text-left">
                  Catégorie de bien
                </div>
                <div class="font-bold">
                  {{ selectedInvestor.search.search_type.substring(0, 1).toUpperCase()
                    + selectedInvestor.search.search_type.substring(1).replace('_', ' ') }}
                </div>
              </div>
              <div v-if="selectedInvestor.search.search_surface" class="flex w-full justify-between gap-5">
                <div class="text-left">
                  Surface minimale
                </div>
                <div class="font-bold">
                  {{ selectedInvestor.search.search_surface }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full gap-5 flex flex-col">
        <!-- Controlers -->
        <div class="flex gap-5 flex-col">
          <div class="titles text-left">
            Simulations realisées par le client
          </div>
          <div class="flex gap-5 items-center justify-between bg-gradient-to-tr from-blue-200 to-blue-50 p-3 rounded-xl">
            <div class="flex gap-3">
              <div id="sort-buttons" class="flex gap-1 place-items-center">
                <img
                  :src="sortAZIcon" class="h-10 cursor-pointer p-1"
                  :class="(sortBy === 'name' || sortBy === 'nameReversed') ? 'filter-selected' : ''"
                  @click="sortBy === 'name' ? sortBy = 'nameReversed' : sortBy = 'name'"
                >
                <img
                  :src="sortUpdatedIcon" class="h-10 cursor-pointer p-1"
                  :class="(sortBy === 'created' || sortBy === 'createdReversed') ? 'filter-selected' : ''"
                  @click="sortBy === 'createdReversed' ? sortBy = 'created' : sortBy = 'createdReversed'"
                >
              </div>
              <div id="search-bar" class="bg-white flex place-items-center rounded-xl text-sm h-12">
                <img src="https://api.iconify.design/mingcute:search-line.svg" class="pl-2 h-8">
                <input v-model="filterValue" type="text" placeholder="Rechercher" class="pl-2 mx-2 w-44 rounded-lg h-8">
              </div>
            </div>
            <div class="flex gap-5 items-center">
              <div
                class="flex items-center wrapper h-11"
                :class="comparisonAvailable ? 'button-blue text-sm cursor-pointer' : 'bg-gray-400 border-2 border-gray-400 text-white px-2 py-1 rounded-xl text-sm font-bold shadow-md cursor-not-allowed'"
                @click="setComparisonSelected()"
              >
                Comparer les simulations
                <div v-if="user.sub_level === 0" class="tooltip">
                  <u><router-link to="Abonnement">Faites évoluer votre
                    abonnement</router-link></u> pour comparer vos simulations
                </div>
                <div
                  v-if="(user.sub_level === 1 || user.sub_level === 2) && (selectedProjects.length < 2 || selectedProjects.length > 4)"
                  class="tooltip"
                >
                  Cochez entre 2 et 4 simulations pour les comparer
                </div>
              </div>
              <div>
                <div v-if="actionAvailable" class="flex" @click="displayAction()">
                  <PDropdown :options="actions" option-label="name" placeholder="Action sur la sélection" class="group">
                    <template #value="slotProps">
                      <div class="text-white font-bold text-sm group-hover:text-lightblueaestima-100">
                        {{ slotProps.placeholder }}
                      </div>
                    </template>
                    <template #option="slotProps">
                      <div class="flex" @click="engageAction(slotProps.option.action)">
                        <span class="my-auto rounded-md px-1 py-0.5">{{ slotProps.option.name }}</span>
                      </div>
                    </template>
                  </PDropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Simulations -->
        <div class="flex flex-col gap-2">
          <div class="line bg-gray-200 font-bold shadow-md">
            <input type="checkbox" @change="selectAll($event)">
            <div>
              Nom du projet
            </div>
            <div>
              date
            </div>
            <div>
              Adresse
            </div>
            <div>
              Exploitation
            </div>
            <div>
              Fiscalité
            </div>
            <div />
          </div>
          <div
            v-for="(project, index) in filteredList" :key="project._id"
            class="group shadow-md rounded-xl scale101 cursor-pointer" @click="toggleDisplay(index)"
          >
            <div class="line cursor-zoom-in group-hover:bg-blue-100">
              <input v-model="selectedProjects" type="checkbox" :value="project">
              <div class="font-bold">
                {{ project.name }}
              </div>
              <div>
                {{ project.created.split("T")[0].split("-")[2] }}/{{ project.created.split("T")[0].split("-")[1] }}/{{
                  project.created.split("T")[0].split("-")[0] }}
              </div>
              <div>
                {{ project.loc.carac.name }}, {{ project.loc.carac.city }}
              </div>
              <div>
                {{ translateDispositifs(project.carac.typeexploitation) }}
              </div>
              <div class="truncate">
                {{ getFiscalite(project.carac.formejuridique) }}...
              </div>
              <div>
                <button class="text-xl hover:bg-white p-1 rounded-full" @click="resultProject(project)">
                  <img src="https://api.iconify.design/mdi/eye.svg?color=%2376a9fa" class="h-6">
                </button>
                <button class="text-xl hover:bg-white p-1 rounded-full" @click="editProject(project)">
                  <img src="https://api.iconify.design/fluent/edit-20-filled.svg?color=%2331c48d" class="h-6">
                </button>
                <button
                  class="text-xl hover:bg-white p-1 rounded-full"
                  @click="selectThenEngage('deleteProject', project)"
                >
                  <img src="https://api.iconify.design/material-symbols/delete.svg?color=%23f98080" class="h-6">
                </button>
              </div>
            </div>
            <div v-if="toggledItem === index" class="grid grid-cols-3 text-xs py-4">
              <div class="hoveredEncarts">
                <div class="flex justify-between">
                  <div>Prix d'achat</div>
                  <div class="font-bold">
                    {{ project.carac.buyprice.toLocaleString('FR-fr') }} €
                  </div>
                </div>
                <div class="flex justify-between">
                  <div>Frais d'achat</div>
                  <div class="font-bold">
                    {{ fraisAcquisitionBien(project).toLocaleString('FR-fr') }} €
                  </div>
                </div>
                <div class="flex justify-between">
                  <div>Montant des travaux</div>
                  <div class="font-bold">
                    {{ project.carac.trvametot.toLocaleString('FR-fr') }} €
                  </div>
                </div>
              </div>
              <div class="hoveredEncarts">
                <div class="flex justify-between">
                  <div>Loyer mensuel</div>
                  <div class="font-bold">
                    {{ project.carac.loyermensuel }} €
                  </div>
                </div>
                <div class="flex justify-between">
                  <div>Charges mensuelles</div>
                  <div class="font-bold">
                    {{ Math.round(project.carac.totalOPEX.val / 12) }} €
                  </div>
                </div>
                <div class="flex justify-between">
                  <div>Prix de revente</div>
                  <div class="font-bold">
                    {{ project.carac.estimprixrevente.toLocaleString('FR-fr') }} €
                  </div>
                </div>
              </div>
              <div class="hoveredEncarts">
                <div class="flex justify-between">
                  <div>Rentabilité nette</div>
                  <div class="font-bold">
                    {{ project.results.resFluxAIFonc.performance.avecFisc.tir === 0 ?
                    "Infini"
                    : project.results.resFluxAIFonc.performance.avecFisc.tir + "%" }}
                  </div>
                </div>
                <div class="flex justify-between">
                  <div>Cash-Flow mensuel</div>
                  <div class="font-bold">
                    {{ cashFlowComputed(project)[0] }} €
                  </div>
                </div>
                <div class="flex justify-between">
                  <div>Profit total</div>
                  <div class="font-bold">
                    {{ Math.round(project.results.resFluxAIFonc.resTotCashFlowNonActu).toLocaleString('FR-fr') }} €
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="group wrapper flex px-1 py-2 rounded-xl justify-center items-center shadow-md text-lightblueaestima-100  cursor-pointer hover:text-white font-bold gap-3"
            :class="subRestriction ? 'cursor-not-allowed hover:bg-gray-400' : 'hover:bg-lightblueaestima-100'"
            @click="subRestriction ? null : toNewSimulation()"
          >
            <p class="text-2xl  border border-lightblueaestima-100 group-hover:border-white rounded-full h-10 w-10">
              +
            </p>
            <div>
              Créer une nouvelle simulation
              <div v-if="subRestriction" class="tooltip">
                <u><router-link to="Abonnement">Faites évoluer votre abonnement</router-link></u>
                pour créer de nouvelles simulations
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modale -->
    <div v-if="modale.display" class="w-full h-full top-0">
      <div class="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50" />
      <div
        class="fixed flex flex-col gap-5 left-1/2 top-1/2 -translate-x-1/2 transform -translate-y-1/2 bg-white px-7 py-5 rounded-xl shadow-md w-96"
      >
        <div class="font-bold text-md">
          {{ modale.title }}
        </div>
        <div class="text-sm">
          {{ modale.contenu }}
        </div>
        <div>
          <PPassword v-if="displayInputLockPassword" toggleMask inputClass="w-80" :feedback="false" v-model="inputLockPassword" class="mt-2" @keypress.enter="proceedAction(modale.action)"/>
        </div>
        <button
          :class="modale.color === 'red' ? 'button-red' : 'button-blue text-xs'"
          @click="proceedAction(modale.action)"
        >
          {{ modale.bouton }}
        </button>
        <button class="button-blue text-xs" @click="modale.display = false">
          Annuler
        </button>
      </div>
    </div>
    <NotesInvestisseur v-if="displayModaleNote" :investisseur="selectedInvestor" @close="displayModaleNote = false" />
    <PToast />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SelectInvestor from '@/components/SelectInvestor.vue'
import NotesInvestisseur from '@/components/popup/NotesInvestisseur'
import axios from 'axios' 

export default {
  name: 'MesDossiers',
  metaInfo() {
    return {
      title: 'Mes biens locatifs - Aestima',
      meta: [{
        name: 'description',
        content: 'Cette page liste les biens locatifs de l\'utilisateur.',
      }],
      // keywords
    }
  },
  components: {
    SelectInvestor,
    NotesInvestisseur,
  },
  data() {
    return {
      displaySelector: false,
      displayActions: false,
      toggledItem: null,
      sortBy: '',
      filterValue: '',
      modale: {
        display: false,
        title: 'TITRE',
        contenu: 'CONTENU',
        bouton: 'BOUTON',
        action: '',
        color: 'blue',
      },
      selectedProjects: [],
      displayModaleNote: false,
      actions: [
        {
          name: 'Supprimer',
          action: 'deleteProject',
        },
        {
          name: 'Dupliquer',
          action: 'duplicate',
        },
      ],
      inputLockPassword: '',
      displayInputLockPassword : false,
      locked: false,
    }
  },
  created() {
    if(this.user.coworkerLimit > 0) this.locked = true
  },
  computed: {
    ...mapGetters({
      listProj: 'projects/projects',
      listInv: 'invests/invests',
      invests: 'invests/invests',
      user: 'users/user',
    }),
    // a setter for selectedInvestor
    selectedInvestor: {
      get() {
        return this.$store.getters['invests/selected']
      },
      set(value) {
        this.changeSelectedInvestor(value, 1)
      },
    },
    investsList() {
      const listInvestisseurs = this.listInv.filter((val) => {
        return val.archive.isArchived === false
      })
      if (listInvestisseurs?.length === 0) this.$router.push('/Home')
      return listInvestisseurs
    },
    projectsOfInvest() {
      const projects = []
      let investSelected = null

      if (Object.keys(this.selectedInvestor).length > 0) {
        investSelected = this.invests.find(invest => invest._id === this.selectedInvestor._id)
      } else if (this.invests.length > 0) {
        investSelected = this.invests.find(invest => invest._id === this.invests[0]._id)
      }

      if (investSelected) {
        investSelected.projects.forEach((proj) => {
          const project = this.listProj.find(project => project._id === proj)
          if (project) projects.push(project)
        })
      }

      return this.sort(projects)
    },
    filteredList() {
      return this.projectsOfInvest.filter((project) => {
        return (
          project.name.toLowerCase().includes(this.filterValue.toLowerCase())
          || project.loc.carac.name.toLowerCase().includes(this.filterValue.toLowerCase())
          || project.loc.carac.city.toLowerCase().includes(this.filterValue.toLowerCase())
          || this.translateDispositifs(project.carac.typeexploitation).toLowerCase().includes(this.filterValue.toLowerCase())
          || this.getFiscalite(project.carac.formejuridique).toLowerCase().includes(this.filterValue.toLowerCase())
        )
      })
    },
    comparisonAvailable() {
      return (this.selectedProjects.length > 1 && this.selectedProjects.length < 5 && this.user.sub_level !== 0)
    },
    actionAvailable() {
      return this.selectedProjects.length > 0
    },
    sortAZIcon() {
      return (this.sortBy === 'name') ? 'https://api.iconify.design/bx:sort-a-z.svg' : 'https://api.iconify.design/bx:sort-z-a.svg'
    },
    sortUpdatedIcon() {
      return (this.sortBy === 'createdReversed') ? 'https://api.iconify.design/mdi:sort-date-descending.svg' : 'https://api.iconify.design/mdi:sort-date-ascending.svg'
    },
    subRestriction() {
      return (
        (this.user.sub_level === 0 && this.projectsOfInvest.length > 1)
        || (this.user.sub_level === 1 && this.projectsOfInvest.length > 2)
      )
    },
  },
  methods: ({
    ...mapActions({
      setInvestSelected: 'invests/setInvestSelected',
      deleteInvest: 'invests/deleteInvest',
      setProjectComparison: 'projects/setProjectComparison',
      getProject: 'projects/getProject',
      deleteProject: 'projects/deleteProject',
      duplicateProject: 'projects/duplicateProject',
      setProjectSelected: 'projects/setProjectSelected',
    }),
    async checkLockPassword() {
      try {
        await axios.patch(`/user/lock_password/validate/${this.user._id}`, { inputLockPassword: this.inputLockPassword })
        this.inputLockPassword = ''
        this.deleteInvestor()
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Erreur', detail: "Votre mot de passe est incorrect", life: 3000 })
        this.inputLockPassword = ''
      }
    },
    updateInvest() {
      this.$router.replace({
        name: 'Investor',
      })
    },
    async editProject(project) {
      await this.getProject({
        idUser: this.user._id,
        idProject: project._id,
      })
        .then(() => {
          this.$router.replace({
            name: 'SimulatorForm',
          })
        })
    },
    async resultProject(project) {
      await this.getProject({
        idUser: this.user._id,
        idProject: project._id,
      })
        .then(() => {
          this.$router.replace({
            name: 'Results',
          })
        })
    },
    removeProject(project) {
      this.deleteProject({
        idProject: project._id,
        idInvest: this.selectedInvestor._id,
      })
    },
    selectThenEngage(action, projet) {
      this.selectedProjects = [projet]
      this.engageAction(action)
    },
    changeSelectedInvestor(inv, index) {
      this.displaySelector = false
      // localStorage.setItem('itemInvest', JSON.stringify(inv))
      this.setInvestSelected({
        inv,
        index,
      })
    },
    toggleDisplay(index) {
      if (index !== this.toggledItem) {
        this.toggledItem = index
      } else {
        this.toggledItem = null
      }
    },
    displayAction() {
      if (!this.actionAvailable) return
      this.displayActions = !this.displayActions
    },
    selectAll(event) {
      if (event.target.checked) {
        this.selectedProjects = this.filteredList
      } else {
        this.selectedProjects = []
      }
    },
    translateDispositifs(dispo) {
      switch (dispo) {
        case 'pinel_neuf':
          return 'Pinel neuf'
        case 'pinel_ancien':
          return 'Pinel ancien'
        case 'classique_ancien':
          return 'Classique dans l\'ancien'
        case 'classique_neuf':
          return 'Classique dans le neuf'
        case 'denormandie':
          return 'Denormandie ancien'
        case 'malraux':
          return 'Malraux dans l\'ancien'
        default:
          return 'Indéfini'
      }
    },
    sort(projects) {
      const sortByProperty = (prop, direction = 1) => (a, b) => {
        if (a[prop] < b[prop]) {
          return -1 * direction
        }
        if (a[prop] > b[prop]) {
          return 1 * direction
        }
        return 0
      }

      switch (this.sortBy) {
        case 'name':
          return projects.sort(sortByProperty('name'))
        case 'nameReversed':
          return projects.sort(sortByProperty('name', -1))
        case 'created':
          return projects.sort(sortByProperty('created'))
        case 'createdReversed':
          return projects.sort(sortByProperty('created', -1))
        default:
          return projects
      }
    },
    engageDeleteInvestor() {
      this.modale.contenu = `Êtes-vous sûr de vouloir supprimer l'investisseur ${this.selectedInvestor.name} ?  Cette suppression sera irrémédiable.`
      this.modale.title = 'Suppression d\'Investisseur'
      this.modale.bouton = 'Supprimer'
      this.modale.action = 'deleteInvestor'
      this.modale.color = 'red'
      this.modale.display = true
      if(this.locked) this.displayInputLockPassword = true
    },
    engageAction(action) {
      switch (action) {
        case 'deleteProject':
          if (this.selectedProjects.length > 1) {
            this.modale.contenu = 'Voulez-vous supprimer définitivement ces simulations ?'
          } else {
            this.modale.contenu = `Voulez-vous supprimer définitivement la simulation ${this.selectedProjects[0].name} ?`
          }
          this.modale.title = 'Suppression de simulation'
          this.modale.bouton = 'Supprimer'
          this.modale.action = 'deleteProjet'
          this.modale.color = 'red'
          this.displayInputLockPassword = false
          break
        case 'archive':
          this.modale.contenu = `Êtes-vous sûr de vouloir archiver l'investisseur ${this.selectedInvestor.name} ? Vous retrouverez vos archives dans l'onglet paramètres.`
          this.modale.bouton = 'Archiver'
          this.modale.action = 'archive'
          this.modale.color = 'blue'
          this.displayInputLockPassword = false
          break
        case 'duplicate':
          if (this.selectedProjects.length > 1) {
            this.modale.contenu = 'Dupliquer ces simulations ?'
          } else {
            this.modale.contenu = `Dupliquer la simulation ${this.selectedProjects[0].name} ?`
          }
          this.modale.bouton = 'Dupliquer'
          this.modale.action = 'duplicate'
          this.modale.color = 'blue'
          this.displayInputLockPassword = false
          break
        default:
          this.modale.title = 'Erreur'
      }
      this.modale.display = true
    },
    proceedAction(action) {
      switch (action) {
        case 'deleteInvestor':
          if(this.locked) this.checkLockPassword()
          else this.deleteInvestor()
          break
        case 'deleteProjet':
          this.selectedProjects.forEach((projet) => {
            this.removeProject(projet)
          })
          this.selectedProjects = []
          this.modale.display = false
          break
        case 'archive':
          this.$store.dispatch('invests/archiveInvestor', this.selectedInvestor._id)
          this.modale.display = false
          break
        case 'duplicate':
          this.selectedProjects.forEach((project) => {
            this.duplicateProject({
              idProject: project._id,
              idInvest: this.selectedInvestor._id,
              idUser: this.user._id,
            })
          })
          this.selectedProjects = []
          this.modale.display = false
          break
      }
      this.modale.display = false
    },
    deleteInvestor() {
      this.deleteInvest({ idInvest: this.selectedInvestor._id })
      this.changeSelectedInvestor(this.investsList[0], 0)
    },
    getFiscalite(formeJuridique) {
      return formeJuridique.find(forme => forme.selected === true).text
    },
    formeJuridiqueSelected(project) {
      let forme = ''
      project.carac.formejuridique.map((val) => {
        if (val.selected === true) {
          forme = val.text
        }
        return val
      })
      return forme
    },
    toNewSimulation() {
      this.setProjectSelected({ proj: null })
      this.$router.replace({
        name: 'SimulatorForm',
      })
    },
    cashFlowComputed(project) {
      let cashFlowAvantRemb = 0
      let cashFlowAvantRembCounter = 0
      let cashFlowApresRemb = 0
      let cashFlowApresRembCounter = 0
      let resCashFlowAvantRemb = 0
      let resCashFlowApresRemb = 0

      for (let i = 0; i <= project.results.timelineFonc.resTimelineEmpr.length; i++) {
        if (i > 0 && project.results.timelineFonc.resTimelineEmpr[i] === 1) {
          cashFlowAvantRemb += project.results.resFluxAIFonc.resCashFlowNonActu[i]
          cashFlowAvantRembCounter += 1
        }
        if (i > 0 && project.results.timelineFonc.resTimelineEmpr[i] === 0 && project.results.timelineFonc.resDateRevente[i] == 0) {
          cashFlowApresRemb += project.results.resFluxAIFonc.resCashFlowNonActu[i]
          cashFlowApresRembCounter += 1
        }
      }
      resCashFlowAvantRemb = isNaN(Math.round((cashFlowAvantRemb / cashFlowAvantRembCounter) / 12))
        ? 0
        : Math.round((cashFlowAvantRemb / cashFlowAvantRembCounter) / 12)
      resCashFlowApresRemb = isNaN(Math.round((cashFlowApresRemb / cashFlowApresRembCounter) / 12))
        ? 0
        : Math.round((cashFlowApresRemb / cashFlowApresRembCounter) / 12)

      return [resCashFlowAvantRemb, resCashFlowApresRemb]
    },
    economieFiscaleTotale(project) {
      const economieFiscaleTotale = project.tableaux.TableauTresorerie.reduce((acc, val) => {
        return acc + val[9]
      }, 0)
      return economieFiscaleTotale
    },
    dividende(project) {
      const dividende = project.results.resFluxAIFonc.resImpositionDividende.reduce((acc, val) => {
        return acc + val
      }, 0)
      return dividende
    },
    fraisAcquisitionBien(project) {
      return Math.round(
        project.results.resCoutTotalFonc.resFraisNotaire.reduce((a, b) => a + b)
        + -project.results.resCoutTotalFonc.resFraisAgenceImmo.reduce((a, b) => a + b)
        + project.results.resCoutTotalFonc.resTVA.reduce((a, b) => a + b),
      )
    },
    setComparisonSelected() {
      if (this.selectedProjects.length > 1) {
        const dataINeed = []
        for (const project of this.selectedProjects) {
          dataINeed.push({
            nom: project.name,
            id: project._id,
            cityName: project.loc.carac.city,
            surface: project.carac.surface,
            coutTotalAcquisition: project.carac.coutacquisition,
            prixm2Net: project.carac.coutacquisition / project.carac.surface,
            loyerMensuel: project.carac.loyermensuel,
            chargesMensuelles: project.carac.totalOPEX.val,
            estimationTotaleTravaux: project.carac.trvametot,
            dureeDetention: project.carac.dureedetention.val,
            prixRevente: project.carac.estimprixrevente,
            typeExploitation: project.carac.typeexploitation,
            regimeFiscal: this.formeJuridiqueSelected(project),
            profitTotal: project.results.resFluxAIFonc.resTotCashFlowNonActu,
            effortTotal: project.results.resFluxAIFonc.resTotSommeInvest,
            cashFlowMensuel: this.cashFlowComputed(project)[0],
            rentabiliteApresImpots: project.results.resFluxAIFonc.performance.avecFisc.tir,
            economieFiscaleTotale: this.economieFiscaleTotale(project),
            impotRevenuLocatif: project.results.resFluxAIFonc.resTotImpositionRevenusLocatifs,
            impotSurDividendes: this.dividende(project),
            impotSurPlusValue: project.results.resFluxAIFonc.resTotImpositionPlusValue,
            impositionTotale: project.results.resFluxAIFonc.resTotImposition,
          })
        }
        this.setProjectComparison(dataINeed)
          .then((res) => {
            this.$router.replace({
              name: 'Comparateur',
            })
          })
      }
    },
  }),
}
</script>

<style scoped>
.titles {
  @apply font-bold text-2xl
}

.subtitles {
  @apply font-bold text-sm
}

.encart {
  @apply px-5 py-3 flex gap-3 rounded-xl shadow-md bg-purpleaestima-500;
}

.subEncarts {
  @apply flex flex-col w-full text-xs gap-3
}

.line {
  @apply grid grid-cols-7 px-1 py-2 rounded-xl items-center text-sm;
}

.button-blue {
  @apply bg-lightblueaestima-100 rounded-xl text-white font-bold shadow-md border-2 border-lightblueaestima-100 px-2 py-1;
}

.button-blue:hover {
  @apply bg-white text-lightblueaestima-100 border-lightblueaestima-100;
}

.button-red {
  @apply bg-white text-red-400 border-red-400 rounded-xl text-xs font-bold shadow-md border-2 px-2 py-1;
}

.button-red:hover {
  @apply bg-red-400 text-white border-red-400
}

.hoveredEncarts {
  @apply flex flex-col px-8
}

.filter-selected {
  @apply bg-white rounded-xl;
}

.scale101 {
  transition: transform 0.03s ease-in-out;
  /* apply a transition to the transform property */
}

.scale101:hover {
  transform: scale(1.01);
  /* scale the element to 102% when hovered */
}

.wrapper {
  -webkit-transform: translateZ(0);
  /* webkit flicker fix */
  -webkit-font-smoothing: antialiased;
  /* webkit text rendering fix */
}

.wrapper .tooltip {
  background: #14A0EB;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -20px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #1496bb 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
  display: none;
}

.lte8 .wrapper:hover .tooltip {
  display: block;
}

.p-dropdown {
  @apply bg-lightblueaestima-100 rounded-xl border-0 text-white w-56
}

.p-dropdown:hover {
  @apply bg-white text-lightblueaestima-100 border-2 border-lightblueaestima-100;
}

::v-deep .p-dropdown-items-wrapper {
  @apply w-56
}

::v-deep .p-dropdown-item {
  @apply p-2;
}

::v-deep .p-dropdown-trigger {
  @apply text-white !important;
}
</style>
