<template>
	<div>
		<Header/>
		<div class="grid grid-cols-11 gap-1 bg-darkblueaestima-500 relative flex flex-row justify-center items-start">
			<div class="relative text-center col-span-11 sm:col-start-2 sm:col-end-11 z-10">
				<h1 class="uppercase s1-title font-bold text-white text-4xl md:text-6xl mb-4 aestima-title-vw leading-normal">CONDITIONS GENERALES DU PROGRAMME D’AFFILIATION</h1>
			</div>
		</div>
		
		<div class="flex flex-col items-start mx-6 sm:mx-40 my-10">
			<span class="uppercase font-bold text-lg">VERSION 1</span>
			<span class="uppercase font-bold text-lg">En vigueur au 22/08/2022</span>
		</div>
		
		<div class="mx-6 sm:mx-40 text-justify mb-20">
			<h2>DESCRIPTION DES PARTIES AU CONTRAT</h2>
			
			<p>Entre la société EVENY dite « L’affiliant » et dont le siège social est situé au 13 Rue Haxo, 13001 MARSEILLE, immatriculée au RCS MARSEILLE sous le SIREN  918 402 322, représentée par M. Julien VIDEAU  en qualité de Président, dûment habilité aux fins des présentes.</p>
			<p>Et</p>
			<p>Vous en qualité dite « d’affilié ».</p>
			<p>En vous inscrivant au programme d'affiliation de EVENY pour son application professionnelle Aestima, vous indiquez que vous acceptez les conditions générales du programme. EVENY se réserve le droit d'accepter ou de refuser votre candidature.</p>
			<p>En tant qu'affilié autorisé de EVENY, vous acceptez de respecter les termes et conditions et comprenez que toute violation de ces termes entraînera la résiliation de votre participation au programme.</p>
			<p>Veuillez lire attentivement ces Termes et Conditions avant de vous inscrire et de promouvoir l’application Aestima en tant qu'affilié.</p>
			
			<h2>ARTICLE 1 – CANDIDATURE AU PROGRAMME D’AFFILIATION</h2>
			<p>La participation au programme a pour seul but de faire de la publicité légale sur notre site web et en retour de recevoir une commission sur les abonnements par les individus référés à l’application Aestima et enregistrés par le biais du lien d’affiliation personnel de l’affilié.</p>
			<p>Pour candidater au programme, l’affilié devra se rendre sur aestima-immo.com et cliquer sur « adhésion au programme d’affiliation », lien situé sur la page « accueil » dans le bas de page. Il sera alors invité à remplir un formulaire avec ses informations et officialiser sa demande d’adhésion au programme d’affiliation.</p>
			<p>Toute personne souhaitant devenir un affilié doit demander à participer au programme. Le demandeur doit être âgé d'au moins 18 ans. La décision sur la demande peut prendre plusieurs jours. Si la demande est approuvée, le participant recevra un e-mail contenant la confirmation de l'approbation et le lien vers son profil d’affilié. L'email de bienvenue sera envoyé à l'adresse électronique utilisée lors de l'inscription.</p>
			<p>En cas de refus, l’affilié pourra promouvoir Aestima, mais il ne sera pas considéré comme un affilié et ne recevrez aucun paiement à la suite d'une quelconque promotion. L’affilié est autorisé à postuler à nouveau au programme. Si, après l'approbation du programme, un participant perd son statut d'affilié pour quelque raison qu'il soit, il ne sera plus considéré comme un affilié et ne recevra aucun paiement à la suite d'une quelconque promotion. Dans ce cas, le participant résilié n'est pas autorisé à postuler à nouveau au programme en utilisant le même courriel ou un autre nom.</p>
			
			<h2>ARTICLE 2 – COMMISSIONS</h2>
			<p><u>Condition de commission</u></p>
			<p>Le présent contrat est établi pour toutes les prestations proposées par l’application Aestima sur <router-link to="/"><u>www.aestima-immo.com</u></router-link> à savoir :</p>
			<ul class="round-tiret mb-4 pl-16 mt-4">
				<li>Crédit d’utilisation</li>
				<li class="my-2">Abonnement dit « initié »</li>
				<li>Abonnement dit « professionnel »</li>
			</ul>
			<p>Les affiliés reçoivent une commission sur les achats qui en découlent :</p>
			<ul class="round-tiret mb-4 pl-16 mt-4">
				<li>ont eu lieu après leur inscription au programme d'affiliation</li>
				<li class="my-2">ont été suivis grâce au lien d’affiliation qui leur a été donné</li>
				<li>L'affilié recevra une commission de 30 % du montant des achats qu'il a généré</li>
				<li class="my-2">La durée de la commission dépend de la nature de l’engagement choisi par l’utilisateur :</li>
				<div class="router-circle pl-16 mt-4">
					<li>Pour le crédit unique, l’Affilié percevra sa commission en une seule fois lors du versement trimestriel.</li>
					<li class="my-2">Pour l’abonnement à engagement mensuel, le versement de la commission sera récurrent pendant 12 mois ou jusqu’à ce que l’utilisateur arrête son abonnement s’il le fait avant le délai des 12 mois.</li>
					<li>Pour l’engagement annuel, l’utilisateur étant débité une seule fois pour l’année, l’affilié percevra sa commission en une seule fois lors du versement trimestriel.</li>
				</div>
			</ul>
			<p>Différentes récompenses seront mises en place pour la communauté de l’Affilié qui commande par son biais.</p>
			<p>Suppression de récompense en crédit</p>
			<ul class="router-circle pl-32 mt-4 mb-4">
				<!-- <li>Pour l’achat d’un crédit Aestima, l’utilisateur gagnera un crédit supplémentaire.</li> -->
				<li class="my-2">Pour l’abonnement, mensuel ou annuel, à l’abonnement « initié » l’utilisateur aura une réduction de 50% les deux premiers mois.</li>
				<li>Pour l’abonnement, mensuel ou annuel, à l’offre « professionnel » l’utilisateur aura une réduction de 50% les deux premiers mois.</li>
			</ul>
			
			<p>Pour identifier la communauté de l’Affilié et attribuer à chacun leur dû, EVENY enverra un rapport détaillé lors de chaque versement de commission à l’Affilié. Ce dernier pourra alors consulter le nombre de personnes inscrites en abonnement payant avec son code de réduction. Toutes ces transactions sont enregistrées sur notre service de paiement Stripe.</p>
			<p>L'affilié ne peut pas se référer lui-même et ne recevra pas de commission sur son propre compte. Il y a également une limite d'une commission récurrente par parrainage : si une personne utilise le lien de l'affilié pour s'abonner plusieurs fois, l'affilié recevra une commission récurrente sur la première commande uniquement.</p>
			<p>Les membres de la famille de l’affilié ne peuvent pas être recommandés par l’affilié et ce dernier ne recevrez pas de commission pour leurs achats. L'utilisateur référé et l'affilié ne peuvent pas partager le même lieu de travail. Les achats effectués au sein de l'entreprise de l'affilié ne sont pas éligibles et ne seront pas pris en compte dans la commission de l'affilié.</p>
			
			<p><u>Comptabilisation et paiement des Commissions</u></p>
			<p>Les achats effectuées par l’Affilié ne donnent pas lieu à commissions. Seules les achats de prestations fermes faits par sa communauté à savoir celles qui n’auront pas fait l’objet d’un remboursement seront comptabilisées pour le paiement des commissions.</p>
			<p>Les utilisateurs remboursés de leurs frais de souscription ou dont les commandes entraînent des rejets de débits ne pourront pas être comptabilisés. Si l'affilié reçoit un paiement de commission pour un achat qui est ensuite remboursé ou refacturé, EVENY retirera la somme du paiement reçu de la commission de l'affilié du mois suivant.</p>
			<p>Le paiement des commissions sera effectué tous les trois (3) mois, par virement bancaire, sur présentation d’une facture émise par l’Affilié au nom de l’Affilieur.  Les factures seront payées dans un délai de 30 jours à compter de leur réception.</p>
		
			<h2>ARTICLE 3 – FIN DU STATUT D’AFFILIÉ</h2>
			<p>La demande d'affiliation et le statut de l'affilié dans le programme peuvent être rejetés, suspendus ou résiliés pour l'une des raisons suivantes :</p>
			<ul class="round-tiret mb-4 pl-16 mt-4">
				<li>Publicités inappropriées (fausses déclarations, liens hypertextes trompeurs, etc...)</li>
				<li class="my-2">Spamming (courriels de masse, affichage massif dans des groupes de discussion, etc.)</li>
				<li>Publicité sur des sites web contenant ou promouvant des activités illégales</li>
				<li class="my-2">Non-divulgation de la relation d'affiliation pour toute promotion qui peut être considérée comme un soutien en vertu des directives et règlements de la Commission fédérale du commerce ou de toute loi d'État applicable</li>
				<li>Violation des droits de propriété intellectuelle (EVENY se réserve le droit d'exiger des accords de licence de la part de ceux qui utilisent les marques Aestima, afin de protéger nos droits de propriété intellectuelle)</li>
			</ul>
			<p>EVENY se réserve le droit de résilier tout compte à tout moment, pour quelque raison que ce soit ou sans raison, à sa propre discrétion.</p>
			
			<h2>ARTICLE 4 – LIENS D’AFFILIATION</h2>
			<p>L'affilié peut utiliser des versions graphiques et textuelles de ses liens sur des sites web, dans des messages électroniques, des messages de médias sociaux, ainsi que faire de la publicité pour l'application Aestima dans des annonces en ligne et hors ligne, des magazines et des journaux.</p>
			<p>L'affilié peut utiliser les graphiques et les textes fournis par EVENY ou créer ses propres graphiques et textes tant qu'ils sont jugés appropriés selon ces Termes et Conditions et que les éléments suivent la charte graphique de l'application Aestima.</p>
			
			<p>Article 5 : Politique de paiement par clic (PPC)</p>
			<p>L'affilié n'est pas autorisé à utiliser les noms de marque des enchères du PPC (Aestima, aestima-immo.com) dans les publicités du PPC sans autorisation écrite préalable.</p>
			
			<h2>ARTICLE 5 – PUBLICITÉ</h2>
			<p>L'affilié ne peut en aucun cas user de publicités payantes pour promouvoir son lien d'affiliation (Adwords, Facebook Ads, Linked Ads...)</p>
		
			<h2>ARTICLE 6 : RESPONSABILITÉ</h2>
			<p>EVENY ne sera pas responsable des dommages directs, indirects ou accidentels (perte de revenus, commissions) dus à des défaillances de suivi des affiliés, à la perte de fichiers de base de données, ou à tout résultat d'intention de nuire au programme et/ou au site web de Aestima.</p>
			<p>Nous ne prétendons pas que le fonctionnement du Programme d’affiliation de EVENY sera exempt d'erreurs, et EVENY ne sera pas responsable de toute interruption ou erreur.</p>
		
			<h2>ARTICLE 7 – DURÉE DE L’ACCORD</h2>
			<p>La durée de l'accord sur les présentes conditions générales commence au moment de l'acceptation de l'affilié dans le programme et se termine lorsque le compte de l'affilié est résilié. Les présentes conditions générales peuvent être modifiées par EVENY à tout moment. Si une modification des conditions générales est inacceptable pour l'affilié, son seul choix est de résilier son compte d'affilié. La poursuite de la participation au programme constitue l'acceptation par l'affilié de toute modification.</p>
			
			<h2>ARTICLE 8 – MODIFICATION DU SITE</h2>
			<p>Tout changement de la dénomination du site ainsi que tout changement de son lieu d’hébergement, de sa taille, de son objet, de sa fréquence de mise à jour, n’auront aucun effet sur le contrat d’affiliation qui s’appliquera de plein droit et automatiquement.</p>
		
			<h2>ARTICLE 9 – DROIT APPLICABLE ET JURIDICTION COMPÉTENTE</h2>
			<p>La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.</p>
			<p>Cependant en cas de litige ou de désaccord, et dans la mesure du possible, les parties s'engagent à favoriser un arrangement amiable avant de saisir la justice. La nullité d'une clause contractuelle n'entraîne pas la nullité des présentes.</p>
		</div>

	<Footer/>
</div>

</template>

<script>
import Footer from '@/components/Landing/Footer.vue'
import Header from '@/components/Landing/Header.vue'
import { mapGetters, mapActions } from "vuex"

export default {
	name: 'CGA',
	data() {
		return {
			firstname: '',
			lastname: '',
			phone: '',
			email: '',
			work: 'testeur',
			message: '',
			isSend: false,
		}
	},
	components: {
		Footer,
		Header
	},
	metaInfo(){
		return {
			title: "Conditions générales d'affiliation - Aestima",
			meta: [{
				name: 'description',
				content: "Cette page décrit les conditions générales d'affilliation de l'estimateur immobilier Aestima."
			}]
			//keywords
		}
	},
	computed: {
		...mapGetters({
		}),
	},
	methods: {
		...mapActions({
			contact: 'users/contact',
		}),
		setSelectedWork(event) {
			this.work = event.target.value
		},
		async submit(){
			await this.contact({
				firstname: this.firstname,
				lastname: this.lastname,
				phone: this.phone,
				email: this.email,
				work: this.work,
				message: this.message
			})
			.then(() => {
				this.isSend = true
				setTimeout(() => {
					this.isSend = false
				}, 8000);
			})
		}
	},
}
</script>

<style scoped>
h2 {
	font-size: 1.4rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

p {
	font-size: 1.2rem;
	font-weight: normal;
	margin-bottom: 1rem;
}

ul {
	margin-bottom: 1rem;
}

li {
	font-size: 1.2rem;
	font-weight: normal;
}
/* Medium (md) */
@media (min-width: 768px) {
	.h-header {
		height: 50rem;
	}

	.aestima-title-vw {
		font-size: 2.8vw !important;
	}

	.aestima-par-vw {
		font-size: 1.2vw !important;
	}
}

.shadow-inner {
	box-shadow: 0 15px 35px 0 rgba(60,66,87,.08), 0 5px 15px 0 rgba(0,0,0,.12);
}

.round-tiret li {
	list-style: inside;
}

.router-circle li {
	list-style: circle;
}
</style>

