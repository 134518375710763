<template>
  <div class="mb-10 grid grid-cols-12 sm:gap-12">
    <div class="col-span-12 sm:col-start-2 sm:col-end-12">
      <span class="flex self-start font-semibold text-xl mb-4">Liste des utilisateurs Aestima</span>
      <div class="flex flex-row items-center">
        <label class="font-bold mr-5" for="type_filter">Tri :</label>
        <select id="" v-model="type_filter" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="type_filter">
          <option value="lastLoginAt">
            Date de connexion
          </option>
          <option value="name">
            Nom
          </option>
          <option value="createdAt">
            Date de création
          </option>
        </select>
      </div>
      <div class="flex flex-col text-left">
        <div class="-my-2 overflow-x-auto">
          <div class="py-2 align-middle inline-block min-w-full">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Date de création
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Nom complet de l'utilisateur
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      N° Téléphone
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Source
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Profil
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Type d'abonnement
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Nombre total de connexions
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Collaborateurs
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      Mdp Sensible
                    </th>
										<th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
											Date de dernière connexion
										</th>
                                       
                    <!-- <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
								Etat d'avancement
								</th> -->
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="(usr) in displayedUsers" :key="usr._id">
                    <td class="px-6 py-2 whitespace-no-wrap">
                      <div v-if="usr.createdAt" class="text-xs leading-5 text-gray-900">
                        {{ usr.createdAt.split("T")[0].split("-")[2] }}/{{ usr.createdAt.split("T")[0].split("-")[1] }}/{{ usr.createdAt.split("T")[0].split("-")[0] }}
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-no-wrap">
                      <div class="flex items-center">
                        <!-- <div class="flex-shrink-0 h-10 w-10">
										<img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60" alt="">
										</div> -->
                        <div class="ml-4">
                          <div class="text-xs leading-5 font-medium text-gray-900">
                            {{ `${usr.name} ${usr.firstname}` }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-no-wrap">
                      <div class="text-xs leading-5 text-gray-900">
                        {{ usr.email }}
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-no-wrap">
                      <div class="text-xs leading-5 text-gray-900">
                        {{ usr.info_phone }}
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-no-wrap">
                      <div class="text-xs leading-5 text-gray-900">
                        {{ usr.info_know }}
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-no-wrap">
                      <div class="text-xs leading-5 text-gray-900">
                        {{ usr.info_work }}
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-no-wrap">
                      <div v-if="usr.sub_level == 0" class="text-xs leading-5 text-gray-900">
                        Gratuit
                      </div>
                      <div v-if="usr.sub_level == 1" class="text-xs leading-5 text-gray-900">
                        Initié
                      </div>
                      <div v-if="usr.sub_level == 2" class="text-xs leading-5 text-gray-900">
                        Professionnel
                      </div>
                    </td>
                    <!-- <td class="px-6 py-2 whitespace-no-wrap">
									<div class="text-xs leading-5 text-gray-900">{{usr.state}}</div>
								</td> -->
                    <td class="px-6 py-2 whitespace-no-wrap">
                      <div class="text-xs leading-5 text-gray-900">
                        {{ usr.connexion_number ? usr.connexion_number : 0 }}
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-no-wrap">
                      <div class="text-xs leading-5 text-gray-900">
                        <input v-model.number="usr.coworkerLimit" type="number" class="form-control" @change="changeCoworkerLimit(usr)">
                      </div>
                    </td>
                    <td class="px-6 py-2 whitespace-no-wrap">
                      <div class="text-xs leading-5 text-gray-900">
                        <input v-model="usr.lockPassword" type="text" class="form-control" @change="updateLockPassword({ id: usr._id, lockPassword: usr.lockPassword })">
                      </div>
                    </td>
                    
										<td class="px-6 py-2 whitespace-no-wrap" v-if="usr.lastLoginAt">
											<div class="text-xs leading-5 text-gray-900">{{ usr.lastLoginAt.split("T")[0].split("-")[2] }}/{{ usr.lastLoginAt.split("T")[0].split("-")[1] }}/{{ usr.lastLoginAt.split("T")[0].split("-")[0] }}</div>
										</td>
                                       
                  </tr>

                  <!-- More rows... -->
                </tbody>
                <tfoot aria-label="Page navigation example">
                  <tr>
                    <th scope="col" class="px-6 py-3 bg-gray-100 text-gray-800 text-left text-xs font-bold uppercase tracking-wider">
                      <button v-if="page != 1" type="button" class="page-link" @click="page--">
                        Previous
                      </button>
                    </th>
                    <th scope="col" class="px-6 py-3 bg-gray-100 text-gray-800 text-left text-xs font-bold uppercase tracking-wider">
                      <button v-for="pageNumber in pages.slice(page - 1, page + 5)" type="button" class="page-link" @click="page = pageNumber">
                        {{ pageNumber }}
                      </button>
                    </th>
                    <th scope="col" class="px-6 py-3 bg-gray-100 text-gray-800 text-left text-xs font-bold uppercase tracking-wider">
                      <button v-if="page < pages.length" type="button" class="page-link" @click="page++">
                        Next
                      </button>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form class="col-span-12 sm:col-start-2 sm:col-end-12 mb-16 sm:mb-0" @submit.prevent="setGlobals()">
      <div class="col-span-12 sm:col-start-2 sm:col-end-12 mb-16 sm:mb-0">
        <h1>Variables modifiables</h1>
        <div class="flex flex-col items-start">
          <h2 class="font-bold py-3">
            1. Charges locatives
          </h2>
          <div class="flex flex-row grid">
            <div class="col-start-1 col-end-2 flex flex-col px-2">
              <label for="">Taxe foncière (auto)</label><input v-model="newGlobals.taxefonciere.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-2 col-end-3 flex flex-col px-2">
              <label for="">Frais de copropriété (auto)</label><input v-model="newGlobals.fraiscopropriete.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-3 col-end-4 flex flex-col px-2">
              <label for="">Frais PNO (auto)</label><input v-model="newGlobals.fraisassurancepno.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-4 col-end-5 flex flex-col px-2">
              <label for="">Gestion locative (auto)</label><input v-model="newGlobals.gestionlocative.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-1 col-end-2 flex flex-col px-2">
              <label for="">CFE (auto)</label><input v-model="newGlobals.cfe" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-2 col-end-3 flex flex-col px-2">
              <label for="">Constitution société (auto)</label><input v-model="newGlobals.constitutionsociete" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-3 col-end-4 flex flex-col px-2">
              <label for="">Compta + CGA (auto)</label><input v-model="newGlobals.comptacga" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-4 col-end-5 flex flex-col px-2">
              <label for="">GLI (auto)</label><input v-model="newGlobals.gli.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
          </div>
        </div>
        <div class="flex flex-col items-start">
          <h2 class="font-bold  py-3">
            2. Charges emprunt
          </h2>
          <div class="flex flex-row grid">
            <div class="col-start-1 col-end-3 flex flex-col px-2">
              <label for="">Taux d'intéret considéré comme bon (-15 ans)</label><input v-model="newGlobals.tauxinteretmoins15.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-3 col-end-5 flex flex-col px-2">
              <label for="">Taux d'intéret considéré comme bon (15 ans)</label><input v-model="newGlobals.tauxinteret15.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-5 col-end-7 flex flex-col px-2">
              <label for="">Taux d'intéret considéré comme bon (20 ans)</label><input v-model="newGlobals.tauxinteret20.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-7 col-end-9 flex flex-col px-2">
              <label for="">Taux d'intéret considéré comme bon (25 ans)</label><input v-model="newGlobals.tauxinteret25.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-1 col-end-3 flex flex-col px-2">
              <label for="">Taux ass emprunteur (auto)</label><input v-model="newGlobals.tauxassuranceemprunteur.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-3 col-end-5 flex flex-col px-2">
              <label for="">Frais de dossier bancaire (auto)</label><input v-model="newGlobals.fraisdossierbancaire.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-5 col-end-7 flex flex-col px-2">
              <label for="">Garantie bancaire (auto)</label><input v-model="newGlobals.garantiebancaire.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
          </div>
        </div>
        <div class="flex flex-col items-start">
          <h2 class="font-bold  py-3">
            3. Charges fiscalité
          </h2>
          <div class="flex flex-row grid">
            <div class="col-start-1 col-end-2 flex flex-col px-2">
              <label for="">Abbattement micro-BIC</label><input v-model="newGlobals.revenuImpPer.microBic.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-2 col-end-3 flex flex-col px-2">
              <label for="">Abbatement micro-foncier</label><input v-model="newGlobals.revenuImpPer.microFonc.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-3 col-end-4 flex flex-col px-2">
              <label for="">Taux imposition IS minoré</label><input v-model="newGlobals.timpositionIS.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-4 col-end-5 flex flex-col px-2">
              <label for="">Taux plein imposition IS</label><input v-model="newGlobals.impositionIS.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-5 col-end-6 flex flex-col px-2">
              <label for="">PS sur revenus imposables</label><input v-model="newGlobals.PSbenefice.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-6 col-end-7 flex flex-col px-2">
              <label for="">CSG déductible</label><input v-model="newGlobals.CSG.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
          </div>
          <div class="flex flex-row grid">
            <div class="col-start-7 col-end-8 flex flex-col px-2">
              <label for="">IR plus-value</label><input v-model="newGlobals.ImpotPluval.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-8 col-end-9 flex flex-col px-2">
              <label for="">PS plus-value</label><input v-model="newGlobals.PSPluval.per" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
            <div class="col-start-7 col-end-8 flex flex-col px-2">
              <label for="">Cotisation sociales SSI auto</label>
            </div>
            <div class="col-start-8 col-end-9 flex flex-col px-2">
              <input v-model="newGlobals.ssi" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
            </div>
          </div>
        </div>
        <div class="flex flex-col items-start grid">
          <div class="col-start-1 col-end-8">
            <h2 class="font-bold  py-3">
              4. Dispositifs fiscaux
            </h2>
            <div class=" grid">
              <div class="col-start-1 col-end-3 grid px-2">
                <label class="col-start-1 col-end-6" for="">Zone A</label><input v-model="newGlobals.zonea" class="col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
              <div class="col-start-4 col-end-11 grid px-2">
                <label class="col-start-1 col-end-6" for="">Réduction impôts année 1 à 9 (Denormandie)</label><input v-model="newGlobals.reductionannee1a9.per" class="col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
              <div class="col-start-1 col-end-3 grid px-2">
                <label class="col-start-1 col-end-6" for="">Zone A bis</label><input v-model="newGlobals.zoneabis" class="col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
              <div class="col-start-4 col-end-11 grid px-2">
                <label class="col-start-1 col-end-6" for="">Réduction impôts année 10 à 12 (Denormandie)</label><input v-model="newGlobals.reductionannee10a12.per" class="col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
              <div class="col-start-1 col-end-3 grid px-2">
                <label class="col-start-1 col-end-6" for="">Zone B1</label><input v-model="newGlobals.zoneb1" class="col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
              <div class="col-start-1 col-end-3 grid px-2">
                <label class="col-start-1 col-end-6" for="">Zone B2</label><input v-model="newGlobals.zoneb2" class="col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
            </div>
          </div>
          <div class="col-start-8 col-end-12">
            <h2 class="font-bold  py-3">
              5. Barèmes honoraires notaires
            </h2>
            <div class=" grid">
              <div class="col-start-1 col-end-12 px-2 grid">
                <label class="col-start-1 col-end-6" for="">De 0€ à 6 500€</label><input v-model="newGlobals.notaire0a6500.per" class="col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
              <div class="col-start-1 col-end-12 px-2 grid">
                <label class="col-start-1 col-end-6" for="">De 6 501€ à 17 000€</label><input v-model="newGlobals.notaire6501a17000.per" class="col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
              <div class="col-start-1 col-end-12 px-2 grid">
                <label class="col-start-1 col-end-6" for="">De 17 001€ à 60 000€</label><input v-model="newGlobals.notaire17001a60000.per" class="col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
              <div class="col-start-1 col-end-12 px-2 grid">
                <label class="col-start-1 col-end-6" for="">A partir de 60 001€ et +</label><input v-model="newGlobals.notaire60001etplus.per" class="col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
              <div class="col-start-1 col-end-12 flex flex-row px-2 grid">
                <label class="col-start-1 col-end-6" for="">Montant fixe d'emoluments de formalités et débours</label><input v-model="newGlobals.montantfixeemolument" class=" col-start-6 col-end-12 mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text">
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-between my-12 mx-auto">
            <div class="font-bold  py-3">
              6. Limites de collaborateurs
            </div>
            <thead>
              <tr>
                <th class="w-1/2 px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">
                  Niveau de souscription
                </th>
                <th class="w-1/2 px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">
                  Nombre de collaborateurs
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="w-1/2 px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  Découverte
                </td>
                <td class="w-1/2 px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  <input v-model.number="newGlobals.coworkerLimit.decouverte" class="z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out">
                </td>
              </tr>
              <tr>
                <td class="w-1/2 px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  Initié
                </td>
                <td class="w-1/2 px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  <input v-model.number="newGlobals.coworkerLimit.initie" class="z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out">
                </td>
              </tr>
              <tr>
                <td class="w-1/2 px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  Professionnel
                </td>
                <td class="w-1/2 px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  <input v-model.number="newGlobals.coworkerLimit.pro" class="z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out">
                </td>
              </tr>
            </tbody>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="14" height="14" viewBox="0 0 172 172" style=" fill:#000000;">
              <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                <path d="M0,172v-172h172v172z" fill="none" />
                <g fill="#ffffff">
                  <path d="M86,6.61538c-21.99099,0 -39.69231,17.70132 -39.69231,39.69231v13.23077c-14.54868,0 -26.46154,11.91286 -26.46154,26.46154v52.92308c0,14.54868 11.91286,26.46154 26.46154,26.46154h79.38462c14.54868,0 26.46154,-11.91286 26.46154,-26.46154v-52.92308c0,-14.54868 -11.91286,-26.46154 -26.46154,-26.46154v-13.23077c0,-21.99099 -17.70132,-39.69231 -39.69231,-39.69231zM86,19.84615c15.06551,0 26.46154,11.39603 26.46154,26.46154v13.23077h-52.92308v-13.23077c0,-15.06551 11.39603,-26.46154 26.46154,-26.46154zM86,80.00481c11.24099,0 19.84615,8.60517 19.84615,19.84615c0,7.93329 -5.29747,15.34976 -13.23077,17.98558v7.85577h-13.23077v-7.23558c0,-5.94351 3.38522,-10.62079 8.68269,-12.61058c2.63582,-0.67187 4.54808,-3.35937 4.54808,-5.99519c0,-3.97956 -2.63581,-6.61538 -6.61538,-6.61538c-3.97956,0 -6.61538,2.63582 -6.61538,6.61538h-13.23077c0,-11.24099 8.60517,-19.84615 19.84615,-19.84615zM79.38462,132.30769h13.23077v13.23077h-13.23077z" />
                </g>
              </g>
            </svg>
          </span>
          Enregistrer les paramètres (sans le barème TMI)
        </button>
      </div>
    </form>

    <!-- <form @submit.prevent="setGlobals()" class="col-span-12 sm:col-start-2 sm:col-end-12 mb-16 sm:mb-0">
			<span class="flex self-start font-semibold text-xl mb-4">Liste des variables globales</span>
			<div class="rounded-lg shadow-sm text-left p-10 bg-gray-100">
				<div class="grid grid-cols-6 gap-6 mb-6">
					<div class="col-span-6 sm:col-span-2">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">timpositionIS (%)</label>
						<div class="relative">
							<input required v-model="newGlobals.timpositionIS.per" id="timpositionIS" type="Number" step="any" max="100" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
					<div class="col-span-6 sm:col-span-2">
						<label for="mail" class="block text-sm font-medium leading-5 text-gray-700">dureeamortLMNPIS (en années)</label>
						<div class="relative">
							<input required v-model="newGlobals.dureeamortLMNPIS" id="dureeamortLMNPIS" type="Number" step="any" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
					<div class="col-span-6 sm:col-span-2">
						<label for="mail" class="block text-sm font-medium leading-5 text-gray-700">plafondImpositionIS (€)</label>
						<div class="relative">
							<input required v-model="newGlobals.plafondImpositionIS" id="plafondImpositionIS" type="Number" step="any" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
				</div>

				<div class="grid grid-cols-6 gap-6 mb-6">
					<div class="col-span-6 sm:col-span-2">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">timpositionIS (%)</label>
						<div class="relative">
							<input required v-model="newGlobals.impositionIS.per" id="impositionIS" type="Number" step="any" max="100" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
					<div class="col-span-6 sm:col-span-2">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">tactualisation (%)</label>
						<div class="relative">
							<input required v-model="newGlobals.tactualisation.per" id="tactualisation" type="Number" step="any" max="100" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
					<div class="col-span-6 sm:col-span-2">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">PSbenefice (%)</label>
						<div class="relative">
							<input required v-model="newGlobals.PSbenefice.per" id="PSbenefice" type="Number" step="any" max="100" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
				</div>

				<div class="grid grid-cols-6 gap-6 mb-6">
					<div class="col-span-6 sm:col-span-2">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">CSG (%)</label>
						<div class="relative">
							<input required v-model="newGlobals.CSG.per" id="CSG" type="Number" step="any" max="100" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
					<div class="col-span-6 sm:col-span-2">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">cagnottenu (€)</label>
						<div class="relative">
							<input required v-model="newGlobals.cagnottenu" id="cagnottenu" type="Number" step="any" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
					<div class="col-span-6 sm:col-span-2">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">dureecagnotte (en années)</label>
						<div class="relative">
							<input required v-model="newGlobals.dureecagnotte" id="dureecagnotte" type="Number" step="any" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
				</div>

				<div class="grid grid-cols-6 gap-6 mb-6">
					<div class="col-span-6 sm:col-span-2">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">ImpotPluval (%)</label>
						<div class="relative">
							<input required v-model="newGlobals.ImpotPluval.per" id="ImpotPluval" type="Number" step="any" max="100" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
					<div class="col-span-6 sm:col-span-2">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">PSPluval (%)</label>
						<div class="relative">
							<input required v-model="newGlobals.PSPluval.per" id="PSPluval" type="Number" step="any" max="100" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
						</div>
					</div>
				</div>

				<div class="grid grid-cols-6 gap-6 mb-6">
					<div class="col-span-6 sm:col-span-6">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">calPluvalIR</label>
						<div class="relative">
							<textarea required v-model="newGlobals.calPluvalIR" id="calPluvalIR" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
							</textarea>
						</div>
					</div>
				</div>

				<div class="grid grid-cols-6 gap-6 mb-6">
					<div class="col-span-6 sm:col-span-6">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">calPluvalPS</label>
						<div class="relative">
							<textarea required v-model="newGlobals.calPluvalPS" id="calPluvalPS" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
							</textarea>
						</div>
					</div>
				</div>

				<div class="grid grid-cols-6 gap-6 mb-6">
					<div class="col-span-6 sm:col-span-6">
						<label for="agence" class="block text-sm font-medium leading-5 text-gray-700">tactualisationPer</label>
						<div class="relative">
							<textarea required v-model="newGlobals.tactualisationPer" id="tactualisationPer" class="mt-1 z-0 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
							</textarea>
						</div>
					</div>
				</div>

				<div class="mt-10">
					<button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out">
						<span class="absolute left-0 inset-y-0 flex items-center pl-3">
							<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="14" height="14" viewBox="0 0 172 172" style=" fill:#000000;">
								<g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
									<path d="M0,172v-172h172v172z" fill="none"></path>
									<g fill="#ffffff">
										<path d="M86,6.61538c-21.99099,0 -39.69231,17.70132 -39.69231,39.69231v13.23077c-14.54868,0 -26.46154,11.91286 -26.46154,26.46154v52.92308c0,14.54868 11.91286,26.46154 26.46154,26.46154h79.38462c14.54868,0 26.46154,-11.91286 26.46154,-26.46154v-52.92308c0,-14.54868 -11.91286,-26.46154 -26.46154,-26.46154v-13.23077c0,-21.99099 -17.70132,-39.69231 -39.69231,-39.69231zM86,19.84615c15.06551,0 26.46154,11.39603 26.46154,26.46154v13.23077h-52.92308v-13.23077c0,-15.06551 11.39603,-26.46154 26.46154,-26.46154zM86,80.00481c11.24099,0 19.84615,8.60517 19.84615,19.84615c0,7.93329 -5.29747,15.34976 -13.23077,17.98558v7.85577h-13.23077v-7.23558c0,-5.94351 3.38522,-10.62079 8.68269,-12.61058c2.63582,-0.67187 4.54808,-3.35937 4.54808,-5.99519c0,-3.97956 -2.63581,-6.61538 -6.61538,-6.61538c-3.97956,0 -6.61538,2.63582 -6.61538,6.61538h-13.23077c0,-11.24099 8.60517,-19.84615 19.84615,-19.84615zM79.38462,132.30769h13.23077v13.23077h-13.23077z"></path>
									</g>
								</g>
							</svg>
						</span>
						Enregistrer
					</button>
				</div>
			</div>
		</form> -->

    <form class="col-span-12 sm:col-start-2 sm:col-end-12 mb-16 sm:mb-0" @submit.prevent="setTmi()">
      <span class="flex self-start font-semibold text-xl mb-4">TMI</span>
      <div class="rounded-lg shadow-sm text-left p-10 bg-gray-100">
        <div class="flex flex-row justify-center align-middle">
          <p class="my-auto mx-3">
            0%
          </p>
          <input v-model="tmi['0'].start" required type="Number" placeholder="Valeur de départ" class="mt-1 z-0 form-input block w-full py-2 px-3 mx-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
          <input v-model="tmi['0'].end" required type="Number" placeholder="Valeur de fin" class="mt-1 z-0 form-input block w-full py-2 px-3 mx-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
        <div class="flex flex-row justify-center align-middle">
          <p class="my-auto mx-3">
            11%
          </p>
          <input v-model="tmi['11'].start" required type="Number" placeholder="Valeur de départ" class="mt-1 z-0 form-input block w-full py-2 px-3 mx-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
          <input v-model="tmi['11'].end" required type="Number" placeholder="Valeur de fin" class="mt-1 z-0 form-input block w-full py-2 px-3 mx-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
        <div class="flex flex-row justify-center align-middle">
          <p class="my-auto mx-3">
            30%
          </p>
          <input v-model="tmi['30'].start" required type="Number" placeholder="Valeur de départ" class="mt-1 z-0 form-input block w-full py-2 px-3 mx-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
          <input v-model="tmi['30'].end" required type="Number" placeholder="Valeur de fin" class="mt-1 z-0 form-input block w-full py-2 px-3 mx-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
        <div class="flex flex-row justify-center align-middle">
          <p class="my-auto mx-3">
            41%
          </p>
          <input v-model="tmi['41'].start" required type="Number" placeholder="Valeur de départ" class="mt-1 z-0 form-input block w-full py-2 px-3 mx-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
          <input v-model="tmi['41'].end" required type="Number" placeholder="Valeur de fin" class="mt-1 z-0 form-input block w-full py-2 px-3 mx-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
        <div class="flex flex-row justify-center align-middle">
          <p class="my-auto mx-3">
            45%
          </p>
          <input v-model="tmi['45'].start" required type="Number" placeholder="Valeur de départ" class="mt-1 z-0 form-input block w-full py-2 px-3 mx-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
        </div>
      </div>
      <div class="mt-10">
        <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="14" height="14" viewBox="0 0 172 172" style=" fill:#000000;">
              <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                <path d="M0,172v-172h172v172z" fill="none" />
                <g fill="#ffffff">
                  <path d="M86,6.61538c-21.99099,0 -39.69231,17.70132 -39.69231,39.69231v13.23077c-14.54868,0 -26.46154,11.91286 -26.46154,26.46154v52.92308c0,14.54868 11.91286,26.46154 26.46154,26.46154h79.38462c14.54868,0 26.46154,-11.91286 26.46154,-26.46154v-52.92308c0,-14.54868 -11.91286,-26.46154 -26.46154,-26.46154v-13.23077c0,-21.99099 -17.70132,-39.69231 -39.69231,-39.69231zM86,19.84615c15.06551,0 26.46154,11.39603 26.46154,26.46154v13.23077h-52.92308v-13.23077c0,-15.06551 11.39603,-26.46154 26.46154,-26.46154zM86,80.00481c11.24099,0 19.84615,8.60517 19.84615,19.84615c0,7.93329 -5.29747,15.34976 -13.23077,17.98558v7.85577h-13.23077v-7.23558c0,-5.94351 3.38522,-10.62079 8.68269,-12.61058c2.63582,-0.67187 4.54808,-3.35937 4.54808,-5.99519c0,-3.97956 -2.63581,-6.61538 -6.61538,-6.61538c-3.97956,0 -6.61538,2.63582 -6.61538,6.61538h-13.23077c0,-11.24099 8.60517,-19.84615 19.84615,-19.84615zM79.38462,132.30769h13.23077v13.23077h-13.23077z" />
                </g>
              </g>
            </svg>
          </span>
          Enregistrer les paramètres du barème progressif TMI (uniquement)
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'Admin',
  components: {
  },
  props: {
  },
  data() {
    return {
      type_filter: 'lastLoginAt',
      usersList: [],
      page: 1,
      perPage: 20,
      pages: [],
      tmi: {
        0: {
          start: 0,
          end: 0,
        },
        11: {
          start: 0,
          end: 0,
        },
        30: {
          start: 0,
          end: 0,
        },
        41: {
          start: 0,
          end: 0,
        },
        45: {
          start: 0,
        },
      },
      newGlobals: {
        timpositionIS: {
          val: '',
          per: '',
        },
        dureeamortLMNPIS: '',
        plafondImpositionIS: '',
        impositionIS: {
          val: '',
          per: '',
        },
        tactualisation: {
          val: '',
          per: '',
        },
        PSbenefice: {
          val: '',
          per: '',
        },
        CSG: {
          val: '',
          per: '',
        },
        cagnottenu: '',
        dureecagnotte: '',
        ImpotPluval: {
          val: '',
          per: '',
        },
        PSPluval: {
          val: '',
          per: '',
        },
        calPluvalIR: [],
        calPluvalPS: [],
        tactualisationPer: [],
        revenuImpPer: {
          microBic: {
            val: '',
            per: '',
          },
          microFonc: {
            val: '',
            per: '',
          },
        },
        taxefonciere: {
          val: '',
          per: '',
        },
        fraiscopropriete: {
          val: '',
          per: '',
        },
        fraisassurancepno: {
          val: '',
          per: '',
        },
        gestionlocative: {
          val: '',
          per: '',
        },
        cfe: '',
        constitutionsociete: '',
        comptacga: '',
        gli: {
          val: '',
          per: '',
        },
        tauxinteretmoins15: {
          val: '',
          per: '',
        },
        tauxinteret15: {
          val: '',
          per: '',
        },
        tauxinteret20: {
          val: '',
          per: '',
        },
        tauxinteret25: {
          val: '',
          per: '',
        },
        tauxassuranceemprunteur: {
          val: '',
          per: '',
        },
        fraisdossierbancaire: {
          val: '',
          per: '',
        },
        garantiebancaire: {
          val: '',
          per: '',
        },
        ssi: '',
        zonea: '',
        zoneabis: '',
        zoneb1: '',
        zoneb2: '',
        reductionannee1a9: {
          val: '',
          per: '',
        },
        reductionannee10a12: {
          val: '',
          per: '',
        },
        notaire0a6500: {
          val: '',
          per: '',
        },
        notaire6501a17000: {
          val: '',
          per: '',
        },
        notaire17001a60000: {
          val: '',
          per: '',
        },
        notaire60001etplus: {
          val: '',
          per: '',
        },
        montantfixeemolument: '',
        coworkerLimit: {
          decouverte: 0,
          initie: 0,
          pro: 0,
        },
      },
    }
  },
  metaInfo() {
    return {
      title: 'Espace Administrateur - Aestima',
      // keywords
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
      user: 'users/user',
      globals: 'projects/globals',
      project_tmi: 'projects/tmi',
    }),
    displayedUsers() {
      let user = []

      switch (this.type_filter) {
        case 'name':
          user = this.usersList.sort((a, b) => a.name.localeCompare(b.name))
          break
        case 'lastLoginAt':
          user = this.usersList.sort((a, b) => {
            return new Date(b.lastLoginAt).getTime() - new Date(a.lastLoginAt).getTime()
          })
          break
        case 'createdAt':
          user = this.usersList.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          })
          break
      }

      return this.paginate(user)
    },
  },
  watch: {
    usersList() {
      this.setPages()
    },
  },
  created() {
    this.newGlobals.timpositionIS = this.globals.timpositionIS
    this.newGlobals.dureeamortLMNPIS = this.globals.dureeamortLMNPIS
    this.newGlobals.plafondImpositionIS = this.globals.plafondImpositionIS
    this.newGlobals.impositionIS = this.globals.impositionIS
    this.newGlobals.tactualisation = this.globals.tactualisation
    this.newGlobals.PSbenefice = this.globals.PSbenefice
    this.newGlobals.CSG = this.globals.CSG
    this.newGlobals.cagnottenu = this.globals.cagnottenu
    this.newGlobals.dureecagnotte = this.globals.dureecagnotte
    this.newGlobals.ImpotPluval = this.globals.ImpotPluval
    this.newGlobals.PSPluval = this.globals.PSPluval
    this.newGlobals.calPluvalIR = this.globals.calPluvalIR
    this.newGlobals.calPluvalPS = this.globals.calPluvalPS
    this.newGlobals.tactualisationPer = this.globals.tactualisationPer
    this.newGlobals.revenuImpPer = this.globals.revenuImpPer
    this.newGlobals.ssi = this.globals.ssi
    if (this.globals.taxefonciere) {
      this.newGlobals.taxefonciere = this.globals.taxefonciere
      this.newGlobals.fraiscopropriete = this.globals.fraiscopropriete
      this.newGlobals.fraisassurancepno = this.globals.fraisassurancepno
      this.newGlobals.gestionlocative = this.globals.gestionlocative
      this.newGlobals.cfe = this.globals.cfe
      this.newGlobals.constitutionsociete = this.globals.constitutionsociete
      this.newGlobals.comptacga = this.globals.comptacga
      this.newGlobals.gli = this.globals.gli
      if (this.globals.tauxinteretmoins15) this.newGlobals.tauxinteretmoins15 = this.globals.tauxinteretmoins15
      this.newGlobals.tauxinteret15 = this.globals.tauxinteret15
      this.newGlobals.tauxinteret20 = this.globals.tauxinteret20
      this.newGlobals.tauxinteret25 = this.globals.tauxinteret25
      this.newGlobals.tauxassuranceemprunteur = this.globals.tauxassuranceemprunteur
      this.newGlobals.fraisdossierbancaire = this.globals.fraisdossierbancaire
      this.newGlobals.garantiebancaire = this.globals.garantiebancaire
      this.newGlobals.zonea = this.globals.zonea
      this.newGlobals.zoneabis = this.globals.zoneabis
      this.newGlobals.zoneb1 = this.globals.zoneb1
      this.newGlobals.zoneb2 = this.globals.zoneb2
      this.newGlobals.reductionannee1a9 = this.globals.reductionannee1a9
      this.newGlobals.reductionannee10a12 = this.globals.reductionannee10a12
      this.newGlobals.notaire0a6500 = this.globals.notaire0a6500
      this.newGlobals.notaire6501a17000 = this.globals.notaire6501a17000
      this.newGlobals.notaire17001a60000 = this.globals.notaire17001a60000
      this.newGlobals.notaire60001etplus = this.globals.notaire60001etplus
      this.newGlobals.montantfixeemolument = this.globals.montantfixeemolument
    }
    this.newGlobals.coworkerLimit = this.globals?.coworkerLimit || {
      decouverte: 0,
      initie: 0,
      pro: 0,
    }

    this.tmi = this.project_tmi
    axios.get('/user/userList', {
      params: {
        user: this.user._id,
      },
    })
      .then((response) => {
        this.usersList = response.data.users.filter(usr => usr.type !== 'admin123')
      })
  },
  methods: {
    ...mapActions({
      signOut: 'users/logout',
      getProjects: 'projects/getProjects',
      setProjectSelected: 'projects/setProjectSelected',
      updateGlobals: 'projects/updateGlobals',
      updateTmi: 'projects/updateTmi',
      updateCoworkerLimit: 'users/updateCoworkerLimit',
      updateLockPassword: 'users/updateLockPassword',
    }),
    async changeCoworkerLimit(user) {
      const newUser = await this.updateCoworkerLimit({ id: user._id, coworkerLimit: user.coworkerLimit })
      user.lockPassword = newUser.lockPassword
    },
    setTmi() {
      this.updateTmi(this.tmi)
    },
    setPages() {
      const numberOfPages = Math.ceil(this.usersList.length / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        if (!this.pages.includes(index)) {
          this.pages.push(index)
        }
      }
    },
    paginate(users) {
      const page = this.page
      const perPage = this.perPage
      const from = (page * perPage) - perPage
      const to = (page * perPage)
      return users.slice(from, to)
    },
    setGlobals() {
      this.newGlobals.timpositionIS.per = Number(this.newGlobals.timpositionIS.per)
      this.newGlobals.timpositionIS.val = this.newGlobals.timpositionIS.per / 100

      this.newGlobals.dureeamortLMNPIS = Number(this.newGlobals.dureeamortLMNPIS)
      this.newGlobals.plafondImpositionIS = Number(this.newGlobals.plafondImpositionIS)

      this.newGlobals.impositionIS.per = Number(this.newGlobals.impositionIS.per)
      this.newGlobals.impositionIS.val = this.newGlobals.impositionIS.per / 100

      this.newGlobals.tactualisation.per = Number(this.newGlobals.tactualisation.per)
      this.newGlobals.tactualisation.val = this.newGlobals.tactualisation.per / 100

      this.newGlobals.PSbenefice.per = Number(this.newGlobals.PSbenefice.per)
      this.newGlobals.PSbenefice.val = this.newGlobals.PSbenefice.per / 100

      this.newGlobals.CSG.per = Number(this.newGlobals.CSG.per)
      this.newGlobals.CSG.val = this.newGlobals.CSG.per / 100

      this.newGlobals.cagnottenu = Number(this.newGlobals.cagnottenu)
      this.newGlobals.dureecagnotte = Number(this.newGlobals.dureecagnotte)

      this.newGlobals.ImpotPluval.per = Number(this.newGlobals.ImpotPluval.per)
      this.newGlobals.ImpotPluval.val = this.newGlobals.ImpotPluval.per / 100

      this.newGlobals.ImpotPluval.per = Number(this.newGlobals.ImpotPluval.per)
      this.newGlobals.ImpotPluval.val = this.newGlobals.ImpotPluval.per / 100

      this.newGlobals.PSPluval.per = Number(this.newGlobals.PSPluval.per)
      this.newGlobals.PSPluval.val = this.newGlobals.PSPluval.per / 100

      this.newGlobals.revenuImpPer.microBic.per = Number(this.newGlobals.revenuImpPer.microBic.per)
      this.newGlobals.revenuImpPer.microBic.val = this.newGlobals.revenuImpPer.microBic.per / 100
      this.newGlobals.revenuImpPer.microFonc.per = Number(this.newGlobals.revenuImpPer.microFonc.per)
      this.newGlobals.revenuImpPer.microFonc.val = this.newGlobals.revenuImpPer.microFonc.per / 100

      this.newGlobals.taxefonciere.per = Number(this.newGlobals.taxefonciere.per)
      this.newGlobals.taxefonciere.val = this.newGlobals.taxefonciere.per / 100

      this.newGlobals.fraiscopropriete.per = Number(this.newGlobals.fraiscopropriete.per)
      this.newGlobals.fraiscopropriete.val = this.newGlobals.fraiscopropriete.per / 100

      this.newGlobals.fraisassurancepno.per = Number(this.newGlobals.fraisassurancepno.per)
      this.newGlobals.fraisassurancepno.val = this.newGlobals.fraisassurancepno.per / 100

      this.newGlobals.gestionlocative.per = Number(this.newGlobals.gestionlocative.per)
      this.newGlobals.gestionlocative.val = this.newGlobals.gestionlocative.per / 100

      this.newGlobals.gli.per = Number(this.newGlobals.gli.per)
      this.newGlobals.gli.val = this.newGlobals.gli.per / 100

      this.newGlobals.tauxinteretmoins15.per = Number(this.newGlobals.tauxinteretmoins15.per)
      this.newGlobals.tauxinteretmoins15.val = this.newGlobals.tauxinteretmoins15.per / 100

      this.newGlobals.tauxinteret15.per = Number(this.newGlobals.tauxinteret15.per)
      this.newGlobals.tauxinteret15.val = this.newGlobals.tauxinteret15.per / 100

      this.newGlobals.tauxinteret20.per = Number(this.newGlobals.tauxinteret20.per)
      this.newGlobals.tauxinteret20.val = this.newGlobals.tauxinteret20.per / 100

      this.newGlobals.tauxinteret25.per = Number(this.newGlobals.tauxinteret25.per)
      this.newGlobals.tauxinteret25.val = this.newGlobals.tauxinteret25.per / 100

      this.newGlobals.tauxassuranceemprunteur.per = Number(this.newGlobals.tauxassuranceemprunteur.per)
      this.newGlobals.tauxassuranceemprunteur.val = this.newGlobals.tauxassuranceemprunteur.per / 100

      this.newGlobals.fraisdossierbancaire.per = Number(this.newGlobals.fraisdossierbancaire.per)
      this.newGlobals.fraisdossierbancaire.val = this.newGlobals.fraisdossierbancaire.per / 100

      this.newGlobals.garantiebancaire.per = Number(this.newGlobals.garantiebancaire.per)
      this.newGlobals.garantiebancaire.val = this.newGlobals.garantiebancaire.per / 100

      this.newGlobals.reductionannee1a9.per = Number(this.newGlobals.reductionannee1a9.per)
      this.newGlobals.reductionannee1a9.val = this.newGlobals.reductionannee1a9.per / 100

      this.newGlobals.reductionannee10a12.per = Number(this.newGlobals.reductionannee10a12.per)
      this.newGlobals.reductionannee10a12.val = this.newGlobals.reductionannee10a12.per / 100

      this.newGlobals.notaire0a6500.per = Number(this.newGlobals.notaire0a6500.per)
      this.newGlobals.notaire0a6500.val = this.newGlobals.notaire0a6500.per / 100

      this.newGlobals.notaire6501a17000.per = Number(this.newGlobals.notaire6501a17000.per)
      this.newGlobals.notaire6501a17000.val = this.newGlobals.notaire6501a17000.per / 100

      this.newGlobals.notaire17001a60000.per = Number(this.newGlobals.notaire17001a60000.per)
      this.newGlobals.notaire17001a60000.val = this.newGlobals.notaire17001a60000.per / 100

      this.newGlobals.notaire60001etplus.per = Number(this.newGlobals.notaire60001etplus.per)
      this.newGlobals.notaire60001etplus.val = this.newGlobals.notaire60001etplus.per / 100

      if (typeof this.newGlobals.calPluvalIR == 'string') {
        this.newGlobals.calPluvalIR = this.newGlobals.calPluvalIR.split(',').map(val => Number(val))
      } else {
        this.newGlobals.calPluvalIR = this.newGlobals.calPluvalIR.map(val => Number(val))
      }
      if (typeof this.newGlobals.calPluvalPS == 'string') {
        this.newGlobals.calPluvalPS = this.newGlobals.calPluvalPS.split(',').map(val => Number(val))
      } else {
        this.newGlobals.calPluvalPS = this.newGlobals.calPluvalPS.map(val => Number(val))
      }
      if (typeof this.newGlobals.tactualisationPer == 'string') {
        this.newGlobals.tactualisationPer = this.newGlobals.tactualisationPer.split(',').map(val => Number(val))
      } else {
        this.newGlobals.tactualisationPer = this.newGlobals.tactualisationPer.map(val => Number(val))
      }

      this.updateGlobals({
        id: this.globals._id,
        globals: this.newGlobals,
      })
    },
  },
}
</script>

<style scoped>
th button {
	margin-left: 0.8rem;
	margin-right: 0.8rem;
}
</style>
