<template>
  <div class="relative">
    <ExportPDF class="inset-x-0 -mt-20" v-if="exportPopup" @close="closeExportPopup" />
    <div class="pb-10 text-left bg">
      <!-- CONTENT -->
      <div class="grid grid-cols-9">
        <aside
          class="fixed top-0 right-0 z-50 w-full h-screen overflow-auto bg-slate-700"
          :class="{
            hidden: modalPDF == false,
            block: modalPDF == true,
          }"
        >
          <div
            :class="{
              hidden: modalPDF == false,
              block: modalPDF == true,
            }"
            id="overlay"
            class="height-sidebar"
          ></div>
          <div
            :class="{
              'z-0': modalPDF == false,
              'z-100': modalPDF == true,
            }"
            class="absolute w-full height-sidebar"
          ></div>
        </aside>
        <!-- LEFT NAV BAR -->
        <div id="navbar-results" class="flex flex-col p-5 mt-5 ml-5 text-sm card col-span-2 col-start-1 rounded-xl justify-between">
          <div>
            <!-- VUE D'ENSEMBLE LINK -->
            <a
              href="#"
              class="flex flex-row py-2 pl-2 rounded-lg"
              @click="toggleComponent('vueEnsemble')"
              :class="{
                'bg-darkblueaestima-500 text-white font-bold ': isToggle.vueEnsemble,
              }"
            >
              <img
                v-if="isToggle.vueEnsemble"
                src="../assets/icons/vue_ensemble.png"
                class="h-8 mr-4"
                alt="Vue d'ensemble"
              />
              <img v-else src="../assets/icons/vue_ensemble_black.png" class="h-8 mr-4" alt="Vue d'ensemble" />
              <p class="my-auto">Vue d'ensemble</p>
            </a>
            <!-- FIN VUE D'ENSEMBLE LINK -->

            <!-- ETUDE DE MARCHE LINK -->
            <a
              href="#"
              class="flex flex-row py-2 pl-2 mt-4 rounded-lg"
              @click="toggleComponent('etudeMarche')"
              :class="{
                'bg-darkblueaestima-500 text-white font-bold ': isToggle.etudeMarche,
              }"
            >
              <img
                v-if="isToggle.etudeMarche"
                src="../assets/icons/etude_marche.png"
                class="h-8 mt-1 mr-4"
                alt="Etude de marché"
              />
              <img
                v-else
                src="../assets/icons/etude_marche_black.png"
                class="h-8 mt-1 mr-4"
                alt="Etude de marché"
              />
              <p class="my-auto">Étude de marché de la ville</p>
            </a>
            <!-- FIN ETUDE DE MARCHE LINK -->

            <!-- RESULTAT FINANCIER LINK -->
            <a
              href="#"
              class="flex flex-row py-2 pl-2 mt-5 rounded-lg"
              @click="toggleComponent('resultatFinancier')"
              :class="{
                'bg-darkblueaestima-500 text-white font-bold ': isToggle.resultatFinancier,
              }"
            >
              <img
                v-if="isToggle.resultatFinancier"
                src="../assets/icons/resultats_financier.png"
                class="h-8 mr-4"
                alt="Resultat financier"
              />
              <img
                v-else
                src="../assets/icons/resultat_financier_black.png"
                class="h-8 mr-4"
                alt="Resultat financier"
              />
              <p class="my-auto">Résultats financiers</p>
            </a>
            <!-- FIN RESULTAT FINANCIER LINK -->
            <hr class="h-2 mx-5 mt-6 text-black" />
            <a
              href="#"
              @click="showExportPopup()"
              class="flex flex-row px-6 py-2 mt-4 rounded-lg"
              style="background-color: rgba(12, 30, 59, 0.12)"
            >
              <p class="my-auto">Exporter le rapport financier</p>
            </a>
            <!-- FIN EXPORT LINK -->
          </div>
          <div>
            <div class="text-xs text-gray-500 flex place-self-end">REF : {{ projectSelected._id }}</div>
          </div>
        </div>
        <!-- FIN LEFT NAV BAR -->

        <div id="main-panneau" class="mx-5 col-span-7 col-start-3 overflow-x-hidden">
          <VueEnsemble
            v-show="isToggle.vueEnsemble"
            :salePrice="salePriceM2"
            :chartDatas="chartDatas"
            :apport="apport()"
            :cash-flow-computed="cashFlowComputed"
            :chart-insee="chartInsee"
            :messageStrategy="messageStrategy"
            :strategyIndex="strategyIndex"
          />
          <EtudeMarche
            v-show="isToggle.etudeMarche"
            :salePrice="salePriceM2"
            :chartDatas="chartDatas"
            :apport="apport()"
            :cash-flow-computed="cashFlowComputed"
            :chart-insee="chartInsee"
            :showLinear="showLinear"
            :dvf="dvf"
            :indexListDVF="indexListDVF"
            :messageStrategy="messageStrategy"
            :strategyIndex="strategyIndex"
            :chartLabels="chartLabels"
          />
          <ResultatFinancier
            v-show="isToggle.resultatFinancier"
            :rendementInvestissementFiscaMoyenne="rendementInvestissementFiscaMoyenne"
            :salePrice="salePriceM2"
            :chartDatas="chartDatas"
            :apport="apport()"
            :cash-flow-computed="cashFlowComputed"
            :chart-insee="chartInsee"
            :showLinear="showLinear"
            :dvf="dvf"
            :indexListDVF="indexListDVF"
            :messageStrategy="messageStrategy"
            :strategyIndex="strategyIndex"
            :chartLabels="chartLabels"
            :dataImpositionDetaille="dataImpositionDetaille"
            :totauxImpositionDetaille="totauxImpositionDetaille"
            :thead="thead"
            :tfoot="tfoot"
            :getResult="lesResults"
          />
        </div>
      </div>
      <div
        class="z-10 mx-5 mt-10 card rounded-xl"
        v-show="isToggle.resultatFinancier"
        id="tableau_tresorerie"
      >
        <div class="mt-5 sm:mt-0 sm:col-start-1 sm:col-end-12 rounded-xl">
          <div class="sm:rounded-xl">
            <div class="flex items-center px-4 py-5 bg-white sm:p-6 grid grid-cols-6 gap-6 rounded-xl">
              <div class="items-start justify-start text-left sm:col-start-1 sm:col-end-11">
                <h3 class="font-bold">Tableau de trésorerie complet de l’investissement</h3>
                <!-- <div v-if="isPaid" class="flex flex-row items-center justify-between"> -->
                <div>
                  <div class="mt-10">
                    <div class="-my-2 overflow-visible sm:-mx-6 lg:-mx-8">
                      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="border-b border-gray-200 shadow sm:rounded-xl">
                          <table class="min-w-full divide-y divide-gray-200">
                            <thead>
                              <tr>
                                <th
                                  v-html="thead[0]"
                                  scope="col"
                                  class="py-3 font-bold tracking-wider text-center text-gray-500 uppercase bg-gray-50 text-titre"
                                ></th>
                                <th
                                  scope="col"
                                  class="py-3 font-bold tracking-wider text-center text-gray-500 uppercase bg-gray-50 text-titre"
                                >
                                  <div class="flex flex-col items-center">
                                    <p>Investissement /</p>
                                    <p>Désinvestissement (+ travaux)</p>
                                    <QuestionMark
                                      class="ml-2"
                                      :text="`Coût d’acquisition du bien avec travaux. Prix de revente du bien en fin d’investissement.`"
                                    />
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  class="py-3 font-bold tracking-wider text-center text-gray-500 uppercase bg-gray-50 text-titre"
                                >
                                  <div class="flex flex-col items-center">
                                    <p>{{ (thead[2]) }}</p>
                                    <QuestionMark
                                      class="ml-2"
                                      :text="`Loyers annuels majorés avec l’IRL et minorés de la vacance locative.`"
                                    />
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  class="py-3 font-bold tracking-wider text-center text-gray-500 uppercase bg-gray-50 text-titre"
                                >
                                  <div class="flex flex-col items-center">
                                    <p>{{ thead[3] }}</p>
                                    <QuestionMark
                                      class="ml-2"
                                      :text="`Comprend toutes les charges récurrentes du bien comme la taxe foncière, les charges de copropriété, etc. Selon votre régime fiscal, sont également comptés les frais de comptabilité et taxes d’entreprises.`"
                                    />
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  class="py-3 font-bold tracking-wider text-center text-gray-500 uppercase bg-gray-50 text-titre"
                                >
                                  <div class="flex flex-col items-center">
                                    <p>{{ thead[4] }}</p>
                                    <QuestionMark
                                      class="ml-2"
                                      :text="`Intérêts d’emprunt majorés de l’assurance emprunteur. Les frais d’ouverture de crédit et les IRA sont également présents dans cette colonne.`"
                                    />
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  class="w-32 py-3 font-bold tracking-wider text-center text-gray-500 uppercase bg-gray-50 text-titre"
                                >
                                  <div class="flex flex-col items-center">
                                    <p>{{ thead[5] }}</p>
                                    <QuestionMark
                                      class="ml-2"
                                      :text="`Imposition sur revenus ou sociétés avec les prélèvements sociaux payés sur l’investissement. En année de revente, se rajoute l’impôt sur la plus-value.`"
                                    />
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  class="py-3 font-bold tracking-wider text-center text-gray-500 uppercase w-44 bg-gray-50 text-titre"
                                >
                                  <div class="flex flex-col items-center">
                                    <p>{{ thead[6] }}</p>
                                    <QuestionMark
                                      class="ml-2"
                                      :text="`Valeur empruntée en première année puis valeur annuelle du remboursement du capital emprunté.`"
                                    />
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  class="py-3 font-bold tracking-wider text-center text-gray-500 uppercase bg-gray-50 text-titre"
                                >
                                  <p>{{ thead[7] }}</p>
                                  <!-- <div class="flex flex-col items-center">
                                    <QuestionMark class="ml-2" :text="`Cash-flow dégagé chaque année. On ne tient pas compte ici de l’amortissement comptable du bien.`"/>
                                  </div> -->
                                </th>
                                <th
                                  scope="col"
                                  class="flex flex-col place-items-center py-3 tracking-wider text-center text-gray-500 uppercase bg-gray-50 text-titre"
                                  v-if="
                                    ['pinel_neuf', 'pinel_ancien', 'denormandie', 'malraux'].includes(
                                      projectSelected.carac.typeexploitation
                                    )
                                  "

                                >
                                  <p>{{ thead[9] }}</p>
                                  <WarningTooltip 
                                    v-if="projectSelected.results.resFluxAIFonc.cumulMalrauxReporte"
                                    text="La réduction d'impôt n'a pas été entièrement appliquée car plafonnée à l'impôt sur le revenu de l'investisseur. Néanmoins le reliquat de réduction peut s'appliquer sur les 3 années suivantes."
                                  />
                                  <!-- ici pour le warning -->
                                </th>
                                <th
                                  scope="col"
                                  class="py-3 tracking-wider text-center text-gray-500 uppercase bg-gray-50 text-titre"
                                  v-if="
                                    ['pinel_neuf', 'pinel_ancien', 'denormandie', 'malraux'].includes(
                                      projectSelected.carac.typeexploitation
                                    )
                                  "
                                >
                                  <p>{{ thead[10] }}</p>
                                </th>
                                <th
                                  scope="col"
                                  class="py-3 tracking-wider text-center text-gray-500 uppercase bg-gray-50 text-titre"
                                >
                                  <p>{{ thead[8] }}</p>
                                  <!-- <div class="z-40 flex flex-col items-center">
                                    <QuestionMark class="ml-2" :text="`Ne tient pas compte, à l’inverse du taux de rentabilité, de la revente du bien. Le calcul se base sur les revenus nets du bien divisé par son coût d’acquisition.`"/>
                                  </div> -->
                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              <tr v-for="(res, index) in lesResults" :key="index">
                                <td class="p-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index)}">
                                  <span
                                    class="flex w-20 px-2 text-xs text-center rounded-full ont-bold leading-5"
                                  >
                                    Année {{ index + 1 }}
                                  </span>
                                  <!-- <div v-if="index + 1 == 1" class="inline-block tooltip"> -->
                                  <!-- <QuestionMark/> -->
                                  <!-- <span class="text-base tooltiptext">L'année 1 = Période 1. La période débute lors du premier mois de l'acquisition et dure 12 mois.<br/> <b>Exemple :</b> si le bien est acquis en Juin 2022, année 1 = Juin 2022 Juin 2023.</span> -->
                                  <!-- </div> -->
                                </td>
                                <template v-if="isPaid">
                                  <td class="px-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index) }">
                                    <span class="inline-flex px-2 text-xs rounded-full leading-5">
                                      {{ Number(res[1]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index)}">
                                    <span class="inline-flex px-2 text-xs rounded-full leading-5">
                                      {{ Number(Math.round(res[2])).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index)}">
                                    <span class="inline-flex px-2 text-xs rounded-full leading-5">
                                      {{ Number(res[3] * -1).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index)}">
                                    <span class="inline-flex px-2 text-xs rounded-full leading-5">
                                     <!-- - {{ projectSelected && projectSelected.results && projectSelected.results.resFluxAIFonc && projectSelected.results.resFluxAIFonc.resChargesFinancieres ? Math.round(Number(projectSelected.results.resFluxAIFonc.resChargesFinancieres[index])).toLocaleString() : "indéfini" }} € -->
                                      {{  Number(chargesFinanciereData[index]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index)}">
                                    <span class="inline-flex px-2 text-xs rounded-full leading-5">
                                      {{ Number(res[5]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index)}">
                                    <span class="inline-flex px-2 text-xs rounded-full leading-5">
                                      {{ Number(res[6]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index)}">
                                    <span class="inline-flex px-2 text-xs font-bold rounded-full leading-5">
                                      {{ Number(res[7]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td
                                    class="px-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index)}"
                                    v-if="
                                      ['pinel_neuf', 'pinel_ancien', 'denormandie', 'malraux'].includes(
                                        projectSelected.carac.typeexploitation
                                      )
                                    "
                                  >
                                    <span class="inline-flex px-2 text-xs rounded-full leading-5">
                                      {{ Number(res[9]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td
                                    class="px-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index)}"
                                    v-if="
                                      ['pinel_neuf', 'pinel_ancien', 'denormandie', 'malraux'].includes(
                                        projectSelected.carac.typeexploitation
                                      )
                                    "
                                  >
                                    <span class="inline-flex px-2 text-xs font-bold rounded-full leading-5">
                                      {{ Number(res[10]).toLocaleString() }} €
                                    </span>
                                  </td>

                                  <td class="px-2 text-center whitespace-nowrap" :class="{'bg': yearIsAfterFiscalityTypeChange(index)}">
                                    <span v-if="index != lesResults.length - 1" class="inline-flex px-2 text-xs font-bold rounded-full leading-5">
                                      {{ res[8] }} %
                                    </span>
                                  </td>
                                </template>
                                <template v-else>
                                  <td class="px-2 text-center whitespace-nowrap">
                                    <span
                                      :class="{
                                        'blur-lg': index > 0,
                                        'blur-none': index == 0,
                                      }"
                                      class="inline-flex px-2 text-xs font-normal rounded-full leading-5"
                                    >
                                      {{ Number(res[1]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap">
                                    <span
                                      :class="{
                                        'blur-lg': index > 0,
                                        'blur-none': index == 0,
                                      }"
                                      class="inline-flex px-2 text-xs font-normal rounded-full leading-5"
                                    >
                                      {{ Number(Math.round(res[2])).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap">
                                    <span
                                      :class="{
                                        'blur-lg': index > 0,
                                        'blur-none': index == 0,
                                      }"
                                      class="inline-flex px-2 text-xs font-normal rounded-full leading-5"
                                    >
                                      {{ Number(res[3] * -1).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap">
                                    <span
                                      :class="{
                                        'blur-lg': index > 0,
                                        'blur-none': index == 0,
                                      }"
                                      class="inline-flex px-2 text-xs font-normal rounded-full leading-5"
                                    >
                                      {{ Number(res[4]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap">
                                    <span
                                      :class="{
                                        'blur-lg': index > 0,
                                        'blur-none': index == 0,
                                      }"
                                      class="inline-flex px-2 text-xs font-normal rounded-full leading-5"
                                    >
                                      {{ Number(res[5]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap">
                                    <span
                                      :class="{
                                        'blur-lg': index > 0,
                                        'blur-none': index == 0,
                                      }"
                                      class="inline-flex px-2 text-xs font-normal rounded-full leading-5"
                                    >
                                      {{ Number(res[6]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td class="px-2 text-center whitespace-nowrap">
                                    <span
                                      :class="{
                                        'blur-lg': index > 0,
                                        'blur-none': index == 0,
                                      }"
                                      class="inline-flex px-2 text-xs rounded-full leading-5"
                                    >
                                      {{ Number(res[7]).toLocaleString() }} €
                                    </span>
                                  </td>
                                  <td
                                    class="px-2 text-center whitespace-nowrap"
                                    v-if="
                                      ['pinel_neuf', 'pinel_ancien', 'denormandie', 'malraux'].includes(
                                        projectSelected.carac.typeexploitation
                                      )
                                    "
                                  >
                                    <span
                                      :class="{
                                        'blur-lg': index > 0,
                                        'blur-none': index == 0,
                                      }"
                                      class="inline-flex px-2 text-xs rounded-full leading-5"
                                    >
                                      ici reduction €
                                    </span>
                                  </td>

                                  <td class="px-2 text-center whitespace-nowrap">
                                    <span
                                      :class="{
                                        'blur-lg': index > 0,
                                        'blur-none': index == 0,
                                      }"
                                      class="inline-flex px-2 text-xs rounded-full leading-5"
                                    >
                                      {{ res[8] }} %
                                    </span>
                                  </td>
                                </template>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <!-- RANGEE DU BAS -->
                                <template v-if="isPaid">
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 rounded-l-xl"
                                  >
                                    {{ tfoot[0] }}
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100"
                                  >
                                    {{ Number(tfoot[1]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100"
                                  >
                                    {{ Number(tfoot[2]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100"
                                  >
                                    {{ Number(tfoot[3] * -1).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100"
                                  >
                                    {{ projectSelected.results.resFluxAIFonc.resChargesFinancieres.reduce((a, b) => a + b, 0).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100"
                                  >
                                    {{ Number(tfoot[5]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100"
                                  >
                                    {{ Number(tfoot[6]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100"
                                  >
                                    {{ Number(tfoot[7]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100"
                                    v-if="
                                      ['pinel_neuf', 'pinel_ancien', 'denormandie', 'malraux'].includes(
                                        projectSelected.carac.typeexploitation
                                      )
                                    "
                                  >
                                    {{ Number(tfoot[9]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100"
                                    v-if="
                                      ['pinel_neuf', 'pinel_ancien', 'denormandie', 'malraux'].includes(
                                        projectSelected.carac.typeexploitation
                                      )
                                    "
                                  >
                                    {{ Number(tfoot[10]).toLocaleString() }} €
                                  </th>

                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 rounded-r-xl"
                                  >
                                    {{ tfoot[8] }} %
                                  </th>
                                </template>
                                <template v-else>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 rounded-xl"
                                  >
                                    {{ tfoot[0] }}
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 blur-lg"
                                  >
                                    {{ Number(tfoot[1]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 blur-lg"
                                  >
                                    {{ Number(tfoot[2]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 blur-lg"
                                  >
                                    {{ Number(tfoot[3] * -1).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 blur-lg"
                                  >
                                    {{ Number(tfoot[4]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 blur-lg"
                                  >
                                    {{ Number(tfoot[5]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 blur-lg"
                                  >
                                    {{ Number(tfoot[6]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 blur-lg"
                                  >
                                    {{ Number(tfoot[7]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 blur-lg"
                                    v-if="
                                      ['pinel_neuf', 'pinel_ancien', 'denormandie', 'malraux'].includes(
                                        projectSelected.carac.typeexploitation
                                      )
                                    "
                                  >
                                    {{ Number(tfoot[9]).toLocaleString() }} €
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 blur-lg"
                                    v-if="
                                      ['pinel_neuf', 'pinel_ancien', 'denormandie', 'malraux'].includes(
                                        projectSelected.carac.typeexploitation
                                      )
                                    "
                                  >
                                    {{ Number(tfoot[10]).toLocaleString() }} €
                                  </th>

                                  <th
                                    scope="col"
                                    class="px-2 py-3 text-xs tracking-wider text-center text-green-800 uppercase bg-green-100 blur-lg"
                                  >
                                    {{ tfoot[8] }} %
                                  </th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <img v-else class="w-full mt-10" src="@/assets/blur/blur-array.png" alt="image floutée map"> -->
              </div>
              <div class="col-span-6 sm:col-start-5 sm:col-end-7">
                <!-- <template v-if="isPaid">
                    <pie-chart :width="200" :height="200" v-if="showLinear" class="" :options="barPieOptions(false)"
                    :chartdata="pieChartdata(['Propriétaires', 'Locataires', 'Occupants logés gratuitement'], [], [chartInsee.occ.pro, chartInsee.occ.loc, chartInsee.occ.free])"/>
                    <div v-else class="flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" display: block; shape-rendering: auto;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25" stroke-dasharray="70.68583470577033 25.561944901923447">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </circle>
                      </svg>
                    </div>
                  </template>
                  <img v-else class="inline-block w-auto mx-2 h-80" src="@/assets/results-chart-pie.png" alt="image floutée chart pie"> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- CONTENT -->
    </div>

    <div
      v-show="modalExportPayant"
      id="modalExportPDFPayant"
      tabindex="-1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-50 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
      <div class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"></div>
      <div class="relative w-full h-full max-w-md p-4 md:h-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            @click="modalExportPayant = false"
            type="button"
            class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="popup-modal"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Fermer la modal</span>
          </button>
          <div class="flex items-center justify-center p-5">
            <h3 class="font-bold text-black" style="font-size: 25px">Coup dur !</h3>
          </div>
          <p>Avec votre abonnement actuel vous ne pouvez pas exporter de dossier.</p>
          <p>
            Pour remettre à votre client un travail plus que parfait et exporter son dossier,
            <a href="#">faites évoluer votre abonnement.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Map from "@/components/Map/Map.vue";
import MapPolygon from "@/components/Map/MapPolygon.vue";
import BarChart from "@/components/Charts/BarChart.vue";
import PieChart from "@/components/Charts/PieChart.vue";
import DoughnutChart from "@/components/Charts/DoughnutChart.vue";
import ProgressBar from "@/components/Charts/ProgressBar.vue";
import QuestionMark from "@/components/SVG/QuestionMark.vue";
import VueEnsemble from "@/components/result/VueEnsemble";
import WarningTooltip from "@/components/SVG/WarningTooltip.vue";

import rapportFinancierSeul from "@/components/PDF/rapportFinancierSeul.vue";
import ImpositionDetaille from "@/components/popup/ImpositionDetaille";
import ExportPDF from "@/components/popup/ExportPDF";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";
import ProjectTools from '@/tools/Project'
import EtudeMarche from "../components/result/EtudeMarche.vue";
import ResultatFinancier from "../components/result/ResultatFinancier.vue";

export default {
  name: "Results",
  data() {
    return {
      lesResults: [],
      rendementInvestissementFiscaMoyenne: 0,
      modalExportPayant: false,
      modalPDF: false,
      exportPopup: false,
      isToggle: {
        vueEnsemble: true,
        etudeMarche: false,
        resultatFinancier: false,
      },
      partEconomieFiscale: 0,
      file: null,
      isfileError: false,
      fileMessage: "",
      resultatFinancier: true,
      showDetailFinance: false,
      impositionDetaille: false,
      dataImpositionDetaille: [],
      totauxImpositionDetaille: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      dvf: [],
      zoomValue: 250,
      chartLabels: [],
      chartDatas: [],
      salePriceM2: {},
      chartInsee: {},
      showLinear: false,
      indexListDVF: null,
      center: [],
      dataGouvGeo: {},

      inseeYear: "2017",

      // TO-DO: A la place, récupérer l'objet simulation (projet) avec le flag isPaid
      // isPaid: true,
      // TO-DO: Récupérer longitude et latitude du bien enregistré dans la simulation (projet)
      lon: "-0.630386",
      lat: "44.80583",
      thead: [
        "Période",
        "Investissement / Désinvestissement (+ travaux)",
        "Revenus locatifs nets",
        "Charges locatives",
        "Charges financières",
        "Imposition",
        "Emprunt et remboursement du capital ",
        "Trésorerie annuel",
        "Taux de rendement net",
        "Réduction d'impôts",
        "Trésorerie annuelle nette"
      ],
      tfoot: ["Total"],
      tbody: [],
      impotHorsAnneeRevente: null,
      isModal: false,
      messageModal: "Vous allez utiliser 1 crédit aestima.",
      chargesFinanciereData : [],
      messageStrategy: [
        {
          id: "retraite",
          intro: {
            title:
              "Dans votre cas, vous souhaitez un placement sécurisé à long terme. Assurez-vous donc en priorité des points suivants :",
            subTitle: [
              "La ville que vous avez choisi doit être dynamique (population active) et ses prix immobiliers doivent être stables et croissants.",
              "L'effort d'épargne mensuel et annuel doit être soutenable pour vous.",
              `Pour plus de recommandations adaptées à votre profil d’investisseur, cliquez sur les symboles <img class='inline-block w-auto h-6 mx-2' src='${require("@/assets/picto/Panneau.png")}' alt='pictogramme panneau attention'> présents sur la page.`,
            ],
          },
          cashflowMens:
            "Assurez-vous que l'effort d'épargne mensuel et annuel que réclame l’investissement soit soutenable pour vous à long terme. Cette somme ne doit pas engendrer une baisse de votre niveau de vie.",
          rendementhv:
            "Pour que cet investissement soit correct en terme de performances, le rendement net de fiscalité hors revente doit être supérieur à 2%.",
          rendementnetaf:
            "Pour que cet investissement soit correct en terme de performances, la rentabilité nette après fiscalité doit être supérieure à 3%.",
          evolprice: "L'évolution des prix immobiliers de la ville doit être positive.",
          pricem2: "",
          loyer: "",
          historyprice:
            "Par sécurité, assurez-vous que la ville choisie pour votre investissement ait un marché immobilier dit ''liquide'' c’est-à-dire où un bien peut être revendu facilement. Pour cela, consultez le volume et l'historique des ventes sur notre carte interactive. ",
          inseepop: "Assurez-vous que les personnes actifs ayant un emploi représentent plus de 50% de la population.",
          inseelog:
            "Assurez-vous que le pourcentage de résidences principales soit largement majoritaire par rapport aux résidences secondaires.",
        },
        {
          id: "patrimoine",
          intro: {
            title:
              "Dans votre cas, vous souhaitez un placement sécurisé mais un rendement optimisé.Vous devez donc en priorité utiliser le module d'optimisation pour booster les performances de votre simulation. Ensuite, assurez-vous en priorité des points suivants :",
            subTitle: [
              "Il vous reste suffisament d'épargne personnelle même après avoir fournir l'apport financier de départ.",
              "La ville que vous avez choisi doit être dynamique (population active) et ses prix immobiliers doivent être stables et croissants.",
              "L'effort d'épargne mensuel et annuel est soutenable pour vous.",
              `Pour plus de recommandations adaptées à votre profil d’investisseur, cliquez sur les symboles <img class='inline-block w-auto h-6 mx-2' src='${require("@/assets/picto/Panneau.png")}' alt='pictogramme panneau attention'> présents sur la page.`,
            ],
          },
          cashflowMens:
            "Assurez-vous que l'effort d'épargne mensuel et annuel que réclame l’investissement soit soutenable pour vous à long terme. Cette somme ne doit pas engendrer une baisse de votre niveau de vie.",
          rendementhv: "",
          rendementnetaf:
            "Pour que cet investissement soit correct en terme de performances, la rentabilité nette après fiscalité doit être supérieure à 5%.",
          evolprice: "L'évolution des prix immobiliers de la ville doit être positive.",
          pricem2:
            "Votre prix d'achat au m2 (travaux compris) doit se situer dans la fourchette basse ou moyenne du marché.",
          loyer: "",
          historyprice: "",
          inseepop: "Assurez-vous que les personnes actifs ayant un emploi représentent plus de 50% de la population.",
          inseelog: "",
        },
        {
          id: "revenus",
          intro: {
            title:
              "Dans votre cas, vous souhaitez un placement rémunérateur le plus rapidement possible. Vous devez donc en priorité utiliser le module d'optimisation pour booster les performances de votre simulation. Ensuite, assurez-vous en priorité des points suivants :",
            subTitle: [
              "Votre trésorerie personnelle est suffisante pour couvrir des frais imprévus ou des impayés même en ayant souscrit à une garantie loyers impayés.",
              "Cette stratégie réclame un important investissement en temps. Assurez-vous d'avoir du temps disponible malgré la mise en gestion de votre bien.",
              `Pour plus de recommandations adaptées à votre profil d’investisseur, cliquez sur les symboles <img class='inline-block w-auto h-6 mx-2' src='${require("@/assets/picto/Panneau.png")}' alt='pictogramme panneau attention'> présents sur la page.`,
            ],
          },
          cashflowMens: "Le cash-flow mensuel doit être impérativement supérieur à 0.",
          rendementhv: "",
          rendementnetaf: "",
          evolprice: "",
          pricem2:
            "Votre prix d'achat au m2 (travaux compris) doit se situer dans la fourchette basse ou moyenne du marché.",
          loyer:
            "Votre loyer peut être dans la fourchette moyenne haute pour des logements similaires à condition que votre bien locatif et ses prestations soient qualitatifs (vue, équipement, emplacement, etc.). Un loyer élevé pour un logement classique risque de faire augmenter la rotation de vos locataires et donc votre vacance locative.",
          historyprice: "",
          inseepop: "",
          inseelog: "",
        },
        {
          id: "defiscaliser",
          intro: {
            title:
              "Dans votre cas, vous souhaitez un revendre rapidement votre bien pour dégager une plus-value. Vous devez donc en priorité utiliser le module de comparaison pour solidifier votre scénario de revente. Ensuite, assurez-vous en priorité des points suivants : ",
            subTitle: [
              "L'effort d'épargne mensuel et annuel est soutenable pour vous et votre trésorerie personnelle est suffisante pour couvrir des frais imprévus ou des impayés.",
              "Cette stratégie réclame un important investissement en temps. Assurez-vous d'avoir du temps disponible pour gérer les travaux et la gestion du projet.",
              `Pour plus de recommandations adaptées à votre profil d’investisseur, cliquez sur les symboles <img class='inline-block w-auto h-6 mx-2' src='${require("@/assets/picto/Panneau.png")}' alt='pictogramme panneau attention'> présents sur la page.`,
            ],
          },
          cashflowMens: "",
          rendementhv: "",
          rendementnetaf:
            "Pour que cet investissement soit correct en terme de performances, la rentabilité nette après fiscalité doit être supérieure à 15%.",
          evolprice: "",
          pricem2:
            "Votre prix d'achat au m2 (travaux compris) doit se situer en dessous de la fourchette basse du marché.",
          loyer: "",
          historyprice:
            "Vous revendrez facilement votre bien en vérifiant que le marché immobilier de votre ville ou quartier est liquide. Pour cela, consultez le volume l'historique des ventes sur notre carte interactive. ",
          inseepop: "",
          inseelog: "",
        },
      ],
      exportFinance: {
        showExportFinance: false,
        presentation: "",
        showMarketLoy: true,
        showMarketEvol: true,
        showMarketLoc: true,
        marketComment: "",
        synthesis: "",
        societyName: "",
        showAmo: false,
        showTreso: true,
        PDFname: "",
        files: null,
      },

      showExportBancaire: false,
      modalPDFLoader: false,
      uploadErrorMessage: null,
    };
  },
  props: {},
  metaInfo() {
    return {
      title: `Resultats - Aestima`,
      meta: [
        {
          name: "description",
          content: "Cette page est la page de profil de l'utilisateur.",
        },
      ],
      //keywords
    };
  },
  components: {
    VueEnsemble,
    Map,
    BarChart,
    PieChart,
    DoughnutChart,
    ExportPDF,
    MapPolygon,
    ProgressBar,
    QuestionMark,
    rapportFinancierSeul,
    ImpositionDetaille,
    EtudeMarche,
    ResultatFinancier,
    WarningTooltip,
  },
  mounted() {
    this.center = [this.projectSelected.loc.carac.coordinates[1], this.projectSelected.loc.carac.coordinates[0]];
    this.lesResults = this.getResult;
    let typeLocal =
      this.projectSelected.carac.categ === "appartement" ||
        this.projectSelected.carac.categ === "appartement" ||
        this.projectSelected.carac.categ === "appartement"
        ? "Appartement"
        : "Maison";
    let nbPieceLocal = 0;
    if (typeLocal == "Appartement") {
      if (
        this.projectSelected.carac.type == "CH" ||
        this.projectSelected.carac.type == "ST" ||
        this.projectSelected.carac.type == "T1"
      ) {
        nbPieceLocal = 1;
      } else if (this.projectSelected.carac.type == "T2") {
        nbPieceLocal = 2;
      } else if (this.projectSelected.carac.type == "T3") {
        nbPieceLocal = 3;
      } else if (this.projectSelected.carac.type == "T4") {
        nbPieceLocal = 4;
      } else if (this.projectSelected.carac.type == "T5") {
        nbPieceLocal = 5;
      }
    }
    // console.log(this.projectSelected.loc.carac.citycode)
    axios
      .all([
          // axios.get("/apidvfmap", {
          //   params: {
          //     dist: this.zoomValue,
          //     lat: this.projectSelected.loc.carac.coordinates[1],
          //     lon: this.projectSelected.loc.carac.coordinates[0],
          //   },
          // }),
          // axios.get("/dataPriceEvolution", {
          //   params: {
          //     code_commune: this.projectSelected.loc.carac.citycode,
          //     nature_mutation: "Vente",
          //     type_local: typeLocal,
          //     nb_piece: nbPieceLocal,
          //   },
          // }),
        // axios.get("/dataPriceMoy", {
        //   params: {
        //     code_commune: this.projectSelected.loc.carac.citycode,
        //     nature_mutation: "Vente",
        //     type_local: typeLocal,
        //     nb_piece: nbPieceLocal,
        //   },
        // }),
        axios.get("/apiinsee", {
          params: {
            code_commune: this.projectSelected.loc.carac.citycode,
          },
        }),
        axios.get("/apigouvgeo", {
          params: {
            code_commune: this.projectSelected.loc.carac.citycode,
          },
        }),
      ])
      .then(
        axios.spread((response4, response5) => {
            // this.dvf = response1.data.features;
            // this.center = [this.dvf[0].properties.lat, this.dvf[0].properties.lon]

            // for (const [key, value] of Object.entries(response2.data)) {
            //   this.chartLabels.push(key);
            //   this.chartDatas.push(value.moy);
            // }

          // this.salePriceM2 = response3.data;
          this.chartInsee = response4.data;

          this.dataGouvGeo = response5.data[0];

          this.showLinear = true;
          // console.log("this.chartLabels",this.chartLabels, "this.chartDatas", this.chartDatas)
        })
      )
      .catch((err) => {
        console.log(err.response.data.message);
      });
  },
  created: function () { this.setBankPDFBool() },
  computed: {
    ...mapGetters({
      authenticated: "users/authenticated",
      user: "users/user",
      listProj: "projects/projects",
      projectSelected: "projects/selected",
      formeJuridiqueProject: "projects/forme_juridique_project",
      investSelected: "invests/selected",
      projectBankPdfBoolean: "projects/project_bank_pdf_boolean",
      tmi: 'projects/tmi',
      globals: 'projects/globals'
    }),
    strategyIndex: {
      get: function () {
        if (this.investSelected.type_strategy == "retraite") {
          return 0;
        } else if (this.investSelected.type_strategy == "patrimoine") {
          return 1;
        } else if (this.investSelected.type_strategy == "revenus") {
          return 2;
        } else if (this.investSelected.type_strategy == "defiscaliser") {
          return 3;
        }
      },
      set: function (val) { },
    },
    isPaid: {
      get: function () {
        return this.user.sub_level != 0 || this.projectSelected.isPaid == true ? true : false;
      },
      set: function (val) { },
    },
    titleCategBien: {
      get: function () {
        let categName = "";
        if (this.projectSelected.carac.categ == "appartement") {
          categName = "Appartements";
        } else if (this.projectSelected.carac.categ == "maison_individuelle") {
          categName = "Maisons";
        } else if (this.projectSelected.carac.categ == "immeuble_residentiel") {
          categName = "Appartements";
        } else if (this.projectSelected.carac.categ == "immeuble_de_rapport") {
          categName = "Appartements";
        }

        return categName;
      },
      set: function (val) { },
    },
    coutTotInvestCalc: {
      get: function () {
        let ct =
          -Math.round(this.projectSelected.results.resCoutTotalFonc.resPrixAchat[0]) +
          -Math.round(
            this.projectSelected.results.resCoutTotalFonc.resFraisNotaire[0] +
            this.projectSelected.results.resCoutTotalFonc.resFraisAgenceImmo[0]
          ) +
          -Math.round(
            this.projectSelected.results.resOperationNetFonc.resTotFraisExploit +
            this.projectSelected.results.resOperationNetFonc.resTotStructureSCI +
            this.projectSelected.results.resInvTravFonc.resTotalInvTrav
          ) +
          -Math.round(
            this.projectSelected.results.resInvTravFonc.resTotFraisBanc +
            this.projectSelected.results.resInvTravFonc.resTotItr +
            this.projectSelected.results.resInvTravFonc.resTotAssEmpr
          ) +
          this.impotHorsAnneeReventeComputed;
        return ct;
      },
      set: function (val) { },
    },
    impotHorsAnneeReventeComputed: {
      get: function () {
        let impotAnneeRevente = 0;
        let dureeempr =
          this.projectSelected.carac.dureedetention.isAuto == true
            ? Number(this.projectSelected.carac.dureedetention.valAuto / 12)
            : Number(this.projectSelected.carac.dureedetention.val);

        for (let i = 0; i <= dureeempr; i++) {
          if (i != dureeempr) {
            impotAnneeRevente += this.projectSelected.results.resFluxAIFonc.resImposition[i];
          }
        }

        return -Math.round(impotAnneeRevente);
      },
      set: function (val) { },
    },
    cashFlowComputed: {
      get: function () {
        let cashFlowAvantRemb = 0,
          cashFlowAvantRembCounter = 0,
          cashFlowApresRemb = 0,
          cashFlowApresRembCounter = 0,
          resCashFlowAvantRemb = 0,
          resCashFlowApresRemb = 0;

        for (let i = 0; i <= this.projectSelected.results.timelineFonc.resTimelineEmpr.length; i++) {
          if (i > 0 && this.projectSelected.results.timelineFonc.resTimelineEmpr[i] == 1) {
            cashFlowAvantRemb += this.projectSelected.results.resFluxAIFonc.resCashFlowNonActu[i];
            cashFlowAvantRembCounter += 1;
          }
          if (i > 0 && this.projectSelected.results.timelineFonc.resTimelineEmpr[i] == 0 && this.projectSelected.results.timelineFonc.resDateRevente[i] == 0) {
            cashFlowApresRemb += this.projectSelected.results.resFluxAIFonc.resCashFlowNonActu[i];
            cashFlowApresRembCounter += 1;
          }
        }
        resCashFlowAvantRemb = isNaN(Math.round((cashFlowAvantRemb / cashFlowAvantRembCounter) / 12))
          ? 0
          : Math.round((cashFlowAvantRemb / cashFlowAvantRembCounter) / 12);
        resCashFlowApresRemb = isNaN(Math.round((cashFlowApresRemb / cashFlowApresRembCounter) / 12))
          ? 0
          : Math.round((cashFlowApresRemb / cashFlowApresRembCounter) / 12);

        return [resCashFlowAvantRemb, resCashFlowApresRemb];
      },
      set: function (val) { },
    },
    chartLabelsComputed: {
      get: function () {
        return this.chartLabels;
      },
      set: function (val) { },
    },
    chartDatasComputed: {
      get: function () {
        return this.chartDatas;
      },
      set: function (val) { },
    },
    sameAdresse: {
      get: function () {
        let arrayAdresse = [];
        arrayAdresse = this.dvf.filter((v, i, a) => {
          return (
            this.dvf.findIndex(
              (t) => t.properties.lat === v.properties.lat && t.properties.lon === v.properties.lon
            ) === i
          );
        });

        arrayAdresse.map((value, index) => {
          arrayAdresse[index].sameAdresse = this.dvf.filter((data) => {
            return value.properties.lon == data.properties.lon && value.properties.lat == data.properties.lat;
          });
        });

        return arrayAdresse;
      },
      set: function (val) { },
    },
    getResult: {
      get: function () {
        let dureeempr =
          this.projectSelected.carac.dureedetention.isAuto == true
            ? Number(this.projectSelected.carac.dureedetention.valAuto / 12)
            : Number(this.projectSelected.carac.dureedetention.val);
        let tmp = [];
        let resultArray = [];
        let somme_4an_investissement = 0
        if(this.projectSelected.results.resInvTravFonc.resInvestissement){
          somme_4an_investissement = this.projectSelected.results.resInvTravFonc.resInvestissement[0] + this.projectSelected.results.resInvTravFonc.resInvestissement[1] +
                                      this.projectSelected.results.resInvTravFonc.resInvestissement[2] +  this.projectSelected.results.resInvTravFonc.resInvestissement[3]
        }
      for (let i = 0; i <= dureeempr; i++) {
          try {
            let revenusLocatifs = this.projectSelected.results.resOperationNetFonc.resRevLoc[i];
            let fraisExploit = this.projectSelected.results.resOperationNetFonc.resFraisExploit[i];
            let fraisBancaires = this.projectSelected.results.resInvTravFonc.resFraisBanc[i];
            let coutTotalAcqui = this.projectSelected.results.resCoutTotalFonc.resCoutTotalAcqui[i];
            let structSCI = this.projectSelected.results.resOperationNetFonc.resStructureSCI[i];
            let imposition = this.projectSelected.results.resFluxAIFonc.resImposition[i];
            let invTravaux = this.projectSelected.results.resInvTravFonc.resInvTrav[i];
            let assEmprunt = this.projectSelected.results.resInvTravFonc.resAssEmpr[i];
            let indemRembDette = this.projectSelected.results.resInvTravFonc.resIndemRmbDette[i]
              ? this.projectSelected.results.resInvTravFonc.resIndemRmbDette[i]
              : 0;
            let emprunt = this.projectSelected.results.resInvTravFonc.resEmprunt[i]
            let rembDette = this.projectSelected.results.resInvTravFonc.resRmbDette[i]
            let interets = this.projectSelected.results.resInvTravFonc.resItr[i];
            let rembCapital = this.projectSelected.results.resInvTravFonc.resRmbCap[i]
            let CapFinPeriode = 0
            let assurance = 0
            let partInteret = 0
            let annuite = 0
            let montantEmprunte = this.projectSelected.carac.montantemprunte.val
            if (typeof this.projectSelected.carac.amortData != 'undefined') {

              CapFinPeriode = this.projectSelected.carac.amortData[i]?.amortCapRemb
                ? this.projectSelected.carac.amortData[i].amortCapRemb
                : 0;
              assurance = this.projectSelected.carac.amortData[i]?.amortAssur
                ? this.projectSelected.carac.amortData[i].amortAssur
                : 0;
              partInteret = this.projectSelected.carac.amortData[i]?.amortPartInt
                ? this.projectSelected.carac.amortData[i].amortPartInt
                : 0;
              annuite = this.projectSelected.carac.amortData[i]?.amortAnnuit
                ? this.projectSelected.carac.amortData[i].amortAnnuit
                : 0;
              if(this.projectSelected.carac.modefinancement == "fond_propre"){
                assurance = 0
                annuite = 0
              }
            }


            tmp.push(i);
            let investissement = 0
            if(this.projectSelected.results.resInvTravFonc.resInvestissement){
              investissement = this.projectSelected.results.resInvTravFonc.resInvestissement[i]
            }
            // Investissement / desinvestissement
            tmp.push(investissement);
            // Revenus locatifs
            // tmp.push(revenusLocatifs);
            tmp.push(revenusLocatifs);
            let chargesLocatives = -(fraisExploit + structSCI)
            // Charges locatives
            tmp.push(chargesLocatives);
            let chargesFinancieres = ((-annuite * this.projectSelected.results.timelineFonc.resTimelineEmpr[i] + -assurance * this.projectSelected.results.timelineFonc.resTimelineEmpr[i]) - (-rembCapital * this.projectSelected.results.timelineFonc.resTimelineEmpr[i])  + fraisBancaires + indemRembDette)
            // Charges financieres
            tmp.push(chargesFinancieres);
            this.chargesFinanciereData.push(chargesFinancieres)

            // Imposition
            tmp.push(Math.round(imposition));

            // Emprunt et remboursement du capital=
            let EmpruntEtRemboursementDuCapital = -rembCapital + emprunt + -rembDette
            tmp.push(EmpruntEtRemboursementDuCapital);
            let frais_pro = 0

            let tresorerieAnnuelle = Math.round((investissement + revenusLocatifs + -chargesLocatives + chargesFinancieres + frais_pro) + imposition + EmpruntEtRemboursementDuCapital)
            // Tresorerie annuelle avant impots
            tmp.push(tresorerieAnnuelle)
            // tmp.push(
            //   Math.round(
            //     coutTotalAcqui +
            //       invTravaux +
            //       revenusLocatifs +
            //       (fraisExploit + structSCI) -
            //       (-fraisBancaires + partInteret + assurance - indemRembDette) +
            //       imposition +
            //       EmpruntEtRemboursementDuCapital
            //   )
            // );

            let rendement_net = ((((revenusLocatifs + -chargesLocatives + chargesFinancieres + frais_pro) + imposition) / -somme_4an_investissement) * 100).toFixed(1)
            // Taux de rendement net
            tmp.push(rendement_net);
            // reduction d'impots
            let reduction_impots = 0
            if(this.projectSelected.results.resOperationNetFonc.reductionImpots){
              reduction_impots = this.projectSelected.results.resOperationNetFonc.reductionImpots[i]
            }
            tmp.push(reduction_impots)
            //Trésorerie annuelle nette
            tmp.push(tresorerieAnnuelle + reduction_impots)

            // console.log('TMP', tmp)
            resultArray.push(tmp);

            tmp = [];

            let results_calcul_imposition = ProjectTools.calculTableauImposition(this.projectSelected, i, this.dataImpositionDetaille, this.investSelected, this.tmi, this.globals)
            results_calcul_imposition.tableau_imposition[3] = this.projectSelected.results.resOperationNetFonc.resAutresFrais[i]
            results_calcul_imposition.tableau_imposition[5] = this.projectSelected.results.resFluxAIFonc.resBaseImposable[i]
            results_calcul_imposition.tableau_imposition[6] = this.projectSelected.results.resFluxAIFonc.resCagnotteReportable[i]
            results_calcul_imposition.tableau_imposition[7] = this.projectSelected.results.resFluxAIFonc.resBaseImposableNette[i]
            this.dataImpositionDetaille.push(results_calcul_imposition.tableau_imposition);

            this.totauxImpositionDetaille[0] += Number(results_calcul_imposition.tableau_imposition[0]);
            this.totauxImpositionDetaille[1] += Number(results_calcul_imposition.tableau_imposition[1]);
            this.totauxImpositionDetaille[2] += Number(results_calcul_imposition.tableau_imposition[2]);
            this.totauxImpositionDetaille[3] += Number(results_calcul_imposition.tableau_imposition[3]);
            this.totauxImpositionDetaille[4] += Number(results_calcul_imposition.tableau_imposition[4]);
            this.totauxImpositionDetaille[5] += Number(results_calcul_imposition.tableau_imposition[5]);
            this.totauxImpositionDetaille[6] += Number(results_calcul_imposition.tableau_imposition[6]);
            this.totauxImpositionDetaille[7] += Number(results_calcul_imposition.tableau_imposition[7]);
            this.totauxImpositionDetaille[8] += Number(results_calcul_imposition.tableau_imposition[9]);
          } catch (error) {
            console.log(error);
          }
        }
        // console.log(resultArray)
        const reducer = (accumulator, currentValue) => accumulator + currentValue;

        // let totInvest = resultArray.reduce((accumulator, currentValue) => accumulator[1] + currentValue[1])
        let totInvest = 0,
          totRevLoc = 0,
          totChargeLoc = 0,
          totChargeFin = 0,
          totImp = 0,
          totEmpRemb = 0,
          totTreso = 0,
          totTauxRend = 0,
          totReduction = 0,
          totTresoNette = 0;
        let totTauxRendCounter = 0;
        resultArray.map((val) => {
          totInvest += val[1];
          totRevLoc += val[2];
          totChargeLoc += val[3];
          totChargeFin += val[4];
          totImp += val[5];
          totEmpRemb += val[6];
          totTreso += val[7];
          totTauxRend += Number(val[8]);
          totTauxRendCounter += 1;
          totReduction += val[9];
          totTresoNette += val[10];
        });
        this.rendementInvestissementFiscaMoyenne = (totTauxRend / totTauxRendCounter).toFixed(2)
        this.tbody = resultArray;
        this.tfoot = [
          "TOTAL",
          Math.round(totInvest),
          Math.round(totRevLoc),
          totChargeLoc,
          totChargeFin,
          totImp,
          totEmpRemb,
          totTreso,
          (totTauxRend / totTauxRendCounter).toFixed(2),
          totReduction,
          totTresoNette
        ];
        this.partEconomieFiscale = totReduction

        let tableauImpos = [this.totauxImpositionDetaille, this.dataImpositionDetaille];

        axios.post(`/project/tab/${this.user._id}/${this.projectSelected._id}`, {
          params: {
            TableauTresorerie: resultArray,
            TableauImposition : tableauImpos,
          },
        });

        return resultArray;
      },
    },
  },
  methods: {
    ...mapActions({
      signOut: "users/logout",
      getProjects: "projects/getProjects",
      setProjectSelected: "projects/setProjectSelected",
      updateUser: "users/updateUser",
      updateUserMDP: "users/updateUserMDP",
      unlockProject: "projects/unlockProject",
      generateBankPDF: "projects/generateBankPDF",
      setBankPDFBool: "projects/setBankPDFBool",
      refreshUserInformation: "users/setUserRefreshed",
    }),
    // scroll() {
    //   window.onscroll = () => {
    //     let el_data = document.getElementById('tableau_tresorerie').getBoundingClientRect()
    //     let results = (el_data.y <= (window.innerHeight || document.documentElement.clientHeight) - 150 && el_data.y != 0);
    //     if (results) {
    //       document.getElementById('navbar-results').classList.add('hidden')
    //     } else {
    //       document.getElementById('navbar-results').classList.remove('hidden')

    //     }
    //   }
    // },
    toggleComponent(view){
      switch(view){
        case 'vueEnsemble':
          this.isToggle.vueEnsemble = true
          this.isToggle.etudeMarche = false
          this.isToggle.resultatFinancier = false
          break;
        case 'etudeMarche':
          this.isToggle.vueEnsemble = false
          this.isToggle.etudeMarche = true
          this.isToggle.resultatFinancier = false
          break;
        case 'resultatFinancier':
          this.isToggle.vueEnsemble = false
          this.isToggle.etudeMarche = false
          this.isToggle.resultatFinancier = true
          break;
      }
      console.log(this.isToggle);
    },
    yearIsAfterFiscalityTypeChange(year){
      const fiscalityChangeActivated = this.projectSelected.carac.changementDeFiscalite
      if (!fiscalityChangeActivated) return false

      const fiscalChangeYear = this.projectSelected.carac.engagementPlusTravaux
      return fiscalChangeYear <= year
    },
    showExportPopup() {
      this.exportPopup = true
    },
    closeExportPopup() {
      this.exportPopup = false
    },
    onSelect(event) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const file = event.target.files[0];

      const maxSize = 5*1024*1024;
      if (!allowedTypes.includes(file.type) && file.size > maxSize) {
        this.file = null;
        this.fileMessage = "";
        this.isfileError = true;
        this.fileMessage = "Le type du fichier n'est pas bon ! La taille de l'image est supérieure à 5MB !";
	alert('Le type du fichier n\'est pas bon ! La taille de l\'image est supérieure à 5MB !');
      } else if (!allowedTypes.includes(file.type)) {
        this.file = null;
        this.fileMessage = "";
        this.isfileError = true;
        this.fileMessage = "Le type du fichier n'est pas bon !";
      } else if (file.size > maxSize) {
        this.file = null;
        this.fileMessage = "";
        this.isfileError = true;
        this.fileMessage = "La taille de l'image est supérieure à 5MB !";
	alert("Attention ! L'image n'a pas pu être chargée car elle dépasse 5 Mo. Sélectionnez-en une autre.");
      } else {
        this.file = file;
        this.fileMessage = "";
        this.isfileError = false;
      }
    },
    uploadFile(event) {
      this.uploadErrorMessage = null;
      if (event.target.files.length <= 4) {
        this.exportFinance.files = event.target.files;
      } else {
        this.uploadErrorMessage = "4 fichiers max.";
      }
    },
    submitPDF() {
      // Conditionne le choix de l'export BANCAIRE ou FINANCIER
      // if() {
        console.log("submitPDF !!!!")
      // }
      try {
        if (this.uploadErrorMessage != null) {
        return;
      }
      this.uploadErrorMessage = null;
      let formData = null;
      if (this.exportFinance.files == null) {
        formData = null;
      } else {
        formData = new FormData();
        for (const i of Object.keys(this.exportFinance.files)) {
          formData.append("files", this.exportFinance.files[i]);
        }
      }
      if (this.file != null) {
        if (formData == null) formData = new FormData();
        formData.append("files", this.file, "logo");
      }

      this.modalPDFLoader = true;

      this.exportFormDatas = { ...this.exportFinance };
      this.exportFormDatas.presentation = encodeURI(JSON.stringify(this.exportFormDatas.presentation));
      this.exportFormDatas.marketComment = encodeURI(JSON.stringify(this.exportFormDatas.marketComment));
      this.exportFormDatas.synthesis = encodeURI(JSON.stringify(this.exportFormDatas.synthesis));
      this.exportFormDatas.societyName = encodeURI(JSON.stringify(this.exportFormDatas.societyName));
      this.exportFormDatas.PDFname = encodeURI(JSON.stringify(this.exportFormDatas.PDFname));

      this.generateBankPDF({
        formData: formData,
        data: {
          ...this.exportFormDatas,
          rendementInv: this.tfoot[8],
          rentaNetAvtF:
            Number(this.projectSelected.results.resFluxAIFonc.performance.avecDette.tir) == 0
              ? "Negatif"
              : this.projectSelected.results.resFluxAIFonc.performance.avecDette.tir,
          rentaNetAprF:
            Number(this.projectSelected.results.resFluxAIFonc.performance.avecFisc.tir) == 0
              ? "Negatif"
              : this.projectSelected.results.resFluxAIFonc.performance.avecFisc.tir,
          profitTotal: this.numberWithSpaces(
            Math.round(this.projectSelected.results.resFluxAIFonc.rendementTotal.total.val)
          ),
          rendementInt:
            Number(this.projectSelected.results.resFluxAIFonc.performance.tir) == 0
              ? "Negatif"
              : this.projectSelected.results.resFluxAIFonc.performance.tir,
          van: this.numberWithSpaces(Math.round(this.projectSelected.results.resFluxAIFonc.resTotCashFlowActu)),
          indiceProf: this.projectSelected.results.resFluxAIFonc.performance.avecFisc.indprof
            ? Number(this.projectSelected.results.resFluxAIFonc.performance.avecFisc.indprof.toFixed(1))
            : 0,
          levierEmprunt: this.projectSelected.results.resFluxAIFonc.performance.avecDette.effetlevier
            ? Number(this.projectSelected.results.resFluxAIFonc.performance.avecDette.effetlevier.toFixed(2))
            : 0,
          apportFournir: this.numberWithSpaces(
            Math.round(
              this.projectSelected.results.resCoutTotalFonc.resCoutTotalAcqui[0] +
              this.projectSelected.results.resInvTravFonc.resEmprunt[0] +
              this.projectSelected.results.resInvTravFonc.resFraisBanc[0] +
              this.projectSelected.results.resInvTravFonc.resInvTrav[0]
            )
          ),
          cashflowAvtR: this.numberWithSpaces(this.cashFlowComputed[0]),
          cashflowAprR: this.numberWithSpaces(this.cashFlowComputed[1]),
          effortEpargne: this.numberWithSpaces(
            -Math.round(this.projectSelected.results.resFluxAIFonc.resTotSommeInvest)
          ),
          thead: encodeURI(JSON.stringify(this.thead)),
          tbody: this.tbody,
          tfoot: this.tfoot,
          coutTotInv: this.coutTotInvestCalc,
          impotHAR: this.impotHorsAnneeReventeComputed,
          chartDatas: {
            chartLabels: this.chartLabels,
            chartDatas: this.chartDatas,
            chartInsee: this.chartInsee,
            salePriceM2: this.salePriceM2,
            coutTotalInvest: [
              Math.round(
                (-Math.round(this.projectSelected.results.resCoutTotalFonc.resPrixAchat[0]) * 100) /
                Math.round(this.coutTotInvestCalc)
              ),
              Math.round(
                (-Math.round(
                  this.projectSelected.results.resCoutTotalFonc.resFraisNotaire[0] +
                  this.projectSelected.results.resCoutTotalFonc.resFraisAgenceImmo[0]
                ) *
                  100) /
                Math.round(this.coutTotInvestCalc)
              ),
              Math.round(
                (-Math.round(
                  this.projectSelected.results.resOperationNetFonc.resTotFraisExploit +
                  this.projectSelected.results.resOperationNetFonc.resTotStructureSCI +
                  this.projectSelected.results.resInvTravFonc.resTotalInvTrav
                ) *
                  100) /
                Math.round(this.coutTotInvestCalc)
              ),
              Math.round(
                (-Math.round(
                  this.projectSelected.results.resInvTravFonc.resTotFraisBanc +
                  this.projectSelected.results.resInvTravFonc.resTotItr +
                  this.projectSelected.results.resInvTravFonc.resTotAssEmpr
                ) *
                  100) /
                Math.round(this.coutTotInvestCalc)
              ),
              Math.round(
                (-Math.round(
                  this.projectSelected.results.resFluxAIFonc.resTotImposition -
                  0 +
                  this.projectSelected.results.resFluxAIFonc.resTotVersDiv
                ) *
                  100) /
                Math.round(this.coutTotInvestCalc)
              ),
            ],
            modeFinancement: [
              Math.round(
                (Math.round(-Math.round(this.projectSelected.results.resOperationNetFonc.resTotRevLoc)) * 100) /
                Math.round(
                  this.projectSelected.results.resOperationNetFonc.resTotRevLoc -
                  this.projectSelected.results.resFluxAIFonc.resTotSommeInvest
                )
              ),
              Math.round(
                (-Math.round(this.projectSelected.results.resFluxAIFonc.resTotSommeInvest) * 100) /
                Math.round(
                  this.projectSelected.results.resOperationNetFonc.resTotRevLoc -
                  this.projectSelected.results.resFluxAIFonc.resTotSommeInvest
                )
              ),
            ],
            profitTotal: [
              Math.round(
                (Math.round(this.projectSelected.results.resFluxAIFonc.rendementTotal.revloc.val) * 100) /
                Math.round(this.projectSelected.results.resFluxAIFonc.rendementTotal.total.val)
              ),
              Math.round(
                (Math.round(this.projectSelected.results.resFluxAIFonc.rendementTotal.plusval.val) * 100) /
                Math.round(this.projectSelected.results.resFluxAIFonc.rendementTotal.total.val)
              ),
            ],
            // dataGouvGeo: this.dataGouvGeo
          },
          user: this.user._id,
        },
        idProj: this.projectSelected._id,
        idInv: this.investSelected._id,
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${this.exportFinance.PDFname}.pdf`;
          link.click();
          this.modalPDFLoader = false;
          this.refreshUserInformation();
        })
        .catch((err) => {
          this.modalPDFLoader = false;
          console.error(err);
        });
      } catch (error) {
        console.log(error);
      }
    },
    constructedPDF(pdfs) {
      var doc = new DOMParser().parseFromString(pdfs, "text/xml");

      console.log("=====================> PDF", doc);
      var pdf = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: [8.5, 11],
      });
      html2canvas(doc).then((canvas) => {
        // var link = document.createElement('a');
        // link.download = 'my-image-name.png';
        // link.href = canvas.toDataURL('image/jpeg');
        // link.click();exportClose

        pdf.addImage(canvas.toDataURL("image/jpeg"), "PNG", 0, 0);
        pdf.save("facture" + ".pdf");

        // document.body.appendChild(canvas)
      });
    },
    apport() {
      if (!this.projectSelected || !this.projectSelected.results || !this.projectSelected.results.resCoutTotalFonc) {
    // Handle the undefined case, perhaps by returning 0 or logging an error
    return 0;
  }
      const apport = Math.round(
        this.projectSelected.results.resCoutTotalFonc.resCoutTotalAcqui[0] +
        this.projectSelected.results.resInvTravFonc.resEmprunt[0] +
        this.projectSelected.results.resInvTravFonc.resFraisBanc[0] +
        this.projectSelected.results.resInvTravFonc.resInvTrav[0]
      );
      return isNaN(apport) ? 0 : Number(apport);
    },
    exportClose() {
      document.body.classList.remove('overflow-hidden')
      this.modalPDF = false
      this.exportFinance.showExportFinance = false;
      this.showExportBancaire = false;
    },
    makeSVG() {
      /** WITH CSS */
      domtoimage
        .toSvg(this.$refs.content, {
          style: { left: "0", right: "0", bottom: "0", top: "0" },
        })
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "my-image-name.svg";
          link.href = dataUrl;
          link.click();
        });
    },
    formeJuridiqueSelected(value) {
      let bool = false;
      this.projectSelected.carac.formejuridique.map((val) => {
        if (val.selected == true) {
          value.map((index) => {
            if (val.code == index) {
              return (bool = true);
            }
          });
        }
      });

      if (bool == true) return true;
      else return false;
    },
    unlockSimulation() {
      this.unlockProject({
        idUser: this.user._id,
        idProject: this.projectSelected._id,
      })
        .then((res) => {
          this.isModal = false;
        })
        .catch((err) => {
          throw err;
        });
    },
    setEmitCenter(center) {
      this.center = [center[0], center[1]];
    },
    indexListDVFSet(index, marker) {
      if (this.indexListDVF == index) {
        this.indexListDVF = null;
      } else {
        this.indexListDVF = index;
      }

      // tmp.push(rembCapital + emprunt + rembDette)

      // Tresorerie annuelle
      tmp.push(
        Math.round(
          coutTotalAcqui +
          invTravaux +
          revenusLocatifs +
          (fraisExploit + structSCI) -
          (-fraisBancaires + partInteret + assurance - indemRembDette) +
          imposition -
          CapFinPeriode
        )
      );
      this.center = [marker.properties.lat, marker.properties.lon];
    },
    numberWithSpaces(x) {
      if (isNaN(x) || x == undefined) return 0;
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
    setZoomDVF(value) {
      // console.log("======> zoom value1: ", this.zoomValue)
      // this.zoomValue = this.zoomValue + value
      // console.log("======> zoom value2: ", this.zoomValue)
      // // return
      // axios
      // .get('/apidvfmap', {
      // 	params: {
      // 		dist: this.zoomValue + value,
      // 	}
      // })
      // .then(response => {
      // 	this.dvf = response.data.features
      // 	// this.center = response.data.features[0]
      // 	// console.log(this.center)
      // })
      // .catch(error => {throw error})
    },

    barChartdata(labels, label, data, type, isDark) {
      // let darkBlue = '#0C1E3B'
      // let lightBlue = '#14A0EB'
      // let red = '#D45B40'
      // let yellow = '#ECCA51'

      let datasets = [];

      for (let i = 0; i < data.length; i++) {
        datasets.push({
          type: type[i],
          label: label[i],
          data: data[i],
          fill: true,
          fillColor: "rgba(20, 160, 235, 1)",
          tension: 0,
          order: i + 1,
          pointRadius: 2,
          pointHitRadius: 30,
          lineTension: 0.3,
          backgroundColor: ["rgba(20, 160, 235, 0.2)"],
          borderColor: ["rgba(20, 160, 235, 1)"],
          borderWidth: 3,
        });
      }

      let chartdata = {
        labels: labels,
        datasets: datasets,
      };

      return chartdata;
    },
    pieChartdata(labels, label, data) {
      let datasets = [];

      datasets.push({
        data: data,
        label: "Bar Chart",
        borderWidth: 1,
        backgroundColor: ["rgba(12, 30, 59, 1)", "rgba(20, 160, 235, 1)", "rgba(235, 123, 20, 1)"],
        borderColor: ["rgba(12, 30, 59, 1)", "rgba(20, 160, 235, 1)", "rgba(235, 123, 20, 1)"],
        pointBorderColor: "#2554FF",
      });

      let chartdata = {
        labels: labels,
        datasets: datasets,
      };

      return chartdata;
    },
    doughnutChartdata(labels, label, data, colors) {
      let datasets = [];

      let colorArray = [];

      if (colors.length > 2) {
        colorArray = [colors[0], colors[1], colors[2], colors[3]];
      } else {
        colorArray = [colors[0], colors[1]];
      }

      datasets.push({
        data: data,
        label: "doughnut",
        borderWidth: 1,
        backgroundColor: colorArray,
        borderColor: colorArray,
        pointBorderColor: "#2554FF",
      });

      let chartdata = {
        labels: labels,
        datasets: datasets,
      };

      return chartdata;
    },
    barOptions(isAxes) {
      let options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
              },
              gridLines: {
                display: true,
              },
              display: true,
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
              },
              gridLines: {
                display: false,
              },
              display: true,
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      };

      return options;
    },
    barPieOptions(isAxes) {
      let options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
              display: false,
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              display: false,
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        pieceLabel: {
          render: function (d) {
            return d.label + " (" + d.percentage + "%)";
          },
          fontColor: "#000",
          position: "outside",
          segment: true,
        },
        tooltips: {
          enabled: true,
        },
        aspectRatio: 1,
      };

      return options;
    },
    doughnutPieOptions(isAxes) {
      let options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
              display: false,
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              display: false,
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        tooltips: {
          enabled: true,
        },
        aspectRatio: 1,
        cutoutPercentage: 70,
      };

      return options;
    },
    //Ouvre la fenetre du  tableau d'imposition détaillé
    showImpositionDetaille() {
      this.impositionDetaille = true;
    },
    //Ferme la fenetre du tableau d'imposition détaillé
    closeImpositionDetaille() {
      this.impositionDetaille = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);

  &-header {
    &-img {
      width: 259px;
      height: 215px;
    }
  }
}

.chart-blue {
  background-color: #0c1e3b;
}
.text-titre {
  font-size: 0.69em;
}
.chart-blue-light {
  background-color: #14a0eb;
}

.chart-orange {
  background-color: #eb7b14;
}

.chart-red {
  background-color: #f91515;
}

.chart-yellow {
  background-color: rgba(235, 175, 20, 0.6);
}

.chart-grey {
  background-color: rgba(95, 95, 95, 0.4);
}

.h-40-c {
  height: 30rem;
}

.h-27-c {
  height: 27rem;
}

.li-box {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  cursor: pointer;
  background-color: #ffffff;
  padding: 10px 0px 10px 0px;
  margin-bottom: 0.1rem;
}

.li-box:hover {
  transform: scale(1.03);
}

.bg-green-pointer {
  background-color: #55ef0c;
}

.bg-red-pointer {
  background-color: #f11212;
}

.text-xs {
  font-size: 0.8rem;
}

.blur-lg {
  filter: blur(0.3rem);
}

.wrapper {
  -webkit-transform: translateZ(0);
  /* webkit flicker fix */
  -webkit-font-smoothing: antialiased;
  /* webkit text rendering fix */
}

.wrapper .tooltip {
  background: #14a0eb;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -13.5rem;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  width: 30rem;
  z-index: 999999;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
  bottom: -20px;
  content: ' ';
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #1496bb 10px;
  bottom: -10px;
  content: ' ';
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
  display: none;
}

.lte8 .wrapper:hover .tooltip {
  display: block;
}

#overlay {
  position: absolute;
  /* display: none; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 55;
  cursor: pointer;
}

.z-100 {
  z-index: 100;
}

.export-form {
  transition: 0.3s;
  transform: translateX(100rem);
}

.export-form-active {
  transform: translateX(0);
}

.export-form-close {
  position: absolute;
  left: -3.5rem;
  cursor: pointer;
}
.height-sidebar {
  height: 45rem;
}
</style>
