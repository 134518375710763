<template>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->

	<div>
		<div class="grid grid-cols-12 ml-6 mt-6">
			<router-link to="/" class="col-span-11 sm:col-span-1 text-lg font-semibold text-white  py-3 bg-lightblueaestima-100 rounded-full aestima-button mt-10 sm:mt-0">Retour</router-link>
		</div>
		
		<div class="grid grid-cols-11 gap-0 relative flex flex-row justify-center items-start mt-10">
			<div class="bg-white relative text-center col-span-11 sm:col-start-3 sm:col-end-10">
			<!-- <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-on-white.svg" alt="Workflow"> -->
				<img class="mx-auto h-36 w-auto" src="../assets/logo_aestima-white.png" alt="Workflow">
				
				<h1 class="mt-12 text-center text-3xl leading-9 font-bold text-gray-900">Se connecter </br>  <span class="text-sm font-normal">ou <router-link to="/register" @click.native="selectedStripePrice({id: 0, isTrial: false})"><u class="text-lightblueaestima-100">S’inscrire gratuitement</u></router-link></span> </h1>
				
			</div>
			<form class="mt-8 relative text-center col-span-11 sm:col-start-3 sm:col-end-10 mx-10 sm:mx-0" @submit.prevent="submit">
				<input type="hidden" name="remember" value="true">
				<div class="rounded-md shadow-sm">
					<div>
					<input required v-model="email" aria-label="Email address" name="email" type="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Adresse mail">
					</div>
					<div class="-mt-px flex relative">
						<input required v-model="password" aria-label="Password" name="password" id="password" type="password" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Mot de passe">
						<img src="https://api.iconify.design/iconoir/eye.svg?color=%2314a0eb" class="absolute right-1 h-8 my-auto top-1 bottom-1 cursor-pointer z-10" @click="changeType('password')"/>
					</div>
				</div>

				<div v-if="authStatus.isFailed == true" class="mt-6 px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-lg bg-red-100 text-red-800 border-red-800  border border-solid flex justify-center items-center">
					<svg class="h-10 w-10 p-2 mr-2 text-red-800 bg-white rounded-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
					</svg>
					<span>{{authStatus.message}}</span>
				</div>

				<div class="mt-6 flex items-center justify-between">
					<div class="flex items-center">
						<input id="remember_me" v-model="checkboxStayConnected" type="checkbox" class="form-checkbox h-4 w-4 text-lightblueaestima-100 transition duration-150 ease-in-out">
						<label for="remember_me" class="ml-2 block text-sm leading-5 text-gray-900">
							Se souvenir de moi
						</label>
					</div>

					<div class="text-sm leading-5">
					<router-link to="/ForgotPassword" class="font-medium text-lightblueaestima-100 hover:text-darkblueaestima-500 focus:outline-none focus:underline transition ease-in-out duration-150">
						Mot de passe oublié?
					</router-link>
					</div>
				</div>
				<div class="mt-6">
					<button :disabled="modalPDFLoader == true" type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-lightblueaestima-100 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition duration-150 ease-in-out">
					<span class="absolute left-0 inset-y-0 flex items-center pl-3">
						<svg class="h-5 w-5 text-blueaestima-500 group-hover:blueaestima-500 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
						<path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
						</svg>
					</span>
					Se connecter
					</button>
				</div>
				<div class="mt-6 flex justify-center items-center">
					<svg v-show="modalPDFLoader == true" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" display: block; shape-rendering: auto;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
						<circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25" stroke-dasharray="70.68583470577033 25.561944901923447">
						<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
						</circle>
					</svg>
				</div>
				
				
			</form>
			<div class="col-span-12 mt-20">
				<Footer/>
			</div>
		</div>
		
	</div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex"
import Footer from '@/components/Landing/Footer.vue'

export default {
  name: 'SignIn',
  data() {
		return {
			email: "",
			password: "",
			isStayConnected: false,
			modalPDFLoader: false,
		}
	},
	components: {
		Footer
	},
  	computed: {
		...mapGetters({
			authStatus: 'users/authStatus',
		}),
		checkboxStayConnected: {
			get: function () {
				return this.isStayConnected
			},
			set: function (val) {
				this.isStayConnected = val
				if(val == true) {
					this.setStayConnected()
				} else {
					this.unsetStayConnected()
				}
			}
		},
	},
    methods: {
		...mapActions({
			signIn: 'users/login',
			setStayConnected: 'users/setStayConnected',
			unsetStayConnected: 'users/unsetStayConnected',
			selectedStripePrice: 'users/setStripePriceSelected',
		}),
		async submit() {
			
			this.modalPDFLoader = true
			await this.signIn({
				email: this.email,
				password: this.password
			})
			.then(() => {
				this.modalPDFLoader = false
				this.$router.replace({
					name: 'Home'
				})
			})

			},
			changeType(id) {
      if (document.getElementById(id).type === 'password') {
        document.getElementById(id).setAttribute('type', 'text')
      } else {
        document.getElementById(id).setAttribute('type', 'password')
      }
    },
	},
}
</script>
