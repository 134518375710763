<template>
  <div
    id="defaultModal"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    class="overflow-y-auto overflow-x-hidden fade fixed top-0 right-0 left-0 md:inset-0 h-modal justify-center items-center flex z-10"
  >
    <!-- <div id="overlay" class="height-sidebar"></div> -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="relative my-auto p-10 h-full md:h-auto backdrop-blur">
      <div
        class="relative bg-white rounded-lg shadow dark:bg-gray-700 border-grey-300 border-2"
      >
        <div class="flex justify-center items-center p-5 shadow">
          <h3 class="font-bold text-black" style="font-size: 30px">
            Tableau d'imposition détaillé année par année (hors année de revente)
            <button
              class="cursor-pointer text-center inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-6 font-medium rounded-md text-white bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
              @click="close"
            >
              Fermer
            </button>
          </h3>
        </div>
        <div class="shadow border-b border-gray-200 sm:rounded-lg mt-6">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                ></th>
                <th
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                >
                  <div class="flex flex-col items-center">
                    <p>Revenus locatifs imposables</p>
                  </div>
                </th>
                <th
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                >
                  <div class="flex flex-row items-center">
                    <p>Charges locatives déductibles</p>
                    <QuestionMarkv2
                      class="-ml-4 infobulle self-end"
                      :text="`${infobulles.chargesLocativesDeductibles}`"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                >
                  <div class="flex flex-row items-center">
                    <p>Charges financières déductibles</p>
                    <QuestionMarkv2
                      class="-ml-4 infobulle self-end"
                      :text="`${infobulles.chargesFinancièresDéductibles}`"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                >
                  <div class="flex flex-row items-center">
                    <p>Autres frais déductibles</p>
                    <QuestionMarkv2
                      class="-ml-4 infobulle self-end"
                      :text="`${infobulles.autresFraisDéductibles}`"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                >
                  <div class="flex flex-row items-center">
                    <p>Amortissement comptable</p>
                    <QuestionMarkv2
                      class="-ml-4 infobulle self-end"
                      :text="`${infobulles.amortissementComptable}`"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                >
                  <div class="flex flex-row items-center">
                    <p>Base imposable brute</p>
                    <QuestionMarkv2
                      class="-ml-4 infobulle self-end"
                      :text="`${infobulles.baseImposableBrute}`"
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                >
                  <div class="flex flex-row items-center">
                    <p>Déficits reportables cumulés</p>
                    <QuestionMarkv2
                      class="-ml-4 infobulle self-end"
                      :text="`${infobulles.cagnotteReportable}`"
                    />
                  </div>
                  <!-- <div class="flex flex-col items-center">
                                    <QuestionMark class="ml-2" :text=" `Cash-flow dégagé chaque année. On ne tient pas compte ici de l’amortissement comptable du bien.`"/>
                                  </div> -->
                </th>
                <th
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                >
                  <div class="flex flex-row items-center">
                    <p>Base imposable nette</p>
                    <QuestionMarkv2
                      class="-ml-4 infobulle self-end"
                      :text="`${infobulles.baseImposableNette}`"
                    />
                  </div>
                  <!-- <div class="flex flex-col items-center z-50">
                                    <QuestionMark class="ml-2" :text="`Ne tient pas compte, à l’inverse du taux de rentabilité, de la revente du bien. Le calcul se base sur les revenus nets du bien divisé par son coût d’acquisition.`"/>
                                  </div> -->
                </th>

                <th
                  v-if="
                    laFormeJuridiqueSelected != 'sasuis' &&
                    laFormeJuridiqueSelected != 'sciis'
                  "
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                >
                  <p>TMI</p>
                </th>
                <template v-else>
                  <template
                    v-if="projectSelected.carac.dividendeSCI"
                    class="flex"
                  >
                    <th
                      scope="col"
                      class="text-center text-xs font-bold text-gray-500 flex"
                      :class="{ 'rounded cursor-default uppercase': displayDetails, 'cursor-pointer rounded-xl': !displayDetails}"
                    >
                      <PButton 
                        :icon="displayDetails ? 'pi pi-chevron-left' : 'pi pi-chevron-right'" 
                        class="p-button-text p-button-rounded p-button-secondary" 
                        v-tooltip.top="`Plus de détails`"
                        @click="displayDetails = !displayDetails"
                      />
                      <p v-if="displayDetails">
                        Pourcentage d'impôt sur les sociétés
                      </p>
                    </th>
                    <th
                      v-if="displayDetails"
                      scope="col"
                      class="text-center text-xs font-bold text-gray-500 uppercase"
                    >
                      Part de l'impôt sur les sociétés
                    </th>
                    <th
                      v-if="displayDetails"
                      scope="col"
                      class="text-center text-xs font-bold text-gray-500 uppercase rounded "
                    >
                      Part de l'impôt sur les dividendes
                    </th>
                  </template>
                  <th
                    v-else
                    scope="col"
                    class="text-center text-xs font-bold text-gray-500 uppercase"
                  >
                    Pourcentage d'impôt sur les sociétés
                  </th>
                </template>

                <th
                  scope="col"
                  class="text-center text-xs font-bold text-gray-500 uppercase"
                >
                  <div class="flex flex-row items-center">
                    <QuestionMarkv2bottomVue
                      class="pl-4 infobulle self-end"
                      :text="`${infobulles.impositionGlobale}`"
                    />
                    <p>Imposition globale sur ce projet</p>
                  </div>
                  <!-- <div class="flex flex-col items-center z-50">
                                    <QuestionMark class="ml-2" :text="`Ne tient pas compte, à l’inverse du taux de rentabilité, de la revente du bien. Le calcul se base sur les revenus nets du bien divisé par son coût d’acquisition.`"/>
                                  </div> -->
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(res, index) in dataRow" :key="index">
                <td
                  class="p-2 whitespace-nowrap text-center"
                  :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                >
                  <span class="px-2 inline-flex text-xs leading-5 font-bold rounded-full">
                    {{ index + 1 }}
                  </span>
                  <!-- <div v-if="index + 1 == 1" class="tooltip inline-block"> -->
                  <!-- <QuestionMark/> -->
                  <!-- <span class="tooltiptext text-base">L'année 1 = Période 1. La période débute lors du premier mois de l'acquisition et dure 12 mois.</br> <b>Exemple :</b> si le bien est acquis en Juin 2022, année 1 = Juin 2022 Juin 2023.</span> -->
                  <!-- </div> -->
                </td>
                <template v-if="true">
                  <td
                    class="px-2 whitespace-nowrap text-center"
                    :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                    id="revenusLocatifsImposables"
                  >
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-normal rounded-full"
                    >
                      {{ Number(res[0]).toLocaleString() }} €
                    </span>
                  </td>
                  <td
                    class="px-2 whitespace-nowrap text-center"
                    :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                    id="chargesLocativesDeductibles"
                  >
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-normal rounded-full"
                    >
                      {{ Number(res[1]).toLocaleString() }} €
                    </span>
                  </td>
                  <td
                    class="px-2 whitespace-nowrap text-center"
                    :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                    id="chargesFinancieresDeductibles"
                  >
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-normal rounded-full"
                    >
                      -{{ Number(res[2]).toLocaleString() }} €
                    </span>
                  </td>
                  <td
                    class="px-2 whitespace-nowrap text-center"
                    :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                    id="autresFraisDeductibles"
                  >
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-normal rounded-full"
                    >
                      -{{ Number(res[3]).toLocaleString() }} €
                    </span>
                  </td>
                  <td
                    class="px-2 whitespace-nowrap text-center"
                    :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                    id="amortissementComptable"
                  >
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-normal rounded-full"
                    >
                      -{{ Number(res[4]).toLocaleString() }} €
                    </span>
                  </td>
                  <td
                    class="px-2 whitespace-nowrap text-center"
                    :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                    id="baseImposableBrute"
                  >
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-normal rounded-full"
                      :class="{
                        'text-red-700': res[5] < 0,
                        'text-green-700': res[5] >= 0,
                      }"
                    >
                      {{ Number(res[5]).toLocaleString() }} €
                    </span>
                  </td>
                  <td
                    class="px-2 whitespace-nowrap text-center"
                    :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                    id="cagnotteReportable"
                  >
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-bold rounded-full"
                    >
                      {{ Number(res[6]).toLocaleString() }} €
                    </span>
                  </td>
                  <td
                    class="px-2 whitespace-nowrap text-center"
                    :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                    id="baseImposableNette"
                  >
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-bold rounded-full"
                    >
                      {{ Number(res[7]).toLocaleString() }} €
                    </span>
                  </td>
                  <td
                    v-if="
                      laFormeJuridiqueSelected != 'sasuis' &&
                      laFormeJuridiqueSelected != 'sciis'
                    "
                    class="px-2"
                    :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                    id="tmi"
                  >
                    <span
                      class="px-2 w-20 inline-flex place-content-center text-xs leading-5 font-bold rounded-full"
                    >
                      <!-- <div class="inline-flex z-40" v-if="checkIfTmiChange(index)">
                        <div v-html="getTMIChange(index) + '%'"></div>
                        <img
                          class="w-auto h-4 mx-2 inline-block"
                          src="@/assets/picto/Panneau.png"
                          alt="pictogramme panneau attention"
                        />
                      </div>
                      <p v-else>{{ res[8] }} %</p>
                      -->
                      {{ res[8] }} %
                    </span>
                  </td>

                  <template v-else>
                    <template v-if="projectSelected.carac.dividendeSCI">
                      <td
                        :class="{ bg: yearIsAfterFiscalityTypeChange(index)}"
                        class="px-2 w-20 place-content-center text-xs leading-5 font-bold rounded-full"
                        id="tauxImpositionSociete"
                      >
                        <p v-if="displayDetails">
                          {{ projectSelected.results.resFluxAIFonc.resRateIS[index] * 100 }} %
                        </p>
                      </td>
                      <td
                        v-if="displayDetails"
                        class="px-2 w-20 place-content-center text-xs leading-5 rounded-full"
                        :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                        id="partImpotSociete"
                      >
                      {{ Math.round(projectSelected.results.resFluxAIFonc.resImpotIS[index]).toLocaleString() }} €
                      </td>
                      <td
                        v-if="displayDetails"
                        :class="{ bg: yearIsAfterFiscalityTypeChange(index)}"
                        class="px-2 w-20 place-content-center text-xs leading-5 rounded-full"
                        id="partImpotDividendes"
                      >
                      {{ Math.round(projectSelected.results.resFluxAIFonc.resDividendes[index]).toLocaleString() }} €
                      </td>
                    </template>

                    <td
                      v-else
                      class="px-2"
                      :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                      id="tauxImpositionSociete2"
                    >
                      <span
                        class="px-2 w-20 inline-flex place-content-center text-xs leading-5 font-bold rounded-full"
                      >
                        {{ projectSelected.results.resFluxAIFonc.resRateIS[index] * 100 }} %
                      </span>
                    </td>
                  </template>

                  <td
                    class="px-2 whitespace-nowrap text-center"
                    :class="{ bg: yearIsAfterFiscalityTypeChange(index) }"
                    id="impositionGlobale"
                  >
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-normal rounded-full"
                      :class="{
                        'text-red-700': res[9] < 0,
                        'text-green-700': res[9] >= 0,
                      }"
                    >
                      {{ Number(res[9]).toLocaleString() }} €
                    </span>
                  </td>
                </template>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <template v-if="true">
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  >
                    TOTAL
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  >
                    {{ Number(dataTotal[0]).toLocaleString() }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  >
                    {{ Number(dataTotal[1]).toLocaleString() }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  >
                    -{{ Number(dataTotal[2]).toLocaleString() }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  >
                    -{{ Number(dataTotal[3]).toLocaleString() }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  ></th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  ></th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  ></th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  ></th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  ></th>
                  <template v-if="displayDetails">
                    <th 
                      scope="col"
                      class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                    />
                    <th 
                      scope="col"
                      class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                    />
                  </template>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                    :class="{
                      'text-red-700': dataTotal[8] >= 0,
                      'text-green-700': dataTotal[8] < 0,
                    }"
                  >
                    {{ Number(dataTotal[8]).toLocaleString() }} €
                  </th>
                </template>
                <template v-else>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider"
                  >
                    TOTAL
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider blur-lg"
                  >
                    {{ dataTotal[0] }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider blur-lg"
                  >
                    {{ dataTotal[1] }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider blur-lg"
                  >
                    {{ dataTotal[2] }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider blur-lg"
                  >
                    {{ dataTotal[3] }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider blur-lg"
                  >
                    {{ dataTotal[4] }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider blur-lg"
                  >
                    {{ dataTotal[5] }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider blur-lg"
                  >
                    {{ dataTotal[6] }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider blur-lg"
                  >
                    {{ dataTotal[7] }} €
                  </th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider blur-lg"
                  ></th>
                  <th
                    scope="col"
                    class="px-2 text-center py-3 bg-green-100 text-green-800 text-xs font-bold uppercase tracking-wider blur-lg"
                    :class="{
                      'text-red-700': dataTotal[8] >= 0,
                      'text-green-700': dataTotal[8] < 0,
                    }"
                  >
                    {{ dataTotal[8] }} €
                  </th>
                </template>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionMarkv2 from "@/components/SVG/QuestionMarkv2.vue";
import QuestionMarkv2bottomVue from "../SVG/QuestionMarkv2bottom.vue";
import { mapGetters } from "vuex";

export default {
  name: "ImpositionDetaille",
  props: {
    dataRow: Array,
    dataTotal: Array,
    changementDeFiscalite: Boolean,
    engagementPlusTravaux: Number,
  },
  components: {
    QuestionMarkv2,
    QuestionMarkv2bottomVue,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    //Vérifie si la TMI a changé entre deux périodes
    checkIfTmiChange(index) {
      console.log("investSelected.tmi", this.investSelected.tmi);
      return this.investSelected.tmi != this.dataRow[index][8];
    },
    //Récupère la variation de TMI
    getTMIChange(index) {
      return this.investSelected.tmi + "/" + this.dataRow[index][8];
    },
    yearIsAfterFiscalityTypeChange(year) {
      const fiscalityChangeActivated = this.changementDeFiscalite;
      if (!fiscalityChangeActivated) return false;

      const fiscalChangeYear = this.engagementPlusTravaux;
      return fiscalChangeYear <= year;
    },
  },
  data() {
    return {
      infobulles: {
        chargesLocativesDeductibles:
          "Regroupe les charges locatives annuelles relatives au projet d’investissement (taxe foncière, assurance PNO, charges de copropriété, etc.).",
        chargesFinancièresDéductibles:
          "Regroupe les charges financières annuelles relatives au projet d’investissement (intérêts d’emprunt, assurance emprunteur, frais de dossier et de garantie).",
        autresFraisDéductibles:
          "Regroupe les autres frais annuels relatifs au projet d’investissement (travaux, frais de notaire et d’agence selon le régime fiscal, frais de comptabilité et taxes d’entreprises).",
        amortissementComptable:
          "Pourcentage de la dépréciation de valeur du bien locatif, cet amortissement comptable est une charge fictive qui vient en déduction de la base imposable quand le projet est mené avec des régimes fiscaux comme LMNP au réel, SCI à l’IS, SASU ou SAS.",
        baseImposableBrute:
          "Cette donnée n’a rien à voir avec un flux financier de trésorerie. Il s’agit de la base de calcul pour l’impôt avant la prise en compte des déficits. Dans le cadre d’une activité en société ou en location meublée, on parle de résultat comptable.",
        cagnotteReportable:
          "Lorsqu’il y a un résultat imposable négatif, la base imposable tombe à 0€ et ce déficit est inscrit dans une cagnotte qui est généralement cumulable et reportable sur 10 ans (report de déficit illimité pour : sociétés à l’IS ou déficit lié à l'amortissement comptable en LMNP réel par exemple). Seul la location nue au régime réel permet de déduire un déficit foncier dans la limite de -10700€. L’excédent sera inscrit en cagnotte reportable.",
        baseImposableNette:
          "Réel base sur laquelle l’impôt final sera calculé après avoir pris en compte les reports de déficits.",
        impositionGlobale:
          "Imposition totale composée de l’impôt sur le revenu (ou sur les sociétés quand le régime fiscal est choisi), des prélèvements sociaux, de la contribution sociale généralisée (CSG) récupérable. Si vous avez fait le choix d’une fiscalité évolutive dans la création de votre client, le changement de tranche d’imposition (TMI) engendré par le projet locatif est automatiquement calculé. Si le projet est monté en LMP, la cotisation pour la sécurité sociale des indépendants (SSI) est elle aussi prise en compte dans le calcul.",
      },
      displayDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      investSelected: "invests/selected",
      projectSelected: "projects/selected",
    }),
    laFormeJuridiqueSelected() {
      for (let item of this.projectSelected.carac.formejuridique) {
        if (item.selected === true) {
          return item.code;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.head-text {
  font-size: 15px;
  line-height: 40px;
}
.content-text {
  font-size: 20px;
  line-height: 40px;
}
.subtitle {
  font-size: 20px;
  margin-top: 60px;
}
.infobulle {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-transform: none;
}

#overlay {
  position: absolute;
  /* display: none; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 55;
  cursor: pointer;
}

::v-deep .p-button-icon {
  font-size: 1.5rem;
  font-weight: 700;
  transition: background-color 0.2s;
}



</style>
