<template>
	<div class="wrapper z-60">
    <WarningIcon class="w-5 h-5" />
		<div class="tooltip text-justify">
			<p v-html="text"></p>
		</div>
	</div>
</template>

<script>
import WarningIcon from "@/components/SVG/WarningIcon.vue";
export default {
	name: 'QuestionMarkv2',
	props: {
		text: { type: String },
	},
	components: {
		WarningIcon,
	}
};
</script>

<style scoped>
.wrapper {
	-webkit-transform: translateZ(0); /* webkit flicker fix */
	-webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.wrapper .tooltip {
	background: #fff9c7;
	bottom: 100%;
	color: #685e42;
	display: block;
	left: -14rem;
	font-size: small;
	margin-bottom: 15px;
	opacity: 0;
	padding: 20px;
	pointer-events: none;
	position: absolute;
	width: 30rem;
	z-index: 999999;
	border-radius: 30px;
	-webkit-transform: translateY(10px);
	-moz-transform: translateY(10px);
	-ms-transform: translateY(10px);
	-o-transform: translateY(10px);
	transform: translateY(10px);
	-webkit-transition: all 0.25s ease-out;
	-moz-transition: all 0.25s ease-out;
	-ms-transition: all 0.25s ease-out;
	-o-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;
	-webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
	-moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
	-ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
	-o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
	box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
	bottom: -20px;
	content: ' ';
	display: block;
	height: 20px;
	left: 0;
	position: absolute;
	width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
	border-left: solid transparent 10px;
	border-right: solid transparent 10px;
	border-top: solid #fff9c7 10px;
	bottom: -10px;
	content: ' ';
	height: 0;
	left: 50%;
	margin-left: -13px;
	position: absolute;
	width: 0;
}

.wrapper:hover .tooltip {
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-ms-transform: translateY(0px);
	-o-transform: translateY(0px);
	transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
	display: none;
}

.lte8 .wrapper:hover .tooltip {
	display: block;
}
</style>
