<template>
  <div
    id="defaultModal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed top-10 left-0 right-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto md:inset-0"
  >
    <div
      class="fixed inset-0 flex items-center justify-center z-50" 
      v-if="display_confirm"
    >
      <div
        class="fixed inset-0 bg-black opacity-50"
      ></div>
      <div
        class="bg-white rounded-lg px-4 py-2 z-10"
      >
        <div>Etes-vous sûr de vouloir quitter sans enregistrer ?</div>
        <div class="flex justify-center mt-2 space-x-4">
          <button class="bg-lightblueaestima-100 hover:bg-blue-700 text-white py-1 px-2 rounded" @click="closeWithoutSaving">Oui</button>
          <button class="bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded" @click="display_confirm = false">Non</button>
        </div>
      </div>
    </div>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="relative h-full px-4 my-auto md:h-auto">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div
          @click="display_confirm = true"
          class="cursor-pointer absolute right-5 top-5 z-10 text-5xl rounded-lg h-10 w-10 flex place-content-center place-items-center hover:bg-red-500 focus:outline-none focus:shadow-outline-indigo transition ease-in-out duration-150"
        >
          <CloseIcon />
        </div>
        <div class="flex items-center justify-center p-5">
          <h3 class="text-lg font-bold text-black">Répartition des appels de fonds</h3>
        </div>
        <div class="p-2 overflow-x-auto overflow-y-auto">
          <div class="px-5 grid grid-cols-5">
            <div class="col-span-2">
              <p class="head-text">Période</p>
              <p class="content-text">ADF N°1 : Réservation</p>
              <p class="content-text">ADF N°2 : Achèvement des fondations</p>
              <p class="content-text">ADF N°3 : Mise hors d'eau et hors d'air</p>
              <p class="content-text">ADF N°4 : Achèvement total des travaux</p>
              <p class="content-text">ADF N°5 : Livraison du bien et remise des clés</p>
            </div>
            <div class="ml-5 col-span-1 justify-self-start">
              <p class="head-text">Valeur</p>
              <p class="font-bold text-right content-text">
                {{ this.calculateYear(this.appelDeFond.reservation) }}€
              </p>
              <p class="font-bold text-right content-text">
                {{ this.calculateYear(this.appelDeFond.achevementFondation) }}€
              </p>
              <p class="font-bold text-right content-text">
                {{ this.calculateYear(this.appelDeFond.miseEauAir) }}€
              </p>
              <p class="font-bold text-right content-text">
                {{ this.calculateYear(this.appelDeFond.achevementTravaux) }}€
              </p>
              <p class="font-bold text-right content-text">
                {{ this.calculateYear(this.appelDeFond.livraisonRemiseClef) }}€
              </p>
            </div>
            <div class="col-span-1">
              <p class="head-text">Pourcentage</p>
              <input
                :disabled="!newProject"
                :class="{ 'bg-gray-300': !newProject }"
                type="number"
                v-model="appelDeFond.reservation"
                id="reservation"
                min="0"
                max="100"
                class="block w-2/3 px-3 py-1 mt-2 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <input
                :disabled="!newProject"
                :class="{ 'bg-gray-300': !newProject }"
                type="number"
                v-model="appelDeFond.achevementFondation"
                id="achevementFondation"
                min="0"
                max="100"
                class="block w-2/3 px-3 py-1 mt-2 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <input
                :disabled="!newProject"
                :class="{ 'bg-gray-300': !newProject }"
                type="number"
                v-model="appelDeFond.miseEauAir"
                id="miseEauAir"
                min="0"
                max="100"
                class="block w-2/3 px-3 py-1 mt-2 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <input
                :disabled="!newProject"
                :class="{ 'bg-gray-300': !newProject }"
                type="number"
                v-model="appelDeFond.achevementTravaux"
                id="achevementTravaux"
                min="0"
                max="100"
                class="block w-2/3 px-3 py-1 mt-2 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <input
                :disabled="!newProject"
                :class="{ 'bg-gray-300': !newProject }"
                type="number"
                v-model="appelDeFond.livraisonRemiseClef"
                id="livraisonRemiseClef"
                min="0"
                max="100"
                class="block w-2/3 px-3 py-1 mt-2 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
            <div class="col-span-1">
              <p class="head-text">Nombre de mois</p>
              <input
                :disabled="!newProject"
                :class="{ 'bg-gray-300': !newProject }"
                type="number"
                disabled
                v-model="appelDeFond.reservationMois"
                id="reservationMois"
                min="1"
                max="36"
                class="block w-2/3 px-3 py-1 mt-2 bg-gray-300 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <input
                :disabled="!newProject"
                :class="{ 'bg-gray-300': !newProject }"
                type="number"
                v-model="appelDeFond.achevementFondationMois"
                id="achevementFondationMois"
                min="0"
                max="36"
                class="block w-2/3 px-3 py-1 mt-2 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <input
                :disabled="!newProject"
                :class="{ 'bg-gray-300': !newProject }"
                type="number"
                v-model="appelDeFond.miseEauAirMois"
                id="miseEauAirMois"
                min="0"
                max="36"
                class="block w-2/3 px-3 py-1 mt-2 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <input
                type="number"
                :disabled="!newProject"
                :class="{ 'bg-gray-300': !newProject }"
                v-model="appelDeFond.achevementTravauxMois"
                id="achevementTravauxMois"
                min="0"
                max="36"
                class="block w-2/3 px-3 py-1 mt-2 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
              <input
                type="number"
                disabled
                :class="{ 'bg-gray-300': !newProject }"
                v-model="this.moisMax"
                id="livraisonRemiseClefMois"
                class="block w-2/3 px-3 py-1 mt-2 bg-gray-300 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <p class="text-center text-red-600" v-if="error != ''">
            <img
              class="inline-block w-auto h-6 mx-2"
              src="@/assets/picto/Panneau.png"
              alt="pictogramme panneau attention"
            />{{ error }}
          </p>
          <p class="text-center text-red-600" v-if="error2 != ''">
            <img
              class="inline-block w-auto h-6 mx-2"
              src="@/assets/picto/Panneau.png"
              alt="pictogramme panneau attention"
            />{{ error2 }}
          </p>
          <p class="text-center text-red-600" v-if="errorMoisMax && moisMax != false">
            <img
              class="inline-block w-auto h-6 mx-2"
              src="@/assets/picto/Panneau.png"
              alt="pictogramme panneau attention"
            />Attention, l'échelonnage des paiements des appels de fonds ne peut pas dépasser
            <span class="font-bold">{{ moisMax }}</span>
          </p>
          <p class="pt-0 m-0 text-center text-red-600" v-if="errorMoisMax && moisMax != false">
            mois correspondant à la date d'achèvement des travaux et de mise en location.
          </p>
          <p class="pt-2 text-lg font-bold text-center text-black">Cumul des appels de fonds</p>
          <div class="px-5 grid grid-cols-3">
            <div class="col-span-1">
              <p class="head-text">Période</p>
              <p class="content-text">Année 1</p>
              <p class="content-text">Année 2</p>
              <p class="content-text">Année 3</p>
            </div>
            <div class="col-span-1 justify-self-start">
              <p class="head-text">Valeur</p>
              <div>
                <p class="text-right content-text">{{ this.cumulADF[0].number }} €</p>
              </div>
              <div>
                <p class="text-right content-text">{{ this.cumulADF[1].number }} €</p>
              </div>
              <div>
                <p class="text-right content-text">{{ this.cumulADF[2].number }} €</p>
              </div>
            </div>
            <div class="col-span-1">
              <p class="head-text">Pourcentage</p>
              <p class="content-text">{{ this.cumulADF[0].percent }}%</p>
              <p class="content-text">{{ this.cumulADF[1].percent }}%</p>
              <p class="content-text">{{ this.cumulADF[2].percent }}%</p>
            </div>
          </div>

          <div class="my-6">
            <div class="flex items-center justify-center px-4 text-right sm:px-6">
              <span class="inline-flex rounded-md shadow-sm">
                <a
                  @click="closeAndSave"
                  class="inline-flex items-center justify-center py-4 text-base font-bold text-white whitespace-no-wrap border border-transparent px-15 leading-6 rounded-md bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
                >
                  Enregistrer les modifications
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectTools from '@/tools/Project'
import moment from 'moment'
import CloseIcon from '@/components/SVG/CloseIcon.vue'

export default {
  name: 'AppelDeFond',
  props: {
    newProject: Boolean,
    price: Number,
    appelDeFond: Object,
    tva: Number,
    dateacqui: String,
    achevementtravaux: String,
  },
  data() {
    return {
      display_confirm: false,
    }
  },
  components: {
    CloseIcon
  },
  methods: {
    closeAndSave() {
      if (this.error == '' && this.error2 == '') {
        this.appelDeFond.livraisonRemiseClefMois = this.moisMax
        this.$emit('close', true, this.appelDeFond, this.cumulADF)
      } else {
        alert("Il y a une erreur dans l'entrée de vos données")
      }
    },
    closeWithoutSaving() {
      this.display_confirm = false
      this.$emit('close', false, 0, 0)
    },
    calculateYear(year) {
      const y = Number.parseInt(year)
      if (y) {
        return Math.round((Number.parseInt(this.price + this.tva) * y) / 100).toLocaleString('fr-FR')
      } else {
        return 0
      }
    },
  },
  created: function () {},
  computed: {
    cumulADF() {
      return ProjectTools.cumulADF(this.appelDeFond, this.price, this.tva)
    },
    moisMax() {
      if (this.achevementtravaux !== '' && this.dateacqui !== '') {
        const [year1, month1] = this.achevementtravaux.split('-')
        const [year2, month2] = this.dateacqui.split('-')
        const diff = (parseInt(year1) - parseInt(year2)) * 12 + (parseInt(month1) - parseInt(month2))
        return Math.round(diff * 100) / 100
      } else {
        console.log('error string to date')
        return false
      }
    },
    error() {
      if (
        this.appelDeFond.achevementTravauxMois < +this.appelDeFond.miseEauAirMois ||
        +this.appelDeFond.miseEauAirMois < +this.appelDeFond.achevementFondationMois
      ) {
        return 'Les mois doivent être dans un ordre croissant'
      } else {
        return ''
      }
    },
    error2() {
      if (
        +this.appelDeFond.achevementTravauxMois == +this.appelDeFond.miseEauAirMois ||
        +this.appelDeFond.miseEauAirMois == +this.appelDeFond.achevementFondationMois ||
        +this.appelDeFond.achevementFondationMois == 1
      ) {
        return 'Les mois ne peuvent pas avoir la même valeur'
      } else {
        return ''
      }
    },
    errorMoisMax() {
      if (
        +this.appelDeFond.achevementTravauxMois > +this.moisMax ||
        +this.appelDeFond.miseEauAirMois > +this.moisMax ||
        +this.appelDeFond.achevementFondationMois > +this.moisMax
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.head-text {
  font-size: 15px;
  line-height: 40px;
}

.content-text {
  font-size: 14px;
  line-height: 40px;
}

.subtitle {
  font-size: 14px;
  margin-top: 60px;
}
</style>
