<template>
  <!-- <div v-if="!selected" class="flex items-center justify-center pt-10">
    <svg xmlns="http://www.w3.org/2000/svg"
         style="margin: 20px auto auto;background: rgb(255, 255, 255); display: block; shape-rendering: auto;"
         width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25" stroke-dasharray="70.68583470577033 25.561944901923447">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  </div> -->
  <!-- <div v-else class="pt-10 text-left bg"> LE DIV DU DESSOUS + LE V-ELSE -->
  <div class="py-10 text-left bg">
    <form @submit.prevent="openModalValidation" @keypress.enter.prevent novalidate>
      <SidebarInvestor />

      <!-- INFORMATION CARD -->
      <div id="infos" class="grid grid-cols-10">
        <div class="mr-8 card">
          <div class="flex flex-row align-middle card-header">
            <img src="../assets/icons/faq4.jpg" class="w-20 h-20" alt="informations de l’investisseur" />
            <div ref="qui" class="flex flex-col my-auto ml-5">
              <p class="text-xl">Qui est votre client ?</p>
              <p class="text-xl font-bold">Informations de l’investisseur</p>
            </div>
          </div>
          <div class='flex flex-col gap-5'>
          <div class="card-body">
              <div class='flex flex-row flex-wrap justify-start gap-y-2 gap-x-5'>
              <!-- GENRE -->
              <div class='flex flex-col justify-between'>
                <label for="gender" class="text-xs font-medium text-gray-700">Genre *</label>
                <select
                  class="text-gray-500 bg-transparent border form-select form-input sm:text-xs sm:leading-5"
                  v-model="gender"
                  name="gender"
                  id="gender"
                >
                  <!-- <option disabled value=""></option> -->
                  <option value="femme">Femme</option>
                  <option value="homme" selected>Homme</option>
                  <option value="societe" selected>Société</option>
                </select>
              </div>
              <!-- FIN GENRE -->
              <!-- NOM SOCIETE-->
              <div class='flex flex-col justify-between' v-if="gender === 'societe'">
                <label for="nameSociete" class="text-xs font-medium text-gray-700">Nom de la société</label>
                <input
                  type="text"
                  v-model="nameSociete"
                  id="nameSociete"
                  class="w-24 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
              <!-- FIN NOM SOCIETE-->
              <!-- COFORME JURIDIQUE -->
              <div class='flex flex-col justify-between' v-if="gender === 'societe'">
                  <label for="forme" class="text-xs font-medium text-gray-700">Forme juridique</label>
                <input
                  type="text"
                  v-model="forme"
                  id="forme"
                  class="w-24 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
              <!-- FIN COFORMEE JURIDIQUE -->
              <!-- NOM -->
              <div class='flex flex-col justify-between'>
                  <label for="name" class="text-xs font-medium text-gray-700">Nom <span v-if="gender === 'societe'">du représentant</span>*</label>
                <input
                  type="text"
                  v-model="name"
                  id="name"
                  class="w-24 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
              <!-- FIN NOM -->
              <!-- PRENOM -->
              <div class='flex flex-col justify-between'>
                  <label for="prenom" class="text-xs font-medium text-gray-700">Prénom <span v-if="gender === 'societe'">du représentant</span> *</label>
                <input
                  type="text"
                  v-model="prenom"
                  id="prenom"
                  class="w-24 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
              <!-- FIN NOM -->
              <!-- PHOTO -->
              <div class='flex flex-col justify-between'>
                <label for="photo" class="text-xs font-medium text-gray-700">
                  Photo
                  <div
                    class="text-center border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5 "
                    :class="
                      this.file
                        ? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110'
                        : 'bg-white'
                    "
                  >
                    <div
                      class="my-auto cursor-pointer"
                      :class="
                        file
                          ? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110'
                          : 'bg-white'
                      "
                    >
                      Parcourir
                    </div>
                  </div>
                </label>
                <input
                  id="photo"
                  ref="file"
                  @change="onSelect"
                  accept="image/jpg, image/jpeg, image/png"
                  type="file"
                  class="border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
              <!-- FIN PHOTO -->
              <!-- Téléphone -->
              <div class='flex flex-col justify-between'>
                <label for="phone" class="text-xs font-medium text-gray-700"
                  >Numéro de téléphone</label
                >
                <input
                  v-model="phone"
                  id="phone"
                  type="tel"
                  class="border border-gray-300 w-28 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
              <!-- FIN Téléphone -->
              <!-- Email -->
              <div class='flex flex-col justify-between'>
                <label for="email" class="text-xs font-medium text-gray-700">E-mail</label>
                <input
                  v-model="mail"
                  id="email"
                  type="email"
                  class="border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
            </div>
          </div>
              <!-- FIN Email -->

              <!-- DEBUT button -->
              <div v-if="gender != 'societe'" class='flex'>
                <button
                  type="button"
                  class="p-2 text-sm font-medium text-white border-transparent transition duration-150 ease-in-out rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700"
                  :class="coInvestor ? 'bg-red-400 hover:bg-darkblueaestima-500' : 'bg-lightblueaestima-100 hover:bg-darkblueaestima-500'"
                  @click="coInvestor = !coInvestor"
                >
                    <p v-if='coInvestor === false'>Ajouter un co-investisseur</p>
                    <p v-else>Supprimer le co-investisseur</p>
                </button>
            </div>
            <!-- FIN button -->

          <div class="card-body" v-if="coInvestor === true && gender != 'societe'">
              <div class='flex flex-row flex-wrap justify-start gap-x-5 gap-y-2'>
              <!-- GENRE -->
          <div class='flex flex-col justify-between'>
            <label for="coGender" class="text-xs font-medium text-gray-700">Genre *</label>
                <select
                  class="text-gray-500 bg-transparent border form-select form-input sm:text-xs sm:leading-5"
                  v-model="coGender"
                  name="coGender"
                  id="coGender"
                >
                  <!-- <option disabled value=""></option> -->
                  <option value="femme">Femme</option>
                  <option value="homme" selected>Homme</option>
                </select>
              </div>
              <!-- FIN GENRE -->
              <!-- NOM -->
              <div class='flex flex-col justify-between'>
                  <label for="coName" class="text-xs font-medium text-gray-700">Nom *</label>
                <input
                  type="text"
                  v-model="coName"
                  id="coName"
                  class="w-24 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
              <!-- FIN NOM -->
              <!-- PRENOM -->
              <div class='flex flex-col justify-between'>
                  <label for="coPrenom" class="text-xs font-medium text-gray-700">Prénom *</label>
                <input
                  type="text"
                  v-model="coPrenom"
                  id="coPrenom"
                  class="w-24 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
              <!-- FIN NOM -->
              <!-- PHOTO -->
              <div class='flex flex-col justify-between'>
                <label for="coPhoto" class="text-xs font-medium text-gray-700">
                  Photo
                  <div
                    class="text-center border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5 "
                    :class="
                      this.coFile
                        ? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110'
                        : 'bg-white'
                    "
                  >
                    <div
                      class="my-auto cursor-pointer"
                      :class="
                        coFile
                          ? 'bg-gradient-to-r from-teal-100 to-green-100 transition duration-500 ease-in-out scale-110'
                          : 'bg-white'
                      "
                    >
                      Parcourir
                    </div>
                  </div>
                </label>
                <input
                  id="coPhoto"
                  ref="file"
                  @change="onCoSelect"
                  accept="image/jpg, image/jpeg, image/png"
                  type="file"
                  class="border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
              <!-- FIN PHOTO -->
              <!-- Téléphone -->
              <div class='flex flex-col justify-between'>
                <label for="coPhone" class="text-xs font-medium text-gray-700"
                  >Numéro de téléphone</label
                >
                <input
                  v-model="coPhone"
                  id="coPhone"
                  type="tel"
                  class="border border-gray-300 w-28 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
              <!-- FIN Téléphone -->
              <!-- Email -->
              <div class='flex flex-col justify-between'>
                <label for="coEmail" class="text-xs font-medium text-gray-700">E-mail</label>
                <input
                  v-model="coEmail"
                  id="coEmail"
                  type="email"
                  class="border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                />
              </div>
            </div>
          </div>

              <!-- Doc Contrat -->
              <!-- <div class="text-xs text-right col-span-2 col-start-8">
								Document contractuel
								<QuestionMarkv2
									class="float-right ml-2"
									:text="`${infobulles.documentContractuel}`"
								/>
								<div class="text-">mandat, contrat de réservation, etc</div>
							</div>
							<div class="px-3 col-span-2 col-start-10">
								<div
									class="flex justify-center p-0 mt-1 text-center border border-gray-300 h-9 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5 h-100"
								>
									<div class="my-auto">Parcourir</div>
								</div>

								<input
									id="photo"
									ref="file"
									@change="onSelect"
									accept="image/jpg, image/jpeg, image/png"
									type="file"
									class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
								/>
							</div> -->
              <!-- FIN Doc Contrat -->
          <div class='flex flex-row gap-5'>
            <!-- Pays de résidence fiscale -->
            <div class='flex flex-col gap-1'>
              <label for="pays" class="text-xs font-medium text-gray-700"
                >Pays de résidence fiscale</label
              >
              <input
                v-model="pays"
                id="pays"
                type="text"
                class="border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
              />
            </div>
            <!-- FIN Pays de résidence fiscale -->
            <!-- Adresse -->
            <div class='flex flex-col gap-1 w-52'>
              <label for="adress" class="text-xs font-medium text-gray-700 w-52"
                >Adresse postale</label
              >
              <input
                v-model="adress"
                id="adress"
                type="text"
                class="border border-gray-300 w-52 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
              />
            </div>
            <!-- FIN Adresse -->
          </div>
      </div>
        </div>
      </div>
      <!-- FIN INFORMATION CARD -->

      <!-- INVESTISSEMENT CARD -->
      <div id="stratégie" class="grid grid-cols-10">
        <div class="p-3 px-5 mt-5 mb-10 mr-8 card col-span-10 col-start-3 pb-7">
          <div class="flex flex-row align-middle card-header">
            <img src="../assets/icons/faq6.jpg" class="w-20 h-20" alt="Stratégie d’investissement" />
            <div class="flex flex-col my-auto ml-5">
              <p class="text-xl">Pourquoi investit-il ?</p>
              <p class="text-xl font-bold">Stratégie d’investissement</p>
            </div>
          </div>
          <div class="mt-10 card-body">
            <div class="mt-4 grid grid-cols-12">
              <!-- TYPE -->
              <div class="col-span-8 col-start-3">
                <label for="strategy" class="block text-xs font-medium text-gray-700 leading-5"
                  >Quel est l’objectif principal de votre client ?</label
                >
                <select
                  class="w-full h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
                  v-model="strategy_calc"
                  name="strategy"
                  id="strategy"
                >
                  <option value="retraite">Avoir des revenus locatifs pour sa retraite</option>
                  <option value="patrimoine">Avoir un patrimoine à moindre coût</option>
                  <option value="revenus">Générer des revenus immédiatement</option>
                  <option value="defiscaliser">Défiscaliser ses revenus</option>
                </select>
              </div>
              <div class="col-span-8 col-start-3">
                <div class="relative px-5 py-3 border-2 rounded-lg my-7 tips col-span-6 sm:col-span-4">
                  <img
                    class="absolute z-0 w-auto max-h-10 ampoule-picto"
                    src="../assets/picto/light.png"
                    alt=""
                  />
                  <div class="mt-1 grid grid-cols-2">
                    <p class="text-right col-span-1">
                      <b>Sensibilité de l'investissement</b>
                    </p>
                    <p class="col-span-1">
                      <em class="ml-4 not-italic text-black normal-case font-base">{{
                        this.strategyInfo.sensibility
                      }}</em>
                    </p>
                  </div>
                  <div class="mt-1 grid grid-cols-2">
                    <p class="text-right col-span-1">
                      <b>Horizon d'investissement</b>
                    </p>
                    <p class="col-span-1">
                      <em class="ml-4 not-italic text-black normal-case font-base">{{
                        this.strategyInfo.horizon
                      }}</em>
                    </p>
                  </div>
                  <div class="mt-1 grid grid-cols-2">
                    <p class="text-right col-span-1">
                      <b>Critère prioritaire</b>
                    </p>
                    <p class="ml-4 col-span-1">
                      <em class="not-italic text-justify text-black normal-case font-base">{{
                        this.strategyInfo.critere
                      }}</em>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-span-8 col-start-3">
                <label for="stratinfo" class="block text-xs font-medium text-gray-700 leading-5"
                  >Plus de précisions concernant sa stratégie</label
                >
                <textarea
                  v-model="stratInfo"
                  id="stratinfo"
                  type="text"
                  class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN INVESTISSEMENT CARD -->

      <!-- FISCAL CARD -->
      <div id="situation" class="grid grid-cols-10">
        <div class="p-3 px-5 mt-5 mb-10 mr-8 card col-span-10 col-start-3 pb-7">
          <div class="flex flex-row align-middle card-header">
            <img src="../assets/icons/faq7.jpg" class="w-20 h-20" alt="Situation fiscale" />
            <div class="flex flex-col my-auto ml-5">
              <p class="text-xl">Comment est-il imposé ?</p>
              <p class="text-xl font-bold">sa situation fiscale et son imposition</p>
            </div>
          </div>
          <div class="mt-10 card-body">
            <div class="flex justify-around text-center">
              <div class="flex flex-col" v-if="this.investSelected == null">
                <div>
                  <p>
                    <b>{{ type_fiscalite_display_gauche.titre }}</b>
                  </p>
                  <p>{{ type_fiscalite_display_gauche.soustitre }}</p>
                </div>
              </div>
              <label class="switch" v-if="this.investSelected == null">
                <input type="checkbox" @click="tmi_fix = !tmi_fix" />
                <span class="slider"></span>
              </label>
              <div class="flex flex-col">
                <div>
                  <p>
                    <b>{{ type_fiscalite_display_droite.titre }}</b>
                  </p>
                  <p>{{ type_fiscalite_display_droite.soustitre }}</p>
                </div>
              </div>
            </div>
            <!-- FISCALITE IDENTIQUE -->
            <div v-if="tmi_fix" class="mt-12 grid grid-cols-12">
              <div class="flex flex-row justify-between col-span-8 col-start-2">
                <label
                  for="residencefiscale"
                  class="block my-auto text-xs font-medium text-gray-700 leading-5"
                  >Résidence fiscale du client</label
                >
                <select
                  class="h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
                  v-model="residence_fiscale"
                  name="residencefiscale"
                  id="residencefiscale"
                >
                  <option selected value="france">France</option>
                  <option value="rev_locatif_metro">Etranger (revenus locatifs métropolitains)</option>
                  <option value="rev_locatif_dom">Etranger (revenus locatifs DOM)</option>
                </select>
              </div>
              <div v-if="residence_fiscale === 'france'" class="mt-5 col-span-10 col-start-2">
                <div class="flex flex-row items-center">
                  <label class="block text-xs font-medium text-gray-700 leading-5"
                    >Tranche Marginale d’Imposition (TMI)*</label
                  >
                  <QuestionMark
                    class="ml-2"
                    :text="`Pour tranche marginale d’imposition, cette donnée correspond au pourcentage d’impôt sur le revenu auquel vous êtes soumis en fonction de vos revenus. <u><a target='_blank' href='https://www.service-public.fr/particuliers/vosdroits/F1419'>Cliquez ici</a></u> pour savoir sur quelle tranche d'imposition vous vous trouvez.`"
                  />
                </div>
                <div class="flex flex-row items-center mt-4">
                  <div
                    style="background-color: #c7caff"
                    :class="{ 'text-white font-bold': tranche_tmi === 0 }"
                    class="py-1 mr-2 font-semibold border-2 border-gray-100 rounded-full cursor-pointer px-7 hover:bg-yellow-300 hover:text-white hover:font-bold"
                    @click="tranche_tmi = 0"
                  >
                    0%
                  </div>
                  <div
                    style="background-color: #c7caff"
                    :class="{ 'text-white font-bold': tranche_tmi === 11 }"
                    class="py-1 mr-2 font-semibold border-2 border-gray-100 rounded-full cursor-pointer px-7 hover:bg-yellow-300 hover:text-white hover:font-bold"
                    @click="tranche_tmi = 11"
                  >
                    11%
                  </div>
                  <div
                    style="background-color: #c7caff"
                    :class="{ 'text-white font-bold': tranche_tmi === 30 }"
                    class="py-1 mr-2 font-semibold border-2 border-gray-100 rounded-full cursor-pointer px-7 hover:bg-yellow-300 hover:text-white hover:font-bold"
                    @click="tranche_tmi = 30"
                  >
                    30%
                  </div>
                  <div
                    style="background-color: #c7caff"
                    :class="{ 'text-white font-bold': tranche_tmi === 41 }"
                    class="py-1 mr-2 font-semibold border-2 border-gray-100 rounded-full cursor-pointer px-7 hover:bg-yellow-300 hover:text-white hover:font-bold"
                    @click="tranche_tmi = 41"
                  >
                    41%
                  </div>
                  <div
                    style="background-color: #c7caff"
                    :class="{ 'text-white font-bold': tranche_tmi === 45 }"
                    class="py-1 mr-2 font-semibold border-2 border-gray-100 rounded-full cursor-pointer px-7 hover:bg-yellow-300 hover:text-white hover:font-bold"
                    @click="tranche_tmi = 45"
                  >
                    45%
                  </div>
                </div>
                <div class="relative px-6 py-4 my-10 border-2 rounded-lg tips col-span-6 sm:col-span-4">
                  <img
                    class="absolute z-0 w-auto max-h-10 ampoule-picto"
                    src="../assets/picto/light.png"
                    alt=""
                  />
                  <div class="flex flex-row justify-between">
                    <p>Si les revenus sont inférieurs à 10 225€, la TMI applicable sera de</p>
                    <p class="font-bold">0%</p>
                  </div>
                  <div class="flex flex-row justify-between">
                    <p>Si les revenus sont entre 10 226€ et 26 070€, la TMI applicable sera de</p>
                    <p class="font-bold">11%</p>
                  </div>
                  <div class="flex flex-row justify-between">
                    <p>Si les revenus sont entre 26 071€ et 74 545€, la TMI applicable sera de</p>
                    <p class="font-bold">30%</p>
                  </div>
                  <div class="flex flex-row justify-between">
                    <p>Si les revenus sont entre 74 546€ et 160 336€, la TMI applicable sera de</p>
                    <p class="font-bold">41%</p>
                  </div>
                  <div class="flex flex-row justify-between">
                    <p>Si les revenus sont supérieurs à 160 336€, la TMI applicable sera de</p>
                    <p class="font-bold">45%</p>
                  </div>
                </div>
              </div>
              <div v-else-if="residence_fiscale === 'rev_locatif_metro'" class="mt-5 col-span-8 col-start-2">
                <div class="flex flex-row items-center">
                  <label class="block text-xs font-medium text-gray-700 leading-5"
                    >Tranche marginiale d’imposition (TMI) du client sur ses revenus locatifs
                    métropolitains</label
                  >
                </div>
                <div class="flex flex-row items-center mt-4">
                  <div
                    style="background-color: #c7caff"
                    :class="{ 'text-white font-bold': tranche_tmi === 20 }"
                    class="py-1 mr-2 font-semibold border-2 border-gray-100 rounded-full px-7 hover:bg-yellow-300 hover:text-white hover:font-bold"
                    @click="tranche_tmi = 20"
                  >
                    20%
                  </div>
                  <div
                    style="background-color: #c7caff"
                    :class="{ 'text-white font-bold': tranche_tmi === 30 }"
                    class="py-1 mr-2 font-semibold border-2 border-gray-100 rounded-full px-7 hover:bg-yellow-300 hover:text-white hover:font-bold"
                    @click="tranche_tmi = 30"
                  >
                    30%
                  </div>
                </div>
                <div class="relative px-6 py-4 my-10 border-2 rounded-lg tips col-span-6 sm:col-span-4">
                  <img
                    class="absolute z-0 w-auto max-h-10 ampoule-picto"
                    src="../assets/picto/light.png"
                    alt=""
                  />
                  <div class="flex flex-row justify-between">
                    <p>Si les revenus sont inférieurs à 26 070€, la TMI applicable sera de</p>
                    <p class="font-bold">20%</p>
                  </div>
                  <div class="flex flex-row justify-between">
                    <p>Si les revenus sont supérieurs à 26 070€, la TMI applicable sera de</p>
                    <p class="font-bold">30%</p>
                  </div>
                </div>
              </div>
              <div v-else-if="residence_fiscale === 'rev_locatif_dom'" class="mt-5 col-span-8 col-start-2">
                <div class="flex flex-row items-center">
                  <label class="block text-xs font-medium text-gray-700 leading-5"
                    >Tranche marginiale d’imposition (TMI) du client sur ses revenus locatifs
                    métropolitains</label
                  >
                </div>
                <div class="flex flex-row items-center mt-4">
                  <div
                    style="background-color: #c7caff"
                    :class="{ 'text-white font-bold': tranche_tmi === 14.4 }"
                    class="py-1 mr-2 font-semibold border-2 border-gray-100 rounded-full px-7 hover:bg-yellow-300 hover:text-white hover:font-bold"
                    @click="tranche_tmi = 14.4"
                  >
                    14.4%
                  </div>
                  <div
                    style="background-color: #c7caff"
                    :class="{ 'text-white font-bold': tranche_tmi === 20 }"
                    class="py-1 mr-2 font-semibold border-2 border-gray-100 rounded-full px-7 hover:bg-yellow-300 hover:text-white hover:font-bold"
                    @click="tranche_tmi = 20"
                  >
                    20%
                  </div>
                </div>
                <div class="relative px-6 py-4 my-10 border-2 rounded-lg tips col-span-6 sm:col-span-4">
                  <img
                    class="absolute z-0 w-auto max-h-10 ampoule-picto"
                    src="../assets/picto/light.png"
                    alt=""
                  />
                  <div class="flex flex-row justify-between">
                    <p>Si les revenus sont inférieurs à 26 070€, la TMI applicable sera de</p>
                    <p class="font-bold">14.4%</p>
                  </div>
                  <div class="flex flex-row justify-between">
                    <p>Si les revenus sont supérieurs à 26 070€, la TMI applicable sera de</p>
                    <p class="font-bold">20%</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- FIN FISCALITE IDENTIQUE -->
            <!-- FISCALITE VARIABLE -->
            <div v-else>
              <div class="mt-12 grid grid-cols-12">
                <div class="col-span-2 col-start-2">
                  <label
                    for="situation_matrimoniale"
                    class="block text-xs font-medium text-gray-700 leading-5"
                    >Situation matrimoniale</label
                  >
                  <select
                    class="w-full h-10 py-0 pl-2 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
                    v-model="fiscalite[0].situation"
                    @change="propagate('situation')"
                    name="situation_matrimoniale"
                    id="situation_matrimoniale"
                  >
                    <option value="celibataire">Célibataire</option>
                    <option value="marie">Marié</option>
                    <option value="pacse">Pacsé</option>
                    <option value="veuf">Veuf</option>
                    <option value="divorce">Divorcé</option>
                    <option value="separe">Séparé</option>
                  </select>
                </div>
                <div class="ml-5 col-span-3 col-start-4">
                  <label for="pays" class="block text-xs font-medium text-gray-700 leading-5"
                    >Nombre d'enfants à charge</label
                  >
                  <input
                    v-model="fiscalite[0].enfants"
                    @change="onChangeEnfants"
                    id="enfants"
                    type="number"
                    min="0"
                    class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                  />
                </div>
                <div class="ml-12 text-sm col-span-5 col-start-7">
                  <div class="relative px-3 py-4 border-2 rounded-lg tips col-span-6 sm:col-span-4">
                    <img
                      class="absolute z-0 w-auto max-h-10 ampoule-picto"
                      src="../assets/picto/light.png"
                      alt=""
                    />
                    <div class="flex flex-row justify-between px-4">
                      <p class="col-span-1">Nombre de parts fiscales du foyer</p>
                      <p class="col-span-1">
                        <b>{{ calculPartsFiscalesTot }}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 grid grid-cols-12">
                <p class="ml-5 col-span-4 col-start-1">
                  <b>Composition de la base imposable</b>
                </p>
              </div>
              <div class="mt-5 grid grid-cols-12">
                <div class="flex flex-row justify-between mt-5 col-span-12 col-start-2 col-end-12 gap-2">
                  <div>
                    <QuestionMarkv2 class="float-right mr-2" :text="`${infobulles.traitementSalaires}`" />
                    <label for="fiscalite_salaire" class="block text-xs font-medium text-gray-700 leading-5"
                      >Traitement et salaire</label
                    >
                    <div class="flex flex-row mt-1 place-items-center gap-1">
                      <input
                        v-model.number="fiscalite[0].salaire"
                        @change="calculateSalaryEvolution"
                        id="fiscalite_salaire"
                        type="text"
                        class="block w-full px-2 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                      <div class="text-sm">€</div>
                    </div>
                  </div>
                  <div>
                    <QuestionMarkv2
                      class="float-right mr-2"
                      :text="`${infobulles.evolutionAnnuelleSalaires}`"
                    />
                    <label for="fiscalite_evolution" class="block text-xs font-medium text-gray-700 leading-5"
                      >Evolution annuelle salaire</label
                    >
                    <div class="flex flex-row mt-1 place-items-center gap-1">
                      <input
                        v-model.number="fiscalite_evolution_salaire"
                        @change="calculateSalaryEvolution"
                        id="fiscalite_evolution"
                        type="text"
                        class="block w-full px-2 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                      <div class="text-sm">%</div>
                    </div>
                  </div>
                  <div>
                    <QuestionMarkv2 class="float-right mr-2" :text="`${infobulles.revenusBICBNC}`" />
                    <label for="fiscalite_bic_bnc" class="block text-xs font-medium text-gray-700 leading-5"
                      >Revenus BIC / BNC du travail</label
                    >
                    <div class="flex flex-row mt-1 place-items-center gap-1">
                      <input
                        v-model.number="fiscalite[0].autrebic"
                        @change="checkAndPropagate($event)"
                        id="fiscalite_bic_bnc"
                        type="text"
                        class="block w-full px-2 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                      <div class="text-sm">€</div>
                    </div>
                  </div>
                  <div>
                    <QuestionMarkv2 class="float-right mr-2" :text="`${infobulles.revenusRCM}`" />
                    <label for="fiscalite_rcm" class="block text-xs font-medium text-gray-700 leading-5"
                      >Revenus capitaux (RCM)</label
                    >
                    <div class="flex flex-row mt-1 place-items-center gap-1">
                      <input
                        v-model.number="fiscalite[0].rcm"
                        @change="checkAndPropagate($event)"
                        id="fiscalite_rcm"
                        type="text"
                        class="block w-full px-2 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                      <div class="text-sm">€</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-5 grid grid-cols-12">
                <div
                  class="flex flex-row justify-between mt-5 align-middle col-span-12 col-start-2 col-end-12 gap-2"
                >
                  <div class="">
                    <QuestionMarkv2 class="float-right mr-2" :text="`${infobulles.revenusFonciers}`" />
                    <label for="fiscalite_fonciers" class="block text-xs font-medium text-gray-700 leading-5"
                      >Revenus fonciers</label
                    >
                    <div class="flex flex-row mt-1 place-items-center gap-1">
                      <input
                        v-model.number="fiscalite[0].fonciers"
                        @change="checkAndPropagate($event)"
                        id="fiscalite_fonciers"
                        type="text"
                        class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                      <div class="text-sm">€</div>
                    </div>
                  </div>
                  <div class="">
                    <QuestionMarkv2 class="float-right mr-2" :text="`${infobulles.revenusBIClocatifs}`" />
                    <label for="fiscalite_bic" class="block text-xs font-medium text-gray-700 leading-5"
                      >Revenus BIC locatifs</label
                    >
                    <div class="flex flex-row mt-1 place-items-center gap-1">
                      <input
                        v-model.number="fiscalite[0].bic"
                        @change="checkAndPropagate($event)"
                        id="fiscalite_bic"
                        type="text"
                        class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                      <div class="text-sm">€</div>
                    </div>
                  </div>
                  <div class="">
                    <QuestionMarkv2 class="float-right mr-2" :text="`${infobulles.abattementsDéductions}`" />
                    <label
                      for="fiscalite_abattement"
                      class="block text-xs font-medium text-gray-700 leading-5"
                      >Abattements / déductions</label
                    >
                    <div class="flex flex-row mt-1 place-items-center gap-1">
                      <input
                        v-model.number="fiscalite[0].deduction"
                        @change="checkAndPropagate($event)"
                        id="fiscalite_abattement"
                        type="text"
                        class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                      <div class="text-sm">€</div>
                    </div>
                  </div>
                  <div class="w-1/5 my-auto">
                    <a
                      @click="showFiscaliteModal"
                      class="inline-flex items-center justify-center w-full px-1 py-1 text-xs font-medium text-center text-white border border-transparent leading-6 rounded-md bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
                      >Ouvrir le tableau d’évolution de l’imposition</a
                    >
                    <Fiscalite :fiscalite="fiscalite" v-if="fiscalite_modal" @close="closeFiscaliteModal" />
                  </div>
                </div>
              </div>
              <div class="mt-5 grid grid-cols-12">
                <div class="col-span-6 col-start-4">
                  <div class="relative px-6 py-4 my-10 border-2 rounded-lg tips col-span-6 sm:col-span-4">
                    <img
                      class="absolute z-0 w-auto max-h-10 ampoule-picto"
                      src="../assets/picto/light.png"
                      alt=""
                    />
                    <div class="flex flex-row justify-between">
                      <p class="col-span-1">Tranche marginale d’imposition actuelle (TMI)</p>
                      <p class="col-span-1">
                        <b>{{ tranche_tmi }}%</b>
                      </p>
                    </div>
                    <div class="flex flex-row justify-between mt-4">
                      <p class="col-span-1">Revenu net imposable avant projet</p>
                      <p class="col-span-1">
                        <b>{{ RevenuNetAvtComputed }}€</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FIN FISCALITE VARIABLE -->
          </div>
        </div>
      </div>
      <!-- FIN FISCAL CARD -->

      <!-- CRITERE CARD -->
      <div id="critères" class="grid grid-cols-10">
        <div class="p-3 px-5 mt-5 mb-10 mr-8 card col-span-10 col-start-3 pb-7">
          <div class="flex flex-row align-middle card-header">
            <img src="../assets/icons/faq8.jpg" class="w-20 h-20" alt="critères de recherche" />
            <div class="flex flex-col my-auto ml-5">
              <p class="text-xl">Que recherche votre client ?</p>
              <p class="text-xl font-bold">Critères de recherche</p>
            </div>
          </div>
          <div class="mt-10 card-body">
            <div class="mt-12 grid grid-cols-12">
              <div class="flex flex-row justify-between col-span-12 col-start-2 col-end-12">
                <div class="w-1/3 mx-5">
                  <label for="search_bien" class="block text-xs font-medium text-gray-700 leading-5"
                    >Catégorie de bien</label
                  >
                  <select
                    class="w-full h-10 py-0 pl-2 mt-4 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
                    v-model="search_bien"
                    name="search_bien"
                    id="search_bien"
                  >
                    <option disabled value="">Choisir un type de bien</option>
                    <option value="appartement">Appartement</option>
                    <option value="maison">Maison</option>
                    <option value="immeuble_residentiel">Immeuble résidentiel</option>
                    <option value="immeuble_rapport">Immeuble de rapport</option>
                    <option value="indifferent">Indifférent</option>
                  </select>
                </div>
                <div class="w-1/3 mx-5">
                  <label for="search_type" class="block text-xs font-medium text-gray-700 leading-5"
                    >Type et style de l’immeuble</label
                  >
                  <select
                    class="w-full h-10 py-0 pl-2 mt-4 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
                    v-model="search_type"
                    name="search_type"
                    id="search_type"
                  >
                    <option disabled value="">Choisir un style</option>
                    <option value="recent_tous_styles">Récent tous styles</option>
                    <option value="ancien_tous_styles">Ancien tous styles</option>
                    <option value="style_haussmannien">Style Haussmannien</option>
                    <option value="style_art_déco">Style Art déco</option>
                    <option value="style_empire">Style Empire</option>
                    <option value="style_louis_philippe">Style Louis Philippe</option>
                  </select>
                </div>
                <div class="w-1/3 mx-5">
                  <label for="search_loue" class="block text-xs font-medium text-gray-700 leading-5"
                    >Logement déjà loué ?</label
                  >
                  <select
                    class="w-full h-10 py-0 pl-2 mt-4 text-gray-500 bg-transparent border-transparent form-select pr-7 sm:text-xs sm:leading-5"
                    v-model="search_loue"
                    name="search_loue"
                    id="search_loue"
                  >
                    <option disabled value="">Choisir une préférence</option>
                    <option value="yes">Oui je recherche uniquement ce type de logement</option>
                    <option value="no_pref">Pas de préférence</option>
                    <option value="no">Non je veux qu’il soit vendu libre d’occupant</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="mt-12 grid grid-cols-12">
              <div class="flex flex-row justify-between col-span-12 col-start-2 col-end-12">
                <div class="w-1/3 mx-5">
                  <label for="search_budget" class="block text-xs font-medium text-gray-700 leading-5"
                    >Budget maximum (emprunt compris)</label
                  >
                  <input
                    v-model="search_budget"
                    id="search_budget"
                    type="text"
                    class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                  />
                </div>
                <div class="w-1/4 mx-5">
                  <label for="search_fourch" class="block text-xs font-medium text-gray-700 leading-5"
                    >Fourchette de travaux</label
                  >
                  <input
                    v-model="search_fourch"
                    id="search_fourch"
                    type="text"
                    class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                  />
                </div>
                <div class="w-1/3 mx-5">
                  <label for="search_pref" class="block text-xs font-medium text-gray-700 leading-5"
                    >Préférence d’emplacement</label
                  >
                  <input
                    v-model="search_pref"
                    id="search_pref"
                    type="text"
                    placeholder="Bordeaux, Toulouse, Paris"
                    class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                  />
                </div>
              </div>
            </div>
            <div class="mt-12 grid grid-cols-12">
              <div class="flex flex-row justify-between col-span-12 col-start-2 col-end-12">
                <div class="w-1/6 mx-5">
                  <label for="search_surface" class="block text-xs font-medium text-gray-700 leading-5"
                    >Surface souhaitée</label
                  >
                  <input
                    v-model="search_surface"
                    id="search_surface"
                    type="text"
                    class="block w-full px-3 py-2 mt-5 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                  />
                </div>
                <div class="w-1/5 mx-5">
                  <label for="search_piece" class="block text-xs font-medium text-gray-700 leading-5"
                    >Nombre de pièces</label
                  >
                  <p class="text-xs"><em>(ou de lots pour immeuble)</em></p>
                  <input
                    v-model="search_piece"
                    id="search_piece"
                    type="text"
                    class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                  />
                </div>
                <div class="w-2/4 mx-5">
                  <label for="search_autre" class="block text-xs font-medium text-gray-700 leading-5"
                    >Autres critères spécifiques</label
                  >
                  <textarea
                    placeholder="Rue calme, appartement orienté sud, 1er étage de préférence avec ascenseur..."
                    v-model="search_autre"
                    id="search_autre"
                    class="block w-full px-3 py-2 mt-1 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN CRITERE CARD -->

      <!-- CRITERE CARD -->
      <div class="grid grid-cols-10">
        <div class="p-3 px-5 mt-5 mb-10 mr-8 card col-span-10 col-start-3 pb-7">
          <div class="flex flex-row align-middle card-header">
            <img src="../assets/icons/faq9.jpg" class="w-20 h-20" alt="Exigences de performances" />
            <div class="flex flex-col my-auto ml-5">
              <p class="text-xl">Quelles sont ses exigences de performances ?</p>
              <p class="text-xl font-bold">Exigences financières</p>
            </div>
          </div>
          <div class="mt-10 card-body">
            <div class="grid grid-cols-10">
              <div class="col-span-7 col-start-2">
                <div class="flex flex-row items-center mb-2">
                  <label for="params_flux_avt" class="block text-xs font-medium text-gray-700 leading-5"
                    >Effort d’épargne maximum ou gain récurrent minimum souhaité.</label
                  >
                </div>
                <div class="flex flex-row items-center">
                  <input
                    v-model.number="params_flux_avt"
                    type="range"
                    min="-1000"
                    max="1000"
                    step="50"
                    class="slider-input"
                    id="params_flux_avt"
                  />
                  <div class="ml-4">{{ params_flux_avt }} €</div>
                </div>
              </div>

              <div class="mt-6 col-span-7 col-start-2">
                <div class="flex flex-row items-center mb-2">
                  <label for="params_profit_tot" class="block text-xs font-medium text-gray-700 leading-5"
                    >Profit total généré</label
                  >
                </div>
                <div class="flex flex-row items-center">
                  <input
                    v-model.number="params_profit_tot"
                    type="range"
                    min="0"
                    max="400000"
                    step="25000"
                    class="slider-input"
                    id="params_profit_tot"
                  />
                  <div class="ml-4">{{ params_profit_tot }} €</div>
                </div>
              </div>

              <div class="my-6 col-span-7 col-start-2">
                <div class="flex flex-row items-center mb-2">
                  <label for="params_renta" class="block text-xs font-medium text-gray-700 leading-5"
                    >Rentabilité nette après fiscalité
                  </label>
                </div>
                <div class="flex flex-row items-center">
                  <input
                    v-model.number="params_renta"
                    type="range"
                    min="0"
                    max="15"
                    step="0.1"
                    class="slider-input"
                    id="params_renta"
                  />
                  <div class="ml-4">{{ params_renta }} %</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN CRITERE CARD -->

      <!-- PLUS DE PARAMETRE -->
      <div id="solvabilité" class="grid grid-cols-10">
        <div class="mr-8 col-span-7 col-start-3">
          <div
            class="relative flex flex-row justify-between px-4 py-3 bg-white border border-black rounded-lg cursor-pointer"
            @click="isToggle.param = !isToggle.param"
          >
            <h2 class="text-lg font-bold">Calcul rapide de la solvabilité de votre client</h2>
            <div>
              <span v-if="!isToggle.param" class="absolute cursor-pointer right-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="34"
                  height="34"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="inherit"
                    font-size="none"
                    text-anchor="inherit"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g id="Layer_1" fill="#444b54">
                      <path
                        d="M123.49063,63.02188l-37.49063,37.49063l-37.49062,-37.49062c-1.6125,-1.6125 -4.16562,-1.6125 -5.64375,0c-1.6125,1.6125 -1.6125,4.16562 0,5.64375l40.3125,40.3125c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82187,-1.20938l40.3125,-40.3125c1.6125,-1.6125 1.6125,-4.16563 0,-5.64375c-1.6125,-1.6125 -4.03125,-1.6125 -5.64375,0z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              <span v-else class="absolute cursor-pointer right-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="34"
                  height="34"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="inherit"
                    font-size="none"
                    text-anchor="inherit"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g id="Layer_1" fill="#444b54">
                      <path
                        d="M42.86563,108.97813c1.6125,1.6125 4.16562,1.6125 5.64375,0l37.49063,-37.49062l37.49063,37.49062c0.80625,0.80625 1.88125,1.20938 2.82187,1.20938c0.94062,0 2.01563,-0.40313 2.82188,-1.20938c1.6125,-1.6125 1.6125,-4.16562 0,-5.64375l-40.3125,-40.3125c-1.6125,-1.6125 -4.16563,-1.6125 -5.64375,0l-40.3125,40.3125c-1.6125,1.6125 -1.6125,4.03125 0,5.64375z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- FIN PLUS DE PARAMETRE -->

      <div v-if="isToggle.param" class="grid grid-cols-10">
        <div class="p-3 px-5 mb-10 mr-8 card-no-top col-span-10 col-start-3 pb-7">
          <div class="flex flex-row align-middle card-header">
            <img src="../assets/icons/faq10.jpg" class="w-20 h-20" alt="Capacité d’endettement" />
            <div class="flex flex-col my-auto ml-5">
              <p class="text-xl">Votre client est-il solvable ?</p>
              <p class="text-xl font-bold">sa capacité d’endettement.</p>
            </div>
          </div>
          <div class="mt-10 card-body">
            <div class="grid grid-cols-10">
              <div class="col-span-10 col-start-2 col-end-10">
                <div class="flex flex-row justify-between">
                  <div class="w-2/4 mx-5">
                    <p class="text-2xl"><b>Revenus annuels</b></p>
                    <div class="mt-3">
                      <label for="emprunt_salaire" class="block text-xs font-medium text-gray-700 leading-5"
                        >Salaires nets (ou retraite)</label
                      >
                      <input
                        v-model="emprunt_salaire"
                        id="emprunt_salaire"
                        type="text"
                        class="block w-full px-3 py-2 mt-4 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="emprunt_rev_loc" class="block text-xs font-medium text-gray-700 leading-5"
                        >Revenus locatifs (70% de leur valeur)</label
                      >
                      <input
                        v-model="emprunt_rev_loc"
                        id="emprunt_rev_loc"
                        type="text"
                        class="block w-full px-3 py-2 mt-4 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="emprunt_rev_autre" class="block text-xs font-medium text-gray-700 leading-5"
                        >Autres revenus (BIC, BNC, RCM, ect.)</label
                      >
                      <input
                        v-model="emprunt_rev_autre"
                        id="emprunt_rev_autre"
                        type="text"
                        class="block w-full px-3 py-2 mt-4 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                    </div>
                    <div class="flex flex-row justify-center mt-5">
                      <img src="@/assets/positif.png" class="h-8 mr-3" alt="Positif" />
                      <p class="my-auto font-bold">Revenus annuels totaux</p>
                    </div>
                    <div class="mt-5">
                      <p class="text-center">
                        <span
                          class="px-10 py-3 font-bold text-center text-white"
                          style="background-color: #0c1e3b; border-radius: 9px"
                          >{{ this.emprunt_rev_tot_calc }} €</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="w-2/4 mx-10">
                    <p class="text-2xl"><b>Dépenses annuelles</b></p>
                    <div class="mt-3">
                      <label for="emprunt_loyer" class="block text-xs font-medium text-gray-700 leading-5"
                        >Son loyer annuel s’il est locataire</label
                      >
                      <input
                        v-model="emprunt_loyer"
                        id="emprunt_loyer"
                        type="text"
                        class="block w-full px-3 py-2 mt-4 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="emprunt_annuite" class="block text-xs font-medium text-gray-700 leading-5"
                        >Annuités d’emprunts immobiliers (ass comprise)</label
                      >
                      <input
                        v-model="emprunt_annuite"
                        id="emprunt_annuite"
                        type="text"
                        class="block w-full px-3 py-2 mt-4 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="emprunt_autre" class="block text-xs font-medium text-gray-700 leading-5"
                        >Autres charges, pensions versées et crédits</label
                      >
                      <input
                        v-model="emprunt_autre"
                        id="emprunt_autre"
                        type="text"
                        class="block w-full px-3 py-2 mt-4 border border-gray-300 form-input rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-xs sm:leading-5"
                      />
                    </div>
                    <div class="flex flex-row justify-center mt-5">
                      <p class="my-auto font-bold">Dépenses annuelles totales</p>
                      <img src="@/assets/negatif.png" class="h-8 ml-3" alt="Négatif" />
                    </div>
                    <div class="mt-5">
                      <p class="text-center">
                        <span
                          class="px-10 py-3 font-bold text-center text-white"
                          style="background-color: #0c1e3b; border-radius: 9px"
                          >{{ this.emprunt_dep_tot_calc }} €</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-10">
              <div class="col-span-6 col-start-3">
                <div class="relative px-6 py-4 my-10 border-2 rounded-lg tips col-span-6 sm:col-span-4">
                  <img
                    class="absolute z-0 w-auto max-h-10 ampoule-picto"
                    src="../assets/picto/light.png"
                    alt=""
                  />
                  <div class="mt-4 grid grid-cols-6">
                    <p class="text-right col-span-4">
                      <b>Capacité d’emprunt théorique</b>
                    </p>
                    <QuestionMarkv2 class="ml-2" :text="`${infobulles.capaciteEmprunt}`" />
                    <p class="text-left col-start-6 col-span-1">
                      <b>{{ this.emprunt_info_emprunt_calc }}€</b>
                    </p>
                  </div>
                  <div class="mt-4 grid grid-cols-6">
                    <p class="text-right col-span-4">Mensualité d’emprunt (hors intérêts)</p>
                    <p class="text-left col-start-6 col-span-1">
                      {{ Number.isInteger(this.emprunt_info_mens_calc) ? this.emprunt_info_mens_calc : 0 }}€
                    </p>
                  </div>
                  <div class="mt-4 grid grid-cols-6">
                    <p class="text-right col-span-4">Taux d’endettement théorique avant financement</p>
                    <QuestionMarkv2 class="ml-2" :text="`${infobulles.tauxEndettement}`" />
                    <p class="text-left col-start-6 col-span-1">
                      {{
                        typeof this.emprunt_info_endet_calc === 'number' ? this.emprunt_info_endet_calc : 0
                      }}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <div class="flex items-center justify-center px-4 py-3 text-right sm:px-6">
          <span class="inline-flex rounded-md shadow-sm">
            <button
              v-if="this.investSelected == null"
              type="submit"
              class="inline-flex items-center justify-center py-4 text-base font-medium font-bold text-white whitespace-no-wrap border border-transparent px-15 leading-6 rounded-md bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
            >
              Enregistrer et créer le profil
            </button>
            <button
              v-else
              :disabled="modalPDFLoader"
              type="submit"
              :class="{
                'bg-lightblueaestima-100': modalPDFLoader,
                'bg-grey-400': modalPDFLoader,
              }"
              class="inline-flex items-center justify-center py-4 text-base font-medium font-bold text-white whitespace-no-wrap border border-transparent px-15 leading-6 rounded-md bg-lightblueaestima-100 hover:bg-darkblueaestima-500 focus:outline-none focus:border-darkblueaestima-500 focus:shadow-outline-indigo active:bg-darkblueaestima-500 transition ease-in-out duration-150"
            >
              Enregistrer la modification
            </button>
          </span>
        </div>
      </div>
    </form>
    <div v-if="modalValidation" class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div
          class="inline-block overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10"
              >
                <svg
                  class="w-6 h-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg font-medium text-gray-900 leading-6" id="modal-headline">Attention !</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Êtes-vous sûr de vouloir enregistrer ces modifications ? Toutes simulations déjà réalisées
                    pour cet investisseur, même dupliquées, ne tiendront pas compte des nouveaux paramètres du
                    module « situation fiscale ». Il vous faudra créer de nouvelles simulations pour appliquer
                    les changements
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              @click="saveInvestor()"
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-blue-600 border border-transparent hover:bg-blue-700 focus:ring-blue-500 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Enregistrer
            </button>
            <button
              @click="modalValidation = false"
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SignIn from '@/components/SignIn.vue'
import QuestionMarkv2 from '@/components/SVG/QuestionMarkv2.vue'
import LeftSimulatorHome from '@/components/LeftSimulatorHome'
import Fiscalite from '@/components/popup/Fiscalite'
import { mapActions, mapGetters } from 'vuex'
import SidebarInvestor from '@/components/SidebarInvestor'

export default {
  name: 'Investor',
  data() {
    return {
      modalValidation: false,
      file: '',
      coFile:'',
      isfileError: false,
      fileMessage: '',
      message: '',
      auto: false,
      isSCI: false,
      isNue: true,
      warning: {
        isTI: false,
        isRA: false,
        isTA: false,
        message: 'La valeur saisie est anormalement élevée !',
      },
      name: '',
      prenom: '',
      //new v1.1
      gender: 'homme',
      phone: '',
      coInvestor: false,
      coGender : 'homme',
      coName   : '',
      nameSociete: '',
      forme : '',
      coPrenom : '',
      coPhoto  : '',
      coPhone  : '',
      coEmail  : '',
      family: '',
      pays: '',
      adress: '',
      strategy: 'patrimoine',
      strategyInfo: {
        sensibility: 'SECURISÉ',
        horizon: 'Long terme',
        critere: 'Valorisation du bien à long terme',
      },
      stratInfo: '',
      tranche_tmi: 0,
      tmi_fix: true,
      residence_fiscale: 'france',
      fiscalite_modal: false,
      fiscalite_evolution_salaire: 0,
      type_fiscalite_display_gauche: {
        titre: 'Fiscalité variable',
        soustitre: 'en fonction des revenus du client',
      },
      type_fiscalite_display_droite: {
        titre: 'Fiscalité identique',
        soustitre: 'sur toute la durée du projet',
      },
      impotsIndicatifs: [],
      fiscalite: {
        0: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        1: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        2: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        3: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        4: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        5: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        6: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        7: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        8: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        9: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        10: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        11: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        12: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        13: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        14: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        15: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        16: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        17: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        18: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        19: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        20: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        21: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        22: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        23: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        24: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        25: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        26: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        27: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        28: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        29: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
        30: {
          situation: 'celibataire',
          enfants: 0,
          salaire: 0,
          frais: 0,
          fonciers: 0,
          bic: 0,
          autrebic: 0,
          rcm: 0,
          deduction: 0,
        },
      },
      fiscalite_evolutive: {
        tmi: 0,
        revenu: 0,
      },

      mail: '',
      dateacqui: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,

      emprunt_salaire: 0,
      emprunt_rev_loc: 0,
      emprunt_rev_autre: 0,
      emprunt_rev_tot: 0,
      emprunt_loyer: 0,
      emprunt_annuite: 0,
      emprunt_autre: 0,
      emprunt_dep_tot: 0,
      emprunt_info_reste: 0,
      emprunt_info_emprunt: 0,
      emprunt_info_endet: 0,
      emprunt_info_mens: 0,

      isToggle: {
        param: false,
        advanced: false,
      },

      search_bien: '',
      search_type: '',
      search_budget: 0,
      search_pref: '',
      search_no_pref: false,
      search_surface: '',
      search_fourch: '',
      search_loue: '',
      search_autre: '',
      search_piece: '',

      params_flux_avt: 0,
      params_flux_apr: 0,
      params_profit_tot: 100000,
      params_renta: 3,
      params_taux: {
        val: 1,
        isAuto: true,
      },

      advanced_perso_date: '',
      advanced_perso_city: '',
      advanced_perso_address: '',
      advanced_perso_address_since: '',
      advanced_perso_pic: '',
      advanced_perso_credit: '',
      advanced_perso_child: '',

      advanced_pro_poste: '',
      advanced_pro_contract: '',
      advanced_pro_enterprise: '',
      advanced_pro_contract_start: '',
      advanced_pro_contract_end: '',
      advanced_pro_contract_autre: '',

      advanced_pat_compte: '',
      advanced_pat_livret: '',
      advanced_pat_av: '',
      advanced_pat_scpi: '',
      advanced_pat_autre: '',
      advanced_pat_tot: '',

      advanced_pat_immmo: [
        {
          advanced_pat_marche: 0,
          advanced_pat_type: '',
          advanced_pat_loyer: 0,
          advanced_pat_charge: 0,
          advanced_pat_annuite: 0,
          advanced_pat_duree: 0,
        },
      ],

      advanced_pat_bien_array: [],

      advanced_pat_strong_point: '',
      infobulles: {
        tauxEndettement:
          'Ratio qui détermine le poids des charges courantes sur les revenus courants de votre client. C’est un indicateur important pour la banque qui se basera dessus pour définir le montant maximum qu’elle lui prêtera. En France, ce taux ne doit pas dépasser 35% pour espérer être financé.',
        capaciteEmprunt:
          'Cet indicateur vous permet de voir rapidement la capacité d’emprunt de votre client. Doublez de vigilance en vous assurant auprès de sa banque qu’il est bien solvable. Tout n’est pas question de chiffres. Pour ce calcul, nous nous sommes basés sur un prêt de 20 ans.',
        documentContractuel:
          'Téléchargez ici le document contractuel qui vous rattache à votre client. Cela peut être un mandat de recherche, une lettre de mission, un contrat de réservation. Bref, vous pouvez le centraliser directement sur votre interface et le consulter quand vous voulez.',
        traitementSalaires:
          'Saisissez le montant imposable sans prendre en compte la déduction de 10% des frais professionnels. Cette déduction sera calculée directement. Pour toutes modifications de ces revenus pour les années à venir, vous pourrez cliquer sur le bouton bleu « accéder au tableau de répartition ».',
        revenusBICBNC:
          "Bénéfices Industriels et Commerciaux (BIC) et Bénéfices Non Commerciaux (BNC). N'insérez ici que les revenus issus du travail et non des locations meublées.",
        revenusFonciers:
          'Les revenus locatifs issus de location nue sont à intégrer dans cette partie. Intégrez ici les revenus fonciers nets déductions faites des frais réels ou des forfaits. Si le solde est négatif, c’est-à-dire, que votre client a un déficit foncier, intégrez-le également. Nous en tiendrons compte dans les simulations.',
        revenusRCM:
          'Revenus de Capitaux Mobiliers (RCM). Ils correspondent entre autres, aux dividendes perçues (non soumis à la flat tax) et revenus de placements financiers.',
        revenusBIClocatifs:
          'Les revenus locatifs issus de location meublée sont à intégrer dans cette partie en BIC.',
        abattementsDéductions:
          'Plusieurs conditions peuvent déclencher des abattements ou déductions sur le revenu net imposable (RNI) de l’investisseur comme par exemple le fait d’être âgé de plus de 65 ans, de verser des pensions, d’être infirme. Inscrivez ici l’ensemble de ces abattements et déductions. Pour toutes modifications pour les années à venir, vous pourrez cliquer sur le bouton bleu « accéder au tableau de répartition ».',
        evolutionAnnuelleSalaires:
          'Pour éviter de saisir manuellement, année après année, les salaires de votre client en y rajoutant quelques centaines d’euros, vous pouvez inscrire un taux de croissance régulier de ces revenus. Pour toutes modifications de ces revenus pour les années à venir, vous pourrez cliquer sur le bouton bleu « accéder au tableau de répartition ».',
      },
    }
  },
  metaInfo() {
    return {
      title:
        this.investSelected == null
          ? `Nouvel investisseur - Aestima`
          : `Modification de l'investisseur ${this.investSelected.name} - Aestima`,
      meta: [
        {
          name: 'description',
          content: "Cette page permet d'ajouter un investisseur à un projet.",
        },
      ],
      //keywords
    }
  },
  components: {
    SignIn,
    LeftSimulatorHome,
    QuestionMarkv2,
    Fiscalite,
    SidebarInvestor,
  },
  created() {
    if (this.investSelected != null) {
        this.coInvestor = this.investSelected.coInvestor
      this.coGender = this.investSelected.coGender
      this.forme = this.investSelected.forme
      this.nameSociete = this.investSelected.nameSociete
      this.coName = this.investSelected.coName
      this.coPhone = this.investSelected.coPhone
      this.coEmail = this.investSelected.coEmail
      this.coPrenom = this.investSelected.coPrenom
      this.residence_fiscale = this.investSelected.residence_fiscale
      this.name = this.investSelected.name
      this.tmi_fix = this.investSelected.tmi_fix
      this.fiscalite = this.investSelected.fiscalite == null ? '0' : this.investSelected.fiscalite
      this.adress = this.investSelected.adress
      this.pays = this.investSelected.pays
      this.prenom = this.investSelected.firstname
      this.gender = this.investSelected.gender
      this.phone = this.investSelected.phone
      this.family = this.investSelected.family
      this.strategy = this.investSelected.strategy.typestrategy
      this.fiscalite_evolution_salaire = this.investSelected.fiscalite_evolution_salaire,
      this.strategy_calc = this.strategy
      this.mail = this.investSelected.mail
      this.dateacqui =
        this.investSelected.strategy.dateacqui.split('-')[1].length === 1
          ? `${this.investSelected.strategy.dateacqui.split('-')[0]}-0${
              this.investSelected.strategy.dateacqui.split('-')[1]
            }`
          : this.investSelected.strategy.dateacqui
      this.tranche_tmi = this.investSelected.tmi
      this.stratInfo = this.investSelected.stratInfo

      this.emprunt_salaire = this.investSelected.emprunt.emprunt_salaire
      this.emprunt_rev_loc = this.investSelected.emprunt.emprunt_rev_loc
      this.emprunt_rev_autre = this.investSelected.emprunt.emprunt_rev_autre
      this.emprunt_rev_tot = this.investSelected.emprunt.emprunt_rev_tot
      this.emprunt_loyer = this.investSelected.emprunt.emprunt_loyer
      this.emprunt_annuite = this.investSelected.emprunt.emprunt_annuite
      this.emprunt_autre = this.investSelected.emprunt.emprunt_autre
      this.emprunt_dep_tot = this.investSelected.emprunt.emprunt_dep_tot
      this.emprunt_info_reste = this.investSelected.emprunt.emprunt_info_reste
      this.emprunt_info_emprunt = this.investSelected.emprunt.emprunt_info_emprunt
      this.emprunt_info_endet = this.investSelected.emprunt.emprunt_info_endet
      this.emprunt_info_mens = this.investSelected.emprunt.emprunt_info_mens

      this.search_bien = this.investSelected.search.search_bien
      this.search_piece = this.investSelected.search.search_piece
      this.search_type = this.investSelected.search.search_type
      this.search_budget = this.investSelected.search.search_budget
      this.search_pref = this.investSelected.search.search_pref
      this.search_no_pref = this.investSelected.search.search_no_pref
      this.search_surface = this.investSelected.search.search_surface
      this.search_fourch = this.investSelected.search.search_fourch
      this.search_loue = this.investSelected.search.search_loue
      this.search_autre = this.investSelected.search.search_autre

      this.params_flux_avt = this.investSelected.params.params_flux_avt
      this.params_flux_apr = this.investSelected.params.params_flux_apr
      this.params_profit_tot = this.investSelected.params.params_profit_tot
      this.params_renta = this.investSelected.params.params_renta
      this.params_taux = this.investSelected.params.params_taux

      this.advanced_perso_date = this.investSelected.advanced_info.personals.advanced_perso_date
      this.advanced_perso_city = this.investSelected.advanced_info.personals.advanced_perso_city
      this.advanced_perso_address = this.investSelected.advanced_info.personals.advanced_perso_address
      this.advanced_perso_address_since =
        this.investSelected.advanced_info.personals.advanced_perso_address_since
      this.advanced_perso_pic = this.investSelected.advanced_info.personals.advanced_perso_pic
      this.advanced_perso_credit = this.investSelected.advanced_info.personals.advanced_perso_credit
      this.advanced_perso_child = this.investSelected.advanced_info.personals.advanced_perso_child

      this.advanced_pro_poste = this.investSelected.advanced_info.pro.advanced_pro_poste
      this.advanced_pro_contract = this.investSelected.advanced_info.pro.advanced_pro_contract
      this.advanced_pro_enterprise = this.investSelected.advanced_info.pro.advanced_pro_enterprise
      this.advanced_pro_contract_start = this.investSelected.advanced_info.pro.advanced_pro_contract_start
      this.advanced_pro_contract_end = this.investSelected.advanced_info.pro.advanced_pro_contract_end
      this.advanced_pro_contract_autre = this.investSelected.advanced_info.pro.advanced_pro_contract_autre

      this.advanced_pat_compte = this.investSelected.advanced_info.patrimoine_financier.advanced_pat_compte
      this.advanced_pat_livret = this.investSelected.advanced_info.patrimoine_financier.advanced_pat_livret
      this.advanced_pat_av = this.investSelected.advanced_info.patrimoine_financier.advanced_pat_av
      this.advanced_pat_scpi = this.investSelected.advanced_info.patrimoine_financier.advanced_pat_scpi
      this.advanced_pat_autre = this.investSelected.advanced_info.patrimoine_financier.advanced_pat_autre
      this.advanced_pat_tot = this.investSelected.advanced_info.patrimoine_financier.advanced_pat_tot

      this.advanced_pat_immmo = this.investSelected.advanced_info.patrimoine_immobilier

      this.advanced_pat_strong_point = this.investSelected.advanced_info.strong_point
    }
    this.tranche_tmi = this.getTMI() * 100
  },
  computed: {
    ...mapGetters({
      selected: 'projects/selected',
      investSelected: 'invests/selected',
      globals: 'projects/globals',
      user: 'users/user',
      modalPDFLoader: 'invests/modalPDFLoader',
      tmi: 'projects/tmi',
    }),
    returnGetter: {
      get() {
        return this.selected
      },
    },
    calculPartsFiscalesTot() {
      let tempPartsFiscalesTot = this.partsFiscalesAvecEnfants(
        this.fiscalite[0].situation,
        this.fiscalite[0].enfants
      )
      this.partsFiscalesTot = tempPartsFiscalesTot
      return tempPartsFiscalesTot
    },
    strategy_calc: {
      get: function () {
        return this.strategy
      },
      set: function (val) {
        this.strategy = val
        if (val === 'retraite') {
          this.params_flux_avt = -400
          this.params_flux_apr = 200
          this.params_renta = 3
          this.strategyInfo = {
            sensibility: 'SECURISÉ',
            horizon: 'Long terme',
            critere: 'Valorisation du bien à long terme',
          }
        } else if (val === 'patrimoine') {
          this.params_flux_avt = -50
          this.params_flux_apr = 300
          this.params_renta = 4.5
          this.strategyInfo = {
            sensibility: 'ÉQUILIBRÉ',
            horizon: 'Moyen terme',
            critere: "Effort d'épargne minimum",
          }
        } else if (val === 'revenus') {
          this.params_flux_avt = 20
          this.params_flux_apr = 500
          this.params_renta = 6
          this.strategyInfo = {
            sensibility: 'RISQUÉ',
            horizon: 'Moyen terme',
            critere: 'Cash-flows mensuels positifs',
          }
        } else if (val === 'defiscaliser') {
          this.params_flux_avt = -400
          this.params_flux_apr = 0
          this.params_renta = 10
          this.strategyInfo = {
            sensibility: 'ÉQUILIBRÉ',
            horizon: 'Moyen / Long terme',
            critere: 'Equilibre entre rendement et économie fiscale',
          }
        }
      },
    },
    emprunt_rev_tot_calc: {
      get: function () {
        this.emprunt_rev_tot =
          Number(this.emprunt_salaire) + Number(this.emprunt_rev_loc) + Number(this.emprunt_rev_autre)
        return this.emprunt_rev_tot
      },
      set: function (val) {},
    },
    // emprunt_salaire_calc: {
    // 	get: function () {

    // 		return this.emprunt_salaire
    // 	},
    // 	set: function (val) {
    // 		this.emprunt_salaire = val * 12
    // 	},
    // },
    emprunt_dep_tot_calc: {
      get: function () {
        this.emprunt_dep_tot =
          Number(this.emprunt_loyer) + Number(this.emprunt_annuite) + Number(this.emprunt_autre)
        return this.emprunt_dep_tot
      },
      set: function (val) {},
    },
    tmiComputed() {
      return this.calculateTMI()
    },
    RevenuNetAvtComputed() {
      return this.revenuFiscal(this.fiscalite, 0)
    },
    // emprunt_annuite_calc: {
    // 	get: function () {
    // 		this.emprunt_annuite = Number(this.emprunt_annuite) * 12
    // 		return this.emprunt_annuite
    // 	},
    // 	set: function (val) {
    // 	},
    // },
    emprunt_info_reste_calc: {
      get: function () {
        this.emprunt_info_reste = Math.round(
          (Number(this.emprunt_rev_tot) - Number(this.emprunt_dep_tot)) / 12
        )
        return this.emprunt_info_reste
      },
      set: function (val) {},
    },
    emprunt_info_emprunt_calc: {
      get: function () {
        this.emprunt_info_emprunt =
          Number(this.emprunt_info_endet) < 35 ? Number(this.emprunt_info_mens) * 240 : 0

        return this.emprunt_info_emprunt
      },
      set: function (val) {},
    },
    emprunt_info_endet_calc: {
      get: function () {
        this.emprunt_info_endet = Number(
          ((Number(this.emprunt_dep_tot) / Number(this.emprunt_rev_tot)) * 100).toFixed(1)
        )
        return this.emprunt_info_endet
      },
      set: function (val) {},
    },
    emprunt_info_mens_calc: {
      get: function () {
        this.emprunt_info_mens =
          Number(this.emprunt_info_endet) < 35
            ? Math.round((Number(this.emprunt_rev_tot) * (0.35 - Number(this.emprunt_info_endet) / 100)) / 12)
            : 'Au vu de votre reste à vivre, il vous sera difficile de débloquer un crédit bancaire. Rapprochez-vous de votre conseiller bancaire pour connaître en détail les conditions d’emprunt.'

        return this.emprunt_info_mens
      },
      set: function (val) {},
    },
    advanced_pat_tot_calc: {
      get: function () {
        this.advanced_pat_tot =
          Number(this.advanced_pat_compte) +
          Number(this.advanced_pat_livret) +
          Number(this.advanced_pat_av) +
          Number(this.advanced_pat_scpi) +
          Number(this.advanced_pat_autre)
        return this.advanced_pat_tot
      },
      set: function (val) {},
    },
  },
  methods: {
    getTMI() {
      let baremeEnfant = 3184
      let impotCalcul1 = this.impotRefacto(this.partsFiscalesSansEnfant(this.fiscalite[0].situation))
      let impotCalcul2 = this.impotRefacto(this.calculPartsFiscalesTot)
      if (impotCalcul1.impot - impotCalcul2.impot <= baremeEnfant * this.partsFiscalesDesEnfants(this.fiscalite[0].enfants)) {
	      return impotCalcul2.tmi
      } else {
	      return impotCalcul1.tmi
      }
    },
    impotRefacto(parts) {
      let impot = 0
      let taux = 0
      let qf = this.RNI() / parts
      let resteImposable = qf
      let tmiAdmin = []
      let tranches = Object.keys(this.tmi)
      tranches.map((t, i) => tmiAdmin.push([Number(tranches[i]) / 100, this.tmi[t].start]))
      // tmiAdmin => [[0, 0], [11, 10226], [30, 26071], [41, 74546], [45, 160337]]
      for (let i = 0; i < tmiAdmin.length; i++) {
        taux = tmiAdmin[i][0]
        let seuilPrev = tmiAdmin[i][1]
        let seuil

        if (tmiAdmin[i + 1]) seuil = tmiAdmin[i + 1][1]
        else seuil = 999999999

        if (resteImposable < seuil - seuilPrev) {
          impot += resteImposable * taux
          break
        } else {
          impot += (seuil - seuilPrev) * taux
          resteImposable -= seuil - seuilPrev
        }
      }
      impot *= parts
      return { impot: Math.round(impot), tmi: taux, qf: Math.round(qf) }
    },
    RNI() {
      return (
        this.fiscalite[0].salaire -
        this.fiscalite[0].frais +
        this.fiscalite[0].fonciers +
        this.fiscalite[0].bic +
        this.fiscalite[0].autrebic +
        this.fiscalite[0].rcm -
        this.fiscalite[0].deduction
      )
    },
    ...mapActions({
      setInvest: 'invests/setInvest',
      setResults: 'invests/setResults',
      setModalPDFLoader: 'invests/setModalPDFLoader',
      updateInvest: 'invests/updateInvest',
      clearInvest: 'invests/setInvestSelected',
    }),
    showFiscaliteModal() {
      // if (!this.fiscalite[1].partssansenfants)
      // 	console.log('bonjourr')
      // 	for (let i = 1; i < 30; i++) {
      // 		this.fiscalite[i].partssansenfants = this.partsFiscaleSansEnfant(
      // 			this.fiscalite['0'].situation
      // 		);
      // 		this.fiscalite[i].enfants = this.partsFiscaleAvecEnfant(
      // 			this.fiscalite['0'].situation,
      // 			this.fiscalite['0'].enfants
      // 		);
      // 		this.fiscalite[i].salaire = this.fiscalite['0'].salaire;
      // 		this.fiscalite[i].frais = this.fiscalite['0'].frais;
      // 		this.fiscalite[i].fonciers = this.fiscalite['0'].fonciers;
      // 		this.fiscalite[i].bic = this.fiscalite['0'].bic;
      // 		this.fiscalite[i].autrebic = this.fiscalite['0'].autrebic;
      // 		this.fiscalite[i].rcm = this.fiscalite['0'].rcm;
      // 		this.fiscalite[i].deduction = this.fiscalite['0'].deduction;
      // 	}
      this.fiscalite_modal = true
    },
    onChangeEnfants(enfants) {
      if (this.fiscalite[0].enfants == '') this.fiscalite[0].enfants = 0
      this.propagate('enfants')
    },
    checkAndPropagate(evt) {
      let keyWord = ''
      switch (evt.target.id) {
        case 'fiscalite_bic_bnc':
          if (this.fiscalite[0].autrebic == '') this.fiscalite[0].autrebic = 0
          keyWord = 'autrebic'
          break
        case 'fiscalite_rcm':
          if (this.fiscalite[0].rcm == '') this.fiscalite[0].rcm = 0
          keyWord = 'rcm'
          break
        case 'fiscalite_fonciers':
          if (this.fiscalite[0].fonciers == '') this.fiscalite[0].fonciers = 0
          keyWord = 'fonciers'
          break
        case 'fiscalite_bic':
          if (this.fiscalite[0].bic == '') this.fiscalite[0].bic = 0
          keyWord = 'bic'
          break
        case 'fiscalite_abattement':
          if (this.fiscalite[0].deduction == '') this.fiscalite[0].deduction = 0
          keyWord = 'deduction'
          break
      }
      this.tranche_tmi = this.getTMI() * 100
      this.propagate(keyWord)
    },
    propagate(row) {
      for (let i = 1; i < 30; i++) {
        this.fiscalite[i][row] = this.fiscalite[i - 1][row]
      }
    },
    partsFiscalesSansEnfant(situation) {
      if (situation === 'celibataire' || situation === 'divorce' || situation === 'separe') return 1
      if (situation === 'marie' || situation === 'veuf' || situation === 'pacse') return 2
      return 0
    },
    partsFiscalesDesEnfants(nbr) {
      let parts = 0
      if (nbr == 1) parts = 0.5
      else if (nbr > 1) parts = nbr - 1
      return parts
    },
    partsFiscalesAvecEnfants(situation, nbr) {
      return this.partsFiscalesDesEnfants(nbr) + this.partsFiscalesSansEnfant(situation)
    },
    quotientFamilial(fiscalite, i) {
      let revenue = this.revenuFiscal(fiscalite, i)
      return Math.round(revenue / this.partsFiscalesAvecEnfants(fiscalite[i].situation, fiscalite[i].enfants))
    },
    revenuFiscal(fiscalite, i) {
      return (
        +(fiscalite[i].salaire - fiscalite[i].frais) +
        +fiscalite[i].fonciers +
        +fiscalite[i].bic +
        +fiscalite[i].autrebic +
        +fiscalite[i].rcm -
        +fiscalite[i].deduction
      )
    },
    calculateSalaryEvolution() {
      this.tranche_tmi = this.getTMI() * 100
      if (this.fiscalite == 0) {
        if (this.fiscalite[0].salaire === '') this.fiscalite[0].salaire = 0
      }
      if (this.fiscalite_evolution_salaire === '') this.fiscalite_evolution_salaire = 0
      let evolution = 1 + this.fiscalite_evolution_salaire / 100
      let plafondFraisPro = 12829
      for (let i = 1; i < 30; i++) {
        this.fiscalite[i].salaire = Math.round(this.fiscalite[i - 1].salaire * evolution)
        // partie frais deductibles
        this.fiscalite[i].frais = Math.min(Math.round(this.fiscalite[i].salaire / 10), plafondFraisPro)
      }
      this.fiscalite[0].frais = Math.min(Math.round(this.fiscalite[0].salaire / 10), plafondFraisPro)
    },
    calculateTMI() {
      // copiée collée de Fiscalite.vue
      const tmis = Object.keys(this.tmi).filter((t) =>
        this.tmi[t].start === undefined
          ? 0
          : (this.tmi[t].start < this.quotientFamilial(this.fiscalite, 0) && this.tmi[t].end === null) ||
            this.tmi[t].end > this.quotientFamilial(this.fiscalite, 0)
      )
      this.tranche_tmi = tmis[0]
      this.fiscalite_evolutive.tmi = tmis[0] + '%'
      return tmis[0] + '%'
    },
    closeFiscaliteModal(saved, fisca, tmi, revenu, impotsIndicatifs  ) {
      this.fiscalite = fisca
      this.impotsIndicatifs = impotsIndicatifs
      console.log('impotsIndicatifs', impotsIndicatifs)
      if (saved) {
        this.fiscalite_evolutive.tmi = tmi
        this.tranche_tmi = tmi * 100
        this.fiscalite_evolutive.revenu = revenu
      }
      this.fiscalite_modal = false
    },
    addField(value, fieldType) {
      fieldType.push({
        advanced_pat_annuite: 0,
        advanced_pat_charge: 0,
        advanced_pat_duree: 0,
        advanced_pat_loyer: 0,
        advanced_pat_marche: 0,
        advanced_pat_type: '',
      })
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1)
    },
    toYear(val) {
      return val / 12
    },
    onChangeSigle(value, boolean) {
      this[value][value + 'Euro'] = boolean
    },
    onCoSelect(e) {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']
      const coFile = e.target.files[0]

      //console.log(coFile.size)
      const maxSize = 5*1024*1024
      if (!allowedTypes.includes(coFile.type) && coFile.size > maxSize) {
        this.coFile = ''
        this.fileMessage = ''
        this.isfileError = true
        this.fileMessage = "Le type du fichier n'est pas bon ! La taille de l'image est supérieure à 5MB !"
	alert('Le type du fichier n\'est pas bon ! La taille de l\'image est supérieure à 5MB !');
      } else if (!allowedTypes.includes(coFile.type)) {
        this.coFile = ''
        this.fileMessage = ''
        this.isfileError = true
        this.fileMessage = "Le type du fichier n'est pas bon !"
      } else if (coFile.size > maxSize) {
        this.coFile = ''
        this.fileMessage = ''
        this.isfileError = true
        this.fileMessage = "La taille de l'image est supérieure à 5MB !"
	alert("Attention ! L'image n'a pas pu être chargée car elle dépasse 5 Mo. Sélectionnez-en une autre.");
      } else {
        // this.file = ""
        this.coFile = coFile
        this.fileMessage = ''
        this.isfileError = false
      }
      this.coFile = coFile
    },
    onSelect(e) {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png']
      const file = e.target.files[0]
      console.log('file', file)

      //console.log(file.size)
      const maxSize = 5*1024*1024
      if (!allowedTypes.includes(file.type) && file.size > maxSize) {
        this.file = ''
        this.fileMessage = ''
        this.isfileError = true
        this.fileMessage = "Le type du fichier n'est pas bon ! La taille de l'image est supérieure à 5MB !"
	alert('Le type du fichier n\'est pas bon ! La taille de l\'image est supérieure à 5MB !');
      } else if (!allowedTypes.includes(file.type)) {
        this.file = ''
        this.fileMessage = ''
        this.isfileError = true
        this.fileMessage = "Le type du fichier n'est pas bon !"
      } else if (file.size > maxSize) {
        this.file = ''
        this.fileMessage = ''
        this.isfileError = true
        this.fileMessage = "La taille de l'image est supérieure à 5MB !"
	alert("Attention ! L'image n'a pas pu être chargée car elle dépasse 5 Mo. Sélectionnez-en une autre.");
      } else {
        // this.file = ""
        this.file = file
        this.fileMessage = ''
        this.isfileError = false
      }
      this.file = file
    },
    openModalValidation() {
      if (this.investSelected == null) {
        this.saveInvestor()
      } else {
        this.modalValidation = true
      }
    },
    async saveInvestor() {
      if (this.name == '' || this.firstName == '') {
        alert("Veuillez entrer le nom et le prenom de l'investisseur.")
        let element = this.$refs['qui']
        let top = element.offsetTop
        window.scrollTo(0, top)
      } else {
        let formData
        let coFormData
        if (this.coFile) {
          coFormData = new FormData()
          coFormData.append('image', this.coFile)
        }
        if (this.file) {
          formData = new FormData()
          formData.append('image', this.file)
        }
        this.setModalPDFLoader(true)
        if (this.investSelected == null) {
          this.setInvest({
            idUser: this.user._id,
            formData: formData,
            coFormData: coFormData,
            body: {
              name: this.name,
              firstname: this.prenom,
              mail: this.mail,
              gender: this.gender,
              phone: this.phone,
              pays: this.pays,
              coGender: this.coGender,
              forme: this.forme,
              nameSociete: this.nameSociete,
              coName: this.coName,
              coPrenom: this.coPrenom,
              coEmail: this.coEmail,
              coPhone: this.coPhone,
              coInvestor: this.coInvestor,
              adress: this.adress,
              family: this.family,
              tmi: this.tranche_tmi,
              stratInfo: this.stratInfo,
              tmi_fix: this.tmi_fix,
              fiscalite: this.tmi_fix ? null : this.fiscalite,
              impotsIndicatifs: this.impotsIndicatifs,
	      fiscalite_evolution_salaire: this.fiscalite_evolution_salaire,
              residence_fiscale: this.residence_fiscale,
              date_acqui: this.dateacqui,
              type_strategy: this.strategy,
              strat_info: this.strat_info,
              strategy: {
                dateacqui: this.dateacqui,
                typestrategy: this.strategy,
              },
              emprunt: {
                emprunt_salaire: this.emprunt_salaire,
                emprunt_rev_loc: this.emprunt_rev_loc,
                emprunt_rev_autre: this.emprunt_rev_autre,
                emprunt_rev_tot: this.emprunt_rev_tot,
                emprunt_loyer: this.emprunt_loyer,
                emprunt_annuite: this.emprunt_annuite,
                emprunt_autre: this.emprunt_autre,
                emprunt_dep_tot: this.emprunt_dep_tot,
                emprunt_info_reste: this.emprunt_info_reste,
                emprunt_info_emprunt: this.emprunt_info_emprunt,
                emprunt_info_endet: this.emprunt_info_endet,
                emprunt_info_mens: this.emprunt_info_mens,
              },
              search: {
                search_bien: this.search_bien,
                search_type: this.search_type,
                search_budget: this.search_budget,
                search_pref: this.search_pref,
                search_no_pref: this.search_no_pref,
                search_surface: this.search_surface,
                search_fourch: this.search_fourch,
                search_loue: this.search_loue,
                search_autre: this.search_autre,
                search_piece: this.search_piece,
              },
              params: {
                params_flux_avt: this.params_flux_avt,
                params_flux_apr: this.params_flux_apr,
                params_profit_tot: this.params_profit_tot,
                params_renta: this.params_renta,
                params_taux: this.params_taux,
              },
              advanced_info: {
                personals: {
                  partsFiscalesTot: this.partsFiscalesTot,
                  advanced_perso_date: this.advanced_perso_date,
                  advanced_perso_city: this.advanced_perso_city,
                  advanced_perso_address: this.advanced_perso_address,
                  advanced_perso_address_since: this.advanced_perso_address_since,
                  advanced_perso_pic: this.advanced_perso_pic,
                  advanced_perso_credit: this.advanced_perso_credit,
                  advanced_perso_child: this.advanced_perso_child,
                },
                pro: {
                  advanced_pro_poste: this.advanced_pro_poste,
                  advanced_pro_contract: this.advanced_pro_contract,
                  advanced_pro_enterprise: this.advanced_pro_enterprise,
                  advanced_pro_contract_start: this.advanced_pro_contract_start,
                  advanced_pro_contract_end: this.advanced_pro_contract_end,
                  advanced_pro_contract_autre: this.advanced_pro_contract_autre,
                },
                patrimoine_financier: {
                  advanced_pat_compte: this.advanced_pat_compte,
                  advanced_pat_livret: this.advanced_pat_livret,
                  advanced_pat_av: this.advanced_pat_av,
                  advanced_pat_scpi: this.advanced_pat_scpi,
                  advanced_pat_autre: this.advanced_pat_autre,
                  advanced_pat_tot: this.advanced_pat_tot,
                },
                patrimoine_immobilier: this.advanced_pat_immmo,
                strong_point: this.advanced_pat_strong_point,
              },
            },
          }).then(() => {
            this.setModalPDFLoader(false)
          })
        } else {
          this.updateInvest({
            id: this.investSelected._id,
            formData: formData,
            coFormData: coFormData,
            push: true,
            body: {
              name: this.name,
              firstname: this.prenom,
              mail: this.mail,
              gender: this.gender,
              phone: this.phone,
              pays: this.pays,
              coGender: this.coGender,
              forme: this.forme,
              nameSociete: this.nameSociete,
              coName: this.coName,
              coPrenom: this.coPrenom,
              coEmail: this.coEmail,
              coPhone: this.coPhone,
              coInvestor: this.coInvestor,
              adress: this.adress,
              family: this.family,
              tmi: this.tranche_tmi,
              stratInfo: this.stratInfo,
              fiscalite: !this.tmi_fix ? this.fiscalite : null,
              impotsIndicatifs: this.impotsIndicatifs,
              tmi_fix: this.tmi_fix,
	      fiscalite_evolution_salaire: this.fiscalite_evolution_salaire,
              residence_fiscale: this.residence_fiscale,
              date_acqui: this.dateacqui,
              type_strategy: this.strategy,
              strat_info: this.strat_info,
              strategy: {
                dateacqui: this.dateacqui,
                typestrategy: this.strategy,
              },
              emprunt: {
                emprunt_salaire: this.emprunt_salaire,
                emprunt_rev_loc: this.emprunt_rev_loc,
                emprunt_rev_autre: this.emprunt_rev_autre,
                emprunt_rev_tot: this.emprunt_rev_tot,
                emprunt_loyer: this.emprunt_loyer,
                emprunt_annuite: this.emprunt_annuite,
                emprunt_autre: this.emprunt_autre,
                emprunt_dep_tot: this.emprunt_dep_tot,
                emprunt_info_reste: this.emprunt_info_reste,
                emprunt_info_emprunt: this.emprunt_info_emprunt,
                emprunt_info_endet: this.emprunt_info_endet,
                emprunt_info_mens: this.emprunt_info_mens,
              },
              search: {
                search_bien: this.search_bien,
                search_type: this.search_type,
                search_budget: this.search_budget,
                search_pref: this.search_pref,
                search_no_pref: this.search_no_pref,
                search_surface: this.search_surface,
                search_fourch: this.search_fourch,
                search_loue: this.search_loue,
                search_autre: this.search_autre,
                search_piece: this.search_piece,
              },
              params: {
                params_flux_avt: this.params_flux_avt,
                params_flux_apr: this.params_flux_apr,
                params_profit_tot: this.params_profit_tot,
                params_renta: this.params_renta,
                params_taux: this.params_taux,
              },
              advanced_info: {
                personals: {
                  partsFiscalesTot: this.partsFiscalesTot,
                  advanced_perso_date: this.advanced_perso_date,
                  advanced_perso_city: this.advanced_perso_city,
                  advanced_perso_address: this.advanced_perso_address,
                  advanced_perso_address_since: this.advanced_perso_address_since,
                  advanced_perso_pic: this.advanced_perso_pic,
                  advanced_perso_credit: this.advanced_perso_credit,
                  advanced_perso_child: this.advanced_perso_child,
                },
                pro: {
                  advanced_pro_poste: this.advanced_pro_poste,
                  advanced_pro_contract: this.advanced_pro_contract,
                  advanced_pro_enterprise: this.advanced_pro_enterprise,
                  advanced_pro_contract_start: this.advanced_pro_contract_start,
                  advanced_pro_contract_end: this.advanced_pro_contract_end,
                  advanced_pro_contract_autre: this.advanced_pro_contract_autre,
                },
                patrimoine_financier: {
                  advanced_pat_compte: this.advanced_pat_compte,
                  advanced_pat_livret: this.advanced_pat_livret,
                  advanced_pat_av: this.advanced_pat_av,
                  advanced_pat_scpi: this.advanced_pat_scpi,
                  advanced_pat_autre: this.advanced_pat_autre,
                  advanced_pat_tot: this.advanced_pat_tot,
                },
                patrimoine_immobilier: this.advanced_pat_immmo,
                strong_point: this.advanced_pat_strong_point,
              },
            },
          })
            .then(() => {
              this.setModalPDFLoader(false)
              // this.clearInvest({
              //   inv: null,
              //   index: 0
              // })
            })
            .catch((err) => {
              this.setModalPDFLoader(false)
              this.clearInvest({
                inv: null,
                index: 0,
              })
            })
        }
      }
    },
    params_taux_calc(event) {
      // console.log(event.target.value)
      if (event.target.value === 'automatique') {
        this.params_taux.isAuto = true
        this.params_taux.val = 1
      } else this.params_taux.isAuto = false
    },
  },
  mounted() {
    // cette fonction un peu crado sert à afficher la bonne légende en fonction de la fiscalité choisie
    // le bouton est en CSS et on ne peut pas binder sa position avec un variable
    if (!this.tmi_fix) {
      this.type_fiscalite_display_droite = {
        titre: 'Fiscalité variable',
        soustitre: 'en fonction des revenus du client',
      }
      this.type_fiscalite_display_gauche = {
        titre: 'Fiscalité identique',
        soustitre: 'sur toute la durée du projet',
      }
    }
    // console.log('FISCALITE MOUNTED', this.fiscalite);
  },
}
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  grid-column: span 10 / span 10;
  grid-column-start: 3;
  grid-column-end: 10;
  padding: 0.75rem /* 12px */;
  padding-left: 1.25rem /* 20px */;
  padding-right: 1.25rem /* 20px */;
  padding-bottom: 1.75rem /* 28px */;
  margin-bottom: 2.5rem /* 40px */;
  border-radius: 0.85rem /* 8px */;
}

.card-no-top {
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  grid-column: span 10 / span 10;
  grid-column-start: 3;
  grid-column-end: 10;
  padding: 0.75rem /* 12px */;
  padding-left: 1.25rem /* 20px */;
  padding-right: 1.25rem /* 20px */;
  padding-bottom: 1.75rem /* 28px */;
  margin-bottom: 0.5rem /* 40px */;
  border-bottom-left-radius: 0.85rem /* 8px */;
  border-bottom-right-radius: 0.85rem /* 8px */;
}

.card-header {
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

.card-body {
}

.tips {
  background: #c7caff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 37px;
  font-size: 13px;
}

input[type='file'] {
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  background: white;
  border-radius: 50px;

  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: #0c1e3b solid 1px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 37px;
  width: 45px;
  right: -1px;
  bottom: -1px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: #14a0eb;
  border-radius: 50px;
}

.slider-input {
  -webkit-appearance: none;
  width: 85%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider-input:hover {
  opacity: 1;
}

.slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #14a0eb;
  cursor: pointer;
}

.slider-input::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #14a0eb;
  cursor: pointer;
}

input:focus + .slider {
  box-shadow: 0 0 1px #14a0eb;
}

input:checked + .slider:before {
  -webkit-transform: translateX(-3.2rem);
  -ms-transform: translateX(-3.2rem);
  transform: translateX(-3.2rem);
}

select:not(.forme-jur) {
  border: 1px solid #d2d6dc;
  background-color: #ffffff;
  color: #000000;
  font-weight: normal;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAApklEQVRIie2VQQrCMBBFH15CjQp6/3XBU1RasGAVFOo92kUzUCSlzXSyEP3wd8n7gcnMwF+GcsAFyIGtNXwDXIHW+wbsLOHVAC6uLUL2wD0AFz+BoxZ+mICLX8BJA3/MgKtDygi4uAiBViMB75jXeDWKO1+sM/E1yEKgsRo4xaOimi75N5WQOY22qJunRkXtzyzS5yQ1HXaiNQnHtcjRL5skC+eH1QFftG+pNdEy3AAAAABJRU5ErkJggg==');
}

.ampoule-picto {
  top: -1rem;
  left: -0.8rem;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 37px;
  border-radius: 22px;
  background: white;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}
</style>
