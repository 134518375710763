<template>
  <div id="app">
    <AppHeader v-if="['Home', 'MesDossiers'].includes($route.name)" />
    <Navbar v-else-if="isAllow()" />
    <div class="flex">
      <AppSidebar v-if="['Home', 'MesDossiers'].includes($route.name)" />
      <router-view class="w-full" />
    </div>
    <transition name="fade">
      <div v-show="scY > 300" id="pagetop" class="fixed right-3 bottom-3" @click="toTop">
        <div class="p-1 rounded-lg cursor-pointer bg-lightblueaestima-100">
          <svg
            xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none"
            stroke="#FFFFFF"
            stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs"
          >
            <path d="M18 15l-6-6-6 6" />
          </svg>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios'

import { mapActions, mapGetters } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'

const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
// const elements = stripe.elements()
// const card = undefined

export default {
  name: 'App',
  components: {
    Navbar,
    AppHeader,
    AppSidebar,
  },
  props: {
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
    }
  },
  metaInfo() {
    return {
      title: 'Aestima: Le 1er logiciel pour CGP vraiment intuitif',
      meta: [{
        name: 'description',
        content: 'Simulateur d’investissement immobilier - Simulateur de défiscalisation - Pédagogique pour vos équipes et vos clients.',
      }],
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
      user: 'users/user',
      isTourLoading: 'users/isTourLoading',
    }),
    currentPage() {
      return this.$route.name
    },
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll)
    // if user is connected post to this route '/user/update_connexion' with the email as a body
    if (this.authenticated) {
      console.log('hello')
      await axios.post('/user/update_connexion', { email: this.user.email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((result) => {
          console.log(result)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  },
  methods: {
    ...mapActions({
      updateUserTour: 'users/updateUserTour',
      setTourLoading: 'users/setTourLoading',
    }),
    isAllow() {
      const pageName = ['HomeLanding', 'HIWLanding', 'WAWLanding', 'PricingLanding', 'ContactLanding', 'Register', 'CGVLanding', 'RGPDLanding', 'LegalLanding', 'ProposLanding', 'FAQLanding', 'Rent', 'Bank', 'Strategy', 'InvestSCI', 'Lexique', 'SignVersion', 'LogIn']
      if (this.authenticated) {
        if (pageName.includes(this.currentPage)) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    isSub() {
      if (this.isAllow() && this.authenticated.isSub == false) {
        return false
      }

      return true
    },
    isFree() {
      const freeDate = this.authenticated.free.split('/')
      const freedd = freeDate[0]
      const freemm = freeDate[1]
      const freeyyyy = freeDate[2]
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()

      const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      const firstDate = new Date(parseInt(yyyy), parseInt(mm), parseInt(dd))
      const secondDate = new Date(parseInt(freeyyyy), parseInt(freemm), parseInt(freedd))

      // const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))

      if (this.isAllow() && (secondDate.getTime() > firstDate.getTime())) {
        return true
      }

      return false
    },
    createCheckoutSession(priceId) {
      return axios.post('/create-checkout-session', { priceId, mail: this.authenticated.email, customerId: this.authenticated.customerId, isSub: this.authenticated.isSub, free: this.authenticated.free, isPreorder: this.authenticated.isPreorder })
        .then((result) => {
          return result.data
        })
        .catch((err) => {
          console.error('Checkout error', err)
        })
    },
    handleScroll() {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bg{
  background-color: #f3fafe;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4) !important;
}

.v-step {
  background: #FFFFFF !important;
  max-width: 28rem !important;
  padding: 1rem 0rem 0rem 0rem !important;
}

.chart-blue {
  background-color: #0c1e3b;
}

.chart-blue-light {
  background-color: #14a0eb;
}

.chart-orange {
  background-color: #eb7b14;
}

.chart-red {
  background-color: #f91515;
}

.chart-yellow {
  background-color: rgba(235, 175, 20, 0.6);
}

.chart-grey {
  background-color: rgba(95, 95, 95, 0.4);
}

.wrapper {
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.wrapper .tooltip {
  background: #14a0eb;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -13.5rem;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  width: 30rem;
  z-index: 999999;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #1496bb 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
  display: none;
}

.lte8 .wrapper:hover .tooltip {
  display: block;
}

.p-tooltip {
  z-index: 9999 !important;
}
</style>
