<script>
import {Pie, mixins } from 'vue-chartjs'
// const { reactiveProp } = mixins

export default {
  extends: Pie,
  // mixins: [reactiveProp],
  data() {
      return {
      }
    },
  props: ['chartdata', 'options'],
  mounted() {
    // console.log("this.chartdata",this.chartdata, "this.options", this.options)
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

<style>
</style>