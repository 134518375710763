<template>
  <nav class="bg-darkblueaestima-500">
    <div class="relative z-10 py-4 mx-auto xl:container xl:mx-auto sm:px-6 lg:px-8 sm:py-10">
      <div class="relative flex items-center justify-between h-16">
        <!-- Mobile menu button-->


        <div class="absolute inset-y-0 left-0 flex items-center w-full sm:hidden grid grid-cols-6 gap-1">
          <button
            @click="isMenuMobile = !isMenuMobile"
            class="inline-flex items-center justify-center p-2 text-gray-400 col-start-1 col-end-2 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
            aria-label="Main menu"
            aria-expanded="false"
          >
            <svg
              v-if="!isMenuMobile"
              class="block w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <svg
              v-else
              class="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <router-link to="/" class="col-start-2 col-end-6">
            <img
              class="z-10 inline-block w-auto h-16 text-center sm:hidden"
              alt="Logo du simulateur immobilier Aestima"
              src="../../assets/logo_aestima.png"
            />
          </router-link>
          <div v-if="isMenuMobile" class="absolute z-10 w-full h-64 bg-darkblueaestima-500 top-20 sm:hidden">
                      <div class="flex mx-6 grid grid-cols-6 gap-1">
                        <router-link
                          to="/"
                          class="px-3 py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-1 sm:col-end-2 rounded-md leading-5 focus:outline-none hover:bg-lightblueaestima-100 focus:text-white focus:bg-lightblueaestima-100 transition duration-150 ease-in-out"
                          >Accueil</router-link
                        >
                        <router-link
                          to="/tarifs"
                          class="px-3 py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-2 sm:col-end-3 sm:ml-4 rounded-md leading-5 hover:bg-lightblueaestima-100 focus:outline-none focus:text-white focus:bg-lightblueaestima-100 transition duration-150 ease-in-out"
                          >Tarifs</router-link
                        >
                        <router-link
                          to="/Qui-sommes-nous"
                          class="px-3 py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-3 sm:col-end-4 rounded-md leading-5 focus:outline-none hover:bg-lightblueaestima-100 focus:text-white focus:bg-lightblueaestima-100 transition duration-150 ease-in-out"
                          >Qui sommes-nous</router-link
                        >
                        <a
                          href="https://calendly.com/contact-aestima/45min?"
                          target="_blank"
                          class="px-3 py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-4 sm:col-end-5 sm:ml-4 rounded-md leading-5 hover:bg-lightblueaestima-100 focus:outline-none focus:text-white focus:bg-lightblueaestima-100 transition duration-150 ease-in-out"
                          >Demandez une démo</a
                        >
                        <router-link
                          to="/Contact"
                          class="px-3 py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-5 sm:col-end-6 sm:ml-4 rounded-md leading-5 hover:bg-lightblueaestima-100 focus:outline-none focus:text-white focus:bg-lightblueaestima-100 transition duration-150 ease-in-out"
                          >Posez-nous vos questions</router-link
                        >

                        <router-link
                          to="/Home"
                          v-if="authenticated"
                          class="px-3 py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-6 sm:col-end-7 sm:ml-4 rounded-md leading-5 bg-lightblueaestima-100 focus:outline-none focus:text-white focus:bg-lightblueaestima-100 transition duration-150 ease-in-out"
                          >Mon espace</router-link
                        >
                        <router-link
                          to="/Login"
                          v-else
                          class="px-3 py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-6 sm:col-end-7 sm:ml-4 rounded-md leading-5 bg-lightblueaestima-100 focus:outline-none focus:text-white focus:bg-lightblueaestima-100 transition duration-150 ease-in-out"
                          >Se connecter</router-link
                        >
                      </div>
          </div>
        </div>


        <div class="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0">
            <router-link to="/" class="flex flex-col sm:flex-row">
              <img
                class="hidden w-auto h-20 sm:block"
                alt="Logo du simulateur immobilier Aestima"
                src="../../assets/logo_aestima.png"
              />
              <!-- <h1 class="self-start hidden mt-2 ml-4 text-xs font-bold text-white sm:inline-block">Simulateur d’investissement locatif.</h1> -->
            </router-link>
          </div>

          <div class="hidden w-full sm:block sm:ml-6">
            <ul class="flex flex-row justify-end sm:gap-4 md:gap-12">
              <li
                class="py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-4 sm:col-end-6 rounded-md leading-5 hover:text-lightblueaestima-100 focus:outline-none focus:text-white transition duration-150 ease-in-out"
              >
                <a href="javascript:document.getElementById('slider_desktop').scrollIntoView(true);"
                  >Fonctionnalités</a
                >
              </li>
              <li
                class="py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-6 sm:col-end-7 rounded-md leading-5 hover:text-lightblueaestima-100 focus:outline-none focus:text-white transition duration-150 ease-in-out"
              >
                <router-link to="/tarifs">Tarifs</router-link>
              </li>
              <li
                class="self-center py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-7 sm:col-end-9 rounded-md leading-5 hover:text-lightblueaestima-100 focus:outline-none focus:text-white transition duration-150 ease-in-out"
              >
                <router-link to="/Qui-sommes-nous">Qui sommes-nous</router-link>
              </li>
              <li
                class="self-center py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-9 sm:col-end-11 rounded-md leading-5 hover:text-lightblueaestima-100 focus:outline-none focus:text-white transition duration-150 ease-in-out"
              >
                <p class="cursor-default">Contactez-nous</p>
                <div class="dropdown">
                  <ul>
                    <li class="flex flex-row items-start justify-start">
                      <img
                        class="h-auto mt-2 ml-4 w-9"
                        src="../../assets/icons/home/aestima_demo.png"
                        alt=""
                      />
                      <a href="https://calendly.com/contact-aestima/45min?" target="_blank">
                        <p>Demandez une démo</p>
                        <span
                          >Profitez d'une démo personnalisée de notre solution adaptée à votre fonctionnement
                          et vos objectifs.</span
                        >
                      </a>
                    </li>
                    <li class="flex flex-row items-start self-center justify-start">
                      <img
                        class="w-auto mt-2 ml-4 h-13"
                        src="../../assets/icons/home/aestima_contact.png"
                        alt=""
                      />
                      <router-link to="/Contact">
                        <p>Posez-nous vos questions</p>
                        <span
                          >Echangeons sur votre activité et vos objectifs, on vous répondra le plus rapidement
                          possible.</span
                        >
                      </router-link>
                    </li>
                  </ul>
                  <!-- <ul class="mini">
                    </ul> -->
                </div>
              </li>
              <li
                v-if="authenticated"
                class="self-center px-3 py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-11 sm:col-end-13 rounded-md leading-5 bg-lightblueaestima-100 focus:outline-none focus:text-white focus:bg-lightblueaestima-100 transition duration-150 ease-in-out"
              >
                <router-link to="/Home">Mon espace</router-link>
              </li>
              <router-link
                to="/Login"
                class="px-3 py-2 text-base font-medium text-white normal-case col-span-6 sm:col-start-11 sm:col-end-13 rounded-md leading-5 bg-lightblueaestima-100 focus:outline-none focus:text-white focus:bg-lightblueaestima-100 transition duration-150 ease-in-out"
                v-else
                ><li>Se connecter</li></router-link
              >
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Header',
  data() {
    return {
      isMenuMobile: false,
    };
  },
  props: {},
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
      user: 'users/user',
    }),
  },
  methods: {
    // ...mapActions("users", ["login"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Custom Stripe Dropdown */
nav {
  width: 100%;
}

nav ul {
  text-align: center;
}
nav ul li {
  /* display: inline-block;  */
  font-weight: 700;
  position: relative;
}

/* Main Dropdown */
nav ul li > div.dropdown {
  left: -9999px;
  opacity: 0;
  padding: 20px 0;
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  width: 440px;
  visibility: hidden;
}
nav ul li > div.dropdown:before {
  background-color: white;
  border-radius: 2px 0 0 0;
  content: '';
  height: 18px;
  left: calc(50% - 9px);
  position: absolute;
  top: 15px;
  transform: rotate(45deg);
  width: 18px;
}
nav ul li > div.dropdown:after {
  border-radius: 9999px;
  bottom: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
  content: '';
  filter: blur(5px);
  height: 40px;
  left: 5%;
  position: absolute;
  width: 90%;
  z-index: -1;
}
nav ul li:hover > div.dropdown,
nav ul li:focus-within > div.dropdown {
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
  visibility: visible;
}
nav ul li > div.dropdown ul {
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  padding: 10px 0;
  text-align: left;
}
nav ul li > div.dropdown ul li a,
nav ul li > div.dropdown ul li a:visited {
  color: #000000;
  display: inline-block;
  padding: 20px 20px 20px 20px;
}
nav ul li > div.dropdown ul li a:hover p,
nav ul li > div.dropdown ul li a:hover span {
  opacity: 0.7;
}
nav ul li > div.dropdown ul li a {
  position: relative;
}
/* nav ul li > div.dropdown ul li a:before {
    border-radius: 9999px;
    content: '';
    height: 60px;
    left: 20px;
    position: absolute;
    top: calc(50% - 30px);
    width: 60px;
}
nav ul li > div.dropdown ul li:nth-of-type(1) a:before { background-color: dodgerblue; }
nav ul li > div.dropdown ul li:nth-of-type(2) a:before { background-color: limegreen; }
nav ul li > div.dropdown ul li:nth-of-type(3) a:before { background-color: crimson; } */
nav ul li > div.dropdown ul li p {
  font-size: 1.1rem;
  margin-bottom: 8px;
  text-transform: inherit;
}
nav ul li > div.dropdown ul li span {
  color: grey;
  font-size: 0.9rem;
  font-weight: 400;
}

/* Mini, Bottom Dropdown Items */
nav ul li > div.dropdown ul.mini {
  background-color: #f6f9fc;
  border-radius: 0 0 10px 10px;
}
nav ul li > div.dropdown ul.mini li a {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 70px;
}
nav ul li > div.dropdown ul.mini li a p {
  margin-bottom: 0;
  margin-right: 10px;
}
nav ul li > div.dropdown ul.mini li a:before {
  left: 25px;
  height: 30px;
  top: calc(50% - 15px);
  width: 30px;
}
nav ul li > div.dropdown ul.mini li:nth-of-type(1) a:before {
  background-color: blueviolet;
}
nav ul li > div.dropdown ul.mini li:nth-of-type(2) a:before {
  background-color: gold;
}
nav ul li > div.dropdown ul.mini li:nth-of-type(3) a:before {
  background-color: hotpink;
}
nav ul li > div.dropdown ul.mini li:nth-of-type(4) a:before {
  background-color: lightblue;
}
nav ul li > div.dropdown ul.mini li:nth-of-type(5) a:before {
  background-color: slateblue;
}
</style>
