<template>
  <div class="relative flex flex-col">
    <div v-if="displayCopyPopup" class="fixed w-100 h-50 place-self-center bg-white mx-auto z-30 bottom-40 px-2 py-1 rounded-xl border-2 border-lightblueaestima-100 shadow-md text-sm animate-bounce">
      Copié dans le presse-papier
    </div>
    <div class="grid grid-cols-7 grid-container gap-4 px-4 pb-4 font-bold text-sm">
      <div />
      <div>Nom</div>
      <div>Email</div>
      <div>Téléphone</div>
      <div>Ville</div>
      <div>Commentaire</div>
      <div class="text-container">
        Simulations
      </div>
    </div>
    <div v-for="(invest, index) in investsList" :key="invest._id" class="gap-4 text-xs">
      <div class="grid grid-cols-7 grid-container gap-4 p-4 mb-3 bg rounded-2xl shadow-md cursor-pointer scale101" @click="clickInvest(invest, index)">
        <div class="flex">
          <img
            class="invest-pic z-20"
            :src="hasProfilePic(invest) ? invest.img.data : defaultPic(invest.gender)"
            :alt="invest.name"
          >
          <img
            v-if="invest.coInvestor"
            class="invest-pic -ml-4 z-10"
            :src="invest.coImg ? invest.coImg.data : defaultPic(invest.coGender)"
          >
        </div>
        <div v-if="invest.gender !== 'societe'" class="my-auto">
          <p class="break-all font-bold">
            {{ invest.firstname }} {{ invest.name }}
          </p>
          <p v-if="invest.coInvestor" class="break-all font-bold mb-3">
            et {{ invest.coPrenom }} {{ invest.coName }}
          </p>
        </div>
        <div class="my-auto" v-else>
          <p class="break-all font-bold">
            {{ invest.forme }} {{ invest.nameSociete }}
          </p>
        </div>
        <div class="break-all my-auto" :class="{ copy: !hasCopied }" @click.stop @click="copy(invest.mail)" @mouseleave="hasCopied = false">
          {{ invest.mail }}
        </div>
        <div class="break-all my-auto" :class="{ copy: !hasCopied }" @click.stop @click="copy(invest.phone)" @mouseleave="hasCopied = false">
          {{ invest.phone }}
        </div>
        <div class="break-all my-auto" @click="clickInvest(invest, index)">
          {{ getCityFromAdress(invest.adress) }}
        </div>
        <textarea
          type="text" class="break-normal whitespace-normal rounded-xl shadow-inner p-1" :value="invest.comments"
          placeholder="Prenez ici les notes que vous souhaitez" @click.stop
          @input="updateComment(invest, $event)"
        />
        <div class="m-auto font-bold text-lg">
          {{ invest.projects ? invest.projects.length : 0 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { debounce } from '@/tools/debounce'
import defaultFemmePic from '@/assets/picto/femme.png'
import defaultHommePic from '@/assets/picto/homme.png'
import defaultSocietePic from '@/assets/picto/societe.png'

export default {
  name: 'InvestorGridView',
  props: {
    investsList: {
      type: Array,
      required: true,
    },
  },
  emits: ['clickInvest'],
  data() {
    return {
      defaultFemmePic,
      defaultHommePic,
      defaultSocietePic,
      hasCopied: false,
      displayCopyPopup: false,
    }
  },
  created() {
    this.updateComment = debounce(this.updateComment, 500)
  },
  methods: {
    ...mapActions({
      updateInvest: 'invests/updateInvest',
    }),
    copy(text) {
      navigator.clipboard.writeText(text)
      this.hasCopied = true
      this.displayCopyPopup = true
      setTimeout(() => {
        this.displayCopyPopup = false
      }, 1000)
    },
    getCityFromAdress(address) {
      return address.split(' ').slice(-1)[0]
    },
    clickInvest(invest, index) {
      this.$emit('clickInvest', invest, index)
    },
    hasProfilePic(invest) {
      return invest && invest.img && invest.img.data
    },
    updateComment(invest, event) {
      this.updateInvest({
        id: invest._id,
        body: { ...invest, comments: event.target.value },
        push: false,
      }).catch((err) => {
        console.error(err)
      })
    },
    defaultPic(gender) {
      switch (gender) {
        case 'femme':
          return defaultFemmePic
        case 'homme':
          return defaultHommePic
        case 'societe':
          return defaultSocietePic
        default:
          return defaultFemmePic
      }
    },
  },
}
</script>

<style scoped>
.invest-pic {
  @apply h-15 w-15 rounded-full object-cover bg-white border-2 border-white;
}
.grid-container {
  display: grid;
  grid-template-columns: 100px 1fr 1fr 105px 120px 1.5fr 50px;
}
.text-container {
  text-align: right;
  overflow: visible;
  white-space: nowrap; /* Prevents the text from breaking into new lines */
  direction: rtl; /* Makes the overflow go to the left */
  margin-right: -1rem;
}
</style>
