<template>
  <div id="main-container" class="flex flex-col px-24 pb-24 pt-12 gap-8">
    <div id="titre" class="text-3xl font-bold text-left">Tutoriels d'utilisation</div>
    <div id="links" class="flex text-left text-lg font-semibold">
      <div class="w-1/2">
        <a
          class="underline text-lightblueaestima-500"
	  href="https://www.youtube.com/playlist?list=PLtzzHvUMaMTK6jpPhVdKTvlm2bHflKfBa"
          target="_blank"
          >Accéder aux tutoriels vidéos</a
        >
      </div>
      <div @click="toggleTutoModal" class="underline text-lightblueaestima-500 cursor-pointer">
        Relancer le tutoriel texte de la première utilisation
      </div>
      <PremiereUtil v-if="tuto" class="w-11/12 ml-7" @close="toggleTutoModal" />
    </div>
    <div id="faq" class="text-3xl font-bold text-left mt-8">Foire aux questions techniques</div>
    <div id="main-text" class="flex flex-col gap-8 fondbleu py-8 px-16 shadow-md rounded-xl">
      <div v-for="question in questions" class="flex flex-col text-left gap-4">
        <div class="font-bold my-1">
          {{ question[0] }}
        </div>
        <div v-html="question[1]" class="text-sm"></div>
      </div>
    </div>
    <div class="mt-6 justify-center">
      <a
        href="https://www.aestima-immo.com/Contact"
        target="_blank"
        class="text-lg font-bold p-2 px-6 py-4 bg-lightblueaestima-100 text-white rounded-lg hover:underline"
        >Contacter l’équipe</a
      >
    </div>
  </div>
</template>
<script>
import PremiereUtil from '../components/popup/PremiereUtil.vue';
export default {
  name: 'FAQTechnique',
  data() {
    return {
      questions: [
        [
          'Comment est calculé l’impôt sur la plus-value de revente dans ma simulation ?',
          'Si vous avez réalisé votre simulation en Nu, SCI à l’IR ou en LMNP nous appliquons l’imposition sur la plus-value immobilière des particuliers composée d’impôt sur le revenu et de prélèvements sociaux. Pour arriver au résultat final, nous respectons la règle de la plus-value brute en soustrayant du prix de revente le prix total d’acquisition majoré des frais de notaires et des travaux (montant réel ou au forfait si ce dernier vous avantage).<br><br>Nous appliquons ensuite les barèmes dégressifs en fonction de la durée de détention qui permette une exonération totale de l’impôt sur le revenu à partir de la 22ème année de détention et l’exonération totale des prélèvements sociaux à partir de la 30ème année de détention.<br><br>Enfin, nous appliquons la taxe additionnelle selon le barème en vigueur sur la plus-value nette assujetti à l’impôt sur le revenu lorsqu’elle est supérieure à 50 000€.',
        ],
        [
          'Comment est calculé l’amortissement comptable sur ma simulation ?',
          'Lorsque l’un des régimes fiscaux LMNP au réel, SCI à l’IS, SAS ou SASU est choisi, nous appliquons la règle comptable d’amortissement de l’actif qui consiste à accorder une valeur annuelle du bien détenu pour étaler son impact comptable sur plusieurs années.<br><br>Pour commencer, nous isolons la valeur foncière de l’investissement immobilier que nous évaluons, en moyenne, à 15% de la valeur totale du bien.<br><br>Nous répartissons ensuite la valeur du bien dans une grille d’amortissement selon plusieurs catégories de composants. Nous les amortirons sur des durées plus ou moins longues selon leur résistance dans le temps.<br>Si la répartition actuelle des composants ne vous convient pas, vous pouvez la personnaliser directement dans la simulation (partie fiscalité).',
        ],
        [
          'Comment sont comptabilisés les frais d’acquisition (frais d’agence, frais de notaires, etc.) et travaux ?',
          'Ces frais peuvent être comptabilisés de deux manières différentes : en charges portées au compte de résultat sur la première année ou valorisé dans le prix d’acquisition à amortir.<br><br>Dans notre algorithme Aestima, nous avons comptabilisé ces frais, ainsi que les travaux d’entretien et de rénovation sur la première année et donc déduit de la base imposable. Cependant pour les travaux de construction ou d’agrandissement non déductible, nous les avons intégré à votre base amortissable.',
        ],
        [
          'Comment sont reportés les déficits annuels ?',
          'Selon la situation fiscale de votre client inscrite dans le formulaire de création d’investisseur, la fiscalité du projet ainsi que les niches fiscales (comme celle du déficit foncier de 10 700€) s’adaptent. Pour ce qui est des déficits d’exploitation enregistrés avec le statut LMNP au réel seront reportables pendant 10 ans.Pour les déficits créés par les amortissements comptables du statut LMNP, ils sont reportés sans limitation de durée.<br><br>Pour les SCI à l’IS, SAS et SASU, ce déficit est reporté sans limitation de durée. (https://www.service-public.fr/professionnels-entreprises/vosdroits/F23628)',
        ],
        [
          'Selon quelle formule sont calculées les annuités d’emprunt ?',
          'Nous calculons l’emprunt uniquement avec la méthode par annuités constantes car c’est la méthode la plus utilisée pour l’investissement locatif à ce jour. Pour cela, nous prenons en compte le nombre de mois et non d’années. Cette méthode est plus précise que le calcul par annuités.',
        ],
        [
          'Peut-on intégrer, dans la simulation, la fiscalité déjà existante du client ?',
          'Oui, lorsque vous créez la fiche de votre client investir, cochez « fiscalité évolutive » et non « fiscalité figée au projet ». Dans ce cas, une fenêtre de détails apparaîtra et vous permettra de saisir l’ensemble des revenus du foyer fiscal (salaires, revenus fonciers, BIC, etc.). Cela calculera automatiquement la TMI de votre client année par année. Les simulations qui seront faites en suivant tiendront compte de ces revenus et changeront automatiquement de tranche marginale d’imposition si le projet locatif du client le nécessite.',
        ],
        [
          'Comment est calculé le versement et l’imposition des dividendes versés aux associés de société ?',
          'Lorsque vous avez choisi SCI à l’IS, SAS ou SASU dans la simulation de votre investissement locatif, vous êtes libre de cocher ou non le versement des dividendes aux associés de la société à l’IS.<br><br>Pour le versement des dividendes, nous déclenchons le versement automatique dès que le résultat après impôts de l’exercice comptable est positif. Pour rendre la compréhension plus simple, nous n’affectons pas de réserves et versons la totalité du résultat aux associés (réparti selon leurs parts sociales de chacun).<br><br>Pour l’impact de la fiscalité sur ces dividendes versés, vous pourrez choisir soit la méthode par prélèvement forfaitaire unique (PFU) ou « flax tax » soit la méthode d’imposition RCM selon la TMI de votre client.',
        ],
        [
          'Dans le cadre du changement de fiscalité au cours du projet, comment est calculé l’amortissement ?',
          'Lorsque vous souhaitez changer de fiscalité durant la détention d’un même projet, vous pouvez personnaliser le choix du nouveau régime fiscal, la date à laquelle vous souhaitez que ce changement intervienne et le nouveau loyer que vous voulez pratiquer.<br><br>Si vous faites le choix de passer d’une location nue classique à une location meublée au régime LMNP au réel, nous commencerons l’amortissement comptable à la date de passage au régime LMNP. La base de calcul correspondra à la valeur du bien majorée de la revalorisation du marché immobilier jusqu’à la date du changement.<br><br>Dans le cadre d’un passage du LMNP micro BIC au régime LMNP réel par exemple, le base est différent. Nous basons notre amortissement sur les coûts historiques. Cela signifie qu’au moment du changement de régime, la base de calcul correspondra à la valeur du bien à sa date d’achat diminuée de la valeur des amortissements déjà pratiqués. De plus, on prend l’amortissement en cours de route. C’est à dire que s’il reste 5 ans d’amortissement, nous arreterons ce dernier à 5 ans même si vous venez de passer en LMNP au réel et que le bien sera détenu plus de 5 ans.',
        ],
        [
          'Dans le cadre d’une simulation avec dispositif DENORMANDIE, comment sont traités les travaux ?',
          'Tous travaux de rénovation et d’entretien effectués dans le cadre du dispositif DENORMANDIE ne sont logiquement pas déductible de la base imposable et ne peuvent pas générer un déficit foncier. Cependant, tous les travaux dont le montant dépasse 25% de la valeur du bien (condition principale pour bénéficier du dispositif Denormandie) seront déductibles sur la base des revenus fonciers. Cette nuance a été prise en compte dans l’algorithme Aestima.',
        ],
        [
          "Comment est calculée la réduction d'impôts Malraux et notamment la fraction excédentaire ?",
          `Aestima analyse la situation fiscale personnelle de votre client (en fonction des éléments renseignés dans sa création) et adapte la réduction d'impôts Malraux à son impôt sur le revenu. <br><br>

          Comme le dit la loi, si la réduction d'impôt obtenue dépasse l'impôt dû, la fraction excédentaire peut être reportée sur les trois années suivantes, permettant ainsi de bénéficier de cet avantage fiscal sur une période maximale de quatre ans.<br><br>

          Nous en tenons compte dans le logiciel Aestima. Un panneau d'alerte est présent dans le tableau de trésorerie lorsque la simulation remplit cette condition.<br>
          De plus, un point de vigilance est également affiché dans la page "vu d'ensemble" si, malheureusement, la totalité de l'excédent n'a pas pu être utilisé.`
        ]
        // [
        //   'Que prenez-vous en compte dans les calculs relatifs au statut LMP ?',
        //   'Rassurez-vous, nous avons tenu compte de la mise à jour fiscale de 2021 relative au statut LMP avec notamment la cotisation obligatoire à la SSI. Vous pourrez librement fixer le montant de cette cotisation. Par défaut, nous retiendrons le montant plancher de 1145€ par an. Les déficits (hors amortissement) permis par ce statut sont aussi pris en compte dans notre algorithme. Nous respectons également la règle comptable des plus-values “court terme” et “long terme” avec abattements progressifs après 5 ans d’activité et régime d’exonération selon le chiffre d’affaires réalisés.',
        // ],
      ],
      tuto: false,
    };
  },
  methods: {
    toggleTutoModal() {
      this.tuto = !this.tuto;
    },
  },
  components: { PremiereUtil },
};
</script>
<style scoped>
.fondbleu {
  background-color: #eff8fe;
}
</style>
