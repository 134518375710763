<template>
  <div class="h-fill w-full flex-col px-12 relative bg-white shadow-inner">
    <PremiereUtil v-if="tuto" class="w-11/12 ml-7" @close="noMoreTour" />
    <div class="h-28 flex justify-between">
      <div class=" py-6 flex flex-col justify-items-center gap-1">
        <div class="text-xl font-semibold">
          Bonjour {{ user.firstname }} !
        </div>
        <div class="text-xs text-gray-500">
          {{ new Date().toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}
        </div>
      </div>
      <div class="flex gap-4 place-items-center">
        <div class="flex gap-4 py-2 px-4 bg-gradient-to-tr from-purpleaestima-500 to-purple-100 font-bold rounded-2xl shadow-inner">
          <img src="@/assets/NewHome/clients.jpg" class="h-12 rounded-xl m-auto" alt="logo">
          <p class="m-auto">
            Nombre de clients
            <span class="text-lg font-bold">
              {{ ` ${investsList.length}` }}
            </span>
          </p>
        </div>
        <div class="flex gap-4 py-2 px-4 bg-gradient-to-tr from-purpleaestima-500 to-purple-100  font-bold rounded-2xl shadow-inner">
          <img src="@/assets/NewHome/simulations.jpg" class="h-12 rounded-xl m-auto" alt="logo">
          <p class="m-auto">
            Nombre de simulations
            <span class="text-lg font-bold">
              {{ ` ${listProj.length}` }}
            </span>
          </p>
        </div>
      </div>
    </div>
    <div class="h-full flex flex-col">
      <div class="p-3 flex bg-gradient-to-tr from-blue-200 to-blue-50 rounded-xl justify-between shadow-md">
        <div id="left-buttons" class="flex gap-3">
          <div id="vue-buttons" class="flex">
            <button class="button rounded-l-lg border-r-0" :class="[!showGrid ? 'button-selected' : 'button-unselected']" @click="showGrid = false">
              Vue liste
            </button>
            <button class="button rounded-r-lg border-l-0" :class="[showGrid ? 'button-selected' : 'button-unselected']" @click="showGrid = true">
              Vue galerie
            </button>
          </div>
          <div id="sort-buttons" class="flex gap-1 place-items-center">
            <img :src="sortAZIcon" class="h-6 cursor-pointer" @click="sortBy === 'name' ? sortBy = 'nameReversed' : sortBy = 'name'">
            <img :src="sortUpdatedIcon" class="h-6 cursor-pointer" @click="sortBy === 'createdReversed' ? sortBy = 'created' : sortBy = 'createdReversed'">
          </div>
          <div id="search-bar" class="bg-white flex place-items-center rounded-xl text-sm">
            <img src="https://api.iconify.design/mingcute:search-line.svg" class="h-4 pl-2">
            <input v-model="filterValue" type="text" placeholder="Rechercher" class="pl-2 mx-2 w-44 rounded-lg">
          </div>
        </div>
        <button id="right-button" class="flex py-2 px-4 gap-1 place-items-center rounded-2xl cursor-pointer bg-white scale101 hover:bg-lightblueaestima-100 hover:text-white" @click="createInvest">
          <img src="https://api.iconify.design/mingcute:add-fill.svg" class="h-6">
          Créer un nouvel investisseur
        </button>
      </div>
      <div class="h-full text-xl mt-8">
        <InvestorGridView v-if="showGrid" :invests-list="filteredList" @clickInvest="selectInvest" />
        <InvestorListView v-else :invests-list="filteredList" @clickInvest="selectInvest" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InvestorGridView from '@/components/InvestorGridView.vue'
import InvestorListView from '@/components/InvestorListView.vue'
import PremiereUtil from '../components/popup/PremiereUtil.vue'

export default {
  name: 'NewHome',
  components: {
    InvestorGridView,
    InvestorListView,
    PremiereUtil
},
  props: {},
  data() {
    return {
      showGrid: true,
      filterValue: '',
      sortBy: '',
      tuto : false,
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'users/authenticated',
      user: 'users/user',
      listProj: 'projects/projects',
      listInv: 'invests/invests',
    }),
    sortAZIcon() {
      return (this.sortBy === 'name') ? 'https://api.iconify.design/mingcute:az-sort-ascending-letters-line.svg' : 'https://api.iconify.design/mingcute:za-sort-descending-letters-line.svg'
    },
    sortUpdatedIcon() {
      return (this.sortBy === 'createdReversed') ? 'https://api.iconify.design/mingcute:sort-descending-line.svg' : 'https://api.iconify.design/mingcute:sort-ascending-line.svg'
    },
    filteredList() {
      return this.investsList.filter((invest) => {
        return (
          invest.name.toLowerCase().includes(this.filterValue.toLowerCase())
          || invest.firstname.toLowerCase().includes(this.filterValue.toLowerCase())
          || invest.adress.toLowerCase().includes(this.filterValue.toLowerCase())
          || invest.phone.toLowerCase().includes(this.filterValue.toLowerCase())
          || invest.mail.toLowerCase().includes(this.filterValue.toLowerCase())
        )
      })
    },
    lastListProj() {
      const lastThreeProject = []
      for (const invest of this.listInv) {
        if (invest.archive.isArchived === false) {
          for (const project of this.listProj) {
            if (invest.projects.includes(project._id)) {
              lastThreeProject.push(project)
            }
          }
        }
      }
      return lastThreeProject.slice(Math.max(lastThreeProject.length - 3, 0))
    },
    investsList() {
      return this.listInv.filter(val => val.archive.isArchived === false).sort((a, b) => {
        if (this.sortBy === 'name') {
          return a.name.localeCompare(b.name)
        } else if (this.sortBy === 'nameReversed') {
          return b.name.localeCompare(a.name)
        } else if (this.sortBy === 'created') {
          return a.created.localeCompare(b.created)
        } else if (this.sortBy === 'createdReversed') {
          return b.created.localeCompare(a.created)
        } else if (this.sortBy === 'updated') {
          return a.updatedAt.localeCompare(b.updatedAt)
        } else if (this.sortBy === 'updatedReversed') {
          return b.updatedAt.localeCompare(a.updatedAt)
        }
      })
    },
  },
  mounted() {
    if (this.user.isTour === true) this.tuto = true
  },
  metaInfo() {
    return {
      title: 'Aestima | Dashboard',
      meta: [
        {
          name: 'description',
          content: 'Dashboard clients, dossiers, réglages.',
        },
      ],
    }
  },
  created() {
  },
  methods: {
    ...mapActions({
      signOut: 'users/logout',
      getProjects: 'projects/getProjectsSummary',
      getProjectsSummary: 'projects/getProjectsSummary',
      setInvestSelected: 'invests/setInvestSelected',
      updateUserTour: 'users/updateUserTour',
    }),
    createInvest() {
      this.setInvestSelected({
        inv: null,
        index: 0,
      })
      this.$router.replace({
        name: 'Investor',
      })
    },
    selectInvest(inv, index) {
      console.log(inv, index)
      this.setInvestSelected({
        inv,
        index,
      }).then(() => {
        this.$router.replace({
          name: 'MesDossiers',
        })
      })
    },
    investByProject(projectId) {
      let selectedInv = null
      this.listInv.map((inv) => {
        return inv.projects.map((proj) => {
          if (proj === projectId) {
            return (selectedInv = inv.name)
          }
        })
      })
      return selectedInv
    },
    cashFlowComputed(proj) {
      if (proj.results == null) {
        return null
      }

      let cashFlowAvantRemb = 0
      let cashFlowAvantRembCounter = 0
      let resCashFlowAvantRemb = 0
      for (let i = 0; i <= proj.results.timelineFonc.resTimelineEmpr.length; i++) {
        if (i > 0 && proj.results.timelineFonc.resTimelineEmpr[i] === 1) {
          cashFlowAvantRemb += proj.results.resFluxAIFonc.resCashFlowActu[i]
          cashFlowAvantRembCounter += 1
        }
      }

      resCashFlowAvantRemb = isNaN(Math.round(cashFlowAvantRemb / cashFlowAvantRembCounter / 12))
        ? 0
        : Math.round(cashFlowAvantRemb / cashFlowAvantRembCounter / 12)

      return resCashFlowAvantRemb
    },
    noMoreTour() {
      this.tuto = false
      this.updateUserTour({
        id: this.user._id,
        isTour: false,
      })
    },
  },
}
</script>

<style>
/* class for selected and unselected buttons */
.button {
  font-size: 0.8rem;
  padding-left: 8px;
  padding-right: 8px;
}
.button-selected {
  background-color: #43b3ef;
  color: #FFFFFF;
}
.button-unselected {
  background-color: #FFFFFF;
  color: #43b3ef;
}

.scale101 {
  transition: transform 0.05s ease-in-out;  /* apply a transition to the transform property */
}

.scale101:hover {
  transform: scale(1.01);  /* scale the element to 102% when hovered */
}

.scale103 {
  transition: transform 0.05s ease-in-out;  /* apply a transition to the transform property */
}

.scale103:hover {
  transform: scale(1.03);  /* scale the element to 102% when hovered */
}

.copy{
  cursor: copy;
}

.copy:hover {
  font-weight: 700;
}
</style>
