import Vue from 'vue'
import PrimeVue from 'primevue/config'
import axios from 'axios'
import '@/assets/css/tailwind.css'
import 'primevue/resources/themes/lara-light-indigo/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import Meta from 'vue-meta'
import VueTour from 'vue-tour'
import VueAnalytics from 'vue-analytics'
import VueGtag from 'vue-gtag'

import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
import InputSwitch from 'primevue/inputswitch'
import Message from 'primevue/message'
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

import App from './App.vue'
import router from './router'
import store from './store'

require('vue-tour/dist/vue-tour.css')
require('@/store/subscriber')

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-186722129-1',
  router,
})
Vue.use(VueGtag, {
  config: { id: 'G-TWPZRCDRRP' },
}, router)

console.log(process.env.VUE_APP_API_HOSTNAME_DEV)
axios.defaults.baseURL = process.env.VUE_APP_API_HOSTNAME_DEV

Vue.component('PDropdown', Dropdown)
Vue.component('PButton', Button)
Vue.component('PSwitch', InputSwitch)
Vue.component('PMessage', Message)
Vue.component('PDialog', Dialog)
Vue.component('PPassword', Password)
Vue.component('PToast', Toast)
Vue.component('PAccordion', Accordion)
Vue.component('PAccordionTab', AccordionTab)

Vue.directive('tooltip', Tooltip)
Vue.use(ToastService)

Vue.config.productionTip = false
Vue.use(Meta)
Vue.use(PrimeVue)
Vue.use(VueTour)
store.dispatch('users/attempt', { token: localStorage.getItem('token'), refresh_token: localStorage.getItem('refresh_token') })
  .then(() => {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  })
