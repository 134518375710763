<template>
  <div class="relative flex flex-wrap justify-between gap-5">
    <div v-if="displayCopyPopup" class="fixed w-100 left-1/2 transform -translate-x-1/2 bg-white z-30 bottom-40 px-2 py-1 rounded-xl border-2 border-lightblueaestima-100 shadow-md text-sm animate-bounce">
      Copié dans le presse-papier
    </div>
    <div v-for="(invest, index) in investsList" :key="invest._id">
      <div class="flex w-72 h-32 justify-between gap-2 px-3 py-2 bg-gradient-to-tr from-blue-200 to-blue-0 rounded-2xl shadow-md cursor-pointer scale103" @click="clickInvest(invest, index)">
        <div class="w-1/3">
          <div class="flex">
            <img
              class="invest-pic z-20"
              :src="hasProfilePic(invest) ? invest.img.data : defaultPic(invest.gender)"
              :alt="invest.name"
            >
            <img
              v-if="invest.coInvestor"
              class="invest-pic -ml-6 z-10"
              :src="invest.coImg ? invest.coImg.data : defaultPic(invest.coGender)"
            >
          </div>
          <p class="text-xl font-bold mt-1">
            {{ invest.projects ? invest.projects.length : 0 }}
            <span class="text-sm">projets</span>
          </p>
        </div>
        <div class="flex flex-col text-xs w-2/3">
          <div v-if="invest.gender !== 'societe'">
            <p class="text-base font-bold text-left text-lightblueaestima-100">
              {{ invest.firstname }} {{ invest.name }}
            </p>
            <p v-if="invest.coInvestor" class="text-sm font-bold text-left mb-3 text-lightblueaestima-100">
              et {{ invest.coPrenom }} {{ invest.coName }}
            </p>
          </div>
          <div v-else>
            <p class="text-base font-bold text-left text-lightblueaestima-100">
              {{ invest.forme }} {{ invest.nameSociete }}
            </p>
          </div>
          <div v-if="invest.phone" class="flex gap-2" :class="{ copy: !hasCopied }" @click.stop @click="copy(invest.phone)" @mouseleave="hasCopied = false">
            <img src="https://api.iconify.design/mingcute:phone-fill.svg" class="h-4">
            <p> {{ invest.phone }}</p>
          </div>
          <div v-if="invest.mail" class="flex gap-2 w-40" :class="{ copy: !hasCopied }" @click.stop @click="copy(invest.mail)" @mouseleave="hasCopied = false">
            <img src="https://api.iconify.design/mingcute:mail-line.svg" class="h-4">
            <p class="truncate">
              {{ invest.mail }}
            </p>
          </div>
          <div v-if="getCityFromAdress(invest.adress)" class="flex gap-2">
            <img src="https://api.iconify.design/mingcute:location-line.svg" class="h-4">
            <p> {{ getCityFromAdress(invest.adress) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultFemmePic from '@/assets/picto/femme.png'
import defaultHommePic from '@/assets/picto/homme.png'
import defaultSocietePic from '@/assets/picto/societe.png'
import ProjectsStack from '@/components/ProjectsStack.vue'

export default {
  name: 'InvestorGridView',
  components: {
    ProjectsStack,
  },
  props: {
    investsList: {
      type: Array,
      required: true,
    },
  },
  emits: ['clickInvest'],
  data() {
    return {
      defaultFemmePic,
      defaultHommePic,
      hasCopied: false,
      defaultSocietePic,
      displayCopyPopup: false,
    }
  },
  mounted() {
  },
  methods: {
    hasProfilePic(invest) {
      return invest && invest.img && invest.img.data
    },
    getCityFromAdress(address) {
      return address.split(' ').slice(-1)[0]
    },
    clickInvest(invest, index) {
      this.$emit('clickInvest', invest, index)
    },
    copy(text) {
      navigator.clipboard.writeText(text)
      this.hasCopied = true
      this.displayCopyPopup = true
      setTimeout(() => {
        this.displayCopyPopup = false
      }, 1000)
    },
    defaultPic(gender) {
      switch (gender) {
        case 'femme':
          return defaultFemmePic
        case 'homme':
          return defaultHommePic
        case 'societe':
          return defaultSocietePic
        default:
          return defaultFemmePic
      }
    },
  },
}
</script>

<style scoped>
.invest-pic {
  @apply h-15 w-15 rounded-full object-cover bg-white border-2 border-white;
}
</style>
