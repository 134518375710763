<template>
  <div class="relative z-0">
    <Header />
    <section id="hero" class="text-white bg-darkblueaestima-500">
      <div
        class="relative z-0 items-center pt-5 pb-10 overflow-hidden xl:container xl:mx-auto grid grid-cols-12 gap-1 sm:pt-20 md:pb-40 sm:pb-20"
      >
        <div
          class="relative z-30 px-4 mt-0 md:text-left col-span-12 md:col-start-2 md:col-end-7 xl:col-start-1 items-left md:items-left sm:px-6 md:px-0 md:mt-0"
        >
          <h1 class="inline-block text-base sm:text-lg lg:flex">
            Le seul simulateur d’investissement immobilier pour CGP (vraiment) intuitif.
          </h1>
          <p class="mx-1 sm:text-medium sm:mx-0 lg:mt-10">
            Enfin, un logiciel intuitif, pour un CGP excellent.
          </p>
          <div class="flex justify-center gap-4 sm:mt-8 lg:justify-start">
            <router-link
              to="/register?price=0"
              @click.native="selectedStripePrice({ id: 0, isTrial: false })"
              class="p-2 mt-6 sm:text-base sm:py-4 sm:px-6 aestima-button sm:mt-0 sm:mb-3"
              >Démarrez dès maintenant</router-link
            >
            <a
              target="_blank"
              class="p-2 mt-6 text-sm font-bold underline bg-transparent border-2 border-white rounded-lg cursor-pointer sm:text-base sm:px-6 sm:py-4 sm:mt-0 sm:mb-3"
              @click="ytModal = true"
              >Voir la vidéo de présentation</a
            >
                <ModalYoutube v-if="ytModal" videoId="Sym5H5z5bhc" v-on:close="ytModal = false"/>
          </div>
        </div>
        <img
          class="relative flex order-first h-auto px-4 mb-6 col-span-12 md:px-0 md:absolute absolute-screen-splash w-screen-splash"
          src="../../assets/aestima_splash_screen.png"
          alt="aestima splash screen"
        />

        <svg
          class="absolute bottom-0 left-0 z-20 hidden w-screen xl:hidden sm:block md:inline-block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#ffffff"
            fill-opacity="1"
            d="M0,192L120,208C240,224,480,256,720,272C960,288,1200,288,1320,288L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </div>
    </section>

    <section id="comparaison">
      <div id="leTout" class="flex flex-col items-center mt-4 mb-12 marges sm:mb-16">
        <div class="w-full px-1/12">
          <h2 id="leTexte" class="inline w-full mb-8 mx-1/6 place-self-center place-items-center">
            <div class="w-full md:text-left place-self-start">
              Qu’apporte le logiciel CGP intuitif Aestima
            </div>
            <div class="w-full font-normal md:text-left place-self-start">
              par rapport à un simulateur d’investissement immobilier traditionnel ?
            </div>
          </h2>
        </div>
        <div id="lesListes" class="flex flex-col justify-center w-full px-1/12 sm:flex-row">
          <div id="gauche" class="max-w-xl py-4 pr-6 sm:w-7/12">
            <h3>Logiciel CGP complexe traditionnel</h3>
            <div id="laListe" class="flex flex-col">
              <div class="flex flex-row">
                <img src="../../assets/icons/home/cocheNon.png" class="h-10" />
                <div class="pour-contre">
                  <span class="text-red-700">Prise en main difficile </span>
                  pour les équipes
                </div>
              </div>
              <div class="flex flex-row">
                <img src="../../assets/icons/home/cocheNon.png" class="h-10" />
                <div class="pour-contre">
                  <span class="text-red-700">Peu d’accompagnement </span>
                  dans la saisie
                </div>
              </div>
              <div class="flex flex-row">
                <img src="../../assets/icons/home/cocheNon.png" class="h-10" />
                <div class="text-left pour-contre">
                  <span class="text-red-700">Perte de temps </span>
                  pour interpréter les résultats
                </div>
              </div>
              <div class="flex flex-row">
                <img src="../../assets/icons/home/cocheNon.png" class="h-10" />
                <div class="pour-contre">
                  <span class="text-red-700">Confusion du client</span>
                  face à la complexité
                </div>
              </div>
            </div>
          </div>
          <div id="droite" class="max-w-xl px-6 py-4 sm:w-7/12 bg-section-light-blue blue-shadow">
            <h3>Logiciel CGP Aestima, conçu pour être intuitif</h3>
            <div id="laListe" class="flex flex-col">
              <div class="flex flex-row">
                <img src="../../assets/icons/home/cocheOui.png" class="h-10" />
                <div class="pour-contre">Prise en main simplifiée avec un parcours unique</div>
              </div>
              <div class="flex flex-row">
                <img src="../../assets/icons/home/cocheOui.png" class="h-10" />
                <div class="pour-contre">Remplissage auto en fonction des données du marché</div>
              </div>
              <div class="flex flex-row">
                <img src="../../assets/icons/home/cocheOui.png" class="h-10" />
                <div class="pour-contre">Explication des calculs et résultats vulgarisés</div>
              </div>
              <div class="flex flex-row">
                <img src="../../assets/icons/home/cocheOui.png" class="h-10" />
                <div class="pour-contre">Choix de l’affichage pour présenter l’essentiel au client</div>
              </div>
              <router-link
                to="/register"
                @click.native="selectedStripePrice({ id: 0, isTrial: false })"
                class="py-2 mx-16 mt-4 mb-2 font-bold text-white rounded-lg w-fit aestima-button-dark"
                >Démarrer gratuitement</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="boostezProd">
      <div
        id="leTout"
        class="flex flex-col justify-center py-8 marges sm:flex-row sm:gap-10 sm:space-x-6 bg-section-light-blue-2"
      >
        <div class="self-center justify-end hidden sm:flex sm:w-4/12">
          <img src="../../assets/home/bandeau5.png" class="card-image" />
        </div>
        <div id="leTexte" class="max-w-xl text-sm sm:w-8/12 sm:mt-8">
          <h2 class="inline text-left sm:flex sm:w-full">
            Boostez votre productivité avec une prise en main simplifiée
          </h2>
          <div class="my-4 sm:hidden place-self-center">
            <img src="../../assets/home/bandeau5.png" class="card-image" />
          </div>
          <div id="leDitTexte" class="flex flex-col mb-2 text-center space-y-4 sm:text-justify leading-6">
            <div>Offrez à vos équipes un moyen d’être plus productif, plus simplement.</div>
            <div>
              Comment ? Grâce à une prise en main rapide permise par un parcours utilisateur unique et
              facilité sur le
              <span class="font-bold"> simulateur d’investissement immobilier </span>
              Aestima.
            </div>
            <div>
              Tous les membres de votre équipe, qu’ils soient experts ou novices, pourront comprendre et
              utiliser rapidement le
              <span class="font-bold"> simulateur de défiscalisation </span>
              Aestima.
            </div>
          </div>
          <div class="sm:justify-self-start sm:w-max-content">
            <router-link
              to="/register"
              class="px-8 py-2 mt-8 mb-4 font-bold text-white rounded-lg w-fit aestima-button"
              @click.native="selectedStripePrice({ id: 0, isTrial: false })"
              >Démarrer gratuitement</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <section id="optimisezTemps">
      <div id="leTout" class="flex flex-col justify-center py-8 marges sm:flex-row sm:gap-10 sm:space-x-6">
        <div id="leTexte" class="max-w-lg text-sm sm:w-8/12 sm:mt-8">
          <h2 class="inline text-left sm:flex sm:w-full">
            Optimisez votre temps avec une utilisation fluide et intuitive
          </h2>
          <div class="my-4 sm:hidden place-self-center">
            <img src="../../assets/home/bandeau4.png" class="card-image" />
          </div>
          <div id="leDitTexte" class="flex flex-col mb-2 text-center space-y-4 sm:text-justify leading-6">
            <div>
              Fini le temps où vous vous demandez “où se trouve le bouton
              <span class="font-bold"> Calcul Rentabilité Pinel </span>
              déjà?” sur votre
              <span class="font-bold"> simulateur de défiscalisation </span>
              !
            </div>
            <div>
              Profitez de formulaires enrichis pour gagner du temps dans les analyses de vos
              <span class="font-bold"> calculs de rentabilité en Pinel</span>, LMNP ou autres. Directement
              connecté au marché local, l’algorithme de notre
              <span class="font-bold"> simulateur d’investissement immobilier </span>
              vous indiquera les bonnes valeurs à saisir en fonction de l’emplacement du bien locatif.
            </div>
          </div>
          <div class="sm:justify-self-start sm:w-max-content">
            <router-link
              to="/register"
              class="px-8 py-2 mt-4 mb-8 font-bold text-white rounded-lg w-fit aestima-button"
              >Démarrer gratuitement</router-link
            >
          </div>
        </div>
        <div class="hidden sm:block sm:w-4/12 place-self-center justify-self-end">
          <img src="../../assets/home/bandeau4.png" class="card-image" />
        </div>
      </div>
    </section>

    <section id="PDFclairs">
      <div
        id="leTout"
        class="flex flex-col justify-center py-8 marges sm:flex-row sm:gap-10 sm:space-x-6 bg-section-light-blue-2"
      >
        <div class="self-center justify-end hidden sm:flex sm:flex-row sm:w-4/12">
          <img src="../../assets/home/bandeau6.png" class="card-image" />
        </div>
        <div id="leTexte" class="max-w-xl text-sm sm:w-8/12 sm:mt-8">
          <h2 class="inline text-left sm:flex sm:w-full">
            Touchez plus de clients avec des rapports PDF clairs et personnalisés
          </h2>
          <div class="content-end my-4 sm:hidden place-self-center">
            <img src="../../assets/home/bandeau6.png" class="card-image" />
          </div>
          <div id="leDitTexte" class="flex flex-col mb-2 text-center space-y-4 sm:text-justify leading-6">
            <div>Ne perdez plus vos clients dans une masse d’informations qu’ils ne comprennent pas.</div>
            <div>
              Contrôlez ce que vous leur montrez. Chaque résultat et sa formule de calcul sont expliqués et
              détaillés. Que ce soit pour un
              <span class="font-bold"> calcul de rentabilité </span>
              en Pinel, en LMNP, en SCI, autres.
            </div>
            <div>
              Vous pouvez ensuite éditer et personnaliser, page après page, votre rapport de présentation en
              adaptant le niveau d’informations au degré de connaissance de votre client.
            </div>
          </div>
          <div class="sm:justify-self-start sm:w-max-content">
            <router-link
              to="/register"
              class="px-8 py-2 mt-4 mb-8 font-bold text-white rounded-lg justify-self-start w-fit aestima-button"
              >Démarrer gratuitement</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <section id="slider_desktop">
      <div class="items-center hidden py-10 mx-4 md:flex md:flex-col gap-1 sm:mx-0 px-1/12">
        <div id="leTexte" class="max-w-4xl text-left">
          <h2 id="fonctionnality_part" class="py-1 text-2xl font-normal md:text-4xl">
            Un logiciel pour CGP simple et intuitif qui reste complet
          </h2>
          <p class="py-1 mb-10 text-base font-normal sm:text-sm">
            Simple ne veut pas forcément dire incomplet. Voici en détails toutes nos fonctionnalités pour
            vérifier par vous-même que l’on peut réussir à simplifier la complexité d’un
            <strong> simulateur d’investissement immobilier. </strong>
            Et ça c’est notre priorité.
          </p>
        </div>
        <div id="onglets">
          <div class="flex flex-col items-center justify-between col-span-12 sm:col-start-2 sm:col-end-12">
            <div id="cate" class="flex justify-center w-full">
              <ul class="mb-10 tabnav">
                <li
                  v-for="(tab, index) in section_logiciel_submenu"
                  :key="tab"
                  @click="setCurrent(index)"
                  class="relative tabnav-item"
                >
                  <p class="tabnav-link" :class="{ current: currentIndex === index }">
                    {{ tab }}
                  </p>
                </li>
              </ul>
            </div>
            <div
              v-show="currentIndex == 0"
              class="flex flex-col items-center justify-between w-full sm:flex-row"
            >
              <img
                class="h-full shadow-xl sm:w-auto max-h-96 rounded-md"
                src="../../assets/home/bandeau1.png"
                alt="Ecran d'accueil du dashboard aestima "
              />
              <div class="mt-8 text-center sm:mt-0">
                <p class="text-base font-bold">Les + de cette fonctionnalité :</p>
                <ul class="liste-slide-desktop">
                  <li class="liste-slide">Fiscalité fixe ou évolutive</li>
                  <li class="liste-slide">Calcul auto et évolutif de la TMI</li>
                  <li class="liste-slide">Stratégie d’investissement</li>
                  <li class="liste-slide">Critères de recherche détaillés</li>
                  <li class="liste-slide">Simulateur d’endettement</li>
                  <a
                    target="_blank"
                    to="/acces-simulateur"
                    class="px-6 py-4 mt-6 mr-1 font-bold text-white rounded-lg text-md sm:text-base sm:mr-3 bg-aestiblue-light aestima-button sm:mt-10 sm:mb-3"
                    href="https://calendly.com/contact-aestima/45min?"
                    >Programmer une démo</a
                  >
                </ul>
              </div>
            </div>
            <div
              v-show="currentIndex == 1"
              class="flex flex-col items-center justify-between w-full sm:flex-row"
            >
              <img
                class="h-full shadow-xl sm:w-auto max-h-96 rounded-md"
                src="../../assets/home/bandeau2.png"
                alt="Ecran d'accueil du dashboard Aestima "
              />
              <div class="mt-8 text-center sm:mt-0">
                <p class="text-base font-bold">Les + de cette fonctionnalité :</p>
                <ul class="liste-slide-desktop">
                  <li class="liste-slide">Tableau de bord intuitif</li>
                  <li class="liste-slide">Récapitulatif du profil client</li>
                  <li class="liste-slide">Notes sur l’évolution de la relation</li>
                  <li class="liste-slide">Notifications pour le suivi client</li>
                  <li class="liste-slide">Récapitulatif des simulations</li>
                  <a
                    target="_blank"
                    to="/acces-simulateur"
                    class="px-6 py-4 mt-6 mr-1 font-bold text-white rounded-lg text-md sm:text-base sm:mr-3 bg-aestiblue-light aestima-button sm:mt-10 sm:mb-3"
                    href="https://calendly.com/contact-aestima/45min?"
                    >Programmer une démo</a
                  >
                </ul>
              </div>
            </div>
            <div
              v-show="currentIndex == 2"
              class="flex flex-col items-center justify-between w-full sm:flex-row"
            >
              <img
                class="h-full shadow-xl sm:w-auto max-h-96 rounded-md"
                src="../../assets/home/bandeau3.png"
                alt="Ecran d'accueil du dashboard aestima "
              />
              <div class="mt-8 text-center sm:mt-0">
                <p class="text-base font-bold">Les + de cette fonctionnalité :</p>
                <ul class="liste-slide-desktop">
                  <li class="liste-slide">Enrichies des données du marché</li>
                  <li class="liste-slide">Pas de saisie superflue</li>
                  <li class="liste-slide">Fiscalité nom propre ou société</li>
                  <li class="liste-slide">Duplicables et optimisables</li>
                  <li class="liste-slide">MaJ fiscales et comptables</li>
                  <a
                    href="https://calendly.com/contact-aestima/45min?"
                    target="_blank"
                    class="px-6 py-4 mt-6 mr-1 font-bold text-white rounded-lg text-md sm:text-base sm:mr-3 bg-aestiblue-light aestima-button sm:mt-10 sm:mb-3"
                    >Programmer une démo</a
                  >
                </ul>
              </div>
            </div>
            <div
              v-show="currentIndex == 3"
              class="flex flex-col items-center justify-between w-full sm:flex-row"
            >
              <img
                class="h-full shadow-xl sm:w-auto max-h-96 rounded-md"
                src="../../assets/home/bandeau_resultats_epures.png"
                alt="Ecran d'accueil du dashboard aestima "
              />
              <div class="mt-8 text-center sm:mt-0">
                <p class="text-base font-bold">Les + de cette fonctionnalité :</p>
                <ul class="liste-slide-desktop">
                  <li class="liste-slide">Conseils adaptés à la stratégie</li>
                  <li class="liste-slide">Explication de tous les calculs</li>
                  <li class="liste-slide">Fiscalité évolutive selon le client</li>
                  <li class="liste-slide">Principaux ratios fondamentaux</li>
                  <li class="liste-slide">Analyse du marché locatif local</li>
                  <a
                    href="https://calendly.com/contact-aestima/45min?"
                    target="_blank"
                    to="/acces-simulateur"
                    class="px-6 py-4 mt-6 mr-1 font-bold text-white rounded-lg text-md sm:text-base sm:mr-3 bg-aestiblue-light aestima-button sm:mt-10 sm:mb-3"
                    >Programmer une démo</a
                  >
                </ul>
              </div>
            </div>
            <div
              v-show="currentIndex == 4"
              class="flex flex-col items-center justify-between w-full sm:flex-row"
            >
              <img
                class="h-full shadow-xl sm:w-auto max-h-96 rounded-md"
                src="../../assets/home/bandeau_comparaison.png"
                alt="Ecran d'accueil du dashboard aestima "
              />
              <div class="mt-8 text-center sm:mt-0">
                <p class="text-base font-bold">Les + de cette fonctionnalité :</p>
                <ul class="liste-slide-desktop">
                  <li class="liste-slide">Jusqu’à 4 projets comparés</li>
                  <li class="liste-slide">Choisir le projet le plus rentable</li>
                  <li class="liste-slide">Choisir la ville la plus dynamique</li>
                  <li class="liste-slide">Etudier plusieurs scénarios</li>
                  <li class="liste-slide">Faciliter la prise de décision</li>
                  <a
                    href="https://calendly.com/contact-aestima/45min?"
                    target="_blank"
                    class="px-6 py-4 mt-6 mr-1 font-bold text-white rounded-lg text-md sm:text-base sm:mr-3 bg-aestiblue-light aestima-button sm:mt-10 sm:mb-3"
                    >Programmer une démo</a
                  >
                </ul>
              </div>
            </div>
            <div
              v-show="currentIndex == 5"
              class="flex flex-col items-center justify-between w-full sm:flex-row"
            >
              <img
                class="h-full sm:w-auto max-h-96 rounded-md"
                src="../../assets/home/bandeau_export.jpg"
                alt="Ecran d'accueil du dashboard aestima "
              />
              <div class="mt-8 text-center sm:mt-0">
                <p class="text-base font-bold">Les + de cette fonctionnalité :</p>
                <ul class="liste-slide-desktop">
                  <li class="liste-slide">Contenu personnalisable</li>
                  <li class="liste-slide">Logo et nom personnalisable</li>
                  <li class="liste-slide">Récapitulatif de l’étude</li>
                  <li class="liste-slide">Résumé visuel des résultats</li>
                  <li class="liste-slide">Saisie libre de recommandations</li>
                  <a
                    href="https://calendly.com/contact-aestima/45min?"
                    target="_blank"
                    class="px-6 py-4 mt-6 mr-1 font-bold text-white rounded-lg text-md sm:text-base sm:mr-3 bg-aestiblue-light aestima-button sm:mt-10 sm:mb-3"
                    >Programmer une démo</a
                  >
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="slider_mobile">
      <div class="flex flex-col px-8 pt-8 md:hidden">
        <h2 class="text-center place-self-center">
          Un logiciel pour CGP simple et intuitif qui reste complet
        </h2>
        <p class="mb-8 text-base text-center">
          Simple ne veut pas forcément dire incomplet. Voici en détails toutes nos fonctionnalités pour
          vérifier par vous-même que l’on peut réussir à simplifier la complexité d’un simulateur
          d’investissement immobilier. Et ça c’est notre priorité.
        </p>
        <template v-for="slide in slider">
          <div class="text-xl font-bold">{{ slide.titre }}</div>
          <img :src="slide.img" class="self-center my-4 rounded-lg max-h-72" />
          <ul id="example-1" class="mt-2 mb-10 pl-1/6">
            <li v-for="plus in slide.plus" class="text-left list-disc">{{ plus }}</li>
          </ul>
        </template>
      </div>
    </section>

    <section>
      <div class="flex flex-col pt-10 bg-section-light-blue-2">
        <div class="flex justify-center">
          <h2>Pourquoi faire compliqué quand on peut faire simple ?</h2>
        </div>

        <div class="flex flex-col items-center justify-center gap-4 sm:flex-row align-center">
          <router-link
            to="/register"
            class="px-6 py-3 mx-6 font-bold text-white rounded-lg justify-self-start w-fit aestima-button"
            >Démarrer gratuitement</router-link
          >
          <a
            href="https://calendly.com/contact-aestima/45min?"
            target="_blank"
            class="px-2 py-4 font-bold text-black underline bg-transparent rounded-lg text-md place-self-center align-center sm:text-base"
            >Contactez-nous pour découvrir Aestima</a
          >
        </div>
      </div>
    </section>

    <ModalGuide v-if="showModal" v-on:close="showModal = false">
      <img
        class="w-40 h-auto"
        slot="img"
        src="../../assets/icons/home/aestima_expertise_livre.png"
        alt="Préface du livre d'expertise aestima"
      />
      <span class="text-2xl font-bold text-center sm:text-left" slot="header"
        >Développez votre expertise en investissement immobilier.</span
      >
      <span class="text-center sm:text-left" slot="subheader"
        >7 notions à connaître pour conseiller au mieux vos clients.</span
      >
      <span class="w-full mt-6" slot="form">
        <div id="mc_embed_signup">
          <form
            action="https://aestima-immo.us6.list-manage.com/subscribe/post?u=40e14fe3835ab511fb36aef65&amp;id=82356b6ac9"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate
          >
            <div id="mc_embed_signup_scroll">
              <div class="flex flex-row w-full">
                <div class="mr-1 mc-field-group">
                  <label style="display: none" for="mce-EMAIL">Adresse e-mail </label>
                  <input
                    placeholder="monemail@exemple.com"
                    type="email"
                    value=""
                    name="EMAIL"
                    class="placeholder-black bg-gray-300 border-none required email"
                    id="mce-EMAIL"
                  />
                </div>
                <div class="ml-1 mc-field-group">
                  <label style="display: none" for="mce-FNAME">Prénom </label>
                  <input
                    placeholder="Prénom"
                    type="text"
                    value=""
                    name="FNAME"
                    class="placeholder-black bg-gray-300 border-none required"
                    id="mce-FNAME"
                  />
                </div>
              </div>
              <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" style="display: none"></div>
                <div class="response" id="mce-success-response" style="display: none"></div>
              </div>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px" aria-hidden="true">
                <input type="text" name="b_40e14fe3835ab511fb36aef65_82356b6ac9" tabindex="-1" value="" />
              </div>
              <div class="text-center clear" @click="showModal = false">
                <input
                  type="submit"
                  value="Télécharger mon guide gratuitement"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  class="w-auto px-0 button sm:px-10"
                />
              </div>
            </div>
          </form>
        </div>
      </span>
    </ModalGuide>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Landing/Footer.vue';
import Header from '@/components/Landing/Header.vue';
import ModalGuide from '@/components/ModalGuide.vue';
import ModalYoutube from '@/components/popup/ModalYoutube.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'HomeLanding',
  data() {
    return {
      ytModal: false,
      currentIndex: 0,
      section_logiciel_submenu: [
        'Création de clients',
        'Gestion de clients',
        'Simulations financières',
        'Résultats épurés',
        'Comparaison',
        'Export de dossiers PDF',
      ],
      slider: [
        {
          titre: 'Création de clients',
          img: require('../../assets/home/bandeau1.png'),
          plus: [
            'Fiscalité fixe ou évolutive',
            'Calcul auto et évolutif de la TMI',
            'Stratégie d’investissement',
            'Critères de recherche détaillés',
            'Simulateur d’endettement',
          ],
        },
        {
          titre: 'Gestion de clients',
          img: require('../../assets/home/bandeau2.png'),
          plus: [
            'Tableau de bord intuitif',
            'Récapitulatif du profil client',
            'Notes sur l’évolution de la relation',
            'Notifications pour le suivi client',
            'Récapitulatif des simulations',
          ],
        },
        {
          titre: 'Simulations financières',
          img: require('../../assets/home/bandeau3.png'),
          plus: [
            'Enrichies des données du marché',
            'Pas de saisie superflue',
            'Fiscalité nom propre ou société',
            'Fiscalité nom propre ou société',
            'MaJ fiscales et comptables',
          ],
        },
        {
          titre: 'Résultats épurés',
          img: require('../../assets/home/bandeau_resultats_epures.png'),
          plus: [
            'Conseils adaptés à la stratégie',
            'Explication de tous les calculs',
            'Fiscalité évolutive selon le client',
            'Principaux ratios fondamentaux',
            'Analyse du marché locatif local',
          ],
        },
        {
          titre: 'Comparaison',
          img: require('../../assets/home/bandeau_comparaison.png'),
          plus: [
            'Jusqu’à 4 projets comparés',
            'Choisir le projet le plus rentable',
            'Choisir la ville la plus dynamique',
            'Etudier plusieurs scénarios',
            'Faciliter la prise de décision',
          ],
        },
        {
          titre: 'Export de dossiers PDF',
          img: require('../../assets/home/bandeau_export.jpg'),
          plus: [
            'Contenu personnalisable',
            'Logo et nom personnalisable',
            'Récapitulatif de l’étude',
            'Résumé visuel des résultats',
            'Saisie libre de recommandations',
          ],
        },
      ],
      showModal: false,
    };
  },
  components: {
    Footer,
    Header,
    ModalGuide,
    ModalYoutube,
  },
  created: function () {},
  methods: {
    ...mapActions({
      signIn: 'users/login',
      setStayConnected: 'users/setStayConnected',
      unsetStayConnected: 'users/unsetStayConnected',
      selectedStripePrice: 'users/setStripePriceSelected',
    }),
    setCurrent(index) {
      this.currentIndex = index;
    },
  },
  metaInfo() {
    return {
      title: 'Le 1er logiciel pour CGP vraiment intuitif : Aestima',
      meta: [
        {
          name: 'description',
          content:
            'Simulateur d’investissement immobilier - Simulateur de défiscalisation - Pédagogique pour vos équipes et vos clients.',
        },
      ],
      //keywords
    };
  },
};
</script>

<style scoped>
.aestima-button {
  --text-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--text-opacity));
  font-size: 0.875rem /* 14px */;
  font-weight: 700;
  display: inline-block;
  transition: 0.5s;
  border-radius: 0.5rem /* 8px */;
  background-color: #14a0eb;
  border: 2px solid #14a0eb;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 6px 6px 6px -2px rgba(0, 0, 0, 0.1), 3px 3px 2px -1px rgba(0, 0, 0, 0.05);
}

.aestima-button:hover {
  background-color: #ffffff;
  transform: scale(1);
  color: #14a0eb;
  border: 2px solid #14a0eb;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 6px 6px 4px -2px rgba(92, 157, 255, 0.315), 3px 3px 2px -1px rgba(0, 0, 0, 0.05);
}

.aestima-button-dark {
  font-size: 0.875rem /* 14px */;
  transition: 0.5s;
  background-color: #0e1e3a;
  border: 2px solid #0e1e3a;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.aestima-button-dark:hover {
  background-color: #ffffff;
  transform: scale(1);
  color: #14a0eb;
  border: 2px solid #14a0eb;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.blue-shadow {
  box-shadow: 0 10px 15px -3px rgba(62, 178, 255, 0.281), 0 4px 6px -2px rgba(102, 219, 255, 0.158);
}

@media (min-width: 960px) {
  .absolute-screen-splash {
    /* right: -5rem; */
    right: 2%;
    top: 1rem;
  }

  .w-screen-splash {
    width: 46%;
  }
}
h1 {
  font-size: xx-large;
  font-weight: 700;
  margin-bottom: 2rem;
  width: 80%;
}

h2 {
  font-size: x-large;
  font-weight: 700;
  margin-bottom: 2rem;
  width: 80%;
}

h3 {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: left;
  padding-left: 0.5rem;
}

. {
  box-shadow: 10px 10px 15px -3px rgba(0, 0, 0, 0.1), 4px 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.card-image {
  height: auto;
  max-height: 28rem /* 384px */;
}

.marges {
  padding-left: 6%;
  padding-right: 6%;
}
.pour-contre {
  font-size: 0.85rem;
  align-self: center;
  margin-left: 0.5rem /* 8px */;
  text-align: left;
  line-height: 1rem;
}

.liste-slide-desktop {
  margin-top: 0.75rem /* 12px */;
  margin-bottom: 2.5rem /* 40px */;
  margin-left: 5rem /* 16px */;
}
.liste-slide {
  margin-bottom: 0.75rem /* 12px */;
  list-style-type: disc;
  text-align: left;
}

.bg-section-light-blue {
  background: rgba(20, 160, 235, 0.05);
}

.bg-section-light-blue-2 {
  background: #f0f5fa;
}
.tabnav {
  overflow-x: auto;
  /* white-space: nowrap; */
}

.tabnav-item {
  display: inline-block;
  padding-left: 40px;
  border-bottom: 1px solid #c7c7c7;
  list-style: none;
  outline: none;
}

.tabnav-item:first-child {
  padding-left: 0;
}

.tabnav-link {
  pointer-events: auto;
  font-weight: 700;
  padding-bottom: 0;

  font-size: 17px;
  line-height: 1;
  letter-spacing: -0.021em;
  padding: 9px 0 11px;
  margin-top: 2px;
  margin-bottom: 4px;
  color: #bcbcbc;
  text-align: left;
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 0;
  cursor: pointer;
}
.tabnav-item .tabnav-link:after {
  border-bottom-width: 4px;
}
.tabnav-link:after {
  left: 0;
  position: absolute;
  bottom: -5px;
  width: 100%;
  border-bottom: 1px solid transparent;
  content: '';
}

.tabnav-link.current {
  pointer-events: none;
  color: #14a0eb;
  text-decoration: none;
  cursor: default;
  z-index: 10;
  cursor: default;
}

.tabnav-link.current:after {
  border-bottom-color: #14a0eb;
}
</style>
