<template>
	<div class="w-full grid grid-cols-12 gap-0">
		<div class="relative col-span-12 sm:col-start-1 sm:col-end-13 flex flex-col justify-center items-center py-10 mt-popup">
			<svg v-if="modalPDFLoader == true" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" display: inline-block; shape-rendering: auto; margin-top: 1.4rem" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
				<circle cx="50" cy="50" fill="none" stroke="#14a0eb" stroke-width="8" r="25" stroke-dasharray="70.68583470577033 25.561944901923447">
				<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1.6949152542372883s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
				</circle>
			</svg>
			<div v-else class="w-full grid grid-cols-11 gap-0">
				<h1 class="text-2xl">Félicitation, votre abonnement a bien été pris en compte.</h1>
				<p class="text-2xl">Vous pouvez désormais utiliser l'outil Aestima.</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import axios from "axios"

export default {
	name: 'Subscribe',
	data() {
			return {

			}
		},
	props: {
	},
	metaInfo(){
		return {
			title: `Mon Abonnement - Aestima`,
			meta: [{
				name: 'description',
				content: "Cette page est la page d'abonnement de l'utilisateur."
			}]
			//keywords
		}
	},
	components: {
	},
	created: function () {
		this.setModalPDFLoader(true)
		// this.usermail = this.$route.params.email
		let urlParams = new URLSearchParams(window.location.search);

		axios.post("/create-customer", {sessionId: urlParams.get('session_id'), userId: this.user._id})
		.then((result) => {
			//Call re-sub
			axios.post("/session-re-sub", {userId: this.user._id})
			.then(function(result) {
				// console.log("Est toujours abonné ? ", result.data.sub)
			})
			.catch(err => {
				console.log("message 1",err)
			})
			// console.log("reeeeeeees", result)
			
			
			this.setModalPDFLoader(false)
			
		})
		.catch(e => {
			console.log("error trace :", e.response.data)
		})
  	},
	computed: {
		...mapGetters({
			authenticated: 'users/authenticated',
			user: 'users/user',
			listProj: 'projects/projects',
			user: 'users/user',
			modalPDFLoader: 'invests/modalPDFLoader',
		}),
	},
	methods: {
		...mapActions({
			signOut: 'users/logout',
			getProjects: 'projects/getProjects',
			setProjectSelected: 'projects/setProjectSelected',
			updateUser: 'users/updateUser',
			updateUserMDP: 'users/updateUserMDP',
			setModalPDFLoader: 'invests/setModalPDFLoader',
		}),

	},
}
</script>

<style scoped>
.shadow-inner {
	box-shadow: 0 15px 35px 0 rgba(60,66,87,.08), 0 5px 15px 0 rgba(0,0,0,.12);
}

.box-billing {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 32px;
}

.box-border {
	border-radius: 4px;
    border: 1px solid rgb(49, 52, 62);
}

.box-bt {
	border-top: 1px solid rgb(49, 52, 62);
}

.mt-popup {
	margin-top: -65px;
}
</style>