<template>
<div>
		<l-map
		:center="center"
		:zoom="zoom"
		:minZoom="minZoom"
		:maxZoom="maxZoom"
		class="map"
		ref="map"
		@update:zoom="zoomUpdate"
		@update:center="centerUpdated"
		:options="{
			zoomControl: false,
			zoomDelta: zoomDelta,
			zoomSnap: zoomDelta,
			dragging: true,
		}"
		>
		<l-tile-layer
			:url="url"
			:options="{
				maxZoom: 25
			}"
		>
		</l-tile-layer>
			<l-marker
			v-if="sameAdresse.filter(val => val.properties.lat == center[0] && val.properties.lon == center[1]).length == 0"
			:lat-lng="center"
			:icon="iconSelected"
			></l-marker>
			<l-marker
			:key="marker.properties.numero_plan + Math.floor(Math.random() * 10000) + Math.floor(Math.random() * 10000)"
			v-for="marker in sameAdresse"
			:lat-lng="[marker.properties.lat, marker.properties.lon]"
			:icon="iconHandler(marker)"
			>
			<!-- @popupopen="openPopup" -->
				<l-tooltip
				:options="{
					permanent: true,
					direction: 'bottom',
				}"
				>{{numFormatterK(marker.properties.valeur_fonciere)}} €</l-tooltip>
				<l-popup
				 class="popup">
					<template v-if="marker.sameAdresse.length > 1">
						<p class="street">{{marker.properties.numero_voie}} {{marker.properties.type_voie}} {{marker.properties.voie}} - {{marker.properties.commune}}</p>
						<ul class="overflow-y-auto h-24">
							<li :key="Math.floor(Math.random() * 10000) + adr.properties.numero_plan + Math.floor(Math.random() * 10000)"
							v-for="(adr) in marker.sameAdresse"
							class="px-1 mb-2 bg-gray-100 mx-2 rounded-md">
								<p class="type_list">{{adr.properties.nombre_pieces_principales}} pièce(s) - {{adr.properties.surface_relle_bati}} m²</p>
								<p class="price_list">{{(adr.properties.valeur_fonciere)}} € <em class="saledate_list"> Vendu en {{new Date(adr.properties.date_mutation).toLocaleString('default', { month: 'long', year: 'numeric' })}}</em></p>
							</li>
						</ul>
						<div class="w-full bg-lightblueaestima-100 flex flex-row justify-between text-white px-4 py-2">
						</div>
					</template>
					<template v-else>
						<p class="street">{{marker.properties.numero_voie}} {{marker.properties.type_voie}} {{marker.properties.voie}} - {{marker.properties.commune}}</p>
						<p class="type">{{marker.properties.nombre_pieces_principales}} pièce(s) - {{marker.properties.surface_relle_bati}} m²</p>
						<p class="price">{{(marker.properties.valeur_fonciere)}} €</p>
						<p class="saledate">Vendu en {{new Date(marker.properties.date_mutation).toLocaleString('default', { month: 'long', year: 'numeric' })}}</p>
						<div class="w-full bg-lightblueaestima-100 flex flex-row justify-between text-white px-4 py-2">
						</div>
					</template>
				</l-popup>
			</l-marker>

			<l-control-zoom position="topright"></l-control-zoom>
		</l-map>
</div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LControlZoom, LPopup, LTooltip } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

export default {
 components: {
   LMap,
   LTileLayer,
   LMarker,
   LControlZoom,
   LPopup,
   LTooltip
 },
  props: {
		datas: Array,
		center: Array,
	},
 data () {
   return {
		// url: 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
		url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
		// url: 'http://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png',
		// url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		zoomObject: {
			start: 0,
			end: 0,
		},
		zoom: 19,
		minZoom: 17,
		maxZoom: 19,
		zoomDelta: 1,
		icon: L.icon({
			iconUrl: 'data:image/svg+xml;base64,' + btoa('<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g><path d="M86,14.33333c-39.5815,0 -71.66667,32.08517 -71.66667,71.66667c0,39.5815 32.08517,71.66667 71.66667,71.66667c39.5815,0 71.66667,-32.08517 71.66667,-71.66667c0,-39.5815 -32.08517,-71.66667 -71.66667,-71.66667z" fill="#ffffff"></path><path d="M86,28.66667c-31.66592,0 -57.33333,25.66742 -57.33333,57.33333c0,31.66592 25.66742,57.33333 57.33333,57.33333c31.66592,0 57.33333,-25.66742 57.33333,-57.33333c0,-31.66592 -25.66742,-57.33333 -57.33333,-57.33333z" fill="#14a0eb"></path></g></g></svg>'),
			iconSize: [18, 18],
			className: 'cursor'
		}),
		iconSelected: L.icon({
			iconUrl: 'data:image/svg+xml;base64,' + btoa('<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g><path d="M86,14.33333c-39.5815,0 -71.66667,32.08517 -71.66667,71.66667c0,39.5815 32.08517,71.66667 71.66667,71.66667c39.5815,0 71.66667,-32.08517 71.66667,-71.66667c0,-39.5815 -32.08517,-71.66667 -71.66667,-71.66667z" fill="#ffffff"></path><path d="M86,28.66667c-31.66592,0 -57.33333,25.66742 -57.33333,57.33333c0,31.66592 25.66742,57.33333 57.33333,57.33333c31.66592,0 57.33333,-25.66742 57.33333,-57.33333c0,-31.66592 -25.66742,-57.33333 -57.33333,-57.33333z" fill="#00FA9A"></path></g></g></svg>'),
			iconSize: [18, 18],
			className: 'cursor'
		}),
		arrayIndex: 0,
   }
 },
 methods: {
	zoomUpdate (zoom) {
		this.zoom = zoom;
   },
   zoomStart (zoom) {
   },
   zoomEnd (zoom) {
   },
   centerUpdated (center) {
   },
	numberWithSpaces(x) {
		if(isNaN(x) || x == undefined) return 0
		var parts = x.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		return parts.join(".");
	},
	prevAdresse() {
		this.arrayIndex = this.arrayIndex == 0 ? 0 : this.arrayIndex - 1
	},
	nextAdresse(size) {
		this.arrayIndex = this.arrayIndex == (size) ? size : this.arrayIndex + 1
	},
	iconHandler(marker) {
		if(marker.properties.lat == this.center[0] && marker.properties.lon == this.center[1]) {
			return this.iconSelected
		} else {
			return this.icon
		}
	},
	openPopup(event) {
        //   event.disableClickPropagation()
		//   console.log("eveeeeent =======> ",event)
		
		// this.center = [marker.properties.lat, marker.properties.lon]
		// this.$emit('setCenter', [event.target._latlng.lat, event.target._latlng.lng])
	},
	numFormatterK(num) {
		if(num > 999 && num < 1000000){
			return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
		}else if(num > 1000000){
			return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
		}else if(num < 900){
			return num; // if value < 1000, nothing to do
		}
	},
 },
 computed: {
	 sameAdresse: {
		get: function () {
			let arrayAdresse = []
			arrayAdresse = this.datas.filter((v,i,a)=> {
				return this.datas.findIndex(t=>(t.properties.lat === v.properties.lat && t.properties.lon ===v.properties.lon))===i
			})
			
			arrayAdresse.map((value, index) => {
				arrayAdresse[index].sameAdresse = this.datas.filter(data => {
					return value.properties.lon == data.properties.lon && value.properties.lat == data.properties.lat
				})
			})
			return arrayAdresse
			
		},
		set: function (val) {
			
		}
	},
 }
}
</script>

<style>
 .map {
   position: absolute;
   width: 100%;
   height: 100%;
   overflow :hidden
 } 
 
 .cursor {
	transition: 0.2s;
 }
 .cursor:hover {
	border-radius: 100%;
	padding: 3px;
	background: rgba(20,160,235,0.4);
	width:10rem;
	height:10rem;
 }
 
.leaflet-popup {
	bottom: 0px !important;
}

.leaflet-popup-content-wrapper {
	padding: 0px;
	border-radius: 2px;
}

.leaflet-popup-content-wrapper .leaflet-popup-content {
	margin: 0px !important;
	width: 20rem !important;
}

.leaflet-popup-tip-container {
}

.leaflet-popup-content p {
	margin: 2px 0;
}

.popup {
	/* padding: 10px; */
}

.popup .street {
	padding: 15px 15px 0px 15px;
	font-size: 1rem;
	font-weight: 600;
}

.popup .type {
	padding: 0px 15px 0px 15px;
	font-size: 0.8rem;
	font-weight: 400;
}

.popup .price {
	padding: 0px 15px 0px 15px;
	font-size: 1.2rem;
	font-weight: 700;
}

.popup .saledate {
	padding: 0px 15px 15px 15px;
	font-size: 0.8rem;
	font-weight: 400;
}

.popup .type_list {
	padding: 5px 10px 0px 10px;
	font-size: 0.8rem;
	font-weight: 400;
}

.popup .price_list {
	padding: 0px 10px 5px 10px;
	font-size: 1rem;
	font-weight: 700;
}

.popup .saledate_list {
	padding: 0px 15px 0px 15px;
	font-size: 0.8rem;
	font-weight: 400;
}

.leaflet-tooltip {
	padding: 1px 1px 1px 1px;
	top: 9px;
	left: 5px;
} 

.leaflet-zoom-animated{
	
}

.leaflet-tooltip-bottom {
	
}

.leaflet-tooltip-bottom::before {
	display: none;
}


</style>